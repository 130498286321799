import React, { useState, useEffect } from 'react';
import classes from './CareerDetailContainer.module.scss';
import Icon from '../../../../components/UI/Icon/Icon';
import Button from '../../../../components/UI/Button/Button';
import axios from '../../../../axios';
import { ANTAVAYA_API } from '../../../../constant/Api';
import ApplyCareerForm from '../../../../components/Mobile/NonProduct/ApplyCareerForm/ApplyCareerForm';

const careerDetailContainer = props => {
    const [showApplyForm, setShowApplyForm] = useState(false);
    const [career, setCareer] = useState(null);
    const [isSecondHeader, setIsSecondHeader] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        let slug = props.match.params.slug;
        axios.get(ANTAVAYA_API + 'v1/career/' + slug)
            .then( response => {
                if (response.data) {
                    setCareer(response.data);
                } else {
                    goToCareerPageHandler()
                }
            })
            .catch( error => {
                console.log(error)
            });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    const handleScroll = () => {
        const offsetTop = window.scrollY;
        if (offsetTop > 25) {
            setIsSecondHeader(true);
        } else {
            setIsSecondHeader(false);
        }
    }

    const goToCareerPageHandler = () => {
        props.history.push('/career');
    }

    const showApplyFormClickHandler = () => {
        document.body.style.overflowY = 'hidden';
        setShowApplyForm(true);
    }
    
    const hideApplyFormClickHandler = () => {
        document.body.style.overflowY = 'auto';
        setShowApplyForm(false);
    }

    if (!career) {
        return null
    }

    let secondHeaderClasses = [classes.Header, classes.SecondNav];
    if (isSecondHeader) {
        secondHeaderClasses.push(classes.Show);
    }

    let jobDescriptionSection = null;
    if (career.job_descriptions && career.job_descriptions.length) {
        const jobDescriptionList = career.job_descriptions.map((jobDescription, index) => {
            return (<li key={index}>{jobDescription}</li>);
        });
        jobDescriptionSection = (
            <div className="u-mb-30">
                <div className={classes.SubTitle}>Job Descriptions</div>
                <div className={classes.Description}>
                    <ul>
                        {jobDescriptionList}
                    </ul>
                </div>
            </div>
        );
    }

    let requirementSection = null;
    if (career.requirements && career.requirements.length) {
        const requirementList = career.requirements.map((requirement, index) => {
            return (<li key={index}>{requirement}</li>);
        });
        requirementSection = (
            <div className="u-mb-30">
                <div className={classes.SubTitle}>Requirements</div>
                <div className={classes.Description}>
                    <ul>
                        {requirementList}
                    </ul>
                </div>
            </div>
        );
    }

    let additionalInformationSection = null;
    if (career.additional_information !== "") {
        additionalInformationSection = (
            <div>
                <div className={classes.SubTitle}>Additional Information</div>
                <div className={classes.Description}>
                    <p>{career.additional_information}</p>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.Wrapper}>
            <ApplyCareerForm
                show={showApplyForm}
                backClicked={hideApplyFormClickHandler}
                jobDetail={career} />

            <div className={classes.Header}>
                <span className={classes.Icon} onClick={goToCareerPageHandler}>
                    <Icon name="ico-arrow-left" width="20" stroke="none" fill="#ffffff" />
                </span>
                <div className={[classes.Title, 'u-color-white'].join(' ')}>Career</div>
            </div>

            <div className={secondHeaderClasses.join(' ')}>
                <span className={classes.Icon} onClick={goToCareerPageHandler}>
                    <Icon name="ico-arrow-left" width="20" stroke="none" fill="#202124" />
                </span>
                <div className={classes.Title}>Career</div>
            </div>

            <div className={classes.Content}>
                <div className={classes.Title}>{career.name}</div>
                <div className={[classes.Location, 'u-mb-40'].join(' ')}>{career.location}</div>
                {jobDescriptionSection}
                {requirementSection}
                {additionalInformationSection}
            </div>

            <div className={classes.Footer}>
                <div >
                    <div className={classes.Title}>{career.name}</div>
                    <div className={classes.Location}>
                        {career.location}
                    </div>
                </div>
                <div className={classes.Button}>
                    <Button 
                        classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                        clicked={showApplyFormClickHandler}>
                        Apply Now
                    </Button>
                </div>
            </div>

        </div>
    );
}

export default careerDetailContainer;