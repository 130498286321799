import React, { useState, useEffect } from 'react';
import classes from './TourInput.module.scss';
import SearchAutocomplete from '../SearchAutocomplete/SearchAutocomplete';
import { withRouter } from 'react-router-dom';
import axios from '../../../../../axios';
import { ANTAVAYA_API } from '../../../../../constant/Api';

const tourInput = props => {
    const [regionList, setRegionList] = useState([]);
    const [regionSelected, setRegionSelected] = useState('all-region');

    useEffect(() => {
        axios.get(ANTAVAYA_API + 'v1/tour/destinations')
            .then( response => {
                setRegionList(response.data);
            })
            .catch( error => {
                console.log(error);
            })

        if(props.regionSelected && props.regionSelected !== regionSelected) {
            setRegionSelected(props.regionSelected);
        }
    }, [props.regionSelected])

    const itemSelectedHandler = (val) => {
        props.hideBackdropClicked();
        setRegionSelected(val);
    }

    const showBackdropHandler = () => {
        props.showBackdrop();
    }

    const regionInputChangeHandler = (event) => {
        setRegionSelected(event.target.value);
    }

    const getNameRegionSelected = regionVal => {
        const regionLists = regionList;
        let regionName = 'all-region';
        if (regionVal !== '') {
            for (const index in regionLists) {
                if (regionLists[index].value === regionVal) {
                    regionName = regionLists[index].name;
                    break;
                }
            }
        }
        return regionName;
    }

    let regionNameSelected = getNameRegionSelected(regionSelected);
    let sortDropDown = "asc";
    
    let wrapperClass = [classes.Wrapper];
    let formClasses = [classes.Form];
    if (props.type === 'small') {
        wrapperClass.push(classes.TopLeftRadius);
        formClasses.push(classes.Small);
    }
    return (
        <div>
            <div className={wrapperClass.join(' ')}>
                <div className={formClasses.join(' ')}>
                    <div className={classes.InputWrapper}>
                        <SearchAutocomplete
                            isReadOnly={true}
                            label="REGION"
                            placeholder="Select a Region"
                            value={regionNameSelected}
                            dropdownList={regionList}
                            sortDropDown={sortDropDown}
                            showBackdrop={showBackdropHandler}
                            changed={(event) => regionInputChangeHandler(event)}
                            itemSelected={(val) => itemSelectedHandler(val)}/>
                    </div>
                </div>
                <div className={classes.Action}>
                    <button className={classes.Button} onClick={() => props.searchTourClicked(regionSelected)}>Explore</button>
                </div>
            </div>
        </div>
    );
}

export default withRouter(tourInput);
