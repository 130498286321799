import React, { useState } from 'react';
import classes from './CalendarSwipe.module.scss';
import './Calendar.scss';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import Datepicker from 'react-mobile-datepicker';
import { MONTH_MAP } from '../../../constant/General';
import { formatDisplayDate } from '../../../components/../helper/GlobalFunc';
import Popup from '../Popup/Popup';

const calendarSwipe = (props) => {
    const [ showBackdrop, setBackdrop ] = useState(false);
    const [ isOpen, setIsOpen ] = useState(true);

    let showCalendarContentHandler = () => {
        setBackdrop(true);
    }

    let hideCalendarContentHandler = () => {
        setBackdrop(false);
    }

    const dateConfig = {
        'date': {
            format: 'DD',
            caption: 'Day',
            step: 1,
        },
        'month': {
            format: value => MONTH_MAP[value.getMonth() + 1],
            caption: 'Mon',
            step: 1,
        },
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        },
    };

    let dateDisplay = formatDisplayDate(props.value);

    let content = (
        <div className={classes.WrapperContent}>
            <div className={classes.Content}>
                <Datepicker
                    isPopup={false}
                    value={props.value}
                    min={props.minDate}
                    max={props.maxDate}
                    theme={'default'}
                    isOpen={true}
                    onSelect={(date) => props.changed(date)}
                    onCancel={() => setIsOpen(!isOpen)}
                    customHeader="Custom Header"
                    confirmText={(
                        <Button
                            classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
                            clicked={hideCalendarContentHandler}
                            children="Save"/>
                    )}
                    cancelText=""
                    showHeader={false}
                    showFooter={true}
                    dateConfig={dateConfig}/>
                </div>
            <div className={classes.BackgroundFocus}></div>
        </div>
    )

    return (
        <div>
            <Popup
                show={showBackdrop}
                hideClicked={hideCalendarContentHandler}
                title={props.title}
                content={content}/>

            <div className={classes.InpuDate} onClick={showCalendarContentHandler}>
                <div className={classes.Label}>{dateDisplay}</div>
                <div className={classes.Icon}>
                    <Icon name="ico-calendar" width={22} stroke="none" fill="#818589" />
                </div>
            </div>
        </div>
    )
}

export default calendarSwipe;
