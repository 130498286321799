import React from 'react';
import Modal from '../../../../../UI/Modal/Modal';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import Button from '../../../../../UI/Button/Button';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../../redux/Shared/Member/MemberAction';

function  emailModal(props) {
    const updateFormEmail = () => {
        let newEmail = {
            email: props.formEmail.email.value
        }
        props.submitChangedEmail(newEmail)
    }
    
    return (
        <Modal 
            title="Edit Email Address"
            show={props.showUpdateEmailForm}
            closeClicked={() => props.showHideUpdateEmailClicked(false)}>
            <div className="u-mb-40">
                <div className="label">Email Address</div>
                <div className="u-mb-24">
                    <TextField
                        placeholder="e.g. youremail@example.com" 
                        value={props.formEmail.email.value}
                        changed={(event) => props.inputChangedEmail(event, 'email')}/>
                </div>
            </div>
            <div className="u-display-flex">
                <div className="u-flex-5 u-mr-8">
                    <Button 
                        classes={['BtnOutline', 'BtnMedium', 'BtnBlock']}
                        clicked={() => props.showHideUpdateEmailClicked(false)}>Cancel</Button>
                </div>
                <div className="u-flex-5 u-ml-8">
                    <Button 
                        clicked={() => updateFormEmail()}
                        isLoading={props.isSubmitLoading}
                        classes={['BtnRed', 'BtnMedium', 'BtnBlock']}>Save</Button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        formEmail: state.MemberReducer.formEmail,
        isSubmitLoading: state.MemberReducer.isSubmitLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChangedEmail: (event, inputIdentifier) => dispatch(memberAction.inputChangedEmail(event, inputIdentifier)),
        submitChangedEmail: (newEmail) => dispatch(memberAction.submitChangedMember(newEmail, 'emailForm'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(emailModal);