import React from 'react'
import MyBookingContainer from '../MyBookingContainer/MyBookingContainer'
import RetrieveBookingContainer from '../RetrieveBookingContainer/RetrieveBookingContainer';
import Auth from '../../../../middleware/Auth';

function BookingContainer() {
    let screen = <RetrieveBookingContainer/>
    if (Auth.isAuthenticated()) {
        screen = <MyBookingContainer />
    }

    return (
        <div>
            {screen}
        </div>
    )
}
export default BookingContainer
