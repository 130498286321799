import React, { useEffect } from 'react';
import classes from './Terms.module.scss';
import List from '../../../../UI/List/List';
import CSSTransition from 'react-transition-group/CSSTransition';
import Header from '../../../Shared/Header/Header';



const Terms = props => {
    useEffect(() => {
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [props.show]);

    return (
        <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={270}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close 
            }}>
            <div className={classes.Wrapper}>
                <div className={classes.Header}>
                    <Header 
                        classes={['Shadow']}
                        leftIcon="close"
                        leftClicked={props.hidePopupClicked}
                        content="Terms & Conditions" />
                </div>
                <div className={classes.Content}>
                    <div className={classes.Item}>
                        <div className={classes.Title}>Cancellation Policy</div>
                        <List listSize="small" list={props.cancellationPolicyList}/>
                    </div>
                    <div className={classes.Item}>
                        <div className={classes.Title}>Terms & Conditions</div>
                        <List listSize="small" list={props.tocList}/>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}

export default Terms;