import React from "react";
import classes from "./TermAndCondition.module.scss";
import CSSTransition from "react-transition-group/CSSTransition";
import Header from "../../../Mobile/Shared/Header/Header";
import List from "../../../UI/List/List";

const termAndCondition = (props) => {
	let item = null;
	if (props.data && props.data.toc) {
		item = props.data.toc.map((item, index) => {
			return (
				<div className={classes.Item} key={index}>
					<div className={classes.Title}>{item.shortTitle}</div>
					<List listSize="small" list={item.data} />
				</div>
			);
		});
	}
	return (
		<CSSTransition
			in={props.show}
			mountOnEnter
			unmountOnExit
			timeout={270}
			classNames={{
				enterActive: classes.Open,
				exitActive: classes.Close,
			}}
		>
			<div className={classes.Wrapper}>
				<div className={classes.Header}>
					<Header
						classes={["Shadow"]}
						leftIcon="close"
						leftClicked={props.hidePopupClicked}
						content="Terms and Condition"
					/>
				</div>
				<div className={classes.Content}>{item}</div>
			</div>
		</CSSTransition>
	);
};

export default termAndCondition;
