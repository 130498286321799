import React from 'react';
import classes from './AutocompleteList.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';

const AutocompleteList = (props) => {
    let dropdownList = null;
    if (props.dropdownList && props.dropdownList.length > 0) {
        dropdownList = props.dropdownList.map((item, index) => {
            return (
                <li key={index} className={classes.Item} onClick={() => props.itemSelected(item.code)}>
                    <div>
                        <div className={classes.Region}>{item.location + ', ' + item.airportCountry}</div>
                        <div className={classes.Airport}>{item.name}</div>
                    </div>
                    <span className={classes.Code}>{item.code}</span>
                </li>
            );
        });
    } else {
        dropdownList = <li className={classes.Item}>No Result</li>
    }

    let popularLabel = <li className={classes.Label}>Popular Destination</li>;
    if (props.isFilterDropdownList) {
        popularLabel = null;
    }
    
    return (
        <CSSTransition
            in={props.showDropdownList}
            mountOnEnter
            unmountOnExit
            timeout={270}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close
            }}>
            <div className={classes.Wrapper}>
                <ul className={classes.List}>
                    {popularLabel}
                    {dropdownList}
                </ul>
            </div>
        </CSSTransition>
    );
}

export default AutocompleteList;