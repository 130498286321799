import React, { useState, useEffect } from 'react';
import classes from './DetailContainer.module.scss';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import queryString from 'query-string';
import history   from '../../../../history';
import Icon from '../../../../components/UI/Icon/Icon';
import { formatDisplayDate } from '../../../../helper/GlobalFunc';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import Button from '../../../../components/UI/Button/Button';
import CurrencyFormat from 'react-currency-format';
import DetailPanel from '../../../../components/Mobile/ThingsToDo/Admission/Detail/DetailPanel/DetailPanel';
import List from '../../../../components/UI/List/List';
import TermAndCondition from '../../../../components/Mobile/ThingsToDo/Admission/Detail/TermAndCondtion/TermAndCondtion';
import DetailPackageLoader from '../../../../components/UI/Loaders/Mobile/DetailPackage/DetailPackageLoader';
import UnavailableTour from '../../../../components/UI/UnavailableTour/UnavailableTour';
import WhatsappLink from '../../../../components/Mobile/Shared/WhatsappLink/WhatsappLink';

import { connect } from "react-redux";
import * as DetailAction from '../../../../redux/Admission/Detail/DetailAction';

const detailContainer = (props) => {
    const [isSecondHeader, setIsSecondHeader] = useState(false);
    const [showAboutVoucher, setShowAboutVoucher] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', handleScroll);
        props.onInitAdmission(props.match.params.slug)
    }, [])

    const handleScroll = () => {
        const offsetTop = window.scrollY;
        if (offsetTop > 215) {
            setIsSecondHeader(true)
        } else {
            setIsSecondHeader(false);
        }
    }

    const backClickHandler = () => {
        if (props.history.action === 'PUSH') {
            const values = queryString.parse(props.location.search);
            if (values.page === 'booking') {
                props.history.push('/');
            } else {
                history.go(-1);
            }
        } else {
            props.history.push('/');
        }
    }

    const backToHome = () => {
        props.history.push('/');
        document.body.style.overflow = 'auto';
    }

    let secondHeaderClasses = [classes.Nav, classes.SecondNav];
    if (isSecondHeader) {
        secondHeaderClasses.push(classes.Show);
    }

    let content = null;
    let whatsappUrl = null;

    if (props.isErrorFetchAdmission) {
        content = <UnavailableTour
        showUnavailableTour={true}
        title="Admission Empty"
        description="Sorry, admission is unavailable. Please see other destinations."
        buttonLabel="Back to Home"
        redirect={backToHome}
        />;
    } else {
        if (props.isFetchAdmission) {
            content = <DetailPackageLoader />;
        } else {
            if (props.admissionDetail) {
                let virtualTourName = ((props.admissionDetail.tourAdmissionName).split('&').join('')).split(' ').join('%20');
                whatsappUrl = `https://wa.me/62${(props.admissionDetail.customerServiceData.phoneNumber).slice(1)}?text=Saya%20ingin%20mengetahui%20lebih%20lanjut%20tentang%20Admission%20Tour%20${virtualTourName}`;
                content = (
                    <div className={classes.Content}>
                        <div className={classes.Image}>
                            <img src={props.admissionDetail.image} alt="virtual-tour" />
                        </div>
        
                        <div className={classes.TopContent}>
                            <div className={classes.Title}>{props.admissionDetail.tourAdmissionName}</div>
                            <div className={classes.Location}>
                                <Icon name="ico-location" fill="#666666" stroke="none" width={20} />
                                <div className={classes.LocationName}>{props.admissionDetail.location}</div>
                            </div>
                            <div className={classes.DateWrapper}>
                                <label>Date of Validity</label>
                                <div className={classes.ValidDate}>
                                    <Icon name="ico-calendar" fill="#666666" stroke="none" width={24} />
                                    <div className={classes.Date}>{formatDisplayDate(props.admissionDetail.startPeriodOfTravelling)} - {formatDisplayDate(props.admissionDetail.endPeriodOfTravelling)}</div>
                                </div>
                            </div>
                        </div>
        
                        <div className="u-p-16">
                            <DetailPanel
                                title="Description" 
                                isCollapseBtn={true} 
                                showMore={true}
                                data={props.admissionDetail.description}/>
                        </div>
        
                        <div className="u-p-16">
                            <div className={classes.Heading}>Include</div>
                            <PanelBox>
                                <div className="u-p-16">
                                    <List
                                        list={props.admissionDetail.include}
                                        listColor={'green'}
                                    />
                                </div>
                            </PanelBox>
                        </div>
        
                        <div className="u-p-16 u-mb-16">
                            <div className={classes.Heading}>You Should Know</div>
                            <div className={classes.SplitSpace}>
                                <div className={classes.FreeVoucherSpace}>
                                    <PanelBox>
                                        <div className={classes.Card} onClick={() => setShowAboutVoucher(true)}>
                                            Terms and Conditions, Redempt...
                                            <Icon name="ico-chev-right" fill="#202124" stroke="none" />
                                        </div>
                                    </PanelBox>
                                </div>
                                <div className={classes.WhatsappSpace}>
                                    {/* <a href={whatsappLink} target="blank" className={classes.Button}>
                                        <Icon name="ico-whatsapp" stroke="none" fill="#ffffff" />
                                    </a> */}
                                </div>
                            </div>
                        </div>
        
                        <div className={classes.FooterWrapper}>
                            <div className={classes.PriceWrapper}>
                                <div className={classes.Label}>Price</div>
                                <div className={classes.Price}>
                                    <CurrencyFormat value={props.admissionDetail.priceStartFrom} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                                </div>
                            </div>
                            <div className={classes.Button}>
                                <Button 
                                    classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                                    clicked={() => props.history.push('/admission/pre-booking/' + props.admissionDetail.slug)}>
                                    Book Now
                                </Button>
                            </div>
                        </div>
        
                    </div>
                )
            }
        }
    }

    return (
        <div onScroll={handleScroll}>
            <div className={classes.Wrapper}>
                <TermAndCondition
                    list={(props.admissionDetail) ? props.admissionDetail.termAndCondition : ''}
                    show={showAboutVoucher}
                    hidePopupClicked={() => setShowAboutVoucher(false)} />
                <div className={classes.Nav}>
                    <Header 
                        classes={['BgTransparent']}
                        icoColor="white"
                        leftIcon="back"
                        leftClicked={backClickHandler} />
                </div>
                <div className={secondHeaderClasses.join(' ')}>
                    <Header
                        classes={['Shadow']}
                        leftIcon="back"
                        leftClicked={backClickHandler}
                        content={'Universal Studio Singapore**'} />
                </div>
                {content}
                <WhatsappLink whatsappLink={whatsappUrl}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        admissionDetail: state.AdmissionDetailReducer.admissionDetail,
        isFetchAdmission: state.AdmissionDetailReducer.isFetchAdmissionDetail,
        isErrorFetchAdmission: state.AdmissionDetailReducer.isErrorFetchAdmissionDetail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onInitAdmission: (slug) => dispatch(DetailAction.initAdmissionDetail(slug)),
        onInitVisitors: (data) => dispatch(DetailAction.initVisitors(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(detailContainer);
