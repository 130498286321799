import React, { Component } from 'react';
import classes from './BannerSlider.module.scss';
import Slider from 'react-slick';
import Icon from '../../../UI/Icon/Icon';
import { withRouter } from 'react-router-dom';
import axios from '../../../../axios';
import { ANTAVAYA_API } from '../../../../constant/Api';
import BannerSliderLoader from '../../../UI/Loaders/Mobile/BannerSlider/BannerSliderLoader';

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
		<div
			className={[classes.Arrow, classes.PrevArrow].join(' ')}
			onClick={onClick} >
			<Icon name="ico-arrow-right" fill="#202124" stroke="none" width={60}/>
		</div>
    );
}

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
		<div
			className={[classes.Arrow, classes.NextArrow].join(' ')}
			onClick={onClick}>
			<Icon name="ico-arrow-right" fill="#202124" stroke="none" width={60}/>
		</div>
    );
}

class BannerSlider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			bannerSliders: []
		};
	}
	
	goToPage = (isExternalUrl, url) => {
		if (isExternalUrl) {
			window.open(url, '_blank');
		} else {
			this.props.history.push(url);
		}
	}

	componentDidMount() {
		axios.get(ANTAVAYA_API + 'v1/site/home-banner')
			.then( response => {
				this.setState({
					isLoading: false,
					bannerSliders: response.data
				});
			})
			.catch( error => {
				console.log(error);
			});
	}

    render() {
        const settings = {
			centerMode: true,
            dots: true,
            infinite: true,
			speed: 500,
			slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            adaptiveHeight: true,
            variableWidth: true,
            centerPadding: '0px',
            fade:false,
            dotsClass:  'slick-dots sm-dot-light ' + classes.DotWrapper,
            autoplay: false,
            autoplaySpeed: 3500,
            nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			swipeToSlide: true,
            customPaging: function(i) {
                return <div className="banner-product"></div>;
            },
		};
		
		let sliderItems = [];
		if (!this.state.isLoading) {
			sliderItems = this.state.bannerSliders.map( (slider, index) => {
				return (
					<div key={index} className={classes.Item} onClick={() => this.goToPage(slider.isExternalUrl, slider.url)}>
						<div className={classes.ImageWrapper}>
							<img src={slider.mobileImage} alt={slider.name} />
						</div>
					</div>
				);
			});
		} else {
			for (let index = 0; index < 3; index++) {
				sliderItems.push(<BannerSliderLoader key={index}/>);
			}
		}
		
        return (
            <div className={classes.PromoList}>
                <Slider {...settings}>
					{sliderItems}
                </Slider>
            </div>
        );
    }
}

export default withRouter(BannerSlider);