import React from 'react';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import MetaTag from '../../../../helper/MetaTag';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import BannerAvailability from '../../../../components/Desktop/Activity/BannerAvailibility/BannerAvailibility';
import ContentAvailability from '../../../../components/Desktop/Admission/ContentAvailability/ContentAvailability';

const availabilityContainer = (props) => {
    return (
        <div className="bg-white-dark">
            <MetaTag title="Admission"/>
            <Header />
            
            <BannerAvailability />
            <ContentAvailability />
            <Footer />
        </div>
    )
}

export default availabilityContainer;
