import React from 'react';
import classes from './Button.module.scss';
import Icon from '../../../../UI/Icon/Icon';

const button = (props) => {
    let sortBtn = null;
    if (props.contentAvailability === 'experience') {
        sortBtn = (
            <div className={classes.Item} onClick={props.showSortPopupClicked}>
                <Icon name="ico-sort" fill="#CD2033" stroke="none" />
                <span className={classes.Label}>Sort</span>
            </div>
        )
    }
    return (
        <div className={classes.Wrapper}>
            {sortBtn}
            <div className={classes.Item} onClick={props.showFilterPopupClicked}>
                <Icon name="ico-filter" fill="#CD2033" stroke="none" />
                <span className={classes.Label}>Filter</span>
            </div>
        </div>
    );
} 

export default button;