import React from 'react';
import classes from './BannerSliderLoader.module.scss';
import ContentLoader from 'react-content-loader';

const bannerSliderLoader = () => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.ImageWrapper}>
                <ContentLoader
                    height={227}
                    speed={1}
                    primaryColor={'#c2c2c2'}
                >
                </ContentLoader>
            </div>
        </div>
    )
}

export default bannerSliderLoader;
