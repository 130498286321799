import React from 'react';
import ContentCard from '../Component/ContentCard/ContentCard';
import { withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import NotFound from '../NotFound/NotFound';
import PanelBox from '../../../../../../components/UI/PanelBox/PanelBox';

const upcomingPage = (props) => {
    const goTo = id => {
        props.history.push(`/user/my-booking/${id}`)
    }

    const tripUpcoming = props.listTour;
    let bookingList = null;
    if (props.isLoading) {
        let loaders = [];
        for (let i = 0; i < 5; i++) {
            loaders.push(
            <div key={i} className="u-pb-16">
                <PanelBox>
                    <ContentLoader
                        height={150}
                        speed={1}
                        primaryColor={'#e6e6e6'}
                    >
                        <rect x="16" y="16" rx="4" ry="4" width="200" height="15" />
                        <rect x="285" y="16" rx="4" ry="4" width="100" height="15" />

                        <rect x="16" y="50" rx="40" ry="40" width="65" height="65" />
                        <rect x="100" y="50" rx="4" ry="4" width="200" height="17" />
                        <rect x="100" y="75" rx="4" ry="4" width="120" height="15" />
                        <rect x="100" y="100" rx="4" ry="4" width="180" height="15" />
                        
                    </ContentLoader>
                </PanelBox>
            </div>
            )
        }
        bookingList = loaders;
    } else {
        bookingList = tripUpcoming.filter( item => {
            return item.orderStatus === props.activePage;
        }).map((item, index) => {
            return (
                <div key={index}>
                    <ContentCard
                        overlay={false}
                        data={item}
                        clicked={() => goTo(item.bookingID)}
                    />
                </div>
            )
        })
        if (bookingList.length === 0) {
        
            bookingList =  <NotFound />;
        }
    }
    return <div>{bookingList}</div>
}

export default withRouter(upcomingPage);