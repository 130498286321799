import React from 'react';
import classes from './Item.module.scss';
import { toCapitalize, formatHumanDate, currencyFormat, pluralize } from '../../../../../../../helper/GlobalFunc';
import Icon from '../../../../../../UI/Icon/Icon';
import Button from '../../../../../../UI/Button/Button';
import ImgThumb from '../../../../../../UI/ImgThumb/ImgThumb';
import PaymentStatus from '../../../../../../Shared/PaymentStatus/PaymentStatus';

const item = props => {
    const paymentClickHandler = (id) => {
        window.location = process.env.REACT_APP_PAYMENT_HOST + '?orderNo=' + id;
    }

    return (    
        <div className={classes.Wrapper}>
            <div className={classes.Head}>
                <div className={classes.Title}>Booking Id : <span>{props.data.bookingID}</span></div>
                <div className={classes.Link} onClick={props.bookingDetailClicked}>See Details</div>
            </div>
            <div  className={classes.Body}>
                <div className={classes.Info}>
                    <ImgThumb type="square" src={props.data.image.url} />
                    <div className={classes.Text}>
                        <div className={classes.Title}>{`${props.data.duration}D `+ toCapitalize(props.data.tourName)}</div>
                        <div className={classes.Duration}>{pluralize(props.data.days, 'Day', 'Days')} & {pluralize(props.data.night, 'Night', 'Nights')}</div>
                    </div>
                </div>
                <div className={classes.Detail}>
                    <div className={classes.Box}>
                        <div className={classes.Label}>Date</div>
                        <div className={classes.Value}>
                            <Icon name="ico-calendar" stroke="none" fill="#6A6E73"  />
                            <span>{formatHumanDate(props.data.departureDate)} - {formatHumanDate(props.data.endTourDate)}</span>
                        </div>
                    </div>
                    <div className={classes.Box}>
                        <div className={classes.Label}>Status</div>
                        <div className={classes.Value}>
                            <PaymentStatus status={props.data.paymentStatus} />
                        </div>
                    </div>
                </div>
            </div>
            {(props.data.paymentStatus === 'waiting') ? <div className={classes.Footer}>
                <div className={classes.Label}>Total Price : 
                    <span>{currencyFormat(props.data.totalPrice)}</span>
                </div>
                <div>
                    <Button clicked={()=> paymentClickHandler(props.data.bookingID)} classes={['BtnSmall', 'BtnRed']}>Continue to Payment</Button>
                </div>
            </div>: null}
            
        </div>
    );
}

export default item;