import React, { useEffect } from 'react';
import classes from './ActivityFeatured.module.scss';
import Slider from 'react-slick';
import Icon from '../../../UI/Icon/Icon';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import Button from '../../../UI/Button/Button';
import { DEFAULT_IMG } from '../../../../constant/Content';
import { withRouter } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../redux/Admission/Availability/AvailabilityAction';

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div
			className={[classes.Arrow, classes.PrevArrow].join(' ')}
			onClick={onClick}>
			<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48} />
		</div>
	);
}

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div
			className={[classes.Arrow, classes.NextArrow].join(' ')}
			onClick={onClick}>
			<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48} />
		</div>
	);
}

const activityFeatured = (props) => {
    useEffect(() => {
        props.initAdmissionPackages()
    }, [])

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 3,
		variableWidth: true,
		autoplay: false,
		autoplaySpeed: 2500,
		dotsClass: "slick-dots lg-dot-light " + classes.DotWrapper,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		customPaging: function (i) {
			return (
				<div className="banner-product"></div>
			);
		},
    };
    
    const goToDetail = (slug) => {
		props.history.push('/admission/detail/' + slug);
    }

    let content = null;
    if (props.isErrorFetchAdmission) {
        content = <div className={classes.NotFound}>Sorry, no tour package matches your preference. Please change your search.</div>;
    } else {
        if (props.isFetchAdmission) {
            // content = 'Loading...';
        } else {
            if (props.admissionPackages && props.admissionPackages.length > 0) {
                let itemList = props.admissionPackages.map((item, index) => {
                    return (
                        <div className={classes.Item} key={index}>
                            <PanelBox>
                                <div className={classes.ItemBox}>
                                    <div className={classes.Image}>
                                        <img onError={DEFAULT_IMG} src={item.image} alt="bird" />
                                    </div>
                                    <div className={classes.Body}>
                                        <div className={classes.Title}>
                                            {item.tourAdmissionName}
                                        </div>

                                        <div className={classes.Location}>
                                            <div className={classes.Icon}>
                                                <Icon name="ico-location" fill="#666666" stroke="none" width={16} />
                                            </div>
                                            {item.location}
                                        </div>
                                    </div>
                                    <div className={classes.Footer}>
                                        <div className={classes.ItemPrice}>
                                            <div className={classes.Label}>
                                                Start From
                                            </div>
                                            <div className={classes.Price}>
                                                <CurrencyFormat value={item.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                                            </div>
                                        </div>
                                        <div className={classes.ItemBtn}>
                                            <Button
                                                classes={['BtnSmall', 'BtnBlock', 'BtnRed']}
                                                clicked={() => goToDetail(item.slug)}>
                                                Book Now
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </PanelBox>
                        </div>
                    )
                })

                content = (
                    <Slider {...settings}>
                        {itemList}
                    </Slider>
                );
            }
        }
    }

    return (
		<div className={classes.Wrapper}>
			<div className={classes.Container}>
				<div className={classes.Title}>Popular Activities</div>
				<div className={classes.SubTitle}>Explore inpiration things to do with exclusive deals</div>
				<div className={classes.List}>
					<div className={classes.ItemWrapper}>
                        {content}
					</div>
					<div className={classes.Clear}></div>
				</div>
			</div>

		</div>
    )
}

const mapStateToProps = (state) => {
    return {
        admissionPackages: state.AdmissionAvailabilityReducer.admissionPackages,
        isFetchAdmission: state.AdmissionAvailabilityReducer.isFetchAdmission,
        isErrorFetchAdmission: state.AdmissionAvailabilityReducer.isErrorFetchAdmission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initAdmissionPackages: () => dispatch(AvailabilityAction.initAdmissionPackages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(activityFeatured));
