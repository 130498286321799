import React, { useEffect } from 'react';
import classes from './Itinerary.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import Header from '../../../Shared/Header/Header';
import ItineraryList from '../../../Shared/ItineraryList/ItineraryList';

const Itinerary = props => {

    useEffect(() => {
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [props.show]);


    const headTitle = <div className={classes.HeadTitle}>
        <div>Itinerary</div>
        <div><a href={props.downloadFile} className={classes.Download} target="blank">Download PDF</a></div>
    </div>;

    return (
        <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={270}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close 
            }}>
            <div className={classes.Wrapper}>
                <div className={classes.Header}>
                    <Header 
                        classes={['Shadow']}
                        leftIcon="close"
                        leftClicked={props.hidePopupClicked}
                        content={headTitle} />
                </div>
                <div className="u-pt-60">
                    <ItineraryList iteneraryList={props.iteneraryList}/>
                </div>
            </div>
        </CSSTransition>
    );
}

export default Itinerary;