import React from 'react';
import classes from './RightContent.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../UI/Icon/Icon';
import { toCapitalize, formatDisplayDate, formatDisplayTime } from '../../../../../helper/GlobalFunc';
import ImgThumb from '../../../../UI/ImgThumb/ImgThumb';
import { DEFAULT_IMG } from '../../../../../constant/Content';
import CurrencyFormat from 'react-currency-format';

const rightContent = (props) => {

    let content = null;
    content = (
        <React.Fragment>
            <li className={classes.Item}>
                <div className={classes.Wrapper}>
                    <div className={classes.Info}>
                        <div className={classes.Title}>
                            {toCapitalize(props.virtualTourDetail.name)}
                        </div>
                    </div>
                    <ImgThumb onError={DEFAULT_IMG} src={props.virtualTourDetail.imageSource} />
                </div>
            </li>
            <li className={classes.Item}>
                <div className={classes.Body}>
                    <div className={classes.Date}>
                        <div className={classes.Label}>Date and Time</div>
                        <div className={classes.Detail}>
                            <div className={classes.Icon}>
                                <Icon name="ico-calendar" fill="#666666" stroke="none" width={20} />
                            </div>
                            {formatDisplayDate(props.virtualTourDetail.liveDate)} {'  ' + formatDisplayTime(props.virtualTourDetail.liveDate)}
                        </div>
                    </div>
                    <div className={classes.Date}>
                        <div className={classes.Label}>Duration</div>
                        <div className={classes.Detail}>
                            <div className={classes.Icon}>
                                <Icon name="ico-time" fill="#666666" stroke="none" width={20} />
                            </div>
                            {props.virtualTourDetail.duration + ' minutes'}
                        </div>
                    </div>
                </div>
            </li>
            <li className={classes.Item}>
                <div className={classes.PriceWrap}>
                    <div className={classes.Label}>Price</div>
                    <div className={classes.Detail}>
                        <span className={classes.Price}>
                            <CurrencyFormat value={props.virtualTourDetail.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                        </span>
                    </div>
                </div>
            </li>
        </React.Fragment>
    );

    return (
        <PanelBox isOverfloe={false}>
            <div className={classes.Wrapper}>
                <ul className={classes.SectionList}>
                    {content}
                </ul>
            </div>
        </PanelBox>
    )
}

export default rightContent
