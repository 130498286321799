import React, { useState, useEffect } from 'react';
import classes from './BookingContainer.module.scss';
import MetaTag from '../../../../helper/MetaTag';
import BookingHeader from '../../../../components/Desktop/Shared/BookingHeader/BookingHeader';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';
import LeftContent from '../../../../components/Desktop/VirtualTour/BookingForm/LeftContent/LeftContent';
import RightContent from '../../../../components/Desktop/VirtualTour/BookingForm/RightContent/RightContent';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import ContentLoader from 'react-content-loader';
import MessageBox from './MessageBox/MessageBox';

import { connect } from 'react-redux';
import * as BookingAction from '../../../../redux/VirtualTour/Booking/VirtualTourBookingAction';

const bookingContainer = (props) => {
    const [isContinueConfirm, setIsContinueConfirm] = useState(false);

    const goBack = () => {
        props.setIsSuccessSubmit(false);
        props.history.push('/');
    }

    useEffect(() => {
        let slug = props.match.params.slug;
        props.fetchVirtualTourDetail(slug);
    }, [])
    
    useEffect(() => {
        if (props.isSuccessSubmit) {
            setIsContinueConfirm(false)
        }
    }, [props.isSuccessSubmit])

    const submitInquryHandler = () => {
		if (props.isValidForm) {
			setIsContinueConfirm(true);
		} else {
            props.formChecking();
		}
    }

	const submitData = () => {
        let data = {
            virtualTourID: props.virtualTourDetail.ID,
            title: props.formData.title.value,
            firstName: props.formData.firstName.value,
            lastName: props.formData.lastName.value,
            phoneNumber: props.formData.dialCode.value + props.formData.phone.value,
            email: props.formData.email.value,
            zoomID: '-',
            question: '-'
        }
        props.formSubmited(data)
    };

    let content = null;
    let step = 1;

    let packageInfo = '';
    if (props.isFetchVirtualTourDetail) {
        packageInfo = <Loader />;
    } else {
        if (!props.isErrorFetchVirtualTourDetail) {
            if (props.virtualTourDetail) {
                packageInfo = <RightContent virtualTourDetail={props.virtualTourDetail}/>
            }
        }
    }

    if (props.isSuccessSubmit) {
        content = <MessageBox clicked={() => goBack()}/>
    } else {
        content = (
            <div className={classes.Container}>
                <div className={classes.Header}>
                    <div className={classes.Title}>Virtual Tour Booking</div>
                    <div className={classes.SubTitle}>Fill in your details and review your booking</div>
                </div>
                <div className={classes.Body}>
                        <div className={classes.LeftContent}>
                            <LeftContent submitInquryHandler={submitInquryHandler}/>
                        </div>
                        <div className={classes.RightContent}>
                            {packageInfo}
                        </div>
                    </div>
            </div>
        )
    }

    return (
        <div className="bg-white-dark">
            <MetaTag 
                title="Virtual Tour Booking"/>
            <Confirm
                show={isContinueConfirm}
                title="Are You Sure?"
                desc="Make sure your data is correct."
                cancelClicked={() => setIsContinueConfirm(false)}
                isConfirmLoading={props.isSubmitBookLoading}
                cancelLabel="No, check again"
                confirmLabel="Yes, continue"
                confirmClicked={submitData}/>
                <div className={classes.Wrapper}>
                    <BookingHeader step={step}/>
                    {content}
                </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        formData: state.VirtualTourBookingReducer.formData,
        isValidForm: state.VirtualTourBookingReducer.isValidForm,
        virtualTourDetail: state.VirtualTourDetailReducer.virtualTourDetail,
        isSuccessSubmit: state.VirtualTourBookingReducer.isSuccessSubmit,
        isLoadingSubmitForm: state.VirtualTourBookingReducer.isLoadingSubmitForm,
        isFetchVirtualTourDetail: state.VirtualTourBookingReducer.isFetchVirtualTourDetail,
        isErrorFetchVirtualTourDetail: state.VirtualTourBookingReducer.isErrorFetchVirtualTourDetail,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChanged: (identifier, value) => dispatch(BookingAction.inputChanged(identifier, value)),
        formChecking: (identifier, value) => dispatch(BookingAction.formChecking(identifier, value)),
        formSubmited: (data) => dispatch(BookingAction.formSubmited(data)),
        fetchVirtualTourDetail: (slug) => dispatch(BookingAction.fetchVirtualTourDetail(slug)),
        setIsSuccessSubmit: (value) => dispatch(BookingAction.setIsSuccessSubmit(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(bookingContainer);

const Loader = () => {
    return (
        <PanelBox>
            <ContentLoader
                height={370}
                speed={1}
                primaryColor={'#e6e6e6'}>
                <rect x="300" y="20" rx="37" ry="37" width="78" height="78" />
                <rect x="24" y="35" rx="5" ry="5" width="230" height="16" />
                <rect x="24" y="65" rx="5" ry="5" width="200" height="16" />

                <rect x="24" y="150" rx="5" ry="5" width="120" height="14" />
                <rect x="24" y="175" rx="5" ry="5" width="200" height="16" />

                <rect x="24" y="220" rx="5" ry="5" width="120" height="14" />
                <rect x="24" y="245" rx="5" ry="5" width="200" height="16" />

                <rect x="24" y="320" rx="5" ry="5" width="100" height="16" />
                <rect x="250" y="320" rx="5" ry="5" width="120" height="25" />
            </ContentLoader>
        </PanelBox>
    )
}