import React from 'react';
import classes from './DestinationItem.module.scss';

const destinationItem = (props) => {
    
    return (
        <div className={classes.Wrapper}>
            <img src={props.destination.image} className={classes.Img} alt={props.destination.name} />
            <div className={classes.Description}>
                <div className={classes.Title}>{props.destination.name}</div>
                <div className={classes.Subtitle}>{props.destination.total} Packages</div>
            </div>
        </div>
    );
}

export default destinationItem;