import React, { useEffect } from 'react';
import classes from './PriceDetail.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import Backdrop from '../../../../../UI/Backdrop/Backdrop';
import CurrencyFormat from 'react-currency-format';
import Button from '../../../../../UI/Button/Button';
import { toCapitalize } from '../../../../../../helper/GlobalFunc';

const priceDetail = (props) => {
    useEffect(() => {
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [props.show]);

    const totalPrice = (prices) => {
        let total = 0;
        for (const key in prices) {
            total += prices[key].price * prices[key].qty
        }
        return (total === 0) ? props.admissionDetail.priceStartFrom : total;
    }

    let visitors = null;
    if (props.visitors) {
        let visitorsData = props.visitors.filter((data) => data.qty > 0);
        visitors = visitorsData.map((item, index) => {
            return (
                <div className={classes.Item} key={index}>
                    <div>{toCapitalize(item.type)} x {item.qty}</div>
                    <div>
                        <CurrencyFormat value={item.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                    </div>
                </div>
            )
        })
    }
    
    return (
        <div>
            <Backdrop showBackdrop={props.show} backdropClicked={props.hideClicked}/>
            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={200}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Closed 
                }}>
                    <div className={classes.Wrapper}>
                        <div className={classes.PriceHead}>
                            <div className={classes.PriceWrapper} onClick={() => props.hideClicked()}>
                                <div className={classes.Label}>Price</div>
                                <div className={classes.Price}>
                                    <CurrencyFormat value={(props.visitors === null) ? 0 : totalPrice(props.visitors)} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                                </div>
                            </div>
                            <div className={classes.Button}>
                                <Button 
                                    disabled={props.disableBtn}
                                    classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                                    clicked={props.goToBookingClicked}>
                                    Continue
                                </Button>
                            </div>
                        </div>
                        <div className={classes.PriceDetail}>
                            <div className={classes.List}>
                                {visitors}
                            </div>
                            <div className={classes.TotalPrice}>
                                <div className={classes.Label}>Total Price</div>
                                <div>
                                    <CurrencyFormat value={(props.visitors === null) ? 0 : totalPrice(props.visitors)} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
        </div>
    )
}

export default priceDetail;
