import React from 'react';
import classes from './Benefit.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import bgLogin from '../../../../../assets/images/bg-login.png';

const benefit = () => {
    let benefits = [{
        icon : 'ico-card',
        text : 'Fast & Secure Payments Lorem Ipsum is simply dummy' 
    }, {
        icon : 'ico-autofill',
        text : 'Autofill your details Lorem Ipsum is simply' 
    }, {
        icon : 'ico-doc',
        text : 'Manage your bookings Lorem Ipsum is simply dummy the printing' 
    }, {
        icon : 'ico-promo',
        text : 'Amazing deals on Tour, Flight & Hotel Lorem Ipsum is simply dummy' 
    }];

    benefits = benefits.map( (benefit, index) => {
        return (
            <li key={index} className={classes.Item}>
                <div className={classes.Icon}>
                    <Icon name={benefit.icon} stroke="none" fill="#1A6FA3" />
                </div>
                <div className={classes.Text}>{benefit.text}</div>
            </li>
        );
    });
    return (
        <div className={classes.Left}>
            <div className={classes.Benefit}>
                <div className={classes.BenefitTitle}>Benefits of  Antavaya member</div>
                <ul className={classes.BenefitList}>
                    {benefits}
                </ul>
            </div>
            <img src={bgLogin} className={classes.BgLogin} alt="bg"/>
        </div>
    );
}

export default benefit;