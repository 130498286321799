import React from 'react';
import classes from './PriceDetail.module.scss';
import { currencyFormat, toCapitalize } from '../../../../../../helper/GlobalFunc';

function priceDetail(props) {
    const list = props.prices.map( (price, index) => {
        let qty = null;
        if (price.quantity > 1) {
            qty = ` x ${price.quantity}`; 
        }
        return (
            <li key={index} className={classes.Item}>
                <div className={classes.Label}>{toCapitalize(price.name)} {qty}</div>
                <div className={classes.Price}>{currencyFormat(price.total)}</div>
            </li>
        );
    });

    return (
        <ul className={classes.List}>
            {list}
        </ul>
    );
}

export default priceDetail;