import React from 'react';
import ContentLoader from 'react-content-loader';

const highlightLoader = () => {
    return (
        <ContentLoader
            height={180}
            speed={1}
            primaryColor={'#e6e6e6'}
        >
            <rect x="20" y="15" rx="3" ry="3" width="100" height="15" />
            <rect x="20" y="50" rx="3" ry="3" width="190" height="12" />
            <rect x="20" y="80" rx="3" ry="3" width="175" height="12" />
            <rect x="20" y="110" rx="3" ry="3" width="190" height="12" />
            <rect x="20" y="150" rx="3" ry="3" width="360" height="25" />
        </ContentLoader>
    )
}

export default highlightLoader;
