import React, { useState, useEffect } from 'react';
import classes from './DetailContainer.module.scss';
import MetaTag from '../../../../helper/MetaTag';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import LeftContent from '../../../../components/Desktop/Admission/ContentDetail/LeftContent/LeftContent';
import RightContent from '../../../../components/Desktop/Admission/ContentDetail/RightContent/RightContent';
import { textLimit } from '../../../../helper/GlobalFunc';

import { connect } from 'react-redux';
import * as DetailAction from '../../../../redux/Admission/Detail/DetailAction';

const visitorsDataInit = [
    {
        type: 'all-price',
        active: false,
        price: 0,
        qty:0
    },
    {
        type: 'adult',
        active: false,
        price: 0,
        qty:0
    },
    {
        type: 'child',
        active: false,
        price: 0,
        qty:0
    },
    {
        type: 'senior',
        active: false,
        price: 0,
        qty:0
    }
]

const detailContainer = (props) => {
    const [ticket, setTicket] = useState(null);
    const [visitors, setVisitors] = useState(null);
    const [updater, setUpdater] = useState(false);
    const [selectedDate, setselectedDate] = useState(null);

    useEffect(() => {
        props.onInitAdmission(props.match.params.slug)
    }, []);

    let breadcrumbLink = [];
    let title = 'Admission Detail';
    if (props.admissionDetail) {
        title = props.admissionDetail.tourAdmissionName
    }
    if (true) {
        title = textLimit(title, 50);
        breadcrumbLink = [{
            name: 'Home',
            url: '/'
        },{
            name: 'Admission',
            url: '/admission/availability'
        }, {
            name: title,
            url: '/admission/availability'
        }];
    }

    const goToBooking = (slug) => {
        if (visitors && ticket !== null && selectedDate) {
            setLocalStorageHandler()
            props.history.push('/admission/booking/' + slug)
        }
    }

    const onSetTicketHandler = (id, priceArray) => {
        setTicket(id)
        onSetVisitorHandler(priceArray)
    }

    const onSetVisitorHandler = (priceArray) => {
        const priceCp = [...priceArray];
        const visitorsCp = [...visitorsDataInit];

        for (const keyVisitors in visitorsCp) {
            for (const key in priceCp) {
                if (priceCp[key].type === visitorsCp[keyVisitors].type) {
                    priceCp[key].active = true;
                    priceCp[key].qty = 0;
                    if (priceCp[key].type === 'adult' || priceCp[key].type === 'all-price') {
                        priceCp[key].qty = 1;
                    }
                }
            }
        }
        setVisitors(priceCp);
    }

    const onGuestAddedHandler = (type) => {
        let visitorsCp = null;
        visitorsCp = [...visitors];
        for (const key in visitorsCp) {
            if (visitorsCp[key].type === type) {
                visitorsCp[key].qty += 1;
            }
        }
        setUpdater(!updater)
        setVisitors(visitorsCp)
    }

    const onGuestRemoveHandler = (type) => {
        let visitorsCp = null;
        visitorsCp = [...visitors];
        for (const key in visitorsCp) {
            if (visitorsCp[key].type === type) {
                visitorsCp[key].qty -= 1;
            }
        }
        setUpdater(!updater)
        setVisitors(visitorsCp)

    }

    const setLocalStorageHandler = () => {
        localStorage.setItem('visitors', JSON.stringify(visitors));
    }

    return (
        <div className="bg-white-dark min-width">
            <MetaTag title={title}/>
            <Header />
            <div className={classes.Content}>
                <div className={classes.Container}>
                    <div className={classes.LeftContent}>
                        <LeftContent
                            breadcrumbLink={breadcrumbLink}
                            isFetchAdmission={props.isFetchAdmission}
                            isErrorFetchAdmission={props.isErrorFetchAdmission}
                            admissionDetail={props.admissionDetail}
                        />
                    </div>
                    <div className={classes.RightContent}>
                        <RightContent 
                            ticket={ticket}
                            visitorsData={visitors}
                            updater={updater}
                            activeButton={(visitors && ticket !== null && selectedDate) ? false : true}
                            setselectedDateHandler={(date) => setselectedDate(date)}
                            onGuestAddedClicked={(type) => onGuestAddedHandler(type)}
                            onGuestRemoveClicked={(type) => onGuestRemoveHandler(type)}
                            goToBooking={(slug) => goToBooking(slug)}
                            onSetTicketClicked={(id, priceArray) => onSetTicketHandler(id, priceArray)}
                            isFetchAdmission={props.isFetchAdmission}
                            isErrorFetchAdmission={props.isErrorFetchAdmission}
                            admissionDetail={props.admissionDetail}
                        /> 
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        admissionDetail: state.AdmissionDetailReducer.admissionDetail,
        isFetchAdmission: state.AdmissionDetailReducer.isFetchAdmissionDetail,
        isErrorFetchAdmission: state.AdmissionDetailReducer.isErrorFetchAdmissionDetail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onInitAdmission: (slug) => dispatch(DetailAction.initAdmissionDetail(slug)),
        onInitVisitors: (data) => dispatch(DetailAction.initVisitors(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(detailContainer);
