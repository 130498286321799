import React, { Component } from 'react';
import classes from './DayPicker.module.scss';
import ReactDayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './DatePickerOveride.scss';

class DayPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }
    
    onDayClickHandler = day => {
        let dates = {from: day};
        if (this.props.isRange) {
            dates = DateUtils.addDayToRange(day, { from: this.props.from, to: this.props.to});
        }
        this.props.dateClicked(dates);
    }

    onMonthChangeHandler = date => {
        this.props.activeMonthChange(date);
    }

    render() {
        const today = new Date();

        let holidayList = [];
        if (this.props.holidayList) {
            holidayList = this.props.holidayList.map( date => {
                return new Date(date);
            });
        }

        const from = this.props.from;
        const to = this.props.to;
        const modifiers = { 
            start: from, 
            end: to,
            highlighted: holidayList,
        };

        let fromMonth = new Date();
        let toMonth = new Date();
        toMonth.setMonth(fromMonth.getMonth() + 24);

        let reactDayPicker = (
            <ReactDayPicker
                fromMonth={fromMonth}
                toMonth={toMonth}
                disabledDays={{ before: today }}
                selectedDays={from}
                modifiers={modifiers}
                onDayClick={this.onDayClickHandler}
                onMonthChange={this.onMonthChangeHandler}/>
        );

        if (this.props.isRange) {
            reactDayPicker = (
                <ReactDayPicker
                    className="Selectable"
                    fromMonth={fromMonth}
                    toMonth={toMonth}
                    disabledDays={{ before: today }}
                    numberOfMonths={1}
                    selectedDays={[from, { from, to }]}
                    modifiers={modifiers}
                    onDayClick={this.onDayClickHandler}
                    onMonthChange={this.onMonthChangeHandler}/>
            );
        }

        return (
            <div className={classes.Wrapper}>
                {reactDayPicker}
            </div>
        );
    }
}

export default DayPicker;