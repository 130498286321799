import React, { useState, useEffect } from 'react';
import classes from './Career.module.scss';
import JobList from './JobList/JobList';
import SearchForm from './SearchForm/SearchForm';
import { connect } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';
import * as careerAction from '../../../../redux/NonProducts/Career/CareerAction';

const career = props => {
    const [positionValue, setPositionValue] = useState('');
    const [departmentValue, setDepartmentValue] = useState('All Department');
    const [locationValue, setLocationValue] = useState('All Location');
    const [departmentList, setDepartmentList] = useState([]);
    const [locationList, setLocationList] = useState([]);

    let searchForm = React.createRef();
    
    useEffect(() => {
        props.onInitCareerList();
    }, [])

    useEffect(() => {
        if(props.careerList) {
            setDepartmentAndLocation(props.careerList);
        }
    }, [props.careerList])

    const goToTop = () => {
        const configScroll = { 
            offset: 0, 
            align: 'top', 
            duration: 700
        };
        scrollToComponent(searchForm, configScroll);
    }

    const setPositionHandler = event => {
        setPositionValue(event.target.value);
        goToTop();
    }

    const goToDetailPageHandler = (slug) => {
        props.history.push('/career/' + slug);
    }

    const setDepartmentAndLocation = (careerList) => {
        let departments = [...new Set(careerList.map(item => item.department ))].map(department => {
            return {
                value: department,
                name: department
            }
        });
        departments.unshift({value: 'All Department', name: 'All Department'});
        let locations = [...new Set(careerList.map(item => item.location))].map(location => {
            return {
                value: location,
                name: location
            }
        });
        locations.unshift({value: 'All Location', name: 'All Location'});

        setDepartmentList(departments);
        setLocationList(locations);
    }

    return (
        <div>
            <div className={classes.Wrapper}>
                <div className={classes.Container}>
                    <div className="u-mt--35 u-mb-60" onClick={goToTop} ref={(section) => { searchForm = section; }}>
                        <SearchForm 
                            positionValue={positionValue}
                            departmentValue={departmentValue}
                            locationValue={locationValue}
                            departmentList={departmentList}
                            locationList={locationList}
                            setPositionChanged={(event) => setPositionHandler(event)}
                            setDepartmentSelected={(val) => setDepartmentValue(val)}
                            setLocationSelected={(val) => setLocationValue(val)} />
                    </div>
                    <div className={classes.Title}>Career Opportunities</div>
                    <div className={classes.SubTitle}>Browse hundreds of job opportunities to be a part of something magical.</div>
                    <JobList 
                        careerList={props.careerList}
                        positionValue={positionValue}
                        departmentValue={departmentValue}
                        locationValue={locationValue}
                        goToDetailPageClicked={(slug) => goToDetailPageHandler(slug)}/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        careerList : state.CareerReducer.careerList,
        isErrorFetchCareerList : state.CareerReducer.isErrorFetchCareerList
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onInitCareerList: () => dispatch(careerAction.initCareerList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(career);