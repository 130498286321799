import { axiosAuth } from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';
import * as resHandler from '../../../helper/ResHandler';

export const setInitState = () => {
    return {
        type: 'SET_MY_BOOKING_INIT_STATE'
    }
}

export const setMyBookingList = (listTour) => {
    return {
        type: 'SET_MY_BOOKING_LIST',
        payload: {
            data: listTour
        }
    }
}

export const setMyBookingDetail = (data) => {
    return {
        type: 'SET_MY_BOOKING_DETAIL',
        payload: { data }
    }
}

export const setIsFetch = (value) => {
    return {
        type: 'SET_IS_FETCH_MY_BOOKING',
        payload: { value }
    }
}

export const setIsErrorFetch = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_MY_BOOKING',
        payload: { value }
    }
}

export const setMyBookingId = (id) => {
    return {
        type: 'SET_MY_BOOKING_ID',
        payload: { id }
    }
}
export const setIsFetchBookingDetail = (value) => {
    return {
        type: 'SET_IS_FETCH_MY_BOOKING_DETAIL',
        payload: { value }
    }
}
export const setIsErrorFetchBookingDetail = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_MY_BOOKING_DETAIL',
        payload: { value }
    }
}

export const initBookingList = () => {
    let payload = {
        page: 1,
        perPage: 10
    }
    return dispatch => {
        axiosAuth.post(ANTAVAYA_API + 'member/tour-by-member', payload)
            .then((response) => {
                dispatch(setMyBookingList(response.data.data));
                dispatch(setIsFetch(false));
            })
            .catch((error) => {
                if (error) {
                    dispatch(setIsFetch(false));
                    dispatch(setIsErrorFetch(true));
                    if (error.response) {
                        if (error.response.data.code === 401) {
                            resHandler.unauthorize();
                        }
                    }
                }
            })
    }
}

export const initBookingDetail = (data) => {
    return dispatch => {
        axiosAuth.post(ANTAVAYA_API + 'member/tour-detail', data)
            .then((response) => {
                let data = injectPdfUrl(response.data.data, response.data.urlPdf);
                dispatch(setMyBookingDetail(data));
                dispatch(setIsFetchBookingDetail(false));
            })
            .catch((error) => {
                if (error){
                    dispatch(setIsErrorFetchBookingDetail(true));
                    dispatch(setIsFetchBookingDetail(false));
                    if (error.response) {
                        if (error.response.data.code === 401) {
                            resHandler.unauthorize();
                        }
                    }
                }
            })
    }
}

const injectPdfUrl = (data, url) => {
    return {...data, ...{pdfUrl: (url) ? ANTAVAYA_API+ 'pdf/tour-detail-pdf/' + url: ''}}
}