import React from 'react';
import classes from './PackageInfo.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import { toCapitalize, formatDisplayDate, currencyFormat } from '../../../../../helper/GlobalFunc';
import Icon from '../../../../UI/Icon/Icon';
import ImgThumb from '../../../../UI/ImgThumb/ImgThumb';

const packageInfo = props => {
    
    let content = null;
    if (props.data) {
        content = (
            <div>
                <div className={classes.Header} >
                    <div className="u-mr-16">
                        <ImgThumb src={props.data.imageSource} size="small" />
                    </div>
                    <div className={classes.Title}>{toCapitalize(props.data.name)}</div>
                </div>
                <div className={classes.Body}>
                    <div className={classes.Item}>
                        <div className={classes.Label}>Booking Period</div>
                        <div className={classes.Date}>
                            <div className={classes.Icon}>
                                <Icon name="ico-calendar" stroke="none" fill="#818589" width={22}/> 
                            </div>
                            {formatDisplayDate(props.data.bookingPeriodStartDate)} - {formatDisplayDate(props.data.bookingPeriodEndDate)}
                        </div>
                    </div>
                    <div className={classes.Item}>
                        <div className={classes.Label}>Stay Period</div>
                        <div className={classes.Date}>
                            <div className={classes.Icon}>
                                <Icon name="ico-calendar" stroke="none" fill="#818589" width={22}/> 
                            </div>
                            {formatDisplayDate(props.data.stayPeriodStartDate)} - {formatDisplayDate(props.data.stayPeriodEndDate)}
                        </div>
                    </div>
                </div>
                <div className={classes.TotalPrice}>
                    <div >Price</div>
                    <div className={classes.Price}>{currencyFormat(props.data.hotelPrice)}<span className={classes.Label}>/{props.data.minNight} nights stay</span> </div>
                </div>
            </div>
        )
    }
    
    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                {content}
            </div>
        </PanelBox>
    );
}

export default packageInfo;