import React, { useState, useEffect } from 'react';
import classes from './DetailContainer.module.scss';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import BannerDetail from '../../../../components/Desktop/Tour/BannerDetail/BannerDetail';
import scrollToComponent from 'react-scroll-to-component';
import LeftContent from '../../../../components/Desktop/Tour/ContentDetail/LeftContent/LeftContent';
import RightContent from '../../../../components/Desktop/Tour/ContentDetail/RightContent/RightContent';
import UnavailableTour from '../../../../components/UI/UnavailableTour/UnavailableTour';
import { textLimit } from '../../../../helper/GlobalFunc';
import { connect } from 'react-redux';
import * as availabilityAction from '../../../../redux/Tour/Availability/AvailabilityAction';
import * as detailAction from '../../../../redux/Tour/Detail/DetailAction';
import * as tourBooking from '../../../../redux/Tour/Booking/BookingAction';
import MetaTag from '../../../../helper/MetaTag';

const tourDetailContainer = props => {
    const [schedule, setSchedule] = useState(0);
    const [showTourEmpty, setShowTourEmpty] = useState(false);
    
    let photo = React.createRef();

    useEffect(() => {
        let slug = props.match.params.slug;
        props.onInitDetailTour(slug);
        // props.fetchHotelDetail();
        scrollTop();
    }, [])

    useEffect(() => {
        if(props.tourDetail) {
            setSchedule(props.tourDetail.schedule[0].tourScheduleID)
        }
    }, [props.tourDetail])
    
    useEffect(() => {
        if(props.tourEmpty) {
            setShowTourEmpty(props.tourEmpty);
        }
    }, [props.tourEmpty])

    const setElement = (element) => {
        photo = element;
    }

    const goToPhotoSectionHandler = () => {
        const configScroll = { 
            offset: 0, 
            align: 'top', 
            duration: 1500
        };
        scrollToComponent(photo, configScroll);
    }

    const scrollTop = () =>{
        window.scrollTo(0, 0);
    }

    const submitBookingHandler = () => {
        let rooms = [];

        // eslint-disable-next-line
        let loopRoom = props.roomList.map((room) => {
            let payload = {
                adult: room.adult.length,
                child: room.child.length,
                isExtraBed: room.isExtrabed
            }
            rooms.push(payload);
        })

        let dataTour = {
            tourScheduleId: schedule, 
            tourInventoryCode: props.tourDetail.inventoryCode, 
            room: rooms
        }

        props.onSubmitTour(dataTour, props.history)
    }

    const backToHome = () => {
        props.history.push('/');
        document.body.style.overflow = 'auto';
    }

    let breadcrumbLink = [];
    let title = 'Tour Detail';
    if (props.tourDetail) {
        title = textLimit(props.tourDetail.inventoryTitle, 50);
        breadcrumbLink = [{
            name: 'Home',
            url: '/'
        },{
            name: 'Tour Packages',
            url: '/tour/availability'
        }, {
            name: title,
            url: '/tour/availability'
        }];
    }

    let modal = null;
    if (showTourEmpty) {
        modal = (
            <UnavailableTour
                showUnavailableTour={showTourEmpty}
                title="Tour Empty"
                description="Sorry, tour is unavailable. Please see other destinations."
                buttonLabel="Back to Home"
                redirect={backToHome}/>
        )
    }

    return (
        <div className="bg-white-dark min-width">
            {modal}
            <MetaTag 
                title={title}/>
            <Header />
            <BannerDetail
                isLoading={props.isLoading}
                goToPhotoSectionClicked={goToPhotoSectionHandler}
                tourPackage={props.tourDetail}/>
            <div className={classes.Content}>
                <div className={classes.Container}>
                    <div className={classes.LeftContent}>
                        <LeftContent
                            setElement={setElement}
                            breadcrumbLink={breadcrumbLink}
                            tourPackage={props.tourDetail} />
                    </div>
                    <div className={classes.RightContent}>
                        <RightContent 
                            isLoading={props.isLoading}
                            schedule={props.scheduleId}
                            priceStartFrom={props.priceStartFrom}
                            setScheduleChanged={(val) => setSchedule(val)}
                            tourPackage={props.tourDetail}
                            submitBookingClicked={submitBookingHandler}
                            setItinerary={
                                (scheduleId) => props.setItineraryHandler(scheduleId)
                            }/>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        roomList: state.TourDetailReducer.rooms,
        tourDetail: state.TourDetailReducer.tourDetail,
        tourEmpty: state.TourDetailReducer.tourEmpty,
        isLoading: state.TourDetailReducer.isLoadingTourDetail,
        scheduleId: state.TourDetailReducer.scheduleId,
        priceStartFrom: state.TourDetailReducer.priceStartFrom
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitTourPackages : () => dispatch(availabilityAction.initTourPackages()),
        onInitDetailTour : (tourId) => dispatch(detailAction.initDetailTour(tourId)),
        setItineraryHandler: (scheduleId) => dispatch(detailAction.scheduleChanged(scheduleId)),
        onSubmitTour: (dataTour, history) => dispatch(tourBooking.submitTour(dataTour, history)),
        // fetchHotelDetail: () => dispatch(hotelBookingAction.fetchHotelDetail())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(tourDetailContainer);