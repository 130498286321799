import React, { Component } from 'react';
import classes from './RedirectContainer.module.scss';
import successIcon from '../../../../assets/images/pergi-logo.jpg';
import { PERGI_HOST } from '../../../../constant/Api';

class RedirectContainer extends Component {
    state = {  }

    componentDidMount() {
        const url = window.location.search.substring(5);
        this.captchaInterval = setInterval(() => {
            clearInterval(this.captchaInterval);
            window.location = PERGI_HOST.concat(url);
        }, 1000);    
    }

    render() {
        return (
            <div className={classes.Wrapper}>
                <div className={classes.Alert}>
                    <div className={classes.Icon}>
                        <img src={successIcon} alt="Success" />
                    </div>
                    <div className={classes.Title}>Directed to Pergi.com site</div>
                    <div className={classes.Description}>In a few seconds you will be directed to the Pergi.com site</div>
                </div>
            </div>
        );
    }
}

export default RedirectContainer;