import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import * as authAction from '../../../../redux/Shared/Auth/AuthAction';

function activationContainer (props) {
    useEffect(() => {
        props.activationHandler(props.match.params.activationCode)
    }, []);

    useEffect(() => {
        if (localStorage.getItem('sessionKey') && localStorage.getItem('userProfile')) {
            props.history.push('/');
        }
    }, [props.isLogin])

    return (
        <div>
            <h1>Loading...</h1>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogin: state.AuthReducer.isLogin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        activationHandler: (activationCode) => dispatch(authAction.activationAccount(activationCode))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(activationContainer);
