import React from 'react';
import classes from './Filter.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import ElementFilter from './ElementFilter/ElementFilter';

const filter = (props) => {
    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                <div className={classes.Title}>
                    Filter
                    <div className={classes.Reset} onClick={props.resetFilterClicked}>Reset</div>
                </div>
                <ElementFilter 
                    title="Price" 
                    list={props.priceFilterList}
                    filterChanged={(event, index) => props.filterChanged(event, index, 'price')}
                    resetClicked={props.resetPriceFilterClicked} />
            </div>
        </PanelBox>
    )
}

export default filter;
