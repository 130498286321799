import React, { useState } from 'react';
import classes from './BannerAvailibility.module.scss';
import { IMAGE_URl } from '../../../../constant/ResourceUrl';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import BannerInput from '../../Shared/ThingsToDo/BannerInput/BannerInput';
import { withRouter } from 'react-router-dom';
import { toCapitalize } from '../../../../helper/GlobalFunc';

const BannerAvailibility = (props) => {
    const [showBackdrop, setShowBackdrop] = useState(false);

    // eslint-disable-next-line
    let searchForm = React.createRef();

    const showBackdropHandler = () => {
        setShowBackdrop(true);
    }

    const hideBackdropHandler = () => {
        setShowBackdrop(false);
    }

    const searchActivityClickedHandler = () => {
        props.history.push('/activity/availability');
    }

    const searchAdmissionClickedHandler = () => {
        props.history.push('/admission/availability');
    }

    let zIndex = {
        zIndex:  100
    };
    if (showBackdrop) {
        zIndex = {
            zIndex:  660
        };
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Background}>
                <img src={IMAGE_URl + 'tour-banner.jpg'} alt="promo" />
            </div>
            <Backdrop 
                showBackdrop={showBackdrop}
                backdropClicked={() => setShowBackdrop(false)} />
            <div className={classes.Container} style={zIndex}>
                <div className={classes.SearchForm}>
                    <div className={classes.Title}>{toCapitalize(props.match.url.split('/')[1])} Packages</div>
                    <div ref={(section) => { searchForm = section; }}>
                        <BannerInput
                            path={props.match.url.split('/')[1]}
                            backdropVisibility={showBackdrop}
                            hideBackdropClicked={hideBackdropHandler}
                            showBackdrop={showBackdropHandler}
                            searchActivityClicked={searchActivityClickedHandler}
                            searchAdmissionClicked={searchAdmissionClickedHandler}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(BannerAvailibility);
