import React from 'react';
import classes from './Item.module.scss';
import { DEFAULT_IMG } from '../../../../../../constant/Content';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../../UI/Icon/Icon';
import CurrencyFormat from 'react-currency-format';
import { withRouter } from 'react-router-dom';

const item = (props) => {
    return (
        <div className={classes.Wrapper} onClick={() => props.history.push('/admission/detail/' + props.item.slug)}>
            <PanelBox isOverflow={false}>
                <div className={classes.Panel}>
                    <div className={classes.Image}>
                        <img onError={DEFAULT_IMG} src={props.item.image} alt="hotel" />
                        <div className={classes.Location}>
                            <div className={classes.Detail}>
                                <Icon className={classes.Icon} name="ico-location" fill="#FFFFFF" stroke="none" width={15} />
                                <div className={classes.Label}>
                                    {props.item.location}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.PriceWrap}>
                        <div className={classes.Title}>{props.item.tourAdmissionName}</div>
                        <div>
                            <span className={classes.Price}>
                                <CurrencyFormat value={props.item.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            </span>
                            <span>/pax</span>
                        </div>
                    </div>
                </div>
            </PanelBox>
        </div>
    )
}

export default withRouter(item);
