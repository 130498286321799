import React, { useState } from 'react';
import classes from './ExcludeInclude.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import List from '../../../../../UI/List/List';

function excludeInclude(props) {
    const [menuActive, setMenuActive] = useState('Include');
    
    const setMenuActiveHandler = menuActive => {
        setMenuActive(menuActive);
    }

    let hightlightList = props.tourIncludeList;
    if (menuActive === 'Exclude') {
        hightlightList = props.tourExcludeList;
    }
    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                <ul className={classes.Tabmenu}>
                    <li className={(menuActive === 'Include') ? classes.Active: ''}
                        onClick={() => setMenuActiveHandler('Include')}>Include</li>
                    <li className={(menuActive === 'Exclude') ? classes.Active: ''}
                        onClick={() => setMenuActiveHandler('Exclude')}>Exclude</li>
                </ul>
                <List listColor={(menuActive === 'Include') ? 'green': 'red'} list={hightlightList}/>
            </div>
        </PanelBox>
    );
}

export default excludeInclude;