import React from 'react';
import Modal from '../../../../../UI/Modal/Modal';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import Button from '../../../../../UI/Button/Button';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../../redux/Shared/Member/MemberAction';

function passwordModal (props) {
    const changePasswordHandler = () => {
        let payload = {
            oldPassword: props.changePasswordData.oldPassword.value,
            newPassword: props.changePasswordData.newPassword.value,
            ipAddress: "127.0.0.1"
        }
        props.submitChangedPassword(payload)
    }
    return (
        <Modal 
            title="Change Password"
            show={props.showUpdatePasswordForm}
            closeClicked={props.showHideUpdatePasswordClicked}>
                <div className="u-mb-40">
                    <div className="u-mb-24">
                        <div className="label">Old Password</div>
                        <TextField
                            type="password"
                            placeholder="" 
                            value={props.changePasswordData.oldPassword.value}
                            changed={(event) => props.inputChangedPassword(event, 'oldPassword')}/>
                    </div>
                    <div className="u-mb-24">
                        <div className="label">New Password</div>
                        <TextField
                            type="password"
                            placeholder="" 
                            value={props.changePasswordData.newPassword.value}
                            changed={(event) => props.inputChangedPassword(event, 'newPassword')}/>
                    </div>
                    <div className="u-mb-24">
                        <div className="label">Confirm New Password</div>
                        <TextField
                            type="password"
                            placeholder="" 
                            value={props.changePasswordData.confirmPassword.value}
                            changed={(event) => props.inputChangedPassword(event, 'confirmPassword')}/>
                    </div>
                </div>
            
                <div className="u-display-flex">
                    <div className="u-flex-5 u-mr-8">
                        <Button 
                            classes={['BtnOutline', 'BtnMedium', 'BtnBlock']}
                            clicked={props.showHideUpdatePasswordClicked}>Cancel</Button>
                    </div>
                    <div className="u-flex-5 u-ml-8">
                        <Button 
                            disabled={!props.isValidForm}
                            classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                            isLoading={props.isSubmitLoading}
                            clicked={() => changePasswordHandler()}>Save</Button>
                    </div>
                </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        changePasswordData: state.MemberReducer.changePassword,
        isSubmitLoading: state.MemberReducer.isSubmitLoading,
        isValidForm: state.MemberReducer.isValidForm
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // logout : () => dispatch(authAction.logout())
        inputChangedPassword: (event, inputIdentifier) => dispatch(memberAction.inputChangePassword(event, inputIdentifier)),
        submitChangedPassword: (data) => dispatch(memberAction.submitChangedMember(data, 'passwordForm')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(passwordModal);