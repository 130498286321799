import React, { useState, useEffect } from 'react';
import classes from './AboutUsContainer.module.scss';
import Icon from '../../../../components/UI/Icon/Icon';
import BannerPage from '../../../../components/Mobile/NonProduct/BannerPage/BannerPage';
import { ABOUT_US, JOURNEY, VISI_MISI_LIST } from '../../../../constant/Content';
import ProductService from '../../../../components/Mobile/NonProduct/ProductService/ProductService';
import WhatsappLink from '../../../../components/Mobile/Shared/WhatsappLink/WhatsappLink';

const aboutUsContainer = props => {
    const [isSecondHeader, setIsSecondHeader] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [props])

    const handleScroll = () => {
        const offsetTop = window.scrollY;
        if (offsetTop > 140) {
            setIsSecondHeader(true);
        } else {
            setIsSecondHeader(false);
        }
    }

    const backClickHandler = () => {
        props.history.push('/');
    }

    let secondHeaderClasses = [classes.Header, classes.SecondNav];
    if (isSecondHeader) {
        secondHeaderClasses.push(classes.Show);
    }

    const intro = ABOUT_US.map( (content, index) => {
        return <p key={index}>{content}</p>;
    });

    const journeyList = JOURNEY.map((journey, index) => {
        return (
            <li key={index} className={classes.ItemRight}>
                <div className={classes.Item}>
                    <div className={classes.DotWrapper}>
                        <div className={classes.Dot}></div>
                    </div>
                    <div className={classes.JourneyContent}>
                        <div className={classes.Year}>{journey.year}</div>
                        <div>{journey.title}</div>
                    </div>
                </div>
            </li>
        );
    });

    const visiMisiList = VISI_MISI_LIST.map((item, index) => {
        return (
            <li key={index}>
                <div className={classes.Icon}>
                    <img src={item.icon} alt={item.title} />
                </div>
                <div className={classes.VisionContent}>
                    <div className={[classes.SubTitle, 'u-color-white'].join(' ')}>{item.title}</div>
                    <div className={classes.Description}>{item.description}</div>
                </div>
            </li>
        );
    });

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Header} onClick={backClickHandler}>
                <span className={classes.Icon}>
                    <Icon name="ico-arrow-left" width="20" stroke="none" fill="#ffffff" />
                </span>
            </div>

            <div className={secondHeaderClasses.join(' ')} onClick={backClickHandler}>
                <span className={classes.Icon}>
                    <Icon name="ico-arrow-left" width="20" stroke="none" fill="#202124" />
                </span>
                <div className={classes.Title}>About Us</div>
            </div>

            <div className={classes.Content}>
                <BannerPage title="About Us" image="https://media01.cdnpergi.com/antavaya/images/about-us-1.jpg" />
                <div className={classes.TextContent}>
                    <div className={classes.Description}>
                        {intro}
                    </div>
                </div>
                <div className={[classes.Section, 'bg-white-dark'].join(' ')}>
                    <div className={classes.Title}>Antavaya Journey</div>
                    <ul className={classes.Journey}>
                        {journeyList}
                    </ul>
                </div>
                <div className={[classes.Section, classes.BgBlue].join(' ')}>
                    <div className={[classes.Title, 'u-color-white'].join(' ')}>Antavaya Vision And Mission</div>
                    <ul className={classes.Vision}>
                        {visiMisiList}
                    </ul>
                </div>
                <ProductService />
                <WhatsappLink />
            </div>
        </div>
    );
}

export default aboutUsContainer;