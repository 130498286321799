import React from 'react';
import classes from './NoResult.module.scss';

const noResult = (props) => {
    return (
        <div className={classes.Wrapper}>
            There are no results for "<span>{props.keyword}</span>"
            <div className={classes.SubTitle}>Try other keywords and check your connection</div>
        </div>
    );
}
export default noResult;