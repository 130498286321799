import React from 'react'
import PanelBox from '../../../../../../../../components/UI/PanelBox/PanelBox'
import classes from './Participant.module.scss'

const Participant = () => {
  let participantsDetail = [
    { title: 'Adult', name: 'Mr. John Doe' },
    { title: 'Adult', name: 'Mrs. Jenny Doe' },
    { title: 'Adult', name: 'Mrs. Hannah Cole' },
    { title: 'Children', name: 'Mr. Jason Fields' }
  ]

  let participantDetail
  if (participantsDetail && participantsDetail.length > 0) {
    participantDetail = participantsDetail.map((item, index) => {
      return (
        <div key={index}>
          <ParticipantsDetail title={item.title} name={item.name} />
        </div>
      )
    })
  }

  return (
    <div className={classes.Panel}>
      <div className={classes.HeadTitle}>Participants</div>
      <PanelBox>
        <div className={classes.Card}>{participantDetail}</div>
      </PanelBox>
    </div>
  )
}
export default Participant

const ParticipantsDetail = props => {
  return (
    <div className={classes.Detail}>
      <div className={classes.Title}>{props.title}</div>
      <div className={classes.Value}>{props.name}</div>
    </div>
  )
}
