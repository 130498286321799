import React, { useEffect, useState } from 'react';
import BottomNavBar from '../../../../components/Mobile/Shared/BottomNavBar/BottomNavBar';
import classes from './UserContainer.module.scss';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import Icon from '../../../../components/UI/Icon/Icon';
import { COLOR } from '../../../../constant/Library';
import { connect } from 'react-redux';
import * as authAction from '../../../../redux/Shared/Auth/AuthAction';
import EmailVerify from '../../../../components/Mobile/NonProduct/EmailVerify/EmailVerify';
import VerifyCode from '../../../../components/Mobile/NonProduct/VerifyCode/VerifyCode';
import * as memberAction from '../../../../redux/Shared/Member/MemberAction';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import { formatDisplayPhone } from '../../../../helper/GlobalFunc';

const userContainer = props => {
    const [showEmailVerify, setShowEmailVerify] = useState(false);
    const [isLogoutConfirm, setIsLogoutConfirm] = useState(false);

    useEffect(() => {
        props.getDetailMemberHandler();
    }, [])

    useEffect(() => {
        if (props.successSendValidationEmail) {
            setShowEmailVerify(true)
        }
    }, [props.successSendValidationEmail])

    const confirmLogoutClickHandler = () => {
        setIsLogoutConfirm(false);
        props.logout();
    }

    const goToUrl = url => {
        props.history.push(url);
    }

    let fullname = '';
    let email = '';
    let displayPhone = '';
    let profileStatus = '';
    let emailBtn = '';
    let phoneBtn = '';
    let verifiedEmail = (
        <div className={classes.Status}>
            <Icon name="ico-check-circle-white" fill="#73C700" stroke="none"  />
            Verified
        </div>
    )
    let unverifiedEmail = (
        <div className={classes.Status}>
            <Icon name="ico-check-circle-white" fill={COLOR.greyLight} stroke="none"  />
            Not Verified
        </div>
    )
    let verifyEmailBtn = (
        <span
            className={classes.Action}
            onClick={() => props.sendActivationToEmail()}>Verify
        </span>
    )

    let verifyPhoneBtn = <span className={classes.Action}
            onClick={() => props.setShowVerifyPhoneForm(true)}>Verify</span>;

    if (props.member) {
        fullname = `${props.member.firstName} ${props.member.lastName}`;
        email = props.member.email;
        profileStatus = (props.member.isEmailVerified === 1 && props.member.isPhoneVerified === 1) ? verifiedEmail : unverifiedEmail;
        emailBtn = (props.member.isEmailVerified === 1) ? '' : verifyEmailBtn;
        phoneBtn = (props.member.isPhoneVerified === 1) ? '' : verifyPhoneBtn;
        displayPhone = formatDisplayPhone(`${props.member.countryCode}${props.member.phone}`)
    }

    return (
        <div>
            <Confirm 
                show={isLogoutConfirm}
                title="Are you sure?"
                desc="If you logout, You can’t use the features and benefits as an Antavaya member"
                cancelClicked={() => setIsLogoutConfirm(false)}
                confirmClicked={confirmLogoutClickHandler}/>

            <EmailVerify email={email} show={showEmailVerify} hideClicked={() => setShowEmailVerify(false)}/>
            <VerifyCode phone={displayPhone} show={props.showOtpForm} hideClicked={() => props.setShowVerifyPhoneForm(false)}/>
            
            <div className={classes.Head}>
				<Header classes={['ClBlack', 'BgRed']} 
						icoColor="white"
						align="center"
						content={<div className={classes.Title}>Account</div>}/>
			</div>
            
            <div className={classes.ContentWrapper}>
                <div className={classes.BgRed}></div>
                <div className={classes.Content}>
                    <div className={classes.Item}>
                        <PanelBox>
                            <div className={classes.Profile}>
                                <div className={classes.Title}>
                                    <div>{fullname}</div>
                                    <div className={classes.Action}
                                        onClick={() => goToUrl('user/profile')}>
                                        <Icon name="ico-edit" fill={COLOR.red} stroke="none"  />
                                    </div>
                                </div>
                                {profileStatus}
                            </div>
                        </PanelBox>
                    </div>
                    <div className={classes.Item}>
                        <PanelBox>
                            <div className={classes.Account}>
                                <div className={classes.AccountItem}>
                                    <div className={classes.Label}>E-mail</div>
                                    <div className={classes.AccountValue}>
                                        <span>{email}</span>
                                        {emailBtn}
                                    </div>
                                </div>
                                <div className={classes.AccountItem}>
                                    <div className={classes.Label}>Phone Number</div>
                                    <div className={classes.AccountValue}>
                                        <span>{displayPhone}</span>
                                        {phoneBtn}
                                    </div>
                                </div>
                            </div>
                        </PanelBox>
                    </div>
                    <div className={classes.Item}>
                        <PanelBox>
                            <div className={classes.Menu}>
                                {/* <div className={classes.MenuItem}
                                    onClick={() => goToUrl('user/my-card')}>
                                    <div className={classes.MenuItemLabel}>
                                        <span className={classes.MenuItemLabelIcon}><Icon name="ico-card" fill={COLOR.grey} stroke="none"  /></span>
                                        My Card
                                    </div>
                                    <div className={classes.MenuItemAction}>
                                        <Icon name="ico-chev-right" fill={COLOR.black} stroke="none"  />
                                    </div>
                                </div> */}
                                <div className={classes.MenuItem}
                                    onClick={() => goToUrl('user/setting')}>
                                    <div className={classes.MenuItemLabel}>
                                        <span className={classes.MenuItemLabelIcon}><Icon name="ico-setting" fill={COLOR.grey} stroke="none"  /></span>
                                        Settings
                                    </div>
                                    <div className={classes.MenuItemAction}>
                                        <Icon name="ico-chev-right" fill={COLOR.black} stroke="none"  />
                                    </div>
                                </div>
                                <div onClick={() => setIsLogoutConfirm(true)} className={classes.MenuItem}>
                                    <div className={classes.MenuItemLabel}>
                                        <span className={classes.MenuItemLabelIcon}><Icon name="ico-sign-out" fill={COLOR.grey} stroke="none"  /></span>
                                        Logout
                                    </div>
                                </div>
                            </div>
                        </PanelBox>
                    </div>
                </div>
            </div>
            <BottomNavBar/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        successSendValidationEmail: state.MemberReducer.successSendValidationEmail,
        showOtpForm: state.MemberReducer.showOtpForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout : () => dispatch(authAction.logout()),
        getDetailMemberHandler: () => dispatch(memberAction.getDetailMember()),
        sendActivationToEmail: (data) => dispatch(memberAction.sendActivationToEmail(data)),
        setShowVerifyPhoneForm : val => dispatch(memberAction.setShowOtpForm(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(userContainer);