import React from 'react';
import classes from './Date.module.scss';
import Icon from '../../../../../UI/Icon/Icon';
import SelectWithHtml from '../../../../../UI/Inputs/SelectWithHtml/SelectWithHtml';
import Moment from 'react-moment';

const date = (props) => {
    let dateOptions = null;
    
    dateOptions = props.tourScheduleList.map((schedule, index) => {
        let startDate = (
            <div>
                <Moment format="DD MMM YYYY">
                    {schedule.startDate}
                </Moment>
                <span> - </span>
                <Moment format="DD MMM YYYY">
                    {schedule.endDate}
                </Moment>
            </div>
        );

        return {
            value: schedule.tourScheduleID,
            text: startDate
        };
    });
    
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Header}>
                <Icon name="ico-calendar" fill="#CD2033" stroke="none" />
                <div className={classes.Title}>Date</div>
            </div>
            <div>
                <SelectWithHtml 
                    value={props.schedule}
                    options={dateOptions}
                    changed={(val) => props.setScheduleChanged(val)}
                    placeholder="Select Schedule"
                    tourPackage={props.tourPackage}
                    setItinerary={(scheduleId) => props.setItinerary(scheduleId)}
                    />
            </div>
        </div>
    );
}

export default date;