import React from 'react';
import classes from './ChangePassword.module.scss';
import PopupPage from '../../../UI/PopupPage/PopupPage';
import Icon from '../../../UI/Icon/Icon';
import { COLOR } from '../../../../constant/Library';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import TextField from '../../../UI/Inputs/TextField/TextField';
import Button from '../../../UI/Button/Button';

import { connect } from 'react-redux';
import * as memberAction from '../../../../redux/Shared/Member/MemberAction';

function changePassword (props) {
    let header = (
        <div className={classes.Header}>
            <span className={classes.HeaderAction}
                onClick={props.hideClicked}>
                <Icon name="ico-arrow-left" fill={COLOR.white} stroke="none" />
            </span>
            <span>Change Password</span>
        </div>
    );
    const changePasswordHandler = () => {
        let payload = {
            oldPassword: props.changePasswordData.oldPassword.value,
            newPassword: props.changePasswordData.newPassword.value,
            ipAddress: "127.0.0.1"
        }
        props.submitChangedPassword(payload)
    }
    let content = (
        <div className={classes.Content}>
            <div className={classes.Section}>
                <PanelBox>
                    <div className={classes.Form}>
                        <div className={classes.InputWrapper}>
                            <div className={classes.Label}>
                                <div className="u-flex-10">Current Password</div>
                            </div>
                            <div className={classes.Input}>
                                <div className="u-flex-10">
                                    <TextField
                                        type="password"
                                        placeholder="" 
                                        value={props.changePasswordData.oldPassword.value}
                                        changed={(event) => props.inputChangePasswordHandler(event, 'oldPassword')}/>
                                </div>
                            </div>
                        </div>
                        <div className={classes.InputWrapper}>
                            <div className={classes.Label}>
                                <div className="u-flex-10">New Password</div>
                            </div>
                            <div className={classes.Input}>
                                <div className="u-flex-10">
                                    <TextField
                                        type="password"
                                        placeholder="" 
                                        value={props.changePasswordData.newPassword.value}
                                        changed={(event) => props.inputChangePasswordHandler(event, 'newPassword')}/>
                                </div>
                            </div>
                        </div>
                        <div className={classes.InputWrapper}>
                            <div className={classes.Label}>
                                <div className="u-flex-10">Confirm New Password</div>
                            </div>
                            <div className={classes.Input}>
                                <div className="u-flex-10">
                                    <TextField
                                        type="password"
                                        placeholder="" 
                                        value={props.changePasswordData.confirmPassword.value}
                                        changed={(event) => props.inputChangePasswordHandler(event, 'confirmPassword')}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </PanelBox>
            </div>
            <div>
                <Button clicked={() => changePasswordHandler()} classes={['BtnRed', 'BtnBlock', 'BtnMedium']}>Change Password</Button>
            </div>
        </div>
    );
    return (
        <div>
            <PopupPage 
                show={props.show}
                header={header}
                content={content}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        changePasswordData: state.MemberReducer.changePassword,
        // isValidForm: state.ChangePasswordReducer.isValidForm
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // logout : () => dispatch(authAction.logout())
        inputChangePasswordHandler: (event, inputIdentifier) => dispatch(memberAction.inputChangePassword(event, inputIdentifier)),
        submitChangedPassword: (data) => dispatch(memberAction.submitChangedMember(data, 'passwordForm')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(changePassword);