import React from 'react';
import classes from './Included.module.scss';
import Icon from '../../../UI/Icon/Icon';

// label: stringify,
// color: black, white (default white)

function included(props) {
    let labelClasses = [classes.Label];
    if (props.color === 'black') {
        labelClasses.push(classes.Black);
    }
    return (
        <div className={classes.Wrapper}>
            <Icon name="ico-check-circle" fill="#73C700" stroke="none" width={15} />
            <span className={labelClasses.join(' ')}>{props.label}</span>
        </div>
    );
}

export default included;