import React from 'react';
import classes from './PackagesListLoaderMobile.module.scss';
import PanelBox from '../../../PanelBox/PanelBox';
import ContentLoader from 'react-content-loader';

const packagesListLoaderMobile = () => {
    return (
        <div className={classes.Wrapper}>
            <PanelBox>
                <div className={classes.Image}>
                    <ContentLoader
                        height={130}
                        speed={1}
                        primaryColor={'#e6e6e6'}
                    >
                        <rect x="17" y="90" rx="4" ry="4" width="240" height="15" />
                    </ContentLoader>
                </div>
                <div className={classes.Description}>
                    <ContentLoader
                        height={35}
                        speed={1}
                        primaryColor={'#e6e6e6'}
                    >
                        <rect x="0" y="0" rx="3" ry="3" width="50" height="10" />
                        <rect x="0" y="20" rx="3" ry="3" width="70" height="15" />
                        <rect x="85" y="20" rx="3" ry="3" width="70" height="15" />

                        <rect x="280" y="0" rx="3" ry="3" width="70" height="10" />
                        <rect x="280" y="20" rx="3" ry="3" width="120" height="20" />
                    </ContentLoader>
                </div>
            </PanelBox>
        </div>
    )
}

export default packagesListLoaderMobile;
