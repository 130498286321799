import React, { useEffect } from 'react';
import classes from './PopularActivities.module.scss';
import Item from './Item/Item';
import Slider from 'react-slick';
import Icon from '../../../../UI/Icon/Icon';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../../redux/Admission/Availability/AvailabilityAction';

const popularActivities = (props) => {

    useEffect(() => {
        props.initAdmissionPackages();
    }, [])

	const seeMoreHandler = () => {
		props.history.push('things-to-do/availability');
	}

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: false,
        autoplaySpeed: 2500,
        dotsClass: "slick-dots " + classes.DotWrapper,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        touchMove:true,
        draggable: false,
        customPaging: function(i) {
            return (
                <div className="banner-product"></div>
            );
        },
    };

    let content = null;
    if (props.isErrorFetchAdmission) {
        content = <div className={classes.NotFound}>Sorry, no tour package matches your preference. Please change your search.</div>;
    } else {
        if (props.isFetchAdmission) {
            // content = 'Loading...';
        } else {
            if (props.admissionPackages && props.admissionPackages.length > 0) {
                let itemList = props.admissionPackages.map((item, index) => {
                    return (
                        <div className={classes.Item} key={index}>
                            <Item item={item} />
                        </div>
                    )
                })
                content = (
                    <Slider {...settings}>
                        {itemList}
                    </Slider>
                );
            }
        }
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className={classes.Heading}>
                    <div className={classes.Title}>Popular Activities</div>
                    <div className={classes.More} onClick={() => seeMoreHandler()}>More</div>
                </div>
                <div className={classes.List}>
                    {content}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        admissionPackages: state.AdmissionAvailabilityReducer.admissionPackages,
        isFetchAdmission: state.AdmissionAvailabilityReducer.isFetchAdmission,
        isErrorFetchAdmission: state.AdmissionAvailabilityReducer.isErrorFetchAdmission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initAdmissionPackages: () => dispatch(AvailabilityAction.initAdmissionPackages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(popularActivities));




function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
			className={[classes.Arrow, classes.PrevArrow].join(' ')}
			onClick={onClick}>
				<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
        </div>
    );
}

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
			className={[classes.Arrow, classes.NextArrow].join(' ')}
			onClick={onClick}>
                <Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
        </div>
    );
}