import React from 'react';
import classes from './RightContent.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../UI/Icon/Icon';
import { formatDisplayDate, currencyFormat, toCapitalize } from '../../../../../helper/GlobalFunc';
import ImgThumb from '../../../../UI/ImgThumb/ImgThumb';

const rightContent = (props) => {
    let content = null;
    if (props.hotelDetail) {
        content = (
            <React.Fragment>
                <li className={classes.Item}>
                    <div className={classes.Wrapper}>
                        <div className={classes.Info}>
                            <div className={classes.Title}>
                                {toCapitalize(props.hotelDetail.name)}
                            </div>
                        </div>
                        <ImgThumb src={props.hotelDetail.imageSource} />
                    </div>
                </li>
                <li className={classes.Item}>
                    <div className={classes.Body}>
                        <div className={classes.Date}>
                            <div className={classes.Label}>Booking Period</div>
                            <div className={classes.Detail}>
                                <div className={classes.Icon}>
                                    <Icon name="ico-calendar" fill="#666666" stroke="none" width={20} />
                                </div>
                                {formatDisplayDate(props.hotelDetail.bookingPeriodStartDate)} - {formatDisplayDate(props.hotelDetail.bookingPeriodEndDate)}
                            </div>
                        </div>
                        <div className={classes.Date}>
                            <div className={classes.Label}>Stay Period</div>
                            <div className={classes.Detail}>
                                <div className={classes.Icon}>
                                    <Icon name="ico-calendar" fill="#666666" stroke="none" width={20} />
                                </div>
                                {formatDisplayDate(props.hotelDetail.stayPeriodStartDate)} - {formatDisplayDate(props.hotelDetail.stayPeriodEndDate)}
                            </div>
                        </div>
                    </div>
                </li>
                <li className={classes.Item}>
                    <div className={classes.PriceWrap}>
                        <div className={classes.Label}>Price</div>
                        <div className={classes.Detail}>
                            <span className={classes.Price}>{currencyFormat(props.hotelDetail.hotelPrice)}</span>
                            <span className={classes.Pax}>/{props.hotelDetail.minNight} nights stay</span>
                        </div>
                    </div>
                </li>
                
            </React.Fragment>

        )
    }

    return (
        <PanelBox isOverfloe={false}>
            <div className={classes.Wrapper}>
                <ul className={classes.SectionList}>
                    {content}
                </ul>
            </div>
        </PanelBox>
    )
}

export default rightContent;