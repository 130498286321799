import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classes from './ApplyCareerForm.module.scss';
import Icon from '../../../UI/Icon/Icon';
import Button from '../../../UI/Button/Button';
import TextField from '../../../UI/Inputs/TextField/TextField';
import Recaptcha from 'react-recaptcha';
import { CAPTCHA_KEY } from '../../../../constant/VendorKey';
import CSSTransition from 'react-transition-group/CSSTransition';
import MobileSelectFlag from '../../../UI/Inputs/MobileSelectFlag/MobileSelectFlag';
import UploadFile from '../../../UI/UploadFile/UploadFile';
import SuccessPage from '../../Shared/SuccessPage/SuccessPage';

import { connect } from 'react-redux';
import * as careerFormAction from '../../../../redux/NonProducts/CareerForm/CareerFormAction';

const ApplyCareerForm = props => {

    const [captcha, setCaptcha] = useState(null);

    const onfirmClickHandler = () => {
        props.history.push('/career');
    }

    useEffect(() => {
        setTimeout(() => {
            if (props.show) {
                setCaptcha(<Recaptcha
                    sitekey={CAPTCHA_KEY}
                    render="explicit"
                    verifyCallback={props.verifyClicked} />)
            } else {
                setCaptcha(null);
            }
        }, 270);

    }, [props.show]);

    const submitApplicantHandler = () => {
        if (this.props.isVerified && this.props.isValidForm) {
            let file = this.props.selectedFile;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                let ecy = e.target.result.split(',');
                let attachments = [{
                    name: file.name,
                    content: ecy[1],
                    contentType: file.type
                }];

                const postData = {
                    name: this.props.formData.name.value,
                    phone: this.props.formData.dialCode.value.concat(this.props.formData.phone.value),
                    email: this.props.formData.email.value,
                    position: this.props.jobDetail.name,
                    attachments: attachments, 
                };

                this.captcha.reset();
                this.props.formSubmited(postData);
                
            }
        } else {
            this.props.formChecking();
        }
    }

    return (
        <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={270}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close
            }}>
            <div className={classes.Wrapper}>
                <SuccessPage 
                    showPage={props.showAlert}
                    confirmClicked={onfirmClickHandler} />
                
                <div className={classes.Header}>
                    <div className={classes.BackBtn} onClick={props.backClicked}>
                        <Icon name="ico-close" fill="#202124" stroke="none" />
                    </div>
                    <div className={classes.Title}>Apply Now..!</div>
                </div>

                <div className={classes.Content}>
                    <div className={classes.Form}>
                        <div>
                            <div className={classes.Input}>
                                <label>Full Name</label>
                                <TextField
                                    placeholder="e.g. Julie Applesed"
                                    value={props.formData.name.value}
                                    changed={(event) => props.inputChanged('name', event)}
                                    hasError={props.formData.name.hasError && props.formData.name.errorMsg !== ''}
                                    errorMsg={props.formData.name.errorMsg} />
                            </div>
                        </div>
                        <div>
                            <div className={classes.Input}>
                                <label>E-mail Address</label>
                                <TextField
                                    placeholder="e.g. youremail@example.com"
                                    value={props.formData.email.value}
                                    changed={(event) => props.inputChanged('email', event)}
                                    hasError={props.formData.email.hasError && props.formData.email.errorMsg !== ''}
                                    errorMsg={props.formData.email.errorMsg} />
                            </div>
                        </div>
                        <div>
                            <div className={classes.Input}>
                                <label>Phone Number</label>
                                <div className={classes.Inline}>
                                    <div className={classes.DialCode}>
                                        <MobileSelectFlag 
                                            value={props.formData.dialCode.value}
                                            selected={val => props.dialCodeChanged(val)}/>
                                    </div>
                                    <div className={classes.Phone}>
                                        <TextField
                                            type="text"
                                            placeholder="e.g. 8123456789"
                                            value={props.formData.phone.value}
                                            changed={(event) => props.inputChanged('phone', event)}
                                            hasError={props.formData.phone.hasError && props.formData.phone.errorMsg !== ''}
                                            errorMsg={props.formData.phone.errorMsg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={classes.Input}>
                                <label>Resume / CV</label>
                                <UploadFile
                                    changed={props.fileSelected}
                                    rule="doc, docx, pdf Up to 10 Mb."/>
                            </div>
                        </div>
                        <div className={classes.Input}>
                            {captcha}
                        </div>
                        <div>
                            <Button
                                classes={['BtnRed', 'BtnBlock', 'BtnLarge']}
                                clicked={submitApplicantHandler}
                                disabled={props.isLoadingSubmitForm}>Submit Application</Button>
                        </div>
                    </div>

                    
                </div>
            </div>
        </CSSTransition>
    );
}

const mapStateToProps = state => {
    return {
        formData: state.CareerFormReducer.formData,
        selectedFile: state.CareerFormReducer.selectedFile,
        isValidForm: state.CareerFormReducer.isValidForm,
        isVerified: state.CareerFormReducer.isVerified,
        showAlert: state.CareerFormReducer.showAlert,
        isLoadingSubmitForm: state.CareerFormReducer.isLoadingSubmitForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        inputChanged : (identifier, event) => dispatch(careerFormAction.inputChanged(identifier, event)),
        dialCodeChanged : (value) => dispatch(careerFormAction.dialCodeChanged(value)),
        fileSelected : (event) => dispatch(careerFormAction.fileSelected(event)),
        verifyClicked : (res) => dispatch(careerFormAction.verifyClicked(res)),
        formChecking : () => dispatch(careerFormAction.formChecking()),
        formSubmited : (postData) => dispatch(careerFormAction.formSubmited(postData))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplyCareerForm));