import React, { Component } from 'react';
import classes from './Date.module.scss';
import Icon from '../../../../../UI/Icon/Icon';
import CSSTransition from 'react-transition-group/CSSTransition';
import onClickOutside from "react-onclickoutside";
import DayPicker from 'react-day-picker';
import { formatHumanDate } from '../../../../../../helper/GlobalFunc';
import 'react-day-picker/lib/style.css';
import { formatShortDate } from '../../../../../../helper/GlobalFunc';

class Datess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCalendar: false,
            selectedDate: null,
        }
    }

    handleClickOutside = (evt) => {
        this.setState({showCalendar: false});
    }

    showCalendarHandler = () => {
        this.setState({showCalendar: true});
    }

    onDayClickHandler = day => {
        let start = new Date(this.props.startPeriodOfTravelling);
        let end = new Date(this.props.endPeriodOfTravelling);
        if (day >= start && day < new Date(end.setDate(end.getDate() + 1))) {
            localStorage.setItem('date', formatShortDate(day))
            this.setState({selectedDate: day})
            this.setState({showCalendar: false});
            this.props.setselectedDateHandler(formatShortDate(day))
        }
    }

    render () {
        let fromMonth = new Date(this.props.startPeriodOfTravelling);
        let toMonth = new Date(this.props.endPeriodOfTravelling);

        let content = (
            <div className={classes.Default} onClick={() => this.showCalendarHandler()}>
                Selected date visit
            </div>
        )
        let selectedDate = null;
        let month = new Date(this.props.startPeriodOfTravelling.split('-')[0], this.props.startPeriodOfTravelling.split('-')[1]-1);
        
        if (this.state.selectedDate !== null) {
            let currentDate = this.state.selectedDate;
            content = (
                <div className={classes.SelectedDate} onClick={() => this.showCalendarHandler()}>
                    {formatHumanDate(this.state.selectedDate)}
                </div>
            )
            selectedDate = [new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())];
            month = new Date(currentDate.getFullYear(), currentDate.getMonth())
        }

        return (
            <div className={classes.Item}>
                <div className={classes.Label}>
                    <Icon name="ico-calendar" width={20} stroke="none" fill="#CD2033" />
                    <label>Date</label>
                </div>
                <div className={classes.Content}>
                    {content}
                    <CSSTransition
                        in={this.state.showCalendar}
                        mountOnEnter
                        unmountOnExit
                        timeout={270}
                        classNames={{
                            enterActive: classes.Open,
                            exitActive: classes.Close
                        }}>
                            <div className={classes.Calendar}>
                                <DayPicker
                                    fromMonth={fromMonth}
                                    toMonth={toMonth}
                                    month={month}
                                    disabledDays={{ before: new Date(this.props.startPeriodOfTravelling), after: toMonth }}
                                    selectedDays={selectedDate}
                                    onDayClick={this.onDayClickHandler}/>
                            </div>
                    </CSSTransition>
                </div>
            </div>
        )
    }
}

export default onClickOutside(Datess);
