const lang = {
    payment : 'Pambayaran',
    tour : 'Tour',
    flight : 'Pesawat',
    hotel : 'Hotel',
    pulse : 'Pulsa',
    train : 'Kereta Api',
    checkOrder : 'Cek Pesanan',
    login : 'Masuk',
    hotelLastView : 'Hotel Terakhir Yang Anda Liat',
    hotelPopular : 'Hotel Populer',
}

export default lang;