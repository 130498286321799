import React from 'react'
import classes from './Footer.module.scss'
import { ANTAVAYA } from '../../../../constant/Antavaya'
import Icon from '../../../UI/Icon/Icon'
// import googleBadge from '../../../../assets/images/google-play-badge.png'
// import appleBadge from '../../../../assets/images/app-store-badge.png'
import { SOCMED_LINK } from '../../../../constant/LinkList'

const footer = ({corporate}) => {
	const currentYear = new Date().getFullYear()
	const addresses = ANTAVAYA.addresses.map((address, index) => {
		return (
			<div key={index} className={classes.Address}>
				<div>{address}</div>
			</div>
		)
	})
	const socmedLink = SOCMED_LINK.map((item, index) => {
		return (
			<li key={index}>
				<a href={item.url} target={item.target}>
					<div className={classes.Icon}>
						<Icon name={item.icon} fill='#ffffff' stroke='none' />
					</div>
				</a>
			</li>
		)
	})
	return (
		<div className={classes.Wrapper}>
			<div className={classes.ContactUs}>
				<div className={classes.Title}>Contact Us</div>
				<ul className={classes.List}>
					<li className={classes.Item}>
						<div className={classes.Icon}>
							<Icon name='ico-cs' fill='#6A6E73' stroke='none' width='20' />
						</div>
						<div className={classes.Label}>{corporate ? ANTAVAYA.hotlineCorporate : ANTAVAYA.hotline}</div>
					</li>
					<li className={classes.Item}>
						<div className={classes.Icon}>
							<Icon name='ico-hour' fill='#6A6E73' stroke='none' width='20' />
						</div>
						<div className={classes.Label}>
							{ANTAVAYA.csHours[0]} - {ANTAVAYA.csHours[1]} ({ANTAVAYA.csDays[0]} - {ANTAVAYA.csDays[1]})
						</div>
					</li>
					<li className={classes.Item}>
						<div className={classes.Icon}>
							<Icon name='ico-mail' fill='#6A6E73' stroke='none' width='20' />
						</div>
						<div className={classes.Label}>{corporate ? ANTAVAYA.emailCorporate :ANTAVAYA.email}</div>
					</li>
					<li className={classes.Item}>
						<div className={classes.Icon}>
							<Icon name='ico-place' fill='#6A6E73' stroke='none' width='20' />
						</div>
						<div className={classes.Label}>{addresses}</div>
					</li>
				</ul>
				{/* <div className={[ classes.Title ].join(' ')}>Download Antavaya App</div>
				<div className={classes.DownloadBadge}>
					<div className={classes.PlayStore}>
						<img src={googleBadge} alt='Play Store Badge' />
					</div>
					<div className={classes.AppStore}>
						<img src={appleBadge} alt='App Store Badge' />
					</div>
				</div> */}
				<div className={[ 'u-text-center', classes.Title ].join(' ')}>Follow Us on</div>
				<ul className={classes.SocmedLink}>{socmedLink}</ul>
			</div>
			<div className={classes.Copyright}>Copyright © {currentYear} AntaVaya. All Rights Reserved</div>
		</div>
	)
}

export default footer
