import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

export const inputChanged = (identifier, event) => {
    let value = event.target.value;
    return {
        type: 'INPUT_CHANGED',
        payload: {identifier, value}
    };
};

export const dialCodeChanged = (value) => {
    return {
        type: 'DIAL_CODE_CHANGED',
        value
    };
};

export const subjectChanged = (value) => {
    return {
        type: 'SUBJECT_CHANGED',
        value
    };
};

export const verifyClicked = res => {
    return {
        type: 'VERIFY_CLICKED'
    };
};

export const formChecking = () => {
    return {
        type: 'FORM_CHECKING'
    };
};

export const alertConfirmed = () => {
    return {
        type: 'ALERT_CONFIRMED'
    };
};

export const submitSuccess = () => {
    return {
        type: 'SUBMIT_SUCCESS'
    };
};

export const loadingSubmitForm = () => {
    return {
        type: 'LOADING_SUBMIT_FORM'
    };
};

export const formSubmited = (postData) => {
    return dispatch => {
        dispatch(loadingSubmitForm());
        axios.post(ANTAVAYA_API + 'v1/mailer/corporate', postData)
        .then( response => {
            dispatch(submitSuccess());
        })
        .catch(function (error) {
            console.log('ERR', error);
        });
    };
};