import axios from "../../../axios";
import { ANTAVAYA_API } from "../../../constant/Api";

export const setVouchers = (vouchers) => {
	return {
		type: "SET_VOUCHER",
		payload: { vouchers },
	};
};

export const seIsFetchVoucher = (value) => {
	return {
		type: "SET_IS_FETCH_VOUCHER",
		payload: { value },
	};
};

export const seIsErrorFetchVoucher = (value) => {
	return {
		type: "SET_IS_ERROR_FETCH_VOUCHER",
		payload: { value },
	};
};

export const fetchVoucher = () => {
	return (dispatch) => {
		dispatch(seIsFetchVoucher(true));
		axios.get(ANTAVAYA_API + 'voucher/list')
			.then((response) => {
				dispatch(setVouchers(response.data.data));
				dispatch(seIsFetchVoucher(false));
			})
			.catch((error) => {
				dispatch(seIsErrorFetchVoucher(true));
				dispatch(seIsFetchVoucher(false));
			});
	};
};
