import React from 'react';
import classes from './TourDetail.module.scss';
import { toCapitalize, pluralize, formatHumanDate } from '../../../../../helper/GlobalFunc';
import Icon from '../../../../UI/Icon/Icon';
import ImgThumb from '../../../../UI/ImgThumb/ImgThumb';
import ContentLoader from 'react-content-loader';

const tourDetail = (props) => {

    let room = null;
    let adult = null;
    let child = null;
    if (props.bookingData) {
        room = pluralize(props.bookingData.roomCount, 'Room', 'Rooms');
        adult = (props.bookingData.roomAdultCount > 0) ? ' - ' + pluralize(props.bookingData.roomAdultCount, 'Adult', 'Adults') : '';
        child = (props.bookingData.roomChildCount > 0) ? ' & ' + pluralize(props.bookingData.roomChildCount, 'Child', 'Children') : '';
    }
    
    let flight = null;
	if (props.bookingData && Object.keys(props.bookingData.flight).length > 0) {
        flight = <li className={classes.Item}>
            <Icon name="ico-flight-o" fill="#6A6E73" stroke="none" />
            <div className={classes.Label}>{props.bookingData.flight.flightName}</div>
        </li>
    }

    let content = <div className={classes.Wrapper}>
            <ContentLoader
                height={140}
                speed={1}
                primaryColor={'#e6e6e6'}>
                <rect x="0" y="0" rx="2" ry="2" width="50" height="8" />
                <rect x="0" y="25" rx="5" ry="5" width="50" height="50" />

                <rect x="60" y="25" rx="2" ry="2" width="130" height="10" />
                <rect x="60" y="45" rx="2" ry="2" width="80" height="8" />

                <rect x="0" y="100" rx="2" ry="2" width="150" height="12" />
                <rect x="180" y="100" rx="2" ry="2" width="150" height="12" />
                <rect x="0" y="123" rx="2" ry="2" width="120" height="12" />
                <rect x="180" y="123" rx="2" ry="2" width="120" height="12" />
            </ContentLoader>
        </div>;

    if (!props.isLoading && props.bookingData) {
        content = <div className={classes.Wrapper}>
            <div className={classes.Title}>Tour Details</div>
            <div className={classes.Head}>
                <ImgThumb type="square" src={props.bookingData.image.url} />
                <div className={classes.TitleWrap}>
                    <div className={classes.Title}>{props.bookingData.duration}D {toCapitalize(props.bookingData.title)}</div>
                    <div className={classes.Duration}>{props.bookingData.days} Days & {props.bookingData.night} Nights</div>
                </div>
            </div>
            <ul className={classes.List}>
                <li className={classes.Item}>
                    <Icon name="ico-calendar" fill="#6A6E73" stroke="none" />
                    <div className={classes.Label}> {formatHumanDate(props.bookingData.startDate)} - {formatHumanDate(props.bookingData.endDate)}</div>
                </li>
                {flight}
                <li className={classes.Item}>
                    <Icon name="ico-user-o" fill="#6A6E73" stroke="none" />
                    <span className={classes.Label}>{room}{adult}{child}</span>
                </li>
            </ul>
        </div>
    }


    
    return <div>{content}</div>;
}

export default tourDetail;