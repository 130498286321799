import React, { useState, useEffect } from 'react';
import classes from './BookingContainer.module.scss';
import MetaTag from '../../../../helper/MetaTag';
import BookingHeader from '../../../../components/Desktop/Shared/BookingHeader/BookingHeader';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import LeftContent from '../../../../components/Desktop/Admission/BookingForm/LeftContent/LeftContent';
import RightContent from '../../../../components/Desktop/Admission/BookingForm/RightContent/RightContent';
import MessageBox from './MessageBox/MessageBox';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';

import { connect } from 'react-redux';
import * as BookingAction from '../../../../redux/Admission/Booking/BookingAction';
import * as DetailAction from '../../../../redux/Admission/Detail/DetailAction';

const bookingContainer = (props) => {
    const [visitors, setVisitors] = useState(null);
    const [isContinueConfirm, setIsContinueConfirm] = useState(false);

    useEffect(() => {
        setVisitors(JSON.parse(localStorage.getItem('visitors')))
        props.onInitAdmissionDetail(props.match.params.slug)
    }, [])

    useEffect(() => {
        if (props.isSuccessBooking) {
            setIsContinueConfirm(false)
        }
    }, [props.isSuccessBooking])

    const goBack = () => {
        props.setIsSucessBooking(false);
        props.history.push('/');
    }

    const submitInquryHandler = () => {
        if (props.isValid) {
            setIsContinueConfirm(true)
        } else {
            props.checkBookingForm();
        }
    }

    const submitData = () => {
        let prices = [];
        let visitors = JSON.parse(localStorage.getItem('visitors'));

        for (const key in visitors) {
            prices.push({
                type: visitors[key].type,
                price: visitors[key].price.toString(),
                qty: visitors[key].qty.toString()
            })
        }

        let data = {
            title: props.contactInfoForm.title.value,
            fullName: props.contactInfoForm.firstName.value + ' ' + props.contactInfoForm.lastName.value,
            ticketName: props.admissionDetail.tourAdmissionName,
            date: localStorage.getItem('date'),
            startPrice: props.admissionDetail.priceStartFrom.toString(),
            validUntil: props.admissionDetail.endPeriodOfTravelling,
            phoneNumber: props.contactInfoForm.dialCode.value + '' + props.contactInfoForm.phone.value,
            email: props.contactInfoForm.email.value,
            question: props.contactInfoForm.question.value,
            prices: prices
        }

        props.submitBookingHandler(data)
    }
    
    let step = 1;

    let content = null;
    if (props.isSuccessBooking) {
        step = 2;
        content = (
            <MessageBox clicked={() => goBack()} />
        )
    } else {
        content = (
            <React.Fragment>
                <div className={classes.Container}>
                    <div className={classes.Header}>
                        <div className={classes.Title}>Things to do Booking</div>
                        <div className={classes.SubTitle}>Fill in your details and review your booking</div>
                    </div>
                    <div className={classes.Body}>
                        <div className={classes.LeftContent}>
                            <LeftContent submitInquryHandler={submitInquryHandler}/>
                        </div>
                        <div className={classes.RightContent}>
                            <RightContent admissionDetail={props.admissionDetail} visitors={visitors}/>
                        </div>
                    </div>
                    {content}
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className="bg-white-dark min-width">
            <MetaTag title="Admission Booking"/>
            <Confirm
                show={isContinueConfirm}
                title="Are You Sure?"
                desc="Make sure your data is correct."
                cancelClicked={() => setIsContinueConfirm(false)}
                isConfirmLoading={props.isSubmitBookLoading}
                cancelLabel="No, check again"
                confirmLabel="Yes, continue"
                confirmClicked={submitData}/>
            <div className={classes.Wrapper}>
                <BookingHeader step={step} />
                {content}
            </div>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isValid: state.AdmissionBookingReducer.isValid,
        contactInfoForm: state.AdmissionBookingReducer.contactInfoForm,
        admissionDetail: state.AdmissionDetailReducer.admissionDetail,
        isSuccessBooking: state.AdmissionBookingReducer.isSuccessBooking,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkBookingForm: () => dispatch(BookingAction.checkBookingForm()),
        onInitAdmissionDetail: (slug) => dispatch(DetailAction.initAdmissionDetail(slug)),
        submitBookingHandler: (data) => dispatch(BookingAction.submitBooking(data)),
        setIsSucessBooking: (val) => dispatch(BookingAction.isSucessBooking(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(bookingContainer);