import React from 'react';
import classes from './LoginForm.module.scss';
import Button from '../../../../UI/Button/Button';
import TextField from '../../../../UI/Inputs/TextField/TextField';
import CheckBox from '../../../../UI/Inputs/CheckBox/CheckBox';
// import icoFb from '../../../../../assets/images/ico-fb.png';
// import icoGoogle from '../../../../../assets/images/ico-google.png';

const loginForm = (props) => {
    return(
        <div className={classes.Wrapper}>
            <div className={classes.FormWrapper}>
                <div className={classes.Header}>
                    <div className={classes.Title}>Login</div>
                    <div className={classes.LabelHeader}>Don't have an account? <span className={classes.Link} onClick={() => props.switchFormClicked('register')}>Register</span></div>
                </div>
                <div className={[classes.Input, classes.LargeWidth].join(' ')}>
                    <label className={classes.Label}>Email Address</label>
                    <TextField
                        placeholder="e.g. youremail@example.com" 
                        value={props.loginData.email.value} 
                        changed={(event) => props.inputChanged(event, 'email')}
                        hasError={(props.loginData.email.hasError && props.loginData.email.errorMsg !== '')}
                        errorMsg={props.loginData.email.errorMsg} />
                </div>
                <div className={[classes.Input, classes.LargeWidth].join(' ')}>
                    <label className={classes.Label}>Password</label>
                    <TextField
                        type="password"
                        placeholder="" 
                        value={props.loginData.password.value} 
                        changed={(event) => props.inputChanged(event, 'password')}
                        hasError={(props.loginData.password.hasError && props.loginData.password.errorMsg !== '')}
                        errorMsg={props.loginData.password.errorMsg} />
                </div>
                <div className={[classes.Input, classes.Remember].join(' ')}>
                    <CheckBox 
                        label="Remember Me"
                        checked={props.loginData.rememberMe}
                        changed={(event) => props.rememberMeChanged(event)} />
                        <span className={classes.Link} onClick={() => props.switchFormClicked('forgot-password')}>Forgot Password</span>
                </div>
                <div className={classes.Action}>
                    <Button 
                        classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
                        clicked={props.submitLoginClicked}>
                        Login
                    </Button>
                </div>
                {/* <div className={classes.Sparator}>
                    <span className={classes.Text}>Or Login With</span>
                </div>
                <div className={classes.SocmedLogin}>
                    <div className={[classes.Socmed, classes.FbBtn].join(' ')}><img className={classes.Icon} src={icoFb} alt="facebook" /> Facebook</div>
                    <div className={[classes.Socmed, classes.GoogleBtn].join(' ')}><img className={classes.Icon} src={icoGoogle} alt="google" /> Google</div>
                </div> */}
            </div>
        </div>
    ); 
}

export default loginForm;