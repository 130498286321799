import { updateObject } from "../../utility";

const initialState = {
    admissionDetail: null,
    isFetchAdmissionDetail: false,
    isErrorFetchAdmissionDetail: false,
    visitors: null,
}

const setAdmissionDetail = (state, action) => {
    return(updateObject(state, { admissionDetail: action.payload.data }));
}

const setIsFetchAdmissionDetail = (state, action) => {
    return(updateObject(state, { isFetchAdmissionDetail: action.payload.value }));

}

const setIsErrorFetchAdmissionDetail = (state, action) => {
    return(updateObject(state, { isErrorFetchAdmissionDetail: action.payload.value }));

}

const setVisitors = (state, action) => {
    return(updateObject(state, { visitors: action.payload.visitors }))
}

const visitorAdded = (state, action) => {
    let visitorsCp = state.visitors;
    console.log('visitorsCp', visitorsCp)
    visitorsCp[action.payload.index].qty = visitorsCp[action.payload.index].qty + 1;
    return updateObject(state, { visitors: visitorsCp });
}

const visitorRemove = (state, action) => {
    let visitorsCp = state.visitors;
    console.log('visitorsCp', visitorsCp)
    visitorsCp[action.payload.index].qty = visitorsCp[action.payload.index].qty - 1;
    return updateObject(state, { visitors: visitorsCp });
    
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case 'SET_ADMISSION_DETAIL': return setAdmissionDetail(state, action);
        case 'SET_IS_FETCH_ADMISSION_DETAIL': return setIsFetchAdmissionDetail(state, action);
        case 'SET_IS_ERROR_FETCH_ADMISSION_DETAIL': return setIsErrorFetchAdmissionDetail(state, action);
        case 'SET_VISITORS': return setVisitors(state, action);
        case 'VISITOR_ADDED': return visitorAdded(state, action);
        case 'VISITOR_REMOVE': return visitorRemove(state, action);
        default: return state;
    }
}

export default reducer;