import {updateObject} from '../../utility';

const initialState = {
    vouchers : [],
    isFetchVoucher : false,
    isErrorFetchVoucher : false
}

const setVoucher = (state, action) => {
    return updateObject(state, {vouchers: action.payload.vouchers});
};

const seIsFetchVoucher = (state, action) => {
    return updateObject(state, {isFetchVoucher: action.payload.value});
}

const seIsErrorFetchVoucher = (state, action) => {
    return updateObject(state, {isErrorFetchVoucher: action.payload.value});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_VOUCHER': return setVoucher(state, action);
        case 'SET_IS_FETCH_VOUCHER': return seIsFetchVoucher(state, action);
        case 'SET_IS_ERROR_FETCH_VOUCHER': return seIsErrorFetchVoucher(state, action);
        default: return state;
    }
};

export default reducer;