import React, { Component } from 'react';
import classes from './ItineraryItem.module.scss';
import Icon from '../../../../../../../UI/Icon/Icon';
import CSSTransition from 'react-transition-group/CSSTransition';
import { toCapitalize } from '../../../../../../../../helper/GlobalFunc';

class ItineraryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive : false
        };
    }

    componentWillUpdate(nextProps, nextState) {
        console.log(nextProps, nextState);
        if (nextProps.isActive !== this.state.isActive && !nextState.isActive) {
            this.detailToggleHandler();
        }
    }

    detailToggleHandler = () => {
        this.setState({isActive: !this.state.isActive});
    }

    render() {
        let arrowClasses = [classes.Arrow];
        if (this.state.isActive) {
            arrowClasses = [classes.Arrow, classes.Active];
        }
        let leftClasses = [classes.Left];
        let rightClasses = [classes.Right];
        if (this.props.isLast) {
            leftClasses = [classes.Left, classes.Last];
            rightClasses = [classes.Right, classes.Last];
        }
        let facilityList = null;
        if (this.props.itenerary.facilities && this.props.itenerary.facilities.length > 0) {
            facilityList = this.props.itenerary.facilities.map((facility, index) => {
                return (<li key={index}><Icon name="ico-check-circle-white" fill="#73C700" stroke="none"  /><span>{facility.name}</span></li>);
            });
        }

        let flight = null;
        if (this.props.itenerary.airlineCode) {
            let flightTime = null;
            let airportCode = null;
            if (this.props.itenerary.departureTime && this.props.itenerary.arrivalTime) {
                flightTime = <div className={classes.FlightTitle}>{this.props.itenerary.departureTime} - {this.props.itenerary.arrivalTime}</div>;
            }
            if (this.props.itenerary.departureAirportCode && this.props.itenerary.arrivalAirportCode) {
                airportCode = <div className={classes.FlightSubTitle}>{this.props.itenerary.departureAirportCode} - {this.props.itenerary.arrivalAirportCode}</div>;
            }
            flight = (
                <div className={classes.Flight}>
                    <div className={classes.FlightIcon}>
                        <Icon name="ico-flight-o" fill="#6A6E73" stroke="none" />
                    </div>
                    <div className={classes.FlightDesc}>
                        <div>
                            {flightTime}
                            <div className={classes.FlightSubTitle}>{this.props.itenerary.airlineName}</div>
                        </div>
                    </div>
                    <div className={classes.FlightDesc}>
                        {airportCode}
                    </div>
                </div>
            );
        }
        return (
            <div className={classes.Wrapper}>
                <div className={leftClasses.join(' ')}>
                    <span className={classes.Day} onClick={this.detailToggleHandler}>Day {this.props.itenerary.tourItineraryDay}</span>
                    <div className={classes.Dot}></div>
                </div>
                <div className={rightClasses.join(' ')}>
                    <div className={classes.Title} onClick={this.detailToggleHandler}>
                        {toCapitalize(this.props.itenerary.title)}
                        <div className={arrowClasses.join(' ')}>
                            <Icon name="ico-caret-down" fill="#202124;" stroke="none"  />
                        </div>
                    </div>
                    <CSSTransition
                        in={this.state.isActive}
                        mountOnEnter
                        unmountOnExit
                        timeout={270}
                        classNames={{
                            enterActive: classes.Open,
                            exitActive: classes.Close
                        }}>
                            <div>
                                {flight}
                                <div className={classes.Description}>{this.props.itenerary.description}</div>
                                <ul className={classes.Facilities}>
                                    {facilityList}
                                </ul>
                                <div></div>
                            </div>
                    </CSSTransition>
                </div>
            </div>
        );
    }
}

export default ItineraryItem;