import {updateObject} from '../../utility';

const initialState = {
    branchOfficeList : null,
    isErrorFetchBranchOfficelList : false
}

const setBranchOfficeList = (state, action) => {
    return updateObject( state, {
        branchOfficeList: action.branchOfficeList,
        isErrorFetchBranchOfficelList : false
    } );
};

const fetchBranchOfficeListFailed = (state, action) => {
    return updateObject(state, {isErrorFetchBranchOfficelList: true});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_BRANCH_OFFICE_LIST': return setBranchOfficeList(state, action);
        case 'FETCH_BRANCH_OFFICE_LIST_FAILED': return fetchBranchOfficeListFailed(state, action);
        default: return state;
    }
};

export default reducer;