import React, { Component } from 'react';
import classes from './Dropdown.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import DayPicker from '../../../../../UI/DayPicker/DayPicker';
import Holiday from '../../../Holiday/Holiday';
import Switch2 from '../../../../../UI/Inputs/Switch2/Switch2';
import holidayList from '../../../../../../assets/jsons/holiday.json';
import { formatDisplayDate } from '../../../../../../helper/GlobalFunc';

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holidays: [],
            activeMonth: undefined
        };
    }

    componentDidMount() {
        let holidays = [];
        for (let year in holidayList) {
            for (let month in holidayList[year]) {
                for (let day in holidayList[year][month]) {
                    holidays.push(year + '-' + month + '-' + day);
                }
            }
        }
        this.setState({holidays});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeMonth === undefined) {
            this.setState({activeMonth : prevProps.departureDate});
        } 
    }

    activeMonthChangeHandler = date => {
        this.setState({activeMonth: date});
    }
    render() {

        let holidayNameList = [];
        if (this.state.activeMonth !== undefined) {
            let year = this.state.activeMonth.getFullYear(this.state.activeMonth).toString();
            let month = this.state.activeMonth.getMonth(this.state.activeMonth);
            month = ('0' + (month+1)).slice(-2);
            if (holidayList[year] && holidayList[year][month]) {
                for (const day in holidayList[year][month]) {
                    holidayNameList.push({
                        name: holidayList[year][month][day],
                        date: formatDisplayDate(year + '-' + month + '-' + day)
                    });
                }
            } 
        }

        return (
            <CSSTransition
                in={this.props.showDropdownList}
                mountOnEnter
                unmountOnExit
                timeout={270}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
                }}>
                <div className={classes.Wrapper}>
                    <div className={classes.Header}>
                        <Switch2 
                            value={this.props.isRoundTrip}
                            labels={['One Way', 'Round Trip']}
                            toggleClicked={this.props.roundTripToggleClicked}/>
                    </div>
                    <div className={classes.Body}>
                        <div className={classes.Left}>
                            <DayPicker
                                isRange={this.props.isRoundTrip}
                                from={this.props.departureDate}
                                to={this.props.returnDate}
                                dateClicked={dates => this.props.dateClicked(dates)}
                                activeMonthChange={(date) => this.activeMonthChangeHandler(date)}
                                holidayList={this.state.holidays} />
                        </div>
                        <div className={classes.Right}>
                            <div className={classes.Label}>Holiday</div>
                            <Holiday holidayNameList={holidayNameList} />
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}

export default Dropdown;