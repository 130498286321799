import React from 'react';
import classes from './PackageItem.module.scss';
import { toCapitalize } from '../../../../../helper/GlobalFunc';
import CurrencyFormat from 'react-currency-format';

const packageItem = (props) => {
    let title = null;
    let image = 'https://s3-ap-southeast-1.amazonaws.com/antavaya.com/images/pattern-image.png';

    if (props.packageItem.type === 'experience') {
        title = props.packageItem.days +  'D ' + toCapitalize(props.packageItem.inventoryTitle)
        if (props.packageItem.defaultImage.imageFile !== '') {
            image = props.packageItem.defaultImage.imageFile
        }
    } else {
        title = toCapitalize(props.packageItem.tourAdmissionName);
        if (props.packageItem.image !== null) {
            image = props.packageItem.image;
        }
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.ImageWrapper}>
                <img src={image} className={classes.Img} alt={''} />
            </div>
            <div className={classes.Description}>
                <div className={classes.Title}>
                    {title}
                </div>
                <div className={classes.PriceWrapper}>
                    <div className={classes.StartFrom}>Start From</div>
                    <div className={classes.Price}>
                        <CurrencyFormat value={props.packageItem.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                    </div>
                    <div className={classes.Perpax}>/Pax</div>
                </div>
            </div>
        </div>
    );
}

export default packageItem;