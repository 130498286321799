import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

export const setHotels = ( hotels ) => {
    return {
        type: 'SET_HOTEL',
        payload: { hotels }
        
    };
};

export  const seIsFetchHotel = (value) => {
    return {
        type: 'SET_IS_FETCH_HOTEL',
        payload: { value }
    }
}

export  const seIsErrorFetchHotel = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_HOTEL',
        payload: { value }
    }
}

export const fetchHotel = () => {
    return dispatch => {
        dispatch(seIsFetchHotel(true));
        axios.get(ANTAVAYA_API + 'hotel/list')
            .then( response => {
                dispatch(setHotels(response.data.data));
                dispatch(seIsFetchHotel(false));
            } )
            .catch( error => {
                dispatch(seIsErrorFetchHotel(true));
                dispatch(seIsFetchHotel(false));
            } );
    };
};