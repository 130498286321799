import React, { useState, useEffect } from 'react';
import classes from './BookingContainer.module.scss';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import Step from '../../../../components/Shared/Step/Step';
import PackageInfo from '../../../../components/Mobile/ThingsToDo/Admission/Booking/PackageInfo/PackageInfo';
import AdmissionDetail from '../../../../components/Mobile/ThingsToDo/Admission/Booking/AdmissionDetail/AdmissionDetail';
import ContactInfo from '../../../../components/Mobile/ThingsToDo/Admission/Booking/ContactInfo/ContactInfo';
import Button from '../../../../components/UI/Button/Button';
import Spiner from '../../../../components/UI/Loaders/Spiner/Spiner';
import UnavailableTour from '../../../../components/UI/UnavailableTour/UnavailableTour';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';
import SuccessPage from '../../../../components/Mobile/Shared/SuccessPage/SuccessPage';

import { connect } from 'react-redux';
import * as DetailAction from '../../../../redux/Admission/Detail/DetailAction';
import * as BookingAction from '../../../../redux/Admission/Booking/BookingAction';

const bookingContainer = (props) => {
    const [showAdmissionDetail, setShowAdmissionDetail] = useState(false);
    const [isContinueConfirm, setIsContinueConfirm] = useState(false);

    useEffect(() => {
        props.onInitAdmission(props.match.params.slug);
    }, [])

    useEffect(() => {
        setIsContinueConfirm(false);
    }, [props.isSuccessBooking])

    const backClickHandler = () => {
        props.history.goBack();
    }

    const backToHome = () => {
        props.history.push('/');
        document.body.style.overflow = 'auto';
    }

    const submitInquryHandler = () => {
        if (props.isValid) {
            setIsContinueConfirm(true)
        } else {
            props.checkBookingForm();
        }
    }

    const submitData = () => {
        let prices = [];
        let visitors = JSON.parse(localStorage.getItem('visitors'));

        for (const key in visitors) {
            prices.push({
                type: visitors[key].type,
                price: visitors[key].price.toString(),
                qty: visitors[key].qty.toString()
            })
        }

        let data = {
            title: props.formData.title.value,
            fullName: props.formData.firstName.value + ' ' + props.formData.lastName.value,
            ticketName: props.admissionDetail.tourAdmissionName,
            date: localStorage.getItem('date'),
            startPrice: props.admissionDetail.priceStartFrom.toString(),
            validUntil: props.admissionDetail.periodOfValidity,
            phoneNumber: props.formData.dialCode.value + '' + props.formData.phone.value,
            email: props.formData.email.value,
            question: props.formData.question.value,
            prices: prices,
        }

        props.submitBookingHandler(data)
    }

    let step = 1;
    let content = null;
    if (props.isErrorFetchAdmission) {
        content = <UnavailableTour
        showUnavailableTour={true}
        title="Admission Empty"
        description="Sorry, admission is unavailable. Please see other destinations."
        buttonLabel="Back to Home"
        redirect={backToHome}
        />;
    } else {
        if (props.isFetchAdmission) {
            content = <div className={classes.Loading}><Spiner/></div>;
        } else {
            if (props.admissionDetail) {
                if (props.isSuccessBooking) {
                    step = 2;
                    content = (
                        <SuccessPage
                            showPage={props.isSuccessBooking}
                            confirmClicked={() => backToHome()}
                            />
                    )
                } else {
                    content = (
                        <React.Fragment>
                            <div className={classes.Content}>
                                <div className="u-mb-24">
                                    <div className={classes.SubTitle}>Admission Detail</div>
                                    <PackageInfo 
                                        admissionDetail={props.admissionDetail}
                                        visitors={JSON.parse(localStorage.getItem('visitors'))}
                                        showPopupClicked={() => setShowAdmissionDetail(true)}
                                        tourData={props.tourDetail}/>
                                </div>
                                <div className="u-mb-24">
                                    <div className={classes.SubTitle}>Contact Information</div>
                                    <ContactInfo 
                                        contactInfo={props.contactInfoForm}
                                        inputChanged={(idendtifier, value) => props.inputChanged(idendtifier, value)}
                                        formData={props.formData}
                                        />
                                </div>
                                <div className="u-mt-16">
                                    <Button 
                                        classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                                        clicked={() => submitInquryHandler()}>
                                        Submit Inquiry
                                    </Button>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
            }
        }
    }

    return (
        <div className={classes.Wrapper}>
            <Confirm
                show={isContinueConfirm}
                title="Are you sure?"
                desc="Make sure your data is correct."
                cancelClicked={() => setIsContinueConfirm(false)}
                isConfirmLoading={props.isSubmitBooking}
                cancelLabel="No, check again"
                confirmLabel="Yes, continue"
                confirmClicked={submitData}
            />
            <AdmissionDetail
                admissionDetail={props.admissionDetail}
                show={showAdmissionDetail}
                hidePopupClicked={() => setShowAdmissionDetail(false)}/>
            <div className={classes.Header}>
                <Header 
                    classes={['Shadow']}
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    content="Admission Booking" />
            </div>
            <div className={classes.Step}>
                <Step type='inquiry' step={step} />
            </div>
            {content}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        admissionDetail: state.AdmissionDetailReducer.admissionDetail,
        isFetchAdmission: state.AdmissionDetailReducer.isFetchAdmissionDetail,
        isErrorFetchAdmission: state.AdmissionDetailReducer.isErrorFetchAdmissionDetail,
        formData: state.AdmissionBookingReducer.contactInfoForm,
        isValid: state.AdmissionBookingReducer.isValid,
        isSuccessBooking: state.AdmissionBookingReducer.isSuccessBooking,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onInitAdmission: (slug) => dispatch(DetailAction.initAdmissionDetail(slug)),
        inputChanged: (value, identifier) => dispatch(BookingAction.contactInfoChanged(value, identifier)),
        checkBookingForm: () => dispatch(BookingAction.checkBookingForm()),
        submitBookingHandler: (data) => dispatch(BookingAction.submitBooking(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(bookingContainer);
