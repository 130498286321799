import React, { Component } from 'react';
import classes from './SearchForm.module.scss';
import SearchInput from './TourInput/TourInput';
import ProductMenuList from './ProductMenuList/ProductMenuList';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import scrollToComponent from 'react-scroll-to-component';
import { withRouter } from 'react-router-dom';
import FlightInput from './FlightInput/FlightInput';
import HotelInput from './HotelInput/HotelInput';
import VirtualTourInput from './VirtualTourInput/VirtualTourInput';
import VoucherInput from './VoucherInput/VoucherInput';
import RedirectPopup from './RedirectPopup/RedirectPopup';
import ThingsToDo from './ThingstodoInput/ThingstodoInput';

import { connect } from 'react-redux';

class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBackdrop : false,
            productSelected: 'tour',
            isRedirectPopup: false
        }
    }

    componentDidMount() {
        let productSelected = this.props.location.pathname.substr(1);
        if (productSelected !== '') {
            this.setState({productSelected});
        }
    }

    setProductSelectedHandler = productSelected => {
        this.showBackdropHandler();
        this.setState({productSelected});
    }
    
    showBackdropHandler = () => {
        this.setState({showBackdrop: true});
    }
    
    hideBackdropHandler = () => {
        this.setState({showBackdrop: false});
    }
    
    goToTop = () => {
        const configScroll = { 
            offset: 0, 
            align: 'top', 
            duration: 700
        };
        scrollToComponent(this.SearchForm, configScroll);
    }

    searchTourClickedHandler = regionSelected => {
        this.props.history.push('/tour/availability?destination=' + regionSelected);
    }

    searchHotelClickedHandler = () => {
        this.props.history.push('/hotel/availability');
    }

    searchVirtualTourClickedHandler = () => {
        this.props.history.push('/virtual-tour/detail/' + this.props.virtualTours.slug);
    }

    searchVoucherClickedHandler = () => {
        this.props.history.push('/voucher/availability');
    }

    searchActivityClickedHandler = () => {
        this.props.history.push('/activity/availability');
    }

    searchAdmissionClickedHandler = () => {
        this.props.history.push('/admission/availability');
    }

    searchFlightClickedHandler = (url) => {
        this.setState({isRedirectPopup: true, showBackdrop: false});
        this.captchaInterval = setInterval(() => {
            clearInterval(this.captchaInterval);
            const fullPath = 'https://www.pergi.com/' + url;
            window.open().location = fullPath;
            // window.open(fullPath, '_blank');
            // window.location.assign(fullPath, '_blank');
            // window.location = fullPath;
            this.setState({isRedirectPopup: false});
        }, 4000);
    }

    render() {
        let searchInput = (
            <SearchInput
                hideBackdropClicked={this.hideBackdropHandler}
                showBackdrop={this.showBackdropHandler}
                searchTourClicked={ regionSelected => this.searchTourClickedHandler(regionSelected)} />
        );

        if (this.state.productSelected === 'flight') {
            searchInput = (
                <FlightInput
                    hideBackdropClicked={this.hideBackdropHandler}
                    showBackdrop={this.showBackdropHandler}
                    searchFlightClicked={ url => this.searchFlightClickedHandler(url)} />
            );
        } else if (this.state.productSelected === 'hotel') {
            searchInput = (
                <HotelInput
                    hideBackdropClicked={this.hideBackdropHandler}
                    showBackdrop={this.showBackdropHandler}
                    searchHotelClicked={ regionSelected => this.searchHotelClickedHandler(regionSelected)} />
            );
        } else if (this.state.productSelected === 'voucher') {
            searchInput = (
                <VoucherInput
                    hideBackdropClicked={this.hideBackdropHandler}
                    showBackdrop={this.showBackdropHandler}
                    exploreClicked={ voucherSelected => this.searchVoucherClickedHandler(voucherSelected)} />
            );
        } else if (this.state.productSelected === 'virtual-tour') {
            searchInput = (
                <VirtualTourInput
                    hideBackdropClicked={this.hideBackdropHandler}
                    showBackdrop={this.showBackdropHandler}
                    searchVirtualTourClicked={ regionSelected => this.searchVirtualTourClickedHandler()} />
            );
        } else if (this.state.productSelected === 'things-to-do') {
            searchInput = (
                <ThingsToDo 
                    backdropVisibility={this.state.showBackdrop}
                    hideBackdropClicked={this.hideBackdropHandler}
                    showBackdrop={this.showBackdropHandler}
                    searchActivityClicked={() => this.searchActivityClickedHandler()}
                    searchAdmissionClicked={() => this.searchAdmissionClickedHandler()}/>
            )
        }

        let zIndex = {
            zIndex:  100
        };
        if (this.state.showBackdrop) {
            zIndex = {
                zIndex:  660
            };
        }

        return (
            <div>
                <div className={classes.RedirectPopup}>
                    <RedirectPopup 
                        showPopup={this.state.isRedirectPopup}/>
                </div>
                <Backdrop 
                    showBackdrop={this.state.showBackdrop}
                    backdropClicked={this.hideBackdropHandler} />
                <div className={classes.Wrapper} style={zIndex} ref={(section) => {this.SearchForm = section}}>
                    <div className={classes.Container}>
                        <ProductMenuList 
                            productSelected={this.state.productSelected}
                            setProductSelected={this.setProductSelectedHandler} />
                        {searchInput}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        virtualTours: state.VirtualTourAvailability.virtualTours
    }
}

export default connect(mapStateToProps, null)(withRouter(SearchForm));