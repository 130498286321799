import React from 'react';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import BannerAvailability from '../../../../components/Desktop/Tour/BannerAvailability/BannerAvailability';
import ContentAvailability from '../../../../components/Desktop/Tour/ContentAvailability/ContentAvailability';
import MetaTag from '../../../../helper/MetaTag';

function availabilityContainer(props) {
    const scrollTop = () =>{
        window.scrollTo(0, 0);
    }

    scrollTop();
    
    return (
        <div className="bg-white-dark">
            <MetaTag 
                title="Tour Availability"/>
                
            <Header />
            <BannerAvailability />
            <ContentAvailability />
            <Footer />
        </div>
    );
}


export default availabilityContainer;