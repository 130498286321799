import React, { useEffect, useState } from 'react';
import classes from './MyCardContainer.module.scss';
import Icon from '../../../../components/UI/Icon/Icon';
import { COLOR } from '../../../../constant/Library';
import CreditCard from '../../../../components/Mobile/Shared/CreditCard/CreditCard';
import Popup from '../../../../components/UI/Popup/Popup';
import TextField from '../../../../components/UI/Inputs/TextField/TextField';
import Button from '../../../../components/UI/Button/Button';
import Swipeout from 'rc-swipeout';
import '../../../../../node_modules/rc-swipeout/dist/rc-swipeout.css';

const MyCardContainer = props => {
    const [showAddCard, setShowAddCard] = useState(false);
    const goToUrl = url => {
        props.history.push(url);
    }

    useEffect(() => {
        
    }, []);

    let addForm = (
        <div>
            <ul className={classes.Form}>
                <li className={classes.Row}>
                    <div className={classes.Input}>
                        <label>Card Number</label>
                        <TextField
                            placeholder="e.g. 4000 2020 4424 8868"
                        />
                    </div>
                </li>
                <li className={classes.Row}>
                    <div className={classes.Inputs}>
                        <div className={classes.Valid}>
                            <label>Valid until</label>
                            <TextField
                                placeholder="MM/YY"/>
                        </div>
                        <div className={classes.Cvv}>
                            <label>CVV</label>
                            <TextField
                                placeholder="3 Digits"/>
                        </div>
                    </div>
                </li>
                <li className={classes.Row}>
                    <div className={classes.Input}>
                        <label>Name on Card</label>
                        <TextField
                            placeholder="e.g. Julie Applased"
                        />
                    </div>
                </li>
                <Button
                    classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
                    clicked={() => console.log('Save Card')}
                    children="Save"/>
            </ul>
        </div>
    );

    return (
        <div>
            <Popup show={showAddCard}
                title="Add Bank Card"
                content={addForm}
                hideClicked={() => setShowAddCard(false)}/>

            <div className={[classes.Shadow, classes.Header].join(' ')}>
                <span className={classes.Action}
                    onClick={() => goToUrl('/user')}>
                    <Icon name="ico-arrow-left" fill={COLOR.white} stroke="none" />
                </span>
                <span>My Card</span>
            </div>

            <div className={classes.ContentWrapper}>
                <div className={classes.ContentSwiper}>
                    <div className={classes.SwiperItem}>
                        <Swipeout
                            left={[
                                {
                                text: <div className={classes.Delete}><Icon name="ico-trash" width={20} fill="white" stroke="none"/></div>,
                                onPress:() => alert("Yakin hapus?"),
                                }
                            ]}
                            onOpen={() => console.log('open')}
                            onClose={() => console.log('close')}>
                                <CreditCard isActive={true}/>
                        </Swipeout>
                    </div>
                    <div className={classes.SwiperItem}>
                        <Swipeout
                            left={[
                                {
                                text: <div className={classes.Delete}><Icon name="ico-trash" width={20} fill="white" stroke="none"/></div>,
                                onPress:() => alert("Yakin hapus?"),
                                }
                            ]}
                            onOpen={() => console.log('open')}
                            onClose={() => console.log('close')}>
                                <CreditCard/>
                        </Swipeout>
                    </div>
                </div>

                <div className={classes.AddCard}
                    onClick={() => setShowAddCard(true)}>
                    <div className={classes.Label}>
                        <div className={classes.LabelIcon}>
                            <Icon name="ico-plus-circle-o" fill={COLOR.greyLight} stroke="none" width={28}/>
                        </div>
                        <div>Add Bank Card</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyCardContainer;