import React from 'react';
import ContentLoader from 'react-content-loader';

const imageSliderLoader = () => {
    return (
        <ContentLoader
            height={255}
            primaryColor={'#d6d6d6'}
            speed={1}
        >
        </ContentLoader>
    )
}

export default imageSliderLoader;