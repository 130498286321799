import React from 'react';
import classes from './CorporateTravelServices.module.scss';
import TravelServiceItem from './TravelServiceItem/TravelServiceItem';
import { NEW_CORPORATE_TRAVEL_SERVICES } from '../../../../../constant/Content';

const  corporateTravelServices = () => {
    const services = NEW_CORPORATE_TRAVEL_SERVICES.map((service, index) => {
        return (
            <div className={classes.Item} key={index}>
                <TravelServiceItem service={service} />
            </div>
        );
    });
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className={classes.Title}>Corporate Travel Services</div>
                <div className={classes.List}>
                    {services}
                </div>
            </div>
        </div>
    );
}

export default corporateTravelServices;