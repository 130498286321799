import React from 'react';
import classes from './LeftContent.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Form from './Form/Form';
import List from "../../../../UI/List/List";
import { ABOUT_FREE_VOUCHER } from '../../../../../constant/Content';

const leftContent = (props) => {
    return (
        <div>
            <div className="u-mb-24">
                <div className={classes.Attention}>
                    <div className={classes.Bold}>OPEN DATE</div> : Pilihan tanggal bisa ditentukan nanti (maksimal 7 hari sebelum check in).
                </div>
            </div>
            <div className="u-mb-24">
                <Form
                    isContinuePaymentHandler={(val) => props.isContinuePaymentHandler(val)}
                    hotelDetail={props.hotelDetail}/>
            </div>
            <PanelBox>
                <div className={classes.Wrapper}>
                    <div className={classes.Title}>About Free Voucher Tour</div>
                    <div className="u-p-24">
                        <List list={ABOUT_FREE_VOUCHER}/>
                    </div>
                    </div>
            </PanelBox>
        </div>
    )
}

export default leftContent;