import React, { useState } from 'react';
import classes from './FreeVoucher.module.scss';
import Icon from '../../../UI/Icon/Icon';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import CSSTransition from 'react-transition-group/CSSTransition';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import List from '../../../UI/List/List';
import { ABOUT_FREE_VOUCHER } from '../../../../constant/Content';

function freeVoucher(props) {
    const [showFreeVoucher, setShowFreeVoucher] = useState(false);

    let zIndex = {
        zIndex:  100
    };
    let iconClasses = [classes.Icon]
    if (showFreeVoucher) {
        zIndex = {
            zIndex:  660
        };
        iconClasses.push(classes.Active);
    }
    return (
        <div className={classes.Wrapper}>
            <Backdrop 
                showBackdrop={showFreeVoucher} 
                backdropClicked={() => setShowFreeVoucher(false)}/>
            <div className={classes.FreeVoucher} style={zIndex} onClick={() => setShowFreeVoucher(true)}>
                <span>
                    <span className={classes.Main}>Free Voucher Tour</span>
                    <span>Get Rp 500.000 Free Voucher</span> 
                </span>
                <span className={iconClasses.join(' ')}>
                    <Icon name="ico-chev-right" fill="#2CADB5" stroke="none" />
                </span>
            </div>

            <CSSTransition
                in={showFreeVoucher}
                mountOnEnter
                unmountOnExit
                timeout={270}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
                }}>
                <div className={classes.About} style={zIndex}>
                    <PanelBox>
                        <div className={classes.Panel}>
                            <div className={classes.Panel_Head}>About Free Voucher Tour</div>
                            <div className={classes.Panel_Body}>
                                <List list={ABOUT_FREE_VOUCHER}/>
                            </div>
                        </div>
                    </PanelBox>
                </div>
            </CSSTransition>
        </div>
    );
}

export default freeVoucher;