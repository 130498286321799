import React from 'react';
import classes from './Participant.module.scss';
import ContentLoader from 'react-content-loader';
import { getTitle } from '../../../../../helper/GlobalFunc';

const participant = (props) => {
    let participantList = null;
    
    
    let content = <div className={classes.Wrapper}>
            <ContentLoader
                height={60}
                speed={1}
                primaryColor={'#e6e6e6'}>
                <rect x="0" y="0" rx="2" ry="2" width="50" height="8" />
                <rect x="0" y="25" rx="2" ry="2" width="80" height="10" />
                <rect x="0" y="45" rx="2" ry="2" width="80" height="10" />
                <rect x="200" y="25" rx="2" ry="2" width="80" height="10" />
                <rect x="200" y="45" rx="2" ry="2" width="80" height="10" />
            </ContentLoader>
        </div>

    if (!props.isLoading && props.bookingData) {
        let participants = props.bookingData.participant;
        participantList = participants.map((participant, index) => {
            return <li key={index} className={classes.Item}>
                    {index+1}. {getTitle(participant.title)} {participant.name}
                </li>
        });

        content = <div className={classes.Wrapper}>
            <div className={classes.Title}>Participants</div>
            <ul className={classes.List}>
                {participantList}
            </ul>
        </div>
    }

    return <div>{content}</div>
}

export default participant;