import React from 'react';
import classes from './PriceLoader.module.scss';
import ContentLoader from 'react-content-loader';

const priceLoader = () => {
    return (
        <div className={classes.Wrapper}>
            <ContentLoader
                    height={150}
                    speed={1}
                    primaryColor={'#e6e6e6'}
            >  
                <rect x="0" y="30" rx="4" ry="4" width="100" height="30" />
                <rect x="150" y="30" rx="4" ry="4" width="250" height="30" />
                <rect x="0" y="90" rx="4" ry="4" width="398" height="50" />
            </ContentLoader>
        </div>
    )
}

export default priceLoader;