import React, { Component } from 'react'
import Header from '../../../../components/Desktop/Shared/Header/Header'
import Footer from '../../../../components/Desktop/Shared/Footer/Footer'
import Corporate from '../../../../components/Desktop/NonProducts/Corporate/Corporate'
import MetaTag from '../../../../helper/MetaTag'
import classes from './CorporateBanner.module.scss'
import { DEFAULT_IMG } from '../../../../constant/Content'
import LOGO from '../../../../assets/images/CorpTravel_Logo.svg'

class CorporateContainer extends Component {
	state = {}
	render() {
		return (
			<div className='min-width'>
				<MetaTag title='Corporate' />
				<Header />
				<Banner title='Corporate' image='https://media01.cdnpergi.com/antavaya/images/corporate-1.jpg' />
				<Corporate />
				<Footer corporate />
			</div>
		)
	}
}

export default CorporateContainer

const Banner = (props) => {
	return (
		<div className={classes.Wrapper}>
			<img className={classes.BackImage} onError={DEFAULT_IMG} src={props.image} alt={props.title} />
			<div className={classes.Overlay} />
			<img className={classes.Logo} src={LOGO} alt={props.title} />
		</div>
	)
}
