import React, { Component } from 'react';
import classes from './Ticket.module.scss';
import Icon from '../../../../../UI/Icon/Icon';
import CSSTransition from 'react-transition-group/CSSTransition';
import onClickOutside from "react-onclickoutside";
import { currencyFormat } from '../../../../../../helper/GlobalFunc';

class SelectTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTicket: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ticket !== null) {
            this.setState({showTicket: false})
        }
    }

    showTicketHandler = () => {
        this.setState({ showTicket: true })
    }

    changeTicket = (id, priceArray) => {
        this.props.onSetTicketClicked(id, priceArray)
    }

    handleClickOutside = (evt) => {
        this.setState({showTicket: false});
    }

    render() {
        let iconClasses = [classes.Icon];
        if (this.state.showTicket) {
            iconClasses.push(classes.IsActive);
        }
        
        let content = null;
        if (this.props.ticket === null) {
            let items = this.props.tickets.map((item, index) => {

                let iconSelected = 'ico-radio-button-unchecked';
                let fillSelected = '#c2c2c2';
                let sizeSelected = 14;
                if (index === this.props.ticket) {
                    iconSelected = 'ico-check-circle-white';
                    fillSelected = '#CD2033';
                    sizeSelected = 22;
                }

                return (
                    <div key={index} className={classes.Item} onClick={() => this.changeTicket(index, item.priceArray)}>
                        <div className={classes.Body}>
                            <div className={classes.Title}>
                                {item.name}
                            </div>
                            <Icon name={iconSelected} fill={fillSelected} stroke="none" width={sizeSelected}/>
                        </div>
                        <div className={classes.Footer}>
                            <label>Start From</label>
                            <div className={classes.Price}>
                                {currencyFormat(item.priceStartFrom)}
                            </div>
                        </div>
                    </div>
                )
            })

            content = (
                <div className={classes.Content} onClick={() => this.showTicketHandler(true)}>
                    <div className={classes.Input}>
                        <div>
                            Selected Package
                        </div>
                        <div className={iconClasses.join(' ')}>
                            <Icon name="ico-caret-down" fill="#202124" stroke="none" />
                        </div>
                    </div>
                    <CSSTransition
                        in={this.state.showTicket}
                        mountOnEnter
                        unmountOnExit
                        timeout={270}
                        classNames={{
                            enterActive: classes.Open,
                            exitActive: classes.Close
                        }}>
                            <div className={classes.Ticket} id={classes.StyleScroll}>
                                {items}
                            </div>
                    </CSSTransition>
                </div>
            )

        } else {
            let iconClasses = [classes.Icon];
            if (this.state.showTicket) {
                iconClasses.push(classes.IsActive);
            }

            let selectedTicket = this.props.tickets.filter((ticket, index) => (index === this.props.ticket))
            let items = this.props.tickets.map((item, index) => {
                let iconSelected = 'ico-radio-button-unchecked';
                let fillSelected = '#c2c2c2';
                let sizeSelected = 14;
                if (index === this.props.ticket) {
                    iconSelected = 'ico-check-circle-white';
                    fillSelected = '#CD2033';
                    sizeSelected = 22;
                }
                return (
                    <div key={index} className={classes.Item} onClick={() => this.changeTicket(index, item.priceArray)}>
                        <div className={classes.Body}>
                            <div className={classes.Title}>
                                {item.name}
                            </div>
                            <Icon name={iconSelected} fill={fillSelected} stroke="none" width={sizeSelected} />
                        </div>
                        <div className={classes.Footer}>
                            <label>Start From</label>
                            <div className={classes.Price}>
                                {currencyFormat(item.priceStartFrom)}
                            </div>
                        </div>
                    </div>
                )
            })

            content = (
                <div className={classes.Selected} onClick={() => this.showTicketHandler(true)}>
                    <div className={classes.Item}>
                        <div className={classes.Body}>
                            <div className={classes.Title}>
                                {selectedTicket[0].name}
                            </div>
                            <div className={iconClasses.join(' ')}>
                                <Icon name="ico-caret-down" fill="#202124" stroke="none" />
                            </div>
                        </div>
                        <div className={classes.Footer}>
                            <label>Start From</label>
                            <div className={classes.Price}>
                                {currencyFormat(selectedTicket[0].priceStartFrom)}
                            </div>
                        </div>
                    </div>
                    <CSSTransition
                        in={this.state.showTicket}
                        mountOnEnter
                        unmountOnExit
                        timeout={270}
                        classNames={{
                            enterActive: classes.Open,
                            exitActive: classes.Close
                        }}>
                            <div className={classes.Ticket} id={classes.StyleScroll}>
                                {items}
                            </div>
                    </CSSTransition>
                </div>
            )

        }
        
        return (
            <div className={classes.Wrapper}>
                <div className={classes.Item}>
                    <div className={classes.Label}>
                        <Icon name="ico-voucher" width={20} stroke="none" fill="#CD2033" />
                        <label>Ticket</label>
                    </div>
                    {content}
                </div>
            </div>
        )

    }
}

export default onClickOutside(SelectTicket);