import React, { useEffect } from 'react';
import Header from '../../../../components/Mobile/Shared/Header/Header'
import classes from './RegisterContainer.module.scss'
import PanelBox from '../../../../components/UI/PanelBox/PanelBox'
import TextField from '../../../../components/UI/Inputs/TextField/TextField'
import Select from '../../../../components/UI/Inputs/Select/Select';
import Button from '../../../../components/UI/Button/Button'
// import icoFb from '../../../../assets/images/ico-color-facebook.png'
// import icoGoogle from '../../../../assets/images/ico-google.png'
import MobileSelectFlag from '../../../../components/UI/Inputs/MobileSelectFlag/MobileSelectFlag'
import VerifyEmail from '../../../../components/Mobile/NonProduct/VerifyEmail/VerifyEmail';
import logo from '../../../../assets/images/logo-light.png';

import { connect } from 'react-redux';
import * as signupAction from '../../../../redux/Shared/SignUp/SignupAction';
import Auth from '../../../../middleware/Auth';

const registerContainer = props => {
	useEffect(() => {
		let isLogin = Auth.isAuthenticated();
		if(isLogin) {
			props.history.push('/user')
		}
	}, []);

	const goBack = () => {
		props.history.goBack()
	}

	const register = () => {
		props.signUpHandler(props.signupData);
	}

	const login = () => {
		props.history.push('/login')
	}
    
	return (
		<div className={classes.Background}>
			<VerifyEmail
				show={props.successSignup} />
			<div className={classes.Head}>
				<Header classes={['ClBlack', 'BgRed']} 
					icoColor="white"
					leftIcon="back"
					leftClicked={goBack}
					align="center"
					content={<div className={classes.Logo}><img src={logo} alt="antavaya" /></div>}/>
			</div>
			<div className={classes.Content}>
				<p className={classes.Title}>REGISTER</p>
				<p className={classes.SubTitle}>
					Join with us Antavaya Member and enjoy benefits like fast & secure
					payment, amazing deal on tour and much more
				</p>
				<div className={classes.Panel}>
					<PanelBox>
						<div className={classes.Card}>
							<div className={classes.Input}>
								<label className={classes.Label}>Title</label>
								<Select
									value={props.signupData.title.value}
									options={props.signupData.title.options}
									changed={(val) => props.titleChangedHandler(val)}
								/>
							</div>
							<div className={classes.Input}>
								<label className={classes.Label}>First Name</label>
								<TextField
									placeholder='e.g. Julie'
									value={props.signupData.firstname.value}
									changed={(event) => props.inputChangeHandler(event, 'firstname')}
								/>
							</div>
							<div className={classes.Input}>
								<label className={classes.Label}>Last Name</label>
								<TextField
									placeholder='e.g. Applesed'
									value={props.signupData.lastname.value}
									changed={(event) => props.inputChangeHandler(event, 'lastname')}
								/>
							</div>
							<div className={classes.Input}>
								<label className={classes.Label}>Email</label>
								<TextField
									placeholder='e.g. youremail@example.com'
									value={props.signupData.email.value}
									changed={(event) => props.inputChangeHandler(event, 'email')}
								/>
							</div>
							<div className={classes.Input}>
								<label className={classes.Label}>Password</label>
								<TextField
									type='password'
									placeholder='minimum 6 characters'
									value={props.signupData.password.value}
									changed={(event) => props.inputChangeHandler(event, 'password')}
								/>
							</div>
							{/* <div className={classes.Input}>
                                <label className={classes.Label}>Confirm Password</label>
                                <TextField
                                type='password'
                                placeholder='must match the previous entry'
                                value={}
                                changed={(event) => inputChangeHandler(event, 'password')}
                                />
                            </div> */}
							<div className={[classes.Input, classes.PhoneWrapper].join(' ')}>
								<label className={classes.Label}>Phone Number</label>
								<div className={classes.Inline}>
									<div className={classes.DialCode}>
										<MobileSelectFlag
											value={props.signupData.dialCode.value}
											selected={val => props.setDialCodeHandler(val)} />
									</div>
									<div className={classes.Phone}>
										<TextField
											type='text'
											placeholder='e.g. 8123456789'
											value={props.signupData.phone.value}
											changed={(event) => props.inputChangeHandler(event, 'phone')}/>
									</div>
								</div>
							</div>
						</div>
					</PanelBox>
				</div>
				<Button
					classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
					disabled={!props.isValidForm}
					clicked={register}>
					Register
				</Button>
				{/* <div className={classes.Sparator}>
					<span className={classes.Text}>or register with</span>
				</div>
				<div className={classes.SocmedLogin}>
					<div className={[classes.Socmed, classes.FbBtn].join(' ')}>
						<img className={classes.Icon} src={icoFb} alt='facebook' />{' '}
						Facebook
					</div>
					<div className={[classes.Socmed, classes.GoogleBtn].join(' ')}>
						<img className={classes.Icon} src={icoGoogle} alt='google' />{' '}
						Google
					</div>
				</div> */}
				<div className={classes.LabelHeader}>
					Already have an account?{' '}
					<span
						className={[classes.Link, classes.Bold].join(' ')}
						onClick={login}>
						Login
					</span>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		state: state.SignupReducer,
		signupData: state.SignupReducer.signupData,
		successSignup: state.SignupReducer.successSignup,
		isValidForm: state.SignupReducer.isValidForm
	}
}

const mapDispatchToProps = dispatch => {
	return {
		inputChangeHandler: (event, identifier) => dispatch(signupAction.inputChangeHandler(event, identifier)),
		setDialCodeHandler: (val) => dispatch(signupAction.setDialCodeHandler(val)),
		signUpHandler: (signupData) => dispatch(signupAction.signUpHandler(signupData)),
		titleChangedHandler: (val) => dispatch(signupAction.titleChangedHandler(val))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(registerContainer);
