import { updateObject } from '../../utility';
import * as validation from '../../../helper/Validation';
import { ADULT_TITLE, DEFAULT_TITLE } from '../../../constant/General';
import Moment from 'moment';

const getDay = (month, year) => {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date).getDate());
        date.setDate(date.getDate() + 1);
    }
    return days;
}

const currentDate = new Date();
const indexMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();

const days = getDay(indexMonth, currentYear);
const dayOptions = days.map((day, index) => {
    return {
        value: index + 1,
        text: day
    }
});

const monthOptions = Moment.months().map((month, index) => {
    return {
        value: index + 1,
        text: month
    }
});

let yearOption = [];
const limitYear = 80;
for (let index = currentYear; index >= currentYear - limitYear; index--) {
    yearOption.push({
        value: index,
        text: index
    });
}

const initialState = {
    isValidForm: false,
    successMemberChanged: false,
    isSubmitLoading: false,
    isSendEmailVerifyLoading: false,
    isSuccessSendEmailVerify: false,
    otp: '',
    showOtpForm: false,
    member: null,
    formName: {
        title: {
            value: DEFAULT_TITLE,
            options: ADULT_TITLE,
            hasError: false,
            errorMsg: '',
            rules : {
                required: true
            }
        },
        firstName: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
            }
        },
        lastName: {
            value: '',
            hasError: false,
            errorMsg: '',
            rules : {}
        },
    },
    formEmail: {
        email: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
                isEmail: true
            }
        },
    },
    formPhone: {
        dialCode: {
            value: '',
            hasError: false,
            errorMsg: '',
            rules : {
                required: true,
            }
        },
        phone: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
                isNumericPhone: true
            }
        }
    },
    changePassword: {
        oldPassword: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true
            }
        },
        newPassword: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true
            }
        },
        confirmPassword: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true
            }
        },
    },
    formCitizenshipNumber: {
        citizenshipNumber: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
                isNumeric: true
            }
        },
    },
    formBirthDate: {
        day: {
            value: '',
            options: dayOptions,
            hasError: true,
            errorMsg: '',
            validation : {
                required: true
            }
        },
        month: {
            value: '',
            options: monthOptions,
            hasError: true,
            errorMsg: '',
            validation : {
                required: true
            }
        },
        year: {
            value: '',
            options: yearOption,
            hasError: true,
            errorMsg: '',
            validation : {
                required: true
            }
        }
    },
    formBirthDateMobile: {
        birthDate: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                // required: true
            }
        }
    }
}

const setValidForm = (inputForm) => {
    let isValidForm = true;
    if (inputForm) {
        for (const inputIdentifier in inputForm) {
            if (inputForm[inputIdentifier].value !== undefined && inputForm[inputIdentifier].rules !== undefined ) {
                if (inputForm[inputIdentifier].hasError) {
                    isValidForm = false;
                    break;
                }
            }
        }
    }
    return isValidForm;
}

const inputChangedName = (state, action) => {
    let formNameCp = {...state.formName};
    let isValidForm = {...state.isValidForm};
    let validate = validation.validate(formNameCp[action.payload.inputIdentifier].rules, action.payload.value);

    formNameCp[action.payload.inputIdentifier] = {...formNameCp[action.payload.inputIdentifier], ...validate};
    isValidForm = setValidForm(formNameCp);

    // updateLocalStorage(action.payload.inputIdentifier, action.payload.value)

    return updateObject(state, {formName: formNameCp, isValidForm})
}

const inputChangedEmail = (state, action) => {
    let formEmailCp = {...state.formEmail};
    let isValidForm = {...state.isValidForm};

    let validate = validation.validate(formEmailCp[action.payload.inputIdentifier].rules, action.payload.value);

    formEmailCp[action.payload.inputIdentifier] = {...formEmailCp[action.payload.inputIdentifier], ...validate};
    isValidForm = setValidForm(formEmailCp);
    return updateObject(state, {formEmail: formEmailCp, isValidForm})
}

const successUpdateEMail = (state) => {
    return updateObject(state, {successChangeEmail: !state.successChangeEmail})
}

const dialCodeChanged = (state, action) => {
    let formPhoneCp = {...state.formPhone};
    let isValidForm = {...state.isValidForm};
    let validate = validation.validate(formPhoneCp.dialCode.value.rules, action.payload.value);

    formPhoneCp.dialCode = {...formPhoneCp.dialCode, ...validate};
    isValidForm = setValidForm(formPhoneCp);

    return updateObject(state, {formPhone: formPhoneCp, isValidForm});
}


const inputChangedPhone = (state, action) => {
    let formPhoneCp = {...state.formPhone};
    let isValidForm = {...state.isValidForm};

    let validate = validation.validate(formPhoneCp[action.payload.inputIdentifier].rules, action.payload.value);

    formPhoneCp[action.payload.inputIdentifier] = {...formPhoneCp[action.payload.inputIdentifier], ...validate};
    isValidForm = setValidForm(formPhoneCp);
    if (action.payload.value !== 0) {
        return updateObject(state, {formPhone: formPhoneCp}, isValidForm)
    }
}

const setSuccessChangedPassword = (state) => {
    return updateObject(state, { successChangePassword: !state.successChangePassword });
}

const selectChangedDateOfBirth = (state, action) => {
    let formBirthDateCp = {...state.formBirthDate};
    let isValidForm = {...state.isValidForm};
    let validate = validation.validate(formBirthDateCp[action.payload.inputIdentifier].rules, action.payload.value);

    formBirthDateCp[action.payload.inputIdentifier] = {...formBirthDateCp[action.payload.inputIdentifier], ...validate};
    isValidForm = setValidForm(formBirthDateCp);

    return updateObject(state, {formBirthDate: formBirthDateCp}, isValidForm);

}

const dateBirthProfileChanged = (state, action) => {
    let formBirthDateMobileCp = {...state.formBirthDateMobile};
    let isValidForm = {...state.isValidForm};

    let validate = validation.validate(formBirthDateMobileCp[action.payload.inputIdentifier].rules, new Date(action.payload.value));
    formBirthDateMobileCp[action.payload.inputIdentifier] = {...formBirthDateMobileCp[action.payload.inputIdentifier], ...validate};

    isValidForm = setValidForm(formBirthDateMobileCp);
    return updateObject(state, {formBirthDateMobile: formBirthDateMobileCp}, isValidForm);
}

const inputChangedKTP = (state, action) => {
    let formCitizenshipNumberCp = {...state.formCitizenshipNumber};
    let isValidForm = {...state.isValidForm};
    let validate = validation.validate(formCitizenshipNumberCp[action.payload.inputIdentifier].rules, action.payload.value);

    formCitizenshipNumberCp[action.payload.inputIdentifier] = {...formCitizenshipNumberCp[action.payload.inputIdentifier], ...validate};
    isValidForm = setValidForm(formCitizenshipNumberCp);

    return updateObject(state, {formCitizenshipNumber: formCitizenshipNumberCp, isValidForm})
}

const titleChanged = (state, action) => {
    let formNameCp = {...state.formName};
    let isValidForm = {...state.isValidForm};
    let validate = validation.validate(formNameCp.title.rules, action.payload.value);
    formNameCp.title = {...formNameCp.title, ...validate}

    isValidForm = setValidForm(formNameCp);

    return updateObject(state, { formName: formNameCp, isValidForm });
}

const setMemberDetail = (state, action) => {
    let isValidForm = {...state.isValidForm};
    let dataMember = action.payload.dataMember;
    let formNameCp = {...state.formName};
    let formEmailCp = {...state.formEmail};
    let formPhoneCp = {...state.formPhone};
    let formBirthDateCp = {...state.formBirthDate};
    let formBirthDateMobileCp = {...state.formBirthDateMobile};
    let formCitizenshipNumberCp = {...state.formCitizenshipNumber};

    let date = new Date(dataMember.birthDate);
    let birthDate = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear()
    }
    let phoneNumber = {
        dialCode: dataMember.countryCode,
        phone: dataMember.phone
    }
    let birthDateMobile = {
        // birthDate: new Date(dataMember.birthDate)
        birthDate: (dataMember.birthDate) ? new Date(dataMember.birthDate) : new Date()
    }
    
    for (const keyDataMember in dataMember) {
        for (const key in formNameCp) {
            if (key === keyDataMember) {
                let validateFormName = validation.validate(formNameCp[keyDataMember].rules, dataMember[keyDataMember]);
                formNameCp[keyDataMember] = {...formNameCp[keyDataMember], ...validateFormName}
            }
        }
        for (const key in formEmailCp) {
            if (key === keyDataMember) {
                let validateEmail = validation.validate(formEmailCp[keyDataMember].rules, dataMember[keyDataMember]);
                formEmailCp[keyDataMember] = {...formEmailCp[keyDataMember], ...validateEmail}
            }
        }
        for (const key in formCitizenshipNumberCp) {
            if (key === keyDataMember) {
                let validateCitizenshipNumber = validation.validate(formCitizenshipNumberCp[keyDataMember].rules, dataMember[keyDataMember]);
                formCitizenshipNumberCp[keyDataMember] = {...formCitizenshipNumberCp[keyDataMember], ...validateCitizenshipNumber}
            }
        }
    }

    for (const keyBirthDate in birthDate) {
        for (const key in formBirthDateCp) {
            if (keyBirthDate === key) {
                let validateBirthDate = validation.validate(formBirthDateCp[key].rules, birthDate[keyBirthDate]);
                formBirthDateCp[key] = {...formBirthDateCp[key], ...validateBirthDate}
            }
        }
    }

    for (const key in formPhoneCp) {
        for (const keyhoneNumber in phoneNumber) {
            if (keyhoneNumber === key) {
                let validateEmail = validation.validate(formPhoneCp[key].rules, phoneNumber[keyhoneNumber]);
                formPhoneCp[key] = {...formPhoneCp[key], ...validateEmail}
            }
        }
    }

    for (const key in formBirthDateMobileCp) {
        for (const keybirthDateMobile in birthDateMobile) {
            if (keybirthDateMobile === key) {
                let validateBirthDateMobile = validation.validate(formBirthDateMobileCp.birthDate.rules, birthDateMobile.birthDate);
                formBirthDateMobileCp[key] = {...formBirthDateMobileCp[key], ...validateBirthDateMobile}
            }
        }
    }

    isValidForm = setValidForm(formNameCp);

    return updateObject(state, {
        member: dataMember,
        formName: formNameCp,
        formEmail: formEmailCp,
        formPhone: formPhoneCp,
        formBirthDateMobile: formBirthDateMobileCp,
        formBirthDate: formBirthDateCp,
        formCitizenshipNumber: formCitizenshipNumberCp,
        isValidForm });
}

const successMemberChanged = (state) => {
    return updateObject(state, { successMemberChanged: !state.successMemberChanged })
}


const inputChanged = (state, action) => {
    let changePasswordCp = {...state.changePassword};
    let isValidForm = {...state.isValidForm};

    let validate = validation.validate(changePasswordCp[action.payload.inputIdentifier].rules, action.payload.value);
    changePasswordCp[action.payload.inputIdentifier] = {...changePasswordCp[action.payload.inputIdentifier], ...validate};

    if (confirmPasswordHandler(changePasswordCp.newPassword.value, changePasswordCp.confirmPassword.value)) {
        isValidForm = setValidForm(changePasswordCp, state);
    } else {
        isValidForm = false;
    }

    return updateObject(state, {changePassword: changePasswordCp, isValidForm});
}

const confirmPasswordHandler = (newPassword, confirmPassword) => {
    if (newPassword === confirmPassword) {
        return true;
    }
}

const setIsSuccessSendEmailVerify = (state, action) => {
    return updateObject(state, {isSuccessSendEmailVerify: action.payload.value});
}

const successVerificationEmail = (state) => {
    window.location.href = '/';
    
}

const successChangedPhone = (state) => {
    return updateObject(state, { successChangePhone: true });
}

const setIsSubmitLoading = (state, action) => {
    return updateObject(state, { isSubmitLoading: action.payload.value });
}

const setIsSendEmailVerifyLoading = (state, action) => {
    return updateObject(state, { isSendEmailVerifyLoading: action.payload.value });
}

const setShowOtpForm = (state, action) => {
    return updateObject(state, { showOtpForm: action.payload.value });
}
const setOtp = (state, action) => {
    return updateObject(state, { otp: action.payload.value });
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case 'SET_MEMBER_DETAIL': return setMemberDetail(state, action);
        case 'SET_IS_SUBMIT_MEMBER_LOADING': return setIsSubmitLoading(state, action);
        case 'SET_IS_SUCCESS_SEND_EMAIL_VERIFY': return setIsSuccessSendEmailVerify(state, action);
        case 'SET_IS_SEND_EMAIL_VERIFY_LOADING': return setIsSendEmailVerifyLoading(state, action);
        case 'SET_SHOW_OTP_FORM': return setShowOtpForm(state, action);
        case 'SET_OTP': return setOtp(state, action);

        case 'SELECT_CHANGED_TITLE': return titleChanged(state, action);
        case 'INPUT_CHANGED_NAME': return inputChangedName(state, action);

        case 'INPUT_CHANGED_EMAIL': return inputChangedEmail(state, action);
        case 'SUCCESS_UPDATE_EMAIL': return successUpdateEMail(state);

        case 'SET_DIAL_CODE_HANDLER' : return dialCodeChanged(state, action);
        case 'INPUT_CHANGED_PHONE': return inputChangedPhone(state, action);

        case 'INPUT_CHANGED_PASSWORD': return inputChanged(state, action);

        case 'SELECT_CHANGED_DATE_OF_BIRTH': return selectChangedDateOfBirth(state, action);
        case 'DATE_BIRTH_PROFILE_CHANGED': return dateBirthProfileChanged(state, action);

        case 'INPUT_CHANGED_KTP': return inputChangedKTP(state, action);

        case 'SUCCESS_CHANGED_PASSWORD': return setSuccessChangedPassword(state);
        case 'SUCCESS_VERIFICATION_EMAIL': return successVerificationEmail(state, action);
        case 'SUCCESS_MEMBER_CHANGED': return successMemberChanged(state);
        case 'SUCCESS_CHANGED_PHONE': return successChangedPhone(state);
        default: return state;
    }
}

export default reducer;