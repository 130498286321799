import React from 'react';
import classes from './SuccessMessage.module.scss';
import Button from '../../../../components/UI/Button/Button';
import artSubmitApplication from '../../../../assets/images/art-submit-application.png';

const successMessage = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Content}>
                <img src={artSubmitApplication} className={classes.Image} alt="booking not found" />
                <div className={classes.Title}>{props.title}</div>
                <div className={classes.SubTitle}>{props.subTitle}</div>
                <div className={classes.Action}>
                    <Button 
                        classes={['BtnRed', 'BtnMedium']}
                        clicked={props.clicked}>
                        {props.labelBtn}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default successMessage;