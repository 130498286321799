import React from 'react';
import classes from './WhatsappLink.module.scss';
import Icon from '../../../UI/Icon/Icon';
import {ANTAVAYA} from '../../../../constant/Antavaya';

const whatsappLink = (props) => {
    let whatsappLink = ANTAVAYA.whatsappUrl;
    if (props.whatsappLink) {
        whatsappLink = props.whatsappLink;
    }
    return (
        <div className={classes.Wrapper}>
            <a href={whatsappLink} target="blank" className={classes.Button}>
                <Icon name="ico-whatsapp" stroke="none" fill="#ffffff" />
            </a>
        </div>
    );
}

export default whatsappLink;