import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import * as authAction from '../../../../redux/Shared/Auth/AuthAction';
import classes from './Activation.module.scss';

function activationContainer (props) {
    useEffect(() => {
        props.activationHandler(props.match.params.activationCode)
    }, []);

    useEffect(() => {
        if (localStorage.getItem('sessionKey') && localStorage.getItem('userProfile')) {
            props.history.push('/')
        }
    }, [props.isLogin])

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Content}>
                <div className={classes.Loader}></div>
                <div className={classes.Text}>Please wait...</div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogin: state.AuthReducer.isLogin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        activationHandler: (activationCode) => dispatch(authAction.activationAccount(activationCode))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(activationContainer);
