import React, { Component } from 'react'
import classes from './DetailPage.module.scss'
import PriceDetail from '../../../../../../components/Mobile/Payment/PriceDetail/PriceDetail'
import BookingPaymentDetail from './Component/BookingPaymentDetail/BookingPaymentDetail'
import Participant from './Component/Participant/Participant'
import TourInfo from "../../../../../../components/Mobile/Payment/TourDetail/TourInfo/TourInfo";

class DetailPage extends Component {
  render() {
    return (
      <div className={classes.Content}>
        <BookingPaymentDetail />
        <div className={classes.HeadTitle}>Tour Details</div>
        <TourInfo tourData={DataDummy} />
        <Participant />
        <div className={classes.PriceWrapper}>
          <PriceDetail />
        </div>
      </div>
    )
  }
}

export default DetailPage

const DataDummy = {
  days: 10,
  isFeatured: true,
  night: 7,
  paxPriceStartFrom: 26990000,
  storeName: "Hayam Wuruk",
  storeRegionName: "Jakarta",
  tourCancellation: "Hingga 45 hari = senilai uang muka pendaftaran.; 44 - 16 hari = 50 % dari harga tour.; 15 - 08 hari = 75 % dari harga tour.; 07 - hari H / No Show = 100 % dari harga tour.;",
  tourCategoryName: "Series",
  tourDescription: "10 Hari / 07 Malam - China Airlines;",
  tourExclude: "PPN 1 %.;Tas tour ukuran cabin.;Asuransi perjalanan (pertanggungan hingga usia 80 thn).;Airport tax international & fuel surcharge.;Biaya pembuatan visa USA & passpor.;Pengeluaran yang bersifat pribadi antara lain, mini bar, laundry, pemakaian telepon, room service, tour tambahan dll.;Tipping untuk pengemudi lokal dan tour leader (USD 70/PAX), belum termasuk porter (pengangkut bagasi).;",
  tourImage: [{
    isDefault: true,
    tourImageDescription: "Antelope Canyon",
    tourImageName: "https://d1xc9yb0ryf4qf.cloudfront.net/images/tour-products/anzac/pn5367-amazing-west-coast/antelope_canyon.jpg",
    tourImageTitle: "Antelope Canyon"
  }],
  tourInclude: "Ticket pesawat udara p.p kelas ekonomi dengan China Airlines berdasarkan kondisi tiket group.;Penginapan hotel bintang 3.5.;Makan sesuai dengan keterangan yang tercantum didalam acara perjalanan (MPagi/ MSiang / MMalam).;Objek wisata sesuai dengan keterangan yang tercantum didalam acara perjalanan.;",
  tourInventoryCode: "PN 5367",
  tourInventoryID: 28,
  tourInventoryTitle: "JELAJAH AMERIKA + TAMAN NASIONAL ANTELOPE, HORSESHOE BEND & YOSEMITE",
  tourItineraryFile: "https://d1xc9yb0ryf4qf.cloudfront.net/tour-pdf/pn_5367_jelajah_amerika_antel_horse_bend_yosem.pdf",
  tourSessionName: "Low",
  tourToc: "Deposit tanda jadi adalah sebesar Rp. 8 Juta per orang.;Keberangkatan rombongan minimal 20 peserta, jika peserta kurang dari 20 orang AntaVaya berhak melakukan penyesuaian harga tour.;Jadwal perjalanan dan tanggal keberangkatan dapat berubah sesuai dengan kondisi demi kelancaran pelaksanaan tour.;Harga tour dapat berubah sewaktu waktu bilamana terjadi perubahan kurs mata uang asing atau kenaikan harga tiket pesawat, surcharge (biaya tambahan) di periode convention dan fair dikota/negara tujuan. Selama group belum berangkat.;Harga tour belum termasuk biaya yang dapat timbul apabila peserta melakukan deviasi tiket penerbangan dan juga acara tour.  Dalam hal visa ditolak, peserta tetap dikenakan biaya deposit pembukuan dan adiministrasi tiket yang terjadi dikarenakan adanya batas waktu dalam penerbitan tiket group.;Biaya dan persyaratan visa dapat berubah sewaktu waktu mengikuti kurs, harga dan kebijakan dari pihak kedutaan.  Sesuai ketentuan dari Kedutaan biaya visa tetap dibayarkan walaupun visa ditolak.;Kategori Triple sharing yaitu 1 kamar ditempati 3 orang dewasa dan pada umumnya di beberapa hotel dapat.;berupa tambahan extra Bed / Sofa Bed sesuai dengan standard hotel yang ada.;Pelunasan tour harus dilakukan paling lambat 14 hari sebelum tanggal keberangkatan tour.;",
  tourTypeName: "Viesta",
}
