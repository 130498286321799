import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';
import { admissionFilterForActiveData } from '../../../helper/GlobalFunc';

const setAdmissions = (admissions) => {
    return {
        type: 'SET_ADMISSIONS',
        payload: {
            admissions: admissions
        }
    }
}

const setExperiences = (experiences) => {
    return {
        type: 'SET_EXPERIENCES',
        payload: {
            experiences
        }
    }
}

const setAdmissionExperience = (admissionsExperience) => {
    return {
        type: 'SET_ADMISSIONS_EXPERIENCE',
        payload: {
            admissionExpreriencePackages: admissionsExperience
        }
    }
}

const setIsFetchAdmission = (value) => {
    return {
        type: 'SET_IS_FETCH_ADMISSIONS',
        payload: {
            value: value
        }
    }
}

const setIsErrorFetchAdmission = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_ADMISSIONS',
        payload: {
            value: value
        }
    }
}

const setIsFetchAdmissionExperience = (value) => {
    return {
        type: 'SET_IS_FETCH_ADMISSION_EXPERIENCES',
        payload: {
            value: value
        }
    }
}

const setIsErrorAdmissionExperience = (value) => {
    return {
        type: 'SET_IS_ERROR_ADMISSION_EXPERIENCES',
        payload: {
            value: value
        }
    }
}

const setIsFetchExperience = (value) => {
    return {
        type: 'SET_IS_FETCH_EXPERIENCE',
        payload: {
            value
        }
    }
}

const setIsErrorFetchExperience = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_EXPERIENCE',
        payload: {
            value
        }
    }
}

export const setSortBy = (value) => {
    return {
        type: 'SET_SORT_BY',
        sortBy: value
    }
}

export const setSortByAdmission = (value) => {
    return {
        type: 'SET_SORT_BY_ADMISSION',
        sortBy: value
    }
}

export const setSortByAdmissionExperience = (value) => {
    return {
        type: 'SET_SORT_BY_ADMISSION_EXPERIENCE',
        sortBy: value
    }
}

// search
const setIsFetchSearch = (value) => {
    return {
        type: 'SET_IS_FETCH_SEARCH',
        payload: { value }
    }
}

const setIsErrorFetchSearch = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_SEARCH',
        payload: { value }
    }
}

const setSearchAdmissionPackage = (admissions) => {
    return {
        type: 'SET_SEARCH_ADMISSION',
        payload: { admissions }
    }
}

const setSearchExperiencePackage = (experiences) => {
    return {
        type: 'SET_SEARCH_EXPERIENCE',
        payload: { experiences }
    }
}

export const initAdmissionPackages = () => {
    return dispatch => {
        dispatch(setIsFetchAdmission(true));
        axios.get(ANTAVAYA_API + 'tour-admission/list')
            .then((response) => {
                if (response) {
                    dispatch(setAdmissions(admissionFilterForActiveData(response.data.data)));
                    dispatch(setIsFetchAdmission(false));
                }
            })
            .catch((error) => {
                dispatch(setIsErrorFetchAdmission(true));
                dispatch(setIsFetchAdmission(false));
            })
    }
}

export const initExperiencePackages = () => {
    return dispatch => {
        dispatch(setIsFetchExperience(true))
        axios.get(ANTAVAYA_API + 'tour-admission/experience-list')
            .then((response) => {
                dispatch(setExperiences(response.data));
                dispatch(setIsFetchExperience(false));
            })
            .catch((error) => {
                dispatch(setIsErrorFetchExperience(true));
                dispatch(setIsFetchExperience(false));
            })
    }
}


export const initExperiencePackagesByExperienceType = (type) => {
    return dispatch => {
        dispatch(setIsFetchExperience(true))
        axios.get(ANTAVAYA_API + 'tour-admission/experience-admission-list/type=' + type)
            .then((response) => {
                dispatch(setExperiences(response.data.data));
                dispatch(setIsFetchExperience(false));
            })
            .catch((error) => {
                dispatch(setIsErrorFetchExperience(true));
                dispatch(setIsFetchExperience(false));
            })
    }
}

export const initAdmissionExperiencePackages = () => {
    return dispatch => {
        dispatch(setIsFetchAdmissionExperience(true));
        axios.get(ANTAVAYA_API + 'tour-admission/experience-admission-list/-')
            .then((response) => {
                if (response) {
                    let experience = response.data.data.filter((data) => data.type === 'experience');
                    let admission = response.data.data.filter((data) => data.type === 'admission');
                    dispatch(setAdmissionExperience(admissionFilterForActiveData(response.data.data, 'admission')));
                    dispatch(setAdmissions(admissionFilterForActiveData(admission)));
                    dispatch(setExperiences(experience));
                    dispatch(setIsFetchAdmissionExperience(false));
                    
                }
            })
            .catch((error) => {
                dispatch(setIsFetchAdmissionExperience(false));
                dispatch(setIsErrorAdmissionExperience(true));
            })
    }
}

export const searchAdmissionExperiencePackages = (value) => {
    return dispatch => {
        dispatch(setIsFetchSearch(true));
        axios.get(ANTAVAYA_API + 'tour-admission/experience-admission-list/' + value)
            .then((response) => {
                let experiences = response.data.data.filter((data) => data.type === 'experience');
                let admissions = response.data.data.filter((data) => data.type === 'admission');
                dispatch(setSearchAdmissionPackage(admissions));
                dispatch(setSearchExperiencePackage(experiences));
                dispatch(setIsFetchSearch(false))
            })
            .catch((error) => {
                dispatch(setIsErrorFetchSearch(true));
                dispatch(setIsFetchSearch(false));
            })
    }
}