import React from 'react';
import classes from './Calendar.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import Header from '../../../../Shared/Header/Header';
// import { overflowBody } from '../../../../../../helper/GlobalFunc';
import DayPicker from 'react-day-picker';
import Helmet from 'react-helmet';
import 'react-day-picker/lib/style.css';
import { SHORT_WEEK_MAP } from '../../../../../../constant/General';
import Button from '../../../../../UI/Button/Button';

const calendar = (props) => {

    let month = null;
    let fromMonth = new Date();
    let toMonth = new Date(props.periodOfValidity);
    
    if (props.selectedDate !== null) {
        let currentDate = props.selectedDate;
        month = new Date(currentDate.getFullYear(), currentDate.getMonth())
    }

    return (
		<CSSTransition
			in={props.show}
			mountOnEnter
			unmountOnExit
			timeout={270}
			classNames={{
				enterActive: classes.Open,
				exitActive: classes.Close,
			}}>
			<div className={classes.Wrapper}>
                <Helmet>
                    <style>{`
                            .DayPicker-Month {
                                width: 300px;
                                margin-top: 60px;
                            } 
                            .DayPicker-Day {
                                line-height: 1.95rem;
                            }
                        `}</style>
                </Helmet>
				<div className={classes.Header}>
					<Header
						classes={["Shadow"]}
						leftIcon="close"
						leftClicked={props.hidePopupClicked}
						content="Select Date"
					/>
				</div>
				<div className={classes.Content}>
					<div className={classes.Item}>
                        <DayPicker
                            numberOfMonths={2}
                            canChangeMonth={true}
                            fromMonth={fromMonth}
                            toMonth={toMonth}
                            month={month}
                            weekdaysShort={SHORT_WEEK_MAP}
                            disabledDays={{ before: new Date(), after: toMonth }}
                            selectedDays={props.selectedDate}
                            onDayClick={(day) => props.onDayClickHandler(day)}/>
					</div>
				</div>
                <div className={classes.FooterWrapper}>
                    <Button 
                        classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                        clicked={() => props.hidePopupClicked()}>
                        Done
                    </Button>
                </div>
			</div>
		</CSSTransition>
    )
}

export default calendar;
