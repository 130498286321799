import React, { useState, useEffect } from 'react';
import classes from './BannerSlider.module.scss';
import Slider from 'react-slick';
import Icon from '../../../UI/Icon/Icon';
import { withRouter } from 'react-router-dom';
import axios from '../../../../axios';
import { ANTAVAYA_API } from '../../../../constant/Api';

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
		<div
			className={[classes.Arrow, classes.PrevArrow].join(' ')}
			onClick={onClick} >
			<Icon name="ico-arrow-right" fill="#202124" stroke="none" width={60}/>
		</div>
    );
}
  
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
		<div
			className={[classes.Arrow, classes.NextArrow].join(' ')}
			onClick={onClick}>
			<Icon name="ico-arrow-right" fill="#202124" stroke="none" width={60}/>
		</div>
    );
}

const bannerSlider = props => {
	const [bannerSliders, setBannerSliders] = useState([]);

	useEffect(() => {
		axios.get(ANTAVAYA_API + 'v1/site/home-banner')
			.then( response => {
				setBannerSliders(response.data);
			})
			.catch( error => {
				console.log(error);
			});
	}, [])
	
	const goToPage = (isExternalUrl, url) => {
		if (isExternalUrl) {
			window.open(url, '_blank');
		} else {
			props.history.push(url);
		}
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		adaptiveHeight: true,
		centerMode: true,
		variableWidth: false,
		centerPadding: '0px',
		fade:false,
		dotsClass: "slick-dots md-dot-light " + classes.DotWrapper,
		autoplay: true,
		autoplaySpeed: 3500,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		customPaging: function(i) {
			return (
				<div className="banner-slider"></div>
			);
		},
	};
		
	let sliderItems = [];
	if (bannerSliders && bannerSliders.length > 0) {
		sliderItems = bannerSliders.map( (slider, index) => {
			return (
				<div key={index} className={classes.Item} onClick={() => goToPage(slider.isExternalUrl, slider.url)}>
					<img src={slider.image} alt={slider.name} />
				</div>
			);
		});
	}
	
	return (
		<div className={classes.PromoList}>
			<Slider {...settings}>
				{sliderItems}
			</Slider>
		</div>
	);
}

export default withRouter(bannerSlider);