import React from 'react';
import IteneraryList from '../../../../../components/Mobile/Shared/ItineraryList/ItineraryList';

const itinerary = (props) => {
    return (
        <div>
            <IteneraryList iteneraryList={props.iteneraryList}/>
        </div>
    )
}

export default itinerary;