import React, { Component } from 'react';
import classes from './Filter.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import ElementFilter from './ElementFilter/ElementFilter';

class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <PanelBox>
                <div className={classes.Wrapper}>
                    <div className={classes.Title}>
                        Filter
                        <div className={classes.Reset} onClick={this.props.resetFilterClicked}>Reset</div>
                    </div>
                    <ElementFilter 
                        title="Duration" 
                        list={this.props.durationFilterList}
                        filterChanged={(event, index) => this.props.filterChanged(event, index, 'duration')}
                        resetClicked={this.props.resetDurationFilterClicked} />
                    <ElementFilter 
                        title="Price" 
                        list={this.props.priceFilterList}
                        filterChanged={(event, index) => this.props.filterChanged(event, index, 'price')}
                        resetClicked={this.props.resetPriceFilterClicked} />
                    <ElementFilter 
                        title="Category" 
                        list={this.props.categoryFilterList}
                        filterChanged={(event, index) => this.props.filterChanged(event, index, 'category')}
                        resetClicked={this.props.resetCategoryFilterClicked} />
                </div>
            </PanelBox>
        );
    }
}

export default Filter;