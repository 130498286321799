import React, { useState, useEffect } from 'react';
import classes from './Setting.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import ContentLoader from 'react-content-loader';
import AddParticipant from './AddParticipant/AddParticipant';
import participantEmptyImg from '../../../../../assets/images/art-participant-empty.png';
import Confirm from '../../../../UI/Dialog/Confirm/Confirm';

import { connect } from 'react-redux';
import * as participantAction from '../../../../../redux/Shared/Participant/ParticipantAction';

const setting = props => {
    const [showForm, setShowForm] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [participantId, setParticipantId] = useState(null);
    
    useEffect(() => {
        props.onInitParticipantList();
        if (props.successSubmit) {
            setShowForm(false);
        }
    }, [props.successSubmit]);

    useEffect(() => {
        if (props.succesDelete) {
            setDeleteAlert(false);
        }
    }, [props.succesDelete]);

    const showDeleteAlertHandler = (id) => {
        setDeleteAlert(true);
        setParticipantId(id)
    }

    const deleteParticipantHandler = () => {
        let data = {
            passengerID: String(participantId)
        }
        props.onDeleteParticipant(data);
    }

    const editClickHandler = (participant, index) => {
        props.setEditParticipant(participant, index);
        setShowForm(true);
    }

    const addClickHandler = () => {
        props.setAddParticipant();
        setShowForm(true);
    }

    let participantContent = <ContentLoader
            height={60}
            speed={1}
            primaryColor={'#e6e6e6'}>
            <rect x="16" y="0" rx="4" ry="4" width="240" height="12" />
            <rect x="16" y="20" rx="4" ry="4" width="230" height="12" />
            <rect x="16" y="40" rx="4" ry="4" width="250" height="12" />
        </ContentLoader>

    if (!props.isFetchParticipant) {
        if (props.participants && props.participants.length > 0) {
            participantContent = props.participants.map((participant, key) => {
                return (
                    <li key={key} className={classes.Item}>
                        <div>{participant.title} {participant.firstName} {participant.lastName}</div>
                        <div className={classes.Action}>
                            <div className={classes.Icon} onClick={() => editClickHandler(participant, key)} >
                                <Icon name="ico-pencil" fill="#6A6E73" stroke="none" />
                            </div>
                            <div className={classes.Icon} onClick={() => showDeleteAlertHandler(participant.id)}>
                                <Icon name="ico-trash" fill="#6A6E73" stroke="none" />
                            </div>
                        </div>
                    </li>
                )
            });
        } else {
            participantContent =  <div className="u-text-center"><img src={participantEmptyImg} alt="" width="400" /></div>;
        }
    }

    return (
        <div>
            <AddParticipant
                showAlert={showForm}
                hideFormClickHandler={() => setShowForm(false)} />

            <Confirm show={deleteAlert}
                title="Delete This Participant?"
                desc="By deleting this participant, you won't be able to fill in their details automatically upon future bookings."
                cancelClicked={() => setDeleteAlert(false)}
                isConfirmLoading={props.isDeleteLoading}
                confirmClicked={() => deleteParticipantHandler()}/>


            <div className={classes.Wrapper}>
                <div className={classes.Head}>
                    <div className={classes.Title}>Setting</div>
                </div>
                {/* <div className={[classes.Box, classes.Newsletter].join(' ')}>
                    <div className={classes.LabelWrap}>
                        <div className={classes.Label}>Subscribe Newsletter</div>
                        <div className={classes.Desc}>Be the first to know antavaya promos, best deals, and offers.</div>
                    </div>
                    <div className={classes.Action}>
                        <Switch />
                    </div>
                </div> */}
                <div className={[classes.Box, classes.Participant].join(' ')}>
                    <div className={classes.HeadParticipant}>
                        <div className={classes.Label}>Participants</div>
                        <div className={classes.Link} onClick={addClickHandler}>Add Participants</div>
                    </div>
                    <ul className={classes.List}>
                        {participantContent}
                    </ul>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        participants: state.ParticipantReducer.participants,
        isFetchParticipant: state.ParticipantReducer.isFetchParticipant,
        isDeleteLoading: state.ParticipantReducer.isDeleteLoading,
        successSubmit: state.ParticipantReducer.successSubmit,
        succesDelete: state.ParticipantReducer.succesDelete
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitParticipantList: () => dispatch(participantAction.getParticipantList()),
        setEditParticipant: (participant, index) => dispatch(participantAction.setEditParticipant(participant, index)),
        setAddParticipant: () => dispatch(participantAction.setAddParticipant()),
        onDeleteParticipant: (data) => dispatch(participantAction.onDeleteParticipant(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setting);