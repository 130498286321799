import React from 'react';
import classes from './Counter.module.scss';
import CounterButton from './CounterButton/CounterButton';

const counter = props => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Label}>{props.label}</div>
            <div className={classes.Action}>
                <CounterButton type="decrease" disabled={!props.canDecrease} clicked={props.decreaseClicked}/>
                <div className={classes.Value}>{props.value}</div>
                <CounterButton type="increase" disabled={!props.canIncrease} clicked={props.increaseClicked} />
            </div>
        </div>
    );
}

export default counter;