import React, { Component } from 'react';
import classes from './ImageSlider.module.scss';
import Slider from 'react-slick';
import ImageItem from './ImageItem/ImageItem';
// import ImageSliderLoader from '../../../../UI/Loaders/Mobile/DetailPackage/ImageSlider/ImageSliderLoader';
import { DEFAULT_IMG } from '../../../../../constant/Content';

class ImageSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSlide: 1,
        };
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            autoplay: false,
            autoplaySpeed: 2500,
            arrows: false,
            dotsClass: "slick-dots sm-dot-light " + classes.DotWrapper,
            afterChange: current => {
                this.setState({ currentSlide: current + 1 })
            },
            customPaging: function(i) {
                return (
                    <div className="banner-slider"></div>
                );
            },
        };
    
        let imageList = null;
        if (this.props.imageList && this.props.imageList.length > 0) {
            imageList = this.props.imageList.map((photo, index) => {
                return (
                    <div className={classes.Item} key={index}>
                        <ImageItem clicked={this.props.clicked} photo={photo} />
                    </div>
                );
            });
            imageList = (
                <Slider {...settings}>
                    {imageList}
                </Slider>
            )
        } else {
            // imageList = <ImageSliderLoader/>
            imageList = <div className={classes.ImgError}><img onError={DEFAULT_IMG} src="" alt="" /></div>;
        }
    
        return (
            <div className={classes.Wrapper}>
                <div className={classes.Slider}>
                    {/* <Slider {...settings}> */}
                    {imageList}
                    {/* </Slider> */}
                </div>
            </div>
        );
    }
}

export default ImageSlider;
