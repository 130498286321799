import React from 'react';
import classes from './SearchResult.module.scss';
import PackageItem from './PackageItem/PackageItem';
import ContentLoader from 'react-content-loader';
import { withRouter } from 'react-router-dom';

const searchResult = (props) => {
    let experienceContent = null;
    let admissionContent = null;

    if (props.isErrorFetchSearch) {
        
    } else {
        if (props.isFetchSearch) {
            experienceContent = <ContentLoader></ContentLoader>;
            admissionContent = <ContentLoader></ContentLoader>;
        } else {
            if (props.searchAdmissionPackages && props.searchAdmissionPackages.length > 0) {
                admissionContent = props.searchAdmissionPackages.map((item, index) => {
                    return (
                        <div className={classes.List} key={index}>
                            <div 
                                className={classes.PackageItem}
                                onClick={ () => props.history.push('/admission/detail/' + item.slug)} >
                                <PackageItem
                                    packageItem={item}
                                />
                            </div>
                        </div>
                    )
                })
            } else {
                admissionContent = (
                    <div className={classes.Empty}>
                        Admission is empty
                    </div>
                );
            }

            if (props.searchExperiencePackages && props.searchExperiencePackages.length > 0) {
                experienceContent = props.searchExperiencePackages.map((item, index) => {
                    return (
                        <div className={classes.List} key={index}>
                            <div 
                                className={classes.PackageItem}
                                onClick={ () => props.history.push('/tour/detail/' + item.slug)} >
                                <PackageItem
                                    packageItem={item}
                                />
                            </div>
                        </div>
                    )
                })
            } else {
                experienceContent = (
                    <div className={classes.Empty}>
                        Activity package is empty
                    </div>
                );
            }
        }
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Label}>Activity Packages</div>
            {experienceContent}
            <div className={classes.Label}>Admission</div>
            {admissionContent}
        </div>
    )
}

export default withRouter(searchResult);