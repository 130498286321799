import React from 'react';
import classes from './TourItem.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import Button from '../../../../../UI/Button/Button';
import CurrencyFormat from 'react-currency-format';
import { formatHumanDate } from '../../../../../../helper/GlobalFunc';
import { DEFAULT_IMG } from '../../../../../../constant/Content';
import Icon from '../../../../../UI/Icon/Icon';

const tourItem = (props) => {
    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                <div className={classes.Image}>
                    <img onError={DEFAULT_IMG} src={props.packageItem.image} alt="" />
                </div>
                <div className={classes.Description}>
                    <a href={'/admission/detail/' + props.packageItem.slug} className={classes.Title}>
                        {props.packageItem.tourAdmissionName} 
                    </a>
                    <div className={classes.SubTitle}>
                        <Icon name="ico-location" fill="#666666" stroke="none" width={18} />
                        <div className={classes.Text}>
                            {props.packageItem.location} 
                        </div>
                    </div>
                    <div>
                        <div className={classes.Label}>Period of Validity</div>
                        <div className={classes.Validity}>
                            <Icon name="ico-calendar" fill="#666666" stroke="none" width={18} />
                            <div className={classes.Item}>
                                {formatHumanDate(props.packageItem.startPeriodOfTravelling)} - {formatHumanDate(props.packageItem.endPeriodOfTravelling)}
                            </div>
                        </div>
                    </div>
                    <div className={classes.PriceAction}>
                        <div>
                            <div className={classes.Label}>Starting from</div>
                            <div className={classes.Price}>
                                <CurrencyFormat value={props.packageItem.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            </div>
                        </div>
                        <div className={classes.Action}>
                            <a href={'/admission/detail/' + props.packageItem.slug}>
                                <Button classes={['BtnRed', 'BtnSmall']}>View Package</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </PanelBox>
    )
}

export default tourItem;
