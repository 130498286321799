import React, { useEffect } from 'react';
import classes from './LoginRegister.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import Benefit from './Benefit/Benefit';
import LoginForm from './LoginForm/LoginForm';
import RegisterForm from './RegisterForm/RegisterForm';
import SuccessRegister from './SuccessRegister/SuccessRegister';
import SuccessForgotPassword from './SuccessForgotPassword/SuccessForgotPassword';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { connect } from 'react-redux';
import Icon from '../../../UI/Icon/Icon';
import * as authAction from '../../../../redux/Shared/Auth/AuthAction';
import * as signup from '../../../../redux/Shared/SignUp/SignupAction';
import * as forgotPasswordAction from '../../../../redux/Shared/ForgotPassword/ForgotPasswordAction';
import { overflowBody } from '../../../../helper/GlobalFunc';

function loginRegister(props) {

    useEffect(() => {
        overflowBody(props.show);
    }, [props.show]);

    const submitLoginClickHandler = () => {
        const loginData = {
            detail: {
                email : props.loginData.email.value,
                password : props.loginData.password.value,
            }
        };
        props.submitLoginHandler(loginData);
    }

    const hideClickHandler = () => {
        props.hideLoginRegisterFormClicked();
        props.initFormHandler();
        props.initForgotPassWordFormHandler();
    }

    let form = <LoginForm 
        loginData={props.loginData}
        switchFormClicked={ formActive => props.switchFormClicked(formActive)}
        inputChanged={(event, inputIdentifier) => props.inputChangeHandler(event, inputIdentifier)}
        rememberMeChanged={ event => props.rememberMeChangeHandler(event)}
        submitLoginClicked={ submitLoginClickHandler} />;

    if (props.activeForm === 'register') {
        if (props.successSignup) {
            form = <SuccessRegister/>
        } else {
            form = <RegisterForm 
                loginData={props.loginData}
                switchFormClicked={formActive => props.switchFormClicked(formActive)}/>;
        }
    }

    if (props.activeForm === 'forgot-password') {
        if (props.isForgotPasswordSuccessSubmit) {
            form = <SuccessForgotPassword
                    switchFormClicked={formActive => props.switchFormClicked(formActive)}/>
        } else {
            form = <ForgotPassword
                    switchFormClicked={formActive => props.switchFormClicked(formActive)}/>
        }
    }

    return (
        <div>
            <div className={classes.Backdrop}>
                <Backdrop showBackdrop={props.show}/>
            </div>
            
            <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={160}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close
            }}>
                <div className={classes.Wrapper}>
                    <div className={classes.Container}>
                        <Benefit />
                        <div className={classes.Right}>
                            <div className={classes.CloseBtn} onClick={hideClickHandler}>
                                <Icon name="ico-close" stroke="none" fill="#202124" />
                            </div>
                            {form}
                        </div>
                        
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        state : state.AuthReducer,
        loginData : state.AuthReducer.loginData,
        isValidForm : state.AuthReducer.isValidForm,
        isLogin : state.AuthReducer.isLogin,
        successSignup: state.SignupReducer.successSignup,
        isForgotPasswordSuccessSubmit: state.ForgotPasswordReducer.isSuccessSubmit,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        inputChangeHandler : (event, inputIdentifier) => dispatch(authAction.inputChangeHandler(event, inputIdentifier)),
        rememberMeChangeHandler : (event) => dispatch(authAction.rememberMeChangeHandler(event)),
        checkFormHandler : () => dispatch(authAction.checkFormHandler()),
        submitLoginHandler : (data) => dispatch(authAction.submitLogin(data)),
        initFormHandler : (data) => dispatch(signup.successSignup(false)),
        initForgotPassWordFormHandler : (data) => dispatch(forgotPasswordAction.setIsSuccessSubmit(false))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(loginRegister);