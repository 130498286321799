import React from 'react';
import classes from './DateLoader.module.scss';
import ContentLoader from 'react-content-loader';

const dateLoader = () => {
    return (
        <div className={classes.Wrapper}>
            <ContentLoader
                height={95}
                speed={1}
                primaryColor={'#e6e6e6'}
            >  
                <rect x="0" y="15" rx="4" ry="4" width="150" height="18" />
                <rect x="0" y="45" rx="4" ry="4" width="380" height="50" />
            </ContentLoader>
        </div>
    )
}

export default dateLoader;