import React, { useState, useEffect } from 'react';
import classes from './HolidayPackage.module.scss';
import HolidayPackageItem from './HolidayPackageItem/HolidayPackageItem';
import Slider from 'react-slick';
import Icon from '../../../UI/Icon/Icon';
import { withRouter } from 'react-router-dom';
import axios from '../../../../axios';
import { ANTAVAYA_API } from '../../../../constant/Api';
import CardListLoader from '../../../UI/Loaders/Desktop/Card/CardListLoader';
import Ribbon from '../../../UI/Ribbon/Ribbon';

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      	<div
			className={[classes.Arrow, classes.PrevArrow].join(' ')}
			onClick={onClick}>
				<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
      	</div>
    );
}
  
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      	<div
			className={[classes.Arrow, classes.NextArrow].join(' ')}
			onClick={onClick}>
				<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
      	</div>
    );
}

function holidayPackage(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [tourPackages, setTourPackages] = useState([]);
	
    const goToDetailPageHandler = (slug) => {
        props.history.push('/tour/detail/' + slug);
	}
    
    useEffect(() => {
		axios.get(ANTAVAYA_API + 'v1/tour/features')
            .then( response => {
                if (response.data) {
                    setIsLoading(false);
                    setTourPackages(response.data);
                } else {
                    props.history.push('/');
                }
            })
            .catch( error => {
                setIsLoading(false);
                console.log(error)
            })
    }, [])
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 3,
        variableWidth: true,
        autoplay: false,
        autoplaySpeed: 2500,
        dotsClass: "slick-dots lg-dot-light " + classes.DotWrapper,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        customPaging: function(i) {
            return (
                <div className="banner-product"></div>
            );
        },
    };

    let packageList = [];
    if (isLoading) {
        for (let index = 0; index < 3; index++) {
            packageList.push(<CardListLoader key={index}/>);
        }
    } else {
        if (!isLoading && tourPackages.length > 0) {
            packageList = tourPackages.map((packageItem, index) => {
                return <div key={index} className={classes.Item} onClick={() => goToDetailPageHandler(packageItem.slug)}>
                    <div className={classes.RibbonWrapper}>
                        <Ribbon text="free voucher tour" position="left"/>
                    </div>
                    <HolidayPackageItem packageItem={packageItem}/>
                </div>;
            });
        } else {
            packageList = <div className={classes.NotFound}>Sorry, no tour package matches your preference. Please change your search.</div>;
        }
    }
        
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className={classes.Title}>Holiday Packages</div>
                <div className={classes.SubTitle}>Travel to exciting destinations with flights, hotel, transfers and tours - all packaged up!</div>
                <div className={classes.List}>
                    <Slider {...settings}>
                        {packageList}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default withRouter(holidayPackage);