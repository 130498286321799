import React from 'react';
import classes from './PriceDetail.module.scss';
import PanelBox from '../../../UI/PanelBox/PanelBox';

const priceDetail = () => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Title}>Total Price</div>
            <PanelBox>
                <ul className={classes.PriceList}>
                    <li className={classes.PriceItem}>
                        <div className={classes.Name}>Adult [Twin Sharing] x 2</div>
                        <div className={classes.Price}>Rp. 21.766.000</div>
                    </li>
                    <li className={classes.PriceItem}>
                        <div className={classes.Name}>Adult [Single]</div>
                        <div className={classes.Price}>Rp. 13.668.000</div>
                    </li>
                    <li className={classes.PriceItem}>
                        <div className={classes.Name}>Child [Extra Bed]</div>
                        <div className={classes.Price}>Rp. 10.888.000</div>
                    </li>
                    <li className={classes.PriceItem}>
                        <div className={classes.Name}>PPN 1%</div>
                        <div className={classes.Price}>Rp. 320.000</div>
                    </li>
                    <li className={classes.PriceItem}>
                        <div className={classes.Name}>Airport Tax</div>
                        <div className={classes.Price}>Rp. 320.000</div>
                    </li>
                    <li className={classes.PriceItem}>
                        <div className={classes.Name}>Visa 4x</div>
                        <div className={classes.Price}>Rp. 320.000</div>
                    </li>
                </ul>
                <div className={classes.Total}>
                    <div className={classes.Label}>Total</div>
                    <div className={classes.TotalPrice}>Rp. 47.292.000</div>
                </div>
            </PanelBox>
        </div>
    )
}

export default priceDetail;