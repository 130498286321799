import React from 'react';
import classes from './Footer.module.scss';
// import NewsLetterForm from './NewsLetterForm/NewsLetterForm';
import InquiryMenu from './InquiryMenu/InquiryMenu';
import logo from '../../../../assets/images/logo.png';
import Icon from '../../../UI/Icon/Icon';
import {ANTAVAYA} from '../../../../constant/Antavaya';
import { COMPANY_LINK, SOCMED_LINK } from '../../../../constant/LinkList';

const footer = ({corporate}) => {
    const addresses = ANTAVAYA.addresses.map((address, index) => {
        return (
            <div key={index}>
                <div className={classes.Label} >{address}</div>
            </div>
        );
    });

    const companyLink = COMPANY_LINK.map( (item, index) => {
        return <li key={index}><a href={item.url} target={item.target}>{item.name}</a></li>;
    });

    const socmedLink = SOCMED_LINK.map( (item, index) => {
        return (
            <li key={index}>
                <a href={item.url} target={item.target}>
                    <div className={classes.Icon}>
                        <Icon name={item.icon} fill="#ffffff" stroke="none" />
                    </div>
                    {item.name}
                </a>
            </li>
        );
    });

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className={classes.NewsLetter}>
                    <InquiryMenu />
                </div>
                <div className={classes.Link}>
                    <div className={classes.Logo}>
                        <a href="/">
                            <img src={logo} alt="Antavaya" />
                        </a>
                    </div>

                    <div className={classes.Contact}>
                        <div className={classes.LinkTitle}>Contact Us</div>

                        <div className={classes.Label}>Call center</div>
                        <div className={classes.Phone}>{corporate ? ANTAVAYA.hotlineCorporate : ANTAVAYA.hotline}</div>

                        <div className={classes.Label}>Whatsapp</div>
                        <div className={classes.Phone}>{corporate ? ANTAVAYA.whatsappCorporate : ANTAVAYA.whatsapp}</div>
                        <div className="u-mt-10">
                            <div className={classes.Label}>{ANTAVAYA.csHours[0]} - {ANTAVAYA.csHours[1]} ({ANTAVAYA.csDays[0]} - {ANTAVAYA.csDays[1]}) </div>
                        </div>
                        <div className="u-mt-10">
                            <div className={classes.Label}>{corporate ? ANTAVAYA.emailCorporate :ANTAVAYA.email}</div>
                        </div>
                        <div className="u-mt-10">
                            {addresses}
                        </div>
                    </div>

                    <div className={classes.Item}>
                        <div className={classes.LinkTitle}>About Antavaya</div>
                        <ul className={classes.List}>
                            { companyLink }
                        </ul>
                    </div>

                    <div className={classes.Item}>
                        <div className={classes.LinkTitle}>Follow us on</div>
                        <ul className={classes.List}>
                            {socmedLink}
                        </ul>
                    </div>
                </div>
                <div className={classes.CopyRight}>
                    Copyright © {new Date().getFullYear()} AntaVaya. All Rights Reserved
                </div>
            </div>
        </div>
    );
}

export default footer;