import React from 'react';
import ContentLoader from 'react-content-loader';

const destinationLoader = () => {
    return (
        <ContentLoader
            height={200}
            speed={1}
            primaryColor={'#e6e6e6'}
        >
            <rect x="20" y="16" rx="3" ry="3" width="300" height="17" />

            <rect x="20" y="53" rx="3" ry="3" width="50" height="10" />
            <rect x="80" y="53" rx="3" ry="3" width="45" height="10" />
            <rect x="135" y="53" rx="3" ry="3" width="75" height="10" />

            <rect x="20" y="80" rx="3" ry="3" width="60" height="13" />
            {/* <rect x="90" y="80" rx="3" ry="3" width="75" height="13" /> */}

            <rect x="20" y="122" rx="3" ry="3" width="50" height="15" />

            <rect x="20" y="155" rx="3" ry="3" width="100" height="42" />
            <rect x="130" y="155" rx="3" ry="3" width="100" height="42" />
            <rect x="240" y="155" rx="3" ry="3" width="100" height="42" />
            <rect x="350" y="155" rx="3" ry="3" width="100" height="42" />

        </ContentLoader>
    )
}

export default destinationLoader;