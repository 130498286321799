import { updateObject } from "../../utility";

const initialState = {
    isFetchVirtualTourDetail: false,
    isErrorFetchVirtualTourDetail: false,
    virtualTourDetail: null,
};

const setIsFetchVirtualTourDetail = (state, action) => {
    return updateObject(state, { isFetchVirtualTourDetail: action.payload.value })
}

const setIsErrorFetchVirtualTourDetail = (state, action) => {
    return updateObject(state, { isErrorFetchVirtualTourDetail: action.payload.value })
}

const setVirtualTourDetail = (state, action) => {
    return updateObject(state, { virtualTourDetail: action.payload.data })
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case 'SET_IS_ERROR_FETCH_VIRTUAL_TOUR_DETAIL': return setIsErrorFetchVirtualTourDetail(state, action);
        case 'SET_IS_FETCH_VIRTUAL_TOUR_DETAIL': return setIsFetchVirtualTourDetail(state, action);
        case 'SET_VIRTUAL_TOUR_DETAIL': return setVirtualTourDetail(state, action);
        default: return state;
    }
}

export default reducer;
