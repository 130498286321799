import React from 'react';
import classes from './LeftContent.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Form from './Form/Form';
import List from "../../../../UI/List/List";

const leftContent = (props) => {
    let toc = null;
    if (props.voucherDetail && props.voucherDetail.toc){
        toc = props.voucherDetail.toc.map((item, key) => {
            return (
                <div key={key} className="u-mb-24">
                    <PanelBox>
                        <div className={classes.Wrapper}>
                            <div className={classes.Title}>{item.title}</div>
                            <div className="u-p-24">
                                <List list={item.data}/>
                            </div>
                        </div>
                    </PanelBox>
                </div>
            );
        });
    }

    return (
        <div>
            <div className="u-mb-24">
                <Form
                    isContinuePaymentHandler={(val) => props.isContinuePaymentHandler(val)}
                    hotelDetail={props.hotelDetail}/>
            </div>
            {toc}
            
        </div>
    )
}

export default leftContent;