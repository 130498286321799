import React from 'react';
import classes from './PaymentStatus.module.scss';
import Icon from '../../UI/Icon/Icon';
import { isMobile } from 'react-device-detect';

function paymentStatus(props) {
    let text = '';
    let icon = null;
    let textClasses = [classes.Text];
    let iconSize = 22;
    if (isMobile) {
        textClasses.push(classes.Mobile);
        iconSize = 18;
    } 
    switch (props.status) {
        case 'waiting':
            text = 'Waiting for Payment';
            icon = <Icon name="ico-hour" stroke="none" fill="#F99E00" width={iconSize} />;
            textClasses.push(classes.Warning);
            break;
        case 'pending':
            text = 'Pending Payment';
            icon = <Icon name="ico-hour" stroke="none" fill="#F99E00" width={iconSize} />;
            textClasses.push(classes.Warning);
            break;
        case 'in_process':
            text = 'Waiting for Confirmation';
            icon = <Icon name="ico-hour" stroke="none" fill="#F99E00" width={iconSize} />;
            textClasses.push(classes.Warning);
            break;
        case 'success':
            text = 'Payment Successful';
            icon = <Icon name="ico-check-o" stroke="none" fill="#73C700" width={iconSize} />;
            textClasses.push(classes.Success);
            break;
        case 'failed':
            text = 'Payment Failed';
            icon = <Icon name="ico-warning-o" stroke="none" fill="#CD2033" width={iconSize} />;
            textClasses.push(classes.Danger);
            break;
        case 'cancelled':
            text = 'Payment Cancelled';
            icon = <Icon name="ico-close-o" stroke="none" fill="#c2c2c2" width={iconSize} />;
            textClasses.push(classes.Default);
            break;
    
        default:
            break;
    }
    return(
        <div className={classes.Wrapper}>
            {icon}
            <div className={textClasses.join(' ')}>{text}</div>
        </div>
    );
}

export default paymentStatus;