import React from 'react';
import classes from './PackageItem.module.scss';
import { toCapitalize } from '../../../../../helper/GlobalFunc';
import CurrencyFormat from 'react-currency-format';

const packageItem = (props) => {
    let image = null;
    if (props.packageItem.defaultImage) {
        image = props.packageItem.defaultImage.url;
    }
    return (
        <div className={classes.Wrapper}>
            <div className={classes.ImageWrapper}>
                <img src={image} className={classes.Img} alt={props.packageItem.defaultImage.title} />
            </div>
            <div className={classes.Description}>
                <div className={classes.Title}>{props.packageItem.days + 'D '} {toCapitalize(props.packageItem.inventoryTitle)}</div>
                <div className={classes.PriceWrapper}>
                    <div className={classes.StartFrom}>Start From</div>
                    <div className={classes.Price}>
                        <CurrencyFormat value={props.packageItem.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                    </div>
                    <div className={classes.Perpax}>/Pax</div>
                </div>
            </div>
        </div>
    );
}

export default packageItem;