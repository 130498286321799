import React, { Component } from 'react';
import classes from './Sort.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import onClickOutside from "react-onclickoutside";
import CSSTransition from 'react-transition-group/CSSTransition';

class Sort extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showList: false
        }
    }

    showListHandler = () => {
        this.setState({showList: true});
    }
    setActiveItemHandler = (value) => {
        this.props.setSelected(value);
        this.handleClickOutside();
    }
    
    handleClickOutside = (evt) => {
        this.setState({showList: false});
    }

    render() {
        let DisplayValue = '';
        const itemlist = this.props.options.map((item, index) => {
            let activeClass = null;
            if (this.props.defaultValue === item.value) {
                DisplayValue = item.name;
                activeClass = classes.Active;
            }
            return (
                <li key={index} 
                    className={activeClass} 
                    onClick={() => this.setActiveItemHandler(item.value)}>{item.name}
                </li>
            );
        });

        let iconClasses = [classes.Icon];
        if (this.state.showList) {
            iconClasses.push(classes.IsActive);
        }



        
        return (
            <div className={classes.Wrapper}>
                <div className={classes.Dropdown} onClick={this.showListHandler}>
                    <div className={classes.Input}>
                        <input placeholder="Sort by" value={DisplayValue} readOnly/>
                    </div>
                    <div className={iconClasses.join(' ')}>
                        <Icon name="ico-caret-down" fill="#202124" stroke="none" />
                    </div>
                </div>
                <CSSTransition
                    in={this.state.showList}
                    mountOnEnter
                    unmountOnExit
                    timeout={270}
                    classNames={{
                        enterActive: classes.Open,
                        exitActive: classes.Close
                    }}>
                    <ul className={classes.List}>
                        {itemlist}
                    </ul>
                </CSSTransition>
            </div>
        );
    }
}

export default onClickOutside(Sort);