import React, { useState, useEffect } from 'react';
import classes from './PreBookingContainer.module.scss';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import history from '../../../../history';
import Date from '../../../../components/Mobile/ThingsToDo/Admission/PreBooking/Date/Date';
import Ticket from '../../../../components/Mobile/ThingsToDo/Admission/PreBooking/Ticket/Ticket';
import Calendar from '../../../../components/Mobile/ThingsToDo/Admission/PreBooking/Calendar/Calendar';
import Visitor from '../../../../components/Mobile/ThingsToDo/Admission/PreBooking/Visitor/Visitor';
import CurrencyFormat from 'react-currency-format';
import Button from '../../../../components/UI/Button/Button';
import PriceDetail from '../../../../components/Mobile/ThingsToDo/Admission/PreBooking/PriceDetail/PriceDetail';
import { formatShortDate } from '../../../../helper/GlobalFunc';

import { connect } from "react-redux";
import * as DetailAction from '../../../../redux/Admission/Detail/DetailAction';

const visitorsDataInit = [
    {
        type: 'all-price',
        active: false,
        price: 0,
        qty:0
    },
    {
        type: 'adult',
        active: false,
        price: 0,
        qty:0
    },
    {
        type: 'child',
        active: false,
        price: 0,
        qty:0
    },
    {
        type: 'senior',
        active: false,
        price: 0,
        qty:0
    }
]


const preBooking = (props) => {
    const [visitors, setVisitors] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showPriceDetail, setShowPriceDetail] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [updater, setUpdater] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
        props.onInitAdmission(props.match.params.slug)
    }, [])

    const backClickHandler = () => {
        history.go(-1);
    }

    const onDayClickHandler = day => {
        setSelectedDate(day);
    }

    const onSetTicketHandler = (id, priceArray) => {
        setSelectedTicket(id)
        onSetVisitorHandler(priceArray)
    }

    const onGuestAddedClicked = (type) => {
        let visitorsCp = null;
        visitorsCp = [...visitors];
        for (const key in visitorsCp) {
            if (visitorsCp[key].type === type) {
                visitorsCp[key].qty += 1;
            }
        }
        setUpdater(!updater)
        setVisitors(visitorsCp)
    }

    const onGuestRemoveClicked = (type) => {
        let visitorsCp = null;
        visitorsCp = [...visitors];
        for (const key in visitorsCp) {
            if (visitorsCp[key].type === type) {
                visitorsCp[key].qty -= 1;
            }
        }
        setUpdater(!updater)
        setVisitors(visitorsCp)
    }

    const onSetVisitorHandler = (priceArray) => {
        const priceCp = [...priceArray];
        const visitorsCp = [...visitorsDataInit];

        for (const keyVisitors in visitorsCp) {
            for (const key in priceCp) {
                if (priceCp[key].type === visitorsCp[keyVisitors].type) {
                    priceCp[key].active = true;
                    priceCp[key].qty = 0;
                    if (priceCp[key].type === 'adult' || priceCp[key].type === 'all-price') {
                        priceCp[key].qty = 1;
                    }
                }
            }
        }
        setVisitors(priceCp);
    }

    const goToBooking = (slug) => {
        props.history.push('/admission/booking/' + slug);
        localStorage.setItem('date', formatShortDate(selectedDate));
        localStorage.setItem('visitors', JSON.stringify(visitors));
    }

    const totalPrice = (prices) => {
        let total = 0;
        for (const key in prices) {
            total += prices[key].price * prices[key].qty
        }
        return (total === 0) ? props.admissionDetail.priceStartFrom : total;
    }


    let periodOfValidity = null;
    let title = null;
    let tickets = null;
    if (props.admissionDetail) {
        periodOfValidity = props.admissionDetail.periodOfValidity;
        title = props.admissionDetail.tourAdmissionName;
        tickets = props.admissionDetail.tourAdmissionPrice;
    }
    if (!selectedDate || !selectedTicket) {

    }

    return (
        <div className={classes.Wrapper}>
            <PriceDetail 
                show={showPriceDetail}
                hideClicked={() => setShowPriceDetail(false)}
                visitors={visitors}
                goToBookingClicked={() => goToBooking(props.match.params.slug)}
                disableBtn={(!selectedDate || !selectedTicket) ? true : false}
                />
            <Calendar
                periodOfValidity={periodOfValidity}
                show={showCalendar}
                hidePopupClicked={() => setShowCalendar(false)}
                selectedDate={selectedDate}
                onDayClickHandler={(day) => onDayClickHandler(day)}
                />
            <div className={classes.Nav}>
                <Header 
                    classes={['Shadow']}
                    content={title}
                    leftIcon="back"
                    leftClicked={backClickHandler} />
            </div>
            <div className={classes.Content}>
                <div className="u-mb-24">
                    <Date
                        selectedDate={selectedDate}
                        showHideCalendar={() => setShowCalendar(!showCalendar)}/>
                </div>
                <div className="u-mb-24">
                    <Ticket
                        tickets={tickets}
                        selectedTicket={selectedTicket}
                        onSelectTicketHandler={(id, priceArray) => onSetTicketHandler(id, priceArray)}/>
                </div>
                <div className="u-mb-24">
                    <Visitor
                        visitors={visitors}
                        onGuestAddedClicked={(type) => onGuestAddedClicked(type)}
                        onGuestRemoveClicked={(type) => onGuestRemoveClicked(type)}/>
                </div>
            </div>
            <div className={classes.FooterWrapper}>
                <div className={classes.PriceWrapper} onClick={() => setShowPriceDetail(true)}>
                    <div className={classes.Icon}>
                        o
                    </div>
                    <div>
                        <div className={classes.Label}>Price</div>
                        <div className={classes.Price}>
                            <CurrencyFormat value={(visitors === null) ? 0 : totalPrice(visitors)} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                        </div>
                    </div>
                </div>
                <div className={classes.Button}>
                    <Button 
                        disabled={(!selectedDate || !selectedTicket) ? true : false}
                        classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                        clicked={() => goToBooking(props.match.params.slug)}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        admissionDetail: state.AdmissionDetailReducer.admissionDetail,
        isFetchAdmission: state.AdmissionDetailReducer.isFetchAdmissionDetail,
        isErrorFetchAdmission: state.AdmissionDetailReducer.isErrorFetchAdmissionDetail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onInitAdmission: (slug) => dispatch(DetailAction.initAdmissionDetail(slug)),
        onInitVisitors: (data) => dispatch(DetailAction.initVisitors(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(preBooking);
