import React from 'react';
import Header from '../../../components/Desktop/Shared/Header/Header';
import MetaTag from '../../../helper/MetaTag';
import Banner from '../../../components/Desktop/ThingsToDo/Banner/Banner';
import ContentAvailability from '../../../components/Desktop/ThingsToDo/ContentAvailability/ContentAvailability';
import Footer from '../../../components/Desktop/Shared/Footer/Footer';

const thingsToDoContainer = (props) => {
    return (
        <div className="bg-white-dark">
            <MetaTag title="Things To Do" />
            <Header />
            <Banner />
            <ContentAvailability />
            <Footer />
        </div>
    )
}

export default thingsToDoContainer;
