import axios from '../../../axios';
import { toast } from 'react-toastify';
import { ANTAVAYA_API } from '../../../constant/Api';

export const setToDefault = () => {
    return {
        type: 'SET_TO_DEFAULT',
    };
};

export const setHotelDetail = ( hotelDetail ) => {
    return {
        type: 'SET_HOTEL_DETAIL',
        payload: { hotelDetail }
        
    };
};

export  const seIsFetchHotelDetail = (value) => {
    return {
        type: 'SET_IS_FETCH_HOTEL_DETAIL',
        payload: { value }
    }
}

export  const seIsErrorFetchHotelDetail = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_HOTEL_DETAIL',
        payload: { value }
    }
}

export  const checkContactInfoForm = () => {
    return {
        type: 'CHECK_CONTACT_INFO_FORM_HOTEL'
    }
}

export  const changeContactInfoForm = (identifier, value) => {
    return {
        type: 'CHANGE_CONTACT_INFO_FORM_HOTEL',
        payload: { identifier, value }
    }
}

export  const changeInquiryForm = (identifier, value) => {
    return {
        type: 'CHANGE_INQUIRY_FORM_HOTEL',
        payload: { identifier, value }
    }
}

export  const seIsSubmitBooking = (value) => {
    return {
        type: 'SET_IS_SUBMIT_BOOKING_HOTEL',
        payload: { value }
    }
}

export  const seIsSuccessSubmitBooking = (value) => {
    return {
        type: 'SET_IS_SUCCESS_SUBMIT_BOOKING_HOTEL',
        payload: { value }
    }
}

export const fetchHotelDetail = (slug) => {
    return dispatch => {
        dispatch(seIsFetchHotelDetail(true));
        axios.get( ANTAVAYA_API + 'hotel/detail/' + slug )
            .then( res => {
                dispatch(setHotelDetail(res.data.data));
                dispatch(seIsFetchHotelDetail(false));
            } )
            .catch( error => {
                dispatch(seIsErrorFetchHotelDetail(true));
                dispatch(seIsFetchHotelDetail(false));
            } );
    };
};

export const submitInquiry = (hotelId, contactInfoFrom, inquiryForm) => {
    const payload = {
        hotelID: hotelId,
        title: contactInfoFrom.title.value,
        firstName: contactInfoFrom.firstName.value,
        lastName: contactInfoFrom.lastName.value,
        phoneNumber: contactInfoFrom.dialCode.value.concat(contactInfoFrom.phone.value),
        email: contactInfoFrom.email.value,
        question: inquiryForm.question.value,
    }

    return dispatch => {
        dispatch(seIsSubmitBooking(true));
        axios.post( ANTAVAYA_API + 'hotel/inquiry', payload)
        .then((res) => {
            dispatch(seIsSuccessSubmitBooking(true));
            dispatch(seIsSubmitBooking(false));
        })
        .catch( error => {
            dispatch(seIsSubmitBooking(false));
            if (error && error.response) {
                switch (typeof error.response.data.message) {
                    case 'string':
                        toast.error(error.response.data.message);
                        console.log(error.response.data.message)
                        break;
                    case 'object':
                        for (const key in error.response.data.message) {
                            toast.error(error.response.data.message[key]);
                        }
                        break;
                    default:
                        break;
                }
            }
        })
    }
}