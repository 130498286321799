import React from 'react'
import classes from './TourDetail.module.scss'
import PanelBox from '../../../../UI/PanelBox/PanelBox'
import Icon from '../../../../UI/Icon/Icon'
import ImgThumb from '../../../../UI/ImgThumb/ImgThumb';
import { formatHumanDate, pluralize, toCapitalize } from '../../../../../helper/GlobalFunc';

const TourDetail = (props) => {
	let room = pluralize(props.bookingData.roomCount, 'Room', 'Rooms');
	let adult = (props.bookingData.roomAdultCount > 0) ? ' - ' + pluralize(props.bookingData.roomAdultCount, 'Adult', 'Adults') : '';
	let child = (props.bookingData.roomChildCount > 0) ? ' & ' + pluralize(props.bookingData.roomChildCount, 'Child', 'Children') : '';

	let hotel = null;
	if (props.bookingData && Object.keys(props.bookingData.hotel).length > 0) {
		hotel = <div className={classes.CardContent}>
			<div className={[classes.Detail, classes.Tour].join(' ')}>
				<div className={classes.Icon}>
					<Icon name='ico-hotel'
						fill='#6A6E73'
						stroke='none'
						width={26}
					/>
				</div>
				<div className={[classes.Value, classes.Center].join(' ')}>
					Hotels
				</div>
			</div>
		</div>;
	}

	let flight = null;
	if (props.bookingData && Object.keys(props.bookingData.flight).length > 0) {
		flight = <div className={classes.CardContent}>
			<div className={[classes.Detail, classes.Tour].join(' ')}>
				<div className={classes.Icon}>
					<Icon name='ico-flight-o'
						fill='#6A6E73'
						stroke='none'
						width={26}/>
				</div>
				<div className={[classes.Value, classes.Center].join(' ')}>
					{props.bookingData.flight.flightName}
				</div>
			</div>
		</div>;
	}
	
	return (
		<PanelBox>
			<div className={classes.Card}>
				<div className={classes.CardContent}>
					<div className={classes.Image}>
						<ImgThumb type="square" src={props.bookingData.image.url} />
					</div>
					<div className={classes.Info}>
						<div className={classes.Title}>
							{props.bookingData.days}D {toCapitalize(props.bookingData.title)} {toCapitalize(props.bookingData.title)}
						</div>
						<div className={classes.Desc}>{pluralize(props.bookingData.days, 'Day', 'Days')} & {pluralize(props.bookingData.night, 'Night', 'Nights')}</div>
					</div>
				</div>
				<div className={classes.Divider} />
				<div className={classes.CardContent}>
					<div className={[classes.Detail, classes.Tour].join(' ')}>
						<div className={classes.Icon}>
							<Icon name='ico-calendar'
								fill='#6A6E73'
								stroke='none'
								width={26}/>
						</div>
						<div className={[classes.Value, classes.Center].join(' ')}>
							{formatHumanDate(props.bookingData.startDate)} - {formatHumanDate(props.bookingData.endDate)}
						</div>
					</div>
				</div>

				{hotel}
				
				{flight}
				<div className={classes.CardContent}>
					<div className={[classes.Detail, classes.Tour].join(' ')}>
						<div className={classes.Icon}>
							<Icon name='ico-user-o'
								fill='#6A6E73'
								stroke='none'
								width={26}/>
						</div>
						<div className={[classes.Value, classes.Center].join(' ')}>
							{room}{adult}{child}
						</div>
					</div>
				</div>
			</div>
		</PanelBox>
	)
}
export default TourDetail
