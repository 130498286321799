import React from 'react';
import classes from './ContactInfo.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import Select from '../../../../../UI/Inputs/Select/Select';
import Switch from '../../../../../UI/Inputs/Switch/Switch';
import SelectFlag from '../../../../../UI/Inputs/SelectFlag/SelectFlag';

function contactInfo(props) {
    return (
        <PanelBox isOverflow={false}>
            <div className={classes.Wrapper}>
                <div className={classes.Title}>Contact Information</div>
                <div className="u-p-24">
                    <div className="u-display-flex u-mb-24">
                        <div className="u-mr-16 u-flex-3">
                            <div className="label">Title</div>
                            <Select 
                                placeholder="Title"
                                value={props.contactInfo.title.value}
                                options={props.contactInfo.title.options}
                                changed={(val) => props.inputChanged(val, 'title')} />
                        </div>
                        <div className="u-mr-16 u-flex-10">
                            <div className="label">First Name</div>
                            <TextField
                                placeholder="e.g. Julie" 
                                value={props.contactInfo.firstName.value} 
                                changed={(event) => props.inputChanged(event.target.value, 'firstName')}
                                hasError={props.contactInfo.firstName.hasError && props.contactInfo.firstName.errorMsg !== ''}
                                errorMsg={props.contactInfo.firstName.errorMsg}/>
                        </div>
                        <div className="u-flex-10">
                            <div className="label">Last Name</div>
                            <TextField
                                placeholder="e.g. Applesed" 
                                value={props.contactInfo.lastName.value} 
                                changed={(event) => props.inputChanged(event.target.value, 'lastName')}
                                hasError={props.contactInfo.lastName.hasError && props.contactInfo.lastName.errorMsg !== ''}
                                errorMsg={props.contactInfo.lastName.errorMsg}/>
                        </div>
                    </div>
                    <div className="u-display-flex">
                        <div className="u-mr-16 u-flex-10">
                            <div className="label">Phone Number</div>
                            <div className="u-display-flex">
                                <div className="u-flex-4 u-mr-8">
                                    <SelectFlag 
                                        value={props.contactInfo.dialCode.value}
                                        changed={val => props.inputChanged(val, 'dialCode')}/>
                                </div>
                                <div className="u-flex-6">
                                    <TextField
                                        type="text"
                                        placeholder="e.g. 8123456789" 
                                        value={props.contactInfo.phone.value} 
                                        changed={(event) => props.inputChanged(event.target.value, 'phone')}
                                        hasError={props.contactInfo.phone.hasError && props.contactInfo.phone.errorMsg !== ''}
                                        errorMsg={props.contactInfo.phone.errorMsg}/>
                                </div>
                            </div>
                        </div> 
                        <div className="u-flex-10">
                            <div className="label">E-mail Address</div>
                            <TextField 
                                placeholder="e.g. youremail@example.com" 
                                value={props.contactInfo.email.value} 
                                changed={(event) => props.inputChanged(event.target.value, 'email')}
                                hasError={props.contactInfo.email.hasError && props.contactInfo.email.errorMsg !== ''}
                                errorMsg={props.contactInfo.email.errorMsg}/>
                        </div> 
                    </div>
                </div>
                <div className={classes.Action}>
                    <div>I’m Participant</div>
                    <div>
                        <Switch 
                            checked={props.contactInfo.isParticipant.value}
                            changed={(event) => props.inputChanged(event.target.checked, 'isParticipant')} />
                    </div>
                </div>
            </div>
        </PanelBox>
    );
}

export default contactInfo;