import { updateObject } from '../../utility';
import { DEFAULT_TITLE, ADULT_TITLE, CHILD_TITLE, DEFAULT_DIAL_CODE, GUEST_TYPE_TOUR, DEFAULT_COUNTRY_CODE } from '../../../constant/General';
import * as validation from '../../../helper/Validation';
import Moment from 'moment';

const contactInfoFormInit = {
    title: {
        value: DEFAULT_TITLE,
        options: ADULT_TITLE,
        hasError: false,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    firstName: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    lastName: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    email: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true,
            isEmail: true
        }
    },
    dialCode: {
        value: DEFAULT_DIAL_CODE
    },
    phone: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true,
            isNumericPhone: true,
            minLength: 7,
        }
    },
    isParticipant: {
        value: false
    },
};

const initialState = {
    isInitLoading: true,
    tourDetail: null,
    isValidForm: false,
    isValidParticipantForm: false,
    isSubmitBookLoading: false,
    isErrorInitBooking: false,
    contactInfoForm: contactInfoFormInit,
    participantForm: []
}

const checkBookingForm = state => {
    let contactInfoForm = {...state.contactInfoForm};
    for (const identifier in contactInfoForm) {
        let validate = validation.validate(contactInfoForm[identifier].rules, contactInfoForm[identifier].value);
        contactInfoForm[identifier] = {...contactInfoForm[identifier], ...validate};
    }

    let participantForm = [...state.participantForm];
    if (participantForm && participantForm.length > 0){
        participantForm.forEach( room => {
            room = validatePerRoom(room);
        });
    }
    
    return updateObject(state, {contactInfoForm, participantForm});
}

const checkFormPerRoom = (state, action) => {
    const participantFormCp = [...state.participantForm];
    let room = validatePerRoom(participantFormCp[action.payload.index]);
    participantFormCp[action.payload.index] = {...participantFormCp[action.payload.index], ...room};
    return updateObject(state, {participantForm: participantFormCp});
}

const validatePerRoom = room => {
    let isValidRoom = true;
    let errorMsg = '';
    for (const guestKey in GUEST_TYPE_TOUR) {
        let guestType = GUEST_TYPE_TOUR[guestKey];
        for (const guestIndex in room[guestType]) {
            for (const identifier in room[guestType][guestIndex]) {
                let validate = validation.validate(room[guestType][guestIndex][identifier].rules, room[guestType][guestIndex][identifier].value);
                room[guestType][guestIndex][identifier] = {...room[guestType][guestIndex][identifier], ...validate};
            }
            let isValidPerRoom = validation.isValidForm(room[guestType][guestIndex]);
            if (!isValidPerRoom) {
                isValidRoom = false;
                errorMsg = 'please complete this data';
            }
        }
    }
    room.isValidRoom = isValidRoom;
    room.errorMsg = errorMsg;
    return room;
}

const setContactInfoForm = (state, action) => {
    
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let contactInfoForm = {...state.contactInfoForm};

    let validate = validation.validate(contactInfoForm[identifier].rules, value);
    contactInfoForm[identifier] = {...contactInfoForm[identifier], ...validate};

    let isValidForm = validation.isValidForm(contactInfoForm);

    let participantForm = [...state.participantForm];
    if (identifier === 'isParticipant') {
        if (value) {
            participantForm[0].adult[0].firstName.value = state.contactInfoForm.firstName.value;
            participantForm[0].adult[0].firstName.hasError = false;
            participantForm[0].adult[0].lastName.value = state.contactInfoForm.lastName.value;
            participantForm[0].adult[0].lastName.hasError = false;
            participantForm[0].adult[0].title.value = state.contactInfoForm.title.value;
            participantForm[0].adult[0].title.hasError = false;
        } else {
            participantForm[0].adult[0].firstName.value = '';
            participantForm[0].adult[0].firstName.hasError = true;
            participantForm[0].adult[0].lastName.value = '';
            participantForm[0].adult[0].lastName.hasError = true;
        }
        participantForm[0] = {...participantForm[0], ...validatePerRoom(participantForm[0])};
    }

    let isValidParticipantForm = checkIsValidParticipantForm(participantForm);

    return updateObject(state, {
        contactInfoForm,
        isValidForm,
        isValidParticipantForm
    });
}

const checkIsValidParticipantForm = (participantForm) => {
    let isValidParticipantStatus = true;
    for (let roomKey in participantForm) {
        for (const guestKey in GUEST_TYPE_TOUR) {
            let guestType = GUEST_TYPE_TOUR[guestKey];
            for (let formKey in participantForm[roomKey][guestType]) {
                isValidParticipantStatus = validation.isValidForm(participantForm[roomKey][guestType][formKey]);
                if (!isValidParticipantStatus) {
                    break;
                }
            }
        }
    }
    return isValidParticipantStatus;
}

const setParticipantForm = (state, action) => {
    let guestDataCp = [...state.participantForm];
    let value = action.payload.value;
    let roomIndex = action.payload.roomIndex;
    let guestType = action.payload.guestType;
    let guestIndex = action.payload.guestIndex;
    let identifier = action.payload.identifier;
    let validate = {
        hasError: false,
        errorMsg: '',
        value: value
    }

    if (guestDataCp[roomIndex][guestType][guestIndex][identifier].rules) {
        for (var key in guestDataCp[roomIndex][guestType][guestIndex][identifier].rules) {
            validate = validation[key](value, guestDataCp[roomIndex][guestType][guestIndex][identifier].rules[key]);
            if (validate.hasError) {
                break;
            }
        }
    } 

    guestDataCp[roomIndex][guestType][guestIndex][identifier] = {...guestDataCp[roomIndex][guestType][guestIndex][identifier], ...validate};
    
    let isValidParticipantForm = checkIsValidParticipantForm(guestDataCp);

    return updateObject(state, {
        participantForm: guestDataCp,
        isValidParticipantForm
    });
}

const setBookingDetail = (state, action) => {
    let newFormatRoomList = [];
    let rooms = action.payload.room;

    for (let i = 0; i < rooms.length; i++) {
        let room = rooms[i];
        let adult = [];
        let child = [];
        let isExtraBed = null;
        for(let key in room) {
            let hasObj = {};
            if(key === "adult" && room[key] > 0) {
                for(let j=0; j < room[key]; j++) {
                    adult.push(hasObj);
                }
            } else if(key === "child" && room[key] > 0) {
                for(let k=0; k < room[key]; k++) {
                    child.push(hasObj);
                }
            } else if(key === "isExtraBed") {
                isExtraBed = room[key];
            }
        }

        let payload = {
            adult: adult,
            child: child,
            isExtraBed: isExtraBed
        }
        
        newFormatRoomList.push(payload);
    }
    let participantForm = setguestDataHandler(newFormatRoomList);

    return updateObject(state, {
        tourDetail: action.payload,
        contactInfoForm: contactInfoFormInit,
        participantForm
    });
}

const setguestDataHandler = roomList => {
    let guestDataCp = [];

    const currentDate = new Date();
    const indexMonth = currentDate.getMonth()
    const currentMonth = indexMonth + 1;

    const monthOptions = Moment.months().map((month, index) => {
        return {
            value: (index + 1).toString(),
            text: month
        }
    });

    const limitYear = 80;
    const limitYearPassport = 5;

    const currentYear = currentDate.getFullYear();
    let yearOptionAdult = [];

    for (let index = currentYear - 13; index >= currentYear - limitYear; index--) {
        yearOptionAdult.push({
            value: index.toString(),
            text: index
        });
        
    }

    let yearOptionChild = [];
    for (let index = currentYear - 2; index >= currentYear - 12; index--) {
        yearOptionChild.push({
            value: index.toString(),
            text: index
        });
        
    }

    let yearOptionPassport = [];
    for (let index = currentYear; index <= currentYear + limitYearPassport; index++) {
        yearOptionPassport.push({
            value: index.toString(),
            text: index
        });
        
    }

    const days = getDay(indexMonth, currentYear);
    const currentDay = currentDate.getDate();
    const dayOptions = days.map((day) => {
        return {
            value: day.toString(),
            text: day
        }
    });

    if (roomList && roomList.length > 0) {
        roomList.forEach(room => {
            let roomData = {
                adult: [],
                child: [],
                isExtraBed: room.isExtraBed,
                isValidRoom: false,
                errorMsg: ''
            }
            for (const key in GUEST_TYPE_TOUR) {
                if (room[GUEST_TYPE_TOUR[key]] && room[GUEST_TYPE_TOUR[key]].length > 0) {
                    let titleOptions = ADULT_TITLE;
                    if (GUEST_TYPE_TOUR[key] === 'child') {
                        titleOptions = CHILD_TITLE;
                    }
                    room[GUEST_TYPE_TOUR[key]].forEach(() => {
                        roomData[GUEST_TYPE_TOUR[key]].push({
                            title: {
                                value: DEFAULT_TITLE,
                                options: titleOptions,
                                hasError: false,
                                errorMsg: '',
                                rules : {
                                    required: true
                                }
                            },
                            day: {
                                value: currentDay.toString(),
                                options: dayOptions,
                                hasError: false,
                                errorMsg: '',
                                rules : {
                                    required: true
                                }
                            },
                            month: {
                                value: currentMonth.toString(),
                                options: monthOptions,
                                hasError: false,
                                errorMsg: '',
                                rules : {
                                    required: true
                                }
                            },
                            year: {
                                value: (GUEST_TYPE_TOUR[key] === 'child') ? (currentYear-2).toString() : (currentYear-13).toString(),
                                options: (GUEST_TYPE_TOUR[key] === 'child') ? yearOptionChild : yearOptionAdult,
                                hasError: false,
                                errorMsg: '',
                                rules : {
                                    required: true
                                }
                            },
                            firstName: {
                                value: '',
                                hasError: true,
                                errorMsg: '',
                                rules : {
                                    required: true
                                }
                            },
                            lastName: {
                                value: '',
                                hasError: true,
                                errorMsg: '',
                                rules : {
                                    required: true
                                }
                            },
                            countryCode: {
                                value: DEFAULT_COUNTRY_CODE,
                                hasError: false,
                                errorMsg: '',
                                rules : {
                                    required: true
                                }
                            },
                            passportNumber: {
                                value: '',
                                hasError: false,
                                errorMsg: '',
                                rules : {
                                    isNumeric: true
                                }
                            },
                            dayPassport: {
                                value: currentDay.toString(),
                                options: dayOptions,
                                hasError: false,
                                errorMsg: ''
                            },
                            monthPassport: {
                                value: currentMonth.toString(),
                                options: monthOptions,
                                hasError: false,
                                errorMsg: ''
                            },
                            yearPassport: {
                                value: currentYear.toString(),
                                options: yearOptionPassport,
                                hasError: false,
                                errorMsg: ''
                            },
                            isIncludeVisa: {
                                value: false
                            },
                        });
                    });
                }
            }
            guestDataCp.push(roomData);
        });
    }
    return guestDataCp;
}
const getDay = (month, year) => {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date).getDate());
        date.setDate(date.getDate() + 1);
    }
    return days;
}

const setIsSubmitBookLoading = (state, action) => {
    return updateObject(state, {
        isSubmitBookLoading: action.payload.value
    })
}

const setIsInitLoading = (state, action) => {
    return updateObject(state, {
        isInitLoading: action.payload.value
    })
}

const setIsErrorInitBooking = (state, action) => {
    return updateObject(state, {
        isErrorInitBooking: action.payload.value
    })
}

const setInitContactInfo = (state, action) => {
    let member = action.payload.member;
    let contactInfoForm = {...state.contactInfoForm};

    contactInfoForm.title.value = member.title;
    contactInfoForm.firstName.value = member.firstName;
    contactInfoForm.firstName.hasError = false;
    contactInfoForm.lastName.value = member.lastName;
    contactInfoForm.lastName.hasError = false;
    contactInfoForm.email.value = member.email;
    contactInfoForm.email.hasError = false;
    contactInfoForm.dialCode.value = member.countryCode;
    contactInfoForm.phone.value = member.phone;
    contactInfoForm.phone.hasError = false;

    let isValidForm = validation.isValidForm(contactInfoForm);

    return updateObject(state, { contactInfoForm, isValidForm });
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_CONTACT_INFO_FORM': return setContactInfoForm(state, action);
        case 'SET_PARTICIPANT_FORM': return setParticipantForm(state, action);
        case 'SET_BOOKING_DETAIL': return setBookingDetail(state, action);
        case 'SET_IS_ERROR_INIT_BOOKING': return setIsErrorInitBooking(state, action);

        case 'CHECK_BOOKING_FORM': return checkBookingForm(state);
        case 'CHECK_FORM_PER_ROOM': return checkFormPerRoom(state, action);

        case 'SET_IS_SUMBIT_BOOK_LOADING': return setIsSubmitBookLoading(state, action);
        case 'SET_IS_INIT_LOADING': return setIsInitLoading(state, action);
        case 'SET_INIT_CONTACT_INFO': return setInitContactInfo(state, action);
        default: return state;
    }
}

export default reducer;