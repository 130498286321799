import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';
import { toast } from 'react-toastify';

export const inputChanged = (value, identifier) => {
    return {
        type: 'FORGOT_PASSWORD_INPUT_CHANGED',
        payload: { value, identifier }
    }
}

export const checkingForm = () => {
    return {
        type: 'FORGOT_PASSWORD_CHECKING_FORM'
    }
}

export const setIsSubmitLoading = (value) => {
    return {
        type: 'FORGOT_PASSWORD_SET_IS_SUBMIT_LOADING',
        payload: { value }
    }
}

export const setIsSuccessSubmit = (value) => {
    return {
        type: 'FORGOT_PASSWORD_SET_IS_SUCCESS_SUBMIT',
        payload: { value }
    }
}

export const submitEmail = (form) => {
    let payload = {
        email: form.email.value
    }
    return dispatch => {
        dispatch(setIsSubmitLoading(true));
        axios.post(ANTAVAYA_API + 'member/reset-password', payload)
            .then((res) => {
                dispatch(setIsSuccessSubmit(true));
                dispatch(setIsSubmitLoading(false));
            })
            .catch( error => {
                dispatch(setIsSubmitLoading(false));
                if (error.response) {
                    if (error.response.data.message){
                        switch (typeof error.response.data.message) {
                            case 'string':
                                toast.error(error.response.data.message);
                                break;
                            case 'object':
                                for (const key in error.response.data.message) {
                                    toast.error(error.response.data.message[key][0]);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
            })
    }
}