import React, { useState, useEffect } from 'react';
import classes from './SettingContainer.module.scss';
import Icon from '../../../../components/UI/Icon/Icon';
import { COLOR } from '../../../../constant/Library';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import Button from '../../../../components/UI/Button/Button';
import AddParticipant from '../../../../components/Mobile/NonProduct/AddParticipant/AddParticipant';
import ChangePassword from '../../../../components/Mobile/NonProduct/ChangePassword/ChangePassword';
import ContentLoader from 'react-content-loader';
import Swipeout from 'rc-swipeout';
import '../../../../../node_modules/rc-swipeout/dist/rc-swipeout.css';
import participantEmptyImg from '../../../../assets/images/art-participant-empty.png';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';

import { connect } from 'react-redux';
import * as participantAction from '../../../../redux/Shared/Participant/ParticipantAction';

const settingContainer = props => {
    const [showParticipantForm, setShowParticipantForm] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [participantId, setParticipantId] = useState(null);

    useEffect(() => {
        props.onInitParticipantList();
    }, []);

    useEffect(() => {
        setShowChangePassword(false);
    }, [props.successCHangePassword]);

    useEffect(() => {
        if (props.successSubmit) {
            setShowParticipantForm(false);
        }
    }, [props.successSubmit]);

    useEffect(() => {
        if (props.succesDelete) {
            setDeleteAlert(false);
        }
    }, [props.succesDelete]);
    
    const goToUrl = url => {
        props.history.push(url);
    }

    const editClickHandler = (participant, index) => {
        props.setEditParticipant(participant, index);
        setShowParticipantForm(true);
    }

    const addClickHandler = () => {
        props.setAddParticipant();
        setShowParticipantForm(true);
    }

    const showDeleteAlertHandler = (id) => {
        setDeleteAlert(true);
        setParticipantId(id)
    }

    const deleteParticipantHandler = () => {
        let data = {
            passengerID: String(participantId)
        }
        props.onDeleteParticipant(data);
    }

    let participantContent = <ContentLoader
        height={800}
        speed={1}
        primaryColor="#e6e6e6">
        <rect x="0" y="0" rx="5" ry="5" width="400" height="20" />
        <rect x="0" y="50" rx="5" ry="5" width="400" height="20" />
        <rect x="0" y="100" rx="5" ry="5" width="400" height="20" />
    </ContentLoader>
    if (!props.isFetchParticipant) {
        if (props.participants && props.participants.length > 0) {
            participantContent = props.participants.map((participant, index) => {
                return <div key={index} className={classes.SwiperContent}>
                    <Swipeout
                        left={[{
                            text: <div className={classes.Delete}><Icon name="ico-trash" width={15} fill="white" stroke="none"/></div>,
                            onPress:() => showDeleteAlertHandler(participant.id),
                        }]}>
                        <PanelBox className={classes.Participant}>
                            <div className={classes.Body} onClick={() => editClickHandler(participant, index)}>
                                <span className={classes.Label}>{participant.title} {participant.firstName} {participant.lastName}</span>
                                <div><Icon name="ico-chev-right" fill={COLOR.black} stroke="none" /></div>
                            </div>  
                        </PanelBox>
                    </Swipeout>
                </div>
            });
        } else {
            participantContent = <div className="u-text-center u-mb-24"><img src={participantEmptyImg} alt="" width="100%" /></div>
        }
    }
    
    return (
        <div>
            <AddParticipant 
                show={showParticipantForm}
                hideClicked={() => setShowParticipantForm(false)} />
            
            <Confirm show={deleteAlert}
                title="Delete This Participant?"
                desc="By deleting this participant, you won't be able to fill in their details automatically upon future bookings."
                cancelClicked={() => setDeleteAlert(false)}
                isConfirmLoading={props.isDeleteLoading}
                confirmClicked={() => deleteParticipantHandler()}/>

            <ChangePassword 
                show={showChangePassword}
                hideClicked={() => setShowChangePassword(false)} />

            <div className={[classes.Shadow, classes.Header].join(' ')}>
                <span className={classes.Action}
                    onClick={() => goToUrl('/user')}>
                    <Icon name="ico-arrow-left" fill={COLOR.white} stroke="none" />
                </span>
                <span>Setting</span>
            </div>
            <div className={classes.ContentWrapper}>
                <div className={classes.Content}>
                    
                    <div className={classes.Section} onClick={() => setShowChangePassword(true)}>
                        <PanelBox>
                            <div className={classes.Body}>
                                <span className={[classes.Label, classes.Bold].join(' ')}>Change Password</span>
                                <div><Icon name="ico-chev-right" fill={COLOR.black} stroke="none" /></div>
                            </div>  
                        </PanelBox>
                    </div>
                    <div className={classes.SectionTitle}>Participants</div>
                    {participantContent}

                    <div>
                        <Button clicked={addClickHandler} classes={['BtnMedium', 'BtnBlock', 'BtnOutlineRed']}>
                            <span className={classes.AddLabel}>
                                <Icon name="ico-add" fill={COLOR.red} stroke="none" />
                                Add Participant
                            </span>
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        isFetchParticipant : state.ParticipantReducer.isFetchParticipant,
        participants : state.ParticipantReducer.participants,
        successSubmit: state.ParticipantReducer.successSubmit,
        succesDelete: state.ParticipantReducer.succesDelete,
        isDeleteLoading: state.ParticipantReducer.isDeleteLoading,
        successCHangePassword: state.MemberReducer.successChangePassword
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitParticipantList: () => dispatch(participantAction.getParticipantList()),
        setEditParticipant: (participant, index) => dispatch(participantAction.setEditParticipant(participant, index)),
        setAddParticipant: () => dispatch(participantAction.setAddParticipant()),
        onDeleteParticipant: (data) => dispatch(participantAction.onDeleteParticipant(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(settingContainer);