import React from 'react';
import classes from './FreeVoucher.module.scss';

const destinationList = (props) => {
    return (
        <div className={classes.Wrapper} onClick={props.clicked}>
            <span className={classes.Main}>Free Voucher Tour</span>
            Get Rp 500.000 Free Voucher 
        </div>
    );
}

export default destinationList;