import React, { Component } from 'react'
import classes from './ContactPage.module.scss'
import TourLeader from './Component/TourLeader/TourLeader'
import Antavaya from './Component/Antavaya/Antavaya'

class ContactPage extends Component {
  render () {
    return (
      <div className={classes.Content}>
        <TourLeader />
        <Antavaya/>
      </div>
    )
  }
}
export default ContactPage
