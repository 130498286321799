import React, { useState, useEffect } from 'react';
import classes from './BookingDetailContainer.module.scss';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import Slider from 'react-slick';
import TourDetail from './TourDetail/TourDetail';
import Contact from './Contact/Contact';
import Information from './Information/Information';
import Itenerary from './Itinerary/Itinerary';
import { BOOKING_DETAIL_TAB, DEFAULT_BOOKING_DETAIL_TAB } from '../../../../constant/General';
import NotFound from './NotFound/NotFound';

import { connect } from 'react-redux';
import * as myBookingAction from '../../../../redux/Shared/MyBooking/MyBookingAction';

const bookingDetailContainer = (props) => {
    const [activePage, setActivePage] = useState(DEFAULT_BOOKING_DETAIL_TAB);

    useEffect(() => {
        props.initBookingDetail({ orderNumber: props.match.params.bookingCode });
    }, []);

    const backClickHandler = () => {
        props.history.goBack();
    }

    const downloadBtnHandler = (url) => {
        window.open(url, '_blank');
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        variableWidth: true,
        centerPadding: '0px',
        fade: false,
        dotsClass: 'slick-dots ' + classes.DotWrapper,
        autoplay: false,
        autoplaySpeed: 3500,
        swipeToSlide: true
    }

    let labelList = [];
    labelList = BOOKING_DETAIL_TAB.map((item, index) => {
        let labelClasses = [classes.Item]
        if (item.slug === activePage) {
            labelClasses = [classes.Item, classes.Active]
        }
        return (
            <div
                key={index}
                className={classes.ItemWrapper}
                onClick={() => setActivePage(item.slug)}>
                <div className={labelClasses.join(' ')}>{item.label}</div>
            </div>
        )
    });
    labelList = <Slider {...settings}>{labelList}</Slider>

    let activePageComponent;
    console.log('props.isErrorFetch', props.isErrorFetch);
    if (props.isErrorFetch) {
        activePageComponent = <NotFound />;
    } else {
        switch (activePage) {
            case 'tour-detail': activePageComponent = <TourDetail isLoading={props.isFetch} detailTour={props.bookingDetail}/>
                break;
            case 'itinerary': activePageComponent = <Itenerary iteneraryList={props.bookingDetail.itineraries}/>
                break;
            case 'contact': activePageComponent = <Contact/>
                break;
            case 'other-information': activePageComponent = <Information/>
                break;
            default: break;
        }
    }


    return (
        <div className={classes.Wrapper}>
            <Header
                classes={['Shadow']}
                leftIcon="back"
                leftClicked={backClickHandler}
                content="My Booking"
                downloadBtn={(props.bookingDetail && props.bookingDetail.pdfUrl) ? () => downloadBtnHandler(props.bookingDetail.pdfUrl): null}/>
            <div className={classes.LabelList}>
                {labelList}
            </div>
            {activePageComponent}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        bookingId: state.MyBookingReducer.bookingId,
        bookingDetail: state.MyBookingReducer.bookingDetail,
        isFetch: state.MyBookingReducer.isFetchBookingDetail,
        isErrorFetch: state.MyBookingReducer.isErrorFetchBookingDetail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initBookingDetail: (data) => dispatch(myBookingAction.initBookingDetail(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(bookingDetailContainer);

// const Footer = props => {
//     let textStyle = [classes.TextSuccess]
//     if (props.classes && props.classes.length > 0) {
//         props.classes.forEach(val => {
//             textStyle.push(classes[val])
//         })
//     }
//     return (
//         <>
//             <Icon name={props.icon} fill={props.fill} stroke='none' width={16} />
//             <div className={textStyle.join(' ')}>{props.text}{props.timer}</div>
//         </>
//     )
// }
