import React from 'react';
import classes from './Switch2.module.scss';

const switch2 = (props) => {
    let leftBtnClasses = [classes.Btn, classes.Active];
    let rightBtnClasses = [classes.Btn];
    if (props.value) {
        leftBtnClasses = [classes.Btn];
        rightBtnClasses = [classes.Btn, classes.Active];
    }

    return (
        <div className={classes.Wrapper} onClick={props.toggleClicked}>
            <div className={leftBtnClasses.join(' ')}>{props.labels[0]}</div>
            <div className={rightBtnClasses.join(' ')}>{props.labels[1]}</div>
        </div>
    );
}

export default switch2;