import React, { Component } from 'react';
import classes from './SelectWithHtml.module.scss';
import Icon from '../../Icon/Icon';
import onClickOutside from "react-onclickoutside";
import CSSTransition from 'react-transition-group/CSSTransition';

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showList: false,
        };
    }

    showListHandler = () => {
        this.setState({showList: true});
    }

    hideListHandler = () => {
        this.setState({showList: false});
    }

    handleClickOutside = (evt) => {
        this.hideListHandler();
    }

    getTextHandler = (val, options) => {
        let index = options.findIndex(option => option.value === val);
        let text = <div className={classes.Placeholder}>{this.props.placeholder}</div>;
        if (options && options[index]) {
            text = options[index].text;
        }
        return text;
    }

    setValueHandler = (val) => {
        this.hideListHandler();
        this.props.changed(val);
        // console.log('changed', this.props.tourPackage.schedule)
        this.props.setItinerary(val)
    }


    render() {
        let iconClasses = [classes.Icon];
        if (this.state.showList) {
            iconClasses.push(classes.IsActive);
        }

        let text = <div className={classes.Placeholder}>{this.props.placeholder}</div>;
        if (this.props.value) {
            text = this.getTextHandler(this.props.value, this.props.options);
        }

        let options = null;
        if (this.props.options && this.props.options.length > 0) {
            options = this.props.options.map((option, index) => {
                let activeClass = [classes.Item];
                if (this.props.value === option.value) {
                    activeClass = [classes.Item, classes.Active];
                }
                return (
                    <li key={index} className={activeClass.join(' ')} onClick={() => this.setValueHandler(option.value)}>
                        {option.text}
                    </li>
                );
            });
        }

        return (
            <div className={classes.Wrapper}>
                <div className={classes.Select} onClick={this.showListHandler}>
                    <div className={classes.Input}>
                        <div className={classes.Select}>
                            {text}
                        </div>
                        {/* <input 
                            className={classes.Select} 
                            value={text} 
                            placeholder={this.props.placeholder}
                            readOnly /> */}
                    </div>
                    <div className={iconClasses.join(' ')}>
                        <Icon name="ico-caret-down" fill="#202124" stroke="none" />
                    </div>
                </div>
                <CSSTransition
                    in={this.state.showList}
                    mountOnEnter
                    unmountOnExit
                    timeout={270}
                    classNames={{
                        enterActive: classes.Open,
                        exitActive: classes.Close
                    }}>
                    <ul className={classes.List}>
                        {options}
                    </ul>
                </CSSTransition>
            </div>
        );
    }
}

export default onClickOutside(Select);