import React from 'react';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import classes from './HighlightLoader.module.scss'
import ContentLoader from 'react-content-loader';

const highlightLoader = () => {
    return (
        <div className="u-mb-16">
            <PanelBox>
                <div className={classes.Wrapper}>
                    <ContentLoader
                        height={255}
                        speed={1}
                        primaryColor={'#e6e6e6'}
                    >
                        <rect x="0" y="0" rx="4" ry="4" width="70" height="13" />

                        <rect x="0" y="25" rx="3" ry="3" width="150" height="10" />
                        <rect x="0" y="45" rx="3" ry="3" width="180" height="10" />
                        <rect x="0" y="65" rx="3" ry="3" width="175" height="10" />

                        <rect x="0" y="85" rx="3" ry="3" width="200" height="10" />
                        <rect x="0" y="105" rx="3" ry="3" width="180" height="10" />
                        <rect x="0" y="125" rx="3" ry="3" width="175" height="10" />
                        
                        <rect x="0" y="145" rx="3" ry="3" width="150" height="10" />
                        <rect x="0" y="165" rx="3" ry="3" width="180" height="10" />
                        <rect x="0" y="185" rx="3" ry="3" width="175" height="10" />

                        <rect x="0" y="205" rx="3" ry="3" width="200" height="10" />
                        <rect x="0" y="225" rx="3" ry="3" width="180" height="10" />
                        <rect x="0" y="245" rx="3" ry="3" width="175" height="10" />
                    </ContentLoader>
                </div>
            </PanelBox>
        </div>
    )
}

export default highlightLoader;