import React, { useState } from "react";
import classes from "./ParticipantList.module.scss";
import PanelBox from "../../../../UI/PanelBox/PanelBox";
import { GUEST_TYPE_TOUR } from "../../../../../constant/General";
import Icon from "../../../../UI/Icon/Icon";
import { toCapitalize } from "../../../../../helper/GlobalFunc";
import FillData from "./Component/FillData/FillData";

const ParticipantList = props => {
	const [isActive, setIsActive] = useState(false);

	const backHandler = () => {
		props.checkFormPerRoom();
		setIsActive(false);
	}
	
	let sideIcon = <Icon name="ico-chev-right" fill="#202124" stroke="none" />;
	let iconStyle = [classes.Arrow],
		divider = null,
		roomInfo = null,
		adultInfo = null,
		childInfo = null,
		note = null;

	GUEST_TYPE_TOUR.forEach(guestType => {
		if (props.roomData[guestType] && props.roomData[guestType].length > 0) {
			props.roomData[guestType].forEach(form => {
				if (form.firstName.value) {
					sideIcon = 'Edit';
					iconStyle = [classes.Arrow, classes.Edit];

					divider = <div className={classes.DivWrapper}>
							<div className={classes.Divider} />
						</div>;
					note = <div className={classes.Note}>
							Note: Press edit to fill participant data
						</div>;

					roomInfo = props.roomData[guestType].map((form, guestIndex) => {
						return (
							<div key={guestIndex} className={classes.RoomInfo}>
								<div className={classes.Type}>
									{guestType === 'adult' ? 'Adult ' : 'Children '}{guestIndex + 1}
								</div>
								<div className={!form.firstName.value ? classes.EmptyName : classes.Name}>
								{!form.firstName.value ? 'Participant data is empty' : `${toCapitalize(form.title.value)}. ${toCapitalize(form.firstName.value)} ${toCapitalize(form.lastName.value)}`}
								</div>
							</div>
						);
					});
					if (guestType === 'adult') {
						adultInfo = roomInfo;
					} else if (guestType === 'child') {
						childInfo = roomInfo;
					}
				}
			});
		}
    });
    
    let classRoom = classes.WarningWrapper;
    if (props.warningRoom) {
        classRoom = [classes.WarningWrapper, classes.OutlineRed].join(' ');
    }

	return (
		<PanelBox isOverflow={false}>

            <FillData
                roomIndex={props.roomIndex}
                roomData={props.roomData}
                guestInputChanged={props.guestInputChanged}
                setIsIncludeVisaClicked={props.setIsIncludeVisaClicked}
                guestSelectChanged={props.guestSelectChanged}
                showPopup={isActive}
                hidePopupClicked={backHandler}
                back={backHandler}/>

            <div className={classRoom}>
                <div className={classes.Wrapper}>
                    <div className={classes.Header} onClick={() => setIsActive(true)}>
                    <div className={classes.Title}>Room {props.roomIndex + 1}</div>
                    <div className={iconStyle.join(" ")}>{sideIcon}</div>
                    </div>
                    {divider}
                    {adultInfo}
                    {childInfo}
                </div>
                {note}
            </div>
		</PanelBox>
	);
};

export default ParticipantList;
