import React, {
    useState, useEffect
 } from 'react';
import classes from './TourList.module.scss';
import TourItem from './TourItem/TourItem';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import PackageListLoader from '../../../../UI/Loaders/Desktop/List/PackagesListLoader';
import { PER_PAGE_TOUR } from '../../../../../constant/General';
import EmptyData from '../../../../UI/EmptyData/EmptyData';

const tourList = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [maxShowItem, setMaxShowItem] = useState(PER_PAGE_TOUR);

    useEffect(() => {
        generatePaginate(props.tourPackageList.length);
    }, [props.tourPackageList]);

    const generatePaginate = (totalItem) => {
        if (totalItem > 0) {
            const totalPage = Math.floor((totalItem + PER_PAGE_TOUR - 1) / PER_PAGE_TOUR);
            setTotalPage(totalPage);
            setCurrentPage(1);
        }
    }

    const showMore = () => {
        let nextPage = currentPage + 1;
        if (currentPage < totalPage) {
            setCurrentPage(nextPage);
            setMaxShowItem(nextPage * PER_PAGE_TOUR);
        }
    }

    let content = null;
    if (props.isErrorFetch) {
        content = (
            <EmptyData
                type="tour-empty"
                title="Tour empty"
                desc="Sorry tour is unavailable Please see other destinations"/>
        )
    } else {
        if (props.isFetch) {
            content = [];
            for (let index = 0; index < 5; index++) {
                let loader = (
                    <div className={classes.Item} key={index}>
                        <PackageListLoader />
                    </div>
                );
                content.push(loader);
            }
        } else {
            if (props.tourPackageList && props.tourPackageList.length > 0) {
                content = props.tourPackageList.filter((item, key) => key < maxShowItem).map((packageItem, index) => {
                    return (
                        <div key={index} className={classes.Item}>
                            <TourItem packageItem={packageItem}/>
                        </div>
                    )
                });
            } else {
                content = (
                    <EmptyData
                        type="tour-empty"
                        title="Tour empty"
                        desc="Sorry tour is unavailable Please see other destinations"/>
                )
            }
        }
    }

    return (
        <div className={classes.TourList}>
            {content}
            {totalPage > 1 && currentPage < totalPage ? <PanelBox>
                <div onClick={showMore} className={classes.ShowMore}>
                    SHOW MORE RESULTS
                </div>
            </PanelBox> : ''}
            
        </div>
    )
}

export default tourList;
