import React from 'react';
import Button from '../../../../../UI/Button/Button';
import Modal from '../../../../../UI/Modal/Modal';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import SelectFlag from '../../../../../UI/Inputs/SelectFlag/SelectFlag';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../../redux/Shared/Member/MemberAction';

function phoneModal(props) {
    const updateFormPhone = () => {
        let newNumber = {
            countryCode: props.formPhone.dialCode.value,
            phoneNumber: props.formPhone.phone.value,
            ipAddress: '127.0.0.1'
        }
        props.submitChangedPhone(newNumber)
    }
    return (
        <Modal 
            title="Edit Phone Number"
            show={props.showUpdatePhoneForm}
            closeClicked={() => props.showHideUpdatePhoneClicked(false)}>
            <div className="u-mb-40">
                <div className="label">Phone Number</div>
                <div className="u-display-flex">
                    <div className="u-flex-3 u-mr-8">
                        <SelectFlag
                            value={props.formPhone.dialCode.value}
                            changed={(event) => props.setDialCodeHandler(event)}/>
                    </div>
                    <div className="u-flex-7">
                        <TextField
                            placeholder="" 
                            value={props.formPhone.phone.value}
                            changed={(event) => props.inputChangePhone(event, 'phone')}/>
                    </div>
                </div>
            </div>
            <div className="u-display-flex">
                <div className="u-flex-5 u-mr-8">
                    <Button 
                        classes={['BtnOutline', 'BtnMedium', 'BtnBlock']}
                        clicked={() => props.showHideUpdatePhoneClicked(false)}>Cancel</Button>
                </div>
                <div className="u-flex-5 u-ml-8">
                    <Button 
                        classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                        isLoading={props.isSubmitLoading}
                        clicked={() => updateFormPhone()}>Save</Button>
                </div>
            </div>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        formPhone: state.MemberReducer.formPhone,
        isSubmitLoading: state.MemberReducer.isSubmitLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChangePhone: (event, inputIdentifier) => dispatch(memberAction.inputChangePhone(event, inputIdentifier)),
        setDialCodeHandler: (val) => dispatch(memberAction.setDialCodeHandler(val)),
        submitChangedPhone: (newNumber) => dispatch(memberAction.submitChangedMember(newNumber, 'phoneForm')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(phoneModal);