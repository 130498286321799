export const HOTEL_SORT_LIST = [{
    value: 'lowest-rating',
    name : 'Rating Terendah'
}, {
    value: 'highest-rating',
    name : 'Rating Tertinggi'
}, {
    value: 'lowest-price',
    name : 'Harga Terendah'
}, {
    value: 'highest-price',
    name : 'Rating Terendah'
}, {
    value: 'popular',
    name : 'Populer'
}];

export const TOUR_SORT_LIST = [
{
    value: 'lowest-price',
    name : 'Lowest Price'
}, {
    value: 'highest-price',
    name : 'Highest Price'
}, {
    value: 'sort-duration',
    name : 'Short Duration'
}, {
    value: 'longest-duration',
    name : 'Longest Duration'
}]


export const ADMISSION_SORT_LIST = [
{
    value: 'lowest-price',
    name : 'Lowest Price'
}, {
    value: 'highest-price',
    name : 'Highest Price'
}]
    