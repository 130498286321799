import React from 'react';
import classes from './RightContent.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import TourInfo from './TourInfo/TourInfo';
import TourFacility from './TourFacility/TourFacility';

const rightContent = (props) => {
    let tourInfo = null;
    if (props.bookingData) {
        tourInfo = <TourInfo bookingData={props.bookingData} />;
    }

    let tourFacility = null;
    if (props.bookingData) {
        tourFacility = <TourFacility bookingData={props.bookingData}/>;
    }

    return (
        <PanelBox isOverflow={false}>
            <div className={classes.Wrapper}>
                <ul className={classes.SectionList}>
                    <li className={classes.Item}>
                        {tourInfo}
                    </li>
                    <li className={classes.Item}>
                        {tourFacility}
                    </li>
                </ul>
            </div>
        </PanelBox>
    );
}

export default rightContent;