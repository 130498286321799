import React from 'react';
import classes from './PanelBox.module.scss';

const panelBox = (props) => {
    let panelClasses = [classes.PanelBox, classes.OverflowHidden];
    if (props.isOverflow === false) {
        panelClasses = [classes.PanelBox];
    }
    return (
        <div className={panelClasses.join(' ')}>
            <div>{props.children}</div>
        </div>
    );
}

export default panelBox;