import React from 'react';
import classes from './Item.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import { DEFAULT_IMG } from '../../../../../constant/Content';
import { withRouter } from 'react-router-dom';

const item = (props) => {

    const goToDetail = (slug) => {
        props.history.push('/voucher/booking/' + slug)
    }

    let benefits = null;
    if (props.data.benefit) {
        benefits = props.data.benefit.map((benefitItem, benefitKey) => {
            let benefitClasses = [classes.Benefit];
            let icon = 'ico-close-circle';
            let color = '#E6E6E6';
            if (benefitItem.status) {
                icon = 'ico-check-circle';
                color = '#73C700';
                benefitClasses.push(classes.Active);
            }
            return (
                <div key={benefitKey} className={benefitClasses.join(' ')}>
                    <Icon name={icon} fill={color} stroke="none" width={16} />
                    <div className={classes.Text}>
                        {benefitItem.title}
                    </div>
                </div>
            )
        })
    }

    return (
        <div className={classes.Wrapper} onClick={() => goToDetail(props.data.slug)}>
            <div className={classes.Image}>
                <img onError={DEFAULT_IMG} src={props.data.imageSource} alt="voucher" />
            </div>
            <div className={classes.Body}>
                {benefits}
            </div>
        </div>
    )
}

export default withRouter(item);
