import React, { useState, useRef, useEffect } from 'react';
import classes from './SearchContainer.module.scss';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import history from '../../../../history';
import Icon from '../../../../components/UI/Icon/Icon';
import SearchResult from '../../../../components/Mobile/ThingsToDo/SearchResult/SearchResult';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../redux/Admission/Availability/AvailabilityAction';

const searchContainer = (props) => {
    const [searchInput, setSearchInput] = useState('');
    const inputEl = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const backClickHandler = () => {
        history.goBack();
    }

    const searchChangeHandler = (event) => {
        if (event.target.value !== '') {
            props.onSearchAdmissionExperiencePackages(event.target.value)
        }
        setSearchInput(event.target.value);

    }

    const searchForm = <div className={classes.SearchForm}>
        <div className={classes.FirstIcon}>
            <Icon name="ico-search" width="16" stroke="none" fill="#202124" />
        </div>
        <input 
            ref={inputEl}
            value={props.value}
            onChange={(event) => searchChangeHandler(event)} 
            className={classes.Input} 
            placeholder="Search for destinations"/>
    </div>;

    let content = null;
    if (searchInput) {
        content = (
            <SearchResult 
                isFetchSearch={props.isFetchSearch}
                isErrorFetchSearch={props.isErrorFetchSearch}
                searchAdmissionPackages={props.searchAdmissionPackages}
                searchExperiencePackages={props.searchExperiencePackages}/>
        )
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Header}>
                <Header classes={['ClBlack', 'Shadow']} 
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    content={searchForm}/>
            </div>
            
            <div className={classes.Content}>
                {content}
            </div>
        </div> 
    )
}

const mapStateToProps = (state) => {
    return {
        isFetchSearch: state.AdmissionAvailabilityReducer.isFetchSearch,
        isErrorFetchSearch: state.AdmissionAvailabilityReducer.isErrorFetchSearch,
        searchAdmissionPackages: state.AdmissionAvailabilityReducer.searchAdmissionPackages,
        searchExperiencePackages: state.AdmissionAvailabilityReducer.searchExperiencePackages,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSearchAdmissionExperiencePackages: (value) => dispatch(AvailabilityAction.searchAdmissionExperiencePackages(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(searchContainer);
