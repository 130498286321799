import React from 'react';
import classes from './ScheduleList.module.scss';
import Slider from 'react-slick';
import Moment from 'react-moment';

const scheduleList = (props) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        variableWidth: true,
        centerPadding: '0px',
        fade:false,
        dotsClass: "slick-dots " + classes.DotWrapper,
        autoplay: false,
        autoplaySpeed: 3500,
        swipeToSlide: true,
    };

    let scheduleList = props.scheduleList.map( (schedule, index) => {
        let startDate = new Date(schedule.startDate);
        let startMonth = startDate.getMonth();
        let startYear = startDate.getFullYear();
        let endDate = new Date(schedule.endDate);
        let endMonth = endDate.getMonth();
        let endYear = endDate.getFullYear();
        let dateClasses = [classes.Item];
        if (props.scheduleSelected === schedule.tourScheduleID) {
            dateClasses = [classes.Item, classes.Active];
        }
        let dateItem = (
            <div>
                <div className={classes.Top}><Moment format="DD">{schedule.startDate}</Moment> - <Moment format="DD">{schedule.endDate}</Moment></div>
                <div className={classes.Bottom}><Moment format="MMM YYYY">{schedule.startDate}</Moment></div>
            </div>
        );

        if (startMonth !== endMonth) {
            dateItem = (
                <div>
                    <div className={classes.Top}><Moment format="DD MMM">{schedule.startDate}</Moment> - <Moment format="DD MMM">{schedule.endDate}</Moment></div>
                    <div className={classes.Bottom}><Moment format="YYYY">{schedule.startDate}</Moment></div>
                </div>
            );
            if (startYear !== endYear) {
                dateItem = (
                    <div>
                        <div className={classes.Top}><Moment format="DD MMM YYYY">{schedule.startDate}</Moment> - <Moment format="DD MMM YYYY">{schedule.endDate}</Moment></div>
                    </div>
                );
            }
        }
        return (
            <div 
                key={index} 
                className={classes.ItemWrapper}
                onClick={() => props.scheduleClicked(schedule.tourScheduleID)}>
                <div className={dateClasses.join(' ')}>
                    {dateItem}
                </div>
            </div>
        );
    });
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Title}>Date</div>
            <div className={classes.LabelList}>
                <Slider {...settings}>
                    {scheduleList}
                    {/* <div className={classes.ItemWrapper}>
                        <div className={[classes.Item, classes.Active].join(' ')}>
                            <div>
                                <div className={classes.Top}>12 - 31</div>
                                <div className={classes.Bottom}>May 2019</div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.ItemWrapper}>
                        <div className={[classes.Item].join(' ')}>
                            <div>
                                <div className={classes.Top}>12 <span className={classes.TextSmall}>May</span> - 31 <span className={classes.TextSmall}>Jun</span></div>
                                <div className={classes.Bottom}>2019</div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.ItemWrapper}>
                        <div className={[classes.Item].join(' ')}>
                            <div>
                                <div className={classes.Top}>28 <span className={classes.TextSmall}>Des 2019</span> - 5 <span className={classes.TextSmall}>Jan 2020</span></div>
                                <div className={classes.Bottom}></div>
                            </div>
                        </div>
                    </div> */}
                    
                </Slider>
            </div>
        </div>
    );
}

export default scheduleList;