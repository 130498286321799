import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import BranchOfficeSearch from '../../../../components/Desktop/NonProducts/BranchOfficeSearch/BranchOfficeSearch';
import Banner from '../../../../components/Desktop/NonProducts/Banner/Banner';
import BranchOfficeList from '../../../../components/Desktop/NonProducts/BranchOfficeList/BranchOfficeList';

import * as branchOfficeAction from '../../../../redux/NonProducts/BranchOffice/BranchOfficeAction';
import MetaTag from '../../../../helper/MetaTag';

class BranchOfficeContainer extends Component {
    state = {
        regionSelected: 'Jakarta'
    }

    componentDidMount() {
        this.props.onInitBranchOfficeList();
    }

    setRegionSelectedHandler = (val) => {
        this.setState({regionSelected: val});
    }

    render() {
        let regionIndex = 0;
        let regionList = [];
        let stores = [];

        if (this.props.branchOfficeList) {
            regionIndex = this.props.branchOfficeList.findIndex(region => {
                return region.name === this.state.regionSelected;
            });
            regionIndex = (regionIndex < 0) ? 0 : regionIndex;
            stores = this.props.branchOfficeList[regionIndex].stores;
            regionList = this.props.branchOfficeList.map(region => {
                return region.name;
            });
        }

        return (
            <div>
                <MetaTag 
                    title="Branch Office"/>
                <Header/>
                <Banner title="Branch Office" image="https://media01.cdnpergi.com/antavaya/images/branch-office-1.jpg" />
                <BranchOfficeSearch 
                    regionSelected={this.state.regionSelected}
                    regionList={regionList}
                    regionChanged={(val) => this.setRegionSelectedHandler(val)}/>
                <BranchOfficeList stores={stores}/>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        branchOfficeList : state.BranchOfficeReducer.branchOfficeList,
        isErrorFetchBranchOfficeList : state.BranchOfficeReducer.isErrorFetchBranchOfficeList
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onInitBranchOfficeList: () => dispatch(branchOfficeAction.initBranchOfficeList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchOfficeContainer);