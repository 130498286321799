export const NAV_MENU = [
	{
		name: 'Corporate',
		target: '',
		url: '/corporate'
	},
	// {
	//     name   : 'Destination',
	//     target : 'blank',
	//     url    : 'http://antavaya-destination.com/'
	// }
	{
		name: 'Umroh',
		target: 'blank',
		url: 'http://antaumroh.com/'
	},
	{
		name: 'Transportation',
		target: 'blank',
		url: 'http://antavaya-transportation.com/'
	}
]

export const COMPANY_LINK = [
	{
		name: 'About Us',
		target: '',
		url: '/about-us'
	},
	{
		name: 'Branch Office',
		target: '',
		url: '/branch-office'
	},
	{
		name: 'Career',
		target: '',
		url: '/career'
	},
	{
		name: 'Blog',
		target: 'blank',
		url: 'https://blog.antavaya.com'
	}
]

export const SOCMED_LINK = [
	{
		name: 'Twitter',
		target: 'blank',
		url: 'https://www.twitter.com/antavaya_id',
		icon: 'ico-twitter'
	},
	{
		name: 'Facebook',
		target: 'blank',
		url: 'https://www.facebook.com/antavaya',
		icon: 'ico-facebook'
	},
	{
		name: 'Instagram',
		target: 'blank',
		url: 'https://www.instagram.com/antavaya_id',
		icon: 'ico-instagram'
	},
	{
		name: 'Linked In',
		target: 'blank',
		url: 'https://www.linkedin.com/company/antatour',
		icon: 'ico-linkedin'
	}
]

export const USER_NAV = [
	{
		name: 'Profile',
		slug: 'profile',
		icon: 'ico-account',
		isActive: true
	},
	{
		name: 'My Booking',
		slug: 'my-booking',
		icon: 'ico-booking',
		isActive: true
	},
	{
		name: 'My Card',
		slug: 'my-card',
		icon: 'ico-card',
		isActive: false
	},
	{
		name: 'Promo Info',
		slug: 'promo-info',
		icon: 'ico-message-fill',
		isActive: false
	},
	{
		name: 'Setting',
		slug: 'setting',
		icon: 'ico-setting',
		isActive: true
	},
	{
		name: 'Logout',
		slug: 'logout',
		icon: 'ico-sign-out',
		isActive: true
	}
]
