import React, { useState, useEffect } from 'react';
import classes from './Filter.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import ElementFilter from './ElementFilter/ElementFilter';
import { TOUR_PRICE_FILTER_LIST, TOUR_DURATION_FILTER_LIST } from '../../../../../constant/FilterList';
import Button from '../../../../UI/Button/Button';
import CSSTransition from 'react-transition-group/CSSTransition';

const filter = props => {
    const [priceFilterList, setPriceFilterList] = useState([]);
    const [durationFilterList, setDurationFilterList] = useState([]);
    const [categoryFilterList, setCategoryFilterList] = useState([]);

    useEffect(() => {
        if(props.show) {
            document.body.style.overflow = 'hidden';
            setPriceFilterList(props.priceFilterList);
            setDurationFilterList(props.durationFilterList);
            setCategoryFilterList(props.categoryFilterList);
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [props.show]);

    const resetFilterList = () => {
        setPriceFilterListHandler();
        setDurationFilterListHandler();
        setCategoryFilterListHandler();
    }

    const setFilterList = () => {
        props.hideFilterPopupClicked();
        props.setFilterClicked(priceFilterList, durationFilterList, categoryFilterList);
    }

    const filterChangeHandler = (event, index, filterType) => {
        let value = event.target.checked;
        let filterList = null;
        if (filterType === 'price') {
            filterList = [...priceFilterList];
            filterList[index].isChecked = value;
            setPriceFilterList(filterList);
        }
        if (filterType === 'duration') {
            filterList = [...durationFilterList];
            filterList[index].isChecked = value;
            setDurationFilterList(filterList);
        }
        if (filterType === 'category') {
            filterList = [...categoryFilterList];
            filterList[index].isChecked = value;
            setCategoryFilterList(filterList);
        }
    }

    const setPriceFilterListHandler = () => {
        const priceFilterList = TOUR_PRICE_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });

        setPriceFilterList(priceFilterList)
    }

    const setDurationFilterListHandler = () => {
        const durationFilterList = TOUR_DURATION_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });

        setDurationFilterList(durationFilterList);
    }

    const setCategoryFilterListHandler = () => {
        const categoryFilterList = props.categoryFilterList.map( item => {
            return {...item, isChecked: false};
        });

        setCategoryFilterList(categoryFilterList);
    }

    return (
        <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={300}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close
            }}>
            <div className={classes.Wrapper}>
                <div className={classes.Header}>
                    <div className={classes.BackBtn} onClick={props.hideFilterPopupClicked}>
                        <Icon name="ico-close" fill="#202124" stroke="none" />
                    </div>
                    <div className={classes.Title}>Filter</div>
                </div>
                <div className={classes.Content}>
                    <ElementFilter 
                        title="Duration" 
                        list={durationFilterList}
                        filterChanged={(event, index) => filterChangeHandler(event, index, 'duration')}
                        resetClicked={setDurationFilterListHandler} />
                    <ElementFilter 
                        title="Price" 
                        list={priceFilterList}
                        filterChanged={(event, index) => filterChangeHandler(event, index, 'price')}
                        resetClicked={setPriceFilterListHandler} />
                    <ElementFilter 
                        title="Category" 
                        list={categoryFilterList}
                        filterChanged={(event, index) => filterChangeHandler(event, index, 'category')}
                        resetClicked={setCategoryFilterListHandler} />
                    
                </div>
                <div className={classes.ActionWrapper}>
                    <div className={classes.Button}>
                        <Button 
                            classes={['BtnMedium', 'BtnBlock']}
                            clicked={resetFilterList}>
                            Clear All
                        </Button>
                    </div>
                    <div className={classes.Button}>
                        <Button 
                            classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                            clicked={setFilterList} >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}

export default filter;