import React from 'react';
import classes from './Holiday.module.scss';

const holiday = (props) => {
    let holidayList = [];
    if (props.holidayNameList) {
        holidayList = props.holidayNameList.map( (holiday, index) => {
            return (
                <li key={index} className={classes.Item}>
                    <div className={classes.Name}>{holiday.name}</div>
                    <div className={classes.Date}>{holiday.date}</div>
                </li>
            );
        }); 
    }

    return (
        <ul className={classes.Wrapper}>
            {holidayList}
        </ul>
    );
}

export default holiday;