import React from 'react';
import classes from './VerifyEmail.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import artSubmitApplication from '../../../../assets/images/art-submit-application.png';

const verifyEmail = props => {
    return (
        <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={500}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close 
            }}
        >
            <div className={classes.Wrapper}>
                <div className={classes.Content}>
                    <div>
                        <div className={classes.Image}>
                            <img src={artSubmitApplication} alt=""/>
                        </div>
                        <div className={classes.Desc}>
                            <p>
                                Please click on the link that has just been sent to your email account to verify your email and continue the registration process.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default verifyEmail;