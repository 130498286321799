import React, { useState, useEffect } from 'react';
import classes from './ItineraryItem.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../UI/Icon/Icon';
import CSSTransition from 'react-transition-group/CSSTransition';
import { toCapitalize } from '../../../../../helper/GlobalFunc';

const itineraryItem = props => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if(props.isActive !== isActive && !props.isActive) {
            detailToggleHandler();
        }
    }, [props.isActive])

    const detailToggleHandler = () => {
        setIsActive(!isActive);
    }

    let arrowClasses = [classes.Arrow];
    if (isActive) {
        arrowClasses = [classes.Arrow, classes.Active];
    }
    let flight = null;
    // console.log('itinerary == ', props)

    if (props.itenerary.flight) {
        let flightTime = null;
        let airportCode = null;

        if (props.itenerary.flight.length > 0) {
            flightTime = <div className={classes.FlightTitle}>{props.itenerary.flight[0].departureDateTime} - {props.itenerary.flight[0].arrivalDateTime}</div>;
        }
        if (props.itenerary.flight.length > 0) {
            airportCode = <div className={classes.FlightSubTitle}>{props.itenerary.flight[0].departureAirportCode} - {props.itenerary.flight[0].arrivalAirportCode}</div>;
            
            flight = (
                <div className={classes.Flight}>
                    <div className={classes.FlightIcon}>
                        <Icon name="ico-flight-o" fill="#6A6E73" stroke="none" />
                    </div>
                    <div className={classes.FlightDesc}>
                        <div>
                            {flightTime}
                            <div className={classes.FlightSubTitle}>{props.itenerary.flight[0].airlineName}</div>
                        </div>
                    </div>
                    <div className={classes.FlightDesc}>
                        {airportCode}
                    </div>
                </div>
            );
        }
    }
    let facilityList = null;
    if (props.itenerary.facilities && props.itenerary.facilities.length > 0) {
        facilityList = props.itenerary.facilities.map((facility, index) => {
            return (<li key={index}><Icon name="ico-check-circle-white" fill="#73C700" stroke="none"  /><span>{facility.name}</span></li>);
        });
    }

    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                <div className={classes.Header} onClick={detailToggleHandler}>
                    <div className={classes.Day}>Day {props.itenerary.days}</div>
                    <div className={classes.Title}>
                        <span>{toCapitalize(props.itenerary.title)}</span>
                        <div className={arrowClasses.join(' ')}>
                            <Icon name="ico-caret-down" fill="#202124;" stroke="none"  />
                        </div>
                    </div>
                </div>
                <CSSTransition
                    in={isActive}
                    mountOnEnter
                    unmountOnExit
                    timeout={270}
                    classNames={{
                        enterActive: classes.Open,
                        exitActive: classes.Close
                    }}>

                    <div className={classes.Body}>
                        {flight}
                        <div className={classes.Description}>{props.itenerary.description}</div>
                        <ul className={classes.Facilities}>
                            {facilityList}
                        </ul>
                    </div>
                </CSSTransition>
            </div>
        </PanelBox>
    );
}

export default itineraryItem;