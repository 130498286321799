import React from 'react';
import ContentLoader from 'react-content-loader';

const labelLoader = () => {
    return (
         <ContentLoader
            speed={1}
            primaryColor={'#e6e6e6'}
        >
            <rect x="0" y="0" rx="10" ry="10" width="50" height="20" />
            <rect x="60" y="0" rx="10" ry="10" width="100" height="20" />
            <rect x="170" y="0" rx="10" ry="10" width="60" height="20" />
            <rect x="240" y="0" rx="10" ry="10" width="60" height="20" />
            <rect x="310" y="0" rx="10" ry="10" width="70" height="20" />
        </ContentLoader>
    )
}

export default labelLoader;