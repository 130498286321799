import icoVision from '../assets/images/ico-vision.png'
import icoMission from '../assets/images/ico-mission.png'
import icoCorevalue from '../assets/images/ico-corevalue.png'
import icoCommitment from '../assets/images//ico-commitment.png'

export const TRAVEL_LEADERS_GROUP = [
	`AntaVaya Corporate Travel (PT. Anta Express Tour &#38; Travel Service) is the <strong>leading travel management company in Indonesia,</strong> helping corporations of all sizes, multinationals and government institutions <strong>manage travel</strong> in a professional and cost-effective way.`,

	`AntaVaya Corporate Travel is the corporate travel arm of <strong>AntaVaya Group,</strong> Indonesia's largest travel company whose other interests include leisure travel and tours, meetings & events, incentive travel, inbound travel and hotel distribution. Since 2007 AntaVaya has been <strong>a business of CT Corp,</strong> one of the fastest-growing business groups in Indonesia under the leadership of chairman Chairul Tanjung. This provides Antavaya with unique synergies with other CT Corp businesses in the finance, hospitality, media, entertainment and retail space.`,

	`AntaVaya Corporate Travel is the largest Travel Management Company in Indonesia. Established in 1965, it has gained a reputation of service quality and professional management second to none. With <strong>more than 400 staff</strong> focused exclusively on corporate travel, a three-floor contact centre with advanced telephony and systems, <strong>over 10 implant locations,</strong> and a national network spanning Java, Kalimantan, Sulawesi, Bali, all the way to Papua, AntaVaya Corporate Travel is in a unique position to deliver the specific travel service that your company needs.`,

	`In 1994 PT. Anta Express Tour & Travel Service (AntaVaya Corporate travel legal entity) joined forces with Vayatour, another established travel brand, to form <strong>AntaVaya,</strong> the leading travel agency in the country. In 2012, AntaVaya started to regroup all corporate travel activities under the AntaVaya Corporate Travel banner, so today it has an <strong>exclusive focus on corporate services.</strong> That single focus is another strength to maintain our corporate leadership.`,

	`We believe in robust business supported by modern systems and procedures, as demonstrated by our <strong>ISO 9001:2015, OHSAS 18001:2018 certification</strong>,</strong> whose global customers are cared for in Indonesia by AntaVaya Corporate Travel.`,

	`Our core values of <strong>Customer First, Innovation</strong> and <strong>Performance ensure</strong> that your company gets the most value out of your relationship with AntaVaya. `
]

export const CORPORATE_ONLINE_SOLUTIONS = [
	{
		spacerTitle: 'What included?',
		subItem: [
			{
				item1: 'Website',
				item2: 'ico-check-circle',
				item3: 'ico-check-circle',
				item4: ''
			},
			{
				item1: 'Mobile App',
				item2: '',
				item3: 'ico-check-circle',
				item4: ''
			},
			{
				item1: 'Company Size',
				item2: 'Small / Medium',
				item3: 'Medium / Large',
				item4: 'Large'
			},
			{
				item1: 'No. of Travel Transaction',
				item2: '> 150 a Year',
				item3: '> 1.000 a Year',
				item4: '> 5.000 a Year'
			},
			{
				item1: 'Man Power',
				item2: 'Travel Administrator',
				item3: 'Travel Coordinator',
				item4: 'IT & Travel Coordinator'
			},
			{
				item1: 'User Login',
				item2: 'Unlimited',
				item3: 'Unlimited',
				item4: 'Limits by Client'
			},
			{
				item1: 'Service',
				item2: 'Flight, Train, Hotel',
				item3: 'Flight, Train, Hotel',
				item4: 'Flight, Train, Hotel'
			},
			{
				item1: 'Approval',
				item2: '1 Layer',
				item3: 'Multi Layers',
				item4: 'Customized by Client'
			},
			{
				item1: `Manage Traveler Profile,
				Policy & Budget`,
				item2: '',
				item3: 'ico-check-circle',
				item4: 'Customized by Client'
			},
			{
				item1: `Customer Service 24 Hours`,
				item2: 'ico-check-circle',
				item3: 'ico-check-circle',
				item4: 'ico-check-circle'
			},
			{
				item1: `Technical Support`,
				item2: 'ico-check-circle',
				item3: 'ico-check-circle',
				item4: 'ico-check-circle'
			},
			{
				item1: `e-Invoicing & e-Faktur Pajak`,
				item2: 'ico-check-circle-circle',
				item3: 'ico-check-circle',
				item4: 'ico-check-circle'
			}
		]
	}
]

export const CORPORATE_ONLINE_SOLUTION_MOBILE = [
	{
		title: 'Corporate Booking Tools (ABT/SBT)',
		star: 2,
		mail: `mailto:sales.corporate@antavaya.com&subject=Ask about Corporate Booking Tools (ABT/SBT)`,
		desc: [
			{
				title: `What's included?`,
				item: [
					'Website',
					'For small / medium company',
					'>150 a year no. of travel transaction',
					'Travel administrator man power',
					'Unlimited user login',
					'Service: Flight, train, hotel',
					'1 layer approval',
					'Customer service 24 hours',
					'Technical support',
					'e-Invoicing & e-Faktur Pajak'
				]
			}
		]
	},
	{
		title: 'Travel Management System (TMS)',
		star: 3,
		mail: `mailto:sales.corporate@antavaya.com&subject=Ask about Travel Management System (TMS)`,
		desc: [
			{
				title: `What's included?`,
				item: [
					'Website & mobile app',
					'For medium / large company',
					'>1.000 a year no. of travel transaction',
					'Travel coordinator man power',
					'Unlimited user login',
					'Service: Flight, train, hotel',
					'Multi layers approval',
					'Manage traveler profile, policy & budget',
					'Customer service 24 hours',
					'Technical support',
					'e-Invoicing & e-Faktur Pajak'
				]
			}
		]
	},
	{
		title: 'System Integration (API)',
		star: 1,
		mail: `mailto:sales.corporate@antavaya.com&subject=Ask about System Integration (API)`,
		desc: [
			{
				title: `What's included?`,
				item: [
					'For large company',
					'>5.000 a year no. of travel transaction',
					'IT & travel coordinator man power',
					'For user login, it will be limits by client',
					'Service: Flight, train, hotel',
					'For approval, it will be customized by client',
					'For manage traveler profile, policy & budget it will be customized by client',
					'Customer service 24 hours',
					'Technical support',
					'e-Invoicing & e-Faktur Pajak'
				]
			}
		]
	}
]

export const NEW_CORPORATE_TRAVEL_SERVICES = [
	{
		title: 'Corporate Travel Management',
		description:
			'Complete Travel Fulfillment for your business trip. Includes but not limited to Air Tickets, Accommodations, Airport Meet&Greet, Vehicle Rent, Train Ticket, Emergency Travel Assistance, Travel Documents, until Leisure Products.',
		item: [
			{
				title: 'Focused on Mobility',
				detail:
					'AntaVaya provides you with customize service: 24/7 connection with a live travel agent, easy access to book & issued through AntaVaya Corporate Online Solutions.',
				icon: 'ico-cs'
			},
			{
				title: 'Payment Solutions',
				detail:
					'We have advised many companies of all sizes on deploying innovative solutions to optimize their process, such as implementing a corporate credit card or formalizing an effective travel policy.',
				icon: 'ico-card'
			},
			{
				title: 'Service Coverage',
				detail: `A ‘one-stop-shop’ for all your travel needs, includes various car/bus vehicle rental, Airport Meet & Greet, Emergency Travel Assistance and After Hours, Train Ticket, Travel Document, Billbacks Service, Leisure Products and more.`,
				icon: 'ico-service-coverage'
			}
		]
	},
	{
		title: 'Strategic Meetings Management',
		description: 'Provides scalable solutions that streamline your events process.',
		item: [
			{
				title: 'Meeting, Group, Event for both Online & Offline',
				detail: `AntaVaya is experienced in handling conventional-offline event, online with various digital platforms, or event Hybrid Event, with various scales of the number of participants.
					The Event services includes, but not limited, meeting, conference, seminar, webinar, product launching, training, assesment, interview, customer gathering, community event, virtual exhibition, virtual wedding. Contact us for more details.`,
				icon: 'ico-new-participants'
			},
			{
				title: 'Restaurant Chain & Logistic Meeting Tools',
				detail:
					'Meetings or events can also be held in restaurants or meeting rooms, or webinars from each house. AntaVaya is experienced in handling food delivery or its MeetingKit in accordance with the provisions of the Event Committee.',
				icon: 'ico-new-restaurant'
			}
		]
	},
	{
		title: 'Account Management',
		description:
			'The key elements of quality service, cost savings, control and management of expenditure are drawn together by the account management function.',
		item: [
			{
				title: 'MIS Reporting',
				detail:
					'AntaVaya reporting is fast developing into the most sophisticated, immediate reporting tool in the Corporate Travel industry. Please contact us for further discussion.',
				icon: 'ico-reporting'
			}
		]
	},
	{
		title: 'AntaVaya Corporate Online Solutions',
		description: 'Key Benefits :',
		item: [
			{
				title: 'Multiple payment method (Invoice, Billing, Credit Card, bank transfer).',
				detail: '',
				icon: 'ico-check-circle'
			},
			{
				title: 'Book real time.',
				detail: '',
				icon: 'ico-check-circle'
			},
			{
				title: 'Competitive price (include corporate deals)',
				detail: '',
				icon: 'ico-check-circle'
			},
			{
				title: 'Manage travel policy.',
				detail: '',
				icon: 'ico-check-circle'
			},
			{
				title: 'Multilayer approval.',
				detail: '',
				icon: 'ico-check-circle'
			},
			{
				title: 'Monitor travel expense.',
				detail: '',
				icon: 'ico-check-circle'
			},
			{
				title: 'Multiple payment method (Invoice, Billing, Credit Card, bank transfer).',
				detail: '',
				icon: 'ico-check-circle'
			},
			{
				title: 'Real time report.',
				detail: '',
				icon: 'ico-check-circle'
			},
			{
				title: 'Manage travel tracker.',
				detail: '',
				icon: 'ico-check-circle'
			}
		]
	}
]

export const CORPORATE_BOOKING_TOOL = [
	{
		title: 'Corporate Travel Management Services',
		description:
			'Now days industry requirement is how to manage the travel bookings efficiently and effectively in a streamlined organizations. Our online booking tool would be precisely answers. This tool has been designed to comprehensively cater business travel management:',
		list: [
			'Manage database of employee & executives',
			'Travel profile storage',
			'Hierarchy of approval',
			'Manage travel policy and compliance',
			'Booking tools with multiple suppliers',
			'Report and management information system'
		]
	},
	{
		title: 'The Most Chosen Travel Management Company In Indonesia a True Business Partner',
		description: '',
		list: [ 'Flexible service set-up', 'Real account management', 'Data you can rely upon' ]
	},
	{
		title: 'Always Reliable Pricing',
		description: '',
		list: [ 'Competitive', 'Trustworthy', 'Industry #1 fares and rates' ]
	},
	{
		title: 'Smarter Technology',
		description: '',
		list: [ 'Non GDS aggregation for air & hotel', 'Contact center technology' ]
	},
	{
		title: 'AntaVaya Corporate Service Airline and Hotel',
		description: '',
		list: [ 'Recognized expert people', 'Client first culture', '24/7 service center' ]
	},
	{
		title: 'Backed By CT Corp',
		description: '',
		list: [ 'Financially sound', 'Cross benefits (corporate card, meeting facilities)' ]
	},
	{
		title: 'Art Of AntaVaya Group',
		description: '',
		list: [ 'Leisure Offering', 'Incentive trips', 'Meeting & event' ]
	}
]

export const ABOUT_US = [
	'PT. Anta Express Tour & Travel Service was established on March 25, 1976, well- known as Antatour, PT. Anta Express Tour & Travel Service and its subsidiary Vayatour have grown to be one of the big five players in travel industry in Indonesia.',
	'Since joining forces in 1994, Antatour and Vayatour have grown into the largest travel agency in Indonesia, serving the whole travel industry through a range of business units. AntaVaya enjoys a strong reputation in the industry for its reliability, professionalism and service quality across all the businesses in which we operate.',
	'With more than 30 years of travel-industry experience, AntaVaya expands progressively by providing complete value-added travel solutions to Corporate Travel Management, Leisure Travel (ticketing, outbound and domestic tours), Meeting and Events, Inbound Travel, Incentive Travel, Convention & Exhibition, Umroh services and Transportation',
	'Since 2007, AntaVaya has been part of Para Group (currently known as CT Corp), one of the fastest-growing business groups in Indonesia under the leadership of chairman Bpk. Chairul Tanjung. This provides AntaVaya with unique strategies with other CT Corp businesses in the finance, hospitality, media, entertainment and fashion',
	'In order to give the best services and best focus especially on new travel business development, new company, PT. Vaya Micetama Servindo was established under AntaVaya Group. This company will cater the online travel business which being carefully prepared to fulfil the demand of millennium generation.',
	'Our core values of Customer First, Innovation and Performance ensure that everybody gets the most value out of their relationship with AntaVaya.'
]

export const JOURNEY = [
	{
		year: '1965',
		title: 'Vaya Tour Starts Business',
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the"
	},
	{
		year: '1972',
		title: 'Anta Tour Starts Business',
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the"
	},
	{
		year: '1994',
		title: 'Anta Tour and Vaya Tour Joined',
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the"
	},
	{
		year: '2007',
		title: 'Acquired by Para Group (CT Corp)',
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the"
	},
	{
		year: '2012',
		title: 'Brand Refocus Antavaya',
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the"
	},
	{
		year: '2013',
		title: 'Introducing Antavaya Identity',
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the"
	}
]

export const VISI_MISI_LIST = [
	{
		icon: icoVision,
		title: 'Vision',
		description: 'Our vision is to be the best and the largest Travel Power House in Indonesia'
	},
	{
		icon: icoMission,
		title: 'Mission',
		description:
			'Our mission is to provide quality, value-added services to our customers by delivering impeccable and memorable travel Experience'
	},
	{
		icon: icoCorevalue,
		title: 'Core Values',
		description:
			'Our core values of Customer First, Innovation, and Performance ensure that client gets the most value out of your relationship with AntaVaya'
	},
	{
		icon: icoCommitment,
		title: 'Commitment',
		description:
			'AntaVaya is committed to strengthen its focus on customer satisfaction by continuous service improvement, integrated travel services, effective reservation and customer travel data administration system based on information technology, skilled professionals and convenient branch locations'
	}
]

export const PRODUCT_SERVICES = [
	{
		title: 'Domestic and International Airline Tickets',
		image: 'ico-flight',
		description:
			'Providing variety airline tickets from reputable airlines and our experienced travel consultant will help to assist by giving the best option and alternative routes.'
	},
	{
		title: 'Outbound and Domestic Tours',
		image: 'ico-tour',
		description:
			'Providing best itinerary for the customers who will do outbound (to outside the country) and also domestic (inside the country). Customers can choose whether to travel together in group or individual. Our experience tour leader will accompany those who travel by groups and will give the best assistance to make sure customers will be sound mind during the trip.'
	},
	{
		title: 'Incentive Travel',
		image: 'ico-incentive',
		description:
			'AntaVaya has long experience in running incentive tours and incentive gathering around the world. Providing you with attractive ideas, competitive cost, safe execution and the guarantee of your return on investment.'
	},
	{
		title: 'Inbound Tours',
		image: 'ico-inbound-tour',
		description:
			'AntaVaya also offering Inbound tour services, which is service foreigners whether it is FIT (free individual traveler) or group which come from any parts of the world will experience the best services while they are travelling in Indonesia.'
	},
	{
		title: 'Domestic and International Hotel Reservation',
		image: 'ico-hotel',
		description:
			'By obtaining sophisticated Hotel Reservation System which compared all inventories by XML connection, customers will always find the best rate for our domestic and international hotel reservation. With attractive rate to over 100,000 hotels across the country, AntaVaya Hotel Reservation System will offer so much more choices of hotels to his customers.'
	},
	{
		title: 'Online Travel Agent',
		image: 'ico-laptop',
		description:
			'To cater new segment market which more vibrant, energetic, as we called Y Generation, AntaVaya has set up new booking engine which used for online transaction and this new business development is under PT. Vaya Micetama Servindo (subsidiary of AntaVaya Group)'
	},
	{
		title: 'Meeting, Incentive, Conference and Exhibition (MICE)',
		image: 'ico-meeting',
		description:
			'Antavaya is providing the tailor-made program which cover the activities of Meeting, Incentive group program, Conference and also Exhibition. Our Incentive Account Manager has got much more experience to offer some consultation or best option to make sure the needs of customers for Meeting, Incentive, Conference and Exhibition are met.'
	},
	{
		title: 'Cruise Holiday',
		image: 'ico-cruise-holiday',
		description:
			'To cater the demand of market who needs all the things related to cruise holiday, AntaVaya has built new division called Anta Cruise. This Anta Cruise will collaborate with reputable cruise company around the world and will make sure AntaVaya is able to give the best price, best information for these cruise holiday'
	},
	{
		title: 'Travel Insurance',
		image: 'ico-insurance',
		description:
			'AntaVaya will offer the chance to customers to obtain travel insurance in order to help customers in time of need. This insurance will be variety from inflight insurance to life insurance'
	},
	{
		title: 'Travel Document and Airport Handling',
		image: 'ico-document',
		description:
			'Assisting customers to obtain the travel document by giving the right information, checking the documentations. AntaVaya also providing the assistance for those who needs help upon the check in or arrival at the airport.'
	},
	{
		title: 'Moslem Pilgrimage (Umroh)',
		image: 'ico-umroh',
		description:
			'AntaUmroh as a subsidiary of AntaVaya is offering the most experience and specialized Umrah travel consultant that will help those who will do pilgrimage (Umroh) by giving the best program and also the best religious tour guide or religious counselor to accompany the customer during the pilgrimage.'
	},
	{
		title: 'Transportation',
		image: 'ico-transportation',
		description:
			'Obtaining the modern and up to date fleet (currently with 25 new fleet), AntaVaya is providing the best vehicle and also the experience drivers to assist the customer.'
	}
]

export const DEFAULT_IMG = (ev) => {
	ev.target.src = 'https://s3-ap-southeast-1.amazonaws.com/antavaya.com/images/pattern-image.png'
}

export const ABOUT_FREE_VOUCHER = [
	'Voucher ini berlaku khusus untuk pembelian Paket Tour Series.',
	'Voucher ini tidak berlaku untuk pembayaran Deposit Paket Tour.',
	'Voucher ini berlaku untuk pembelian di seluruh kantor AntaVaya.',
	'Voucher ini tidak dapat diuangkan dan tidak dapat digabungkan dengan promosi lainnya.',
	'Voucher berlaku bila ada cap asli.',
	'1 (satu) voucher hanya berlaku untuk 1 (satu) kali transaksi.'
]
