import { updateObject } from '../../utility';

const initialState = {
    isFetchVirtualTours: false,
    isErrorFetchVirtualTours: false,
    virtualTours: null,
}

const setIsFetchVirtualTours = (state, action) => {
    return updateObject(state, { isFetchVirtualTours: action.payload.value });
}

const setIsErrorFetchVirtualTour = (state, action) => {
    return updateObject(state, { isErrorFetchVirtualTours: action.payload.value });
}

const setVirtualTours = (state, action) => {
    return updateObject(state, { virtualTours: action.payload.value });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VIRTUAL_TOURS': return setVirtualTours(state, action);
        case 'SET_IS_FETCH_VIRTUAL_TOUR': return setIsFetchVirtualTours(state, action);
        case 'SET_IS_ERROR_FETCH_VIRTUAL_TOUR': return setIsErrorFetchVirtualTour(state, action);
        default: return state;
    }
}

export default reducer;