import React from 'react';
import classes from './Availability.module.scss';
import Admission from './Admission/Admission';
import Tour from './Tour/Tour';
import { withRouter } from 'react-router-dom';

const availability = (props) => {
    let content = null;
    if (props.contentData && props.contentData.length > 0) {
        content = props.contentData.map((item, index) => {
            let renderItem = null;
            if (item.type === 'admission') {
                renderItem = <Admission admissionData={item} clicked={() => props.history.push('/admission/detail/' + item.slug)}/>
            } else {
                renderItem = <Tour tourData={item} clicked={() => props.history.push('/tour/detail/' + item.slug)}/>
            }
            return (
                <React.Fragment key={index}>
                    {renderItem}
                </React.Fragment>
            )
        })
    }
    return (
        <div className={classes.Wrapper}>
            {content}
        </div>
    )
}

export default withRouter(availability);
