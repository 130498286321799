import React from 'react';
import classes from './Availability.module.scss';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import { toCapitalize } from '../../../../components/../helper/GlobalFunc';
import CurrencyFormat from 'react-currency-format';
import PackagesListLoaderMobile from '../../../UI/Loaders/Mobile/List/PackagesListLoaderMobile';
import EmptyData from '../../../UI/EmptyData/EmptyData';
import Included from '../../Tour/Included/Included';
import { DEFAULT_IMG } from '../../../../constant/Content';
import Ribbon from '../../../UI/Ribbon/Ribbon';

const availability = props => {
    let packageList = [];
        
    if (props.isLoading) {

        for (let index = 0; index < 5; index++) {
            packageList.push(<PackagesListLoaderMobile key={index}/>)
        }

    } else {

        if (props.tourPackageList && props.tourPackageList.length > 0) {
            packageList = props.tourPackageList.map((packageItem, index) => {
                let image = '';
                if (packageItem.defaultImage) {
                    image = packageItem.defaultImage.url;
                }
    
                return (
                    <div 
                        key={index} 
                        className={classes.TourItem}
                        onClick={() => props.tourPackageClicked(packageItem.slug)} >
                        <PanelBox>
                            <div className={classes.RibbonWrapper}>
                                <Ribbon text="free voucher tour" position="left" size="small" />
                            </div>
                            <div className={classes.Image}>
                                <div className={classes.Title}>
                                    {packageItem.days + 'D '} {toCapitalize(packageItem.inventoryTitle)}
                                </div>
                                <img onError={DEFAULT_IMG} src={image} className={classes.Img} alt="" />
                            </div>
                            <div className={classes.Description}>
                                <div>
                                    <div className={classes.Label}>Included</div>
                                    <div className={classes.Includes}>
                                        {(packageItem.includeFlight) &&
                                            <div className={classes.Item}>
                                                <Included label="Flight" color="black"/>
                                            </div>
                                        }
                                        {(packageItem.includeHotel) &&
                                            <div className={classes.Item}>
                                                <Included label="Hotel" color="black"/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={classes.PriceWrapper}>
                                    <div className={classes.Label}>Start From</div>
                                    <div className={classes.Price}>
                                        <CurrencyFormat value={packageItem.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                                        <span className={classes.Perpax}>/Pax</span>
                                    </div>
                                </div>
                            </div>
                        </PanelBox>
                    </div>
                );
            });

        } else {
            packageList = (
                <EmptyData
                    type="tour-empty"
                    title="Tour empty"
                    desc="Sorry tour is unavailable Please see other destinations"/>
            )
        }
    }

    return (
        <div className={classes.Wrapper}>
            {packageList}
        </div>
    );
    // }
}

export default availability;