import React, { useEffect } from 'react';
import classes from './HotelPackage.module.scss';
import Item from './Item/Item';
import Slider from 'react-slick';
import Icon from '../../../UI/Icon/Icon';
import ContentLoader from 'react-content-loader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as availabilityAction from '../../../../redux/Hotel/Availability/AvailabilityAction';

function hotelPackage(props) {
    useEffect(() => {
        props.fetchHotel();
    }, []);

    const goToDetail = (slug) => {
        props.history.push('/hotel/booking/'+ slug)
    }

	const seeMoreHandler = () => {
		props.history.push('hotel/availability')
	}

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: false,
        autoplaySpeed: 2500,
        dotsClass: "slick-dots " + classes.DotWrapper,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        touchMove:true,
        draggable: false,
        customPaging: function(i) {
            return (
                <div className="banner-product"></div>
            );
        },
    };

    let hotelList = [<Loading key={1}/>, <Loading key={2}/>];
    if (!props.isFetchHotel) {
        hotelList = props.hotels.map( (item, key) => {
            return <div key={key} className={classes.Item} onClick={() => goToDetail(item.slug)}>
            <Item data={item} />
        </div>
        });
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className={classes.Heading}>
                    <div className={classes.Title}>Buy Now, Stay Later</div>
                    <div className={classes.More} onClick={() => seeMoreHandler()}>More</div>
                </div>
                <div className={classes.List}>
                    <Slider {...settings}>
                        {hotelList}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        hotels : state.HotelAvailabilityReducer.hotels,
        isFetchHotel : state.HotelAvailabilityReducer.isFetchHotel,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchHotel : () => dispatch(availabilityAction.fetchHotel())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(hotelPackage));


function Loading() {
    let content = <div className={classes.LoadingWrap}>
        <ContentLoader
            height={545}
            speed={1}
            primaryColor="#e6e6e6">
            <rect x="20" y="20" rx="20" ry="20" width="360" height="330" />

            <rect x="30" y="390" rx="8" ry="8" width="300" height="30" />
            <rect x="30" y="450" rx="8" ry="8" width="170" height="30" />

        </ContentLoader>
    </div>
    return content;
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className={[classes.Arrow, classes.PrevArrow].join(' ')}
            onClick={onClick}>
                <Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
        </div>
    );
}

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className={[classes.Arrow, classes.NextArrow].join(' ')}
            onClick={onClick}>
                <Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
        </div>
    );
}