import React, { Component } from 'react';
import classes from './UserNav.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import { USER_NAV } from '../../../../../constant/LinkList';
import CSSTransition from 'react-transition-group/CSSTransition';
import onClickOutside from "react-onclickoutside";
import * as authAction from '../../../../../redux/Shared/Auth/AuthAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Confirm from '../../../../UI/Dialog/Confirm/Confirm';

class UserNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogoutConfirm: false,
            showList : false,
            userProfile: {
                name: '',
                avatar: ''
            }
        };
    }

    componentDidMount() {
        if (localStorage.getItem('userProfile')) {
            const userProfile = {...this.state.userProfile, ...JSON.parse(localStorage.getItem('userProfile'))};
            this.setState({userProfile});
        }
    }

    showListHandler = () => {
        this.setState({showList: true});
    }

    hideListHandler = () => {
        this.setState({showList: false});
    }

    showLogoutHandler = () => {
        this.setState({isLogoutConfirm: true});
    }

    hideLogoutHandler = () => {
        this.setState({isLogoutConfirm: false});
    }

    menuClickHandler = (slug) => {
        if (slug === 'logout') {
            this.showLogoutHandler();
            // this.props.logout();
        } else {
            this.props.history.push('/user/' + slug);
            window.location.reload()
        }
        this.hideListHandler();
    }

    handleClickOutside = (evt) => {
        this.hideListHandler();
    }

    render() {
        const userNav = USER_NAV.map( (item, index) => {
            let navItem = null;
            if (item.isActive) {
                navItem = <li key={index} 
                        className={classes.Item}
                        onClick={() => this.menuClickHandler(item.slug)}>{item.name}</li>;
            }
            return navItem;
        });
        
        let iconClasses = [classes.Icon];
        if (this.state.showList) {
            iconClasses.push(classes.IsActive);
        }
        return (
            <div className={classes.Wrapper}>
                <Confirm 
                    show={this.state.isLogoutConfirm}
                    title="Are you sure?"
                    desc="If you logout, You can’t use the features and benefits as an Antavaya member"
                    cancelClicked={() => this.hideLogoutHandler()}
                    confirmClicked={()=> this.props.logout()}/>

                <div className={classes.UserNav} onClick={this.showListHandler}>
                    <div className={classes.Avatar}>
                        <Icon name="ico-user" stroke="none" fill="#ffffff" />
                    </div>
                    <div>{`${this.state.userProfile.firstName} ${this.state.userProfile.lastName}`}</div>
                    <div className={iconClasses.join(' ')}>
                        <Icon name="ico-caret-down" stroke="none" fill="#6A6E73" />
                    </div>
                </div>
                <CSSTransition
                    in={this.state.showList}
                    mountOnEnter
                    unmountOnExit
                    timeout={270}
                    classNames={{
                        enterActive: classes.Open,
                        exitActive: classes.Close
                    }}>
                    <ul className={classes.List}>
                        {userNav}
                    </ul>
                    </CSSTransition>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userLoginData : state.AuthReducer.userLoginData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout : () => dispatch(authAction.logout())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(onClickOutside(UserNav)));