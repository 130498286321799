import React from 'react';
import classes from './SuccessForgotPassword.module.scss';
import artSubmitApplication from '../../../../../assets/images/art-submit-application.png';

const SuccessRegister = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Content}>
                <div className={classes.Image}>
                    <img src={artSubmitApplication} alt="Success Register"/>
                </div>
                <div className={classes.Desc}>Please check your email, we have sent you a new password on your registered email address</div>
                <div className={classes.Label}>Back to <span className={classes.Link} onClick={() => props.switchFormClicked('login')}> Login</span></div>
            </div>
        </div>
    )
}

export default SuccessRegister;