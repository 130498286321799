import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

export const setCareerList = ( careerList ) => {
    return {
        type: 'SET_CAREER_LIST',
        careerList: careerList
        
    };
};

export  const fetchCareerListFailed = () => {
    return {
        type: 'FETCH_CAREER_LIST_FAILED'
    }
}

export const initCareerList = () => {
    return dispatch => {
        axios.get( ANTAVAYA_API + 'v1/career' )
            .then( response => {
                dispatch(setCareerList(response.data));
            } )
            .catch( error => {
                //case error
                dispatch(fetchCareerListFailed());
            } );
    };
};