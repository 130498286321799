import React from 'react';
import classes from './Availability.module.scss';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import { toCapitalize } from '../../../../helper/GlobalFunc';
import { DEFAULT_IMG } from '../../../../constant/Content';
import CurrencyFormat from 'react-currency-format';
import Ribbon from '../../../UI/Ribbon/Ribbon';
import Included from './Included/Included';
import { withRouter } from 'react-router-dom';

const availability = (props) => {
    let content = null;

    if (props.experiencePackages) {
        content = props.experiencePackages.map((item ,index) => {

            let flight = null;
            let hotel = null;
            if (item.includeFlight) {
                flight = (
                    <div className={classes.Item}>
                        <Included label="Flight" color="black"/>
                    </div>
                )
            }
            
            if (item.includeHotel) {
                hotel = (
                    <div className={classes.Item}>
                        <Included label="Hotel" color="black"/>
                    </div>
                );
            }

            return (
                <div className={classes.TourItem} onClick={() => props.history.push('/tour/detail/' + item.slug)} key={index}>
                    <PanelBox>
                        <div className={classes.RibbonWrapper}>
                            <Ribbon text="free voucher tour" position="left" size="small" />
                        </div>
                        <div className={classes.Image}>
                            <div className={classes.Title}>
                                {item.days + 'D '} {toCapitalize(item.inventoryTitle)}
                            </div>
                            <img onError={DEFAULT_IMG} src={item.defaultImage.imageFile} className={classes.Img} alt="" />
                        </div>
                        <div className={classes.Description}>
                            <div>
                                <div className={classes.Label}>Included</div>
                                <div className={classes.Includes}>
                                    {flight}
                                    {hotel}
                                </div>
                            </div>
                            <div className={classes.PriceWrapper}>
                                <div className={classes.Label}>Start From</div>
                                <div className={classes.Price}>
                                    <CurrencyFormat value={item.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                                    <span className={classes.Perpax}>/Pax</span>
                                </div>
                            </div>
                        </div>
                    </PanelBox>
                </div>
            )
        })
    }

    return (
        <div className={classes.Wrapper}>
            {content}
        </div>
    )
}

export default withRouter(availability);
