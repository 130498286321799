import React, { useState, useEffect } from 'react';
import classes from './BookingContainer.module.scss';
import Button from '../../../../components/UI/Button/Button';
import PackageInfo from '../../../../components/Mobile/Hotel/Booking/PackageInfo/PackageInfo';
import ContactInfo from '../../../../components/Mobile/Hotel/Booking/ContactInfo/ContactInfo';
import Spiner from '../../../../components/UI/Loaders/Spiner/Spiner';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';
import NotFound from '../../../../components/Mobile/Tour/NotFound/NotFound';
import Step from '../../../../components/Shared/Step/Step';
import SuccessBook from '../../../../components/Mobile/Hotel/Booking/SuccessBook/SuccessBook';
import AboutVoucher from '../../../../components/Mobile/Hotel/Booking/AboutVoucher/AboutVoucher';

import { connect } from 'react-redux';
import * as bookingAction from '../../../../redux/Hotel/Booking/BookingAction';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import Icon from '../../../../components/UI/Icon/Icon';

const bookingContainer = props => {
    const [isContinueConfirm, setIsContinueConfirm] = useState(false);
    const [showAboutVoucher, setShowAboutVoucher] = useState(false);

    let step = 1;
    useEffect(() => {
        let slug = props.match.params.slug;
        props.fetchHotelDetail(slug);
        props.setToDefault();
        scrollTop();
        if (!props.isSubmitBooking) {
            setIsContinueConfirm(false);
        }
    }, [props.isSubmitBooking]);

    const scrollTop = () =>{
        window.scrollTo(0, 0);
    }

    const submitInquiryHandler = () => {
        if (props.isValidContactInfoForm) {
            setIsContinueConfirm(true);
        } else {
            props.checkContactInfoForm();
        }
    }

    const submitData = () => {
        props.submitInquiry(props.hotelDetail.ID, props.contactInfoForm, props.inquiryForm)
    }

    const backClickHandler = () => {
        props.history.goBack();
    }

    let content =  <div className={classes.Loading}><Spiner/></div>;
    if (!props.isFetchHotelDetail ) {
        if (props.isErrorFetchHotelDetail) {
            content = <NotFound clicked={() => props.history.push('/tour/availability?destination=all-region')}/>
        } else {
            content = (
                <div>
                    <div className={classes.Content}>
                        <div className="u-mb-20">
                            <div className={classes.SubTitle}>Hotel Detail</div>
                            <PackageInfo
                                isLoading={props.isFetchHotelDetail}
                                data={props.hotelDetail}/>
                        </div>
                        <div className="u-mb-20">
                            <div className={classes.SubTitle}>Contact Information</div>
                            <ContactInfo 
                                contactInfo={props.contactInfoForm}
                                inputChanged={(idendtifier, value) => props.contactInfoChanged(idendtifier, value)}
                                inquiry={props.inquiryForm}
                                inquiryChanged={(idendtifier, value) => props.inquiryChanged(idendtifier, value)}/>
                        </div>
                        <div className="u-mb-20">
                            <PanelBox>
                                <div className={classes.Card} onClick={() => setShowAboutVoucher(true)}>
                                    About Free Voucher
                                    <Icon name="ico-chev-right" fill="#202124" stroke="none" />
                                </div>
                            </PanelBox>
                        </div>
                        <Button 
                            classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                            clicked={submitInquiryHandler}>
                            Submit Inquiry
                        </Button>
                    </div>

                </div>
            );
        }
    }

    if (props.isSuccessSubmitBooking) {
        step = 2;
        content = <SuccessBook clicked={() => props.history.push('/')}/>;
    }

    return (
        <div className={classes.Wrapper}>
            <Confirm 
                show={isContinueConfirm}
                title="Are you sure?"
                desc="Make sure your data is correct."
                cancelClicked={() => setIsContinueConfirm(false)}
                isConfirmLoading={props.isSubmitBooking}
                cancelLabel="No, check again"
                confirmLabel="Yes, continue"
                confirmClicked={submitData}/>

            <AboutVoucher
                show={showAboutVoucher}
                hidePopupClicked={() => setShowAboutVoucher(false)} />

            <div className={classes.Header}>
                <Header 
                    classes={['Shadow']}
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    content="Hotel Booking" />
            </div>
            <div className={classes.Step}>
                <Step type="inquiry" step={step} />
            </div>
            {content}
        </div>
    );
    
}

const mapStateToProps = state => {
    return {
        hotelDetail: state.HotelBookingReducer.hotelDetail,
        isFetchHotelDetail: state.HotelBookingReducer.isFetchHotelDetail,
        isErrorFetchHotelDetail: state.HotelBookingReducer.isErrorFetchHotelDetail,
        isValidContactInfoForm: state.HotelBookingReducer.isValidContactInfoForm,
        contactInfoForm: state.HotelBookingReducer.contactInfoForm,
        inquiryForm: state.HotelBookingReducer.inquiryForm,
        isSubmitBooking: state.HotelBookingReducer.isSubmitBooking,
        isSuccessSubmitBooking: state.HotelBookingReducer.isSuccessSubmitBooking
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchHotelDetail: (slug) => dispatch(bookingAction.fetchHotelDetail(slug)),
        contactInfoChanged: (idendtifier, value) => dispatch(bookingAction.changeContactInfoForm(idendtifier, value)),
        inquiryChanged: (idendtifier, value) => dispatch(bookingAction.changeInquiryForm(idendtifier, value)),
        submitInquiry: (hotelId, contactInfoFrom, inquiryForm) => dispatch(bookingAction.submitInquiry(hotelId, contactInfoFrom, inquiryForm)),
        checkContactInfoForm: () => dispatch(bookingAction.checkContactInfoForm()),
        setToDefault: () => dispatch(bookingAction.setToDefault()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(bookingContainer);