import React, { useState, useEffect } from 'react';
import classes from './BranchOfficeContainer.module.scss';
import Icon from '../../../../components/UI/Icon/Icon';
import BannerPage from '../../../../components/Mobile/NonProduct/BannerPage/BannerPage';
import axios from '../../../../axios';
import { FIREBASE_API } from '../../../../constant/Api';
import BranchOfficeSearch from '../../../../components/Mobile/NonProduct/BranchOfficeSearch/BranchOfficeSearch';
import BranchOfficeList from '../../../../components/Mobile/NonProduct/BranchOfficeList/BranchOfficeList';

const branchOfficeContainer = props => {
    const [regionSelected, setRegionSelected] = useState('Jakarta');
    const [branchOfficeList, setBranchOfficeList] = useState(null);
    const [isSecondHeader, setIsSecondHeader] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        axios.get(FIREBASE_API + 'branch_office_list.json')
            .then( response => {
                if (response.data) {
                    console.log('response.data', response.data)
                    setBranchOfficeList(response.data);
                } else {
                    props.history.push('/');
                }
            })
            .catch( error => {
                console.log(error)
            });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [props])

    const backClickHandler = () => {
        props.history.push('/');
    }

    const handleScroll = () => {
        const offsetTop = window.scrollY;
        if (offsetTop > 140) {
            setIsSecondHeader(true);
        } else {
            setIsSecondHeader(false);
        }
    }

    let regionIndex = 0;
    let regionList = [];
    let stores = [];

    if (branchOfficeList) {
        regionIndex = branchOfficeList.findIndex(region => {
            return region.name === regionSelected;
        });
        regionIndex = (regionIndex < 0) ? 0 : regionIndex;
        stores = branchOfficeList[regionIndex].stores;
        regionList = branchOfficeList.map(region => {
            return region.name;
        });
    }

    let secondHeaderClasses = [classes.Header, classes.SecondNav];
    if (isSecondHeader) {
        secondHeaderClasses.push(classes.Show);
    }
        
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Header} onClick={backClickHandler}>
                <span className={classes.Icon}>
                    <Icon name="ico-arrow-left" width="20" stroke="none" fill="#ffffff" />
                </span>
            </div>

            <div className={secondHeaderClasses.join(' ')} onClick={backClickHandler}>
                <span className={classes.Icon}>
                    <Icon name="ico-arrow-left" width="20" stroke="none" fill="#202124" />
                </span>
                <div className={classes.Title}>Branch Office</div>
            </div>

            <div className={classes.Content}>
                <BannerPage title="Branch Office" image="https://media01.cdnpergi.com/antavaya/images/branch-office-1.jpg" />
                <div className={classes.TextContent}>
                    <BranchOfficeSearch 
                        regionSelected={regionSelected}
                        regionList={regionList}
                        regionChanged={(val) => setRegionSelected(val)}/>
                </div>
                <BranchOfficeList stores={stores}/>
            </div>
        </div>
    );
}

export default branchOfficeContainer;