import React from "react";
import classes from "./TourLeader.module.scss";
import PanelBox from "../../../../../../../../components/UI/PanelBox/PanelBox";

const TourLeader = () => {
  let day = 9;
  let content = (
    <div className={classes.TourLeaderText}>
      Kontak tour leader akan kami informasikan 10 hari sebelum keberangkatan.
      Jika ada pertanyaan silahkan hubungi kami.
    </div>
  );
  if (day === 10) {
    content = (
      <>
        <div className={classes.Card}>
          <div className={classes.CardContent}>
            <div className={classes.Info}>
              <div className={classes.Title}>Samanta Siregar</div>
              <div className={classes.Desc}>Tour Leader</div>
            </div>
            <div className={classes.Image}>
              <img
                className={classes.Img}
                src={"https://randomuser.me/api/portraits/men/12.jpg"}
                alt={"Tour leader"}
              />
            </div>
          </div>
        </div>
        <div className={classes.Divider} />
        <div className={classes.Detail}>
          <div className={classes.Title}>Whatsapp</div>
          <div className={classes.Value}>+62 878 8765 8765</div>
        </div>
      </>
    );
  }
  return (
    <div className={classes.Panel}>
      <PanelBox>{content}</PanelBox>
    </div>
  );
};

export default TourLeader;
