import React, { useEffect } from 'react';
import classes from './Sort.module.scss';
import {TOUR_SORT_LIST} from '../../../../../constant/SortList';
import Popup from '../../../../UI/Popup/Popup';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../../redux/Tour/Availability/AvailabilityAction';

const sort = props => {
    useEffect(() => {
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [props.show]);
    
    let sortByClickHandler = (sort) => {
        props.hideSortPopupClicked();
        props.setSortBy(sort);
    }

    const sortList = TOUR_SORT_LIST.map( (item, index) => {
        let itemClasses = [classes.ItemSort];
        if (item.value === props.sortBy) {
            itemClasses = [classes.ItemSort, classes.Active];
        } 
        return (
            <li 
                key={index} 
                className={itemClasses.join(' ')}
                onClick={() => sortByClickHandler(item.value)}>
                {item.name}
            </li>
        );
    });

    let content = (
        <ul className={classes.SortList}>
            {sortList}
        </ul>
    )

    return (
        <Popup
            show={props.show}
            hideClicked={props.hideSortPopupClicked}
            title="Sort"
            content={content}/>
    );
}

const mapStateToProps = state => {
    return {
        sortBy : state.AdmissionAvailabilityReducer.sortBy
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSortBy: (value) => dispatch(AvailabilityAction.setSortBy(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(sort);