import React, { useEffect, useState } from 'react';
import classes from './EditProfileContainer.module.scss';
import Icon from '../../../../components/UI/Icon/Icon';
import { COLOR } from '../../../../constant/Library';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import TextField from '../../../../components/UI/Inputs/TextField/TextField';
import Button from '../../../../components/UI/Button/Button';
import CalendarSwipe from '../../../../components/UI/CalendarSwipe/CalendarSwipe';
import { formatShortDate, formatDisplayPhone } from '../../../../helper/GlobalFunc';
import EditEmail from './EditEmail/EditEmail';
import EditPhoneNumber from './EditPhoneNumber/EditPhoneNumber';
import EmailVerificationModal from './EmailVerificationModal/EmailVerificationModal';
import VerifyCode from '../../../../components/Mobile/NonProduct/VerifyCode/VerifyCode';

import { connect } from 'react-redux';
import * as memberAction from '../../../../redux/Shared/Member/MemberAction';

const editProfileContainer = props => {
    const [showEditEmailForm, setshowEditEmailForm] = useState(false);
    const [showEditPhoneForm, setshowEditPhoneForm] = useState(false);
    const [showConfirmationEmail, setshowConfirmationEmail] = useState(false);

    useEffect(() => {
        props.getDetailMemberHandler();
    },[]);

    useEffect(() => {
        if (props.successChangeMember) {
            props.history.push('/user');
            props.sucessChangedMemberHandler();
        }
    }, [props.successChangeMember]);

    const updateMemberHandler = () => {
        let payload = {
            title: props.formName.title.value,
            firstName: props.formName.firstName.value,
            lastName: props.formName.lastName.value,
            birthDate: formatShortDate(props.formBirthDateMobile.birthDate.value),
            citizenshipNumber: props.formCitizenshipNumber.citizenshipNumber.value
        }
        props.submitChangedMemberHandler(payload)
    }

    let unverifiedStatus = <span className={classes.Status}>
            <span className={classes.Icon}><Icon name="ico-check-circle-white" fill={COLOR.greyLight} stroke="none" /></span>
            <span>Not Verified</span>
        </span>;

    let verifiedStatus = <span className={classes.Status}>
            <span className={classes.Icon}><Icon name="ico-check-circle-white" fill={'#73C700'} stroke="none" /></span>
            <span>Verified</span>
        </span>;
        
    let firstName = '';
    let lastName = '';
    let citizenshipNumber = '';
    let email = '';
    let emailStatus = null;
    let phoneStatus = null;
    let emailVerifyBtn = null;
    let phoneVerifyBtn = null;
    let birthDate = new Date();


    let emailBtn = <span>
            <Button 
                clicked={() => setshowConfirmationEmail(true)} 
                classes={['BtnRed', 'BtnXSmall']}>Verify
            </Button>
        </span>;
    
    let phone = null;
    if (props.member) {
        firstName = props.formName.firstName.value;
        lastName = props.formName.lastName.value;
        citizenshipNumber = props.formCitizenshipNumber.citizenshipNumber.value;
        email = props.member.email;

        emailStatus = (props.member.isEmailVerified === 1) ? verifiedStatus : unverifiedStatus;

        if (props.member.isPhoneVerified === 1) {
            phoneStatus = verifiedStatus;
        } else {
            phoneVerifyBtn = <Button 
                clicked={() => props.setShowVerifyPhoneForm(true)}
                classes={['BtnRed', 'BtnXSmall']}>Verify</Button>;
            phoneStatus = unverifiedStatus;
        }
        emailVerifyBtn = (props.member.isEmailVerified === 1) ? '' : emailBtn;
        phone = formatDisplayPhone(`${props.member.countryCode}${props.member.phone}`);
        birthDate = props.formBirthDateMobile.birthDate.value
    }
    
    return (
        <div>
            <EditEmail
                show={showEditEmailForm}
                hideSortPopupClicked={() => setshowEditEmailForm(false)}/>
            <EmailVerificationModal
                showConfirmationEmail={showConfirmationEmail}
                showHideConfirmationEmailClicked={() => setshowConfirmationEmail(false)}/>

            <EditPhoneNumber
                show={showEditPhoneForm}
                hideSortPopupClicked={() => setshowEditPhoneForm(false)}/>
            
            <VerifyCode phone={phone} show={props.showOtpForm} hideClicked={() => props.setShowVerifyPhoneForm(false)}/>
                
            <div className={[classes.Shadow, classes.Header].join(' ')}>
                <span className={classes.Action}
                    onClick={() => props.history.push('/user')}>
                    <Icon name="ico-arrow-left" fill={COLOR.white} stroke="none" />
                </span>
                <span>Edit Profile</span>
            </div>

            <div className={classes.ContentWrapper}>
                <div className={classes.Content}>
                    <div className={classes.Section}>
                        <div className={classes.Title}>Email & Phone Number</div>
                        <PanelBox>
                            <div className={classes.Account}>
                                <div className={classes.AccountItem}>
                                    <div className="label">Email</div>
                                    <div className={classes.Value}>
                                        <span>{email}</span>
                                        {emailStatus}
                                    </div>
                                    <div className={classes.Action}>
                                        <span className={classes.AtionEdit} onClick={() => setshowEditEmailForm(true)}>Edit</span>
                                        {emailVerifyBtn}
                                    </div>
                                </div>
                                <div className={classes.AccountItem}>
                                    <div className="label">Phone Number</div>
                                    <div className={classes.Value}>
                                        <span>{phone}</span>
                                        {phoneStatus}
                                    </div>
                                    <div className={classes.Action}>
                                        <span className={classes.AtionEdit} onClick={() => setshowEditPhoneForm(true)}>Edit</span>
                                        <span>
                                            {phoneVerifyBtn}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </PanelBox>
                    </div>
                    <div className={classes.Section}>
                        <div className={classes.Title}>Personal Information</div>
                        <PanelBox>
                            <div className="u-px-15 u-py-20">
                                <div className="u-mb-20">
                                    <div className="label">First Name</div>
                                    <TextField
                                        placeholder="e.g. Julie" 
                                        value={firstName}
                                        changed={(event) => props.inputChangedNameHandler(event, 'firstName')}/>
                                </div>
                                <div className="u-mb-20">
                                    <div className="label">Last Name</div>
                                    <TextField
                                        placeholder="e.g. Applesed" 
                                        value={lastName} 
                                        changed={(event) => props.inputChangedNameHandler(event, 'lastName')}/>
                                </div>
                                <div className="u-mb-20">
                                    <div className="label">ID card number (KTP)</div>
                                    <TextField
                                        type="ID card number (KTP)"
                                        placeholder="" 
                                        value={citizenshipNumber}
                                        changed={(event) => props.inputChangedKTPHandler(event, 'citizenshipNumber')}/>
                                </div>
                                <div>
                                    <div className="label">Date of Birth</div>
                                    <CalendarSwipe
                                        inputIdentifier="birthdate"
                                        value={new Date(birthDate)}
                                        changed={(event) => props.dateBirthProfileChangedHandler(event, 'birthDate')}/>
                                </div>
                            </div>
                        </PanelBox>
                    </div>
                    <span>
                        <Button
                            classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
                            isLoading={props.isSubmitLoading}
                            clicked={() => updateMemberHandler()}>Save</Button>
                    </span>

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        formName: state.MemberReducer.formName,
        formEmail: state.MemberReducer.formEmail,
        formCitizenshipNumber: state.MemberReducer.formCitizenshipNumber,
        formBirthDateMobile: state.MemberReducer.formBirthDateMobile,
        successChangeMember: state.MemberReducer.successChangeMember,
        isSubmitLoading: state.MemberReducer.isSubmitLoading,
        showOtpForm: state.MemberReducer.showOtpForm,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailMemberHandler: () => dispatch(memberAction.getDetailMember()),
        inputChangedNameHandler: (event, inputIdentifier) => dispatch(memberAction.inputChangedName(event, inputIdentifier)),
        inputChangedKTPHandler: (event, inputIdentifier) => dispatch(memberAction.inputChangedKTP(event, inputIdentifier)),
        dateBirthProfileChangedHandler: (event, inputIdentifier) => dispatch(memberAction.dateBirthProfileChanged(event, inputIdentifier)),
        submitChangedMemberHandler: (dataMember) => dispatch(memberAction.submitChangedMember(dataMember, 'generalForm')),
        sucessChangedMemberHandler: () => dispatch(memberAction.sucessMemberChanged()),
        setShowVerifyPhoneForm : val => dispatch(memberAction.setShowOtpForm(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(editProfileContainer);