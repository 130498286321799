import React, { Component } from 'react'
import classes from './Corporate.module.scss'
import Intro from './Intro/Intro'
import CorporateTravelServices from './CorporateTravelServices/CorporateTravelServices'
import CorporateOnlineSolution from './CorporateOnlineSolution/CorporateOnlineSolution'

class Corporate extends Component {
	state = {}
	render() {
		return (
			<div className={classes.Wrapper}>
				<Intro />
				<CorporateTravelServices />
				<CorporateOnlineSolution />
			</div>
		)
	}
}

export default Corporate
