import React, { useState, useEffect } from 'react';
import classes from './MyBookingContainer.module.scss';
import BottomNavBar from '../../../../components/Mobile/Shared/BottomNavBar/BottomNavBar';
import Slider from 'react-slick';
import LabelLoader from '../../../../components/UI/Loaders/Mobile/Label/LabelLoader';
import NotFound from './Content/NotFound/NotFound';
import UpcomingPage from './Content/UpcomingPage/UpcomingPage';
import Header from "../../../../components/Mobile/Shared/Header/Header";
import {BOOKING_TAB, DEFAULT_BOOKING_TAB} from '../../../../constant/General';

import { connect } from 'react-redux';
import * as myBookingAction from '../../../../redux/Shared/MyBooking/MyBookingAction';

const myBookingContainer = (props) => {
    const [activePage, setActivePage] = useState(DEFAULT_BOOKING_TAB);

    useEffect(() => {
        props.initBookingList();
    }, []);

    const scrollTop = () => {
        window.scrollTo(0, 0)
    }

    scrollTop()

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        variableWidth: true,
        centerPadding: '0px',
        fade: false,
        dotsClass: 'slick-dots ' + classes.DotWrapper,
        autoplay: false,
        autoplaySpeed: 3500,
        swipeToSlide: true
    }

    let labelList = []
    if (!props.isFetch) {
        labelList = BOOKING_TAB.map((item, index) => {
            let labelClasses = [classes.Item]
            if (item.slug === activePage) {
                labelClasses = [classes.Item, classes.Active]
            }
            return (
                <div key={index}
                    className={classes.ItemWrapper}
                    onClick={() => setActivePage(item.slug)}>
                    <div className={labelClasses.join(' ')}>{item.label}</div>
                </div>
            )
        })
        labelList = <Slider {...settings}>{labelList}</Slider>
    } else {
        labelList = (
            <div className={classes.ItemWrapperLoader}>
                <LabelLoader />
            </div>
        )
    }

    let activePageComponent = null;
    if (props.isErrorFetch) {
        activePageComponent = <NotFound />
    } else {
        activePageComponent = <UpcomingPage activePage={activePage} history={props.history} listTour={props.bookingList} isLoading={props.isFetch}/>
    }

    return (
        <div className={classes.Wrapper}>
            <Header classes={['ClBlack', 'BgWhite']}
                icoColor="white"
                align="center"
                content={<div>My Booking</div>} />
            <div className={classes.LabelList}>
                {labelList}
            </div>
            <div className={classes.Content}>
                {activePageComponent}
            </div>
            <BottomNavBar />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        bookingList: state.MyBookingReducer.bookingList,
        isFetch: state.MyBookingReducer.isFetch,
        isErrorFetch: state.MyBookingReducer.isErrorFetch,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initBookingList: () => dispatch(myBookingAction.initBookingList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(myBookingContainer);
