import React from 'react';
import classes from './NotFound.module.scss';
import img from '../../../../assets/images/art-expired-booking.png';
import Button from '../../../UI/Button/Button';

const notFound = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Content}>
                <img src={img} className={classes.Image} alt="booking not found" />
                <div className={classes.Title}>Oops, Expired Booking</div>
                <div className={classes.SubTitle}>Sorry, your booking time is up. Please make another booking.</div>
                <div className={classes.Action}>
                    <Button 
                        classes={['BtnRed', 'BtnMedium']}
                        clicked={props.clicked}>
                        Another Booking
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default notFound;