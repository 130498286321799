import React from 'react';
import classes from './MessageBox.module.scss';
import PanelBox from '../../../../../components/UI/PanelBox/PanelBox';
import img from '../../../../../assets/images/art-submit-application.png';
import Button from '../../../../../components/UI/Button/Button';

const messageBox = (props) => {
    return (
        <div className={classes.Wrapper}>
            <PanelBox>
                <div className={classes.Content}>
                    <img src={img} className={classes.Image} alt="booking not found" />
                    <div className={classes.Title}>Thank You!</div>
                    <div className={classes.SubTitle}>Your request is submitted. We will call you back within 24hrs.</div>
                    <div className={classes.Action}>
                        <Button 
                            classes={['BtnRed', 'BtnMedium']}
                            clicked={props.clicked}>
                            Back to Home
                        </Button>
                    </div>
                </div>
            </PanelBox>
        </div>
    )
}

export default messageBox;