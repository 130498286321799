import React from 'react';
import classes from './PackageInfo.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import ImgThumb from '../../../../UI/ImgThumb/ImgThumb';
import Icon from '../../../../UI/Icon/Icon';
import ContentLoader from 'react-content-loader';
import { formatDisplayDate, formatDisplayTime } from '../../../../../helper/GlobalFunc';
import CurrencyFormat from 'react-currency-format';

const packageInfo = (props) => {
    let content = <Loader />;
    if (props.virtualTourDetail) {
        content = (
            <PanelBox>
                <div className={classes.Wrapper}>
                    <div className={classes.Content}>
                        <div className={classes.Header}>
                            <div className="u-mr-15">
                                <ImgThumb src={props.virtualTourDetail.imageSource} size="small" />
                            </div>
                            <div className={classes.Title}>
                                {props.virtualTourDetail.name}
                            </div>
                        </div>
                        <div className={classes.Body}>
                            <div className={classes.Item}>
                                <div className={classes.Label}>Date and Time</div>
                                <div className={classes.Detail}>
                                    <div className={classes.Icon}>
                                        <Icon name="ico-calendar" fill="#666666" stroke="none" width={22} />
                                    </div>
                                    {formatDisplayDate(props.virtualTourDetail.liveDate)} {'  ' + formatDisplayTime(props.virtualTourDetail.liveDate)}
                                </div>
                            </div>
                            <div className={classes.Item}>
                                <div className={classes.Label}>Duration</div>
                                <div className={classes.Detail}>
                                    <div className={classes.Icon}>
                                        <Icon name="ico-time" fill="#666666" stroke="none" width={22} />
                                    </div>
                                    {props.virtualTourDetail.duration + ' minutes'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.Footer}>
                        <div className={classes.Label}>
                            Price
                        </div>
                        <div className={classes.Price}>
                            <CurrencyFormat value={props.virtualTourDetail.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                        </div>
                    </div>
                </div>  
            </PanelBox>
        )
    }

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    )
}

export default packageInfo;


const Loader = () => {
    return (
        <PanelBox>
            <ContentLoader
                height={225}
                speed={1}
                primaryColor={'#e6e6e6'}>
                    <rect x="16" y="16" rx="29" ry="29" width="58" height="58" />
                    <rect x="95" y="23" rx="3" ry="3" width="230" height="16" />
                    <rect x="95" y="50" rx="3" ry="3" width="180" height="16" />

                    <rect x="16" y="100" rx="3" ry="3" width="95" height="12" />
                    <rect x="16" y="122" rx="3" ry="3" width="165" height="18" />
                    <rect x="16" y="160" rx="3" ry="3" width="95" height="12" />
                    <rect x="16" y="182" rx="3" ry="3" width="165" height="18" />
            </ContentLoader>
        </PanelBox>
    )
}