import React, { Component } from 'react'
import classes from './BottomNavBar.module.scss'
import Icon from '../../../UI/Icon/Icon'
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux'
import { selectPage } from '../../../../redux/Shared/BottomNavBar/BottomNavBarAction';
import { BOTTOM_NAV_BARS } from '../../../../constant/General';
import Auth from '../../../../middleware/Auth';

const Item = (props) => {
	return(
		<div onClick={props.clicked} className={props.itemClasses.join(' ')}>
			<Icon name={props.icon} width={props.width} stroke={props.stroke} fill={props.fill} />
			<p className={classes.Title}>{props.title}</p>
		</div>
	)
}

class BottomNavBar extends Component {

	componentDidMount=()=> {
		let location = this.props.location.pathname;
		this.props.selectPage(location)
	}

	navBarSelectHandler = (slug) => {
		if (slug === '/user') {
			if (Auth.isAuthenticated()) {
				this.props.history.push('/user')
			} else {
				this.props.history.push('/login')
			}
		} else if (slug === '/user/my-booking'){
			if (Auth.isAuthenticated()) {
				this.props.history.push('/user/my-booking')
			} else {
				this.props.history.push('/retrieve-booking')
			}
		} else {
			this.props.history.push(slug)
		}
		this.props.selectPage(slug)
	}
	render () {
		const navBars = BOTTOM_NAV_BARS.map((nav, index) => {
			let itemClasses = [classes.Item];
			let iconName = nav.ico;
			if (nav.activeUrl.indexOf(this.props.activeNavBar) !== -1) {
				iconName = nav.ico+'-active';
				itemClasses = [classes.Item, classes.Selected];
			}
			return <Item key={index} itemClasses={itemClasses} clicked={() => this.navBarSelectHandler(nav.slug)} icon={iconName} width={25} stroke={'none'} fill={'#818589'} title={nav.label} />;
		});
		return (
			<div className={classes.Wrapper}>
				<div className={classes.PanelBox}>
					{navBars}
				</div>
			</div>
		)
		}
}

const mapStateToProps = state => {
	return{
		activeNavBar : state.BottomNavReducer.activeNavBar,
	}
}

const mapDispatchToProps = dispatch => {
	return{
		selectPage: (slug) => dispatch(selectPage(slug))
	}
}

export default withRouter (connect(mapStateToProps, mapDispatchToProps)(BottomNavBar));
