import React from 'react';
import ContactInfo from './ContactInfo/ContactInfo';
import GuestInfo from './GuestInfo/GuestInfo';
import Button from '../../../../UI/Button/Button';

const leftContent = (props) => {
    let roomList = null;
    if (props.guestData && props.guestData.length > 0) {
        roomList = props.guestData.map((guestPerRoom, index) => {
            return (
                <div key={index} className="u-mb-16">
                    <GuestInfo 
                        roomIndex={index}
                        roomData={guestPerRoom}
                        guestInputChanged={(event, roomIndex, guestType, guestIndex, inputType) => props.guestInputChanged(event, roomIndex, guestType, guestIndex, inputType)} />
                </div>
            );
        });
    }
    
    return (
        <div>
            <div className="u-mb-16">
                <ContactInfo 
                    contactInfo={props.contactInfo}
                    inputChanged={(event, type) => props.inputChanged(event, type)}/>
            </div>
            {roomList}
            <div className="u-display-flex u-justify-content-end">
                <div className="u-flex-4 u-mt-8">
                    <Button 
                        classes={['BtnRed', 'BtnLarge', 'BtnBlock']}
                        isLoading={props.isSubmitBookLoading}
                        clicked={props.submitInquiryClicked}>
                        Continue to payment
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default leftContent;