import React, { Component } from 'react';
import classes from './SearchForm.module.scss';
import Dropdown from '../../../../UI/Dropdown/Dropdown';

class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <div>
                <div className={classes.Wrapper}>
                    <div className={classes.Container}>
                        <div className={classes.WrapperForm}>
                            <div className={classes.InputWrapper}>
                                <div className={classes.InputText}>
                                    <div className={classes.Label}>POSITION</div>
                                    <div className={classes.Input}>
                                        <input placeholder="Type Position" value={this.props.positionValue} onChange={this.props.setPositionChanged}/>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.InputWrapper}>
                                <Dropdown
                                    label="DEPARTMENT"
                                    placeholder="Select Department"
                                    value={this.props.departmentValue}
                                    dropdownList={this.props.departmentList}
                                    setValueSelected={(val) => this.props.setDepartmentSelected(val)}/>
                            </div>
                            <div className={classes.InputWrapper}>
                                <Dropdown
                                    label="LOCATION"
                                    placeholder="Select Location"
                                    value={this.props.locationValue}
                                    dropdownList={this.props.locationList}
                                    setValueSelected={(val) => this.props.setLocationSelected(val)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchForm;
