import React, { Component } from 'react';
import classes from './CareerDetailContainer.module.scss';
import LeftContent from '../../../../components/Desktop/NonProducts/Career/JobDetail/LeftContent/LeftContent';
import Alert from '../../../../components/UI/Alert/Alert';
import axios from '../../../../axios';
import { ANTAVAYA_API } from '../../../../constant/Api';
import MetaTag from '../../../../helper/MetaTag';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon } from 'react-share';
import Icon from '../../../../components/UI/Icon/Icon';
import Button from '../../../../components/UI/Button/Button';
import TextField from '../../../../components/UI/Inputs/TextField/TextField';
import SelectFlag from '../../../../components/UI/Inputs/SelectFlag/SelectFlag';
import Recaptcha from 'react-recaptcha';
import { CAPTCHA_KEY } from '../../../../constant/VendorKey';
import UploadFile from '../../../../components/UI/UploadFile/UploadFile';

import { connect } from 'react-redux';
import * as careerFormAction from '../../../../redux/NonProducts/CareerForm/CareerFormAction';

class CareerDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            career: null
        };
        this.captcha = null;
    }

    componentDidMount() {
        let slug = this.props.match.params.slug;
        axios.get(ANTAVAYA_API + 'v1/career/' + slug)
            .then( response => {
                if (response.data) {
                    this.setState({
                        career: response.data
                    });
                } else {
                    this.goToCareerPageHandler()
                }
            })
            .catch( error => {
                console.log(error)
            });
    }

    submitApplicantHandler = () => {
        if (this.props.isVerified && this.props.isValidForm) {
            let file = this.props.selectedFile;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
                let ecy = e.target.result.split(',');
                let attachments = [{
                    name: file.name,
                    content: ecy[1],
                    contentType: file.type
                }];

                const postData = {
                    name: this.props.formData.name.value,
                    phone: this.props.formData.dialCode.value.concat(this.props.formData.phone.value),
                    email: this.props.formData.email.value,
                    position: this.state.career.name,
                    attachments: attachments, 
                };
                // console.log('', postData);
                this.captcha.reset();
                this.props.formSubmited(postData);
                
            }
        } else {
            this.props.formChecking();
        }
    }

    goToCareerPageHandler = () => {
        this.props.history.push('/career');
    }

    render() {
        window.scrollTo(0, 0);
        if (!this.state.career) {
            return null;
        }

        let url = window.location.href;
        return (
            <div>
                <Alert 
                    showAlert={this.props.showAlert}
                    confirmAlertClicked={this.goToCareerPageHandler}
                    confirmLabel="Back to career list"/>

                <div className={classes.Wrapper}>
                    <div className={classes.JobDetail}>
                        <div className={classes.Container}>
                            <div className={[classes.Content, classes.Left].join(' ')}>
                                <LeftContent 
                                    jobDetail={this.state.career}/>
                            </div>
                        </div>
                    </div>
                    <div className={classes.RightContent}>
                        <div className={classes.FormWrapper}>
                            <MetaTag 
                                title={this.state.career.name + ' - ' + this.state.career.location}
                                description={this.state.name} />
                                
                            <div className={classes.Close} onClick={this.goToCareerPageHandler}>
                                <Icon name="ico-close" fill="#000000" stroke="none" width={32} />
                            </div>
                            <div className={classes.Content}>
                                <div className={[classes.Title, 'u-mb-30'].join(' ')}>Apply Now..!</div>
                                <div className={classes.Form}>
                                    <div>
                                        <div className={classes.Input}>
                                            <label>Full Name</label>
                                            <TextField
                                                placeholder="e.g. Julie Applesed"
                                                value={this.props.formData.name.value}
                                                changed={(event) => this.props.inputChanged('name', event)}
                                                hasError={this.props.formData.name.hasError && this.props.formData.name.errorMsg !== ''}
                                                errorMsg={this.props.formData.name.errorMsg} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.Input}>
                                            <label>E-mail Address</label>
                                            <TextField
                                                placeholder="e.g. youremail@example.com"
                                                value={this.props.formData.email.value}
                                                changed={(event) => this.props.inputChanged('email', event)}
                                                hasError={this.props.formData.email.hasError && this.props.formData.email.errorMsg !== ''}
                                                errorMsg={this.props.formData.email.errorMsg} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.Input}>
                                            <label>Phone Number</label>
                                            <div className={classes.Inline}>
                                                <div className={classes.DialCode}>
                                                    <SelectFlag 
                                                        value={this.props.formData.dialCode.value}
                                                        changed={val => this.props.dialCodeChanged(val)}/>
                                                </div>
                                                <div className={classes.Phone}>
                                                    <TextField
                                                        type="text"
                                                        placeholder="e.g. 8123456789"
                                                        value={this.props.formData.phone.value}
                                                        changed={(event) => this.props.inputChanged('phone', event)}
                                                        hasError={this.props.formData.phone.hasError && this.props.formData.phone.errorMsg !== ''}
                                                        errorMsg={this.props.formData.phone.errorMsg} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.Input}>
                                            <label>Resume / CV</label>
                                            <UploadFile
                                                changed={this.props.fileSelected}
                                                rule="doc, docx, pdf Up to 10 Mb."/>
                                        </div>
                                    </div>
                                    <div className={classes.Input}>
                                        <Recaptcha
                                            ref={e => (this.captcha = e)}
                                            sitekey={CAPTCHA_KEY}
                                            render="explicit"
                                            verifyCallback={this.props.verifyClicked} />
                                    </div>
                                    <div>
                                        <Button
                                            classes={['BtnRed', 'BtnBlock', 'BtnLarge']}
                                            clicked={this.submitApplicantHandler}
                                            disabled={this.props.isLoadingSubmitForm}>
                                            Submit Application
                                        </Button>
                                    </div>
                                </div>

                                <div className={classes.SocialMedia}>
                                    <div className={classes.Title}>Share this job</div>
                                    <ul className={classes.List}>
                                        <li>
                                            <LinkedinShareButton url={url}>
                                                <LinkedinIcon size={32} />
                                            </LinkedinShareButton>
                                        </li>
                                        <li>
                                            <FacebookShareButton url={url}>
                                                <FacebookIcon size={32} />
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <TwitterShareButton url={url}>
                                                <TwitterIcon size={32} />
                                            </TwitterShareButton>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formData: state.CareerFormReducer.formData,
        selectedFile: state.CareerFormReducer.selectedFile,
        isValidForm: state.CareerFormReducer.isValidForm,
        isVerified: state.CareerFormReducer.isVerified,
        showAlert: state.CareerFormReducer.showAlert,
        isLoadingSubmitForm: state.CareerFormReducer.isLoadingSubmitForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        inputChanged : (identifier, event) => dispatch(careerFormAction.inputChanged(identifier, event)),
        dialCodeChanged : (value) => dispatch(careerFormAction.dialCodeChanged(value)),
        fileSelected : (event) => dispatch(careerFormAction.fileSelected(event)),
        verifyClicked : (res) => dispatch(careerFormAction.verifyClicked(res)),
        formChecking : () => dispatch(careerFormAction.formChecking()),
        formSubmited : (postData) => dispatch(careerFormAction.formSubmited(postData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CareerDetailContainer);
