import React from 'react';
import classes from './BannerAvailability.module.scss';
import hotelBannerImage from '../../../../assets/images/banners/hotel.jpg';

const BannerAvailability = () => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Background}>
                <img src={hotelBannerImage} alt="hotel-banner" />
            </div>
            <div className={classes.Container}>
                <div className={classes.SearchForm}>
                    <div className={classes.Title}>Buy Now Stay Later</div>
                    <div className={classes.Desc}>Best deal hotel to enjoy staycation</div>
                </div>
            </div>
        </div>
    )

}

export default BannerAvailability;