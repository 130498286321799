import React from 'react';
import classes from './HeaderBooking.module.scss';
import logo from '../../../../assets/images/logo.png';
import Step from '../../../../components/Shared/Step/Step';

const bookingHeader = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <a href="/">
                    <div className={classes.Logo}>
                        <img src={logo} alt="antavaya" />
                    </div>
                </a>
                <ul className={classes.Nav}>
                    <Step type="inquiry" step={props.step}/>
                </ul>
            </div>

        </div>
    );
}

export default bookingHeader;