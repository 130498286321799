import React, { useState } from 'react';
import classes from './UploadFile.module.scss';
import Icon from '../Icon/Icon';

const uploadFile = props => {
    const [file, setFile] = useState(null);

    let fileInput = React.createRef();
    
    const setImage = () => {
        setFile(fileInput.current.files[0]);
        // tarik ke parent
        props.changed(fileInput.current.files[0])
    }

    let nameFile = props.rule;
    if (file !== null) {
        nameFile = file.name;
    }
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Content}>
                <div className={classes.BtnUpload} onClick={() => document.getElementById("uploadFile").click()}>
                    <Icon name="ico-upload" width={14} stroke="none" fill="#6A6E73"/>
                    <div className={classes.Label}>Upload</div>
                </div>
                <div className={classes.Rule}>{nameFile}</div>
                <div style={{ height: '0px', overflow: 'hidden' }}>
                    <input type="file" onChange={setImage} id="uploadFile" name="uploadFile" ref={fileInput}/>
                </div>
            </div>
        </div>
    )
}

export default uploadFile;