import React from 'react';
import classes from './NotFound.module.scss';
import img from '../../../../../../assets/images/art-expired-booking.png';
// import Button from '../../../../../UI/Button/Button';

function notFound(props){
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Content}>
                <img src={img} className={classes.Image} alt="on error" />
                <div className={classes.Title}>No Bookings Found</div>
                <div className={classes.SubTitle}>Anything you booked shows up here, but it seems like you haven’t made any. Let’s create one via homepage!</div>
                {/* <div className={classes.Action}>
                    <Button 
                        classes={['BtnRed', 'BtnMedium']}
                        clicked={props.clicked}>
                        Another Booking
                    </Button>
                </div> */}
            </div>
        </div>
    );
}

export default notFound