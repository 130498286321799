import React from 'react';
import classes from './Tour.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import { DEFAULT_IMG } from '../../../../../constant/Content';
import CurrencyFormat from 'react-currency-format';
import Ribbon from '../../../../UI/Ribbon/Ribbon';
import Included from './Include/Included';
import { toCapitalize } from '../../../../../helper/GlobalFunc';

const admission = (props) => {
    let flight = null;
    let hotel = null;
    if (props.tourData.includeFlight) {
        flight = (
            <div className={classes.Item}>
                <Included label="Flight" color="black"/>
            </div>
        )
    }
    
    if (props.tourData.includeHotel) {
        hotel = (
            <div className={classes.Item}>
                <Included label="Hotel" color="black"/>
            </div>
        );
    }

    return (
        <div className={classes.TourItem} onClick={props.clicked}>
            <PanelBox>
                <div className={classes.RibbonWrapper}>
                    <Ribbon text="free voucher tour" position="left" size="small" />
                </div>
                <div className={classes.Image}>
                    <div className={classes.Title}>
                        {props.tourData.days + 'D '} {toCapitalize(props.tourData.inventoryTitle)}
                    </div>
                    <img onError={DEFAULT_IMG} src={props.tourData.defaultImage.imageFile} className={classes.Img} alt="" />
                </div>
                <div className={classes.Description}>
                    <div>
                        <div className={classes.Label}>Included</div>
                        <div className={classes.Includes}>
                            {flight}
                            {hotel}
                        </div>
                    </div>
                    <div className={classes.PriceWrapper}>
                        <div className={classes.Label}>Start From</div>
                        <div className={classes.Price}>
                            <CurrencyFormat value={props.tourData.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            <span className={classes.Perpax}>/Pax</span>
                        </div>
                    </div>
                </div>
            </PanelBox>
        </div>
    )
}

export default admission;
