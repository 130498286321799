export const COLOR = {
    black : '#202124',
    blackLight : '#818589',

    grey : '#6A6E73',
    greyLight : '#c2c2c2',

    white : '#FFFFFF',
    whiteDark: '#FAFAFA',
    whiteDark1 : '#f5f5f5',
    whiteDark2 : '#E6E6E6',
    whiteDark3 : '#f0f0f0',

    red : '#CD2033',
    redLight : '#e44631',

    yellow : '#F99E00',
    yellowLight : '#fdeed3',

    green  : '#73C700',
    greenLight  : '#25d366',

    blue: '#1A6FA3',
    blueDark : '#4267B2',
};