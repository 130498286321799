import React, { useState, useEffect } from 'react';
import classes from './BookingContainer.module.scss';
import MetaTag from '../../../../helper/MetaTag';
import BookingHeader from '../../../../components/Desktop/Shared/BookingHeader/BookingHeader';
import LeftContent from '../../../../components/Desktop/Voucher/BookingForm/LeftContent/LeftContent';
import RightContent from '../../../../components/Desktop/Voucher/BookingForm/RightContent/RightContent';
import MessageBox from '../../../../components/Desktop/Shared/MessageBox/MessageBox';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';

import { connect } from 'react-redux';
import * as BookingAction from '../../../../redux/Voucher/Booking/BookingAction';

const bookingContainer = (props) => {
    const [isContinuePayment, setIsContinuePayment] = useState(false);

    useEffect(() => {
        props.setToDefault();
        props.fetchVoucherDetail(props.match.params.slug);

        scrollTop()
    }, []);

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        if (props.isSuccessSubmitBooking) {
            setIsContinuePayment(false);
        }
    }, [props.isSuccessSubmitBooking])

    const goBack = () => {
        props.seIsSuccessSubmitBooking(false);
        props.history.push('/');
    }

    const isContinuePaymentHandler = (val) => {
        setIsContinuePayment(val)
    }

    const submitInquryHandler = () => {
        props.submitInquiry(props.voucherDetail.ID, props.contactInfoForm, props.inquiryForm)
    }

    let content = null;
    let step = 1;
    if (props.isSuccessSubmitBooking) {
        step = 2;
        content = <MessageBox
            title="Thank You!"
            subTitle="Your request is submitted. We will call you back within 24hrs."
            img="art-submit-application"
            labelBtn="Back to Home"
            clicked={() => goBack()} />
    } else {
        content = (
            <div className={classes.Container}>
                <div className={classes.Header}>
                    <div className={classes.Title}>Voucher Booking</div>
                    <div className={classes.SubTitle}>Fill in your details and review your booking</div>
                </div>
                <div className={classes.Body}>
                    <div className={classes.LeftContent}>
                        <LeftContent
                            isContinuePaymentHandler={(val) => isContinuePaymentHandler(val)}
                            voucherDetail={props.voucherDetail} />
                    </div>
                    <div className={classes.RightContent}>
                        <RightContent voucherDetail={props.voucherDetail} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="bg-white-dark">
            <MetaTag
                title="Voucher Booking" />
            <Confirm
                show={isContinuePayment}
                title="Are You Sure?"
                desc="Make sure your data is correct."
                cancelClicked={() => setIsContinuePayment(false)}
                isConfirmLoading={props.isSubmitBookLoading}
                cancelLabel="No, check again"
                confirmLabel="Yes, continue"
                confirmClicked={() => submitInquryHandler()} />
            <div className={classes.Wrapper}>
                <BookingHeader step={step} />
                {content}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        voucherDetail: state.VoucherBookingReducer.voucherDetail,
        contactInfoForm: state.VoucherBookingReducer.contactInfoForm,
        inquiryForm: state.VoucherBookingReducer.inquiryForm,
        isSuccessSubmitBooking: state.VoucherBookingReducer.isSuccessSubmitBooking,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVoucherDetail: (slug) => dispatch(BookingAction.fetchVoucherDetail(slug)),
        seIsSuccessSubmitBooking: (value) => dispatch(BookingAction.seIsSuccessSubmitBooking(value)),
        setToDefault: () => dispatch(BookingAction.setToDefault()),
        submitInquiry: (voucherId, contactInfoFrom, inquiryForm) => dispatch(BookingAction.submitInquiry(voucherId, contactInfoFrom, inquiryForm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(bookingContainer);