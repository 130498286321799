
import React, { Component } from 'react';
import classes from './FlightDate.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import Dropdown from './Dropdown/Dropdown';
import onClickOutside from "react-onclickoutside";

class SearchAutocomplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdownList: false
        };
    }

    showDropdownListHandler = () => {
        this.setState({showDropdownList: true});
        this.props.showBackdrop();
    }

    hideDropdownListHandler = () => {
        this.setState({showDropdownList: false});
    }

    handleClickOutside = (evt) => {
        this.hideDropdownListHandler();
    }

    itemSelectedHandler = (val) => {
        this.props.itemSelected(val);
        this.hideDropdownListHandler();
    }

    focusHandler = event => {
        event.target.select();
    }

    render() {
        let iconClasses = [classes.Icon];
        if (this.state.showDropdownList) {
            iconClasses.push(classes.Active);
        }

        let isReadOnly = false;
        if (this.props.isReadOnly) {
            isReadOnly = true;
        }

        return (
            <div className={classes.Wrapper}>
                <div className={classes.Label}>{this.props.label}</div>
                <div className={classes.InputWrapper} onClick={this.showDropdownListHandler}>
                    <input 
                        onChange={this.props.changed}
                        className={classes.Input} 
                        placeholder={this.props.placeholder} 
                        value={this.props.value}
                        onFocus={this.focusHandler} 
                        readOnly={isReadOnly}/>
                    <div className={iconClasses.join(' ')}>
                        <Icon name="ico-caret-down" fill="#000000" stroke="none" />
                    </div>
                </div>
                <div className={classes.DropdownList}>
                    <Dropdown
                        dropdownList={this.props.dropdownList}
                        showDropdownList={this.state.showDropdownList}
                        itemSelected={(val) => this.itemSelectedHandler(val)}
                        isRoundTrip={this.props.isRoundTrip}
                        roundTripToggleClicked={this.props.roundTripToggleClicked}
                        departureDate={this.props.departureDate}
                        returnDate={this.props.returnDate}
                        dateClicked={ dates => this.props.dateClicked(dates)} />
                </div>
            </div>
        );
    }
}

export default onClickOutside(SearchAutocomplete);