import React from 'react'
import classes from './BookingPaymentDetail.module.scss'
import PanelBox from '../../../../../../../../components/UI/PanelBox/PanelBox'
import Icon from '../../../../../../../../components/UI/Icon/Icon'

const BookingPaymentDetail = () => {
  return (
    <div className={classes.Panel}>
      <PanelBox>
        <div className={classes.Card}>
          <div className={classes.Detail}>
            <div className={classes.Title}>Booking ID</div>
            <div className={classes.Value}>434937617</div>
          </div>
          <div className={classes.Detail}>
            <div className={classes.Title}>Purchased Name</div>
            <div className={classes.Value}>John Doe</div>
          </div>
          <div className={classes.Detail}>
            <div className={classes.Title}>Purchased On</div>
            <div className={classes.Value}>15 July 2019</div>
          </div>
          <div className={classes.Detail}>
            <div className={classes.Title}>Payment Method</div>
            <div className={classes.Value}>Virtual Account BCA</div>
          </div>
        </div>
        <div className={classes.Foot}>
          <Icon
            name='ico-check-circle-mobile'
            fill='#73C700'
            stroke='none'
            width={16}
          />
          <div className={classes.TextSuccess}>Payment Succesful</div>
        </div>
      </PanelBox>
    </div>
  )
}

export default BookingPaymentDetail