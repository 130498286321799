import React from 'react';
import classes from './Information.module.scss';
import PanelBox from '../../../../../components/UI/PanelBox/PanelBox';

const information = () => {
    return (
        <div className={classes.Wrapper}>
            <div className="u-mb-16">
                <PanelBox>
                    <div className={classes.Content}>
                        <div className={classes.Item}>
                            <div className={classes.Label}>1. Iklim dan Cuaca</div>
                            <div className={classes.Desc}>
                                When you start designing the first mockup of your website, a landing page, or an ad copy, you might need to add some placeholder text to get an overall look and feel of your design. Right?
                                Placeholder text helps outline the visual elements of your design. The requirements change per your design – at times you may just need a line of text, and in some cases, you will need loads of paragraphs. But, where do you get these so-called ‘placeholder text’.
                            </div>
                        </div>
                    </div>
                    <div className={classes.Content}>
                        <div className={classes.Item}>
                            <div className={classes.Label}>2. Iklim dan Cuaca</div>
                            <div className={classes.Desc}>
                                When you start designing the first mockup of your website, a landing page, or an ad copy, you might need to add some placeholder text to get an overall look and feel of your design. Right?
                                Placeholder text helps outline the visual elements of your design. The requirements change per your design – at times you may just need a line of text, and in some cases, you will need loads of paragraphs. But, where do you get these so-called ‘placeholder text’.
                            </div>
                        </div>
                    </div>
                    <div className={classes.Content}>
                        <div className={classes.Item}>
                            <div className={classes.Label}>3. Iklim dan Cuaca</div>
                            <div className={classes.Desc}>
                                When you start designing the first mockup of your website, a landing page, or an ad copy, you might need to add some placeholder text to get an overall look and feel of your design. Right?
                                Placeholder text helps outline the visual elements of your design. The requirements change per your design – at times you may just need a line of text, and in some cases, you will need loads of paragraphs. But, where do you get these so-called ‘placeholder text’.
                            </div>
                        </div>
                    </div>
                    <div className={classes.Content}>
                        <div className={classes.Item}>
                            <div className={classes.Label}>4. Iklim dan Cuaca</div>
                            <div className={classes.Desc}>
                                When you start designing the first mockup of your website, a landing page, or an ad copy, you might need to add some placeholder text to get an overall look and feel of your design. Right?
                                Placeholder text helps outline the visual elements of your design. The requirements change per your design – at times you may just need a line of text, and in some cases, you will need loads of paragraphs. But, where do you get these so-called ‘placeholder text’.
                            </div>
                        </div>
                    </div>
                </PanelBox>
            </div>
        </div>
    )
}

export default information;
