import axios, { axiosAuth } from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';
import { GUEST_TYPE_TOUR } from '../../../constant/General';
import { formatShortDate } from '../../../helper/GlobalFunc';
import { toast } from 'react-toastify';
import * as resHandler from '../../../helper/ResHandler';

export const contactInfoChanged = (value, identifier) => {
    return {
        type: 'SET_CONTACT_INFO_FORM',
        payload: {value, identifier}
    };
};

export const checkFormPerRoom = (index) => {
    return {
        type: 'CHECK_FORM_PER_ROOM',
        payload: { index }
    };
};

export const checkBookingForm = () => {
    return {
        type: 'CHECK_BOOKING_FORM'
    }
}

export const participantChanged = (value, roomIndex, guestType, guestIndex, identifier) => {
    return {
        type: 'SET_PARTICIPANT_FORM',
        payload: {value, roomIndex, guestType, guestIndex, identifier}
    };
};

export const setBookingDetail = (dataBooking) => {
    return {
        type: 'SET_BOOKING_DETAIL',
        payload: dataBooking
    }
}

export const successBooking = () => {
    return {
        type: 'SET_SUCCESS_BOOKING'
    }
}

export const setIsSubmitBookLoading = value => {
    return {
        type: 'SET_IS_SUMBIT_BOOK_LOADING',
        payload: { value }
    }
}

export const setIsInitLoading = value => {
    return {
        type: 'SET_IS_INIT_LOADING',
        payload: { value }
    }
}

export const setIsErrorInitBooking = value => {
    return {
        type: 'SET_IS_ERROR_INIT_BOOKING',
        payload: { value }
    }
}

export const setInitContactInfo = member => {
    return {
        type: 'SET_INIT_CONTACT_INFO',
        payload: { member }
    }
}

export const submitTour = (dataBooking, history) => {
    return dispatch => {
        axios.post(ANTAVAYA_API + 'v1/tour/checkout', dataBooking)
            .then((response) => {
                history.push('/tour/booking/' + response.data.Token)
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const submitBooking = (bookingCode, contactInfoFrom, participantForm) => {

    const contactInfoCp = {
        title: contactInfoFrom.title.value,
        firstName: contactInfoFrom.firstName.value,
        lastName: contactInfoFrom.lastName.value,
        phone: contactInfoFrom.dialCode.value.concat(contactInfoFrom.phone.value),
        email: contactInfoFrom.email.value,
    }

    const roomList = [];
    const guestType = GUEST_TYPE_TOUR;
    participantForm.forEach( guestPerRoom => {
        let roomData = {
            adult: [],
            child: [],
            isExtraBed: guestPerRoom.isExtraBed,
        }
        for (const key in guestType) {
            guestPerRoom[guestType[key]].forEach((guest, guestIndex) => {
                roomData[guestType[key]].push({
                    title: guest.title.value,
                    firstName : guest.firstName.value,
                    lastName : guest.lastName.value,
                    dateOfBirth : formatShortDate(new Date(guest.year.value+'-'+guest.month.value+'-'+guest.day.value)),
                    hasVisa : guest.isIncludeVisa.value,
                    passportNumber : guest.passportNumber.value,
                    passportCountryCode : guest.countryCode.value,
                    passportExpiredDate : formatShortDate(new Date(guest.yearPassport.value+'-'+guest.monthPassport.value+'-'+guest.dayPassport.value))
                });
            });
        }
        roomList.push(roomData);
    });

    const payload = {
        roomList: roomList,
        contactInfo: contactInfoCp,
    }

    let axiosSubmit = axios
    if (localStorage.getItem('sessionKey')) {
        axiosSubmit = axiosAuth;
    }
    return dispatch => {
        dispatch(setIsSubmitBookLoading(true));
        axiosSubmit.post(ANTAVAYA_API + `v1/tour/checkout/${bookingCode}`, payload)
        .then((response) => {
            if (response) {
                if (response.data.transactionNo) {
                    window.location = process.env.REACT_APP_PAYMENT_HOST + '?orderNo=' + response.data.transactionNo; 
                }
            }
            dispatch(setIsSubmitBookLoading(false));
        })
        .catch( error => {
            dispatch(setIsSubmitBookLoading(false));
            if (error && error.response) {
                switch (typeof error.response.data.message) {
                    case 'string':
                        toast.error(error.response.data.message);
                        console.log(error.response.data.message)
                        break;
                    case 'object':
                        for (const key in error.response.data.message) {
                            toast.error(error.response.data.message[key]);
                        }
                        break;
                    default:
                        break;
                }
            }
        })
    }
}

export const initBookingDetail = (token) => {
    return dispatch => {
        dispatch(setIsInitLoading(true));
        axios.get(ANTAVAYA_API + 'v1/tour/checkout/' + token)
        .then((response) => {
            dispatch(setBookingDetail(response.data))
            dispatch(setIsInitLoading(false));
        })
        .catch( error => {
            dispatch(setIsInitLoading(false));
            if (error) {
                    if (error.response.status === 400) {
                        dispatch(setIsErrorInitBooking(true));
                    }
                }
            });
    }
}

export const getDetailMember = () => {
    return dispatch => {
        axiosAuth.post(ANTAVAYA_API + 'member/detail')
            .then((response) => {
                dispatch(setInitContactInfo(response.data.data.member));
            })
            .catch((error) => {
                console.log('ERRRR', error);
                if (error.response && error.response.data.code === 401) {
                    resHandler.clearAuth();
                }
            })
    }
}