import React, { useState, useEffect } from 'react';
import classes from './BookingContainer.module.scss';
import Button from '../../../../components/UI/Button/Button';
import PackageInfo from '../../../../components/Mobile/Tour/Booking/PackageInfo/PackageInfo';
import ContactInfo from '../../../../components/Mobile/Tour/Booking/ContactInfo/ContactInfo';
import Spiner from '../../../../components/UI/Loaders/Spiner/Spiner';
import ParticipantList from '../../../../components/Mobile/Tour/Booking/ParticipantList/ParticipantList';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import { currencyFormat } from '../../../../helper/GlobalFunc';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';
import NotFound from '../../../../components/Mobile/Tour/NotFound/NotFound';
import Step from '../../../../components/Shared/Step/Step';

import { connect } from 'react-redux';
import * as tourBooking from '../../../../redux/Tour/Booking/BookingAction';

const bookingContainer = props => {
    const [guestData, setGuestData] = useState([]);
    const [isContinueConfirm, setIsContinueConfirm] = useState(false);
    
    useEffect(() => {
        let bookingCode = props.match.params.bookingCode;
        props.setIsErrorInitBooking(false);
        props.oninitBookingDetail(bookingCode);
        props.getDetailMember();
    }, [])

    const guestSelectChangedHandler = (event, roomIndex, guestType, guestIndex, inputIdentifier) => {
        let guestDataCp = [...guestData];
        let value = event;
        guestDataCp[roomIndex][guestType][guestIndex][inputIdentifier].value = value;
        setGuestData(guestDataCp);
    }

    const submitInquiryHandler = () => {
        if (props.isValidForm && props.isValidParticipantForm) {
            setIsContinueConfirm(true);
        } else {
            props.checkBookingForm();
        }
    }

    const submitData = () => {
        const bookingCode = props.match.params.bookingCode;
        props.onSubmitBooking(bookingCode, props.contactInfo, props.participantForm);
    }

    const backClickHandler = () => {
        props.history.goBack();
    }

    let roomList = null;
    if (props.participantForm && props.participantForm.length > 0) {
        roomList = props.participantForm.map((guestPerRoom, index) => {
            let warningRoom = null;
            if (!guestPerRoom.isValidRoom && guestPerRoom.errorMsg !== '') {
                warningRoom = guestPerRoom.errorMsg;
            }
            return (
                <div key={index} className="u-mb-16">
                    <ParticipantList
                        checkFormPerRoom={() => props.checkFormPerRoom(index)}
                        warningRoom={warningRoom}
                        roomIndex={index}
                        roomData={guestPerRoom}
                        guestInputChanged={(event, roomIndex, guestType, guestIndex, inputType) => props.participantChanged(event, roomIndex, guestType, guestIndex, inputType)}
                        guestSelectChanged={(event, roomIndex, guestType, guestIndex, identifier) => guestSelectChangedHandler(event, roomIndex, guestType, guestIndex, identifier)} />
                </div>
            );
        });
    }

    let content =  <div className={classes.Loading}><Spiner/></div>;
    if (!props.isInitLoading ) {
        if (props.isErrorInitBooking) {
            content = <NotFound clicked={() => props.history.push('/tour/availability?destination=all-region')}/>
        } else {
            let price = null;
            if (props.tourDetail) {
                price =  currencyFormat(props.tourDetail.tokenCheckoutPrice);
            }
            content = (
                <div>
                    <div className={classes.Content}>
                        <div className="u-mb-24">
                            <div className={classes.SubTitle}>Tour Detail</div>
                            <PackageInfo 
                                tourData={props.tourDetail}/>
                        </div>
                        <div className="u-mb-24">
                            <div className={classes.SubTitle}>Contact Information</div>
                            <ContactInfo 
                                contactInfo={props.contactInfo}
                                inputChanged={(event, type) => props.contactInfoChanged(event, type)}/>
                        </div>
                        <div>
                            <div className={classes.SubTitle}>Participants</div>
                            {roomList}
                        </div>
                    </div>

                    <div className={classes.Footer}>
                        <div className={classes.PriceWrapper}>
                            <div className={classes.Label}>Start From</div>
                            <div className={classes.Price}>
                                {price}
                                <span className={classes.Perpax}>/Pax</span>
                            </div>
                        </div>
                        <div className={classes.Button}>
                            <Button 
                                classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                                clicked={submitInquiryHandler}>
                                Continue to payment
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={classes.Wrapper}>
            <Confirm 
                show={isContinueConfirm}
                title="Continue to payment?"
                desc="Make sure your data is correct. You can’t change your booking details once you proceed to payment."
                cancelClicked={() => setIsContinueConfirm(false)}
                isConfirmLoading={props.isSubmitBookLoading}
                cancelLabel="No, check again"
                confirmLabel="Yes, continue"
                confirmClicked={submitData}/>

            <div className={classes.Header}>
                <Header 
                    classes={['Shadow']}
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    content="Tour Booking" />
            </div>
            <div className={classes.Step}>
                <Step step={1} />
            </div>
            {content}
        </div>
    );
    
}

const mapStateToProps = state => {
    return {
        isInitLoading: state.BookingReducer.isInitLoading,
        tourDetail: state.BookingReducer.tourDetail,
        isValidForm: state.BookingReducer.isValidForm,
        isValidParticipantForm: state.BookingReducer.isValidParticipantForm,
        contactInfo: state.BookingReducer.contactInfoForm,
        participantForm: state.BookingReducer.participantForm,
        isSubmitBookLoading: state.BookingReducer.isSubmitBookLoading,
        isErrorInitBooking: state.BookingReducer.isErrorInitBooking
    }
}

const mapDispatchToProps = dispatch => {
    return {
        oninitBookingDetail: (token) => dispatch(tourBooking.initBookingDetail(token)),
        onSubmitBooking: (bookingCode, contactInfoFrom, participantForm) => dispatch(tourBooking.submitBooking(bookingCode, contactInfoFrom, participantForm)),
        contactInfoChanged: (val, type) => dispatch(tourBooking.contactInfoChanged(val, type)),
        participantChanged: (val, roomIndex, guestType, guestIndex, identifier) => dispatch(tourBooking.participantChanged(val, roomIndex, guestType, guestIndex, identifier)),
        checkBookingForm: () => dispatch(tourBooking.checkBookingForm()),
        setIsErrorInitBooking: (val) => dispatch(tourBooking.setIsErrorInitBooking(val)),
        checkFormPerRoom: (index) => dispatch(tourBooking.checkFormPerRoom(index)),
        getDetailMember: () => dispatch(tourBooking.getDetailMember())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(bookingContainer);