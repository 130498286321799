import React from 'react';
import classes from './SuccessPage.module.scss';
import successIcon from '../../../../assets/images/ico-succes.png';
import Button from '../../../UI/Button/Button';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import CSSTransition from 'react-transition-group/CSSTransition';

const successPage = (props) => {
    return (
        <div>
            <Backdrop showBackdrop={props.showPage}/>
            <CSSTransition
                in={props.showPage}
                mountOnEnter
                unmountOnExit
                timeout={150}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
                }}>
                <div className={classes.Wrapper}>
                    <div className={classes.Alert}>
                        <div className={classes.Icon}>
                            <img src={successIcon} alt="Success" />
                        </div>
                        <div className={classes.Title}>Thank You!</div>
                        <div className={classes.Description}>Your request is submitted <br/>We will call you back within 24hrs.</div>
                        <div className={classes.Action}>
                            <div className={classes.SingleBtn}>
                                <Button 
                                    classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                                    clicked={props.confirmClicked}>OK</Button>
                            </div>
                        </div>
                    </div>
                </div>  
            </CSSTransition>
        </div>
    );
}

export default successPage;