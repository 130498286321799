export const ANTAVAYA = {
	type: 'Website',
	siteName: 'Antavaya.com',
	url: process.env.REACT_APP_APP_URL,
	domain: 'www.antavaya.com',
	emailCorporate: 'sales.corporate@antavaya.com',
	hotlineCorporate: '+6221 2922 7999',
	whatsappCorporate: '+62895 0171 8560',
	email: 'hello@antavaya.com',
	hotline: '+6221 6253 919',
	whatsapp: '+62817328838',
	whatsappUrl:
		'https://wa.me/62817328838?text=Saya%20ingin%20mengetahui%20lebih%20lanjut%20tentang%20Produk%20AntaVaya',
	addresses: [ 'Jl. Batu Tulis Raya no. 38, Jakarta 10120 - Indonesia' ],
	latitude: '-6.1635517',
	longitude: '106.8234407',
	locality: 'Jakarta',
	region: 'DKI Jakarta',
	postalCode: '10120',
	countryName: 'Indonesia',
	image: 'https://d1xc9yb0ryf4qf.cloudfront.net/web-assets/favicon.png',
	csHours: [ '08.00', '21.00' ],
	csDays: [ 'Mon', 'Sun' ]
}
