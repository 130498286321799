import React from 'react';
import classes from './Form.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import Button from '../../../../../UI/Button/Button';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import SelectFlag from '../../../../../UI/Inputs/SelectFlag/SelectFlag';
import TextArea from '../../../../../UI/Inputs/TextArea/TextArea';
import Select from '../../../../../UI/Inputs/Select/Select';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as BookingAction from '../../../../../../redux/Voucher/Booking/BookingAction';

const form = (props) => {

    const submitHandler = () => {
        if (props.isValidContactInfoForm) {
            props.isContinuePaymentHandler(true)
        } else {
            props.checkContactInfoForm()
        }
    }

    return (
        <PanelBox isOverflow={false}>
            <div className={classes.Wrapper}>
                <div className={classes.Title}>Contact Information</div>
                <div className="u-p-24">
                    <div className="u-display-flex u-mb-24">
                        <div className="u-mr-16 u-flex-3">
                            <div className="label">Title</div>
                            <Select 
                                placeholder="Title"
                                value={props.contactInfoForm.title.value}
                                options={props.contactInfoForm.title.options}
                                changed={(val) => props.changeContactInfoForm('title', val)} />
                        </div>
                        <div className="u-mr-16 u-flex-10">
                            <div className="label">First Name</div>
                            <TextField
                                placeholder="e.g. Julie"
                                value={props.contactInfoForm.firstName.value} 
                                changed={(event) => props.changeContactInfoForm('firstName', event.target.value)}
                                hasError={props.contactInfoForm.firstName.hasError && props.contactInfoForm.firstName.errorMsg !== ''}
                                errorMsg={props.contactInfoForm.firstName.errorMsg}/>
                        </div>
                        <div className="u-flex-10">
                            <div className="label">Last Name</div>
                            <TextField
                                placeholder="e.g. Applesed" 
                                value={props.contactInfoForm.lastName.value} 
                                changed={(event) => props.changeContactInfoForm('lastName', event.target.value)}
                                hasError={props.contactInfoForm.lastName.hasError && props.contactInfoForm.lastName.errorMsg !== ''}
                                errorMsg={props.contactInfoForm.lastName.errorMsg}/>
                        </div>
                    </div>
                    <div className="u-display-flex u-mb-16">
                        <div className="u-mr-16 u-flex-10">
                            <div className="label">Phone Number</div>
                            <div className="u-display-flex">
                                <div className="u-flex-4 u-mr-8">
                                    <SelectFlag 
                                        value={props.contactInfoForm.dialCode.value}
                                        changed={val => props.changeContactInfoForm('dialCode', val)}/>
                                </div>
                                <div className="u-flex-6">
                                    <TextField
                                        type="text"
                                        placeholder="e.g. 8123456789" 
                                        value={props.contactInfoForm.phone.value} 
                                        changed={(event) => props.changeContactInfoForm('phone', event.target.value)}
                                        hasError={props.contactInfoForm.phone.hasError && props.contactInfoForm.phone.errorMsg !== ''}
                                        errorMsg={props.contactInfoForm.phone.errorMsg}/>
                                </div>
                            </div>
                        </div> 
                        <div className="u-flex-10">
                            <div className="label">E-mail Address</div>
                            <TextField 
                                placeholder="e.g. youremail@example.com" 
                                value={props.contactInfoForm.email.value} 
                                changed={(event) => props.changeContactInfoForm('email', event.target.value)}
                                hasError={props.contactInfoForm.email.hasError && props.contactInfoForm.email.errorMsg !== ''}
                                errorMsg={props.contactInfoForm.email.errorMsg}/>
                        </div> 
                    </div>
                    <div className="u-flex-16 u-mb-16">
                        <div className="label">Have a Question?</div>
                        <TextArea
                            placeholder="Write here"
                            value={props.inquiryForm.question.value}
                            changed={(event) => props.changeInquiryForm('question', event.target.value)}
                            hasError={props.inquiryForm.question.hasError && props.inquiryForm.question.errorMsg !== ''}
                            errorMsg={props.inquiryForm.question.errorMsg}
                            />
                    </div>

                    <div className="u-display-flex u-justify-content-end">
                        <div className="u-flex-4 u-mt-8 u-mr-16">
                            <Button 
                                classes={['BtnOutlineRed', 'BtnLarge', 'BtnBlock']}
                                clicked={() => props.history.push('/voucher/availability')}>
                                Cancel
                            </Button>
                        </div>
                        <div className="u-flex-4 u-mt-8">
                            <Button 
                                isLoading={props.isSubmitBooking}
                                classes={['BtnRed', 'BtnLarge', 'BtnBlock']}
                                clicked={() => submitHandler()}>
                                Submit Inquiry
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </PanelBox>
    )
}


const mapstateToProps = (state) => {
    return {
        contactInfoForm: state.VoucherBookingReducer.contactInfoForm,
        inquiryForm: state.VoucherBookingReducer.inquiryForm,
        isValidContactInfoForm: state.VoucherBookingReducer.isValidContactInfoForm,
        isSubmitBooking: state.VoucherBookingReducer.isSubmitBooking,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeContactInfoForm: (identifier, value) => dispatch(BookingAction.changeContactInfoForm(identifier, value)),
        changeInquiryForm: (identifier, value) => dispatch(BookingAction.changeInquiryForm(identifier, value)),
        checkContactInfoForm: () => dispatch(BookingAction.checkContactInfoForm()),
        submitInquiry: (hotelId, contactInfoFrom, inquiryForm) => dispatch(BookingAction.submitInquiry(hotelId, contactInfoFrom, inquiryForm))
    }
}

export default connect(mapstateToProps, mapDispatchToProps)(withRouter(form));