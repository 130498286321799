import React, { useEffect } from 'react';
import classes from './EditEmail.module.scss';
import Popup from '../../../../../components/UI/Popup/Popup';
import TextField from '../../../../../components/UI/Inputs/TextField/TextField';
import Button from '../../../../../components/UI/Button/Button';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../redux/Shared/Member/MemberAction';

function editEmail (props) {
    useEffect(() => {
        props.hideSortPopupClicked()
    }, [props.successMemberChanged])

    let updateEmail = () => {
        let payload = {
            email: props.formEmail.email.value
        }
        props.submitChangedEmailHandler(payload);
    }
    let content = (
        <div>
            <div className={classes.InputWrapper}>
                <div className={classes.Label}>Email</div>
                <TextField
                    placeholder="e.g. john@sample.com" 
                    value={props.formEmail.email.value}
                    changed={(event) => props.inputChangedEmailHandler(event, 'email')}/>
            </div>
            <div className={classes.InputWrapper}>
                <Button
                    classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
                    isLoading={props.isSubmitLoading}
                    clicked={() => updateEmail()}>Save
                </Button>
            </div>
            
        </div>
    )

    return (
        <Popup
            show={props.show}
            hideClicked={props.hideSortPopupClicked}
            title="Change Email"
            content={content}/>
    )
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        formEmail: state.MemberReducer.formEmail,
        successMemberChanged: state.MemberReducer.successMemberChanged,
        isSubmitLoading: state.MemberReducer.isSubmitLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChangedEmailHandler: (event, inputIdentifier) => dispatch(memberAction.inputChangedEmail(event, inputIdentifier)),
        submitChangedEmailHandler: (data) => dispatch(memberAction.submitChangedMember(data, 'emailForm'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(editEmail);
