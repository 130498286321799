import React, { useState, useEffect } from 'react';
import classes from './BookingContainer.module.scss';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import Step from '../../../../components/Shared/Step/Step';
import ContactInfo from '../../../../components/Mobile/VirtualTour/Booking/ContactInfo/ContactInfo';
import Button from '../../../../components/UI/Button/Button';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';
import PackageInfo from '../../../../components/Mobile/VirtualTour/Booking/PackageInfo/PackageInfo';
import { withRouter } from 'react-router-dom';
import SuccessBook from '../../../../components/Mobile/Hotel/Booking/SuccessBook/SuccessBook';

import { connect } from 'react-redux';
import * as BookingAction from '../../../../redux/VirtualTour/Booking/VirtualTourBookingAction';

const bookingContainer = (props) => {
    const [isContinueConfirm, setIsContinueConfirm] = useState(false);

    let step = 1;
    
    useEffect(() => {
        let slug = props.match.params.slug;
        props.fetchVirtualTourDetail(slug);
    }, [])

    useEffect(() => {
        if (props.isSuccessSubmit) {
            setIsContinueConfirm(false)
        }
    }, [props.isSuccessSubmit])

	const backClickHandler = () => {
		window.history.back();
    };

	const submitInquiryHandler = () => {
		if (props.isValidForm) {
			setIsContinueConfirm(true);
		} else {
            props.formChecking();
		}
    };
    
	const submitData = () => {
        let data = {
            virtualTourID: props.virtualTourDetail.ID,
            title: props.formData.title.value,
            firstName: props.formData.firstName.value,
            lastName: props.formData.lastName.value,
            phoneNumber: props.formData.dialCode.value + props.formData.phone.value,
            email: props.formData.email.value,
            zoomID: '-',
            question: '-'
        }
        props.formSubmited(data)
    };

    let content = (
        <React.Fragment>
            <div className={classes.Content}>
                <div className="u-mb-20">
                    <div className={classes.SubTitle}>Virtual Tour Detail</div>
                    <PackageInfo virtualTourDetail={props.virtualTourDetail} />
                </div>
            </div>
            
            <div className={classes.Content}>
                <div className="u-mb-20">
                    <div className={classes.SubTitle}>Contact Information</div>
                    <ContactInfo
                        formData={props.formData}
                        inputChanged={(identifier, value) => props.inputChanged(identifier, value)}/>
                </div>
            </div>
            
            <div className={classes.WrapperButton}>
                <Button
                    isLoading={props.isLoadingSubmitForm}
                    classes={["BtnMedium", "BtnBlock", "BtnRed"]}
                    clicked={submitInquiryHandler}>
                    Submit Inquiry
                </Button>
            </div>
        </React.Fragment>
    );

    if (props.isSuccessSubmit) {
        content = <SuccessBook clicked={() => props.history.push("/")} />
    }

    return (
        <div className={classes.Wrapper}>
            
            <Confirm
                show={isContinueConfirm}
                title="Are you sure?"
                desc="Make sure your data is correct."
                cancelClicked={() => setIsContinueConfirm(false)}
                isConfirmLoading={props.isSubmitBooking}
                cancelLabel="No, check again"
                confirmLabel="Yes, continue"
                confirmClicked={submitData}/>
            
            <div className={classes.HeaderWrapper}>
                <Header
					leftIcon="back"
					leftClicked={backClickHandler}
					content="Virtual Tour Booking"/>
            </div>
            
            <div className={classes.Step}>
                <Step type="inquiry" step={step} />
            </div>
            
            {content}
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        formData: state.VirtualTourBookingReducer.formData,
        isValidForm: state.VirtualTourBookingReducer.isValidForm,
        virtualTourDetail: state.VirtualTourDetailReducer.virtualTourDetail,
        isSuccessSubmit: state.VirtualTourBookingReducer.isSuccessSubmit,
        isLoadingSubmitForm: state.VirtualTourBookingReducer.isLoadingSubmitForm,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChanged: (identifier, value) => dispatch(BookingAction.inputChanged(identifier, value)),
        formChecking: (identifier, value) => dispatch(BookingAction.formChecking(identifier, value)),
        formSubmited: (data) => dispatch(BookingAction.formSubmited(data)),
        fetchVirtualTourDetail: (slug) => dispatch(BookingAction.fetchVirtualTourDetail(slug))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(bookingContainer));
