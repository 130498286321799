import React, { Component } from 'react';
import classes from './SelectFlag.module.scss';
import Icon from '../../Icon/Icon';
import onClickOutside from "react-onclickoutside";
import CSSTransition from 'react-transition-group/CSSTransition';
import dialCodes from '../../../../assets/jsons/dialCode.json';
import { IMAGE_URl } from '../../../../constant/ResourceUrl';
import TextField from '../../../UI/Inputs/TextField/TextField';

class SelectFlag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showList: false,
            filter: '',
        };
    }

    showListHandler = () => {
        this.setState({showList: true});
    }

    hideListHandler = () => {
        this.setState({showList: false});
    }

    handleClickOutside = (evt) => {
        this.hideListHandler();
    }

    getTextHandler = (val, options) => {
         
        let index = options.findIndex(option => {
            let code = option.dial_code;
            if (this.props.type === 'country') {
                code = option.code;
            }
            return code === val;
        });
        let text = '';
        if (index >= 0) {
            if (this.props.type === 'country') {
                text = (
                    <div className={classes.Value}>
                        <div className={classes.Label}>{options[index].code}</div>
                    </div>
                );
            } else {
                text = (
                    <div className={classes.Value}>
                        <img src={IMAGE_URl + 'flags/' + options[index].code + '.png'} alt={options[index].name} />
                        <div className={classes.Label}>{options[index].dial_code}</div>
                    </div>
                );
            }
        }
        return text;
    }

    setValueHandler = (val) => {
        this.hideListHandler();
        this.props.changed(val);
    }

    filterChangeHandler = (e) => {
        this.setState({filter: e.target.value});
    }


    render() {
        let iconClasses = [classes.Icon];
        let selectClasses = [classes.Select];
        if (this.state.showList) {
            iconClasses.push(classes.IsActive);
            selectClasses.push(classes.Focus);
        }

        let text = '';
        if (this.props.value) {
            text = this.getTextHandler(this.props.value, dialCodes);
        }

        let options = null;
        if (dialCodes && dialCodes.length > 0) {
            options = dialCodes.filter(dialCode => {
                return (this.state.filter === '') || dialCode.dial_code.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1 || dialCode.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1;
            }).map((dialCode, index) => {
                let code = dialCode.dial_code;
                if (this.props.type === 'country') {
                    code = dialCode.code;
                }
                return (
                    <li key={index} className={classes.Item} onClick={() => this.setValueHandler(code)}>
                        <img src={IMAGE_URl + 'flags/' + dialCode.code + '.png'} alt={dialCode.name} />
                        <div className={classes.Label}>{dialCode.name} ({code})</div>
                    </li>
                );
            });
        }


        return (
            <div className={classes.Wrapper}>
                <div className={classes.Select} onClick={this.showListHandler}>
                    <div className={classes.Input}>
                        <div className={selectClasses.join(' ')}>
                            {text}
                        </div>
                    </div>
                    <div className={iconClasses.join(' ')}>
                        <Icon name="ico-caret-down" fill="#202124" stroke="none" />
                    </div>
                </div>
                <CSSTransition
                    in={this.state.showList}
                    mountOnEnter
                    unmountOnExit
                    timeout={270}
                    classNames={{
                        enterActive: classes.Open,
                        exitActive: classes.Close
                    }}>
                    <div className={classes.ListWrapper}>
                        <div className={classes.Filter}>
                            <TextField
                                classes={['TfSmall']}
                                value={this.state.filter}
                                changed={this.filterChangeHandler}/>
                        </div>
                        <ul className={classes.List}>
                            {(options && options.length > 0) ? options: <li className={classes.NotFound}>Not Found</li>}
                        </ul>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default onClickOutside(SelectFlag);