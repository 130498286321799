import React, { useState } from 'react';
import classes from './PackageInfo.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import { toCapitalize, pluralize } from '../../../../../helper/GlobalFunc';
import Icon from '../../../../UI/Icon/Icon';
import Moment from 'react-moment';
import CSSTransition from 'react-transition-group/CSSTransition';
import ImgThumb from '../../../../UI/ImgThumb/ImgThumb';

const packageInfo = props => {
    const [isActive, setIsActive] = useState(false);

    const detailToggleHandler = () => {
        setIsActive(!isActive);
    }

    let arrowClasses = [classes.Arrow];
    if (isActive) {
        arrowClasses = [classes.Arrow, classes.Active];
    }
    let imgUrl = null;
    if (props.tourData && props.tourData.defaultImage) {
        imgUrl = props.tourData.defaultImage.url;
    }

    let room = 0;
    let adult = 0;
    let child = 0;

    if (props.tourData && props.tourData.room) {
        room = props.tourData.room.length;
            for (const index in props.tourData.room) {
                if (props.tourData.room[index].adult) {
                    adult += props.tourData.room[index].adult;
                }
                if (props.tourData.room[index].child) {
                    child += props.tourData.room[index].child;
                }
            }
    }

    let content = null;
    if (props.tourData) {
        content = (
            <React.Fragment>
                <div className={classes.Header} onClick={detailToggleHandler}>
                    <div className="u-mr-8">
                        <ImgThumb src={imgUrl} size="small" />
                    </div>
                    <div className={classes.Info}>
                        <div className={classes.Title}>{toCapitalize(props.tourData.tourTitle)}</div>
                        <div className={classes.Desc}>{props.tourData.days} Days & {props.tourData.night} Nights</div>
                    </div>
                    <div className={arrowClasses.join(' ')}>
                        <Icon name="ico-caret-down" fill="#202124;" stroke="none"  />
                    </div>
                </div>

                <CSSTransition
                    in={isActive}
                    mountOnEnter
                    unmountOnExit
                    timeout={270}
                    classNames={{
                        enterActive: classes.Open,
                        exitActive: classes.Close
                    }}>
                    <div className={classes.Body}>
                        <div className={classes.Item}>
                            <Icon name="ico-flight-o" fill="#6A6E73" stroke="none" />
                            <span className={classes.Label}>{props.tourData.airlineName}</span>
                        </div>
                        <div className={classes.Item}>
                            <Icon name="ico-user-o" fill="#6A6E73" stroke="none" />
                            <span className={classes.Label}>{pluralize(room, 'Room', 'Rooms')} - {pluralize(adult, 'Adult', 'Adults')} - {pluralize(child, 'Child', 'Childs')}</span>
                        </div>
                        <div className={classes.Item}>
                            <Icon name="ico-calendar" fill="#6A6E73" stroke="none" />
                            <span className={classes.Label}>
                                <Moment format="ddd, DD MMM YYYY">
                                    {props.tourData.startDate}
                                </Moment>
                                <span> - </span>
                                <Moment format="ddd, DD MMM YYYY">
                                    {props.tourData.endDate}
                                </Moment>
                            </span>
                        </div>
                    </div>
                </CSSTransition>
            </React.Fragment>
        )
    }
    
    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                {content}
            </div>
        </PanelBox>
    );
}

export default packageInfo;