import React, { useEffect } from 'react';
import classes from './AboutVoucher.module.scss';
import List from '../../../../../UI/List/List';
import CSSTransition from 'react-transition-group/CSSTransition';
import Header from '../../../../Shared/Header/Header';
import { overflowBody } from '../../../../../../helper/GlobalFunc';
import { ABOUT_FREE_VOUCHER } from '../../../../../../constant/Content';


const aboutVoucher = props => {
    useEffect(() => {
        overflowBody(props.show);
    }, [props.show]);
    
    return (
        <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={270}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close 
            }}>
            <div className={classes.Wrapper}>
                <div className={classes.Header}>
                    <Header 
                        classes={['Shadow']}
                        leftIcon="close"
                        leftClicked={props.hidePopupClicked}
                        content="About Free Voucher" />
                </div>
                <div className={classes.Content}>
                    <div className={classes.Item}>
                        <div className={classes.Title}>Terms & Conditions Free Voucher</div>
                        <List listSize="small" list={ABOUT_FREE_VOUCHER}/>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}

export default aboutVoucher;