import { updateObject } from "../../utility";
import * as validation from '../../../helper/Validation';
import { DEFAULT_TITLE, ADULT_TITLE, DEFAULT_DIAL_CODE } from '../../../constant/General';

const contactInfoFormInit = {
    title: {
        value: DEFAULT_TITLE,
        options: ADULT_TITLE,
        hasError: false,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    firstName: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    lastName: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    email: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true,
            isEmail: true
        }
    },
    dialCode: {
        value: DEFAULT_DIAL_CODE
    },
    phone: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true,
            isNumericPhone: true,
            minLength: 7,
        }
    },
    question: {
        value: '',
        hasError: false,
        errorMsg: '',
        rules: {}
    }
}

const initialState = {
    contactInfoForm: contactInfoFormInit,
    isValidForm: false,
    isSuccessBooking: false,
}

const setContactInfo = (state, action) => {
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let contactInfoForm = {...state.contactInfoForm};

    let validate = validation.validate(contactInfoForm[identifier].rules, value);
    contactInfoForm[identifier] = {...contactInfoForm[identifier], ...validate};

    let isValid = validation.isValidForm(contactInfoForm);

    return updateObject(state, {
        contactInfoForm,
        isValid,
    })
}

const checkBookingForm = (state, action) => {
    let contactInfoForm = {...state.contactInfoForm};
    for (const key in contactInfoForm) {
        let validate = validation.validate(contactInfoForm[key].rules, contactInfoForm[key].value);
        contactInfoForm[key] = {...contactInfoForm[key], ...validate}
    }

    return updateObject(state, { contactInfoForm })
}

const setIsSuccessBooking = (state, action) => {
    return updateObject(state, {isSuccessBooking: action.payload.value});
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case 'SET_CONTACT_INFO_FORM_ADMISSION': return setContactInfo(state, action);
        case 'CHECK_BOOKING_FORM': return checkBookingForm(state, action);
        case 'SET_IS_SUCCESS_BOOKING': return setIsSuccessBooking(state, action); 
        default: return state;
    }
}

export default reducer;