import React from 'react';
import classes from './Room.module.scss';
import CheckBox from '../../../../../../UI/Inputs/CheckBox/CheckBox';
import PlusMinusButton from '../../../../../../UI/PlusMinusButton/PlusMinusButton';

const room = (props) => {
    let closeBtn = null;
    if (props.isCloseBtn) {
        closeBtn = (
            <div onClick={props.removeRoomClicked} className={classes.Delete}>Delete</div>
        );
    }
    let totalAdult = props.roomData.adult.length;
    let totalChild = props.roomData.child.length;
    let totalGuest = totalAdult + totalChild;
    let loading = null;
    if (props.roomData.isChangeLoading) {
        loading = <div className={classes.Loading}>
            <div className={classes.Loader}></div>
        </div>
    }

    return (
        <div className={classes.Wrapper}>
            {loading}
            <div className={classes.Header}>
                <div className={classes.Title}>Room {props.index + 1}</div>
                {closeBtn}
            </div>
            <ul className={classes.List}>
                <li className={classes.Item}>
                    <div>
                        <div className={classes.RoomType}>Adults</div>
                        <div className={classes.Desc}>(12 years+)</div>
                    </div>
                    <div className={classes.Action}>
                        <PlusMinusButton isPlus={false} disabled={(totalAdult <= 1) ? true : false} clicked={() => props.removeGuestClicked('adult')} />
                        <div className={classes.Value}>{props.roomData.adult.length}</div>
                        <PlusMinusButton isPlus={true} disabled={(totalGuest >= 3) ? true : false} clicked={() => props.addGuestClicked('adult')}/>
                    </div>
                </li>
                <li className={classes.Item}>
                    <div>
                        <div className={classes.RoomType}>Children</div>
                        <div className={classes.Desc}>(2 - 12 years)</div>
                    </div>
                    <div className={classes.Action}>
                        <PlusMinusButton isPlus={false} disabled={(totalChild <= 0) ? true : false} clicked={() => props.removeGuestClicked('child')} />
                        <div className={classes.Value}>{props.roomData.child.length}</div>
                        <PlusMinusButton isPlus={true} disabled={(totalGuest >= 3) ? true : false} clicked={() => props.addGuestClicked('child')}/>
                    </div>
                </li>
                <li className={classes.Item}>
                    <CheckBox 
                        label="Extra Bed"
                        checked={props.roomData.isExtrabed}
                        changed={props.toggleExtraBed} />
                </li>
            </ul>
        </div>
    );
}

export default room;