import React, { useEffect, useState } from 'react';
import classes from './DetailContainer.module.scss';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import queryString from 'query-string';
import history   from '../../../../history';
import Icon from '../../../../components/UI/Icon/Icon';
import DetailPanel from '../../../../components/Mobile/VirtualTour/Detail/DetailPanel/DetailPanel';
import Button from '../../../../components/UI/Button/Button';
import CurrencyFormat from 'react-currency-format';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import AboutVoucher from '../../../../components/Mobile/VirtualTour/Detail/AboutVoucher/AboutVoucher';
import ContentLoader from 'react-content-loader';
import { formatDisplayDate, formatDisplayTime } from '../../../../helper/GlobalFunc';
import WhatsappLink from '../../../../components/Mobile/Shared/WhatsappLink/WhatsappLink';

import { connect } from 'react-redux';
import * as DetailAction from '../../../../redux/VirtualTour/Detail/DetailAction';

const detailContainer = (props) => {
    const [isSecondHeader, setIsSecondHeader] = useState(false);
    const [showAboutVoucher, setShowAboutVoucher] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', handleScroll);
        
        props.fetchVirtualTourDetail(props.match.params.slug)
    }, [])

    const handleScroll = () => {
        const offsetTop = window.scrollY;
        if (offsetTop > 215) {
            setIsSecondHeader(true)
        } else {
            setIsSecondHeader(false);
        }
    }

    const backClickHandler = () => {
        if (props.history.action === 'PUSH') {
            const values = queryString.parse(props.location.search);
            if (values.page === 'booking') {
                props.history.push('/');
            } else {
                history.go(-1);
            }
        } else {
            props.history.push('/');
        }
    }

    let secondHeaderClasses = [classes.Nav, classes.SecondNav];
    if (isSecondHeader) {
        secondHeaderClasses.push(classes.Show);
    }

    let content = null;
    let whatsappUrl = null;
    
    if (props.isFetchVirtualTourDetail) {
        content = <Loader />;
    } else {
        if (props.virtualTourDetail) {
            let virtualTourName = ((props.virtualTourDetail.name).split('&').join('')).split(' ').join('%20');
            whatsappUrl = `https://wa.me/62${(props.virtualTourDetail.customerServiceData.phoneNumber).slice(1)}?text=Saya%20ingin%20mengetahui%20lebih%20lanjut%20tentang%20virtual%20tour%20${virtualTourName}`;

            content = (
                <div className={classes.Content}>
                    <div className={classes.Image}>
                        <img src={props.virtualTourDetail.imageSource} alt="virtual-tour" />
                    </div>
                    <div className={classes.Title}>{props.virtualTourDetail.name}</div>
                    <div className={classes.TimeAndDuration}>
                        <div className={classes.Item}>
                            <div className={classes.Label}>Date and Time</div>
                            <div className={classes.Detail}>
                                <div className={classes.Icon}>
                                    <Icon name="ico-calendar" fill="#666666" stroke="none" width={22} />
                                </div>
                                {formatDisplayDate(props.virtualTourDetail.liveDate)} {'  ' + formatDisplayTime(props.virtualTourDetail.liveDate)}
                            </div>
                        </div>
                        <div className={[classes.Item, "u-ml-15"].join(' ')}>
                            <div className={classes.Label}>Duration</div>
                            <div className={classes.Detail}>
                                <div className={classes.Icon}>
                                    <Icon name="ico-time" fill="#666666" stroke="none" width={22} />
                                </div>
                                {props.virtualTourDetail.duration + ' minutes'}
                            </div>
                        </div>
                    </div>

                    <div className="u-p-16">
                        <DetailPanel title="Description" isCollapseBtn={true} showMore={true} data={props.virtualTourDetail.remark}/>
                    </div>

                    <div className={classes.SplitSpace}>
                        <div className={classes.FreeVoucherSpace}>
                            <PanelBox>
                                <div className={classes.Card} onClick={() => setShowAboutVoucher(true)}>
                                    About Free Voucher
                                    <Icon name="ico-chev-right" fill="#202124" stroke="none" />
                                </div>
                            </PanelBox>
                        </div>
                        <div className={classes.WhatsappSpace}>
                            {/* <a href={whatsappLink} target="blank" className={classes.Button}>
                                <Icon name="ico-whatsapp" stroke="none" fill="#ffffff" />
                            </a> */}
                        </div>
                    </div>

                    <div className={classes.FooterWrapper}>
                        <div className={classes.PriceWrapper}>
                            <div className={classes.Label}>Price</div>
                            <div className={classes.Price}>
                                <CurrencyFormat value={props.virtualTourDetail.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            </div>
                        </div>
                        <div className={classes.Button}>
                            <Button 
                                classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                                clicked={() => props.history.push('/virtual-tour/booking/'+ props.match.params.slug)}>
                                Book Now
                            </Button>
                        </div>
                    </div>

                </div>
            )
        }
    }

    return (
        <div onScroll={handleScroll}>
            <div className={classes.Wrapper}>
                <AboutVoucher
                    show={showAboutVoucher}
                    hidePopupClicked={() => setShowAboutVoucher(false)} />
                <div className={classes.Nav}>
                    <Header 
                        classes={['BgTransparent']}
                        icoColor="white"
                        leftIcon="back"
                        leftClicked={backClickHandler} />
                </div>
                <div className={secondHeaderClasses.join(' ')}>
                    <Header
                        classes={['Shadow']}
                        leftIcon="back"
                        leftClicked={backClickHandler}
                        content={'Yokoso Japan Ameya Yokocho & Akihabara'} />
                </div>
                {content}
                <WhatsappLink whatsappLink={whatsappUrl}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetchVirtualTourDetail: state.VirtualTourDetailReducer.isFetchVirtualTourDetail,
        virtualTourDetail: state.VirtualTourDetailReducer.virtualTourDetail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVirtualTourDetail: (slug) => dispatch(DetailAction.fetchVirtualTourDetail(slug))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(detailContainer);

const Loader = () => {
    return (
        <div className={classes.Content}>
            <div className={classes.Image}>
                <ContentLoader height={270} backgroundcolor={'#333'}/>
            </div>
            <div className="u-p-16">
                <ContentLoader height={800} backgroundcolor={'#333'}>
                    <rect x="0" y="0" rx="5" ry="5" width="270" height="20" />
                    <rect x="0" y="32" rx="5" ry="5" width="100" height="20" />

                    <rect x="0" y="80" rx="5" ry="5" width="95" height="12" />
                    <rect x="0" y="100" rx="5" ry="5" width="130" height="18" />
                    <rect x="190" y="80" rx="5" ry="5" width="95" height="12" />
                    <rect x="190" y="100" rx="5" ry="5" width="130" height="18" />

                    <rect x="0" y="150" rx="5" ry="5" width="130" height="18" />
                    <rect x="0" y="185" rx="5" ry="5" width="100%" height="180" />

                    <rect x="0" y="400" rx="5" ry="5" width="100%" height="50" />
                </ContentLoader>
            </div>
        </div>
    )
}