import React, { useEffect, useRef } from 'react';
import classes from './PopupPage.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';

function popupPage(props) {
    const headerRef = useRef(null);
    useEffect(() => {
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [props.show])

    let header = null;
    if (props.header) {
        header = <div className={classes.Header} ref={headerRef}>
            {props.header}
        </div>;
    }

    let content = null;
    if (props.content) {
        content = <div className={classes.Content}>
            {props.content}
        </div>;
    }

    let footer = null;
    if (props.footer) {
        footer = <div className={classes.Footer}>
            {props.footer}
        </div>;
    }
    return (
        <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={300}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Closed
            }}>
            <div className={classes.Wrapper}>
                {header}
                {content}
                {footer}
            </div>
        </CSSTransition>
    );
}


export default popupPage;