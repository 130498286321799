import React, { Component } from 'react';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import BannerAvailability from '../../../../components/Desktop/Voucher/BannerAvailability/BannerAvailability';
import ContentAvailability from '../../../../components/Desktop/Voucher/ContentAvailability/ContentAvailability';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import MetaTag from '../../../../helper/MetaTag';

class VoucherAvailabilityContainer extends Component {

    scrollTop = () =>{
        window.scrollTo(0, 0);
    }

    render() {
        this.scrollTop();
        return (
            <div className="bg-white-dark">
                <MetaTag 
                    title="Voucher Availability"/>
                <Header />
                <BannerAvailability />
                <ContentAvailability />
                <Footer />
            </div>
        );
    }
}

export default VoucherAvailabilityContainer;