import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const clearAuth = () => {
    cookies.remove('token', { path: '/' });
    localStorage.removeItem('sessionKey');
    localStorage.removeItem('userProfile');
    let recentPath = window.location.pathname;
    sessionStorage.setItem('recentPath', recentPath);
}

export const unauthorize = () => {
    // cookies.remove('token', { path: '/' });
    // localStorage.removeItem('sessionKey');
    // localStorage.removeItem('userProfile');
    // let recentPath = window.location.pathname;
    // sessionStorage.setItem('recentPath', recentPath);
    clearAuth();
    window.location.href = '/';
}

