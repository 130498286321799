import React, { useState, useEffect } from 'react'
import classes from './ImageDetail.module.scss'
import Icon from '../../../../UI/Icon/Icon'
import CSSTransition from 'react-transition-group/CSSTransition'
import Slider from 'react-slick'
import ImageItem from '../ImageSlider/ImageItem/ImageItem'
import ImageNav from './ImageNav/ImageNav'

const ImageDetail = props => {
	// props.imageSet[0].tourImageTitle
	const [photoName, setPhotoName] = useState('xx');
	const [photoIndex, setPhotoIndex] = useState(1);

	useEffect( () => {
        if (props.show) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }

	}, [props.show]); 

	const click = (photo, index) => {
		setPhotoName(photo.tourImageTitle);
		setPhotoIndex(index + 1);
	}

	let imageList = [];
	if (props.imageSet && props.imageSet.length > 0) {
		imageList = props.imageSet.map((photo, index) => {
			return (
				<div key={index}>
					<ImageItem photo={photo} />
				</div>
			)
		})
	}

	let imageNav = [];
	if (props.imageSet && props.imageSet.length > 0) {
		imageNav = props.imageSet.map((photo, index) => {
			return (
				<div key={index}>
					<ImageNav clicked={() => click(photo, index)} photo={photo} />
				</div>
			)
		})
	}

	const settings = {
		customPaging: function (i) {
			return <div>{imageNav[i]}</div>
		},
		dots: true,
		dotsClass: 'slick-dots gallery-nav ' + classes.DotWrapper,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		draggable: false,
		arrows: false,
		swipe: false
	}

	return (
		<CSSTransition
			in={props.show}
			mountOnEnter
			unmountOnExit
			timeout={270}
			classNames={{
				enterActive: classes.Open,
				exitActive: classes.Closed
			}}>
			<div className={classes.Wrapper}>
				<div className={classes.Header}>
					<div
						className={classes.BackBtn}
						onClick={props.hidePopupClicked}>
						<Icon name='ico-arrow-left' fill='#ffffff' stroke='none' />
					</div>
				</div>
				<div className={classes.Content}>
					<Slider {...settings}>{imageList}</Slider>
					<div className={classes.Destination}>
						<p className={classes.Number}>
							{photoIndex} of {imageNav.length}
						</p>
						<p className={classes.Name}>{photoName}</p>
					</div>
				</div>
			</div>
		</CSSTransition>
	)
}

export default ImageDetail
