import React, { useState, useEffect } from 'react';
import classes from './AvailabilityContainer.module.scss';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import Slider from 'react-slick';
import Availability from '../../../../components/Mobile/ThingsToDo/Availability/Availability';
import SortFilterBtn from '../../../../components/Mobile/ThingsToDo/SortFilter/Button/Button';
import Sort from '../../../../components/Mobile/ThingsToDo/SortFilter/Sort/Sort';
import Filter from '../../../../components/Mobile/ThingsToDo/SortFilter/Filter/Filter';
import { TOUR_PRICE_FILTER_LIST, TOUR_DURATION_FILTER_LIST } from '../../../../constant/FilterList';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../redux/Admission/Availability/AvailabilityAction';

const availabilityContainer = (props) => {
    const [showSortPopup, setShowSortPopup] = useState(false);
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [priceFilterList, setPriceFilterList] = useState([]);
    const [contentAvailability, setContentAvailability] = useState('all');
    const [categoryFilterList, setCategoryFilterList] = useState([]);
    const [durationFilterList, setDurationFilterList] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setFilterListHandler();
        props.initAdmissionExperiencePackages()
    }, [])

    const setFilterListHandler = () => {
        setPriceFilterListHandler();
        setDurationFilterListHandler();
        setCategoryFilterListHandler();
    }

    const setDurationFilterListHandler = () => {
        const durationFilterList = TOUR_DURATION_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });

        setDurationFilterList(durationFilterList);
    }

    const setCategoryFilterListHandler = () => {
        const categoryFilterList = props.categoryFilters.map( item => {
            return {...item, isChecked: false};
        });

        setCategoryFilterList(categoryFilterList);
    }

    const setPriceFilterListHandler = () => {
        const priceFilterList = TOUR_PRICE_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });

        setPriceFilterList(priceFilterList);
    }

    const backClickHandler = () => {
        window.history.back();
    }

    const searchClickHandler = () => {
        props.history.push('/things-to-do');
    }

    const setFilterClickHandler = (priceFilterList, durationFilterList, categoryFilterList) => {
        setPriceFilterList(priceFilterList);
        setDurationFilterList(durationFilterList);
        setCategoryFilterList(categoryFilterList);
    }

    const packagesFilter = (packages) => {
        const priceFiltersCp = priceFilterList.filter(filter => {
            return filter.isChecked;
        })
        const durationFilters = durationFilterList.filter( filter => {
            return filter.isChecked;
        });
        const categoryFilters = categoryFilterList.filter( filter => {
            return filter.isChecked;
        });

        let packagesCp = packages;
        if (priceFiltersCp.length > 0) {
            packagesCp = packages.filter((item) => {
                let isValid = false;
                for (const index in priceFiltersCp) {
                    let limit = priceFiltersCp[index].value.split(':');
                    let min = parseInt(limit[0]);
                    let max = parseInt(limit[1]);
                    if (min <= item.priceStartFrom && max >= item.priceStartFrom) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            })
        }

        if (durationFilters.length > 0) {
            packagesCp = packagesCp.filter( tourPackage => {
                let isValid = false;
                for (let durationIndex in durationFilters) {
                    let limit = durationFilters[durationIndex].value.split(':');
                    let min = parseInt(limit[0]);
                    let max = parseInt(limit[1]);
                    if (isNaN(max)) {
                        if (min <= tourPackage.days) {
                            isValid = true;
                            break;
                        }
                    } else {
                        if (min <= tourPackage.days && max >= tourPackage.days) {
                            isValid = true;
                            break;
                        }
                    }
                }
                return isValid;
            });
        }

        if (categoryFilters.length > 0) {
            packagesCp = packagesCp.filter( tourPackage => {
                let isValid = false;
                for (let index in categoryFilters) {
                    let value = categoryFilters[index].value;
                    if (value === tourPackage.experienceType) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            });
        }

        return packagesCp;
    }

    const renderComponent = (componentName) => {
        setFilterListHandler();
        if (componentName === 'all') {
            setContentAvailability('all');
        } else if (componentName === 'experience') {
            setContentAvailability('experience');
        } else {
            setContentAvailability('admission');
        }
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        variableWidth: true,
        centerPadding: '0px',
        fade:false,
        dotsClass: "slick-dots " + classes.DotWrapper,
        autoplay: false,
        autoplaySpeed: 3500,
        swipeToSlide: true,
    };

    let contentData = null;
    if (contentAvailability === 'all') {
        contentData = packagesFilter(props.admissionExperiencePackages);
    } else if (contentAvailability === 'experience') {
        contentData = packagesFilter(props.experiencePackages);
    } else if (contentAvailability === 'admission') {
        contentData = packagesFilter(props.admissionPackages);
    }

    return (
        <div className={classes.Wrapper}>
            <Sort 
                show={showSortPopup}
                hideSortPopupClicked={() => setShowSortPopup(false)} />

            <Filter 
                show={showFilterPopup}
                hideFilterPopupClicked={() => setShowFilterPopup(false)}
                priceFilterList={priceFilterList}
                durationFilterList={durationFilterList}
                categoryFilterList={categoryFilterList}
                resetFilterClicked={setFilterListHandler}
                setFilterClicked={(priceFilterList, durationFilterList, categoryFilterList) => setFilterClickHandler(priceFilterList, durationFilterList, categoryFilterList)}
                />

            <div className={classes.HeaderWrapper}>
                <Header 
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    rightIcon="search"
                    rightClicked={searchClickHandler}
                    content="Things to do" />
                <div className={classes.LabelList}>
                    <Slider {...settings}>
                        <div
                            className={classes.ItemWrapper}
                            onClick={ () => renderComponent('all')}>
                            <div className={(contentAvailability === 'all') ? [classes.Item, classes.Active].join(' ') : classes.Item}>All</div>
                        </div>
                        <div
                            className={classes.ItemWrapper}
                            onClick={ () => renderComponent('experience')}>
                            <div className={(contentAvailability === 'experience') ? [classes.Item, classes.Active].join(' ') : classes.Item}>Activity Package</div>
                        </div>
                        <div
                            className={classes.ItemWrapper}
                            onClick={ () => renderComponent('admission')}>
                            <div className={(contentAvailability === 'admission') ? [classes.Item, classes.Active].join(' ') : classes.Item}>Admission</div>
                        </div>
                    </Slider>
                </div>
            </div>
            <div className={classes.Content}>
                <Availability contentData={contentData}/>
            </div>

            <div className={classes.SortFilter}>
                <SortFilterBtn 
                    contentAvailability={contentAvailability}
                    showSortPopupClicked={() => setShowSortPopup(true)}
                    showFilterPopupClicked={() => setShowFilterPopup(true)} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        admissionPackages: state.AdmissionAvailabilityReducer.admissionPackages,
        experiencePackages: state.AdmissionAvailabilityReducer.experiencePackages,
        admissionExperiencePackages: state.AdmissionAvailabilityReducer.admissionExperiencePackages,
        categoryFilters: state.AdmissionAvailabilityReducer.categoryFilters
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initAdmissionExperiencePackages:() => dispatch(AvailabilityAction.initAdmissionExperiencePackages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(availabilityContainer);
