import React from 'react';
import classes from './Banner.module.scss';
import { DEFAULT_IMG } from '../../../../constant/Content';

const banner = (props) => {
    return (
        <div className={classes.Wrapper}>
            <img onError={DEFAULT_IMG} src={props.image} alt={props.title} />
            <div className={classes.Overlay}></div>
            <div className={classes.Title}>{props.title}</div>
        </div>
    );
}

export default banner;