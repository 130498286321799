import React from 'react';
import classes from './SuccessRegister.module.scss';
import artSubmitApplication from '../../../../../assets/images/art-submit-application.png';

const SuccessRegister = () => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Content}>
                <div className={classes.Image}>
                    <img src={artSubmitApplication} alt="Success Register"/>
                </div>
                <div className={classes.Desc}>Please click on the link that has just been sent to your email account to verify your email and continue the registration process.</div>
            </div>
        </div>
    )
}

export default SuccessRegister;