import React from 'react';
import classes from './Itinerary.module.scss';
import ItineraryItem from './ItineraryItem/ItineraryItem';

const itinerary = (props) => {
    let itineraryList = null;
    if (props.itineraryList) {
        itineraryList = props.itineraryList.map((itinerary, index) => {
            let isLast = (props.itineraryList.length === index + 1) ? true : false;
            return <ItineraryItem 
                        key={index} 
                        isLast={isLast}
                        itenerary={itinerary}
                        isActive={(index === 0) ? true: false} />;
        });
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.List}>
                {itineraryList}
            </div>
        </div>
    );
}

export default itinerary;