import React from 'react';
import classes from './LeftContent.module.scss';
import Breadcrumb from '../../../../Desktop/Shared/Breadcrumb/Breadcrumb';
// import BreadCrumbLoader from '../../../../UI/Loaders/Desktop/DetailPackages/Breadcrumb/BreadCrumbLoader';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import ListPanel from './ListPanel/ListPanel';
import { DEFAULT_IMG, ABOUT_FREE_VOUCHER } from '../../../../../constant/Content';

const leftContent = (props) => {

    let breadcrumb = <Breadcrumb linkList={props.breadcrumbLink}/>;

    return (
        <div className={classes.Wrapper}>
            {breadcrumb}
            <div className="u-mb-24">
                <PanelBox>
                    <div className={classes.WrapperItem}>
                        <div className={classes.Title}>{props.virtualTourDetail.name}</div>
                        <img onError={DEFAULT_IMG} src={props.virtualTourDetail.imageSource} alt="virtual-tour" />
                    </div>
                </PanelBox>
            </div>
            <div className="u-mb-24">
                <PanelBox>
                    <div className="u-p-24">
                        <div className={classes.Title}>Description</div>
                        <div className={classes.Summary}>
                            {props.virtualTourDetail.remark}
                        </div>
                    </div>
                </PanelBox>
            </div>
            <div className="u-mb-24">
                <ListPanel
                isCollapseBtn={true}
                listColor="grey"
                title="Term and Condition Free Voucher Tour"
                list={ABOUT_FREE_VOUCHER} />
            </div>
        </div>
    )
}

export default leftContent;
