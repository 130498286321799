import React, { } from 'react';
import Modal from '../../../../../UI/Modal/Modal';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import Button from '../../../../../UI/Button/Button';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../../redux/Shared/Member/MemberAction';

function citizenshipNumberModal (props) {
    const updateFormKTP = () => {
        let dataMember = {
            citizenshipNumber: props.formCitizenshipNumber.citizenshipNumber.value
        }

        props.submitChangedMember(dataMember)
    }
    return (
        <Modal 
            title="Add ID Card Number"
            show={props.showUpdateIdCardForm}
            closeClicked={() => props.showHideUpdateIdCardClicked(false)}>
            <div className="u-mb-40">
                <div className="label">ID card number (KTP)</div>
                <TextField
                    type="ID card number (KTP)"
                    placeholder="" 
                    value={props.formCitizenshipNumber.citizenshipNumber.value}
                    changed={(event) => props.inputChangedKTPHandler(event, 'citizenshipNumber')}/>
            </div>
            <div>
                <Button 
                    clicked={() => updateFormKTP()}
                    isLoading={props.isSubmitLoading}
                    classes={['BtnRed', 'BtnMedium', 'BtnBlock']}>Add</Button>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        isSubmitLoading: state.MemberReducer.isSubmitLoading,
        formCitizenshipNumber: state.MemberReducer.formCitizenshipNumber,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChangedKTPHandler: (event, inputIdentifier) => dispatch(memberAction.inputChangedKTP(event, inputIdentifier)),
        selectChangedTitle: (value) => dispatch(memberAction.selectChangedTitle(value)),
        submitChangedMember: (dataMember) => dispatch(memberAction.submitChangedMember(dataMember, 'generalForm'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(citizenshipNumberModal);
