import React, { useEffect } from 'react';
import classes from './AvailabilityContainer.module.scss';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import { DEFAULT_IMG } from '../../../../constant/Content';
import Icon from '../../../../components/UI/Icon/Icon';
import { formatDisplayDate } from '../../../../helper/GlobalFunc';
import EmptyData from '../../../../components/UI/EmptyData/EmptyData';
import ContentLoader from 'react-content-loader';

import { connect } from 'react-redux';
import * as availabilityVoucher from '../../../../redux/Voucher/Availability/AvailabilityAction';

const availabilityContainer = (props) => {

    useEffect(() => {
        props.fetchVoucher()
    }, [])

    const backClickHandler = () => {
        window.history.back();
    }

    const goToDetail = (slug) => {
        props.history.push('/voucher/booking/' + slug)
    }

    let content = null
    if (props.isFetchVoucher) {
        content = (
            <>
                {[<Loader key={1} />, <Loader key={2} />, <Loader key={3} />, <Loader key={4} />]};
            </>
        )
    } else {
        if (props.isErrorFetchVoucher) {
            content = (
                <div className={classes.Item}>
                    <EmptyData
                        type="tour-empty"
                        title="Voucher Empty"
                        desc="Sorry voucher is unavailable" />
                </div>
            )
        } else {
            if (props.vouchers.length === 0) {
                content = (
                    <div className={classes.Item}>
                        <EmptyData
                            type="tour-empty"
                            title="Voucher Empty"
                            desc="Sorry voucher is unavailable" />
                    </div>
                )
            } else {
                content = props.vouchers.map((item, index) => {
                    let benefits = null;
                    if (item.benefit) {
                        benefits = item.benefit.map((benefitItem, benefitKey) => {
                            let benefitClasses = [classes.Benefit];
                            let icon = 'ico-close-circle';
                            let color = '#E6E6E6';
                            if (benefitItem.status) {
                                icon = 'ico-check-circle';
                                color = '#73C700';
                                benefitClasses.push(classes.Active);
                            }
                            return (
                                <div key={benefitKey} className={benefitClasses.join(' ')}>
                                    <Icon name={icon} fill={color} stroke="none" width={16} />
                                    <div className={classes.Text}>
                                        {benefitItem.title}
                                    </div>
                                </div>
                            )
                        })
                    }
                    return (
                        <div key={index} className={classes.Item}>
                            <PanelBox>
                                <div className={classes.Image}>
                                    <img onError={DEFAULT_IMG} src={item.imageSource} alt="voucher" />
                                </div>
                                <div className={classes.Body}>
                                    {benefits}
                                </div>
                                <div className={classes.Footer}>
                                    <div className={classes.ItemDate}>
                                        <div className={classes.Label}>
                                            Expired : {formatDisplayDate(item.expiredDate)}
                                        </div>
                                    </div>
                                    <div className={classes.ItemBtn}>
                                        <div onClick={() => goToDetail(item.slug)} className="link u-fw-500">Buy Now</div>
                                    </div>
                                </div>
                            </PanelBox>
                        </div>
                    )
                })
            }
        }
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.HeaderWrapper}>
                <Header
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    content="Voucher" />
            </div>
            <div className={classes.Content}>
                {content}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        vouchers: state.VoucherAvailabilityReducer.vouchers,
        isFetchVoucher: state.VoucherAvailabilityReducer.isFetchVoucher,
        isErrorFetchVoucher: state.VoucherAvailabilityReducer.isErrorFetchVoucher
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchVoucher: () => dispatch(availabilityVoucher.fetchVoucher())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(availabilityContainer);

const Loader = (props) => {
    return (
        <div className={classes.Item} key={props.key}>
            <PanelBox>
                <div className={classes.Image}>
                    <ContentLoader
                        height={155}
                        speed={1}
                        primaryColor={'#e6e6e6'}>
                    </ContentLoader>
                </div>
                <div className={classes.Body}>
                    <ContentLoader
                        height={77}
                        speed={1}
                        primaryColor={'#e6e6e6'}>
                        <rect x="0" y="3" rx="4" ry="4" width="200" height="15" />
                        <rect x="0" y="32" rx="4" ry="4" width="200" height="15" />
                        <rect x="0" y="62" rx="4" ry="4" width="200" height="15" />
                    </ContentLoader>
                </div>
            </PanelBox>
        </div>
    )
}