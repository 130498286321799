import React from 'react';
import classes from './CardListLoader.module.scss';
import ContentLoader from 'react-content-loader';

const cardListLoader = () => {
    return(
        <div className={classes.Wrapper}>
            <div className={classes.Item}>
                <ContentLoader
                    height={800}
                    speed={1}
                    primaryColor={'#c2c2c2'}
                >
                    <rect x="22" y="40" rx="15" ry="15" width="100" height="30"/>
                    <rect x="25" y="340" rx="5" ry="5" width="310" height="25" />
                    <rect x="25" y="385" rx="5" ry="5" width="150" height="25" />
                    <rect x="25" y="440" rx="5" ry="5" width="80" height="20" />
                    <rect x="120" y="440" rx="5" ry="5" width="80" height="20" />
                    <rect x="25" y="490" rx="5" ry="5" width="100" height="15" />
                    <rect x="25" y="520" rx="5" ry="5" width="200" height="35" />
                </ContentLoader>
            </div>
        </div>
    )
}

export default cardListLoader;