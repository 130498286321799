import React from 'react';
import classes from './ProductServiceItem.module.scss';
import Icon from '../../../../UI/Icon/Icon';

const bestDealItem = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Icon}>
                <Icon name={props.service.image} fill="#ffffff" stroke="none" width={40} />
            </div>
            <div className={classes.Container}>
                <div className={classes.Description}>
                    <div className={classes.Title}>{props.service.title}</div>
                    <div className={classes.Text}>{props.service.description}</div>
                </div>
            </div>
        </div>
    );
}

export default bestDealItem;