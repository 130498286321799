import React from 'react';
import classes from './EmailVerify.module.scss';
import PopupPage from '../../../UI/PopupPage/PopupPage';
import Icon from '../../../UI/Icon/Icon';
import { COLOR } from '../../../../constant/Library';

function emailVerify (props) {
    let header = (
        <div className={classes.Header}>
            <span className={classes.HeaderAction}
                onClick={props.hideClicked}>
                <Icon name="ico-arrow-left" fill={COLOR.white} stroke="none" />
            </span>
            <span>Email Verification</span>
        </div>
    );
    let content = (
        <div className={classes.Content}>
            <div className={classes.Section}>
                <div className={classes.Text}>Please check your email and click the link to verification email that we sent to <b>{props.email}</b></div>
                <div className={classes.Link}>Resend Link Verification</div>
            </div>
        </div>
    );
    return (
        <div>
            <PopupPage 
                show={props.show}
                header={header}
                content={content}/>
        </div>
    );
}

export default emailVerify;