import React, { useEffect } from 'react';
import classes from './VirtualTourPackage.module.scss';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import Icon from '../../../UI/Icon/Icon';
import { withRouter } from 'react-router-dom';
import { currencyFormat, formatDisplayDate, formatDisplayTime } from '../../../../helper/GlobalFunc';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../redux/VirtualTour/Availability/AvailabilityAction';

const voucherPackage = (props) => {
    useEffect(() => {
        props.fetchVirtualTour();
    }, []);

    if (props.VirtualTourAvailability) {
        return (
            <div className={classes.Wrapper}>
                <div className={classes.Title}>Exclusive Virtual Tour</div>
                <PanelBox>
                    <div className={classes.Content} onClick={() => props.history.push(`/virtual-tour/detail/${props.VirtualTourAvailability.slug}`)}>
                        <div className={classes.Left}>
                            <div className={classes.Image}>
                                <img src={props.VirtualTourAvailability.imageSource} alt="virtual-tour" />
                            </div>
                        </div>
                        <div className={classes.Detail}>
                            <div className={classes.Badge}>Free Voucher Tour</div>
                            <div className={classes.Name}>{props.VirtualTourAvailability.name}</div>
                            <div className={classes.DateTime}>
                                <div className={classes.Date}>
                                    <Icon name="ico-calendar" fill="#666666" stroke="none" width={16} />
                                    <div className={classes.DateDetail}>
                                        {formatDisplayDate(props.VirtualTourAvailability.liveDate)}
                                        {' ' + formatDisplayTime(props.VirtualTourAvailability.liveDate)}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.Price}>
                                <div className={classes.Label}>Price</div>
                                <div className={classes.PriceNumber}>{currencyFormat(props.VirtualTourAvailability.price)}</div>
                            </div>
                        </div>
                    </div> 
                </PanelBox>
            </div>
        )
    } else {
        return '';
    }

}

const mapStateToProps = (state) => {
    return {
        VirtualTourAvailability: state.VirtualTourAvailability.virtualTours,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVirtualTour: () => dispatch(AvailabilityAction.fetchVirtualTour())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(voucherPackage));
