import React, { useEffect, useState } from "react";
import classes from "./BookingContainer.module.scss";
import Header from "../../../../components/Mobile/Shared/Header/Header";
import Step from "../../../../components/Shared/Step/Step";
import PanelBox from "../../../../components/UI/PanelBox/PanelBox";
import Icon from "../../../../components/UI/Icon/Icon";
import Button from "../../../../components/UI/Button/Button";
import { DEFAULT_IMG } from "../../../../constant/Content";
import { formatDisplayDate, currencyFormat } from "../../../../helper/GlobalFunc";
import ContactInfo from "./ContactInfo/ContactInfo";
import TermAndCondition from "../../../../components/Mobile/Voucher/TermAndCondition/TermAndCondition";
import Confirm from "../../../../components/UI/Dialog/Confirm/Confirm";
import SuccessBook from "../../../../components/Mobile/Hotel/Booking/SuccessBook/SuccessBook";
import EmptyData from '../../../../components/UI/EmptyData/EmptyData';

import { connect } from "react-redux";
import * as bookingVoucher from "../../../../redux/Voucher/Booking/BookingAction";

const bookingContainer = (props) => {
	const [showTermAndCondition, setshowTermAndCondition] = useState(false);
	const [isContinueConfirm, setIsContinueConfirm] = useState(false);

	let step = 1;

	const backClickHandler = () => {
		window.history.back();
	};

	const submitInquiryHandler = () => {
		if (props.isValidContactInfoForm) {
			setIsContinueConfirm(true);
		} else {
			props.checkContactInfoForm();
		}
	};

	const submitData = () => {
		props.submitInquiry(
			props.voucherDetail.ID,
			props.contactInfoForm,
			props.inquiryForm
		);
	};

	useEffect(() => {
		props.setToDefault();
		props.fetchVoucherDetail(props.match.params.slug);

		scrollTop()
	}, []);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	}

	useEffect(() => {
		if (!props.isSubmitBooking) {
			setIsContinueConfirm(false);
		}
	}, [props.isSubmitBooking]);

	let voucherDetailContent = null;
	if (props.voucherDetail) {
		let benefits = null;
		if (props.voucherDetail.benefit) {
			benefits = props.voucherDetail.benefit.map(
				(benefitItem, benefitKey) => {
					let benefitClasses = [classes.Benefit];
					let icon = "ico-close-circle";
					let color = "#E6E6E6";
					if (benefitItem.status) {
						icon = "ico-check-circle";
						color = "#73C700";
						benefitClasses.push(classes.Active);
					}
					return (
						<div key={benefitKey} className={benefitClasses.join(" ")}>
							<Icon name={icon} fill={color} stroke="none" width={16} />
							<div className={classes.Text}>{benefitItem.title}</div>
						</div>
					);
				}
			);
		}

		voucherDetailContent = (
			<div className={classes.Item}>
				<PanelBox>
					<div className={classes.Image}>
						<img onError={DEFAULT_IMG} src={props.voucherDetail.imageSource} alt="voucher"/>
					</div>
					<div className={classes.Body}>{benefits}</div>
					<div className={classes.Dates}>
						<div className={classes.ItemDate}>
							<div className={classes.Label}>Expired</div>
							<div className={classes.Date}>
								<Icon name="ico-calendar" fill="#666666" stroke="none" width={20}/>
								<div className={classes.Text}>
									{formatDisplayDate(props.voucherDetail.expiredDate)}
								</div>
							</div>
						</div>
					</div>
					<div className={classes.Footer}>
						<div className={classes.ItemPrice}>
							<div className={classes.Label}>Price</div>
						</div>
						<div className={classes.Price}>
							<div className="link u-fw-500">{currencyFormat(props.voucherDetail.price)}</div>
						</div>
					</div>
				</PanelBox>
			</div>
		);
	}

	let content = null;
	if (!props.isFetchVoucherDetail) {
		if (props.isErrorFetchVoucherDetail) {
			content = (
                <div className={classes.Item}>
                    <EmptyData
                        type="tour-empty"
                        title="Voucher Empty"
                        desc="Sorry voucher is unavailable" />
                </div>
			)
		} else {
			content = (
				<div className={classes.Content}>
					<div className="u-mb-20">
						<div className={classes.SubTitle}>Voucher Detail</div>
						{voucherDetailContent}
					</div>
					<div className="u-mb-20">
						<div className={classes.SubTitle}>Contact Information</div>
						<ContactInfo
							contactInfo={props.contactInfoForm}
							inputChanged={(identifier, value) => props.changeContactInfoForm(identifier, value)}
							inquiry={props.inquiryForm}
							inquiryChanged={(idendtifier, value) => props.inquiryChanged(idendtifier, value)}/>
					</div>
					<div className="u-mb-20">
						<PanelBox>
							<div className={classes.Card} onClick={() => setshowTermAndCondition(true)}>
								Terms and Condition Tour Voucher
							</div>
						</PanelBox>
					</div>
					<Button
						classes={["BtnMedium", "BtnBlock", "BtnRed"]}
						clicked={submitInquiryHandler}>
						Submit Inquiry
					</Button>
				</div>
			);
		}
	}
	if (props.isSuccessSubmitBooking) {
		step = 2;
		content = <SuccessBook clicked={() => props.history.push("/")} />;
	}

	return (
		<div className={classes.Wrapper}>
			<Confirm
				show={isContinueConfirm}
				title="Are you sure?"
				desc="Make sure your data is correct."
				cancelClicked={() => setIsContinueConfirm(false)}
				isConfirmLoading={props.isSubmitBooking}
				cancelLabel="No, check again"
				confirmLabel="Yes, continue"
				confirmClicked={submitData}
			/>
			<TermAndCondition
				show={showTermAndCondition}
				data={props.voucherDetail}
				hidePopupClicked={() => setshowTermAndCondition(false)}
			/>
			<div className={classes.HeaderWrapper}>
				<Header
					leftIcon="back"
					leftClicked={backClickHandler}
					content="Tour Voucher Booking"/>
			</div>
			<div className={classes.Step}>
				<Step type="inquiry" step={step} />
			</div>
			{content}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		voucherDetail: state.VoucherBookingReducer.voucherDetail,
		isFetchVoucherDetail: state.VoucherBookingReducer.isFetchVoucherDetail,
		isErrorFetchVoucherDetail: state.VoucherBookingReducer.isErrorFetchVoucherDetail,
		isValidContactInfoForm: state.VoucherBookingReducer.isValidContactInfoForm,
		contactInfoForm: state.VoucherBookingReducer.contactInfoForm,
		inquiryForm: state.VoucherBookingReducer.inquiryForm,
		isSubmitBooking: state.VoucherBookingReducer.isSubmitBooking,
		isSuccessSubmitBooking: state.VoucherBookingReducer.isSuccessSubmitBooking,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchVoucherDetail: (slug) => dispatch(bookingVoucher.fetchVoucherDetail(slug)),
		changeContactInfoForm: (identifier, value) => dispatch(bookingVoucher.changeContactInfoForm(identifier, value)),
		submitInquiry: (voucherId, contactInfoFrom, inquiryForm) => dispatch(bookingVoucher.submitInquiry(voucherId, contactInfoFrom, inquiryForm)),
		checkContactInfoForm: () => dispatch(bookingVoucher.checkContactInfoForm()),
		setToDefault: () => dispatch(bookingVoucher.setToDefault()),
		inquiryChanged: (idendtifier, value) => dispatch(bookingVoucher.changeInquiryForm(idendtifier, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(bookingContainer);
