import React, { useState, useEffect } from 'react';
import classes from './AvailabilityContainer.module.scss';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import { toCapitalize } from '../../../../helper/GlobalFunc';
import Availability from '../../../../components/Mobile/Promoted/Availability/Availability';
import { TOUR_PRICE_FILTER_LIST } from '../../../../constant/FilterList';
import Sort from '../../../../components/Mobile/ThingsToDo/SortFilter/Sort/Sort';
import Filter from '../../../../components/Mobile/ThingsToDo/SortFilter/Filter/Filter';
import SortFilterBtn from '../../../../components/Mobile/ThingsToDo/SortFilter/Button/Button';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../redux/Admission/Availability/AvailabilityAction';

const availabilityContainer = (props) => {
    const [showSortPopup, setShowSortPopup] = useState(false);
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [priceFilterList, setPriceFilterList] = useState([]);
    // eslint-disable-next-line
    const [contentAvailability, setContentAvailability] = useState('experience');

    useEffect(() => {
        props.initExperiencePackages(props.match.params.slug);
    }, []);

    useEffect(() => {
        setFilterListHandler();
    }, [props.categoryFilters]);

    const setFilterListHandler = () => {
        setPriceFilterListHandler();
    }

    const setPriceFilterListHandler = () => {
        const priceFilterList = TOUR_PRICE_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });
        setPriceFilterList(priceFilterList);
    }

    const setFilterClickHandler = (priceFilterList) => {
        setPriceFilterList(priceFilterList);
    }

    const backClickHandler = () => {
        props.history.push('/')
    }
    
    return (
        <div className={classes.Wrapper}>
            <Sort 
                show={showSortPopup}
                hideSortPopupClicked={() => setShowSortPopup(false)} />

            <Filter 
                show={showFilterPopup}
                hideFilterPopupClicked={() => setShowFilterPopup(false)}
                priceFilterList={priceFilterList}
                setFilterClicked={(priceFilterList) => setFilterClickHandler(priceFilterList)}
                />
            
            <div className={classes.HeaderWrapper}>
                <Header 
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    content={toCapitalize(props.match.params.slug)}/>
            </div>

            <div className={classes.Content}>
                <Availability experiencePackages={props.experiencePackages}/>
            </div>

            <div className={classes.SortFilter}>
                <SortFilterBtn 
                    contentAvailability={contentAvailability}
                    showSortPopupClicked={() => setShowSortPopup(true)}
                    showFilterPopupClicked={() => setShowFilterPopup(true)} 
                    />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        categoryFilters: state.AdmissionAvailabilityReducer.categoryFilters,
        experiencePackages: state.AdmissionAvailabilityReducer.experiencePackages,
        isFetchExperience: state.AdmissionAvailabilityReducer.isFetchExperience,
        isErrorFetchExperience: state.AdmissionAvailabilityReducer.isErrorFetchExperience,
        sortBy : state.AdmissionAvailabilityReducer.sortBy
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initExperiencePackages: (slug) => dispatch(AvailabilityAction.initExperiencePackagesByExperienceType(slug)),
        setSortBy: (value) => dispatch(AvailabilityAction.setSortBy(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(availabilityContainer);
