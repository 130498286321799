import React from 'react';
import classes from './Step.module.scss';
import Icon from '../../../components/UI/Icon/Icon';
import { isMobile } from 'react-device-detect';
import { PAYMENT_STEP, INQUIRY_STEP } from '../../../constant/General';

function step (props) {
    let steps = PAYMENT_STEP
    if (props.type === 'inquiry') {
        steps = INQUIRY_STEP;
    } 

    let stepList = null;
    if (isMobile) {
        stepList = steps.map((step, index) => {
            let itemClasses = [classes.ItemMobile];
            let icon = <Icon className={classes.Icon} name={step.icon} width={20} stroke="none" fill={'#1A6FA3'}/>
            if (props.step > index + 1) {
                icon = <Icon className={classes.Icon} name={'ico-check-circle'} width={16} stroke="none" fill="#73C700"/>
            } else if (props.step < index + 1) {
                itemClasses.push(classes.Inactive);
                icon = <Icon className={classes.Icon} name={step.icon} width={20} stroke="none" fill={'#c2c2c2'}/>
            }
            let stepItem = (
                <li key={index} className={itemClasses.join(' ')}>
                    {icon}
                    <span className={classes.Text}>{step.label}</span>
                </li>
            )
            return stepItem;
        });
    } else {
        stepList = steps.map((step, index) => {
            let stepItem = <li key={index} className={classes.Item}><span className={classes.Number}>{index + 1}</span><span className={classes.Text}>{step.label}</span></li>;
            if (props.step === index + 1) {
                stepItem = (
                    <li key={index} className={classes.Item}>
                        <span className={[classes.Number, classes.Active].join(' ')}>{index + 1}</span>
                        <span className={classes.Text}>{step.label}</span>
                    </li>
                );
            } else if (props.step > index + 1) {
                stepItem = (
                    <li key={index} className={classes.Item}>
                        <span className={[classes.Number, classes.Done].join(' ')}><Icon name="ico-check" fill="#6A6E73" stroke="none"/></span>
                        <span className={classes.Text}>{step.label}</span>
                    </li>)
                ;
            }
            return stepItem;
        });
    }
    
    return <div className={classes.Wrapper}>
            {stepList}
        </div>
}

export default step;
