import React, { useState, useEffect } from 'react';
import classes from './ItineraryItem.module.scss';
import Icon from '../../../../../../UI/Icon/Icon';
import CSSTransition from 'react-transition-group/CSSTransition';
import { toCapitalize } from '../../../../../../../helper/GlobalFunc';

const itineraryItem = props => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if(props.isActive !== isActive && props.isActive) {
            detailToggleHandler();
        }
    }, [props.isActive])

    const detailToggleHandler = () => {
        setIsActive(!isActive);
    }

    let arrowClasses = [classes.Arrow];
    if (isActive) {
        arrowClasses = [classes.Arrow, classes.Active];
    }

    let leftClasses = [classes.Left];
    let rightClasses = [classes.Right];
    if (props.isLast) {
        leftClasses = [classes.Left, classes.Last];
        rightClasses = [classes.Right, classes.Last];
    }

    let facilityList = null;
    if (props.itenerary.facilities && props.itenerary.facilities.length > 0) {
        facilityList = props.itenerary.facilities.map((facility, index) => {
            return (<li key={index}><Icon name="ico-check-circle-white" fill="#73C700" stroke="none"  /><span>{facility.name}</span></li>);
        });
    }

    let flight = null;
    if (props.itenerary.flight.length > 0) {
        let flightTime = null;
        let airportCode = null;
        
        if (props.itenerary.flight[0].departureDateTime && props.itenerary.flight[0].arrivalDateTime) {
            flightTime = <div className={classes.FlightTitle}>{props.itenerary.flight[0].departureDateTime} - {props.itenerary.flight[0].arrivalDateTime}</div>;
        }
        if (props.itenerary.flight[0].departureAirportCode && props.itenerary.flight[0].arrivalAirportCode) {
            airportCode = <div className={classes.FlightSubTitle}>{props.itenerary.flight[0].departureAirportCode} - {props.itenerary.flight[0].arrivalAirportCode}</div>;
        }
        flight = (
            <div className={classes.Flight}>
                <div className={classes.FlightIcon}>
                    <Icon name="ico-flight-o" fill="#6A6E73" stroke="none" />
                </div>
                <div className={classes.FlightDesc}>
                    <div>
                        {flightTime}
                        <div className={classes.FlightSubTitle}>{props.itenerary.flight[0].airlineName}</div>
                    </div>
                </div>
                <div className={classes.FlightDesc}>
                    {airportCode}
                </div>
            </div>
        );
    }

    return (
        <div className={classes.Wrapper}>
            <div className={leftClasses.join(' ')}>
                <span className={classes.Day} onClick={detailToggleHandler}>Day {props.itenerary.days}</span>
                <div className={classes.Dot}></div>
            </div>
            <div className={rightClasses.join(' ')}>
                <div className={classes.Title} onClick={detailToggleHandler}>
                    {toCapitalize(props.itenerary.title)}
                    <div className={arrowClasses.join(' ')}>
                        <Icon name="ico-caret-down" fill="#202124;" stroke="none"  />
                    </div>
                </div>
                <CSSTransition
                    in={isActive}
                    mountOnEnter
                    unmountOnExit
                    timeout={270}
                    classNames={{
                        enterActive: classes.Open,
                        exitActive: classes.Close}}>
                    <div>
                        {flight}
                        <div className={classes.Description}>{props.itenerary.description}</div>
                        <ul className={classes.Facilities}>
                            {facilityList}
                        </ul>
                        <div></div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
}

export default itineraryItem;