import axios from "../../../axios";
import { ANTAVAYA_API } from "../../../constant/Api";

export const setIsFetchVirtualTourDetail = (value) => {
    return {
        type: 'SET_IS_FETCH_VIRTUAL_TOUR_DETAIL',
        payload: {
            value
        }
    }
}

export const setIsErrorFetchVirtualTourDetail = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_VIRTUAL_TOUR_DETAIL',
        payload: {
            value
        }
    }
}

export const setVirtualTourDetail = (data) => {
    return {
        type: 'SET_VIRTUAL_TOUR_DETAIL',
        payload: {
            data
        }
    }
}

export const fetchVirtualTourDetail = (slug) => {
    return dispatch => {
        dispatch(setIsFetchVirtualTourDetail(true));
        axios.get(ANTAVAYA_API + 'virtual-tour/detail/' + slug)
            .then((response) => {
                dispatch(setVirtualTourDetail(response.data.data))
                dispatch(setIsFetchVirtualTourDetail(false));
            })
            .catch((error) => {
                dispatch(setIsErrorFetchVirtualTourDetail(true))
                dispatch(setIsFetchVirtualTourDetail(false));
            })
    }
}