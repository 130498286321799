import React from 'react';
import classes from './TourItem.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import Button from '../../../../../UI/Button/Button';
import CurrencyFormat from 'react-currency-format';
import { toCapitalize, pluralize } from '../../../../../../helper/GlobalFunc';
import Included from '../../../../Tour/Included/Included';
import { DEFAULT_IMG } from '../../../../../../constant/Content';
import Ribbon from '../../../../../UI/Ribbon/Ribbon';

const tourItem = (props) => {
    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                <div className={classes.RibbonWrapper}>
                    <Ribbon text="free voucher tour" position="left" size="small" />
                </div>
                <div className={classes.Image}>
                    <img onError={DEFAULT_IMG} src={props.packageItem.defaultImage.imageFile} alt={props.packageItem.defaultImage.imageDescription} />
                </div>
                <div className={classes.Description}>
                    <a href={'/tour/detail/' + props.packageItem.slug} className={classes.Title}>
                        {props.packageItem.days}D {toCapitalize(props.packageItem.inventoryTitle)}
                    </a>
                    <div className={classes.SubTitle}>
                        {pluralize(props.packageItem.days, 'Day', 'Days')} & {pluralize(props.packageItem.night, 'Night', 'Nights')}
                    </div>
                    <div className={classes.Included}>
                        <div className={classes.Label}>Included in package:</div>
                        <div className={classes.Includes}>
                            {(props.packageItem.includeFlight) &&
                                <div className={classes.Item}>
                                    <Included label="Flight" color="black" />
                                </div>
                            }
                            {(props.packageItem.includeHotel) && 
                                <div className={classes.Item}>
                                    <Included label="Hotel" color="black"/>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={classes.PriceAction}>
                        <div>
                            <div className={classes.Label}>Starting from</div>
                            <div className={classes.Price}>
                                <CurrencyFormat value={props.packageItem.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            </div>
                        </div>
                        <div className={classes.Action}>
                            <a href={'/tour/detail/' + props.packageItem.slug}>
                                <Button classes={['BtnRed', 'BtnSmall']}>View Package</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </PanelBox>
    )
}

export default tourItem;
