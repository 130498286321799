import {updateObject} from '../../utility';

const initialState = {
    isFetchTour: true,
    tourPackages : [],
    categoryFilters : [],
    sortBy: 'lowest-price'
}

const generateFilter = (tours) => {
    let catFilters = [];
    if (tours && tours.length > 0) {
        catFilters = tours.map(item => item.typeName)
                        .filter((item, index, self) => self.indexOf(item) === index)
                        .map(item => ({value : item, name : item}));
    } 
    return catFilters;
}

const setTours = (state, action) => {
    let catFilters = generateFilter(action.tours);
    return updateObject( state, {
        tourPackages: action.tours.sort((a, b) => a.priceStartFrom - b.priceStartFrom),
        categoryFilters: catFilters
    });
};

const setSortBy = (state, action) => {
    let tourPackages = state.tourPackages;
    if (action.sortBy === 'lowest-price') {
        tourPackages = state.tourPackages.sort((a, b) => a.priceStartFrom - b.priceStartFrom);
    } else if (action.sortBy === 'highest-price') {
        tourPackages = state.tourPackages.sort((a, b) => b.priceStartFrom - a.priceStartFrom);
    } else if (action.sortBy === 'sort-duration') {
        tourPackages = state.tourPackages.sort((a, b) => (a.days + a.night) - (b.days + b.night));
    } else if (action.sortBy === 'longest-duration') {
        tourPackages = state.tourPackages.sort((a, b) => (b.days + b.night) - (a.days + a.night));
    }
    return updateObject( state, {
        sortBy: action.sortBy,
        tourPackages: tourPackages,
    } );
};

const setIsFetchTour = (state, action) => {
    return updateObject(state, {isFetchTour: action.value});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_TOURS': return setTours(state, action);
        case 'SET_IS_FETCH_TOUR': return setIsFetchTour(state, action);
        case 'SET_SORT_BY': return setSortBy(state, action);
        default: return state;
    }
};

export default reducer;