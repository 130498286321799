import React from 'react';
import classes from './BannerDetailLoader.module.scss';
import ContentLoader from 'react-content-loader';

const bannerDetailLoader = () => {
    return (
        <div className={classes.Wrapper}>
            <ContentLoader
                height={140}
                speed={1}
                primaryColor={'#a3a3a3'}
            >
                <rect x="0" y="0" rx="5" ry="5" width="57" height="10" />
                <rect x="0" y="60" rx="3" ry="3" width="250" height="12" />
                <rect x="0" y="84" rx="3" ry="3" width="50" height="7" />
                <rect x="55" y="84" rx="3" ry="3" width="30" height="7" />
                <rect x="90" y="84" rx="3" ry="3" width="30" height="7" />
                <rect x="0" y="98" rx="3" ry="3" width="30" height="8" />
                <rect x="350" y="92" rx="3" ry="3" width="50" height="15" />
            </ContentLoader>
        </div>
    )
}

export default bannerDetailLoader;