const lang = {
    payment : 'Payment',
    tour : 'Tour',
    flight : 'Flight',
    hotel : 'Hotel',
    pulse : 'Pulse',
    train : 'Train',
    checkOrder : 'Check Order',
    login : 'Login',
    hotelLastView : 'Your Last View Hotel',
    hotelPopular : 'Popular Hotel',
};

export default lang;