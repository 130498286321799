import React from 'react';
import classes from './Summary.module.scss';
import PanelBox from '../../../../../../components/UI/PanelBox/PanelBox';
import { formatHumanDate, getTitle } from '../../../../../../helper/GlobalFunc';
import ContentLoader from 'react-content-loader';
import PaymentStatus from '../../../../../../components/Shared/PaymentStatus/PaymentStatus';

const summary = (props) => {
    let content = null;
    if (!props.isLoading) {
        content = (
            <div className={classes.Wrapper}>
                <div className={classes.Item}>
                    <div className={classes.Label}>Booking ID</div>
                    <div className={classes.Value}>{props.detailTour.orderNumber}</div>
                </div>
                <div className={classes.Item}>
                    <div className={classes.Label}>Purchased Name</div>
                <div className={classes.Value}>{getTitle(props.detailTour.customerTitle)} {props.detailTour.customerName}</div>
                </div>
                <div className={classes.Item}>
                    <div className={classes.Label}>Purchased On</div>
                    <div className={classes.Value}>
                        {formatHumanDate(props.detailTour.bookingDate)}
                    </div>
                </div>
                <div className={classes.Item}>
                    <div className={classes.Label}>Payment Method</div>
                    <div className={classes.Value}>-</div>
                </div>
                <div className={classes.Foot}>
                        <PaymentStatus status={props.detailTour.paymentStatus} />
                </div>
            </div>
        )
    } else {
        content = (
            <div className="u-p-16">
                <ContentLoader
                    height={140}
                    speed={1}
                    primaryColor={'#e6e6e6'}
                >
                    <rect x="0" y="0" rx="2" ry="2" width="200" height="20" />
                    <rect x="280" y="0" rx="2" ry="2" width="200" height="20" />

                    <rect x="0" y="40" rx="2" ry="2" width="210" height="20" />
                    <rect x="280" y="40" rx="2" ry="2" width="200" height="20" />

                    <rect x="0" y="80" rx="2" ry="2" width="180" height="20" />
                    <rect x="280" y="80" rx="2" ry="2" width="200" height="20" />

                    <rect x="0" y="120" rx="2" ry="2" width="200" height="20" />
                    <rect x="280" y="120" rx="2" ry="2" width="200" height="20" />
                </ContentLoader>
            </div>
        );
    }
    
    return (
        <PanelBox>
            {content}
        </PanelBox>
    )
}

export default summary;
