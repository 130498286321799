import React from 'react';
import classes from './Itinerary.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import ItineraryItem from './ItineraryItem/ItineraryItem';

const itinerary = (props) => {
    let itineraryList = null;
    if (props.itineraryList) {
        itineraryList = props.itineraryList.map((itinerary, index) => {
            let isLast = (props.itineraryList.length === index + 1) ? true : false;
            return <ItineraryItem 
                        key={index} 
                        isLast={isLast}
                        itenerary={itinerary}
                        isActive={(index === 0) ? true: false} />;
        });
    }

    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                <div className={classes.Header}>
                    <div className={classes.Title}>Itinerary</div>
                    <a href={props.downloadClicked} className={classes.Download} target="blank">Download PDF
                    </a>
                </div>
                <div className={classes.List}>
                    {itineraryList}
                </div>
            </div>
        </PanelBox>
    );
}

export default itinerary;