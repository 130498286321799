import React, { useEffect } from 'react';
import classes from './HotelFeatured.module.scss';
import Slider from 'react-slick';
import Icon from '../../../UI/Icon/Icon';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import Button from '../../../UI/Button/Button';
import { formatDisplayDate, currencyFormat } from '../../../../helper/GlobalFunc';
import { withRouter } from 'react-router-dom';
// import ContentLoader from 'react-content-loader';
import { DEFAULT_IMG } from '../../../../constant/Content';
import Ribbon from '../../../UI/Ribbon/Ribbon';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../redux/Hotel/Availability/AvailabilityAction';

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div
			className={[classes.Arrow, classes.PrevArrow].join(' ')}
			onClick={onClick}>
			<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48} />
		</div>
	);
}

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div
			className={[classes.Arrow, classes.NextArrow].join(' ')}
			onClick={onClick}>
			<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48} />
		</div>
	);
}

const hotelFeatured = (props) => {

	useEffect(() => {
		props.fetchHotel();
	}, [])

	const goToDetail = (slug) => {
		props.history.push('/hotel/booking/' + slug);
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 3,
		variableWidth: true,
		autoplay: false,
		autoplaySpeed: 2500,
		dotsClass: "slick-dots lg-dot-light " + classes.DotWrapper,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		customPaging: function (i) {
			return (
				<div className="banner-product"></div>
			);
		},
	};

	let content = null;
	if (props.isFetchHotel) {
		content = (
			<Slider {...settings}>
				{/* <Loader /> */}
			</Slider>
		)
	} else {
		if (!props.isErrorFetchHotel) {
			if (props.hotels.length > 0) {
				let hotelList = props.hotels.map((item, index) => {
					return (
						<div className={classes.Item} key={index}>
							<PanelBox>
								<div className={classes.ItemBox}>
									<div className={classes.RibbonWrapper}>
										<Ribbon text="free voucher tour" position="left" />
									</div>
									<div className={classes.Image}>
										<img onError={DEFAULT_IMG} src={item.imageSource} alt="bird" />
									</div>
									<div className={classes.Body}>
										<div className={classes.Date}>
											<div className={classes.Label}>Booking Period</div>
											<div className={classes.Detail}>
												<div className={classes.Icon}>
													<Icon name="ico-calendar" fill="#666666" stroke="none" width={20} />
												</div>
												{formatDisplayDate(item.bookingPeriodStartDate)} - {formatDisplayDate(item.bookingPeriodEndDate)}
											</div>
										</div>
										<div className={classes.Date}>
											<div className={classes.Label}>Stay Period</div>
											<div className={classes.Detail}>
												<div className={classes.Icon}>
													<Icon name="ico-calendar" fill="#666666" stroke="none" width={20} />
												</div>
												{formatDisplayDate(item.stayPeriodStartDate)} - {formatDisplayDate(item.stayPeriodEndDate)}
											</div>
										</div>
									</div>
									<div className={classes.Footer}>
										<div className={classes.ItemPrice}>
											<div className={classes.Label}>
												Start From
                                            </div>
											<div className={classes.Price}>
												{currencyFormat(item.hotelPrice)}
											</div>
										</div>
										<div className={classes.ItemBtn}>
											<Button
												classes={['BtnSmall', 'BtnBlock', 'BtnRed']}
												clicked={() => goToDetail(item.slug)}>
												Book Now
                                            </Button>
										</div>
									</div>
								</div>
							</PanelBox>
						</div>
					)
				})
				content = (
					<Slider {...settings}>
						{hotelList}
					</Slider>
				)
				
			}
		}
	}

	return (
		<div className={classes.Wrapper}>
			<div className={classes.Container}>
				<div className={classes.Title}>Buy Now, Stay Later</div>
				<div className={classes.SubTitle}>Best deal hotel to enjoy staycation and get free voucher tour!</div>
				<div className={classes.List}>
					<div className={classes.ItemWrapper}>
						{content}
					</div>
					<div className={classes.Clear}></div>
				</div>
			</div>

		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		hotels: state.HotelAvailabilityReducer.hotels,
		isFetchHotel: state.HotelAvailabilityReducer.isFetchHotel,
		isErrorFetchHotel: state.HotelAvailabilityReducer.isErrorFetchHotel
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchHotel: () => dispatch(AvailabilityAction.fetchHotel()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(hotelFeatured));

// const Loader = () => {
// 	let loader = [];
// 	for (let i = 0; i < 3; i++) {
// 		loader.push(
// 			<div className={classes.Item} key={i}>
// 				<PanelBox>
// 					<div className={classes.ItemBox}>
// 						<ContentLoader
// 							height={675}
// 							speed={1}
// 							primaryColor={'#e6e6e6'}>
// 							<rect x="0" y="0" rx="12" ry="12" width="400" height="400" />

// 							<rect x="0" y="425" rx="3" ry="3" width="150" height="20" />
// 							<rect x="0" y="460" rx="3" ry="3" width="250" height="23" />

// 							<rect x="0" y="505" rx="3" ry="3" width="150" height="20" />
// 							<rect x="0" y="540" rx="3" ry="3" width="250" height="23" />

// 							<rect x="0" y="600" rx="3" ry="3" width="100" height="20" />
// 							<rect x="0" y="635" rx="3" ry="3" width="180" height="30" />

// 							<rect x="220" y="618" rx="3" ry="3" width="180" height="50" />
// 						</ContentLoader>
// 					</div>
// 				</PanelBox>
// 			</div>
// 		)

// 	}
// 	return loader;

// }