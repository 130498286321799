import React from 'react';
import classes from './BannerPage.module.scss';

const bannerPage = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Title}>{props.title}</div>
            <div className={classes.Overlay}></div>
            <img className={classes.Image} src={props.image} alt={props.title} />
        </div>
    );
}

export default bannerPage;