import React, { useState, useEffect } from 'react';
import classes from './HomeContainer.module.scss';
import Header from '../../../components/Mobile/Shared/Header/Header';
import BannerSlider from '../../../components/Mobile/Shared/BannerSlider/BannerSlider';
import HolidayPackage from '../../../components/Mobile/Tour/HolidayPackage/HolidayPackage';
import HotelPackage from '../../../components/Mobile/Hotel/HotelPackage/HotelPackage';
import VoucherPackage from '../../../components/Mobile/Voucher/VoucherPackage/VoucherPackage';
import VirtualTourPackage from '../../../components/Mobile/VirtualTour/VirtualTourPackage/VirtualTourPackage';
import PopularActivities from '../../../components/Mobile/ThingsToDo/Activity/PopularActivities/PopularActivities';
import ProductList from '../../../components/Mobile/Shared/ProductList/ProductList';
import LeftNav from '../../../components/Mobile/Shared/LeftNav/LeftNav';
import Footer from '../../../components/Mobile/Shared/Footer/Footer'
import WhatsappLink from '../../../components/Mobile/Shared/WhatsappLink/WhatsappLink';
import BottomNavBar from '../../../components/Mobile/Shared/BottomNavBar/BottomNavBar';
import logoLight from '../../../assets/images/logo-light.png';

const homeContainer = props => {
    const [showNav, setShowNav] = useState(false);
    const [isSecondHeader, setIsSecondHeader] = useState(false);
    
    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [props])

    const handleScroll = () => {
        const offsetTop = window.scrollY;
        if (offsetTop > 25) {
            setIsSecondHeader(true);
        } else {
            setIsSecondHeader(false);
        }
    }

    const showNavHandler = () => {
        document.body.style.overflow = "hidden";
        setShowNav(true);
    }

    const hideNavHandler = () => {
        document.body.style.overflow = "auto";
        setShowNav(false);
    }

    let secondHeaderClasses = [classes.Header];
    if (isSecondHeader) {
        secondHeaderClasses.push(classes.Shadow);
    }

    return (
        <div>
            <LeftNav 
                show={showNav} 
                hideClicked={hideNavHandler}/>

            <div className={classes.RedBackground}>
                <div className={classes.Oval}></div>
            </div>
            <div className={secondHeaderClasses.join(' ')}>
                <Header 
                    classes={['BgRed', 'ClWhite']}
                    align="center"
                    leftIcon="menu"
                    icoColor="white"
                    leftClicked={showNavHandler}
                    rightIcon="search"
                    rightClicked={() => props.history.push('/tour')}
                    content={<img className={classes.Logo} src={logoLight} alt="antavaya" />} />
            </div>
            <div className={classes.Content}>
                <BannerSlider />
                <ProductList />
                <HolidayPackage />
                <HotelPackage />
                <VirtualTourPackage />
                <PopularActivities />
                <VoucherPackage />
                {/* <PergiFlightList /> */}
                <Footer />
                <WhatsappLink/>
            </div>
            <BottomNavBar />
        </div>
    );
}

export default homeContainer;