import React from 'react';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import classes from './Participant.module.scss';
import { getTitle } from '../../../../../helper/GlobalFunc';

const Participant = (props) => {

	let participantDetail = [];
	if (props.bookingData && props.bookingData.participant) {
		participantDetail = props.bookingData.participant.map((item, index) => {
			return (
				<div key={index} className={classes.Detail}>
					<div className={classes.Title}>{item.type}</div>
					<div className={classes.Value}>{getTitle(item.title)} {item.name}</div>
				</div>
			)
		})
	}

	return (
		<PanelBox>
			<div className={classes.Card}>
				{participantDetail}
			</div>
		</PanelBox>
	)
}
export default Participant
