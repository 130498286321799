import React from 'react';
import classes from './BannerAvailability.module.scss';
import img from '../../../../assets/images/banners/voucher.jpg';

const BannerAvailability = () => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Background}>
                <img src={img} alt="hotel-banner" />
            </div>
            <div className={classes.Container}>
                <div className={classes.SearchForm}>
                    <div className={classes.Title}>Antavaya Voucher</div>
                    <div className={classes.Desc}>Stay #DirumahAja and get bonus, free travel insurance and free transvision subcription</div>
                </div>
            </div>
        </div>
    )

}

export default BannerAvailability;