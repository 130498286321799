import React, { useState } from 'react';
import classes from './RedemAndAdditionalInfo.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import List from '../../../../../UI/List/List';

const redemAndAdditionalInfo = (props) => {
    const [menuActive, setMenuActive] = useState('Redemption');

    const setMenuActiveHandler = menuActive => {
        setMenuActive(menuActive);
    }

    let hightlightList = props.redemptionList;
    if (menuActive === 'Additional') {
        hightlightList = props.additionalList;
    }
    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                <ul className={classes.Tabmenu}>
                    <li className={(menuActive === 'Redemption') ? classes.Active: ''}
                        onClick={() => setMenuActiveHandler('Redemption')}>Redemption Info</li>
                    <li className={(menuActive === 'Additional') ? classes.Active: ''}
                        onClick={() => setMenuActiveHandler('Additional')}>Additional Info</li>
                </ul>
                <List listColor={(menuActive === 'Redemption') ? 'grey': 'grey'} list={hightlightList}/>
            </div>
        </PanelBox>
    )
}

export default redemAndAdditionalInfo;
