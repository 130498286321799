import React from 'react';
import classes from './Text.module.scss';

const text = (props) => {
    let textClasses = [classes.Tx];
    if (props.classes && props.classes.length > 0) {
        props.classes.forEach((className) => {
            textClasses.push(classes[className]);
        });
    }
    return (
        <div className={textClasses.join(' ')}>
            {props.children}
        </div>
    );
}

export default text;