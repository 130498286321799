import React from 'react';
import classes from './Button.module.scss';
import Icon from '../../../../UI/Icon/Icon';

const button = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Item} onClick={props.showSortPopupClicked}>
                <Icon name="ico-sort" fill="#CD2033" stroke="none" />
                <span className={classes.Label}>Sort</span>
            </div>
            <div className={classes.Item} onClick={props.showFilterPopupClicked}>
                <Icon name="ico-filter" fill="#CD2033" stroke="none" />
                <span className={classes.Label}>Filter</span>
            </div>
        </div>
    );
} 

export default button;