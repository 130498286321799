import React, { useState, useEffect } from 'react';
import classes from './FlagList.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import { IMAGE_URl } from '../../../../../constant/ResourceUrl';
import CSSTransition from 'react-transition-group/CSSTransition';

const FlagList = props => {
    const [filter, setFilter] = useState('');

    useEffect(() => {
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [props.show]);

    const setValueHandler = (val) => {
        props.hideListClicked();
        props.selected(val);
    }

    let options = null;
    if (props.dialCodes && props.dialCodes.length > 0) {
        options = props.dialCodes.filter(dialCode => { 
            return (filter === '') || dialCode.dial_code.toLowerCase().indexOf(filter.toLowerCase()) !== -1 || dialCode.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        }).map((dialCode, index) => {
            let code = dialCode.dial_code;
            if (props.type === 'country') {
                code = dialCode.code;
            }
            return (
                <li key={index} className={classes.Item} onClick={() => setValueHandler(code)}>
                    <div className={classes.Country}>
                        <img className={classes.Image} src={IMAGE_URl + 'flags/' + dialCode.code + '.png'} alt={dialCode.name} />
                        <div className={classes.Label}>{dialCode.name}</div>
                    </div>
                    <div>
                        {code}
                    </div>
                </li>
            );
        });
    }

    return (
        <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={300}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close
            }}> 
            <div className={classes.Wrapper}>
                <div className={classes.Header}>
                    <div className={classes.SearchForm}>
                        <div className={classes.FirstIcon}>
                            <Icon name="ico-search" width="18" stroke="none" fill="#202124" />
                        </div>
                        <input
                            value={filter} 
                            onChange={(event) => setFilter(event.target.value)} 
                            className={classes.Input} 
                            placeholder="Enter your country"/>
                    </div>
                    <div className={classes.BackBtn} onClick={props.hideListClicked}>
                        <Icon name="ico-close" fill="#202124" stroke="none" />
                    </div>
                </div>
                <div className={classes.Content}>
                    <ul className={classes.List}>
                        {options}
                    </ul>
                </div>
            </div>
            </CSSTransition>
    );
}

export default FlagList;