import React from 'react';
import classes from './TourDetail.module.scss';
import Summary from './Summary/Summary';
import Detail from './Detail/Detail';
import Participant from './Participant/Participant';
import PriceDetail from '../../../../../components/Mobile/Shared/PriceDetail/PriceDetail';

const tourDetail = (props) => {
    let pricing = [];
    let total = 0;
    if (props.detailTour) {
        pricing = props.detailTour.pricing;
        total = props.detailTour.totalPrice;
    }
    return (
        <div>
            <div className={classes.Section}>
                <Summary isLoading={props.isLoading} detailTour={props.detailTour}/>
            </div>
            <div className={classes.Section}>
                <div className={classes.SubTitle}>Tour Details</div>
                <Detail detailTour={props.detailTour}/>
            </div>
            <div className={classes.Section}>
                <div className={classes.SubTitle}>Participants</div>
                <Participant detailTour={props.detailTour}/>
            </div>
            <div className={classes.Section}>
                <div className={classes.SubTitle}>Price Details</div>
                <PriceDetail prices={pricing} total={total}/>
            </div>
        </div>
    )
}

export default tourDetail;

