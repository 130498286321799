import React from 'react';
import classes from './TourItem.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import Button from '../../../../../UI/Button/Button';
import CurrencyFormat from 'react-currency-format';
import { toCapitalize } from '../../../../../../helper/GlobalFunc';
import Included from '../../../Included/Included';
import { DEFAULT_IMG } from '../../../../../../constant/Content';
import Ribbon from '../../../../../UI/Ribbon/Ribbon';

const tourItem = (props) => {

    let image = '';
    if (props.packageItem.defaultImage) {
        image = props.packageItem.defaultImage.url;
    }

    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                <div className={classes.RibbonWrapper}>
                    <Ribbon text="free voucher tour" position="left" size="small" />
                </div>
                <div className={classes.Image}>
                    <img onError={DEFAULT_IMG} src={image} alt="" />
                </div>
                <div className={classes.Description}>
                    <a href={'/tour/detail/'+ props.packageItem.slug } className={classes.Title}>
                        {props.packageItem.days + 'D '} {toCapitalize(props.packageItem.inventoryTitle)}
                    </a>
                    <div className={classes.SubTitle}>{props.packageItem.days} Days & {props.packageItem.night} Night</div>
                    <div className={classes.Included}>
                        <div className={classes.Label}>Included in package:</div>
                        <div className={classes.Includes}>
                            {(props.packageItem.includeFlight) &&
                                <div className={classes.Item}>
                                    <Included label="Flight" color="black" />
                                </div>
                            }
                            {(props.packageItem.includeHotel) && 
                                <div className={classes.Item}>
                                    <Included label="Hotel" color="black"/>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={classes.PriceAction}>
                        <div>
                            <div className={classes.Label}>Starting from</div>
                            <div className={classes.Price}>
                                <CurrencyFormat value={props.packageItem.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            </div>
                        </div>
                        <div className={classes.Action}>
                            <a href={'/tour/detail/'+ props.packageItem.slug }>
                                <Button classes={['BtnRed', 'BtnSmall']}>View Package</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </PanelBox>
    );
}

export default tourItem;