import React from 'react';
import classes from './PriceDetail.module.scss';
import PriceDetail from './PriceDetail/PriceDetail';
import {currencyFormat} from '../../../../../helper/GlobalFunc';

const priceDetail = (props) => {
    if (!props.isLoading && props.bookingData) {
        return (
            <div className={classes.Wrapper}>
                <PriceDetail prices={props.bookingData.pricing}/>
                <div className={classes.TotalPrice}>
                    <div>Total Price</div>
                    <div className={classes.Price}>{currencyFormat(props.bookingData.totalPrice)}</div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default priceDetail;