import React, { useState, useEffect } from 'react';
import classes from './TourList.module.scss';
import TourItem from './TourItem/TourItem';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import PackagesListLoader from '../../../../UI/Loaders/Desktop/List/PackagesListLoader';
import EmptyData from '../../../../UI/EmptyData/EmptyData';
import { PER_PAGE_TOUR } from '../../../../../constant/General';

function tourList(props) {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [maxShowItem, setMaxShowItem] = useState(PER_PAGE_TOUR);

    useEffect(() => {
        generatePaginate(props.tourPackageList.length);
    }, [props.tourPackageList]);

    const generatePaginate = (totalItem) => {
        if (totalItem > 0) {
            const totalPage = Math.floor((totalItem + PER_PAGE_TOUR - 1) / PER_PAGE_TOUR);
            setTotalPage(totalPage);
            setCurrentPage(1);
        }

    }

    const showMore = () => {
        let nextPage = currentPage + 1;
        if (currentPage < totalPage) {
            setCurrentPage(nextPage);
            setMaxShowItem(nextPage * PER_PAGE_TOUR);
        }
    }

    let tourPackageList = [];
    if (props.isLoading) {
        for (let index = 0; index < 5; index++) {
            let data = (
                <div className={classes.Item} key={index}>
                    <PackagesListLoader/>
                </div>
            )
            tourPackageList.push(data)
        }
    } else {
        if (props.tourPackageList && props.tourPackageList.length > 0) {
            tourPackageList = props.tourPackageList.filter((item, index) => index < maxShowItem).map((packageItem, index) => {
                return (
                    <div key={index} className={classes.Item}>
                        <TourItem packageItem={packageItem} goToDetailPageHandler={(id) => props.goToDetailPageHandler(id)} />
                    </div>
                );
            });
        } else {
            tourPackageList = (
                <EmptyData
                    type="tour-empty"
                    title="Tour empty"
                    desc="Sorry tour is unavailable Please see other destinations"/>
            )
        }
    }

    return (
        <div className={classes.TourList}>
            {tourPackageList}
            {totalPage > 1 && currentPage < totalPage ? <PanelBox>
                <div onClick={showMore} className={classes.ShowMore}>
                    SHOW MORE RESULTS
                </div>
            </PanelBox> : ''}
            
        </div>
    );
}

export default tourList;