import React from 'react'
import classes from './Antavaya.module.scss'
import PanelBox from '../../../../../../../../components/UI/PanelBox/PanelBox'
import Logo from '../../../../../../../../assets/images/logo.png'

const Antavaya = () => {
  return (
    <div className={classes.Panel}>
      <PanelBox>
        <div className={classes.Card}>
          <div className={classes.CardContent}>
            <div className={classes.Info}>
              <div className={classes.Title}>Antavaya</div>
              <div className={classes.Desc}>Corporate Travel</div>
            </div>
            <div className={classes.Image}>
              <img className={classes.Img} src={Logo} alt={'Tour leader'} />
            </div>
          </div>
        </div>
        <div className={classes.Divider} />
        <div className={classes.Detail}>
          <div className={classes.Title}>Whatsapp</div>
          <div className={classes.Value}>+62 813 8396 1533</div>
        </div>
        <div className={classes.Detail}>
          <div className={classes.Title}>Call Center</div>
          <div className={classes.Value}>+6221 6253 919</div>
        </div>
        <div className={classes.Detail}>
          <div className={classes.Title}>Email</div>
          <div className={classes.Value}>hello@antavaya.com</div>
        </div>
      </PanelBox>
    </div>
  )
}
export default Antavaya