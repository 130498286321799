import React, { useState, useEffect } from 'react';
import classes from './UserContainer.module.scss';
import Header from './../../../../components/Desktop/Shared/Header/Header';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import { USER_NAV } from '../../../../constant/LinkList';
import Icon from '../../../../components/UI/Icon/Icon';
import Profile from '../../../../components/Desktop/NonProducts/User/Profile/Profile';
import MyBooking from '../../../../components/Desktop/NonProducts/User/MyBooking/MyBooking';
import MyCard from '../../../../components/Desktop/NonProducts/User/MyCard/MyCard';
import PromoInfo from '../../../../components/Desktop/NonProducts/User/PromoInfo/PromoInfo';
import Setting from '../../../../components/Desktop/NonProducts/User/Setting/Setting';
import { connect } from 'react-redux';
import * as authAction from '../../../../redux/Shared/Auth/AuthAction';
import * as memberAction from '../../../../redux/Shared/Member/MemberAction';
import * as myBookingAction from '../../../../redux/Shared/MyBooking/MyBookingAction';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';

const userContainer = props => {
    const [activePageState, setActivePageState] = useState('profile');
    const [isLogoutConfirm, setIsLogoutConfirm] = useState(false);

    useEffect(() => {
        props.getDetailMemberHandler();
        let pathArr = window.location.pathname.split('/');
        let slug = pathArr[2];
        if (slug === undefined) {
            slug = 'profile';
        }
        setActivePageState(slug);
    }, []);

    const menuClickHandler = (activePage) => {
        if (activePage !== 'logout') {
            props.history.push('/user/' + activePage);
            props.setInitMyBooking();
            setActivePageState(activePage);
        } else {
            setIsLogoutConfirm(true);
        }
    }
    
    const confirmLogoutClickHandler = () => {
        setIsLogoutConfirm(false);
        props.logout();
    }

    const userNav = USER_NAV.map( (item, index) => {
        let navItem = null;
        let itemClass = [classes.Item];
        if (activePageState === item.slug) {
            itemClass = [classes.Item, classes.Active];
        }
        if (item.isActive) {
            navItem = <li key={index} className={itemClass.join(' ')} onClick={() => menuClickHandler(item.slug)}>
                <Icon name={item.icon} fill="#6A6E73" stroke="none" />
                <div className={classes.Label}>{item.name}</div>
            </li>;
        }
        return navItem;
    });

    let activePage = <Profile />;
    switch (activePageState) {
        case 'my-booking':
            activePage = <MyBooking />
            break;
        case 'my-card':
            activePage = <MyCard />
            break;
        case 'promo-info':
            activePage = <PromoInfo />
            break;
        case 'setting':
            activePage = <Setting />
            break;
        default:
            activePage = <Profile />
            break;
    }

    return (
        <div className={classes.Wrapper}>
            <Confirm 
                show={isLogoutConfirm}
                title="Are you sure?"
                desc="If you logout, You can’t use the features and benefits as an Antavaya member"
                cancelClicked={() => setIsLogoutConfirm(false)}
                confirmClicked={confirmLogoutClickHandler}/>

            <Header />
            <div className={classes.Container}>
                <div className={classes.LeftNav}>
                    <PanelBox>
                        <ul className={classes.MenuList}>
                            {userNav}
                        </ul>
                    </PanelBox>
                </div>
                <div className={classes.RightContent}>
                    <PanelBox>
                        <div className={classes.Content}>
                            {activePage}
                        </div>
                    </PanelBox>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        logout : () => dispatch(authAction.logout()),
        getDetailMemberHandler: () => dispatch(memberAction.getDetailMember()),
        setInitMyBooking: () => dispatch(myBookingAction.setInitState())
    }
}

export default connect(null, mapDispatchToProps)(userContainer);