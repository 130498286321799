import { updateObject } from '../../utility';
import * as validation from '../../../helper/Validation';

const formData = {
    bookingId: {
        value: '',
        hasError: false,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    email: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true,
            isEmail: true
        }
    },
};

const initialState = {
    isValidForm: false,
    formData: formData,
    isFetchBookingDetail: true,
    isErrorFetchBookingDetail: false,
    bookingDetail: null
}

const setValidForm = (inputForm) => {
    let isValidForm = true;
    if (inputForm) {
        for (const inputIdentifier in inputForm) {
            if (inputForm[inputIdentifier].value !== undefined && inputForm[inputIdentifier].rules !== undefined ) {
                if (inputForm[inputIdentifier].hasError) {
                    isValidForm = false;
                    break;
                }
            }
        }
    }
    return isValidForm;
}

const checkingForm = state => {
    let formData = {...state.formData};
    for (const identifier in formData) {
        let validate = validation.validate(formData[identifier].rules, formData[identifier].value);
        formData[identifier] = {...formData[identifier], ...validate};
    }

    return updateObject(state, { formData });
}

const inputChanged = (state, action) => {
    let formNameCp = {...state.formData};
    let isValidForm = {...state.isValidForm};
    let value = action.payload.value;
    let identifier = action.payload.identifier;

    let validate = validation.validate(formNameCp[identifier].rules, value);
    formNameCp[identifier] = {...formNameCp[identifier], ...validate};
    isValidForm = setValidForm(formNameCp);

    return updateObject(state, {formData: formNameCp, isValidForm})
}

const setIsFetchBookingDetail = (state, action) => {
    return updateObject(state, {isFetchBookingDetail: action.payload.value})
}

const setBookingDetail = (state, action) => {
    return updateObject(state, {bookingDetail: action.payload.data})
}

const setIsErrorFetchBookingDetail = (state, action) => {
    return updateObject(state, {isErrorFetchBookingDetail: action.payload.value})
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case 'RETRIEVE_BOOKING_INPUT_CHANGED': return inputChanged(state, action);
        case 'RETRIEVE_BOOKING_IS_FETCH_BOOKING_DETAIL': return setIsFetchBookingDetail(state, action);
        case 'RETRIEVE_BOOKING_IS_ERROR_FETCH_BOOKING_DETAIL': return setIsErrorFetchBookingDetail(state, action);
        case 'RETRIEVE_BOOKING_SET_DETAIL': return setBookingDetail(state, action);
        case 'RETRIEVE_BOOKING_CHECKING_FORM': return checkingForm(state, action);
        default: return state;
    }
}

export default reducer;