import React from 'react';
import classes from './Detail.module.scss';
import PanelBox from '../../../../../../components/UI/PanelBox/PanelBox';
import { formatHumanDate, toCapitalize, pluralize } from '../../../../../../helper/GlobalFunc';
import { DEFAULT_IMG } from '../../../../../../constant/Content';
import Icon from '../../../../../../components/UI/Icon/Icon';
import ContentLoader from 'react-content-loader';

const detail = (props) => {
    let content = null;
    if (props.detailTour) {
        let flight = null;
        if (props.detailTour.flight.flightName) {
            flight = (
                <div className={classes.Item}>
                    <Icon name="ico-flight-o" fill="#6A6E73" stroke="none" />
                    <span className={classes.Label}>{props.detailTour.flight.flightName}</span>
                </div>
            )
        }
        content = (
            <React.Fragment>
                <div className={classes.Header}>
                    <div className={classes.Image}>
                        <img onError={DEFAULT_IMG} className={classes.Img} src={props.detailTour.image.url} alt={props.detailTour.image.title} />
                    </div>
                    <div className={classes.Info}>
                        <div className={classes.Title}>{toCapitalize(props.detailTour.title)}</div>
                        <div className={classes.Desc}>{props.detailTour.days} Days & {props.detailTour.night} Nights</div>
                    </div>
                </div>
                <div className={classes.Body}>
                    <div className={classes.Item}>
                        <Icon name="ico-calendar" fill="#6A6E73" stroke="none" />
                        <span className={classes.Label}>
                            {formatHumanDate(props.detailTour.startDate)}
                            <span> - </span>
                            {formatHumanDate(props.detailTour.endDate)}
                        </span>
                    </div>
                    {flight}
                    <div className={classes.Item}>
                        <Icon name="ico-user-o" fill="#6A6E73" stroke="none" />
                        <span className={classes.Label}>
                            {pluralize(props.detailTour.roomCount, 'Room', 'Rooms')} - {pluralize(props.detailTour.roomAdultCount, 'Adult', 'Adults')} - {pluralize(props.detailTour.roomChildCount, 'Child', 'Children')}
                        </span>
                    </div>
                </div>
            </React.Fragment>
        )
    } else {
        content = (
            <div className="u-p-16">
                <ContentLoader
                    height={220}
                    speed={1}
                    primaryColor={'#e6e6e6'}
                >
                    <rect x="0" y="0" rx="40" ry="40" width="80" height="80" />
                    <rect x="100" y="10" rx="2" ry="2" width="150" height="20" />
                    <rect x="100" y="40" rx="2" ry="2" width="100" height="20" />

                    <rect x="0" y="120" rx="2" ry="2" width="180" height="20" />
                    <rect x="0" y="160" rx="2" ry="2" width="230" height="20" />
                    <rect x="0" y="200" rx="2" ry="2" width="200" height="20" />
                </ContentLoader>
            </div>);
    }
    return (
        <PanelBox>
            {content}
        </PanelBox>
    )
}

export default detail;
