import React, { useState } from "react";
import Header from "../../../../components/Mobile/Shared/Header/Header";
import classes from "./ForgotBookingID.module.scss";
import TextField from "../../../../components/UI/Inputs/TextField/TextField";
import PanelBox from "../../../../components/UI/PanelBox/PanelBox";
import Button from "../../../../components/UI/Button/Button";
import MobileSelectFlag from "../../../../components/UI/Inputs/MobileSelectFlag/MobileSelectFlag";

function ForgotBookingID(props) {
	const [flag, setFlag] = useState("+62");
	let selectedFlag = val => {
		setFlag(val);
	};
	return (
		<div className={classes.Wrapper}>
			<div className={classes.Head}>
				<Header classes={['ClBlack', 'BgWhite', 'Shadow']} 
					icoColor="black"
					align="center"
					leftIcon="back"
					leftClicked={() => props.history.goBack()}
					content={<div className="u-mr-30">Forgot Booking ID</div>}/>
			</div>
			<div className={classes.Body}>
				<div className={classes.HeadText}>
					Please enter your email address you've using for your booking below
					and we will send your booking ID
				</div>
				<div className="u-mb-30">
					<PanelBox>
						<div className="u-p-16">
							<div className="u-mb-24">
								<div className="label">Email</div>
								<TextField
									placeholder="e.g. yourmail@example.com"/>
							</div>
							<div>
								<div className="label">Phone Number</div>
								<div className="u-display-flex">
									<div className="u-flex-4 u-mr-8">
										<MobileSelectFlag value={flag} selected={selectedFlag} />
									</div>
									<div className="u-flex-6">
										<TextField
										type="text"
										placeholder="e.g. 8123456789"
										value="" />
									</div>
								</div>
							</div>
						</div>
					</PanelBox>
				</div>
				
				<Button
					classes={["BtnRed", "BtnBlock", "BtnMedium"]}
					children={"Request My Booking ID"} />
			</div>
		</div>
	);
}
export default ForgotBookingID;
