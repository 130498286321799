import {updateObject} from '../../utility';
import * as validation from '../../../helper/Validation';

const formData = {
    name: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true,
        }
    },
    email: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true,
            isEmail: true
        }
    },
    dialCode: {
        value: '+62',
        hasError: false,
        errorMsg: '',
        rules : {required: true}
    },
    phone: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true,
            isNumericPhone: true
        }
    }
}

const initialState = {
    showAlert: false,
    isLoadingSubmitForm: false,
    isVerified: false,
    isValidForm: false,
    selectedFile: null,
    formData: {...formData}
}

const setValidForm = (inputForm) => {
    let isValidForm = true;
    if (inputForm) {
        for (const inputIdentifier in inputForm) {
            if (inputForm[inputIdentifier].value !== undefined && inputForm[inputIdentifier].rules !== undefined ) {
                if (inputForm[inputIdentifier].hasError) {
                    isValidForm = false;
                    break;
                }
            }
        }
    }
    
    return isValidForm;
}

const inputChangeHandler = (state, action) => {
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let formData = {...state.formData};
    let isValidForm = {...state.isValidForm};

    let validate = validation.validate(formData[identifier].rules, value);
    formData[identifier] = {...formData[identifier], ...validate};

    isValidForm = setValidForm(formData);
    return updateObject(state, {formData, isValidForm});
}
const dialCodeChangeHandler = (state, action) => {
    let formData = {...state.formData};
    formData.dialCode.value = action.value;
    return updateObject(state, {formData});
}

const fileSelectHandler = (state, action) => {
    let selectedFile = action.selectedFile;
    return updateObject(state, {selectedFile});
}

const verifyClickHandler = state => {
    return updateObject(state, {isVerified: true});
}

const alertConfirmedHandler = state => {
    return updateObject(state, {formData, showAlert: false, isVerified: false, isValidForm: false, selectedFile: null});
}

const formCheckingHandler = (state) => {
    let formData = {...state.formData};
    for (const identifier in formData) {
        let validate = validation.validate(formData[identifier].rules, formData[identifier].value);
        formData[identifier] = {...formData[identifier], ...validate};
    }
    return updateObject(state, {formData});
}

const submitSuccessHandler = state => {
    return updateObject(state, {showAlert: true, isLoadingSubmitForm: false});
}

const loadingSubmitFormHandler = state => {
    return updateObject(state, {isLoadingSubmitForm: true});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'CAREER_FORM_INPUT_CHANGED': return inputChangeHandler(state, action);
        case 'CAREER_FORM_DIAL_CODE_CHANGED': return dialCodeChangeHandler(state, action);
        case 'CAREER_FORM_FILE_SELECTED': return fileSelectHandler(state, action);
        case 'CAREER_FORM_VERIFY_CLICKED': return verifyClickHandler(state);
        case 'CAREER_FORM_FORM_CHECKING': return formCheckingHandler(state);
        case 'CAREER_FORM_ALERT_CONFIRMED': return alertConfirmedHandler(state);
        case 'CAREER_FORM_SUBMIT_SUCCESS': return submitSuccessHandler(state);
        case 'CAREER_FORM_LOADING_SUBMIT_FORM': return loadingSubmitFormHandler(state);
        default: return state;
    }
};

export default reducer;