import React from 'react';
import classes from './Ticket.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../../UI/Icon/Icon';
import CurrencyFormat from 'react-currency-format';

const ticket = (props) => {

    let content = null;
    if (props.tickets) {
        content = props.tickets.map((item, index) => {
            let icon = 'ico-radio-button-unchecked';
            let iconColor = '#E6E6E6';
            if (props.selectedTicket === item.tourAdmissionPriceID) {
                icon = 'ico-radio-button-checked';
                iconColor = '#CD2033';
            }
            return (
                <div className="u-mb-16" key={index}>
                    <PanelBox>
                        <div className={classes.Item} onClick={() => props.onSelectTicketHandler(item.tourAdmissionPriceID, item.priceArray)}>
                            <div className={classes.Left}>
                                <div className={classes.Title}>{item.name}</div>
                                <div>
                                    <CurrencyFormat className={classes.Price} value={item.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                                    <div className={classes.Light}>/pax</div>
                                </div>
                            </div>
                            <div className={classes.Right}>
                                <Icon name={icon} fill={iconColor} stroke="none" width={24}/>
                            </div>
                        </div>
                    </PanelBox>
                </div>
            )
        })
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Title}>Selected Package</div>
            {content}
        </div>
    )
}

export default ticket;
