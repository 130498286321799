import { updateObject } from '../../utility';

const init = {
    isFetch: true,
    isErrorFetch: false,
    bookingList: [],
    bookingId: null,
    bookingDetail: null,
    isFetchBookingDetail: true,
    isErrorFetchBookingDetail: false
}

const initialState = init;

const setInitState = (state) => {
    return updateObject(state, init);
}

const setBookingList = (state, action) => {
    return updateObject(state, {bookingList: action.payload.data});
}

const setBookingDetail = (state, action) => {
    return updateObject(state, {bookingDetail: action.payload.data});
}

const setIsFetch = (state, action) => {
    return updateObject(state, {isFetch: action.payload.value});
}

const setIsErrorFetch = (state, action) => {
    return updateObject(state, {isErrorFetch: action.payload.value});
}

const setBookingId = (state, action) => {
    return updateObject(state, {bookingId: action.payload.id});
}

const setIsFetchBookingDetail = (state, action) => {
    return updateObject(state, {isFetchBookingDetail: action.payload.value});
}

const setIsErrorFetchBookingDetail = (state, action) => {
    return updateObject(state, {isErrorFetchBookingDetail: action.payload.value});
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case 'SET_MY_BOOKING_INIT_STATE': return setInitState(state);
        case 'SET_MY_BOOKING_LIST': return setBookingList(state, action);
        case 'SET_IS_FETCH_MY_BOOKING': return setIsFetch(state, action);
        case 'SET_IS_ERROR_FETCH_MY_BOOKING': return setIsErrorFetch(state, action);

        case 'SET_MY_BOOKING_ID': return setBookingId(state, action);
        case 'SET_MY_BOOKING_DETAIL': return setBookingDetail(state, action);
        case 'SET_IS_FETCH_MY_BOOKING_DETAIL': return setIsFetchBookingDetail(state, action);
        case 'SET_IS_ERROR_FETCH_MY_BOOKING_DETAIL': return setIsErrorFetchBookingDetail(state, action);
        default: return state;
    }
}

export default reducer;