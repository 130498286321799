import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

export const setIsFetchVirtualTour = (value) => {
    return {
        type: 'SET_IS_FETCH_VIRTUAL_TOUR',
        payload: {
            value
        }
    }
}

export const setIsErrorFetchVirtualTour = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_VIRTUAL_TOUR',
        payload: {
            value
        }
    }
}

export const setVirtualTours = (value) => {
    return {
        type: 'SET_VIRTUAL_TOURS',
        payload: {
            value
        }
    }
}

export const fetchVirtualTour = () => {
    return dispatch => {
        dispatch(setIsFetchVirtualTour(true));
        axios.get(ANTAVAYA_API + 'virtual-tour/list')
            .then((response) => {
                dispatch(setVirtualTours(response.data.data[0]))
                dispatch(setIsFetchVirtualTour(false));
            })
            .catch((error) => {
                dispatch(setIsFetchVirtualTour(false));
                dispatch(setIsErrorFetchVirtualTour(true))
                console.log('error', error)
            })
    }
}