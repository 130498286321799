import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

export const setTours = ( tours ) => {
    return {
        type: 'SET_TOURS',
        tours
    };
};

export const setIsFetchTour = (value) => {
    return {
        type: 'SET_IS_FETCH_TOUR',
        value
    }
}

export const setSortBy = (value) => {
    return {
        type: 'SET_SORT_BY',
        sortBy: value
    }
}

export const initTourPackages = (destinationSelected) => {
    return dispatch => {
        dispatch(setIsFetchTour(true));
        // replace region parameter to destination after new api ready!
        axios.get( ANTAVAYA_API + 'v1/tour/list?destination=' + destinationSelected )
            .then( response => {
                dispatch(setTours(response.data));
                dispatch(setIsFetchTour(false));
            } )
            .catch( error => {
                //case error
                dispatch(setIsFetchTour(false));
            });
    };
};