import React from 'react';
import classes from './ContactInfo.module.scss';
import PanelBox from '../../../../../components/UI/PanelBox/PanelBox';
import MobileSelect from '../../../../UI/Inputs/MobileSelect/MobileSelect';
import TextField from '../../../../UI/Inputs/TextField/TextField';
import MobileSelectFlag from '../../../../UI/Inputs/MobileSelectFlag/MobileSelectFlag';

// import { connect } from 'react-redux';
// import * as VirtualTourBookingAction from '../../../../../redux/VirtualTour/VirtualTourBookingAction';

const contactInfo = (props) => {
    return (
        <PanelBox>
        <div className={classes.Wrapper}>
            <div>
                <ul className={classes.Form}>
					<li className={classes.Row}>
						<div className={classes.Input}>
							<label>Title</label>
							<MobileSelect
								value={props.formData.title.value}
								options={props.formData.title.options}
								changed={(val) => props.inputChanged('title', val)}/>
						</div>
					</li>
					<li className={classes.Row}>
						<div className={classes.Input}>
							<label>First Name</label>
							<TextField
								placeholder="e.g. Julie"
								value={props.formData.firstName.value}
								changed={(event) => props.inputChanged('firstName', event.target.value)}
								hasError={props.formData.firstName.hasError && props.formData.firstName.errorMsg !== ''}
								errorMsg={props.formData.firstName.errorMsg}/>
						</div>
					</li>
					<li className={classes.Row}>
						<div className={classes.Input}>
							<label>Last Name</label>
							<TextField
								placeholder="e.g. Applesed"
								value={props.formData.lastName.value}
								changed={(event) => props.inputChanged('lastName', event.target.value)}
								hasError={props.formData.lastName.hasError && props.formData.lastName.errorMsg !== ''}
								errorMsg={props.formData.lastName.errorMsg}/>
						</div>
					</li>
					{/* <li className={classes.Row}>
						<div className={classes.Input}>
							<label>Zoom ID</label>
							<TextField
								placeholder="e.g. AsepZoom"
								value={props.formData.zoomId.value}
								changed={(event) => props.inputChanged('zoomId', event.target.value)}
								hasError={props.formData.zoomId.hasError && props.formData.zoomId.errorMsg !== ''}
								errorMsg={props.formData.zoomId.errorMsg}/>
						</div>
					</li> */}
					<li className={classes.Row}>
						<div className={classes.Input}>
							<label>Email</label>
							<TextField
								placeholder="e.g. youremail@example.com"
								value={props.formData.email.value}
								changed={(event) => props.inputChanged('email', event.target.value)}
								hasError={props.formData.email.hasError && props.formData.email.errorMsg !== ''}
								errorMsg={props.formData.email.errorMsg}/>
						</div>
					</li>
					<li className={[classes.Row, classes.MultiColumn].join(" ")}>
						<div className={[classes.Input, classes.PhoneWrapper].join(" ")}>
							<label>Phone Number</label>
							<div className={classes.Inline}>
								<div className={classes.DialCode}>
									<MobileSelectFlag
										value={props.formData.dialCode.value}
										selected={(val) => props.inputChanged("dialCode", val)}
									/>
								</div>
								<div className={classes.Phone}>
									<TextField
										type="text"
										placeholder="e.g. 8123456789"
										value={props.formData.phone.value}
										changed={(event) => props.inputChanged('phone', event.target.value)}
										hasError={props.formData.phone.hasError && props.formData.phone.errorMsg !== ''}
										errorMsg={props.formData.phone.errorMsg}/>
								</div>
							</div>
						</div>
					</li>
                </ul>
            </div>
        </div>
        </PanelBox>
    )
}

// const mapStateToProps = (state) => {
//     return {
//         formData: state.VirtualTourReducer.formData,
//         isValidForm: state.VirtualTourReducer.isValidForm,
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         inputChanged: (identifier, value) => dispatch(BookingAction.inputChanged(identifier, value)),
//         formChecking: (identifier, value) => dispatch(BookingAction.formChecking(identifier, value))
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(contactInfo);

export default contactInfo;
