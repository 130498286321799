import React from 'react';
import classes from './ElementFilter.module.scss';
import CheckBox from '../../../../../UI/Inputs/CheckBox/CheckBox';

const elementFilter = (props) => {
    let list = null;
    if (props.list && props.list.length > 0) {
        list = props.list.map((item, index) => {
            return (
                <li key={index}>
                    <CheckBox 
                        label={item.name} 
                        checked={item.isChecked}
                        changed={(event) => props.filterChanged(event, index)} />
                </li>
            );
        });

    }
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Title}>
                {props.title} 
                <div className={classes.Clear} onClick={props.resetClicked}>Clear</div>
            </div>
            <ul className={classes.List}>
                {list}
            </ul>
        </div>
    );  
}

export default elementFilter;