import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

export const setTourDetail = ( detailTour ) => {
    return {
        type: 'SET_TOUR_DETAIL',
        payload: detailTour
    }
}

export const scheduleChanged = ( scheduleId ) => {
    return {
        type: 'SET_TOUR_SCHEDULE',
        scheduleId
    }
}

export const setTourEmpty = () => {
    return {
        type: 'SET_TOUR_EMPTY'
    }
}

export const addRoom = () => {
    return {
        type: 'ADD_ROOM'
    }
}

export const removeRoom = (index) => {
    return {
        type: 'REMOVE_ROOM',
        index
    }
}

export const addGuest = (guestType, index) => {
    return {
        type: 'ADD_GUEST',
        payload: { guestType, index }
    }
} 

export const removeGuest = (guestType, index) => {
    return {
        type: 'REMOVE_GUEST',
        payload: { guestType, index }
    }
} 

export const toggleExtraBed = (value, index) => {
    return {
        type: 'EXTRA_BED_TOGGLE',
        payload: { value, index }
    }
} 

export const handleSpinner = (isSubmit) => {
    return {
        type: 'CHANGE_LOADING_SPINNER',
        isSubmit
    }
}

export const setIsChangeParticipantLoading = (index, value) => {
    return {
        type: 'SET_IS_CHANGE_PARTICIPANT_LOADING',
        payload: { index, value }
    }
}

export const initDetailTour = (slug) => {
    return dispatch => {
        axios.get(ANTAVAYA_API + 'v1/tour/' + slug)
            .then( response => {
                if (response.data === null) {
                    dispatch(setTourEmpty());
                } else {
                    dispatch(setTourDetail(response.data));
                }
            })
            .catch((error) => {
                console.log(error.response)
                if (error.response.status === 400 && error.response.data.message === 'no tour found') {
                    dispatch(setTourEmpty());
                }
            });
    }
}

export const submitBookingTour = (submitData, history) => {
    return dispatch => {
        dispatch(handleSpinner(true));
        axios.post('/tourBookingData.json', submitData)
            .then((response) => {
                dispatch(handleSpinner(false));
                document.body.style.overflowY = 'auto';
                history.push('/tour/booking'+ response.data.name);
            })
            .catch((error) => {
                dispatch(handleSpinner(false));
                document.body.style.overflowY = 'auto';
                console.log(error);
            })
    }
}

export const participantChanged = (guestType, index, action, data) => {
    return dispatch => {
        dispatch(setIsChangeParticipantLoading(index, true));
        axios.post(ANTAVAYA_API + 'v1/tour/room-type', data)
            .then((response) => {
                if (response) {
                    if (action === 'ADD') {
                        dispatch(addGuest(guestType, index));
                    } else if(action === 'REMOVE') {
                        dispatch(removeGuest(guestType, index));
                    } else if(action === 'IS_EXTRABED') {
                        dispatch(toggleExtraBed(guestType, index));
                    }
                }
                dispatch(setIsChangeParticipantLoading(index, false));
            })
            .catch((error) => {
                dispatch(setIsChangeParticipantLoading(index, false));
            })
    }
}