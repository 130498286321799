import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Lang from './lang/Lang';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import history from './history';
// import WhatsappLink from './components/Desktop/Shared/WhatsappLink/WhatsappLink';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './protectedRoute';
import Icon from './components/UI/Icon/Icon';

const App = props => {
	Lang.setLanguage(props.locale);
	const routeList = routes.map((route, key) => {
		if (route.isProtected) {
			return <ProtectedRoute
				key={key}
				history={history}
				path={route.path}
				component={(isMobile) ? route.mobileComponent : route.desktopComponent}
				exact={route.exact || false} />
		}
		return <Route
			key={key}
			history={history}
			path={route.path}
			component={(isMobile) ? route.mobileComponent : route.desktopComponent}
			exact={route.exact || false}
		/>
	});

	// let whatsappLink = <WhatsappLink />;
	let toasPosition = 'top-right';
	if (isMobile) {
		// whatsappLink = null;
		toasPosition = 'top-center';
	}
	return (
		<div>
			<ToastContainer
				position={toasPosition}
				autoClose={4000}
				closeButton={<Icon name="ico-close" fill="#FFFFFF" stroke="none" />}
				hideProgressBar={true}
				newestOnTop={false}
				rtl={false}
				pauseOnVisibilityChange
				draggable
				pauseOnHover
			/>
			<Switch>
				{routeList}
			</Switch>
			{/* {whatsappLink} */}
		</div>
	);
}

const mapStateToProps = state => {
	return {
		locale: state.lang.locale
	}
}

export default withRouter(connect(mapStateToProps)(App));
