import React from 'react';
import classes from './Intro.module.scss';
import { ABOUT_US } from '../../../../../constant/Content';

const intro = () => {
    const about = ABOUT_US.map( (content, index) => {
        return <p key={index}>{content}</p>;
    });
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className={classes.Title}>About Us</div>
                <div className={classes.Description}>
                    {about}
                </div>
            </div>
        </div>
    );
}

export default intro;