import React, { useState } from 'react';
import classes from './Header.module.scss';
import logo from '../../../../assets/images/logo.png';
import { NAV_MENU } from '../../../../constant/LinkList';
import Button from '../../../UI/Button/Button';
import LoginRegister from '../LoginRegister/LoginRegister';
import RetriveBookingForm from '../RetieveBooking/Form/Form';
import UserNav from './UserNav/UserNav';
import Auth from '../../../../middleware/Auth';
import { connect } from 'react-redux';
import * as authAction from '../../../../redux/Shared/Auth/AuthAction';

const Header = (props) => {
    const [activeForm, setActiveForm] = useState('login');
    const [showRetriveBooking, setShowRetriveBooking] = useState(false);

    const navMenu = NAV_MENU.map((item, index) => {
        return <li key={index} className={classes.Item}><a href={item.url} target={item.target}>{item.name}</a></li>;
    });

    const showShowLoginRegisterForm = form => {
        setActiveForm(form);
        props.setShowLoginRegisterForm(true);
    }

    let navWithAuth = (
        <ul className={classes.Nav}>
            <li onClick={() => setShowRetriveBooking(true)} className={[classes.Item, classes.Link].join(' ')}>My Booking</li>
            <li className={[classes.Item, 'u-ml-12'].join(' ')}>
                <Button classes={['BtnOutline', 'BtnSmall']} clicked={() => showShowLoginRegisterForm('login')}>Login</Button>
            </li>
            <li className={[classes.Item, 'u-ml-12'].join(' ')}>
                <Button classes={['BtnRed', 'BtnSmall']} clicked={() => showShowLoginRegisterForm('register')}>Register</Button>
            </li>
        </ul>
    );

    if (Auth.isAuthenticated()) {
        navWithAuth = (
            <ul className={classes.Nav}>
                <li className={[classes.Item, classes.Link].join(' ')}>
                    <UserNav />
                </li>
            </ul>
        );
    }

    return (
        
        <div className={classes.Wrapper}>
            <RetriveBookingForm
                show={showRetriveBooking}
                hideClicked={()=>setShowRetriveBooking(false)} />
                
            <LoginRegister
                activeForm={activeForm}
                show={props.showLoginRegisterForm}
                switchFormClicked={ activeForm => setActiveForm(activeForm) }
                hideLoginRegisterFormClicked={() => props.setShowLoginRegisterForm(false)} />

            <div className={classes.Container}>
                <a href="/">
                    <div className={classes.Logo}>
                        <img src={logo} alt="antavaya" />
                    </div>
                </a>
                <div className={classes.NavWrapper}>
                    <ul className={classes.Nav}>
                        {navMenu}
                    </ul>
                    {navWithAuth}
                </div>

            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        showLoginRegisterForm : state.AuthReducer.showLoginRegisterForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setShowLoginRegisterForm : (val) => dispatch(authAction.showLoginRegisterForm(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);