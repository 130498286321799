import React from 'react';
import Modal from '../../../../../UI/Modal/Modal';
import Button from '../../../../../UI/Button/Button';
import artSubmitApplication from '../../../../../../assets/images/art-submit-application.png';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../../redux/Shared/Member/MemberAction';

function emailVerificationModal (props) {

    const hideClicked = () => {
        props.showHideConfirmationEmailClicked(false)
        props.setIsSuccessSendEmailVerify(false);
    }
    let content = <div>
        <div className="u-mb-40">
            <p className="u-text-center u-mb-12 u-color-grey">
                To complete email verification, please press the button below.
            </p>
        </div>
        <div className="u-text-center u-display-flex">
            <div className="u-flex-10"></div>
            <div className="u-flex-10">
                <Button 
                    classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                    isLoading={props.isSendEmailVerifyLoading}
                    clicked={props.sendActivationToEmail}>Verify your Email</Button>
            </div>
            <div className="u-flex-10"></div>
        </div>
    </div>;
    if (props.isSuccessSendEmailVerify) {
        content = <div>
            <div className="u-mb-20">
                <div className="u-text-center">
                    <img src={artSubmitApplication} alt="antavaya" width="200"/>
                </div>
                <p className="u-text-center u-mb-12 u-color-grey">
                    Please click on the link that has just been sent to your email account to verify your email
                </p>
            </div>
            <div className="u-text-center u-display-flex">
                <div className="u-flex-10"></div>
                <div className="u-flex-10">
                    <Button 
                        classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                        isLoading={props.isSendEmailVerifyLoading}
                        clicked={hideClicked}>Ok</Button>
                </div>
                <div className="u-flex-10"></div>
            </div>
        </div>;
    }
    return (
        <Modal 
            title="Email Verification"
            show={props.showConfirmationEmail}
            closeClicked={hideClicked}>
            {content}
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        formEmail: state.MemberReducer.formEmail,
        isSendEmailVerifyLoading: state.MemberReducer.isSendEmailVerifyLoading,
        isSuccessSendEmailVerify: state.MemberReducer.isSuccessSendEmailVerify,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendActivationToEmail: () => dispatch(memberAction.sendActivationToEmail()),
        setIsSuccessSendEmailVerify: (val) => dispatch(memberAction.setIsSuccessSendEmailVerify(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(emailVerificationModal);