import React from 'react';
import classes from './LeftNav.module.scss';
import logo from '../../../../assets/images/logo.png';
import Icon from '../../../UI/Icon/Icon';
import { NAV_MENU, COMPANY_LINK } from '../../../../constant/LinkList';
import CSSTransition from 'react-transition-group/CSSTransition';
import Backdrop from '../../../UI/Backdrop/Backdrop';

const leftNav = (props) => {

    const navMenu = NAV_MENU.map((item, index) => {
        return <li className={classes.Item} key={index}><a href={item.url} target={item.target}>{item.name}</a></li>;
    });
    const companyNav = COMPANY_LINK.map((item, index) => {
        return <li className={classes.Item} key={index}><a href={item.url} target={item.target}>{item.name}</a></li>;
    });

    return (
        <div>
            <Backdrop 
                showBackdrop={props.show}
                backdropClicked={props.hideClicked} />

            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={270}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Closed 
                }}>
                <div className={classes.Wrapper}>
                    <div className={classes.Header}>
                        <div className={classes.BurgerMenu} onClick={props.hideClicked}>
                            <Icon name="ico-close" fill="#202124" stroke="none" width={24}/>
                        </div>
                        <a href="/">
                            <img className={classes.Logo} src={logo} alt="antavaya"/>
                        </a>
                    </div>
                    <div className={classes.Content}>
                        <div className={classes.Menu}>
                            <div className={classes.Label}>Our Product</div>
                            <ul className={classes.List}>
                                {navMenu}
                            </ul>
                        </div>
                        <div className={classes.Menu}>
                            <div className={classes.Label}>About Antavaya</div>
                            <ul className={classes.List}>
                                {companyNav}
                            </ul>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default leftNav;