import React from 'react';
import classes from './ImageNav.module.scss';

const bestDealItem = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Image}>
                <img onClick={props.clicked} src={props.photo.url} alt={props.photo.name} />
            </div>
        </div>
    );
}

export default bestDealItem;