import React from 'react';
import classes from './RightContent.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../UI/Icon/Icon';
import { toCapitalize } from '../../../../../helper/GlobalFunc';
import { DEFAULT_IMG } from '../../../../../constant/Content';
import CurrencyFormat from 'react-currency-format';
import ImgThumb from '../../../../UI/ImgThumb/ImgThumb';
import { currencyFormat, formatHumanDate, pluralize } from '../../../../../helper/GlobalFunc';

const rightContent = (props) => {
    let content = null;
    let priceDetails = null;
    let prices = 0;
    let adults = null;
    let childs = null;
    let seniors = null;
    let allPrice = null;
    let personsDetail = [];

    const totalPrice = (prices) => {
        let total = 0;
        for (const key in prices) {
            total += prices[key].price * prices[key].qty
        }
        return (total === 0) ? props.admissionDetail.priceStartFrom : total;
    }

    if (props.admissionDetail) {

        if (props.visitors) {
            let visitors = props.visitors.filter((item) => item.qty > 0);
            
            priceDetails = visitors.map((item, index) => {
                if (item.type === 'adult' && item.qty > 0) {
                    adults = pluralize(item.qty, 'Adult', 'Adults');
                    personsDetail.push(adults);
                } else if (item.type === 'child' && item.qty > 0) {
                    childs = pluralize(item.qty, 'Child', 'Childs');
                    personsDetail.push(childs);
                } else if (item.type === 'senior' && item.qty > 0) {
                    seniors = pluralize(item.qty, 'Senior', 'Seniors');
                    personsDetail.push(seniors);
                } else if (item.type === 'all-price' && item.qty > 0) {
                    allPrice = pluralize(item.qty, 'All Age', 'All Ages');
                    personsDetail.push(allPrice);
                }

                return (
                    <div className={classes.ItemPrice} key={index}>
                        <label>{(item.type === 'all-price') ? 'All Age' : item.type} x {item.qty}</label>
                        <div>{currencyFormat(item.price)}</div>
                    </div>
                )
            })
            prices = totalPrice(props.visitors);
        }
        content = (
            <React.Fragment>
                <li className={classes.Item}>
                    <div className={classes.Wrapper}>
                        <div className={classes.Info}>
                            <div className={classes.Title}>
                                {toCapitalize(props.admissionDetail.tourAdmissionName)}
                            </div>
                        </div>
                        <ImgThumb onError={DEFAULT_IMG} src={props.admissionDetail.image} />
                    </div>
                </li>
                <li className={classes.Item}>
                    <div className={classes.Detail}>
                        <Icon name="ico-calendar" fill="#818589" stroke="none" width={24} />
                        <div className={classes.Label}>{formatHumanDate(localStorage.getItem('date'))}</div>
                    </div>
                    <div className={classes.Detail}>
                        <Icon name="ico-user-o" fill="#818589" stroke="none" width={24} />
                        <div className={classes.Label}>
                            {personsDetail.join('  ')}
                        </div>
                    </div>
                </li>
                <li className={classes.Item}>
                    <div className={classes.PriceWrapper}>
                        <div className={classes.Details}>
                            <div className={classes.Title}>Price Details</div>
                            {priceDetails}
                        </div>
                        <div className={classes.Total}>
                            <label>Total Price</label>
                            <div className={classes.Price}>
                                <CurrencyFormat value={prices} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            </div>
                        </div>
                    </div>
                </li>
            </React.Fragment>
        );
    }

    return (
        <PanelBox isOverfloe={false}>
            <div className={classes.Wrapper}>
                <ul className={classes.SectionList}>
                    {content}
                </ul>
            </div>
        </PanelBox>
    )
}

export default rightContent;
