import React, { Component } from 'react';
import classes from './FlightInput.module.scss';
import DestinationAutocomplete from '../DestinationAutocomplete/DestinationAutocomplete';
import FlightDate from '../FlightDate/FlightDate';
import FlightPassenger from '../FlightPassenger/FlightPassenger';
import Backdrop from '../../../../UI/Backdrop/Backdrop';
import { withRouter, Link } from 'react-router-dom';
import airportList from '../../../../../assets/jsons/airport.json';
import popularAirportCodes from '../../../../../assets/jsons/popularAirportCode.json';
import  {formatDisplayDate, formatShortDate, pluralize} from '../../../../../helper/GlobalFunc';

class FlightInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popularAirportList: [],
            originAirport: 'JKTA',
            originAirportType: '',
            isOriginAirportType: false,
            destinationAirport: 'DPS',
            destinationAirportType: '',
            isDestinationAirportType: false,
            isRoundTrip: true,
            departureDate: undefined,
            returnDate: undefined,
            passengers : {
                adult : 1,
                child : 0,
                infant: 0
            }
        };
    }

    showBackdropHandler = () => {
        this.props.showBackdrop();
    }

    componentDidMount() {
        const popularAirportList = popularAirportCodes.map( airportCode => {
            return airportList[airportCode];
        });

        let departureDate = new Date();
        let returnDate = new Date();
        returnDate.setDate(departureDate.getDate() + 2);


        this.setState({popularAirportList, departureDate, returnDate});
    }

    roundTripToggleClickedHandler = () => {
        this.setState({isRoundTrip: !this.state.isRoundTrip});
    }

    dateClickedHandler = dates => {
        if (this.state.isRoundTrip) {
            this.setState({departureDate: dates.from, returnDate: dates.to});
        } else {
            this.setState({departureDate: dates.from});
        }
    }

    decreaseOrIncreaseClickedHandler = (counterType, passengerType) => {
        let passengers = this.state.passengers;
        if (counterType === 'decrease') {
            if (passengers[passengerType] > 0) {
                passengers[passengerType] = passengers[passengerType] - 1;
            }
        } else {
            passengers[passengerType] = passengers[passengerType] + 1;
        }

        this.setState({passengers});
    }

    originAirportSelectedHandler = (val) => {
        this.setState({
            isOriginAirportType: false,
            originAirport: val
        });
    }

    originAirportChangedHandler = event => {
        this.setState({
            isOriginAirportType: true,
            originAirportType: event.target.value 
        });
    }

    originAirportOutsideClickedHandler = () => {
        this.setState({isOriginAirportType: false});
    }

    destinationAirportSelectedHandler = (val) => {
        this.setState({
            isDestinationAirportType: false,
            destinationAirport: val
        });
    }

    destinationAirportChangedHandler = event => {
        this.setState({
            isDestinationAirportType: true,
            destinationAirportType: event.target.value 
        });
    }

    destinationAirportOutsideClickedHandler = () => {
        this.setState({isDestinationAirportType: false});
    }

    filterAirport = keyword => {
        let list = [];
        let strLower = keyword.toLowerCase(); 
        for (const airportCode in airportList) {
            let airport = airportList[airportCode];
            if (airport.code.toLowerCase().indexOf(strLower) !== -1 || airport.name.toLowerCase().indexOf(strLower) !== -1 || airport.location.toLowerCase().indexOf(strLower) !== -1 || airport.airportCountry.toLowerCase().indexOf(strLower) !== -1) {
                list.push(airportList[airportCode]);
            }
        }
        return list;
    }

    render() {
        
        let wrapperClass = [classes.Wrapper];
        let formClasses = [classes.Form];
        if (this.props.type === 'small') {
            wrapperClass.push(classes.TopLeftRadius);
            formClasses.push(classes.Small);
        }

        let originAirportDisplay = airportList[this.state.originAirport].location + ' (' + airportList[this.state.originAirport].code + ')';
        let originAirportList = this.state.popularAirportList;
        if (this.state.isOriginAirportType) {
            originAirportDisplay = this.state.originAirportType;
            if (this.state.originAirportType !== ''){
                originAirportList = this.filterAirport(this.state.originAirportType);
            }
        }

        let destinationAirportDisplay = airportList[this.state.destinationAirport].location + ' (' + airportList[this.state.destinationAirport].code + ')';
        let destinationAirportList = this.state.popularAirportList;
        if (this.state.isDestinationAirportType) {
            destinationAirportDisplay = this.state.destinationAirportType;
            if (this.state.destinationAirportType !== ''){
                destinationAirportList = this.filterAirport(this.state.destinationAirportType);
            }
        }

        let passengerValue = (this.state.passengers.adult + this.state.passengers.child + this.state.passengers.infant);
        passengerValue = pluralize(passengerValue, 'Passenger', 'Passengers');

        let flightDateValue = formatDisplayDate(this.state.departureDate);
        if (this.state.isRoundTrip) {
            flightDateValue = formatDisplayDate(this.state.departureDate) + ' - ' + formatDisplayDate(this.state.returnDate);
        }

        let isRoundTrip = (this.state.isRoundTrip) ? '1':'0';
        let url = 'searchflight?type=on&ori='+ this.state.originAirport +'&des='+ this.state.destinationAirport+'&ddate='+formatShortDate(this.state.departureDate)+'&rdate='+formatShortDate(this.state.returnDate)+'&adt='+ this.state.passengers.adult+'&chd='+ this.state.passengers.child+'&inf='+ this.state.passengers.infant+'&isround=' + isRoundTrip;

        return (
            <div>
                <Backdrop 
                    showBackdrop={this.state.showBackdrop}
                    backdropClicked={this.hideBackdropHandler} />
                <div className={wrapperClass.join(' ')}>
                    <div className={formClasses.join(' ')}>
                        <div className={classes.InputWrapper}>
                            <DestinationAutocomplete
                                label="FROM"
                                placeholder="Enter city or airport"
                                value={originAirportDisplay}
                                dropdownList={originAirportList}
                                showBackdrop={this.showBackdropHandler}
                                changed={(event) => this.originAirportChangedHandler(event)}
                                itemSelected={(val) => this.originAirportSelectedHandler(val)}
                                outsideClicked={this.originAirportOutsideClickedHandler}
                                isFilterDropdownList={this.state.isOriginAirportType}/>
                        </div>
                        <div className={classes.InputWrapper}>
                            <DestinationAutocomplete
                                label="TO"
                                placeholder="Enter city or airport"
                                value={destinationAirportDisplay}
                                dropdownList={destinationAirportList}
                                showBackdrop={this.showBackdropHandler}
                                changed={(event) => this.destinationAirportChangedHandler(event)}
                                itemSelected={(val) => this.destinationAirportSelectedHandler(val)}
                                outsideClicked={this.destinationAirportOutsideClickedHandler}
                                isFilterDropdownList={this.state.isDestinationAirportType}/>
                        </div>
                        <div className={[classes.InputWrapper, classes.Date].join(' ')}>
                            <FlightDate
                                isReadOnly={true}
                                label="DATE"
                                placeholder=""
                                value={flightDateValue}
                                dropdownList={[]}
                                showBackdrop={this.showBackdropHandler}
                                changed={(event) => this.regionInputChangeHandler(event)}
                                itemSelected={(val) => this.itemSelectedHandler(val)}
                                isRoundTrip={this.state.isRoundTrip}
                                roundTripToggleClicked={this.roundTripToggleClickedHandler}
                                departureDate={this.state.departureDate}
                                returnDate={this.state.returnDate}
                                dateClicked={ dates => this.dateClickedHandler(dates)}/>
                        </div>
                        <div className={classes.InputWrapper}>
                            <FlightPassenger
                                isReadOnly={true}
                                label="TRAVELLERS AND CLASS"
                                placeholder=""
                                value={passengerValue}
                                passengers={this.state.passengers}
                                showBackdrop={this.showBackdropHandler}
                                decreaseOrIncreaseClicked={(counterType, passengerType) => this.decreaseOrIncreaseClickedHandler(counterType, passengerType)}/>
                        </div>
                    </div>
                    <div className={classes.Action}>
                        <Link to={'/redirect?url=' + url} target="_blank" > 
                            <button className={classes.Button} >Explore</button>
                        </Link>
                        {/* <span onClick={() => this.props.searchFlightClicked(url)} > 
                            <button className={classes.Button} >Explore</button>
                        </span> */}
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(FlightInput);