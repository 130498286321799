import { updateObject } from '../../utility';

const initialState = {
    admissionPackages: [],
    isFetchAdmission: false,
    isErrorFetchAdmission: false,
    
    admissionExperiencePackages: [],
    isFetchAdmisionExperiences: false,
    IsErrorFetchAdmisionExperiences: false,

    experiencePackages: [],
    isFetchExperience: false,
    isErrorFetchExperience: false,
    categoryFilters : [],
    sortBy: 'lowest-price',

    searchAdmissionPackages: [],
    searchExperiencePackages: [],
    isFetchSearch: false,
    isErrorFetchSearch: false,
}

const setAdmissions = (state, action) => {
    return(updateObject(state, {
        admissionPackages: action.payload.admissions.sort((a, b) => a.priceStartFrom - b.priceStartFrom)
    }));
}

const generateFilter = (tours) => {
    let catFilters = [];
    if (tours && tours.length > 0) {
        catFilters = tours.map(item => item.experienceType)
            .filter((item, index, self) => self.indexOf(item) === index)
            .map(item => ({value : item, name : item}));
    } 
    return catFilters;
}

const setExperiences = (state, action) => {
    let catFilters = generateFilter(action.payload.experiences);
    return(updateObject(state, {
        experiencePackages: action.payload.experiences.sort((a, b) => a.priceStartFrom - b.priceStartFrom),
        categoryFilters: catFilters
    }))
}

const setAdmissionExperiences = (state, action) => {
    return updateObject(state, {
        admissionExperiencePackages: action.payload.admissionExpreriencePackages.sort((a, b) => a.priceStartFrom - b.priceStartFrom)
    })
}

const setIsFetchAdmission = (state, action) => {
    return(updateObject(state, { isFetchAdmission: action.payload.value }))
}

const setIsErrorFetchAdmission = (state, action) => {
    return(updateObject(state, { isErrorFetchAdmission: action.payload.value }))
}

const setIsFetchExperience = (state, action) => {
    return(updateObject(state, { isFetchExperience: action.payload.value }))
}

const setIsErrorFetchExperience = (state, action) => {
    return(updateObject(state, { isErrorFetchExperience: action.payload.value }))
}

const setIsFetchSearch = (state, action) => {
    return(updateObject(state, { isFetchSearch: action.payload.value }))
}

const setIsErrorFetchSearch = (state, action) => {
    return(updateObject(state, { isErrorFetchSearch: action.payload.value }))
}

const setSearchAdmissionPackage = (state, action) => {
    return(updateObject(state, { searchAdmissionPackages: action.payload.admissions }))
}

const setSearchExperiencePackage = (state, action) => {
    return(updateObject(state, { searchExperiencePackages: action.payload.experiences }))
}

const setSortBy = (state, action) => {
    let tourPackages = state.experiencePackages;
    if (action.sortBy === 'lowest-price') {
        tourPackages = state.experiencePackages.sort((a, b) => a.priceStartFrom - b.priceStartFrom);
    } else if (action.sortBy === 'highest-price') {
        tourPackages = state.experiencePackages.sort((a, b) => b.priceStartFrom - a.priceStartFrom);
    } else if (action.sortBy === 'sort-duration') {
        tourPackages = state.experiencePackages.sort((a, b) => (a.days + a.night) - (b.days + b.night));
    } else if (action.sortBy === 'longest-duration') {
        tourPackages = state.experiencePackages.sort((a, b) => (b.days + b.night) - (a.days + a.night));
    }
    return updateObject( state, {
        sortBy: action.sortBy,
        tourPackages: tourPackages,
    } );
};

const setSortByAdmission = (state, action) => {
    let tourPackages = state.admissionPackages;
    if (action.sortBy === 'lowest-price') {
        tourPackages = state.admissionPackages.sort((a, b) => a.priceStartFrom - b.priceStartFrom);
    } else if (action.sortBy === 'highest-price') {
        tourPackages = state.admissionPackages.sort((a, b) => b.priceStartFrom - a.priceStartFrom);
    }
    
    return updateObject( state, {
        sortBy: action.sortBy,
        tourPackages: tourPackages,
    } );

}

const setSortByAdmissionExperience = (state, action) => {
    let tourPackages = state.admissionExperiencePackages;
    if (action.sortBy === 'lowest-price') {
        tourPackages = state.admissionExperiencePackages.sort((a, b) => a.priceStartFrom - b.priceStartFrom);
    } else if (action.sortBy === 'highest-price') {
        tourPackages = state.admissionExperiencePackages.sort((a, b) => b.priceStartFrom - a.priceStartFrom);
    }
    
    return updateObject( state, {
        sortBy: action.sortBy,
        admissionExperiencePackages: tourPackages,
    } );

}

const setIsFetchAdmisionExperiences = (state, action) => {
    return updateObject(state, { isFetchAdmisionExperiences: action.payload.value })
}

const setIsErrorFetchAdmisionExperiences = (state, action) => {
    return updateObject(state, { IsErrorFetchAdmisionExperiences: action.payload.value })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ADMISSIONS': return setAdmissions(state, action);
        case 'SET_IS_FETCH_ADMISSIONS': return setIsFetchAdmission(state, action);
        case 'SET_IS_ERROR_FETCH_ADMISSIONS': return setIsErrorFetchAdmission(state, action);

        case 'SET_EXPERIENCES': return setExperiences(state, action);
        case 'SET_IS_FETCH_EXPERIENCE': return setIsFetchExperience(state, action);
        case 'SET_IS_ERROR_FETCH_EXPERIENCE': return setIsErrorFetchExperience(state, action);
        
        case 'SET_ADMISSIONS_EXPERIENCE': return setAdmissionExperiences(state, action);
        case 'SET_IS_FETCH_ADMISSION_EXPERIENCES': return setIsFetchAdmisionExperiences(state, action);
        case 'SET_IS_ERROR_ADMISSION_EXPERIENCES': return setIsErrorFetchAdmisionExperiences(state, action);
        
        case 'SET_SORT_BY': return setSortBy(state, action);
        case 'SET_SORT_BY_ADMISSION': return setSortByAdmission(state, action);
        case 'SET_SORT_BY_ADMISSION_EXPERIENCE': return setSortByAdmissionExperience(state, action);

        // search
        case 'SET_IS_FETCH_SEARCH': return setIsFetchSearch(state, action);
        case 'SET_IS_ERROR_FETCH_SEARCH': return setIsErrorFetchSearch(state, action);
        case 'SET_SEARCH_ADMISSION': return setSearchAdmissionPackage(state, action);
        case 'SET_SEARCH_EXPERIENCE': return setSearchExperiencePackage(state, action);

        default: return state;
    }
}

export default reducer;