import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

export const inputChanged = (identifier, value) => {
    return {
        type: 'INPUT_CHANGED_VIRTUAL_TOUR',
        payload: { identifier, value }
    }
}

export const dialCodeChanged = (value) => {
    return {
        type: 'DIAL_CODE_CHANGED_VIRTUAL_TOUR',
        value
    };
};

export const verifyClicked = res => {
    return {
        type: 'VERIFY_CLICKED_VIRTUAL_TOUR'
    };
};

export const formChecking = () => {
    return {
        type: 'FORM_CHECKING_VIRTUAL_TOUR'
    };
};

export const submitSuccess = () => {
    return {
        type: 'SUBMIT_SUCCESS_VIRTUAL_TOUR'
    };
};

export const loadingSubmitForm = () => {
    return {
        type: 'LOADING_SUBMIT_FORM_VIRTUAL_TOUR'
    };
};

export const formSubmited = (postData) => {
    return dispatch => {
        dispatch(loadingSubmitForm());
        axios.post(ANTAVAYA_API + 'virtual-tour/inquiry', postData)
            .then( response => {
                if (response) {
                    dispatch(setIsSuccessSubmit(true))
                }
            })
            .catch(function (error) {
                console.log('ERR', error);
            });
    }
}

export const setVirtualTourDetail = (data) => {
    return {
        type: 'SET_VIRTUAL_TOUR_DETAIL',
        payload: {
            data
        }
    }
}

export const setIsSuccessSubmit = (value) => {
    return {
        type: 'SET_IS_SUCCESS_SUBMIT',
        payload: {
            value
        }
    }
}

export const setIsFetchVirtualTourDetail = (value) => {
    return {
        type: 'SET_IS_FETCH_VIRTUAL_TOUR',
        payload: {
            value
        }
    }
}
export const setIsErrorFetchVirtualTourDetail = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_VIRTUAL_TOUR',
        payload: {
            value
        }
    }
}

export const fetchVirtualTourDetail = (slug) => {
    return dispatch => {
        dispatch(setIsFetchVirtualTourDetail(true));
        axios.get(ANTAVAYA_API + 'virtual-tour/detail/' + slug)
            .then((response) => {
                dispatch(setVirtualTourDetail(response.data.data));
                dispatch(setIsFetchVirtualTourDetail(false));
            })
            .catch((error) => {
                console.log('error', error)
                dispatch(setIsErrorFetchVirtualTourDetail(true));
            })
    }
}