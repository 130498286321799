import React from 'react';
import classes from './CheckBox.module.scss';

const checkBox = (props) => {
    let containerClass = [classes.Container, classes.HasLabel];
    if (props.label === "") {
        containerClass = [classes.Container];
    }
    let id = Math.random().toString(36).substring(7);
    return (
        <div>
            <label className={containerClass.join(' ')}>
                <span htmlFor={id}>{props.label}</span>
                <input id={id} type="checkbox" onChange={props.changed} checked={props.checked} readOnly />
                <span className={classes.Checkmark}></span>
            </label>
        </div>
    );
}

export default checkBox;