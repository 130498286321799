import React, { useEffect, useState } from 'react';
import classes from './RetrieveBookingContainer.module.scss';
import BottomNavBar from '../../../../components/Mobile/Shared/BottomNavBar/BottomNavBar';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import Icon from '../../../../components/UI/Icon/Icon';
import TextField from '../../../../components/UI/Inputs/TextField/TextField';
import Button from '../../../../components/UI/Button/Button';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import * as action from '../../../../redux/Shared/RetrieveBooking/RetrieveBookingAction';
import { objectToParam } from '../../../../helper/GlobalFunc';
import RetrieveBooking from '../../../../components/Mobile/Shared/RetrieveBooking/RetrieveBooking';
import NotFound from '../../../../components/Mobile/Shared/RetrieveBooking/NotFound/NotFound';


function RetrieveBookingContainer(props) {
	const [isForm, setIsForm] = useState(true);
	
	useEffect(() => {
		const urlParams = queryString.parse(props.location.search);
		let orderNumber = urlParams.orderNumber;
		let email = urlParams.email;
		if (orderNumber && email) {
			let data = {
				orderNumber: orderNumber,
				email: email
			};
			setIsForm(false);
			props.initDetailBooking(data);
		}
    }, []);

	const submitBooking = () => {
		if (props.isValidForm) {
			const data = {
				orderNumber: props.form.bookingId.value,
				email: props.form.email.value
			}
			window.location.href = '/retrieve-booking?' + objectToParam(data);
		} else {
			props.checkingForm();
		}
	}
	const downloadBtnHandler = (url) => {
        window.open(url, '_blank');
    }

	let goLogin = () => {
		props.history.push("/login");
	};

	let goBack = () => {
		window.location.href = '/retrieve-booking';
	};

	let content = <div>
		<div className="u-mb-24">
			<PanelBox>
				<div className="u-p-16">
					<div onClick={goLogin} className="u-display-flex u-align-items-center">
						<Icon name={"ico-sign-in"} fill="#1A6FA3" stroke="none" />
						<div className="u-flex-10 u-mx-12 u-fs-14 u-bold u-color-black">
							<span className="u-color-blue">Log in</span> to speed up
							your booking checking
						</div>
						<Icon name={"ico-chev-right"} fill="#202124" stroke="none" />
					</div>
				</div>
			</PanelBox>
		</div>
		<div className="u-mb-30">
			<PanelBox>
				<div className="u-px-16 u-py-24 u-pb-30">
					<div className="u-mb-24">
						<div className="label">Booking ID</div>
						<TextField
							placeholder="e.g. ANT-3536-xxxx" 
							value={props.form.bookingId.value}
							changed={(event) => props.inputChanged(event.target.value, 'bookingId')}
							hasError={props.form.bookingId.hasError && props.form.bookingId.errorMsg !== ''}
							errorMsg={props.form.bookingId.errorMsg}/>
					</div>
					<div>
						<div className="label">Email</div>
						<TextField
							placeholder="e.g. youremail@example.com" 
							value={props.form.email.value}
							changed={(event) => props.inputChanged(event.target.value, 'email')}
							hasError={props.form.email.hasError && props.form.email.errorMsg !== ''}
							errorMsg={props.form.email.errorMsg} />
					</div>
				</div>
			</PanelBox>
		</div>
		<div className="u-mb-24">
			<Button
				classes={["BtnRed", "BtnBlock", "BtnMedium"]}
				clicked={submitBooking}
				children="Check My Booking"/>
		</div>
		{/* <div className="link u-text-center" onClick={forgetId}>Forgot your booking ID?</div> */}
	</div>
	let header = <Header classes={['ClBlack', 'BgWhite', 'Shadow']} 
		icoColor="white"
		align="center"
		content={<div>Retrieve Booking</div>}/>
	if (!isForm) {
		header = <Header classes={['ClBlack', 'BgWhite', 'Shadow']} 
			icoColor="black"
			align="left"
			leftIcon="back"
			leftClicked={goBack}
			content={<div>Booking Detail</div>}/>

		if (props.isErrorFetchBookingDetail) {
			content = <NotFound clicked={() => props.history.push('/tour/availability?destination=all-region')}/>
		} else {
			header = <Header classes={['ClBlack', 'BgWhite', 'Shadow']} 
				icoColor="black"
				align="center"
				leftIcon="back"
				leftClicked={goBack}
				content={<div className={classes.Title}>Booking Detail
					{(props.bookingDetail && props.bookingDetail.pdfUrl) &&
					<Button
						classes={["BtnRed", "BtnXSmall"]}
						clicked={() => downloadBtnHandler(props.bookingDetail.pdfUrl)}
						children="Download E-ticket"/>}
				</div>}/>
			content = <RetrieveBooking 
						isLoading={props.isFetchBookingDetail}
						bookingData={props.bookingDetail}/>;
		}
	}
	return (
		<div className={classes.Wrapper}>
			<div className={classes.Head}>
				{header}
			</div>
			<div className={classes.Body}>
				{content}
			</div>
			<BottomNavBar />
		</div>
	);
}

const mapStateToProps = state => {
	return {
		form: state.RetrieveBookingReducer.formData,
		bookingDetail: state.RetrieveBookingReducer.bookingDetail,
        isFetchBookingDetail: state.RetrieveBookingReducer.isFetchBookingDetail,
		isErrorFetchBookingDetail: state.RetrieveBookingReducer.isErrorFetchBookingDetail,
		isValidForm: state.RetrieveBookingReducer.isValidForm
	};
}

const mapDispatchToProps = dispatch => {
	return {
		inputChanged: (val, identifier) => dispatch(action.inputChanged(val, identifier)),
		initDetailBooking: (data) => dispatch(action.initRetrieveBooking(data)),
		checkingForm: () => dispatch(action.checkingForm())
	};
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RetrieveBookingContainer));
