import React, { Component } from 'react';
import classes from './AvailabilityContainer.module.scss';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import BannerAvailability from '../../../../components/Desktop/Hotel/BannerAvailability/BannerAvailability';
import ContentAvailability from '../../../../components/Desktop/Hotel/ContentAvailability/ContentAvailability';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import MetaTag from '../../../../helper/MetaTag';
import Breadcrumb from '../../../../components/Desktop/Shared/Breadcrumb/Breadcrumb';

const breadcrumbLink = [{
    name: 'Home',
    url: '/'
},{
    name: 'Hotel',
    url: '/hotel/availability'
}];

class HotelAvailabilityContainer extends Component {

    scrollTop = () =>{
        window.scrollTo(0, 0);
    }

    render() {
        this.scrollTop();
        return (
            <div className={classes.Wrapper}>
                <MetaTag 
                    title="Tour Availability"/>
                <Header />
                <BannerAvailability />
                <div className={classes.Content}>
                    <Breadcrumb linkList={breadcrumbLink}/>
                </div>
                <ContentAvailability />
                <Footer />
            </div>
        );
    }
}

export default HotelAvailabilityContainer;