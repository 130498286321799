import React from 'react';
import classes from './AutocompleteList.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';

const AutocompleteList = (props) => {
    let dropdownList = null;
    if (props.dropdownList && props.dropdownList.length > 0) {
        let dropList = props.dropdownList;
        if (['asc', 'desc'].includes(props.sortDropDown)) {
            let sort = props.sortDropDown === 'asc' ? 1 : -1 ;
            dropList = props.dropdownList.sort(
                (a, b) => (a.value > b.value ? sort : -sort)
            )
            let defIndex = dropList.findIndex(e => e.value === 'all-region');
            if (defIndex > 0) {
                dropList.unshift(dropList.splice(defIndex, 1)[0])
            }
        }
        dropdownList = dropList.map((item, index) => {
            return (
                <li key={index} className={classes.Item} onClick={() => props.itemSelected(item.value)}>{item.name}</li>
            );
        });
    } else {
        dropdownList = <li className={classes.Item}>No Result</li>
    }
    
    return (
        <CSSTransition
            in={props.showDropdownList}
            mountOnEnter
            unmountOnExit
            timeout={270}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close
            }}>
            <div className={classes.Wrapper}>
                <ul className={classes.List}>
                    {dropdownList}
                </ul>
            </div>
        </CSSTransition>
    );
}

export default AutocompleteList;
