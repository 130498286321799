import {updateObject} from '../../utility';

const initialState = {
    careerList : null,
    isErrorFetchCareerlList : false
}

const setCareerList = (state, action) => {
    return updateObject( state, {
        careerList: action.careerList,
        isErrorFetchCareerlList : false
    } );
};

const fetchCareerListFailed = (state, action) => {
    return updateObject(state, {isErrorFetchCareerlList: true});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_CAREER_LIST': return setCareerList(state, action);
        case 'FETCH_CAREER_LIST_FAILED': return fetchCareerListFailed(state, action);
        default: return state;
    }
};

export default reducer;