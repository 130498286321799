import React from 'react';
import classes from './PriceDetail.module.scss';
import PriceDetail from './PriceDetail/PriceDetail';
import {currencyFormat} from '../../../../../helper/GlobalFunc';
import PanelBox from '../../../../UI/PanelBox/PanelBox';

const priceDetail = (props) => {
    let content = null;
    if (!props.isLoading && props.bookingData) {
        content = <div className={classes.Wrapper}>
                <PriceDetail prices={props.bookingData.pricing}/>
                <div className={classes.TotalPrice}>
                    <div>Total Price</div>
                    <div className={classes.Price}>{currencyFormat(props.bookingData.totalPrice)}</div>
                </div>
            </div>;
    }

    return (
        <PanelBox>
            {content}
        </PanelBox>
    );
}

export default priceDetail;