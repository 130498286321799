import React, { Component } from 'react';
import classes from './SearchResult.module.scss';
import Icon from '../../../UI/Icon/Icon';
import PackageItem from './PackageItem/PackageItem';

class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packages: []
        };
    }

    render() {
        
        let tourPackageList = this.props.tourPackageList.map((packageItem, index) => {
            return (
                <div key={index} 
                    className={classes.PackageItem}
                    onClick={ () => this.props.tourPackageClicked(packageItem.slug)} >
                    <PackageItem packageItem={packageItem}/>
                </div>
            );
        });

        let regionList = this.props.regionList.map( (region, index) => {
            return (
                <div className={classes.DestinationItem} key={index} onClick={ () => this.props.destinationClicked(region.name)}>
                    <Icon name="ico-location" width="20" stroke="none" fill="#1A6FA3" />
                    <div className={classes.DestinationName}>{region.name}</div>
                </div>
            );
        });

        return (
            <div className={classes.Wrapper}>
                <div className={classes.Label}>Destination</div>
                <div className={classes.List}>
                    {regionList}
                </div>

                <div className={classes.Label}>Tour Packages</div>
                <div className={classes.List}>
                    {tourPackageList}
                </div>
            </div>
        );
    }
}

export default SearchResult;