import React from 'react'
import Header from '../../../../components/Desktop/Shared/Header/Header'
import BannerSlider from '../../../../components/Desktop/Shared/BannerSlider/BannerSlider'
import SearchForm from '../../../../components/Desktop/Shared/SearchForm/SearchForm'
import HolidayPackage from '../../../../components/Desktop/Tour/HolidayPackage/HolidayPackage'
import HotelFeatured from '../../../../components/Desktop/Hotel/HotelFeatured/HotelFeatured'
import VoucherFeatured from '../../../../components/Desktop/Voucher/VoucherFeatured/VoucherFeatured'
import VirtualTourFeatured from '../../../../components/Desktop/VirtualTour/VirtualTourFeatured/VirtualTourFeatured'
// import PergiFlightList from '../../../../components/Desktop/Shared/PergiFlightList/PergiFlightList';
import ActivityFeatured from '../../../../components/Desktop/Activity/ActivityFeatured/ActivityFeatured'
import Footer from '../../../../components/Desktop/Shared/Footer/Footer'

import { connect } from 'react-redux'
import * as availabilityAction from '../.././../../redux/Tour/Availability/AvailabilityAction'
import MetaTag from '../../../../helper/MetaTag'
import WhatsappLink from '../../../../components/Desktop/Shared/WhatsappLink/WhatsappLink'

function tourHomeContainer(props) {
	return (
		<div className='bg-white-dark'>
			<MetaTag title='Pemesanan Paket Wisata Online' />

			<Header />
			<BannerSlider />
			<div className='u-mb-80'>
				<SearchForm />
			</div>
			<div className='u-mb-100'>
				<HolidayPackage />
			</div>
			<div className='u-mb-100'>
				<HotelFeatured />
			</div>
			<div className='u-mb-100'>
				<ActivityFeatured />
			</div>
			<div className='u-mb-100'>
				<VirtualTourFeatured />
			</div>
			<div className='u-mb-100'>
				<VoucherFeatured />
			</div>
			{/* <div className="u-mb-140">
				<PergiFlightList />
			</div> */}
			<Footer />
			<WhatsappLink />
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		tourPackages: state.TourAvailabilityReducer.tourPackages
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onInitTourPackages: () => dispatch(availabilityAction.initTourPackages())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(tourHomeContainer)
