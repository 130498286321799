import React from 'react';
import Form from './Form/Form';

const LeftContent = (props) => {
    return (
        <div>
            <div className="u-mb-24">
                <Form submitInquryHandler={props.submitInquryHandler}/>
            </div>
        </div>
    )

}

export default LeftContent;