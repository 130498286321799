import React from 'react';
import classes from './ProductMenuList.module.scss';
import { PRODUCT_LIST } from '../../../../../constant/General';

import { connect } from 'react-redux';

const productMenuList = (props) => {

    const productList = PRODUCT_LIST.filter( product => {
        return product.isVisible;
    }).map( (product, index) => {
        let badges = null;
        if (product.slug === 'virtual-tour' && !props.virtualTours) {
            return null;
        }
        if (product.isNew) {
            badges = <div className={classes.Badges}>New!</div>;
        }
        return (
            <li key={index} 
                onClick={() => props.setProductSelected(product.slug)}
                className={(props.productSelected === product.slug) ? [classes.Item, classes.Active].join(' ') : classes.Item}>
                    {badges}
                    {product.name}
            </li>
        );
    });
    return (
        <div className={classes.Wrapper}>
            <ul className={classes.List}>
                {productList}
            </ul>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        virtualTours: state.VirtualTourAvailability.virtualTours
    }
}

export default connect(mapStateToProps, null)(productMenuList);