import React from 'react';
import Modal from '../../../../../UI/Modal/Modal';
import Select from '../../../../../UI/Inputs/Select/Select';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import SelectFlag from '../../../../../UI/Inputs/SelectFlag/SelectFlag';
import Button from '../../../../../UI/Button/Button';
import { formatShortDate } from '../../../../../../helper/GlobalFunc';

import { connect } from 'react-redux';
import * as participantAction from '../../../../../../redux/Shared/Participant/ParticipantAction';

function addParticipant(props) {
    const title = ((props.participantId) ? 'Edit' : 'Add') + ' Participant';
    const submitHandler = () => {
        let data = {
            title: props.form.title.value,
            firstName: props.form.firstName.value,
            lastName: props.form.lastName.value,
            birthDate: formatShortDate(new Date(`${props.form.year.value}-${props.form.month.value}-${props.form.day.value}`)),
            passportCountry: props.form.countryCode.value,
            PassportNumber: props.form.passportNumber.value,
            expiredDate: formatShortDate(new Date(`${props.form.yearPassport.value}-${props.form.monthPassport.value}-${props.form.dayPassport.value}`)),
        }
        if (props.participantId) {
            data.passengerID = props.participantId;
        }
        props.submitParticipantHandler(data, props.participantId);
    }

    return (
        <Modal 
            title={title}
            show={props.showAlert}
            closeClicked={props.hideFormClickHandler}>
            <div className="u-mb-40">
                <div className="u-mb-24 u-display-flex">
                    <div className="u-flex-2">
                        <div className="label">Title</div>
                        <Select 
                            placeholder="Title"
                            value={props.form.title.value}
                            options={props.form.title.options}
                            changed={(value) => props.inputChanged(value, 'title')}
                            />
                    </div>
                    <div className="u-flex-4 u-ml-8">
                        <div className="label">First Name</div>
                        <TextField
                            placeholder="e.g. Julie" 
                            value={props.form.firstName.value}
                            changed={(event) => props.inputChanged(event.target.value, 'firstName')}
                            hasError={props.form.firstName.hasError && props.form.firstName.errorMsg !== ''}
                            errorMsg={props.form.firstName.errorMsg} />
                    </div>
                    <div className="u-flex-4 u-ml-8">
                        <div className="label">Last Name</div>
                        <TextField
                            placeholder="e.g. Applesed" 
                            value={props.form.lastName.value}
                            changed={(event) => props.inputChanged(event.target.value, 'lastName')}
                            hasError={props.form.lastName.hasError && props.form.lastName.errorMsg !== ''}
                            errorMsg={props.form.lastName.errorMsg}/>
                    </div>
                </div>
                <div className="u-mb-24">
                    <div className="label">Date of Birth</div>
                    <div className="u-display-flex">
                        <div className="u-flex-2">
                            <Select 
                                placeholder="Day"
                                value={props.form.day.value}
                                options={props.form.day.options}
                                changed={(val) => props.inputChanged(val, 'day')}/>
                        </div>
                        <div className="u-flex-5 u-ml-8 u-mr-8">
                            <Select 
                                placeholder="Month"
                                value={props.form.month.value}
                                options={props.form.month.options}
                                changed={(val) => props.inputChanged(val, 'month')}/>
                        </div>
                        <div className="u-flex-3">
                            <Select 
                                value={props.form.year.value}
                                options={props.form.year.options} 
                                changed={(val) => props.inputChanged(val, 'year')}/>
                        </div>
                    </div>
                </div>
                <div className="u-mb-24">
                    <div className="u-display-flex">
                        <div className="u-flex-3">
                            <div className="label">Country Code</div>
                            <SelectFlag 
                                type="country"
                                value={props.form.countryCode.value}
                                changed={(val) => props.inputChanged(val, 'countryCode')}
                                hasError={props.form.countryCode.hasError && props.form.countryCode.errorMsg !== ''}
                                errorMsg={props.form.countryCode.errorMsg}/>
                        </div>
                        <div className="u-flex-7 u-ml-6">
                            <div className="label">Passport Number</div>
                            <TextField
                                placeholder="e.g. 768765359" 
                                value={props.form.passportNumber.value}
                                changed={(event) => props.inputChanged(event.target.value, 'passportNumber')}
                                hasError={props.form.passportNumber.hasError && props.form.passportNumber.errorMsg !== ''}
                                errorMsg={props.form.passportNumber.errorMsg} />
                        </div>
                    </div>
                </div>
                <div className="u-mb-24">
                    <div className="label">Expired Date</div>
                    <div className="u-display-flex">
                        <div className="u-flex-2">
                            <Select
                                value={props.form.dayPassport.value}
                                options={props.form.dayPassport.options}
                                changed={(val) => props.inputChanged(val, 'dayPassport')}/>
                        </div>
                        <div className="u-flex-5 u-ml-8 u-mr-8">
                            <Select
                                value={props.form.monthPassport.value}
                                options={props.form.monthPassport.options}
                                changed={(event) => props.inputChanged(event, 'monthPassport')}/>
                        </div>
                        <div className="u-flex-3">
                            <Select 
                                value={props.form.yearPassport.value}
                                options={props.form.yearPassport.options} 
                                changed={(event) => props.inputChanged(event, 'yearPassport')}/>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Button 
                    clicked={() => submitHandler()}
                    isLoading={props.isSubmitLoading}
                    classes={['BtnRed', 'BtnMedium', 'BtnBlock']}>{title}</Button>
            </div>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        form : state.ParticipantReducer.participantForm,
        isSubmitLoading : state.ParticipantReducer.isSubmit,
        participantId : state.ParticipantReducer.participantId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        inputChanged: (val, inputIdentifier) => dispatch(participantAction.inputChange(val, inputIdentifier)),
        submitParticipantHandler: (data, id) => dispatch(participantAction.submitParticipant(data, id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(addParticipant);

