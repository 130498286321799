import React from 'react';
import classes from './DestinationList.module.scss';

const destinationList = (props) => {
    const destinationList = props.tourDestinations.map((item, index) => {
        return <li key={index} className={classes.Item}>{item.destinationName}</li>;
    });
    return (
        <ul className={classes.List}>
            {destinationList}
        </ul>
    );
}

export default destinationList;