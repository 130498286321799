import React, { useState } from 'react'
import classes from './ItineraryPage.module.scss'
import ItineraryItem from '../../../../../../components/Mobile/Shared/ItineraryList/ItineraryItem/ItineraryItem'
import Icon from '../../../../../../components/UI/Icon/Icon'
import StarRatingComponent from 'react-star-rating-controlled-component'
import Slider from 'react-slick'
import ITINERARY_DUMMY from './DummyData/DummyData'

const ItineraryPage = () => {
    const [isActive, setIsActive] = useState(false)
    let click = () => {
        setIsActive(!isActive)
    }

    let itineraryList = null
    if (ITINERARY_DUMMY && ITINERARY_DUMMY.length > 0) {
        itineraryList = ITINERARY_DUMMY.map((itinerary, index) => {
            return (
                <div key={index} className={classes.Item}>
                    <ItineraryItem
                        itenerary={itinerary}
                        isActive={isActive}
                        newComponent={<NewComponent hotel={itinerary} />}
                    />
                </div>
            )
        })
    }

    let arrowClasses = [classes.Arrow]
    let panelText = 'Expand All'
    if (isActive) {
        arrowClasses = [classes.Arrow, classes.Active]
        panelText = 'Collapse All'
    }

    return (
        <div className={classes.Content}>
            {itineraryList}
            <div className={classes.Place}>
                <div onClick={click} className={classes.Panel}>
                    <div className={arrowClasses.join(' ')}>
                        <Icon name='ico-expand-more' fill='#CD2033' stroke='none' />
                    </div>
                    <div className={classes.PanelText}>{panelText}</div>
                </div>
            </div>
        </div>
    )
}
export default ItineraryPage

const NewComponent = props => {
    let hotelFacility = null
    let imageSlider = null
    if (props.hotel.hotelDetail && props.hotel.hotelDetail.length > 0) {
        hotelFacility = props.hotel.hotelDetail.map((facility, index) => {
            return (
                <div key={index}>
                    <div className={classes.Hotel}>
                        <div className={classes.Image}>
                            <img
                                className={classes.Img}
                                src={facility.hotelRoom}
                                alt={'Room'}
                            />
                        </div>
                        <div className={classes.HotelDetail}>
                            <div className={classes.HotelName}>{facility.hotelName}</div>
                            <div className={classes.StarSize}>
                                <StarRatingComponent
                                    name='hotel'
                                    emptyStarColor={'#c2c2c2'}
                                    editing={false}
                                    starCount={5}
                                    value={facility.hotelClass}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    if (props.hotel.tourImage && props.hotel.tourImage.length > 0) {
        imageSlider = props.hotel.tourImage.map((image, index) => {
            return (
                <div key={index}>
                    <img className={classes.ImageSlider} src={image} alt={'view from hotel'} />
                </div>
            )
        })
    }

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: false,
        autoplaySpeed: 2500,
        dotsClass: 'slick-dots ' + classes.DotWrapper,
        touchMove: true,
        draggable: true,
    }

    return (
        <div>
            {hotelFacility}
            <Slider {...settings}>{imageSlider}</Slider>
        </div>
    )
}