import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

export const inputChanged = (value, identifier) => {
    return {
        type: 'RETRIEVE_BOOKING_INPUT_CHANGED',
        payload: { value, identifier }
    }
}

export const checkingForm = () => {
    return {
        type: 'RETRIEVE_BOOKING_CHECKING_FORM'
    }
}

export const setBookingDetail = (data) => {
    return {
        type: 'RETRIEVE_BOOKING_SET_DETAIL',
        payload: { data }
    }
}

export const setIsFetchBookingDetail = (value) => {
    return {
        type: 'RETRIEVE_BOOKING_IS_FETCH_BOOKING_DETAIL',
        payload: { value }
    }
}

export const setIsErrorFetchBookingDetail = (value) => {
    return {
        type: 'RETRIEVE_BOOKING_IS_ERROR_FETCH_BOOKING_DETAIL',
        payload: { value }
    }
}



export const initRetrieveBooking = (data, history) => {
    return dispatch => {
        dispatch(setIsFetchBookingDetail(true));
        axios.post(ANTAVAYA_API + 'member/tour-detail-non-login', data)
            .then((response) => {
                let data = injectPdfUrl(response.data.data, response.data.urlPdf);
                dispatch(setBookingDetail(data));
                dispatch(setIsFetchBookingDetail(false));
            })
            .catch((error) => {
                if (error) {
                    dispatch(setIsErrorFetchBookingDetail(true));
                    dispatch(setIsFetchBookingDetail(false));
                }
            })
    }
}

const injectPdfUrl = (data, url) => {
    return {...data, ...{pdfUrl: (url) ? ANTAVAYA_API+ 'pdf/tour-detail-pdf/' + url: ''}}
}