import React from 'react';
import classes from './ImageItem.module.scss';
import {DEFAULT_IMG} from '../../../../../../constant/Content';

const bestDealItem = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Image}>
                <img onError={DEFAULT_IMG} onClick={props.clicked} src={props.photo.url} alt={props.photo.title} />
            </div>
        </div>
    );
}

export default bestDealItem;