import React from 'react';
import classes from './Date.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../../UI/Icon/Icon';
import { formatHumanDate } from '../../../../../../helper/GlobalFunc';

const date = (props) => {
    let today = new Date();
    let currentDate = formatHumanDate(today);
    if (props.selectedDate !== null) {
        currentDate = formatHumanDate(props.selectedDate);
    }

    return (
        <div className={classes.Wrapper}>
            <PanelBox>
                <div className="u-p-16">
                    <div className={classes.Label}>Selected Date Visit</div>
                    <div className={classes.DatePicker} onClick={props.showHideCalendar}>
                        <div className={classes.Date}>{currentDate}</div>
                        <Icon name="ico-calendar" fill="#818589" stroke="none" width={25} />
                    </div>
                </div>
            </PanelBox>
        </div>
    )
}

export default date;
