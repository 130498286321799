import React from 'react';
import classes from './Form.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import Button from '../../../../../UI/Button/Button';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import SelectFlag from '../../../../../UI/Inputs/SelectFlag/SelectFlag';
import Select from '../../../../../UI/Inputs/Select/Select';
import { withRouter } from 'react-router-dom';
import TextArea from '../../../../../UI/Inputs/TextArea/TextArea';

import { connect } from 'react-redux';
import * as BookingAction from '../../../../../../redux/Admission/Booking/BookingAction';

const form = (props) => {
    return (
        <PanelBox isOverflow={false}>
            <div className={classes.Wrapper}>
                <div className={classes.Title}>Contact Information</div>
                <div className="u-p-24">
                    <div className="u-display-flex u-mb-24">
                        <div className="u-mr-16 u-flex-3">
                            <div className="label">Title</div>
                            <Select 
                                placeholder="Title"
                                value={props.formData.title.value}
                                options={props.formData.title.options}
                                changed={(val) => props.inputChanged('title', val)}/>
                        </div>
                        <div className="u-mr-16 u-flex-10">
                            <div className="label">First Name</div>
                            <TextField
                                placeholder="e.g. Julie"
                                value={props.formData.firstName.value}
                                changed={(event) => props.inputChanged('firstName', event.target.value)}
                                hasError={props.formData.firstName.hasError && props.formData.firstName.errorMsg !== ''}
                                errorMsg={props.formData.firstName.errorMsg}/>
                        </div>
                        <div className="u-flex-10">
                            <div className="label">Last Name</div>
                            <TextField
                                placeholder="e.g. Applesed" 
                                value={props.formData.lastName.value}
                                changed={(event) => props.inputChanged('lastName', event.target.value)}
                                hasError={props.formData.lastName.hasError && props.formData.lastName.errorMsg !== ''}
                                errorMsg={props.formData.lastName.errorMsg}/>
                        </div>
                    </div>
                    <div className="u-display-flex u-mb-16">
                        <div className="u-mr-16 u-flex-10">
                            <div className="label">Whatsapp Number</div>
                            <div className="u-display-flex">
                                <div className="u-flex-4 u-mr-8">
                                    <SelectFlag 
                                        value={props.formData.dialCode.value}
                                        changed={val => props.inputChanged('dialCode', val)}/>
                                </div>
                                <div className="u-flex-6">
                                    <TextField
                                        type="text"
                                        placeholder="e.g. 8123456789" 
                                        value={props.formData.phone.value} 
                                        changed={(event) => props.inputChanged('phone', event.target.value)}
                                        hasError={props.formData.phone.hasError && props.formData.phone.errorMsg !== ''}
                                        errorMsg={props.formData.phone.errorMsg}/>
                                </div>
                            </div>
                        </div> 
                        <div className="u-flex-10">
                            <div className="label">E-mail Address</div>
                            <TextField 
                                placeholder="e.g. youremail@example.com" 
                                value={props.formData.email.value}
                                changed={(event) => props.inputChanged('email', event.target.value)}
                                hasError={props.formData.email.hasError && props.formData.email.errorMsg !== ''}
                                errorMsg={props.formData.email.errorMsg}/>
                        </div> 
                    </div>
                    <div className="u-flex-16 u-mb-16">
                        <div className="label">Have a Question?</div>
                        <TextArea
                            placeholder="Write here"
                            value={props.formData.question.value}
                            changed={(event) => props.inputChanged('question', event.target.value)}/>
                    </div>

                    <div className="u-display-flex u-justify-content-end">
                        <div className="u-flex-4 u-mt-8 u-mr-16">
                            <Button 
                                classes={['BtnOutlineRed', 'BtnLarge', 'BtnBlock']}
                                clicked={() => props.history.goBack()}>
                                Cancel
                            </Button>
                        </div>
                        <div className="u-flex-4 u-mt-8">
                            <Button 
                                isLoading={props.isSubmitBooking}
                                classes={['BtnRed', 'BtnLarge', 'BtnBlock']}
                                clicked={props.submitInquryHandler}>
                                Submit Inquiry
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </PanelBox>
    )
}

const mapStateToProps = (state) => {
    return {
        formData: state.AdmissionBookingReducer.contactInfoForm
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChanged: (value, identifier) => dispatch(BookingAction.contactInfoChanged(value, identifier)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(form));

