import React from 'react';
import classes from './AddParticipant.module.scss';
import PopupPage from '../../../UI/PopupPage/PopupPage';
import Icon from '../../../UI/Icon/Icon';
import { COLOR } from '../../../../constant/Library';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import TextField from '../../../UI/Inputs/TextField/TextField';
import CalendarSwipe from '../../../UI/CalendarSwipe/CalendarSwipe';
import Button from '../../../UI/Button/Button';
import MobileSelect from '../../..//UI/Inputs/MobileSelect/MobileSelect';
import MobileSelectFlag from "../../../UI/Inputs/MobileSelectFlag/MobileSelectFlag";
import {getDay, getMonth, getYear, formatShortDate} from '../../../../helper/GlobalFunc';

import * as participantAction from '../../../../redux/Shared/Participant/ParticipantAction';
import { connect } from 'react-redux';

function addParticipant (props) {
    let currentDate = new Date();
    let minBirthdate = new Date(currentDate.getFullYear() - 90, currentDate.getMonth(), currentDate.getDate());
    let maxBirthdate = new Date(currentDate.getFullYear() - 2, currentDate.getMonth(), currentDate.getDate());
    let minExpiredDatePassport = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    let maxExpiredDatePassport = new Date(currentDate.getFullYear() + 5, currentDate.getMonth(), currentDate.getDate());

    const calendarChanged = (date, identifier) => {
        if (identifier === 'birthDay') {
            props.inputChange(getDay(date), 'day');
            props.inputChange(getMonth(date), 'month');
            props.inputChange(getYear(date), 'year');
        } else if (identifier === 'expiredDate') {
            props.inputChange(getDay(date), 'dayPassport');
            props.inputChange(getMonth(date), 'monthPassport');
            props.inputChange(getYear(date), 'yearPassport');
        }
    }

    const submitHandler = () => {
        let data = {
            title: props.form.title.value,
            firstName: props.form.firstName.value,
            lastName: props.form.lastName.value,
            birthDate: formatShortDate(new Date(`${props.form.year.value}-${props.form.month.value}-${props.form.day.value}`)),
            passportCountry: props.form.countryCode.value,
            PassportNumber: props.form.passportNumber.value,
            expiredDate: formatShortDate(new Date(`${props.form.yearPassport.value}-${props.form.monthPassport.value}-${props.form.dayPassport.value}`)),
        }
        if (props.participantId) {
            data.passengerID = props.participantId;
        }
        props.submitParticipantHandler(data, props.participantId);
    }

    let birthdate = new Date(`${props.form.year.value}-${props.form.month.value}-${props.form.day.value}`);
    let expiredDate = new Date(`${props.form.yearPassport.value}-${props.form.monthPassport.value}-${props.form.dayPassport.value}`);

    let header = (
        <div className={classes.Header}>
            <span className={classes.HeaderAction}
                onClick={props.hideClicked}>
                <Icon name="ico-arrow-left" fill={COLOR.white} stroke="none" />
            </span>
            <span>{(props.participantId)? 'Edit' : 'Add'} Participant</span>
        </div>
    );
    let content = (
        <div className={classes.Content}>
            <div className={classes.Section}>
                <PanelBox>
                    <div className={classes.Form}>
                        <div className={classes.InputWrapper}>
                            <div className="label">Title</div>
                            <MobileSelect
                                value={props.form.title.value}
                                options={props.form.title.options}
                                changed={(value) => props.inputChange(value, 'title')}/>
                            
                        </div>
                        <div className={classes.InputWrapper}>
                            <div className="label">First Name</div>
                            <TextField
                                placeholder="e.g. Julie" 
                                value={props.form.firstName.value}
                                changed={ (event) => props.inputChange(event.target.value, 'firstName')}/>
                        </div>
                        <div className={classes.InputWrapper}>
                            <div className="label">Last Name</div>
                            <TextField
                                placeholder="e.g. Applesed" 
                                value={props.form.lastName.value}
                                changed={ (event) => props.inputChange(event.target.value, 'lastName')}/>
                            
                        </div>
                        <div className={classes.InputWrapper}>
                            <div className="label">Date of Birth</div>
                            <CalendarSwipe
                                minDate={minBirthdate}
                                maxDate={maxBirthdate}
                                value={birthdate}
                                changed={date => calendarChanged(date, 'birthDay')}/>
                        </div>
                    </div>
                </PanelBox>
            </div>
            <div className={classes.Title}>Passport Number</div>
            <div className={classes.Section}>
                <PanelBox>
                    <div className={classes.Form}>
                        <div className="u-display-flex u-mb-20">
                            <div className="u-flex-3">
                                <div className="label">Country</div>
                                <MobileSelectFlag
                                    type="country"
                                    value={props.form.countryCode.value}
                                    selected={value => props.inputChange(value, 'countryCode')}/>
                            </div>
                            <div className="u-flex-7 u-ml-8">
                                <div className="label">Passport Number</div>
                                <TextField
                                    placeholder="e.g. 897676543" 
                                    value={props.form.passportNumber.value}
                                    changed={ (event) => props.inputChange(event.target.value, 'passportNumber')} />
                            </div>
                        </div>
                        <div className={classes.InputWrapper}>
                            <div className="label">Expired Date</div>
                            <CalendarSwipe
                                minDate={minExpiredDatePassport}
                                maxDate={maxExpiredDatePassport}
                                value={expiredDate}
                                changed={date => calendarChanged(date, 'expiredDate')}/>
                        </div>
                    </div>
                </PanelBox>
            </div>
            <div>
                <Button 
                    clicked={submitHandler} 
                    isLoading={props.isSubmitLoading}
                    classes={['BtnRed', 'BtnBlock', 'BtnMedium']}>Save</Button>
            </div>
        </div>
    );
    return (
        <div>
            <PopupPage 
                show={props.show}
                header={header}
                content={content}/>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        form: state.ParticipantReducer.participantForm,
        isSubmitLoading : state.ParticipantReducer.isSubmit,
        participantId : state.ParticipantReducer.participantId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        inputChange: (value, identifier) => dispatch(participantAction.inputChange(value, identifier)),
        submitParticipantHandler: (data, id) => dispatch(participantAction.submitParticipant(data, id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(addParticipant);