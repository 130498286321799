import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

export const inputChanged = (identifier, event) => {
    let value = event.target.value;
    return {
        type: 'CAREER_FORM_INPUT_CHANGED',
        payload: {identifier, value}
    };
};

export const dialCodeChanged = (value) => {
    return {
        type: 'CAREER_FORM_DIAL_CODE_CHANGED',
        value
    };
};

export const fileSelected = (file) => {
    let selectedFile = file;
    return {
        type: 'CAREER_FORM_FILE_SELECTED',
        selectedFile
    };
};

export const verifyClicked = res => {
    return {
        type: 'CAREER_FORM_VERIFY_CLICKED'
    };
};

export const formChecking = () => {
    return {
        type: 'CAREER_FORM_FORM_CHECKING'
    };
};

export const alertConfirmed = () => {
    return {
        type: 'CAREER_FORM_ALERT_CONFIRMED'
    };
};

export const submitSuccess = () => {
    return {
        type: 'CAREER_FORM_SUBMIT_SUCCESS'
    };
};

export const loadingSubmitForm = () => {
    return {
        type: 'CAREER_FORM_LOADING_SUBMIT_FORM'
    };
};

export const formSubmited = (postData) => {
    console.log(postData);
    return dispatch => {
        dispatch(loadingSubmitForm());
        console.log('CAREER', postData);
        axios.post(ANTAVAYA_API + 'v1/mailer/career', postData)
        .then( response => {
            dispatch(submitSuccess());
        })
        .catch(function (error) {
            console.log('SUBMIT_CAREER', error);
        });
    };
};