import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

const setAdmissionDetail = (data) => {
    return {
        type: 'SET_ADMISSION_DETAIL',
        payload: {
            data: data
        }
    }
}

const setIsFetchAdmission = (value) => {
    return {
        type: 'SET_IS_FETCH_ADMISSION_DETAIL',
        payload: {
            value: value
        }
    }
}

const setIsErrorFetchAdmission = (value) => {
    return {
        type: 'SET_IS_ERROR_FETCH_ADMISSION_DETAIL',
        payload: {
            value: value
        }
    }
}

const setVisitors = (data) => {
    return {
        type: 'SET_VISITORS',
        payload: {
            visitors: data
        }
    }
}

export const initVisitors = (data) => {
    // console.log('data', data)
    let visitors = data.sort((a, b) => {
        if (a.type < b.type) {
            return -1;
        }
        if (a.type > b.type) {
            return 1;
        } else {
            return 0;
        }
    });
    for (const key in visitors) {
        if (key === '0') {
            visitors[key].qty = 1;
        } else {
            visitors[key].qty = 0;
        }
    }
    return dispatch => {
        dispatch(setVisitors(visitors))
    }
}

export const visitorAdded = (index) => {
    return {
        type: 'VISITOR_ADDED',
        payload: {
            index
        }
    }
}

export const visitorRemove = (index) => {
    return {
        type: 'VISITOR_REMOVE',
        payload: {
            index
        }
    }
}

export const initAdmissionDetail = (slug) => {
    return dispatch => {
        dispatch(setIsFetchAdmission(true));
        axios.get(ANTAVAYA_API + 'tour-admission/detail/' + slug)
            .then((response) => {
                dispatch(setAdmissionDetail(response.data.data));
                dispatch(setIsFetchAdmission(false));
            })
            .catch((error) => {
                dispatch(setIsErrorFetchAdmission(true));
                dispatch(setIsFetchAdmission(false));
            })
    }
}