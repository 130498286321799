import { BRANCH_LIST } from '../../../constant/Branch'

export const setBranchOfficeList = ( branchOfficeList ) => {
    return {
        type: 'SET_BRANCH_OFFICE_LIST',
        branchOfficeList: branchOfficeList
        
    };
};

export  const fetchBranchOfficeListFailed = () => {
    return {
        type: 'FETCH_BRANCH_OFFICE_LIST_FAILED'
    }
}

export const addAllBranchOffice = (branchOfficeList) => {
    let allStore = [];
    if (branchOfficeList.length > 0) {
        branchOfficeList.forEach(branchOffice => {
            branchOffice.stores.forEach(store => {
                allStore.push(store);
            });
        });
        branchOfficeList.unshift({
            name: 'All',
            stores: allStore
        });
    }
    return branchOfficeList;
}

/*
export const initBranchOfficeList = () => {
    return dispatch => {
        fetch('https://d-antavaya.firebaseio.com/branch_office_list.json')
        .then((response) => response.json())
        .then((data) => {
            let branchOfficeList = addAllBranchOffice(data);
            dispatch(setBranchOfficeList(branchOfficeList));
        })
        .catch((err) => {
            dispatch(fetchBranchOfficeListFailed());
        })
    }
};
*/

export const initBranchOfficeList = () => {
    return dispatch => {
        let branchOfficeList = addAllBranchOffice(BRANCH_LIST);
        dispatch(setBranchOfficeList(branchOfficeList));
    }
};
