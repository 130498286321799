import { updateObject } from '../../utility';
const initialState = {
    activeNavBar: 'home'
}

const setActiveBar = (state, action) => {
    return updateObject(state, {activeNavBar: action.slug})
}

const bottomNavBar = (state=initialState, action) => {
    switch(action.type){
        case 'SELECT_PAGE': return setActiveBar(state, action);
        default: return state

    }
}

export default bottomNavBar