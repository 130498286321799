import React from 'react';
import classes from './Item.module.scss';
import { currencyFormat } from '../../../../../helper/GlobalFunc';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import {DEFAULT_IMG} from '../../../../../constant/Content';
import Ribbon from '../../../../UI/Ribbon/Ribbon';

const holidayPackageItem = (props) => {
    
    return (
        <div className={classes.Wrapper}>
            <PanelBox isOverflow={false}>
                <div className={classes.Panel}>
                    <div className={classes.Ribbon}>
                        <Ribbon text="free voucher tour" position="left" />
                    </div>
                    <div className={classes.Image}>
                        <img onError={DEFAULT_IMG} src={props.data.imageSource} alt="hotel" />
                    </div>
                    <div className={classes.PriceWrap}>
                        <div className={classes.Label}>Start From</div>
                        <div>
                            <span className={classes.Price}>{currencyFormat(props.data.hotelPrice)}</span>
                            <span>/{props.data.minNight} nights stay</span>
                        </div>
                    </div>
                </div>
            </PanelBox>
        </div>
    );
}

export default holidayPackageItem;