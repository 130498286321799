import React from 'react';
import classes from './RightContent.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Button from '../../../../UI/Button/Button';
import CurrencyFormat from 'react-currency-format';
import Ticket from './Ticket/Ticket';
import Visitors from './Visitor/Visitor';
import Date from './Date/Date';

import { connect } from 'react-redux';

const rightContent = (props) => {

    const totalPrice = (prices) => {
        let total = 0;
        for (const key in prices) {
            total += prices[key].price * prices[key].qty
        }
        return (total === 0) ? props.admissionDetail.priceStartFrom : total;
    }

    let content = null;
    if (props.isErrorFetchAdmission) {
        content = 'Error';
    } else {
        if (props.isFetchAdmission) {
            content = 'loading';
        } else {
            if (props.admissionDetail) {
                let startPeriodOfTravelling = props.admissionDetail.startPeriodOfTravelling;
                let endPeriodOfTravelling = props.admissionDetail.endPeriodOfTravelling;
                content = (
                    <PanelBox isOverflow={false}>
                        <Ticket 
                            ticket={props.ticket}
                            tickets={props.admissionDetail.tourAdmissionPrice}
                            onSetTicketClicked={(id, priceArray) => props.onSetTicketClicked(id, priceArray)}/>
                        <Date
                            startPeriodOfTravelling={startPeriodOfTravelling}
                            endPeriodOfTravelling={endPeriodOfTravelling}
                            setselectedDateHandler={(date) => props.setselectedDateHandler(date)}/>
                        <Visitors 
                            visitorsData={props.visitorsData}
                            onGuestAddedClicked={(type) => props.onGuestAddedClicked(type)}
                            onGuestRemoveClicked={(type) => props.onGuestRemoveClicked(type)}/>
                        <div className={classes.Item}>
                            <div className={classes.Price}>
                                <label>Price</label>
                                <div className={classes.PriceNumber}>
                                    <CurrencyFormat value={totalPrice(props.visitorsData)} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                                </div>
                            </div>
                            <div className={classes.ItemBtn}>
                                <Button
                                    disabled={props.activeButton}
                                    classes={['BtnSmall', 'BtnBlock', 'BtnRed']}
                                    clicked={() => props.goToBooking(props.admissionDetail.slug)}>
                                    Book Now
                                </Button>
                            </div>
                        </div>
                    </PanelBox>
                );
            }
        }
    }
    return (
        <div className={classes.Wrapper}>
            {content}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        visitors: state.AdmissionDetailReducer.visitors,
    }
}

export default connect(mapStateToProps, null)(rightContent);
