import React from 'react';
import classes from './DropdownList.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';

const dropdownList = (props) => {
    let dropdownList = null;
    if (props.dropdownList && props.dropdownList.length > 0) {
        dropdownList = props.dropdownList.map((item, index) => {
            return (
                <li key={index} className={classes.Item} onClick={() => props.setValueSelected(item.value)}>{item.name}</li>
            );
        });
    }
    
    return (
        <CSSTransition
            in={props.showDropdownList}
            mountOnEnter
            unmountOnExit
            timeout={270}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close
            }}>
            <div className={classes.Wrapper}>
                <ul className={classes.List}>
                    {dropdownList}
                </ul>
            </div>
        </CSSTransition>
    );
}

export default dropdownList;