import React from 'react';
import classes from './BranchOfficeSearch.module.scss';
import MobileSelect from '../../../UI/Inputs/MobileSelect/MobileSelect';


const branchOfficeSearch = props => {
    let options = [];
    if (props.regionList && props.regionList.length > 0) {
        options = props.regionList.map((region) => {
            return {value: region, text: region};
        });

    } 
    
    return (    
        <div className={classes.Wrapper}>
            <div className={[classes.Title, 'u-text-center', 'u-mb-20', 'u-mt-12'].join(' ')}>Select The Location</div>
            <MobileSelect 
                value={props.regionSelected}
                label="Subject"
                options={options}
                changed={(val) => props.regionChanged(val)}  />
        </div>
    );
}

export default branchOfficeSearch;