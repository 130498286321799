import React from 'react';
import classes from './InquiryForm.module.scss';
import Icon from '../../../../../UI/Icon/Icon';
import Button from '../../../../../UI/Button/Button';
import Recaptcha from 'react-recaptcha';
import CSSTransition from 'react-transition-group/CSSTransition';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import TextArea from '../../../../../UI/Inputs/TextArea/TextArea';
import Select from '../../../../../UI/Inputs/Select/Select';
import SelectFlag from '../../../../../UI/Inputs/SelectFlag/SelectFlag';
import { CAPTCHA_KEY } from '../../../../../../constant/VendorKey';

import Alert from '../../../../../UI/Alert/Alert';

import * as inquiryAction from '../../../../../../redux/NonProducts/Inquiry/InquiryAction';
import { connect } from 'react-redux';

function inquiryForm(props) {
    const verifyCallback = () => {
        props.verifyClicked()
    }

    const recaptchaLoadedHandler = () => {}

    const submitFormHandler = () => {
        if(props.isValidForm && props.isVerified) {
            let postData = {
                name: props.formData.name.value,
                phone: props.formData.dialCode.value.concat(props.formData.phone.value),
                email: props.formData.email.value,
                subject: props.formData.subject.value,
                note: props.formData.note.value,
            }
            props.formSubmited(postData);
        } else {
            props.formChecking()
        }
    }

    const options = [{
        value: 'Tour/Leisure',
        text: 'Tour/Leisure'
    },{
        value: 'Corporate',
        text: 'Corporate'
    },{
        value: 'Destination',
        text: 'Destination'
    },{
        value: 'Umroh',
        text: 'Umroh'
    },{
        value: 'Transportation',
        text: 'Transportation'
    }];

    return (
        <div className={classes.Cek}>
            <Alert
                showAlert={props.showAlert}
                confirmAlertClicked={props.alertConfirmed}
                confirmLabel="OK"/>

            <CSSTransition
                in={props.showInquiryForm}
                mountOnEnter
                unmountOnExit
                timeout={260}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
                }}>
                <div className={classes.Wrapper}>
                    <div className={classes.InquiryForm}>
                        <div className={classes.Header}>
                            <div className={classes.Title}>Inquiry</div>
                            <div className={classes.Icon} onClick={props.hideInquiryFormClicked}>
                                <Icon name="ico-close" fill="#000000" stroke="none" />
                            </div>
                        </div>
                        <div className={classes.Body}>
                            <div>
                                <div className={classes.Input}>
                                    <label>Full Name</label>
                                    <TextField
                                        placeholder="e.g. Julie Applesed" 
                                        value={props.formData.name.value} 
                                        changed={(event) => props.inputChanged('name', event)}
                                        hasError={props.formData.name.hasError && props.formData.name.errorMsg!== ''}
                                        errorMsg={props.formData.name.errorMsg}/>
                                </div>
                            </div>
                            <div className={classes.MultiColumn}>
                                <div className={classes.Input}>
                                    <label>E-mail</label>
                                    <TextField
                                        placeholder="e.g. youremail@example.com" 
                                        value={props.formData.email.value} 
                                        changed={(event) => props.inputChanged('email', event)}
                                        hasError={props.formData.email.hasError && props.formData.email.errorMsg !== ''}
                                        errorMsg={props.formData.email.errorMsg}/>
                                </div> 
                                    
                            </div>
                            <div>
                                <div className={classes.Input}>
                                    <label>Phone Number</label>
                                    <div className={classes.Inline}>
                                        <div className={classes.DialCode}>
                                            <SelectFlag 
                                                value={props.formData.dialCode.value}
                                                changed={val => props.setSelected('dialCode', val)}/>
                                        </div>
                                        <div className={classes.Phone}>
                                            <TextField
                                                type="number"
                                                placeholder="e.g. 8123456789" 
                                                value={props.formData.phone.value} 
                                                changed={(event) => props.inputChanged('phone', event)}
                                                hasError={props.formData.phone.hasError && props.formData.phone.errorMsg !== ''}
                                                errorMsg={props.formData.phone.errorMsg}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className={classes.Input}>
                                    <label>Subject</label>
                                    <Select 
                                        value={props.formData.subject.value}
                                        options={options}
                                        changed={(val) => props.setSelected('subject', val)}  />
                                </div> 
                            </div>
                            <div>
                                <div className={classes.Input}>
                                    <label>Note</label>
                                    <TextArea  
                                        placeholder="" 
                                        value={props.formData.note.value} 
                                        changed={(event) => props.inputChanged('note', event)} />
                                </div> 
                            </div>
                            <div>
                                <Recaptcha
                                    sitekey={CAPTCHA_KEY}
                                    render="explicit"
                                    onloadCallback={recaptchaLoadedHandler}
                                    verifyCallback={verifyCallback} />
                            </div>
                        </div>
                        <div className={classes.Footer}>
                            <div className={classes.Action}>
                                <Button 
                                    classes={['BtnRed', 'BtnMedium', 'BtnBlock']} 
                                    clicked={submitFormHandler}>
                                    Send Inquiry
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                </CSSTransition>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        showAlert: state.InquiryReducer.showAlert,
        isLoadingSubmitForm: state.InquiryReducer.isLoadingSubmitForm,
        isVerified: state.InquiryReducer.isVerified,
        isValidForm: state.InquiryReducer.isValidForm,
        formData: state.InquiryReducer.formData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        inputChanged: (identifier, event) => dispatch(inquiryAction.inputChange(identifier, event)),
        setSelected: (identifier ,val) => dispatch(inquiryAction.setSelected(identifier ,val)),
        formSubmited: (postData) => dispatch(inquiryAction.formSubmited(postData)),
        verifyClicked : (res) => dispatch(inquiryAction.verifyClicked(res)),
        formChecking : () => dispatch(inquiryAction.formChecking()),
        alertConfirmed : () => dispatch(inquiryAction.alertConfirmed())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(inquiryForm);