import React from 'react';
import classes from './BranchOfficeItem.module.scss';
import Icon from '../../../../UI/Icon/Icon';

const branchOfficeItem = (props) => {
    let phone = null;
    let fax = null;
    let whatsapp = null;
    if (props.region.phone !== '') {
        phone = <li className={classes.Item}><span>T</span>{props.region.phone}</li>;
    }
    if (props.region.fax !== '') {
        fax = <li className={classes.Item}><span>F</span>{props.region.fax}</li>;
    }
    if (props.region.whatsapp !== undefined && props.region.whatsapp !== '') {
        whatsapp = <a href={'https://wa.me/'+ props.region.whatsapp+'?text=Saya%20ingin%20mengetahui%20lebih%20lanjut%20tentang%20Produk%20AntaVaya'} target="blank">
                        <li className={[classes.Item, classes.Whatsapp].join(' ')}>
                            <Icon name="ico-whatsapp" stroke="none" fill="#25d366" width="20"/>
                            <div className={classes.Label}>Whatsapp</div>
                        </li>
                    </a>;
    }
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Title}>{props.region.name}</div>
            <div className={classes.Address}>
                {props.region.address}
            </div>
            <div className={classes.Contact}>
                <ul>
                    {phone}
                    {fax}
                    {whatsapp}
                </ul>
            </div>
        </div>
    );
}

export default branchOfficeItem;