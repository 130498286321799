import React from 'react';
import classes from './PhotoItem.module.scss';

const bestDealItem = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Image}>
                <img src={props.photo.url} alt={props.photo.title} />
            </div>
            <div className={classes.Description}>
                <div className={classes.Title}>{props.photo.title}</div>
            </div>
        </div>
    );
}

export default bestDealItem;