import React, { useEffect } from 'react';
import classes from './DetailContainer.module.scss';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import MetaTag from '../../../../helper/MetaTag';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import LeftContent from '../../../../components/Desktop/VirtualTour/ContentDetail/LeftContent/LeftContent';
import RightContent from '../../../../components/Desktop/VirtualTour/ContentDetail/RightContent/RightContent';
import { textLimit, toCapitalize } from '../../../../helper/GlobalFunc';
import ContentLoader from 'react-content-loader';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import WhatsappLink from '../../../../components/Desktop/Shared/WhatsappLink/WhatsappLink';

import { connect } from 'react-redux';
import * as DetailAction from '../../../../redux/VirtualTour/Detail/DetailAction';

const VirtualTourDetailContainer = (props) => {
    
    let breadcrumbLink = [];
    let title = 'Virtual Tour Detail';
    if (props.virtualTourDetail) {
        title = toCapitalize(textLimit(props.virtualTourDetail.name, 50));
        breadcrumbLink = [{
            name: 'Home',
            url: '/'
        },{
            name: 'Virtual Tour',
            // url: '/tour/availability'
        }, {
            name: title,
            url: '/tour/availability'
        }];
    }

    const goToBooking = (slug) => {
        props.history.push('/virtual-tour/booking/'+ slug)
    }

    useEffect(() => {
        let slug = props.match.params.slug;
        props.fetchVirtualTourDetail(slug);
    }, []);

    let content = null;
    let whatsappUrl = null;
    
    if (props.isFetchVirtualTourDetail) {
        content = <Loader/>;
    } else {
        if (!props.isErrorFetchVirtualTourDetail) {
            if (props.virtualTourDetail) {
                let virtualTourName = ((props.virtualTourDetail.name).split('&').join('')).split(' ').join('%20');
                whatsappUrl = `https://web.whatsapp.com/send?phone=62${(props.virtualTourDetail.customerServiceData.phoneNumber).slice(1)}&text=Saya%20ingin%20mengetahui%20lebih%20lanjut%20tentang%20virtual%20tour%20${virtualTourName}`;
                content = (
                    <React.Fragment>
                        <div className={classes.LeftContent}>
                            <LeftContent
                                breadcrumbLink={breadcrumbLink}
                                virtualTourDetail={props.virtualTourDetail}
                            />
                        </div>
                        <div className={classes.RightContent}>
                            <RightContent 
                                goToBooking={(slug) => goToBooking(slug)}
                                virtualTourDetail={props.virtualTourDetail}
                                /> 
                        </div>
                    </React.Fragment>
                );
            }
        }
    }

    return (
        <div className="bg-white-dark min-width">
            <MetaTag title={title} />
            <Header />
            <div className={classes.Content}>
                <div className={classes.Container}>
                    {content}
                </div>
            </div>
            <Footer />
            <WhatsappLink whatsappLink={whatsappUrl}/>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetchVirtualTourDetail: state.VirtualTourDetailReducer.isFetchVirtualTourDetail,
        virtualTourDetail: state.VirtualTourDetailReducer.virtualTourDetail,
        isErrorFetchVirtualTourDetail: state.VirtualTourDetailReducer.isErrorFetchVirtualTourDetail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVirtualTourDetail: (slug) => dispatch(DetailAction.fetchVirtualTourDetail(slug))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualTourDetailContainer);

const Loader = () => {
    return (
        <React.Fragment>
            <div className={classes.LeftContent}>
                <div className="u-mt-60">
                    <PanelBox>
                        <ContentLoader
                            height={440}
                            speed={1}
                            primaryColor={'#e6e6e6'}>
                                <rect x="24" y="24" rx="5" ry="5" width="300" height="16" />
                                <rect x="24" y="60" rx="5" ry="5" width="350" height="350" />
                        </ContentLoader>
                    </PanelBox>
                </div>
                <div className="u-mt-24">
                    <PanelBox>
                        <ContentLoader
                            height={150}
                            speed={1}
                            primaryColor={'#e6e6e6'}>
                                <rect x="24" y="24" rx="5" ry="5" width="100" height="12" />
                                <rect x="24" y="50" rx="5" ry="5" width="350" height="80" />
                        </ContentLoader>
                    </PanelBox>
                </div>
                <div className="u-mt-24">
                    <PanelBox>
                        <ContentLoader
                            height={150}
                            speed={1}
                            primaryColor={'#e6e6e6'}>
                                <rect x="24" y="24" rx="5" ry="5" width="100" height="12" />
                                <rect x="24" y="50" rx="5" ry="5" width="350" height="80" />
                        </ContentLoader>
                    </PanelBox>
                </div>
            </div>
            <div className={classes.RightContent}>
                <div className="u-mt-60">
                    <PanelBox>
                        <ContentLoader
                            height={350}
                            speed={1}
                            primaryColor={'#e6e6e6'}>
                                <rect x="24" y="30" rx="5" ry="5" width="150" height="16" />
                                <rect x="24" y="60" rx="5" ry="5" width="300" height="25" />

                                <rect x="24" y="125" rx="5" ry="5" width="150" height="16" />
                                <rect x="24" y="155" rx="5" ry="5" width="300" height="25" />


                                <rect x="24" y="230" rx="5" ry="5" width="80" height="16" />
                                <rect x="210" y="230" rx="5" ry="5" width="150" height="25" />

                                <rect x="24" y="280" rx="5" ry="5" width="350" height="40" />
                        </ContentLoader>
                    </PanelBox>
                </div>
            </div>
        </React.Fragment>
    )
}