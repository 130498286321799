import React from 'react';
import classes from './HolidayPackageItem.module.scss';
import CurrencyFormat from 'react-currency-format';
import { toCapitalize } from '../../../../../helper/GlobalFunc';
import Included from '../../Included/Included';

const holidayPackageItem = (props) => {
    let label = null;
    let image = '';

    if (props.packageItem.region) {
        label = props.packageItem.region;
    }
    if (props.packageItem.defaultImage) {
        image = props.packageItem.defaultImage.url;
    }

    return (
        <div className={classes.Wrapper}>
            <img src={image} alt={props.packageItem.tourInventoryTitle} />
            <div className={classes.Description}>
                <span className={classes.Label}>{label}</span>
                <div className={classes.Title}>{props.packageItem.days + 'D '} {toCapitalize(props.packageItem.inventoryTitle)}</div>
                <div className={classes.Includes}>
                    {(props.packageItem.includeFlight) &&
                        <div className={classes.Item}>
                            <Included label="Flight"/>
                        </div>
                    }
                    {(props.packageItem.includeHotel) &&
                        <div className={classes.Item}>
                            <Included label="Hotel"/>
                        </div>
                    }
                </div>
                <div>
                    <div className={classes.StartFrom}>Starting From</div>
                    <div className={classes.Price}>
                        <CurrencyFormat value={props.packageItem.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                        <div className={classes.Perpax}>/Pax</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default holidayPackageItem;