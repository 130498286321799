import React, { useEffect } from 'react';
// import classes from './VirtualTourFeatured.module.scss';
// import Card from '../../VirtualTour/Card/Card';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../redux/VirtualTour/Availability/AvailabilityAction';

const virtualTourFeatured = (props) => {

	useEffect(() => {
		props.fetchVirtualTour()
	}, []);

	return (
		<div></div>
		// <div className={classes.Wrapper}>
		// 	<div className={classes.Container}>
		// 		<div className={classes.Title}>Virtual Tour</div>
		// 		<div className={classes.SubTitle}>Enjoy the exclusive virtual tour AntaVaya and get free voucher tour</div>
		// 		<div className={classes.List}>
		// 			<Card />
		// 			<Card />
		// 		</div>
		// 	</div>
		// </div>
	)
}

const mapStateToProps = (dispatch) => {
	return {
		fetchVirtualTour: (data) => dispatch(AvailabilityAction.fetchVirtualTour(data))
	}
}

export default connect(null, mapStateToProps)(virtualTourFeatured);
