import {updateObject} from '../../utility';
import * as validation from '../../../helper/Validation';
import Cookies from 'universal-cookie';
import { encrypt, decrypt} from '../../../helper/Encrypt';
import { isMobile } from 'react-device-detect';
const cookies = new Cookies();

let rme = localStorage.getItem('rme');
let rememberData = {
    email: '',
    password: '',
    rememberMe: false
};

if (rme) {
    let deRme = decrypt(rme);
    if (deRme.rememberMe){
        rememberData = {...rememberData, ...deRme};
    }
}

const initialState = {
    showLoginRegisterForm : false,
    isLogin : false,
    isErrorLogin : false,
    loginErrorMessage : '',
    isValidForm : true,
    userLoginData : {},
    loginData : {
        email: {
            value: rememberData.email,
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
                isEmail: true
            }
        },
        password: {
            value: rememberData.password,
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
            }
        },
        rememberMe : rememberData.rememberMe
    }
}

const loginSuccess = (state, action) => {
    let sessionKey = action.payload.sessionKey;
    localStorage.setItem('sessionKey', sessionKey);
    const userProfile = {
        firstName : action.payload.dataMember.firstName,
        lastName : action.payload.dataMember.lastName,
        email: action.payload.dataMember.email,
        phoneNumber: action.payload.dataMember.phoneNumber,
        avatar: 'https://www.netralnews.com/foto/2017/11/01/271-gajah_mada_ilustrasi.jpg',

    };
    const rememberData = {
        email: state.loginData.email.value,
        password: state.loginData.password.value,
        rememberMe: state.loginData.rememberMe
    };
    let rememberMe = encrypt(rememberData);
    localStorage.setItem('rme', rememberMe);
    localStorage.setItem('userProfile', JSON.stringify(userProfile));
    if (isMobile) {
        window.location.href = '/user';
    }
    return updateObject(state, {isLogin: true, showLoginForm: false, showLoginRegisterForm: false});
}

const activationSuccess = (state, action) => {
    const userProfile = {
        firstName : action.payload.dataMember.firstName,
        lastName : action.payload.dataMember.lastName,
        email: action.payload.dataMember.email,
        phoneNumber: action.payload.dataMember.phoneNumber,
        avatar: 'https://www.netralnews.com/foto/2017/11/01/271-gajah_mada_ilustrasi.jpg',
    };
    localStorage.setItem('sessionKey', action.payload.sessionKey);
    localStorage.setItem('userProfile', JSON.stringify(userProfile));
    return updateObject(state, {isLogin: true, showLoginForm: false, showLoginRegisterForm: false});
}

const logoutSuccess = (state) => {
    cookies.remove('token', { path: '/' });
    localStorage.removeItem('sessionKey');
    localStorage.removeItem('userProfile');
    window.location.href = '/';
    
    return updateObject(state, {isLogin: false});
}

const logoutError = (state) => {
    cookies.remove('token', { path: '/' });
    localStorage.removeItem('sessionKey');
    localStorage.removeItem('userProfile');
    window.location.href = '/';
    return updateObject(state, {isLogin: false});
}

const loginError = (state, action) => {
    return updateObject(state, {
        isErrorLogin: true, loginErrorMessage: action.payload.statusText});
}

const setValidForm = (inputForm) => {
    let isValidForm = true;
    if (inputForm) {
        for (const inputIdentifier in inputForm) {
            if (inputForm[inputIdentifier].value !== undefined && inputForm[inputIdentifier].rules !== undefined ) {
                if (inputForm[inputIdentifier].hasError) {
                    isValidForm = false;
                    break;
                }
            }
        }
    }
    return isValidForm;
}

const inputChanged = (state, action) => {
    let loginData = {...state.loginData};
    let isValidForm = {...state.isValidForm};

    let validate = validation.validate(loginData[action.payload.inputIdentifier].rules, action.payload.value);
    loginData[action.payload.inputIdentifier] = {...loginData[action.payload.inputIdentifier], ...validate};

    isValidForm = setValidForm(loginData);
    return updateObject(state, {loginData, isValidForm});
}

const checkForm = (state) => {
    let loginData = {...state.loginData};
    const inputForms = ['email', 'password'];
    for (let inputIdentifier in inputForms) {
        let validate = validation.validate(loginData[inputForms[inputIdentifier]].rules, loginData[inputForms[inputIdentifier]].value);
        loginData[inputForms[inputIdentifier]] = {...loginData[inputForms[inputIdentifier]], ...validate};
    }
    
    return updateObject(state, {loginData});
}

const rememberMeChanged = (state, action) => {
    let loginData = {...state.loginData};
    loginData.rememberMe = action.payload.value;
    return updateObject(state, {loginData});
}

const submitClicked = state => {
    return state;
}

const showLoginRegisterform = (state, action) => {
    return updateObject(state, {showLoginRegisterForm: action.showForm});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SHOW_LOGIN_REGISTER_FORM': return showLoginRegisterform(state, action);
        case 'LOGIN_SUCCESS': return loginSuccess(state, action);
        case 'LOGIN_ERROR': return loginError(state, action);
        case 'LOGIN_INPUT_CHANGED': return inputChanged(state, action);
        case 'LOGIN_REMEMBER_ME_CHANGED': return rememberMeChanged(state, action);
        case 'LOGIN_SUBMIT_CLICKED': return submitClicked(state);
        case 'LOGIN_CHECK_FORM': return checkForm(state);
        case 'LOGOUT_SUCCESS': return logoutSuccess(state);
        case 'LOGOUT_ERROR': return logoutError(state);
        case 'ACTIVATION_SUCCESS': return activationSuccess(state, action);
        default: return state;
    }
};

export default reducer;