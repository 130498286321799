import React from 'react';
import classes from './ImgThumb.module.scss';
import { DEFAULT_IMG } from '../../../constant/Content';

function imgThumb(props){
    let wrapperClasses = [classes.Wrapper];

    if (props.type === 'square') {
        wrapperClasses.push(classes.Square);
    } else {
        wrapperClasses.push(classes.Circle);
    }

    if (props.size === 'small') {
        wrapperClasses.push(classes.Small);
    } else if (props.size === 'large') {
        wrapperClasses.push(classes.Large);
    } else {
        wrapperClasses.push(classes.Medium);
    }

    return(
        <div className={wrapperClasses.join(' ')}>
            <img onError={DEFAULT_IMG} className={classes.Img} src={props.src} alt="antavaya" />
        </div>
    );
}

export default imgThumb;