import {updateObject} from '../../utility';
import { MAX_PER_ROOM_TOUR } from '../../../constant/General';

const initialState = {
    isLoadingTourDetail: true,
    isLoadingSpinner: false,
    schedule: 0,
    scheduleId: 0,
    priceStartFrom: 0,
    tourDetail: null,
    tourEmpty: false,
    rooms: [{
        adult: [{
            name: '',
            dateOfBirth: ''
        }],
        child: [],
        isExtrabed: false,
        isChangeLoading: false
    }]
}

const addRoom = (state) => {
    let rooms = state.rooms;
    return updateObject( state , 
        {rooms: [...rooms, 
            {
                adult: [{
                    name: '',
                    dateOfBirth: ''
                }],
                child: [],
                isExtrabed: false,
                isChangeLoading: false
            }]
        });
};

const removeRoom = (state, action) => {
    let rooms = [...state.rooms];
    rooms.splice(action.index, 1)

    return updateObject(state, {rooms: rooms});
}

const addGuest = (state, action) => {
    let rooms = [...state.rooms];
    let guestData = {
        name: '',
        dateOfBirth: ''
    }

    let totalGuest = rooms[action.payload.index].adult.length + rooms[action.payload.index].child.length;
    if (totalGuest === MAX_PER_ROOM_TOUR) {
        return state;
    }

    rooms[action.payload.index][action.payload.guestType].push(guestData);

    return updateObject(state, {rooms: rooms});
}

const removeGuest = (state, action) => {
    let rooms = [...state.rooms] ;

    if (action.payload.guestType === 'adult' && rooms[action.payload.index][action.payload.guestType].length === 1) {
        return state;
    }

    rooms[action.payload.index][action.payload.guestType] = rooms[action.payload.index][action.payload.guestType].filter((item, index) => {
        return (index !== rooms[action.payload.index][action.payload.guestType].length -1);
    });

    return updateObject(state, {rooms: rooms});
}

const extraBedToggle = (state, action) => {
    let rooms = [...state.rooms];
    rooms[action.payload.index].isExtrabed = action.payload.value; 
    
    return updateObject(state, {rooms: rooms});
}

const setItineraryOnTourHandler = (scheduleId, schedules, tourData) => {
    let itineraryTmp = [];

    // eslint-disable-next-line
    let loopSchedule = schedules.map((itenerary) => {
        if(itenerary.tourScheduleID === scheduleId) {
            itineraryTmp = itenerary.itineraries;
        }
    })

    // eslint-disable-next-line
    let loopItinerary = tourData.itineraries.map((tour) => {
        // eslint-disable-next-line
        let loopIteneraryTmp = itineraryTmp.map((itineraryTmp) => {
            if(tour.tourItineraryID === itineraryTmp.tourItineraryID) {
                tour.flight = itineraryTmp.flight;
                tour.hotel = itineraryTmp.hotel;
            }
        })
    })

    return tourData;
}

const setDetailTour = (state, action) => {
    let schedules = action.payload.schedule;
    let scheduleID = schedules[0].tourScheduleID;
    let priceStartFrom = schedules[0].priceStartFrom;
    let tourData = action.payload;

    return updateObject(state, {
        isLoadingTourDetail: false,
        tourDetail: setItineraryOnTourHandler(scheduleID, schedules, tourData),
        schedule: action.payload.schedule,
        scheduleId: scheduleID,
        priceStartFrom
    });
}

const setTourSchedule = (state, action) => {
    let scheduleID = action.scheduleId;
    let schedules = state.schedule;
    let tourData = state.tourDetail;
    let priceStartFrom = schedules.filter(item => item.tourScheduleID === scheduleID).map(item => item.priceStartFrom)[0];

    return updateObject(state, {
        schedule: schedules,
        tourDetail: setItineraryOnTourHandler(scheduleID, schedules, tourData),
        scheduleId: scheduleID,
        priceStartFrom
    });
}

const setTourEmpty = (state) => {
    return updateObject(state, {tourEmpty: true})
}


const changeLoadingSpinner = (state, action) => {
    return updateObject(state, {
        isLoadingSpinner: action.isSubmit
    });
}

const setIsChangeParticipantLoading = (state, action) => {
    const rooms = [...state.rooms];
    rooms[action.payload.index].isChangeLoading = action.payload.value;
    return updateObject(state, { rooms })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'ADD_ROOM': return addRoom(state);
        case 'REMOVE_ROOM': return removeRoom(state, action);
        case 'ADD_GUEST': return addGuest(state, action);
        case 'REMOVE_GUEST': return removeGuest(state, action);
        case 'EXTRA_BED_TOGGLE': return extraBedToggle(state, action);
        case 'SET_TOUR_DETAIL': return setDetailTour(state, action);
        case 'SET_TOUR_EMPTY': return setTourEmpty(state);
        case 'CHANGE_LOADING_SPINNER': return changeLoadingSpinner(state, action);
        case 'SET_TOUR_SCHEDULE': return setTourSchedule(state, action);
        case 'SET_IS_CHANGE_PARTICIPANT_LOADING': return setIsChangeParticipantLoading(state, action);
        default: return state;
    }
};

export default reducer;