import React, { useEffect } from 'react'
import Header from '../../../../components/Mobile/Shared/Header/Header'
import classes from './LoginContainer.module.scss'
import PanelBox from '../../../../components/UI/PanelBox/PanelBox'
import TextField from '../../../../components/UI/Inputs/TextField/TextField'
import CheckBox from '../../../../components/UI/Inputs/CheckBox/CheckBox'
import Button from '../../../../components/UI/Button/Button'
// import icoFb from '../../../../assets/images/ico-color-facebook.png'
// import icoGoogle from '../../../../assets/images/ico-google.png'
import logo from '../../../../assets/images/logo-light.png';
import Auth from '../../../../middleware/Auth';

import * as authAction from '../../../../redux/Shared/Auth/AuthAction'
import {connect} from 'react-redux'

const loginContainer = props => {

	useEffect(() => {
		let isLogin = Auth.isAuthenticated();
		if(isLogin) {
			props.history.push('/user')
        }
	}, [])

	const forgotPass = () => {
		props.history.replace('/reset')
	}

	const goBack = () => {
		props.history.goBack()
	}

	const login = () => {
		const loginData = {
			detail: {
				email : props.loginData.email.value,
				password : props.loginData.password.value,
			}
		}
		props.submitLoginHandler(loginData)
	}

	const register = () => {
		props.history.push('/register')
	}

	return (
		<div className={classes.Background}>
			<div className={classes.Head}>
				<Header classes={['ClBlack', 'BgRed']} 
						icoColor="white"
						leftIcon="back"
						leftClicked={goBack}
						align="center"
						content={<div className={classes.Logo}><img src={logo} alt="antavaya" /></div>}/>
			</div>
			<div className={classes.Content}>
				<p className={classes.Title}>LOGIN</p>
				<p className={classes.SubTitle}>Log in to enjoy benefits of Antavaya member</p>
				<div className={classes.Panel}>
					<PanelBox>
					<div className={classes.Card}>
						<div className={classes.Input}>
						<label className={classes.Label}>Email</label>
						<TextField
							placeholder='e.g. youremail@example.com'
							value={props.loginData.email.value}
							changed={(event, inputIdentifier) => props.inputChangeHandler(event, 'email')}
							hasError={
							props.loginData.email.hasError &&
							props.loginData.email.errorMsg !== ''
							}
							errorMsg={props.loginData.email.errorMsg}
						/>
						</div>
						<div className={classes.Input}>
						<label className={classes.Label}>Password</label>
						<TextField
							type='password'
							placeholder='minimum 6 characters'
							value={props.loginData.password.value}
							changed={(event, inputIdentifier) => props.inputChangeHandler(event, 'password')}
							hasError={
							props.loginData.password.hasError &&
							props.loginData.password.errorMsg !== ''
							}
							errorMsg={props.loginData.password.errorMsg}
						/>
						</div>
						<div className={[classes.Input, classes.Remember].join(' ')}>
						<CheckBox
							label='Remember Me'
							checked={props.loginData.rememberMe}
							changed={(event) => props.rememberMeChangeHandler(event)}
						/>
						<span onClick={forgotPass} className={classes.Link}>Forgot Password?</span>
						</div>
					</div>
					</PanelBox>
				</div>
				<Button
					classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
					clicked={login}>
					Login
				</Button>
				{/* <div className={classes.Sparator}>
					<span className={classes.Text}>or login with</span>
				</div>
				<div className={classes.SocmedLogin}>
					<div className={[classes.Socmed, classes.FbBtn].join(' ')}>
						<img className={classes.Icon} src={icoFb} alt='facebook' />Facebook
					</div>
					<div className={[classes.Socmed, classes.GoogleBtn].join(' ')}>
						<img className={classes.Icon} src={icoGoogle} alt='google' />Google
					</div>
				</div> */}
				<div className={classes.LabelHeader}>
					Don't have an account?
					<span
						className={[classes.Link, classes.Bold].join(' ')}
						onClick={register}>
						Register
					</span>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return{
		state: state.AuthReducer,
		loginData: state.AuthReducer.loginData,
		isValidForm: state.AuthReducer.isValidForm,
		isLogin: state.AuthReducer.isLogin
	}
}

const mapDispatchToProps = dispatch => {
	return{
		inputChangeHandler: (event, inputIdentifier) => dispatch(authAction.inputChangeHandler(event, inputIdentifier)),
		rememberMeChangeHandler: (event) => dispatch(authAction.rememberMeChangeHandler(event)),
		checkFormHandler: () => dispatch(authAction.checkFormHandler()),
		submitLoginHandler: (data) => dispatch(authAction.submitLogin(data))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(loginContainer);
