import React from 'react'
import { Link } from 'react-scroll'
import classes from './TravelServiceItem.module.scss'
import Icon from '../../../../../UI/Icon/Icon'

const travelServiceItem = ({ service }) => {
	return (
		<div className={classes.Wrapper}>
			<div className={classes.Header}>
				<div className={classes.Title}>{service.title}</div>
			</div>
			<div className={classes.Description}>{service.description}</div>
			{service.item && service.item.length > 0 ? (
				service.item.map((item, index) => {
					return (
						<div key={index} className={classes.Item}>
							{service.title === 'AntaVaya Corporate Online Solutions' ? (
								<div style={{ marginTop: 2 }}>
									<Icon name={item.icon} fill='#CD2033' stroke='none' width={22} />
								</div>
							) : (
								<div className={classes.Icon}>
									<div style={{ position: 'absolute' }}>
										<Icon name={item.icon} fill='#FFFFFF' stroke='none' width={20} />
									</div>
								</div>
							)}
							<div className={classes.Content}>
								<div className={classes.Title}>{item.title}</div>
								<div className={classes.Detail}>{item.detail}</div>
							</div>
						</div>
					)
				})
			) : null}
			{service.title === 'AntaVaya Corporate Online Solutions' ? (
				<div className={classes.DetailOverview}>
					<Link to='solution' spy={true} smooth={true}>
						Detail Overview
					</Link>
				</div>
			) : null}
		</div>
	)
}

export default travelServiceItem
