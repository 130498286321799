import React, { Component } from 'react';
import classes from './Dropdown.module.scss';
import Icon from '../../UI/Icon/Icon';
import DropdownList from './DropdownList/DropdownList';
import onClickOutside from "react-onclickoutside";

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdownList: false
        };
    }

    showDropdownListHandler = () => {
        this.setState({showDropdownList: true});
    }

    hideDropdownListHandler = () => {
        this.setState({showDropdownList: false});
    }

    handleClickOutside = (evt) => {
        this.hideDropdownListHandler();
    }

    setValueSelectedHandler = (val) => {
        this.props.setValueSelected(val);
        this.hideDropdownListHandler();
    }

    render() {
        let iconClasses = [classes.Icon];
        if (this.state.showDropdownList) {
            iconClasses.push(classes.Active);
        }

        return (
            <div className={classes.Wrapper}>
                <div className={classes.Label}>{this.props.label}</div>
                <div className={classes.Input} onClick={this.showDropdownListHandler}>
                    <input placeholder={this.props.placeholder} value={this.props.value} readOnly/>
                    <div className={iconClasses.join(' ')}>
                        <Icon name="ico-caret-down" fill="#000000" stroke="none" />
                    </div>
                </div>
                <div className={classes.DropdownList}>
                    <DropdownList
                        dropdownList={this.props.dropdownList}
                        showDropdownList={this.state.showDropdownList}
                        setValueSelected={(val) => this.setValueSelectedHandler(val)} />
                </div>
            </div>
        );
    }
}

export default onClickOutside(Dropdown);