import { isMobile } from 'react-device-detect'
import flightImg from '../assets/images/products/flight.png'
import hotelImg from '../assets/images/products/hotel.png'
import tourImg from '../assets/images/products/tour.png'
import virtualTourImg from '../assets/images/products/virtual-tour.png'
import thingsToDoImg from '../assets/images/products/things-to-do.png'

export const GUEST_TYPE_TOUR = [ 'adult', 'child' ]

export const MAX_PER_ROOM_TOUR = 3

export const ADULT_TITLE = [
	{
		value: 'mr',
		text: 'Mr.'
	},
	{
		value: 'mrs',
		text: 'Mrs.'
	},
	{
		value: 'ms',
		text: 'Ms.'
	}
]

export const CHILD_TITLE = [
	{
		value: 'mr',
		text: 'Mr.'
	},
	{
		value: 'ms',
		text: 'Ms.'
	}
]

export const DEFAULT_TITLE = 'mr'

export const DEFAULT_DIAL_CODE = '+62'
export const DEFAULT_COUNTRY_CODE = 'ID'

export const PRODUCT_LIST = [
	{
		name: 'Tour',
		slug: 'tour',
		mobileUrl: 'tour',
		color: '#1A6FA3',
		isVisible: true,
		isNew: false,
		img: tourImg
	},
	{
		name: 'Flight',
		slug: 'flight',
		mobileUrl: 'redirect',
		color: '#CD2033',
		isVisible: false,
		isNew: false,
		img: flightImg
	},
	{
		name: 'Hotel',
		slug: 'hotel',
		mobileUrl: 'hotel/availability',
		color: '#CD2033',
		isVisible: true,
		isNew: false,
		img: hotelImg
	},
	{
		name: 'Virtual Tour',
		slug: 'virtual-tour',
		mobileUrl: 'virtual-tour/detail/aaa',
		color: '#CD2033',
		isVisible: true,
		isNew: false,
		img: virtualTourImg
	},
	// {
	// 	name: 'Voucher',
	// 	slug: 'voucher',
	// 	mobileUrl: 'voucher/availability',
	// 	color: '#CD2033',
	// 	isVisible: true,
	// 	isNew: false,
	// 	img: voucherImg
	// },
	{
		name: 'Things to do',
		slug: 'things-to-do',
		mobileUrl: 'things-to-do/availability',
		color: '#CD2033',
		isVisible: true,
		isNew: true,
		img: thingsToDoImg
	}
]

export const SUBJECTS = [
	{
		value: 'Incentive Group',
		text: 'Incentive Group'
	},
	{
		value: 'Ticket',
		text: 'Ticket'
	},
	{
		value: 'Tour',
		text: 'Tour'
	}
]

export const BOTTOM_NAV_BARS = [
	{
		slug: '/home',
		label: 'Home',
		ico: 'ico-home',
		activeUrl: [ '/', '/home' ]
	},
	{
		slug: '/user/my-booking',
		label: 'My Booking',
		ico: 'ico-booking',
		activeUrl: [ '/retrieve-booking', '/user/my-booking' ]
	},
	{
		slug: '/user',
		label: 'Account',
		ico: 'ico-account',
		activeUrl: [ '/user' ]
	}
]

export const CREDIT_CARD_LIST = [
	{
		name: 'mastercard',
		image: 'https://s3-ap-southeast-1.amazonaws.com/antavaya.com/images/banks/master-card.png'
	},
	{
		name: 'visa',
		image: 'https://s3-ap-southeast-1.amazonaws.com/antavaya.com/images/banks/visa.png'
	},
	{
		name: 'jcb',
		image: 'https://s3-ap-southeast-1.amazonaws.com/antavaya.com/images/banks/jcb.png'
	}
]

export const MONTH_MAP = {
	'1': 'Jan',
	'2': 'Feb',
	'3': 'Mar',
	'4': 'Apr',
	'5': 'May',
	'6': 'Jun',
	'7': 'Jul',
	'8': 'Aug',
	'9': 'Sep',
	'10': 'Oct',
	'11': 'Nov',
	'12': 'Dec'
}

export const SHORT_WEEK_MAP = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ]

let deviceData = {
	device: isMobile ? 'Mobile Web' : 'Desktop'
}
export const DEVICE = deviceData
export const PER_PAGE_TOUR = 10

export const DEFAULT_BOOKING_TAB = 'upcoming'
export const BOOKING_TAB = [
	{
		slug: 'upcoming',
		label: 'Upcoming Trips'
	},
	{
		slug: 'history',
		label: 'Your Trip History'
	},
	{
		slug: 'cancelled',
		label: 'Cancelled Trips'
	}
]

export const DEFAULT_BOOKING_DETAIL_TAB = 'tour-detail'
export const BOOKING_DETAIL_TAB = [
	{
		slug: 'tour-detail',
		label: 'Tour Detail'
	},
	{
		slug: 'itinerary',
		label: 'Itinerary'
	},
	{
		slug: 'contact',
		label: 'Contact'
	},
	{
		slug: 'other-information',
		label: 'Other Information'
	}
]

export const PAYMENT_STEP = [
	{
		icon: 'ico-done',
		label: 'Fill in data'
	},
	{
		icon: 'ico-payment-2',
		label: 'Payment'
	},
	{
		icon: 'ico-done',
		label: 'Done'
	}
]

export const INQUIRY_STEP = [
	{
		icon: 'ico-done',
		label: 'Fill in data'
	},
	{
		icon: 'ico-done',
		label: 'Done'
	}
]

export const AGE_RANGE_ADMISSION = {
	adult: '13-59 years',
	child: '2-12 years',
	senior: '60-99 years'
}
