import React, { } from 'react';
import Modal from '../../../../../UI/Modal/Modal';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import Button from '../../../../../UI/Button/Button';
import Select from '../../../../../UI/Inputs/Select/Select';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../../redux/Shared/Member/MemberAction';

function fullNameAlert (props) {
    const updateFormName = () => {
        let dataMember = {
            title: props.formName.title.value,
            firstName: props.formName.firstName.value,
            lastName: props.formName.lastName.value
        }
        props.submitChangedMember(dataMember)
    }
    
    return (
        <Modal 
            show={props.showUpdateNameForm}
            title="Edit Full Name"
            closeClicked={() => props.showHideUpdateNameClicked(false)}>
                <div className="u-mb-40">
                    <div className="u-mb-24">
                        <div className="label">Title</div>
                        <Select
                            value={props.formName.title.value}
                            options={props.formName.title.options}
                            changed={(value) => props.selectChangedTitle(value)}/>
                    </div>
                    <div className="u-mb-24">
                        <div className="label">First Name</div>
                        <TextField
                            placeholder="e.g. Julie" 
                            value={props.formName.firstName.value}
                            changed={(event) => props.inputChangedName(event, 'firstName')}/>
                    </div>
                    <div className="u-mb-24">
                        <div className="label">Last Name</div>
                        <TextField
                            placeholder="e.g. Applesed" 
                            value={props.formName.lastName.value}
                            changed={(event) => props.inputChangedName(event, 'lastName')}/>
                    </div>
                </div>
                <div className="u-display-flex">
                    <div className="u-flex-5 u-mr-8">
                        <Button 
                            classes={['BtnOutline', 'BtnMedium', 'BtnBlock']}
                            clicked={() => props.showHideUpdateNameClicked(false)}>Cancel</Button>
                    </div>
                    <div className="u-flex-5 u-ml-8">
                        <Button 
                            clicked={() => updateFormName()}
                            isLoading={props.isSubmitLoading}
                            classes={['BtnRed', 'BtnMedium', 'BtnBlock']}>Save</Button>
                    </div>
                </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        formName: state.MemberReducer.formName,
        isSubmitLoading: state.MemberReducer.isSubmitLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChangedName: (event, inputIdentifier) => dispatch(memberAction.inputChangedName(event, inputIdentifier)),
        selectChangedTitle: (value) => dispatch(memberAction.selectChangedTitle(value)),
        submitChangedMember: (dataMember) => dispatch(memberAction.submitChangedMember(dataMember, 'generalForm'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(fullNameAlert);