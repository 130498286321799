import React from 'react';
import classes from './IteneraryList.module.scss';
import ItineraryItem from './ItineraryItem/ItineraryItem';

const itineraryList = (props) => {

    let itineraryList = null;
    if (props.iteneraryList && props.iteneraryList.length > 0) {
        itineraryList = props.iteneraryList.map( (itinerary, index) => {
            return (
                <div key={index} className={classes.Item}>
                    <ItineraryItem 
                        itenerary={itinerary}
                        isActive={(index === 0) ? true: false} />
                </div>
            );
        });
    }

    return (
        <div className={classes.List}>
            {itineraryList}
        </div>
    )
}
export default itineraryList;
