import React, { useState } from 'react'
import classes from './InquiryMenu.module.scss'
import Button from '../../../../UI/Button/Button'
import Text from '../../../../UI/Text/Text'
import InquiryForm from './InquiryForm/InquiryForm'
import Backdrop from '../../../../UI/Backdrop/Backdrop'
import Alert from '../../../../UI/Alert/Alert'

function newsLetterForm() {
	const [ showAlert, setShowAlert ] = useState(false)
	const [ showInquiryForm, setShowInquiryForm ] = useState(false)

	const bodyOverflowHidden = () => {
		document.body.style.overflow = 'hidden'
	}

	const bodyOverflowAuto = () => {
		document.body.style.overflow = 'auto'
	}

	const showInquiryFormClickHandler = (val) => {
		if (val) {
			bodyOverflowHidden()
		} else {
			bodyOverflowAuto()
		}
		setShowInquiryForm(val)
	}

	const showAlertClickHandler = (val) => {
		if (val) {
			bodyOverflowHidden()
		} else {
			bodyOverflowAuto()
		}
		setShowAlert(val)
	}

	const submitSuccess = () => {
		showInquiryFormClickHandler(false)
		showAlertClickHandler(true)
		bodyOverflowHidden()
	}

	return (
		<div>
			<Alert showAlert={showAlert} confirmAlertClicked={() => showAlertClickHandler(false)} confirmLabel='OK' />

			<Backdrop showBackdrop={showInquiryForm} />
			<InquiryForm
				showInquiryForm={showInquiryForm}
				hideInquiryFormClicked={() => showInquiryFormClickHandler(false)}
				submitSuccess={submitSuccess}
			/>

			<div className={classes.Wrapper}>
				<div className={classes.Description}>
					<div>
						<Text classes={[ 'TxWhite', 'TxBold' ]}>
							Subscribe to our newsletter now and be the first to know about Antavaya latest promos!
						</Text>
					</div>
				</div>
				<div className={classes.Action}>
					<Button
						classes={[ 'BtnBlue', 'BtnBlock', 'BtnFontMedium', 'BtnMedium' ]}
						clicked={() => showInquiryFormClickHandler(true)}>
						Inquiry
					</Button>
				</div>
			</div>
		</div>
	)
}

export default newsLetterForm
