import React, { useState } from 'react';
import classes from './DetailPanel.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../UI/Icon/Icon';

const detailPanel = (props) => {
    const [showMore, setShowMore] = useState(false);

    const showMoreToggleHandler = () => {
        setShowMore(!showMore);
    }

    let detailClasses = [classes.Wrapper];
    let iconClasses = [];
    let collapseBtn = null;
    let label = 'Show More';
    if (props.isCollapseBtn && props.isCollapseBtn === true) {
        if (showMore) {
            detailClasses = [classes.Detail, classes.More];
            label = 'Show Less';
            iconClasses = [classes.ArrowUp];
        } else {
            label = 'Show More';
            detailClasses = [classes.Detail, classes.Less];
        }
        collapseBtn = (
            <div className={classes.CollapseBtn}>
                <div className={classes.Overlay}></div>
                <div className={classes.Button} onClick={showMoreToggleHandler}>
                    {label} 
                    <div className={iconClasses.join(' ')}>
                        <Icon name="ico-caret-down" fill="#1A6FA3" stroke="none"  />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Label}>{props.title}</div>
            <PanelBox>
                <div className={classes.Content}>
                    <div className={detailClasses.join(' ')}>
                        {props.data}
                    </div>
                    <div className={classes.WrapperBtn}>
                        {collapseBtn}
                    </div>
                </div>
            </PanelBox>
        </div>
    )
}

export default detailPanel;
