import React from 'react';
import classes from './NotFound.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import img from '../../../../../assets/images/art-tour-empty.png';
import Button from '../../../../UI/Button/Button';

function notFound(props){
    return (
        <div className={classes.Wrapper}>
            <PanelBox>
                <div className={classes.Content}>
                    <img src={img} className={classes.Image} alt="booking not found" />
                    <div className={classes.Title}>Oops, No Active Booking Found</div>
                    <div className={classes.SubTitle}>Anything you booked shows up here, but it seems like you haven’t made any. Let’s create one via homepage!</div>
                    <div className={classes.Action}>
                        <Button 
                            classes={['BtnRed', 'BtnMedium']}
                            clicked={props.clicked}>
                            Another Booking
                        </Button>
                    </div>
                </div>
            </PanelBox>
        </div>
    );
}

export default notFound