import React from 'react';
import classes from './CreditCard.module.scss';
import Icon from '../../../UI/Icon/Icon';

const visa = (props) => {
    let active = () => {
        if (props.isActive) {
            return (
                <div className={classes.Verified}>
                    <Icon name="ico-check-circle" width={45} stroke="none" fill="#73C700" />
                </div>
            )
        }
    }
    return (
        <div className={classes.CardWrapper}>
            {active()}
            <div className={classes.Logo}>
                <img className={classes.Image} src={'https://s3-ap-southeast-1.amazonaws.com/antavaya.com/images/banks/master-card.png'} alt="Credit Card"/>
            </div>
            <div className={classes.Number}>
                <span className={classes.Hide}></span>
                <span className={classes.Hide}></span>
                <span className={classes.Hide}></span>
                <span className={classes.Show}>2022</span>
            </div>
            <div className={classes.Detail}>
                <div className={classes.SubDetail}>
                    <div className={classes.Label}>CARD HOLDER</div>
                    <div className={classes.Value}>John Due</div>
                </div>
                <div className={[classes.SubDetail, classes.Small].join(' ')}>
                    <div className={classes.Label}>EXPIRE</div>
                    <div className={classes.Value}>22/24</div>
                </div>
            </div>
        </div>
    )
}

export default visa;
