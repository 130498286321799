import React, { useState, useEffect } from 'react'
import classes from './CorporateContainer.module.scss'
import Icon from '../../../../components/UI/Icon/Icon'
import Footer from '../../../../components/Mobile/Shared/Footer/Footer'
import {
	TRAVEL_LEADERS_GROUP,
	NEW_CORPORATE_TRAVEL_SERVICES,
	CORPORATE_ONLINE_SOLUTION_MOBILE
} from '../../../../constant/Content'
import Button from '../../../../components/UI/Button/Button'
import WhatsappLink from '../../../../components/Mobile/Shared/WhatsappLink/WhatsappLink'
import Alert from '../../../../components/UI/Alert/Alert'

import { connect } from 'react-redux'
import * as contactUsAction from '../../../../redux/NonProducts/ContactUs/ContactUsAction'
import LOGO from '../../../../assets/images/CorpTravel_Logo.svg'
import COS from '../../../../assets/images/COS_warna.png'
import { Link } from 'react-scroll'

const corporateContainer = (props) => {
	const [ isSecondHeader, setIsSecondHeader ] = useState(false)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const handleScroll = () => {
		const offsetTop = window.scrollY
		if (offsetTop > 140) {
			setIsSecondHeader(true)
		} else {
			setIsSecondHeader(false)
		}
	}

	const backClickHandler = () => {
		props.history.push('/')
	}

	let secondHeaderClasses = [ classes.Header, classes.SecondNav ]
	if (isSecondHeader) {
		secondHeaderClasses.push(classes.Show)
	}

	const intro = TRAVEL_LEADERS_GROUP.map((content, index) => {
		return <p dangerouslySetInnerHTML={{ __html: content }} key={index} />
	})
	const services = NEW_CORPORATE_TRAVEL_SERVICES.map((content, index) => {
		return (
			<li key={index} className={classes.Item}>
				<div className={classes.TitleWrapper}>
					<div className={classes.ServiceTitle}>{content.title}</div>
					<div className={classes.Description}>{content.description}</div>
				</div>
				{content.item.map((item, index) => (
					<div key={index} className={classes.SubItemWrapper}>
						{item.icon === 'ico-check-circle' ? (
							<div style={{ marginTop: 2 }}>
								<Icon name={item.icon} fill='#CD2033' stroke='none' width={22} />
							</div>
						) : (
							<div className={classes.SubItemIcon}>
								<div style={{ position: 'absolute' }}>
									<Icon name={item.icon} fill='#FFFFFF' stroke='none' width={20} />
								</div>
							</div>
						)}
						<div
							className={item.icon === 'ico-check-circle' ? classes.SubItemContent : classes.ContentLeft}>
							<div className={classes.SubItemTitle}>{item.title}</div>
							<div className={classes.SubItemDetail}>{item.detail}</div>
						</div>
					</div>
				))}
				{content.title === 'AntaVaya Corporate Online Solutions' ? (
					<div className={classes.DetailOverview}>
						<Link to='solution' spy={true} smooth={true}>
							Detail Overview
						</Link>
					</div>
				) : null}
			</li>
		)
	})
	const onlineComparison = CORPORATE_ONLINE_SOLUTION_MOBILE.map((content, index) => {
		let stars = []
		for (let index = 0; index < content.star; index++) {
			stars.push(<Icon name='ico-star' fill='#F99E00' stroke='none' />)
		}
		return (
			<li key={index} className={classes.OnlineItemWrapper}>
				<div className={classes.TitleWrapper}>
					<div className={classes.ServiceTitle}>{content.title}</div>
					<div className={classes.StarWrapper}>{stars}</div>
				</div>
				<div className={classes.Divider} />
				{content.desc.map((descs, index) => (
					<div key={index} className={classes.SubItemWrapper}>
						<div className={classes.SubItemTitle}>{descs.title}</div>
						{descs.item.map((item, index) => (
							<div key={index} className={classes.SubItemContent}>
								<Icon name='ico-check' fill='#818589' stroke='none' width={24} />
								<div className={classes.SubItemDetail}>{item}</div>
							</div>
						))}
					</div>
				))}
				<a href={content.mail}>
					<Button classes={[ 'BtnSmall', 'BtnBlock', 'BtnRed' ]}>Contact Us</Button>
				</a>
			</li>
		)
	})

	return (
		<div className={classes.Wrapper}>
			<Alert showAlert={props.showAlert} confirmAlertClicked={props.alertConfirmed} confirmLabel='OK' />
			<div className={classes.Header}>
				<span className={classes.Icon} onClick={backClickHandler}>
					<Icon name='ico-arrow-left' width='20' stroke='none' fill='#ffffff' />
				</span>
			</div>
			<div className={secondHeaderClasses.join(' ')} onClick={backClickHandler}>
				<span className={classes.Icon}>
					<Icon name='ico-arrow-left' width='20' stroke='none' fill='#202124' />
				</span>
				<div className={classes.Title}>Corporate</div>
			</div>

			<div className={classes.Content}>
				<BannerPage title='Corporate' image='https://media01.cdnpergi.com/antavaya/images/corporate-1.jpg' />
				<div className={classes.TextContent}>
					<div className={classes.SubTitle}>Overview</div>
					<div className={classes.Description}>{intro}</div>
				</div>
				<div className={[ classes.Section ].join(' ')}>
					<div className={classes.Title}>Corporate Travel Services</div>
					<ul className={classes.List}>{services}</ul>
				</div>
				<div id='solution' className={[ classes.Section, 'bg-white-dark' ].join(' ')}>
					<div className={classes.CosLogo}>
						<img width={216} height={69.66} alt='COS logo' src={COS} />
					</div>
					<ul className={classes.List}>{onlineComparison}</ul>
				</div>
				<Footer corporate />
			</div>
			<WhatsappLink />
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		formData: state.ContactUsReducer.formData,
		showAlert: state.ContactUsReducer.showAlert,
		isValidForm: state.ContactUsReducer.isValidForm,
		isVerified: state.ContactUsReducer.isVerified,
		isLoadingSubmitForm: state.ContactUsReducer.isLoadingSubmitForm
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		inputChanged: (identifier, event) => dispatch(contactUsAction.inputChanged(identifier, event)),
		dialCodeChanged: (value) => dispatch(contactUsAction.dialCodeChanged(value)),
		subjectChanged: (value) => dispatch(contactUsAction.subjectChanged(value)),
		verifyClicked: (res) => dispatch(contactUsAction.verifyClicked(res)),
		formSubmited: (postData) => dispatch(contactUsAction.formSubmited(postData)),
		formChecking: () => dispatch(contactUsAction.formChecking()),
		alertConfirmed: () => dispatch(contactUsAction.alertConfirmed())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(corporateContainer)

const BannerPage = (props) => {
	return (
		<div className={classes.WrapperBanner}>
			<img className={classes.LogoBanner} src={LOGO} alt={props.title} />
			<div className={classes.OverlayBanner} />
			<img className={classes.ImageBanner} src={props.image} alt={props.title} />
		</div>
	)
}
