import React from 'react';
import classes from './VerifyCode.module.scss';
import PopupPage from '../../../UI/PopupPage/PopupPage';
import Icon from '../../../UI/Icon/Icon';
import { COLOR } from '../../../../constant/Library';
import Button from '../../../UI/Button/Button';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';
import * as memberAction from '../../../../redux/Shared/Member/MemberAction';

function verifyCode (props) {
    let header = (
        <div className={classes.Header}>
            <span className={classes.HeaderAction}
                onClick={props.hideClicked}>
                <Icon name="ico-arrow-left" fill={COLOR.white} stroke="none" />
            </span>
            <span>Verification Code</span>
        </div>
    );
    let content = (
        <div className={classes.Content}>
            <div className={classes.Section}>
                <div className={classes.Title}>Confirmation</div>
                <div className={classes.Text}>Please enter the verification code that we sent to <b>{props.phone}</b></div>
                <div className={classes.Otp}>
                <OtpInput
                    onChange={otp => props.otpChanged(otp)}
                    inputStyle="otp-input"
                    numInputs={4}
                    value={props.otp}
                    separator={<span></span>}
                    />
                </div>
                <div className={classes.Button}>
                    <Button 
                        clicked={() => props.confirmOtp(props.otp)}
                        classes={['BtnRed', 'BtnBlock', 'BtnMedium']}>Verify</Button></div>
                <div onClick={props.sendOtp} className={classes.Link}>Send New Code</div>
            </div>
        </div>
    );
    return (
        <div>
            <PopupPage 
                show={props.show}
                header={header}
                content={content}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        otp: state.MemberReducer.otp,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendOtp: () => dispatch(memberAction.sendOtp()),
        otpChanged: (otp) => dispatch(memberAction.setOtp(otp)),
        confirmOtp: (otp) => dispatch(memberAction.confirmOtp(otp))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(verifyCode);