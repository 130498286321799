import React, { Component } from 'react';
import Backdrop from '../Backdrop/Backdrop';
import classes from './UnavailableTour.module.scss';
import emptyImage from '../../../assets/images/art-tour-empty.png';
import Button from '../Button/Button';
import CSSTransition from 'react-transition-group/CSSTransition';

class UnavailableTour extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.props.showUnavailableTour) {
            document.body.style.overflow = 'hidden';
        }
    }

    goToUrlHandler = () => {
        window.location.href = '/';
    }

    render() {
        return (
            <div>
                <Backdrop showBackdrop={this.props.showUnavailableTour}/>
                <CSSTransition
                in={this.props.showUnavailableTour}
                mountOnEnter
                unmountOnExit
                timeout={160}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
                }}>
                    <div className={classes.Wrapper}>
                        <div className={classes.Alert}>
                            <div className={classes.Icon}>
                                <img src={emptyImage} alt="Empty" />
                            </div>
                            <div className={classes.Title}>{this.props.title}</div>
                            <div className={classes.Description}>{this.props.description}</div>
                            <div className={classes.Action}>
                                <div className={classes.SingleBtn}>
                                    <Button 
                                        classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                                        clicked={this.goToUrlHandler}>{this.props.buttonLabel}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default UnavailableTour;