import React from 'react';
import classes from './AdmissionDetail.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import Header from '../../../../Shared/Header/Header';
import TourInfo from '../AdmissionDetail/TourInfo/TourInfo';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import { toCapitalize, pluralize } from '../../../../../../helper/GlobalFunc';
import CurrencyFormat from 'react-currency-format';

const admissionDetail = (props) => {

    const totalPrice = (prices) => {
        let total = 0;
        for (const key in prices) {
            total += prices[key].price * prices[key].qty
        }
        return (total === 0) ? props.admissionDetail.priceStartFrom : total;
	}
	
	let content = null;
	let visitors = null;
	let visitorsData = JSON.parse(localStorage.getItem('visitors')).filter((data) => data.qty > 0);
    let adults = null;
    let childs = null;
	let seniors = null;
	let allPrice = null;
	let personsDetail = [];
	
	if (props.admissionDetail) {
		visitors = visitorsData.map((item, index) => {
			if (item.type === 'adult' && item.qty > 0) {
				adults = pluralize(item.qty, 'Adult', 'Adults');
				personsDetail.push(adults);
			} else if (item.type === 'child' && item.qty > 0) {
				childs = pluralize(item.qty, 'Child', 'Childs');
				personsDetail.push(childs);
			} else if (item.type === 'senior' && item.qty > 0) {
				seniors = pluralize(item.qty, 'Senior', 'Seniors');
				personsDetail.push(seniors);
			} else if (item.type === 'all-price' && item.qty > 0) {
				allPrice = pluralize(item.qty, 'All Age', 'All Ages');
				personsDetail.push(allPrice);
			}
			return (
				<div className={classes.Item} key={index}>
					<div className={classes.Label}>{toCapitalize(item.type)} x {item.qty}</div>
					<div className={classes.SubTotal}>
						<CurrencyFormat value={item.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
					</div>
				</div>
			)
		})

		content = (
			<div className={classes.Content}>
				<div className='u-mb-24'>
					<TourInfo admissionDetail={props.admissionDetail} personsDetail={personsDetail}/>
				</div>
				<div className={classes.PriceDetails}>
					<div className={classes.Title}>Pricing Details</div>
					<PanelBox>
						<div className={classes.Body}>
							{visitors}
						</div>
						<div className={classes.Footer}>
							<div className={classes.Item}>
								<div className={classes.Label}>Total</div>
								<div className={classes.Total}>
									<CurrencyFormat value={totalPrice(visitorsData)} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
								</div>
							</div>
						</div>
					</PanelBox>
				</div>
			</div>
		)
	}
    return (
		<CSSTransition
			in={props.show}
			mountOnEnter
			unmountOnExit
			timeout={270}
			classNames={{
				enterActive: classes.Open,
				exitActive: classes.Close,
			}}>
			<div className={classes.Wrapper}>
				<div className={classes.Header}>
					<Header
						classes={["Shadow"]}
						leftIcon="close"
						leftClicked={props.hidePopupClicked}
						content="Admission Detail"
					/>
				</div>
				{content}
            </div>
        </CSSTransition>
    )
}

export default admissionDetail;
