import React from 'react';
import classes from './LeftContent.module.scss';
import Breadcrumb from '../../../Shared/Breadcrumb/Breadcrumb';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import { DEFAULT_IMG } from '../../../../../constant/Content';
import ListPanel from './ListPanel/ListPanel';
import Icon from '../../../../UI/Icon/Icon';
import List from '../../../../UI/List/List';
import RedemAndAdditionalInfo from './RedemAndAdditionalInfo/RedemAndAdditionalInfo';
import { formatDisplayDate } from '../../../../../helper/GlobalFunc';

const leftContent = (props) => {
    let breadcrumb = <Breadcrumb linkList={props.breadcrumbLink}/>;

    let image = null;
    let description = null;
    let include = null;
    let toc = null;
    let additional = null;
    let content = null;

    if (props.isErrorFetchAdmission) {
        content = 'error';
    } else {
        if (props.isFetchAdmission) {
            content = 'loading';
        } else {
            if (props.admissionDetail) {
                image = (
                    <PanelBox>
                        <div className={classes.WrapperItem}>
                            <div className={classes.Title}>{props.admissionDetail.tourAdmissionName}</div>
                            <div className={classes.Location}>
                                <Icon name="ico-location" fill="#6A6E73" stroke="none" width={16}/>
                                <div className={classes.Detail}>
                                    {props.admissionDetail.location}
                                </div>
                            </div>
                            <img onError={DEFAULT_IMG} src={props.admissionDetail.image} alt="" />
                        </div>
                    </PanelBox>
                )
    
                description = (
                    <PanelBox>
                        <div className="u-p-24">
                            <div className={classes.Title}>Description</div>
                            <div className={classes.Summary}>
                                {props.admissionDetail.description}
                            </div>
                            <div className={classes.ValidUntil}>
                                <Icon name="ico-calendar" fill="#6A6E73" stroke="none" width={20} />
                                <div className="u-pl-10 u-pt-6">Valid until {formatDisplayDate(props.admissionDetail.startPeriodOfTravelling)} - {formatDisplayDate(props.admissionDetail.endPeriodOfTravelling)}</div>
                            </div>
                        </div>
                    </PanelBox>
                )
    
                include = (
                    <PanelBox>
                        <div className="u-p-24">
                            <div className={classes.Title}>Include</div>
                            <List
                                listColor="green"
                                list={props.admissionDetail.include}/>
                        </div>
                    </PanelBox>
                )
    
                toc = (
                    <ListPanel
                        listColor="grey"
                        title="Term and Condition Free Voucher Tour"
                        list={props.admissionDetail.termAndCondition} />
                )
    
                additional = (
                    <div className="u-mb-16">
                        <RedemAndAdditionalInfo 
                            redemptionList={props.admissionDetail.ticketExchange}
                            additionalList={props.admissionDetail.additionalInformation}
                        />
                    </div>
                )
    
                content = (
                    <div>
                        <div className="u-mb-24">
                            {image}
                        </div>
                        <div className="u-mb-24">
                            {description}
                        </div>
                        <div className="u-mb-24">
                            {include}
                        </div>
                        <div className="u-mb-24">
                            {toc}
                        </div>
                        {additional}
    
                    </div>
                )
            }
        }
    }
    
    return (
        <div className={classes.Wrapper}>
            {breadcrumb}
            {content}

        </div>
    )
}

export default leftContent;
