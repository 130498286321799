import React, { useEffect } from 'react';
import Backdrop from '../../Backdrop/Backdrop';
import classes from './Confirm.module.scss';
import Button from '../../Button/Button';
import CSSTransition from 'react-transition-group/CSSTransition';
import { overflowBody } from '../../../../helper/GlobalFunc';

function confirm(props) {
    useEffect( () => {
        overflowBody(props.show);
    }, [props.show]);

    let title = 'This is title';
    let desc = 'You can add alert description here!';
    let cancelLabel = 'No';
    let confirmLabel = 'Yes';

    if (props.title) {
        title = props.title;
    }
    if (props.desc) {
        desc = props.desc;
    }
    if (props.cancelLabel) {
        cancelLabel = props.cancelLabel;
    }
    if (props.confirmLabel) {
        confirmLabel = props.confirmLabel;
    }

    return(
        <div>
            <Backdrop showBackdrop={props.show}/>
            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={160}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
            }}>
                <div className={classes.Wrapper}>
                    <div className={classes.Content}>
                        <div className={classes.Title}>{title}</div>
                        <div className={classes.Description}>{desc}</div>
                        <div className={classes.Action}>
                            <div className="u-flex-5 u-mr-8">
                                <Button 
                                    classes={['BtnOutline', 'BtnMedium', 'BtnBlock']}
                                    clicked={props.cancelClicked}>{cancelLabel}</Button>
                            </div>
                            <div className="u-flex-5 u-ml-8">
                                <Button
                                    classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                                    isLoading={props.isConfirmLoading}
                                    clicked={props.confirmClicked}>{confirmLabel}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default confirm;