import React from 'react';
import classes from './BranchOfficeSearch.module.scss';
import Select from '../../../UI/Inputs/Select/Select';


const branchOfficeSearch = props => {
    let options = [];
    if (props.regionList && props.regionList.length > 0) {
        options = props.regionList.map((region) => {
            return {value: region, text: region};
        });

    } 
    
    return (    
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className={[classes.Title, 'u-text-center', 'u-mb-25'].join(' ')}>Select The Location</div>
                <div className={classes.Description}>
                    <Select 
                        value={props.regionSelected}
                        options={options}
                        changed={(val) => props.regionChanged(val)}  />
                </div>
            </div>
        </div>
    );
}

export default branchOfficeSearch;