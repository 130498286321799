import React from 'react';
import classes from './List.module.scss';

const list = (props) => {
    let listClasses = [classes.Item];
    if (props.listColor) {
        if (props.listColor === 'green') {
            listClasses.push(classes.ListGreen); 
        }
        if (props.listColor === 'red') {
            listClasses.push(classes.ListRed); 
        }
    }
    if (props.listSize === 'small') {
        listClasses.push(classes.ListSmall); 
    }

    let itemlist = null;
    if (props.list && props.list.length > 0) {
        itemlist = props.list.map((item, index) => {
            return (item !== '') ? <li key={index} className={listClasses.join(' ')}>{item}</li>: null;
        });
    }
    return (
        <ul className={classes.Wrapper}>
            {itemlist}
        </ul>
    );
}

export default list;