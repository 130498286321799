import React from 'react';
import classes from './BookingList.module.scss';
import Item from './Item/Item';
import ContentLoader from 'react-content-loader';

import { connect } from 'react-redux';
import NotFound from '../NotFound/NotFound';

const bookingList = (props) => {
    let bookingList = null;
    if (!props.isFetch) {
        bookingList = props.bookingList.filter( item => {
            return item.orderStatus === props.activePage;
        }).map( (item, index) => {
            return (
                <li key={index} className={classes.Item}>
                    <Item data={item} 
                        bookingDetailClicked={() => props.bookingDetailClicked(item.bookingID)} />
                </li>
            );
        });
        if (bookingList.length === 0) {
            bookingList = <NotFound />;
        }
    } else {
        bookingList = <Loader />
    }
    
    return (
        <div>
            <ul className={classes.List}>
                {bookingList}
            </ul>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        bookingList: state.MyBookingReducer.bookingList,
        isFetch: state.MyBookingReducer.isFetch
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const Loader = () => {
    let content = [];
    for (let i = 0; i < 4; i++) {
        content.push(
            <div key={i} className={classes.Loader}>
                <ContentLoader
                    height={80}
                    speed={1}
                    primaryColor={'#e6e6e6'}>
                    <rect x="0" y="0" rx="2" ry="2" width="100" height="8" />
                    <rect x="350" y="0" rx="2" ry="2" width="60" height="8" />
                    <rect x="0" y="25" rx="5" ry="5" width="50" height="50" />

                    <rect x="60" y="25" rx="2" ry="2" width="70" height="8" />
                    <rect x="60" y="40" rx="2" ry="2" width="100" height="8" />
                    <rect x="60" y="60" rx="2" ry="2" width="50" height="8" />

                    <rect x="200" y="25" rx="2" ry="2" width="70" height="8" />
                    <rect x="200" y="45" rx="2" ry="2" width="120" height="12" />
                </ContentLoader>
            </div>
        )
    }
    return content;
}

export default connect(mapStateToProps, mapDispatchToProps)(bookingList);