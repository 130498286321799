import React, { Component } from 'react';
import classes from './HolidayPackage.module.scss';
import HolidayPackageItem from './HolidayPackageItem/HolidayPackageItem';
import Slider from 'react-slick';
import Icon from '../../../UI/Icon/Icon';
import { withRouter } from 'react-router-dom';
import axios from '../../../../axios';
import { ANTAVAYA_API } from '../../../../constant/Api';
import CardListLoaderMobile from '../../../UI/Loaders/Mobile/Card/CardListLoaderMobile';
import Ribbon from '../../../UI/Ribbon/Ribbon';

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div
			className={[classes.Arrow, classes.PrevArrow].join(' ')}
			onClick={onClick}>
			<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48} />
		</div>
	);
}

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div
			className={[classes.Arrow, classes.NextArrow].join(' ')}
			onClick={onClick}>
			<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48} />
		</div>
	);
}

class HolidayPackage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			tourPackages: []
		};
	}

	goToDetailPageHandler = (id) => {
		this.props.history.push('/tour/detail/' + id);
	}

	seeMoreHandler = () => {
		this.props.history.push('tour/availability?destination=all-region');
	}

	componentDidMount() {
		axios.get(ANTAVAYA_API + 'v1/tour/features')
			.then(response => {
				if (response.data) {
					this.setState({
						isLoading: false,
						tourPackages: response.data
					});
				} else {
					this.props.history.push('/');
				}
			})
			.catch(error => {
				console.log(error)
			})
	}

	render() {
		const settings = {
			dots: false,
			arrows: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			autoplay: false,
			autoplaySpeed: 2500,
			dotsClass: "slick-dots " + classes.DotWrapper,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			touchMove: true,
			draggable: false,
			customPaging: function (i) {
				return (
					<div className="banner-product"></div>
				);
			},
		};

		let packageList = [];
		if (this.state.isLoading) {
			let index = null;
			let card = (
				<div className={classes.Item} key={index}>
					<CardListLoaderMobile />
				</div>
			)
			for (index = 0; index < 3; index++) {
				packageList.push(card)
			}
		} else {
			if (!this.state.isLoading && this.state.tourPackages.length > 0) {
				packageList = this.state.tourPackages.map((packageItem, index) => {
					return (
						<div key={index} className={classes.Item} onClick={() => this.goToDetailPageHandler(packageItem.slug)}>
							<div className={classes.Ribbon}>
								<Ribbon text="free voucher tour" position="left" />
							</div>
							<HolidayPackageItem packageItem={packageItem} />
						</div>
					);
				});
			} else {
				packageList = (
					<div>
						<p>Sorry, no tour package matches your preference. Please change your search.</p>
					</div>
				)
			}
		}

		return (
			<div className={classes.Wrapper}>
				<div className={classes.Container}>
					<div className={classes.Heading}>
						<div className={classes.Title}>Holiday Packages</div>
						<div className={classes.More} onClick={() => this.seeMoreHandler()}>More</div>
					</div>
					<div className={classes.List} >
						<Slider {...settings}>
							{packageList}
						</Slider>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(HolidayPackage);