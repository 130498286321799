import React, { Component } from 'react';
import classes from './ProductList.module.scss';
import { PRODUCT_LIST } from '../../../../constant/General';
import { withRouter } from 'react-router-dom';
// import Slider from 'react-slick';
// import Icon from '../../../UI/Icon/Icon';

import { connect } from 'react-redux';

// function SamplePrevArrow(props) {
// 	const { onClick } = props;
// 	return (
// 		<div
// 			className={[classes.Arrow, classes.PrevArrow].join(' ')}
// 			onClick={onClick}>
// 			<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48} />
// 		</div>
// 	);
// }

// function SampleNextArrow(props) {
// 	const { onClick } = props;
// 	return (
// 		<div
// 			className={[classes.Arrow, classes.NextArrow].join(' ')}
// 			onClick={onClick}>
// 			<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48} />
// 		</div>
// 	);
// }

class ProductList extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	productClickHandler = (slug) => {
		this.props.history.push('/' + slug);
	}

	render() {
		// const settings = {
		// 	dots: false,
		// 	arrows: false,
		// 	infinite: false,
		// 	speed: 500,
		// 	slidesToShow: 1,
		// 	slidesToScroll: 1,
		// 	variableWidth: true,
		// 	autoplay: false,
		// 	autoplaySpeed: 2500,
		// 	dotsClass: "slick-dots " + classes.DotWrapper,
		// 	nextArrow: <SampleNextArrow />,
		// 	prevArrow: <SamplePrevArrow />,
		// 	touchMove: true,
		// 	draggable: false,
		// 	customPaging: function (i) {
		// 		return (
		// 			<div className="banner-product"></div>
		// 		);
		// 	},
		// };

		let productList = [];
		if (PRODUCT_LIST) {
			productList = PRODUCT_LIST.filter(product => {
				return product.isVisible;
			}).map((product, index) => {
				let badges = null;
				if (product.isNew) {
					badges = <div className={classes.Badges}>New!</div>;
				}

				// just for virtual-tour case (response single data)
				if (product.slug === 'virtual-tour') {
					if (this.props.virtualTours) {
						return (
							<div key={index}>
								<div className={classes.ProductItem} onClick={() => this.productClickHandler(`virtual-tour/detail/${this.props.virtualTours.slug}`)}>
									{badges}
									<div className={classes.Image}>
										<img src={product.img} alt="icon" />
									</div>
								</div>
								<div className={classes.Label}>{product.name}</div>
							</div>
						);
					} else {
						return null;
					}
				}

				return (
					<div key={index}>
						<div className={classes.ProductItem} onClick={() => this.productClickHandler(product.mobileUrl)}>
							{badges}
							<div className={classes.Image}>
								<img src={product.img} alt="icon" />
							</div>
						</div>
						<div className={classes.Label}>{product.name}</div>
					</div>
				);
			});
		}

		return (
			<div className={classes.Wrapper}>
				{/* <Slider {...settings}> */}
				{productList}
				{/* </Slider> */}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
    return {
        virtualTours: state.VirtualTourAvailability.virtualTours
    }
}

export default connect(mapStateToProps, null)(withRouter(ProductList));