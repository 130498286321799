import { updateObject } from '../../utility';
import * as validation from '../../../helper/Validation';

const initialState = {
    form: {
        email: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
                isEmail: true
            }
        }
    },
    isSuccessSubmit: false,
    isSubmitLoading: false,
    isValidForm: false,
}

const checkingForm = state => {
    let form = {...state.form};
    for (const identifier in form) {
        let validate = validation.validate(form[identifier].rules, form[identifier].value);
        form[identifier] = {...form[identifier], ...validate};
    }

    return updateObject(state, { form });
}

const inputChanged = (state, action) => {
    let form = {...state.form};
    let isValidForm = {...state.isValidForm};
    let identifier = action.payload.identifier;
    let value = action.payload.value;

    let validate = validation.validate(form[identifier].rules, value);
    form[identifier] = {...form[identifier], ...validate};
    
    isValidForm = validation.isValidForm(form);
    return updateObject(state, { form, isValidForm })
}

const setIsSubmitLoading = (state, action) => {
    return updateObject(state, {isSubmitLoading: action.payload.value})
}

const setIsSuccessSubmit = (state, action) => {
    return updateObject(state, {isSuccessSubmit: action.payload.value})
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case 'FORGOT_PASSWORD_INPUT_CHANGED': return inputChanged(state, action);
        case 'FORGOT_PASSWORD_CHECKING_FORM': return checkingForm(state, action);
        case 'FORGOT_PASSWORD_SET_IS_SUBMIT_LOADING': return setIsSubmitLoading(state, action);
        case 'FORGOT_PASSWORD_SET_IS_SUCCESS_SUBMIT': return setIsSuccessSubmit(state, action);
        default: return state;
    }
}

export default reducer;