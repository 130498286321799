import React from 'react';
import classes from './RetrieveBooking.module.scss';
import BookingDetail from './BookingDetail/BookingDetail';
import TourDetail from './TourDetail/TourDetail';
import Participant from './Participant/Participant';
import PriceDetail from './PriceDetail/PriceDetail';

function retrieveBooking(props) {
    if (props.isLoading) {
        return <div>Loading dlu bos</div>
    }
    return(
        <div>
            <div className="u-mb-24">
                <BookingDetail 
                    bookingData={props.bookingData}/>
            </div>
            <div className="u-mb-24">
                <div className={classes.Title}>Tour Detail</div>
                <TourDetail 
                    bookingData={props.bookingData}/>
            </div>
            <div className="u-mb-24">
                <div className={classes.Title}>Participants</div>
                <Participant 
                    bookingData={props.bookingData}/>
            </div>
            <div className="u-mb-24">
                <div className={classes.Title}>Price Detail</div>
                <PriceDetail 
                    isLoading={props.isLoading}
                    bookingData={props.bookingData}/>
            </div>
        </div>
    );
}

export default retrieveBooking;