export const PRICE_FILTER_LIST = [{
    value: '0:500000',
    name : '< Rp 500.000'
}, {
    value: '500000:999999',
    name : 'Rp 500.000 - Rp 999.999'
}, {
    value: '1000000:1999999',
    name : 'Rp 1.000.000 - 1.999.999'
}, {
    value: '2000000:5000000',
    name : 'Rp 2.000.000 - Rp 5.000.000'
}, {
    value: '5000000:~',
    name : '> Rp 5.000.000'
}];

export const TOUR_DURATION_FILTER_LIST = [{
    value: '0:5',
    name : '0 to 5 days'
},{
    value: '5:12',
    name : '5 to 12 days'
},{
    value: '12:21',
    name : '12 to 21 days'
}];

export const TOUR_PRICE_FILTER_LIST = [{
    value: '1000000:10999999',
    name : 'Rp 1.000.000 - Rp 10.999.999'
},{
    value: '11000000:20999999',
    name : 'Rp 11.000.000 - Rp 20.999.999'
},{
    value: '21000000:30999999',
    name : 'Rp 21.000.000 - Rp 30.999.999'
},{
    value: '31000000:40999999',
    name : 'Rp 31.000.000 - Rp 40.999.999'
},{
    value: '41000000:50999999',
    name : 'Rp 41.000.000 - Rp 50.999.999'
}];

export const TOUR_CATEGORY_FILTER_LIST = [{
    value: 'Open Trip',
    name : 'Open Trip'
}, {
    value: 'Super Sale',
    name : 'Super Sale'
}, {
    value: 'Viesta',
    name : 'Viesta'
}, {
    value: 'Experience',
    name : 'Experience'
}, {
    value: 'Premium',
    name : 'Premium'
}];