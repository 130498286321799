import React from 'react';
import classes from './RightContent.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../UI/Icon/Icon';
import Button from '../../../../UI/Button/Button';
import { formatDisplayDate, formatDisplayTime } from '../../../../../helper/GlobalFunc';
import CurrencyFormat from 'react-currency-format';

const rightContent = (props) => {
    return (
        <div className={classes.Wrapper}>
            <PanelBox>
                <div className={classes.Item}>
                    <div className={classes.Label}>
                        <Icon name="ico-calendar" width={20} stroke="none" fill="#CD2033" />
                        <label>Date and Time</label>
                    </div>
                    <div className={classes.Content}>
                        {formatDisplayDate(props.virtualTourDetail.liveDate)} {'  ' + formatDisplayTime(props.virtualTourDetail.liveDate)}
                    </div>
                </div>
                <div className={classes.Item}>
                    <div className={classes.Label}>
                        <Icon name="ico-time" width={20} stroke="none" fill="#CD2033" />
                        <label>Duration</label>
                    </div>
                    <div className={classes.Content}>
                        {props.virtualTourDetail.duration + ' minutes'}
                    </div>
                </div>
                <div className={classes.Item}>
                    <div className={classes.Price}>
                        <label>Price</label>
                        <div className={classes.PriceNumber}>
                            <CurrencyFormat value={props.virtualTourDetail.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                        </div>
                    </div>
                    <div className={classes.ItemBtn}>
                        <Button
                            classes={['BtnSmall', 'BtnBlock', 'BtnRed']}
                            clicked={() => props.goToBooking(props.virtualTourDetail.slug)}>
                            Book Now
                        </Button>
                    </div>
                </div>
            </PanelBox>
        </div>
    )
}

export default rightContent;
