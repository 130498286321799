import React from 'react';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import classes from './ItinaryLoader.module.scss';
import ContentLoader from 'react-content-loader';

 const itinaryLoader = () => {
    return (
        <div className="u-mb-16">
           <PanelBox>
                <div className={classes.Wrapper}>
                    <ContentLoader
                            height={87}
                            speed={1}
                            primaryColor={'#e6e6e6'}
                        >
                            <rect x="0" y="0" rx="4" ry="4" width="70" height="13" />
                            <rect x="0" y="25" rx="3" ry="3" width="150" height="10" />
                            <rect x="0" y="45" rx="3" ry="3" width="180" height="10" />
                            <rect x="0" y="65" rx="3" ry="3" width="175" height="10" />
                    </ContentLoader>
                </div>
            </PanelBox> 
        </div>
    )
}

export default itinaryLoader;
