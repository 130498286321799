import React from 'react';
import classes from './RegisterForm.module.scss';
import Button from '../../../../UI/Button/Button';
import TextField from '../../../../UI/Inputs/TextField/TextField';
// import icoFb from '../../../../../assets/images/ico-fb.png';
// import icoGoogle from '../../../../../assets/images/ico-google.png';
import SelectFlag from '../../../../UI/Inputs/SelectFlag/SelectFlag';
import Select from '../../../../UI/Inputs/Select/Select';

import { connect } from 'react-redux';
import * as signupAction from '../../../../../redux/Shared/SignUp/SignupAction';

const registerForm = props => {
    const signupDataSubmit = val => {
        props.signUpHandler(val);
    }

    return (
        <div className={classes.FormWrapper}>
        <div className={classes.Header}>
            <div className={classes.Title}>Register</div>
            <div className={classes.LabelHeader}>Already heve an account? <span className={classes.Link} onClick={() => props.switchFormClicked('login')}>Login</span></div>
        </div>
            <div className={classes.Input}>
                <label className={classes.Label}>Title</label>
                <Select
                    value={props.signupData.title.value}
                    options={props.signupData.title.options}
                    changed={(event) => props.titleChangedHandler(event)}
                    />
            </div>
            <div className={classes.Input}>
                <div className={classes.Inline}>
                    <div className={classes.Row}>
                        <label className={classes.Label}>First Name</label>
                        <TextField
                            placeholder="e.g. Julie" 
                            value={props.signupData.firstname.value}
                            changed={(event) => props.inputChangeHandler(event, 'firstname')}
                            hasError={props.signupData.firstname.hasError && props.signupData.firstname.errorMsg !== ''}
                            errorMsg={props.signupData.firstname.errorMsg}/>
                    </div>
                    <div className={classes.Row}>
                        <label className={classes.Label}>Last Name</label>
                        <TextField
                            placeholder="e.g. Applesed" 
                            value={props.signupData.lastname.value}
                            changed={(event) => props.inputChangeHandler(event, 'lastname')}
                            hasError={props.signupData.lastname.hasError && props.signupData.lastname.errorMsg !== ''}
                            errorMsg={props.signupData.lastname.errorMsg}/>
                    </div>
                </div>
            </div>
            <div className={[classes.Input, classes.PhoneWrapper].join(' ')}>
                <label className={classes.Label}>Phone Number</label>
                <div className={classes.Inline}>
                    <div className={classes.DialCode}>
                        <SelectFlag 
                            value={props.signupData.dialCode.value}
                            changed={(val) => props.setDialCodeHandler(val)}
                            />
                    </div>
                    <div className={classes.Phone}>
                        <TextField
                            type="text"
                            placeholder="e.g. 8123456789"
                            value={props.signupData.phone.value}
                            changed={(event) => props.inputChangeHandler(event, 'phone')}/>
                    </div>
                </div>
            </div>
            <div className={[classes.Input, classes.LargeWidth].join(' ')}>
                <label className={classes.Label}>Email Address</label>
                <TextField
                    placeholder="e.g. youremail@example.com" 
                    value={props.signupData.email.value}
                    changed={(event) => props.inputChangeHandler(event, 'email')}
                    hasError={props.signupData.email.hasError && props.signupData.email.errorMsg !== ''}
                    errorMsg={props.signupData.email.errorMsg}/>
            </div>
            <div className={[classes.Input, classes.LargeWidth].join(' ')}>
                <label className={classes.Label}>Password</label>
                <TextField
                    type="password"
                    placeholder="minumum 6 characters"
                    value={props.signupData.password.value}
                    changed={(event) => props.inputChangeHandler(event, 'password')}/>
            </div>
            
            <div className={classes.Action}>
                <Button
                    classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
                    disabled={!props.isValidForm}
                    clicked={() => signupDataSubmit(props.signupData)}>
                    Submit
                </Button>
            </div>
            {/* <div className={classes.Sparator}>
                <span className={classes.Text}>Or Register With</span>
            </div>
            <div className={classes.SocmedLogin}>
                <div className={[classes.Socmed, classes.FbBtn].join(' ')}><img className={classes.Icon} src={icoFb} alt="facebook" /> Facebook</div>
                <div className={[classes.Socmed, classes.GoogleBtn].join(' ')}><img className={classes.Icon} src={icoGoogle} alt="google" /> Google</div>
            </div> */}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        state: state.SignupReducer,
        signupData: state.SignupReducer.signupData,
        isValidForm: state.SignupReducer.isValidForm
    }
}

const mapDispatchToProps = dispatch => {
    return {
        inputChangeHandler: (event, inputIdentifier) => dispatch(signupAction.inputChangeHandler(event, inputIdentifier)),
        setDialCodeHandler: (val) => dispatch(signupAction.setDialCodeHandler(val)),
        titleChangedHandler: (val) => dispatch(signupAction.titleChangedHandler(val)),
        signUpHandler: (signupData) => dispatch(signupAction.signUpHandler(signupData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(registerForm);