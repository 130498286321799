import React from 'react';
import classes from './Availability.module.scss';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import { formatDisplayDate, currencyFormat, textLimit } from '../../../../components/../helper/GlobalFunc';
import PackagesListLoaderMobile from '../../../UI/Loaders/Mobile/List/PackagesListLoaderMobile';
import ImgThumb from '../../../UI/ImgThumb/ImgThumb';
import Icon from '../../../UI/Icon/Icon';
import Ribbon from '../../../UI/Ribbon/Ribbon';

const availability = props => {
    let packageList = [];

    if (props.isFetchHotel) {
        for (let index = 0; index < 5; index++) {
            packageList.push(<PackagesListLoaderMobile key={index} />)
        }
    } else {
        packageList = props.data.map((item, key) => {
            return (
                <div
                    key={key}
                    className="u-mb-20"
                    onClick={() => props.clicked(item.slug)} >
                    <PanelBox isOverflow={false}>
                        <div className={classes.Item}>
                            <div className={classes.RibbonWrapper}>
                                <Ribbon text="free voucher tour" position="right" />
                            </div>
                            <div className={classes.Title}>{textLimit(item.name, 20)}</div>
                            <div className={classes.Info}>
                                <div className={classes.Image}>
                                    <ImgThumb size="large" type="square" src={item.imageSource} />
                                </div>

                                <div className={classes.Description}>
                                    <div className={classes.DateWrap}>
                                        <div className={classes.Label}>Booking Period</div>
                                        <div className={classes.Date}>
                                            <div className={classes.Icon}>
                                                <Icon name="ico-calendar" stroke="none" fill="#818589" width={22} />
                                            </div>
                                            {formatDisplayDate(item.bookingPeriodStartDate)} - {formatDisplayDate(item.bookingPeriodEndDate)}
                                        </div>
                                    </div>
                                    <div className={classes.DateWrap}>
                                        <div className={classes.Label}>Stay Period</div>
                                        <div className={classes.Date}>
                                            <div className={classes.Icon}>
                                                <Icon name="ico-calendar" stroke="none" fill="#818589" width={22} />
                                            </div>
                                            {formatDisplayDate(item.stayPeriodStartDate)} - {formatDisplayDate(item.stayPeriodEndDate)}
                                        </div>
                                    </div>
                                    <div className={classes.PriceWrap}>
                                        <div className={classes.Label}>Start From</div>
                                        <div className={classes.Price}>
                                            {currencyFormat(item.hotelPrice)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </PanelBox>
                </div>
            );
        });
    }

    return (
        <div className={classes.Wrapper}>
            {packageList}
        </div>
    );
}

export default availability;