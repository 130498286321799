import React from 'react';
import classes from './LeftContent.module.scss';
import Breadcrumb from '../../../../Desktop/Shared/Breadcrumb/Breadcrumb';
import ListPanel from './ListPanel/ListPanel';
import ExcludeInclude from './ExcludeInclude/ExcludeInclude';
import Itinerary from './Itinerary/Itinerary';
import Photo from './Photo/Photo';
import FreeVoucher from '../../../Shared/FreeVoucher/FreeVoucher';
import BreadCrumbLoader from '../../../../UI/Loaders/Desktop/DetailPackages/Breadcrumb/BreadCrumbLoader';
import HighlightLoader from '../../../../UI/Loaders/Desktop/DetailPackages/Highlight/HighlightLoader';
import ItinaryLoader from '../../../../UI/Loaders/Desktop/DetailPackages/Itinary/ItinaryLoader';

const leftContent = (props) => {
    let breadcrumb = <BreadCrumbLoader/>;
    if (props.tourPackage) {
        breadcrumb = <Breadcrumb linkList={props.breadcrumbLink}/>;
    }

    let highlight = null;
    let itenerary = null;
    let photo = null;
    let excludeInclude = null;
    let toc = null;
    let cancellationPolicy = null;

    if (props.tourPackage) {
        const highlightList = props.tourPackage.sellingPointTags.map(item => item);
        highlight = (
            <div className="u-mb-16">
                <ListPanel 
                    listColor="green" 
                    title="Highlight"
                    list={highlightList} />
            </div>
        );

        itenerary = (
            <div className="u-mb-16">
                <Itinerary 
                    itineraryList={props.tourPackage.itineraries} 
                    downloadClicked={props.tourPackage.pdf}/>
            </div>
        );

        photo = (
            <div className="u-mb-16" ref={props.setElement}>
                <Photo imageList={props.tourPackage.images}/>
            </div>
        );

        excludeInclude = (
            <div className="u-mb-16">
                <ExcludeInclude 
                    tourIncludeList={props.tourPackage.include}
                    tourExcludeList={props.tourPackage.exclude} />
            </div>
        );
        const tocList = props.tourPackage.toc;
        toc = (
            <div className="u-mb-16">
                <ListPanel 
                    title="Terms & conditions"
                    list={tocList}
                    isCollapseBtn={true}
                    showMore={true} />
            </div>
        );

        const cancellationPolicyList = props.tourPackage.cancelTerm;
        cancellationPolicy = (<ListPanel title="Cancellation Policy" list={cancellationPolicyList} />);
    } else {
        highlight = <HighlightLoader/>
        itenerary = <ItinaryLoader/>
    }

    return (
        <div className={classes.Wrapper}>
            {breadcrumb}
            <div className="u-mb-16">
                <FreeVoucher />
            </div>
            {highlight}
            {itenerary}
            {photo}
            {excludeInclude}
            {toc}
            {cancellationPolicy}
        </div>
    );
}

export default leftContent;