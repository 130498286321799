import React from 'react'
import classes from './Intro.module.scss'
import { TRAVEL_LEADERS_GROUP } from '../../../../../constant/Content'

const intro = () => {
	const content = TRAVEL_LEADERS_GROUP.map((text, index) => {
		return <p key={index} dangerouslySetInnerHTML={{ __html: text }} />
	})
	return (
		<div className={classes.Wrapper}>
			<div className={classes.Container}>
				<div className={classes.Title}>Overview</div>
				<div className={classes.Description}>{content}</div>
			</div>
		</div>
	)
}

export default intro
