import React, { useState, useEffect } from 'react';
import classes from './BannerAvailability.module.scss';
import TourInput from '../../Shared/SearchForm/TourInput/TourInput';
import { IMAGE_URl } from '../../../../constant/ResourceUrl';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Backdrop from '../../../UI/Backdrop/Backdrop';
// import scrollToComponent from 'react-scroll-to-component';

import { connect } from 'react-redux';
import * as availabilityAction from '../../../../redux/Tour/Availability/AvailabilityAction';

function bannerAvailability(props) {
    const [showBackdrop, setShowBackdrop] = useState(false);
    // eslint-disable-next-line
    let searchForm = React.createRef();

    useEffect(() => {
        const params = queryString.parse(props.location.search);
        let region = 'all-region';
        if (params.destination !== undefined) {
            region = params.destination;
        } else {
            props.history.push('/tour/availability?destination=all-region');
        }

        props.onInitTourPackages(region);
    }, [])

    const searchTourClickedHandler = regionSelected => {
        props.history.push('/tour/availability?destination=' + regionSelected);
        props.onInitTourPackages(regionSelected);
    }

    let zIndex = {
        zIndex:  100
    };
    if (showBackdrop) {
        zIndex = {
            zIndex:  660
        };
    }

    const params = queryString.parse(props.location.search);

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Background}>
                <img src={IMAGE_URl + 'tour-banner.jpg'} alt="promo" />
            </div>
            <Backdrop 
                showBackdrop={showBackdrop}
                backdropClicked={() => setShowBackdrop(false)} />
            <div className={classes.Container} style={zIndex}>
                <div className={classes.SearchForm}>
                    <div className={classes.Title}>Tour Packages</div>
                    <div ref={(section) => { searchForm = section; }}>
                        <TourInput 
                            type="small"
                            hideBackdropClicked={() => setShowBackdrop(false)}
                            showBackdrop={() => setShowBackdrop(true)}
                            regionSelected={params.destination}
                            searchTourClicked={regionSelected => searchTourClickedHandler(regionSelected)}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        isLoading : state.TourAvailabilityReducer.isLoadingTourPackages,
        tourPackages : state.TourAvailabilityReducer.tourPackages
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitTourPackages : (regionSelected) => dispatch(availabilityAction.initTourPackages(regionSelected))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(bannerAvailability));