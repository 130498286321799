import React, { useEffect } from 'react';
import classes from './Popup.module.scss';
import Icon from '../Icon/Icon';
import CSSTransition from 'react-transition-group/CSSTransition';
import Backdrop from '../../UI/Backdrop/Backdrop';
import { COLOR } from '../../../constant/Library';

const Popup = props => {
    useEffect(() => {
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [props.show]);
    return (
        <div>
            <Backdrop showBackdrop={props.show} backdropClicked={props.hideClicked}/>
            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={200}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Closed 
                }}>
                <div className={classes.Wrapper}>
                    <div className={classes.Header}>
                        <div className={classes.Close} onClick={props.hideClicked}>
                            <Icon name="ico-close" fill={COLOR.black} stroke="none"/>
                        </div>
                        <div className={classes.Title}>{props.title}</div>
                    </div>
                    <div className={classes.Content}>
                        {props.content}
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default Popup;
