import React from 'react';
import classes from './Header.module.scss';
import Icon from '../../../UI/Icon/Icon';
import Button from '../../../../components/UI/Button/Button';
import { withRouter } from 'react-router-dom';

// classes : BgWhite, BgRed, BgTransparent, Shadow (Array)
// leftIcon : menu, back, close, search (String)
// content : (String)
// align : left, center, right (String)
// rightIcon : menu, back, close, search (String)
// icoColor : white, black (String)

const header = (props) => {

    let icoColor = "#202124";
    if (props.icoColor === 'white') {
        icoColor = "#ffffff";
    }
    const icons = {
        menu : <Icon name="ico-menu2" fill={icoColor} stroke="none" width={24}/>,
        back : <Icon name="ico-arrow-left" fill={icoColor} stroke="none" width={24}/>,
        close : <Icon name="ico-close" fill={icoColor} stroke="none" width={24}/>,
        search : <Icon name="ico-search" fill={icoColor} stroke="none" width={24}/>,
    };

    let wrapperClasses = [classes.Wrapper];
    if (props.classes) {
        props.classes.forEach(val => {
            wrapperClasses.push(classes[val]);
        });
    }
    const aligns = {
        left : classes.TxLeft,
        center : classes.TxCenter,
        right : classes.TxRight,
    };
    let contentClasses = [classes.Content];
    if (props.align) {
        contentClasses.push(aligns[props.align]);
    }
    let leftIcon = null;
    if (props.leftIcon) {
        leftIcon = <div className={classes.Left} onClick={props.leftClicked}>
            {icons[props.leftIcon]}
        </div>
    }
    let rightIcon = null;
    if (props.rightIcon) {
        rightIcon = <div className={classes.Right} onClick={props.rightClicked}>
            {icons[props.rightIcon]}
        </div>
    }
    let downloadBtn = null;
    if (props.downloadBtn) {
        downloadBtn=<div className={classes.DownloadBtn} onClick={props.downloadBtn}>
            <Button classes={['BtnXSmall', 'BtnRed']}>
                Download E-Ticket
            </Button>
        </div>
    }
    return (
        <div className={wrapperClasses.join(' ')}>
            {leftIcon}
            <div className={contentClasses.join(' ')}>
                {props.content}
            </div>
            {rightIcon}
            {downloadBtn}
        </div>
    );
}

export default withRouter(header);