import { updateObject } from '../../utility';
import { DEFAULT_TITLE, ADULT_TITLE, DEFAULT_DIAL_CODE } from '../../../constant/General';
import * as validation from '../../../helper/Validation';

const formData = {
    title: {
        value: DEFAULT_TITLE,
        options: ADULT_TITLE,
        hasError: false,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    firstName: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    lastName: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    email: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true,
            isEmail: true
        }
    },
    dialCode: {
        value: DEFAULT_DIAL_CODE
    },
    phone: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true,
            isNumericPhone: true,
            minLength: 7,
        }
    },
    // zoomId: {
    //     value: '',
    //     hasError: true,
    //     errorMsg: '',
    //     rules : {
    //         required: true
    //     }
    // },
}

const initialState = {
    isSuccess: false,
    isLoadingSubmitForm: false,
    isVerified: false,
    isValidForm: false,
    formData: formData,
    isSuccessSubmit: false,
    isFetchVirtualTourDetail: false,
    isErrorFetchVirtualTourDetail: false,
    virtualTourDetail: null,
}

const setValidForm = (inputForm) => {
    let isValidForm = true;
    if (inputForm) {
        for (const inputIdentifier in inputForm) {
            if (inputForm[inputIdentifier].value !== undefined && inputForm[inputIdentifier].rules !== undefined ) {
                if (inputForm[inputIdentifier].hasError) {
                    isValidForm = false;
                    break;
                }
            }
        }
    }
    return isValidForm;
}

const inputChangeHandler = (state, action) => {
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let formData = {...state.formData};
    let isValidForm = {...state.isValidForm};

    let validate = validation.validate(formData[identifier].rules, value);
    formData[identifier] = {...formData[identifier], ...validate};

    isValidForm = setValidForm(formData);
    return updateObject(state, {formData, isValidForm});
}

// const dialCodeChangeHandler = (state, action) => {
//     let formData = {...state.formData};
//     formData.dialCode.value = action.value;
//     return updateObject(state, {formData});
// }

const formCheckingHandler = (state) => {
    let formData = {...state.formData};
    for (const identifier in formData) {
        let validate = validation.validate(formData[identifier].rules, formData[identifier].value);
        formData[identifier] = {...formData[identifier], ...validate};
    }
    return updateObject(state, {formData});
}

// const verifyClickHandler = state => {
//     return updateObject(state, {isVerified: true});
// }

const submitSuccessHandler = state => {
    return updateObject(state, {isSuccess: true, isLoadingSubmitForm: false});
}

const loadingSubmitFormHandler = state => {
    return updateObject(state, {isLoadingSubmitForm: true});
}

const setVirtualTourDetail = (state, action) => {
    return updateObject(state, { virtualTourDetail: action.payload.data })
}

const setIsFetchVirtualTourDetail = (state, action) => {
    return updateObject(state, { isFetchVirtualTourDetail: action.payload.value })
}

const setIsSuccessSubmit = (state, action) => {
    return updateObject(state, { isSuccessSubmit: action.payload.value })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'INPUT_CHANGED_VIRTUAL_TOUR': return inputChangeHandler(state, action);
        case 'FORM_CHECKING_VIRTUAL_TOUR': return formCheckingHandler(state);
        case 'SUBMIT_SUCCESS_VIRTUAL_TOUR': return submitSuccessHandler(state);
        case 'LOADING_SUBMIT_FORM_VIRTUAL_TOUR': return loadingSubmitFormHandler(state);
        case 'SET_VIRTUAL_TOUR_DETAIL': return setVirtualTourDetail(state, action);
        case 'SET_IS_FETCH_VIRTUAL_TOUR': return setIsFetchVirtualTourDetail(state, action);
        case 'SET_IS_SUCCESS_SUBMIT': return setIsSuccessSubmit(state, action);
        default: return state;
    }
};

export default reducer;