import React from 'react'
import classes from './CorporateOnlineSolution.module.scss'
import Icon from '../../../../UI/Icon/Icon'
import Button from '../../../../UI/Button/Button'
import { CORPORATE_ONLINE_SOLUTIONS } from '../../../../../constant/Content'
import LOGO from '../../../../../assets/images/COS_warna.png'

const CorporateOnlineSolution = () => {
	return (
		<div id='solution' className={classes.Wrapper}>
			<div className={classes.Container}>
				<div className={classes.Title}>Corporate Online Solution</div>
				<div className={classes.List}>
					<table>
						<tr className={classes.Head}>
							<th className={classes.Logo}>
								<img width={144} height={46.44} alt='COS logo' src={LOGO} />
							</th>
							<th className={classes.Col1}>Corporate Booking Tools (ABT/SBT)</th>
							<th className={classes.Col2}>Travel Management System (TMS)</th>
							<th className={classes.Col3}>
								System Integration<br />(API)
							</th>
						</tr>
						<tr className={classes.Item}>
							<td>Recomendation</td>
							<td className={classes.Center}>
								<div className={classes.StarContainer}>
									<Icon name='ico-star' fill='#F99E00' stroke='none' />
									<Icon name='ico-star' fill='#F99E00' stroke='none' />
								</div>
							</td>
							<td className={classes.Center}>
								<div className={classes.StarContainer}>
									<Icon name='ico-star' fill='#F99E00' stroke='none' />
									<Icon name='ico-star' fill='#F99E00' stroke='none' />
									<Icon name='ico-star' fill='#F99E00' stroke='none' />
								</div>
							</td>
							<td className={classes.Center}>
								<div className={classes.StarContainer}>
									<Icon name='ico-star' fill='#F99E00' stroke='none' />
								</div>
							</td>
						</tr>
						{CORPORATE_ONLINE_SOLUTIONS.map((item, index) => {
							return (
								<React.Fragment key={index}>
									<tr className={classes.Item}>
										<td className={classes.WhatInclude}>{item.spacerTitle}</td>
									</tr>
									{item.subItem.map((child, index) => {
										return (
											<tr key={index} className={classes.Item}>
												<td className={classes.Item1}>{child.item1}</td>
												<td className={classes.Center}>
													{child.item2.includes('ico') ? (
														<IconSubItem iconName={child.item2} />
													) : (
														child.item2
													)}
												</td>
												<td className={classes.Center}>
													{child.item3.includes('ico') ? (
														<IconSubItem iconName={child.item3} />
													) : (
														child.item3
													)}
												</td>
												<td className={classes.Center}>
													{child.item4.includes('ico') ? (
														<IconSubItem iconName={child.item3} />
													) : (
														child.item4
													)}
												</td>
											</tr>
										)
									})}
								</React.Fragment>
							)
						})}
						<tr className={classes.ButtonItem}>
							<td />
							<td>
								<a
									href={`mailto:sales.corporate@antavaya.com&subject=Ask about Corporate Booking Tools (ABT/SBT)`}>
									<Button classes={[ 'BtnSmall', 'BtnBlock', 'BtnRed' ]} clicked={() => {}}>
										Contact Us
									</Button>
								</a>
							</td>
							<td>
								<a
									href={`mailto:sales.corporate@antavaya.com&subject=Ask about Travel Management System (TMS)`}>
									<Button classes={[ 'BtnSmall', 'BtnBlock', 'BtnRed' ]} clicked={() => {}}>
										Contact Us
									</Button>
								</a>
							</td>
							<td>
								<a
									href={`mailto:sales.corporate@antavaya.com&subject=Ask about System Integration (API)`}>
									<Button classes={[ 'BtnSmall', 'BtnBlock', 'BtnRed' ]} clicked={() => {}}>
										Contact Us
									</Button>
								</a>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	)
}

export default CorporateOnlineSolution

const IconSubItem = ({ iconName }) => (
	<div
		style={{
			display: 'flex',
			width: '100%',
			justifyContent: 'center',
			alignItems: 'center'
		}}>
		<Icon name={iconName} fill='#CD2033' stroke='none' width={24} />
	</div>
)
