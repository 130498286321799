import React from 'react';
import classes from './DetailPackageLoader.module.scss';

import ImageSliderLoader from '../../../../UI/Loaders/Mobile/DetailPackage/ImageSlider/ImageSliderLoader';
import DestinationLoader from '../../../../UI/Loaders/Mobile/DetailPackage/Destination/DestinationLoader';
import HighlightLoader from '../../../../UI/Loaders/Mobile/DetailPackage/Highlight/HighlightLoader';

const detailPackageLoader = () => {
    return (
        <div className={classes.Wrapper}>
            <ImageSliderLoader/>
            <DestinationLoader/>
            <HighlightLoader/>
            <HighlightLoader/>
            <HighlightLoader/>
        </div>
    )
}

export default detailPackageLoader;