import React from 'react';
import classes from './Participant.module.scss';
import Icon from '../../../../../UI/Icon/Icon';
import Room from './Room/Room';

import { connect } from 'react-redux';
import * as detailAction from '../../../../../../redux/Tour/Detail/DetailAction';

function participant(props) {

    const participantChangeHandler = (guestType, index, action, room) => {
        let adult = room.adult.length;
        let child = room.child.length;
        let isExtrabed = room.isExtrabed;
        if (guestType === 'adult') {
            if (action === 'ADD') {
                adult++;
            } else if (action === 'REMOVE') {
                adult--;
            }
        } else {
            if (action === 'ADD') {
                child++;
            } else if (action === 'REMOVE'){
                child--;
            }
        }
        if (action === 'IS_EXTRABED') {
            isExtrabed = !isExtrabed;
        }
        let data = {
            adult : adult,
            child : child,
            isExtraBed : isExtrabed,
            region : props.region
        }
        props.participantChanged(guestType, index, action, data)
    }
    
    let rooms = null;
    if (props.rooms) {
        let isCloseBtn = false;
        if (props.rooms.length > 1) {
            isCloseBtn = true;   
        }
        rooms = props.rooms.map((room, index) => {
            return (
                <li key={index} className={classes.Item}>
                    <Room
                        removeRoomClicked={() => props.onRoomRemoved(index)}
                        removeGuestClicked={(guestType) => participantChangeHandler(guestType, index, 'REMOVE', room)}
                        addGuestClicked={(guestType) => participantChangeHandler(guestType, index, 'ADD', room)}
                        index={index} 
                        roomData={room}
                        isCloseBtn={isCloseBtn}
                        toggleExtraBed={(event) => participantChangeHandler(event.target.checked, index, 'IS_EXTRABED', room)}/>
                </li>
            );
        });
    }
        
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Header}>
                <Icon name="ico-user-o" fill="#CD2033" stroke="none" />
                <div className={classes.Title}>Participants</div>
            </div>
            <div className={classes.Body}>
                <ul className={classes.List}>
                    {rooms}
                </ul>
                <div className={classes.Action} onClick={props.onRoomAdded}>
                    <button className={classes.AddAct}>+ Add Room</button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        rooms : state.TourDetailReducer.rooms
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRoomAdded: () => dispatch(detailAction.addRoom()),
        onRoomRemoved: (index) => dispatch(detailAction.removeRoom(index)),
        onGuestAdded: (guestType, index) => dispatch(detailAction.addGuest(guestType, index)),
        onGuestRemoved: (guestType, index) => dispatch(detailAction.removeGuest(guestType, index)),
        onExtraBedChanged: (event, index) => dispatch(detailAction.toggleExtraBed(event, index)),
        participantChanged: (guestType, index, action, data) => dispatch(detailAction.participantChanged(guestType, index, action, data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(participant);
