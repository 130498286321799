import React, { useEffect } from 'react';
import classes from './Modal.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import Backdrop from '../Backdrop/Backdrop';
import Icon from '../Icon/Icon';
import { overflowBody } from '../../../helper/GlobalFunc';

const modal = props => {
    useEffect( () => {
        overflowBody(props.show);
    }, [props.show]);
    return (
        <div>
            <div className={classes.Backdrop}>
                <Backdrop showBackdrop={props.show}/>
            </div>
            <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={160}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close
            }}>
                <div className={classes.Wrapper}>
                    <div className={classes.Alert}>
                        <div className={classes.CloseBtn} onClick={props.closeClicked}>
                            <Icon name="ico-close" fill="#202124" stroke="none" />
                        </div>
                        <div className={classes.Title}>{props.title}</div>
                        <div className={classes.Content}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default modal;