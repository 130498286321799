import React from 'react';
import classes from './VirtualTour.module.scss';
import Header from '../../../components/Desktop/Shared/Header/Header';
import MetaTag from '../../../helper/MetaTag';
import voucherBannerImg from '../../../assets/images/image-banner-vt-desktop.jpg';
import TextField from '../../../components/UI/Inputs/TextField/TextField';
import PanelBox from '../../../components/UI/PanelBox/PanelBox';
import Button from '../../../components/UI/Button/Button';
import Reaptcha from 'reaptcha';
import { CAPTCHA_KEY } from '../../../constant/VendorKey';
import SelectFlag from '../../../components/UI/Inputs/SelectFlag/SelectFlag';
import MessageBox from '../../../components/Desktop/Shared/MessageBox/MessageBox';

import { connect } from 'react-redux';
import * as VirtualTourBookingAction from '../../../redux/VirtualTour/Booking/VirtualTourBookingAction';

const virtualTour = (props) => {
    let title = 'Virtual Tour';
    // eslint-disable-next-line
    let captcha = React.createRef();

    const submitFormClickHandler = () => {
        if (props.isVerified && props.isValidForm) {
            let postData = {
                name: props.formData.name.value,
                email: props.formData.email.value,
                phone: props.formData.dialCode.value + props.formData.phone.value,
                subject: 'subject'
            }
            props.formSubmited(postData)
        } else {
            props.formChecking();
        }
    }

    const goToHome = () => {
        props.history.push('/');
    }

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    let content = null;
    if (props.isSuccess) {
        scrollTop()
        content = <MessageBox
            title="Terima Kasih"
            subTitle="Data anda telah kami terima, untuk proses pembayaran dan akses zoom akan diteruskan oleh travel consultant kami melalui WhatsApp Messanger."
            img="art-submit-application"
            labelBtn="Back to Home"
            clicked={() => goToHome()} />
    } else {
        content = (
            <React.Fragment>
                <div className={classes.Banner}>
                    <img src={voucherBannerImg} alt="virtual-tour" />
                </div>
                <div className={classes.Wrapper}>
                    <div className={classes.Summary}>
                        <div className={classes.Heading}>Virtual Tour Wo Ai Ni Taiwan</div>
                        <div className={classes.Detail}>
                            <p><b>LIVE Shoot Minggu 13 September 2020 pukul 15:00 WIB berdurasi 40-60 menit.</b></p>
                            <p>Kita akan berwisata virtual mengunjungi 101 Taipei 101 adalah pencakar langit setinggi 101 tingkat di Distrik Xinyi, Taipei, Taiwan. Nama resminya adalah Gedung Finansial Internasional Taipei, Menara ini menjadi gedung tertinggi kedua di dunia.</p>
                            <p>Lalu selanjutnya mengunjungi Yongkang Street aiut kawasan yang terkenal dengan kulinernya. Kamu akan menemukan restoran din tai fung terkenal, cafe, dan gerobak jajan taiwan yang murah meriah.  Akan banyak tips dan informasi yang akan kamu dapatkan untuk nanti kamu berkunjung ke Taiwan, tur virtual ini akan dibawakan oleh pemandu berbahasa Indonesia.</p>
                            <p>Mohon untuk mengisi data di bawah ini dengan baik & benar</p>
                        </div>
                    </div>
                    <div className={classes.Form}>
                        <PanelBox>
                            <div className={classes.Body}>
                                <div>
                                    <div className={classes.Input}>
                                        <label>Full Name</label>
                                        <TextField
                                            type="text"
                                            placeholder="e.g. Julie Applesed"
                                            value={props.formData.name.value}
                                            changed={(event) => props.inputChanged('name', event)}
                                            hasError={(props.formData.name.hasError && props.formData.name.errorMsg !== '')}
                                            errorMsg={props.formData.name.errorMsg} />
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.Input}>
                                        <div className={classes.Inline}>
                                            <div className={classes.DialCode}>
                                                <label>Dial Code</label>
                                                <SelectFlag
                                                    value={props.formData.dialCode.value}
                                                    changed={val => props.dialCodeChanged(val)} />

                                            </div>
                                            <div className={classes.Phone}>
                                                <label>Phone / Whatsapp Number</label>
                                                <TextField
                                                    type="text"
                                                    placeholder="e.g. 8123456789"
                                                    value={props.formData.phone.value}
                                                    changed={(event) => props.inputChanged('phone', event)}
                                                    hasError={(props.formData.phone.hasError && props.formData.phone.errorMsg !== '')}
                                                    errorMsg={props.formData.phone.errorMsg} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.Input}>
                                        <label>Email</label>
                                        <TextField
                                            type="text"
                                            placeholder="e.g. youremail@example.com"
                                            value={props.formData.email.value}
                                            changed={(event) => props.inputChanged('email', event)}
                                            hasError={(props.formData.email.hasError && props.formData.email.errorMsg !== '')}
                                            errorMsg={props.formData.email.errorMsg} />
                                    </div>
                                </div>
                                {/* <div>
                                    <div className={classes.Input}>
                                        <label>Zoom ID</label>
                                        <TextField
                                            type="text"
                                            placeholder="e.g. JulieApplesed"
                                            value={props.formData.zoomId.value}
                                            changed={(event) => props.inputChanged('zoomId', event)}
                                            hasError={(props.formData.zoomId.hasError && props.formData.zoomId.errorMsg !== '')}
                                            errorMsg={props.formData.zoomId.errorMsg} />
                                    </div>
                                </div> */}
                                <div className={classes.Captcha}>
                                    <Reaptcha
                                        ref={e => (captcha = e)}
                                        sitekey={CAPTCHA_KEY}
                                        render="explicit"
                                        onVerify={props.verifyClicked} />
                                </div>
                                <div className={classes.Action}>
                                    <Button
                                        isLoading={props.isLoadingSubmitForm}
                                        classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                                        clicked={submitFormClickHandler}> Submit</Button>
                                </div>

                            </div>
                            <div className={classes.Footer}>*Data yang diberikan dijaga kerahasiaan & hanya untuk keperluan promo AntaVaya.</div>
                        </PanelBox>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className="bg-white-dark min-width">
            <MetaTag title={title} />
            <Header />
            {content}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isValidForm: state.VirtualTourReducer.isValidForm,
        formData: state.VirtualTourReducer.formData,
        isVerified: state.VirtualTourReducer.isVerified,
        isLoadingSubmitForm: state.VirtualTourReducer.isLoadingSubmitForm,
        isSuccess: state.VirtualTourReducer.isSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChanged : (identifier, event) => dispatch(VirtualTourBookingAction.inputChanged(identifier, event)),
        verifyClicked : (res) => dispatch(VirtualTourBookingAction.verifyClicked(res)),
        formSubmited : (postData) => dispatch(VirtualTourBookingAction.formSubmited(postData)),
        formChecking : () => dispatch(VirtualTourBookingAction.formChecking()),
        dialCodeChanged : (value) => dispatch(VirtualTourBookingAction.dialCodeChanged(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(virtualTour);
