import React, { useState, useEffect } from 'react';
import classes from './CareerContainer.module.scss';
import Icon from '../../../../components/UI/Icon/Icon';
import BannerPage from '../../../../components/Mobile/NonProduct/BannerPage/BannerPage';
import * as careerAction from '../../../../redux/NonProducts/Career/CareerAction';
import { connect } from 'react-redux';

const careerContainer = props => {
    const [positionValue, setPositionValue] = useState('');
    const [isSecondHeader, setIsSecondHeader] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        props.onInitCareerList();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    const handleScroll = () => {
        const offsetTop = window.scrollY;
        if (offsetTop > 140) {
            setIsSecondHeader(true);
        } else {
            setIsSecondHeader(false);
        }
    }

    const backClickHandler = () => {
        props.history.push('/');
    }

    const positionChangeHandler = event => {
        setPositionValue(event.target.value);
    }

    const goToDetailPageHandler = slug => {
        props.history.push('/career/' + slug);
    }
    
    let secondHeaderClasses = [classes.Header, classes.SecondNav];
    if (isSecondHeader) {
        secondHeaderClasses.push(classes.Show);
    }

    let careerList = null;
    if (props.careerList && props.careerList.length > 0) {
        careerList = props.careerList.filter((career) => {
            return (career.name.toLowerCase().indexOf(positionValue.toLowerCase()) !== -1 || positionValue === '') ? true : false;
        }).map( (career, index) => {
            return (
                <div key={index} onClick={() => goToDetailPageHandler(career.slug)}>
                    <li className={classes.Item} >
                        <div className={classes.Icon}>
                            <Icon name="ico-hotel" width="30" stroke="none" fill="#CD2033" />
                        </div>
                        <div className={classes.Description}>
                            <div className={classes.Name}>{career.name}</div>
                            <div className={classes.SubName}>{career.department}</div>
                            <div className={classes.SubName}>{career.location}</div>
                        </div>
                    </li>
                </div>
            );
        });
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Header}>
                <span className={classes.Icon} onClick={backClickHandler}>
                    <Icon name="ico-arrow-left" width="20" stroke="none" fill="#ffffff" />
                </span>
            </div>
            
            <div className={secondHeaderClasses.join(' ')}>
                <span className={classes.Icon} onClick={backClickHandler}>
                    <Icon name="ico-arrow-left" width="20" stroke="none" fill="#202124" />
                </span>
                <div className={classes.Title}>Career</div>
            </div>

            <div className={classes.Content}>
                <BannerPage title="Find Your Dream Job in Antavaya" image="https://media01.cdnpergi.com/antavaya/images/branch-office-1.jpg" />
                <div className={classes.TextContent}>
                    <div className={classes.SearchForm}>
                        <div className={classes.FirstIcon}>
                            <Icon name="ico-search" width="20" stroke="none" fill="#202124" />
                        </div>
                        <input 
                            value={props.value} 
                            onChange={(event) => positionChangeHandler(event)} 
                            className={classes.Input} 
                            placeholder="Search for a job title"/>
                    </div>
                </div>
                <ul className={classes.List}>
                    {careerList}
                </ul>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        careerList : state.CareerReducer.careerList,
        isErrorFetchCareerList : state.CareerReducer.isErrorFetchCareerList
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onInitCareerList: () => dispatch(careerAction.initCareerList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(careerContainer);