import React, { useState } from 'react';
import classes from './RegionFilter.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Slider from 'react-slick';
import Icon from '../../../../UI/Icon/Icon';

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      	<div
			className={[classes.Arrow, classes.PrevArrow].join(' ')}
			onClick={onClick}>
				<Icon name="ico-caret-down" fill="#000000" stroke="none" width={40}/>
      	</div>
    );
}
  
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      	<div
			className={[classes.Arrow, classes.NextArrow].join(' ')}
			onClick={onClick}>
				<Icon name="ico-caret-down" fill="#000000" stroke="none" width={40}/>
      	</div>
    );
}

function regionFilter(props) {
    const [current, setCurrent] = useState(0);

    let labelList = [];
    labelList = props.destinationList.map( (value, index) => {
        let labelClasses = [classes.Item];
        if (value === props.destinationSelected) {
            labelClasses.push(classes.Active);
        }
        return (
            <div key={index} className={classes.ItemWrap} onClick={()=> props.regionClicked(value)}>
                <div className={labelClasses.join(' ')}>{value}</div>
            </div>
        );
    });

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        variableWidth: true,
        fade:false,
        // dotsClass: "slick-dots " + classes.DotWrapper,
        autoplay: false,
        autoplaySpeed: 3500,
        swipeToSlide: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: current ? <SamplePrevArrow /> : null,
        beforeChange: (current, next) => {
            setCurrent(next);
        }
    };

    labelList = (
        <Slider {...settings}>
            {labelList}
        </Slider>);

    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                {labelList}
            </div>
        </PanelBox>
    );
}

export default regionFilter;