import DesktopTourHomeContainer from './containers/Desktop/Tour/HomeContainer/HomeContainer';
import DesktopCorporateContainer from './containers/Desktop/NonProducts/CorporateContainer/CorporateContainer';
import DesktopCareerContainer from './containers/Desktop/NonProducts/CareerContainer/CareerContainer';
import DesktopAboutUsContainer from './containers/Desktop/NonProducts/AboutUsContainer/AboutUsContainer';
import DesktopBranchOfficeContainer from './containers/Desktop/NonProducts/BranchOfficeContainer/BranchOfficeContainer';
import DesktopCareerDetailContainer from './containers/Desktop/NonProducts/CareerDetailContainer/CareerDetailContainer';
import RedirectContainer from './containers/Desktop/Tour/RedirectContainer/RedirectContainer';

import DesktopTourAvailabilityContainer from './containers/Desktop/Tour/AvailabilityContainer/AvailabilityContainer';
import DesktopTourDetailContainer from './containers/Desktop/Tour/DetailContainer/DetailContainer';
import DesktopTourBookingContainer from './containers/Desktop/Tour/Booking/BookingContainer';
import DesktopRetrieveBookingContainer from './containers/Desktop/Tour/RetrieveBookingContainer/RetrieveBookingContainer';


import DesktopUserContainer from './containers/Desktop/NonProducts/UserContainer/UserContainer';
import DesktopActivationContainer from './containers/Desktop/NonProducts/ActivationContainer/ActivationContainer';
import DesktopActivationEmailContainer from './containers/Desktop/NonProducts/ActivationEmailContainer/ActivationEmailContainer';

import DesktopHotelAvailability from './containers/Desktop/Hotels/AvailabilityContainer/AvailabilityContainer';
import DesktopHotelBooking from './containers/Desktop/Hotels/BookingContainer/BookingContainer';

import DesktopVoucherAvailability from './containers/Desktop/Voucher/AvailabilityContainer/AvailabilityContainer';
import DesktopVoucherBooking from './containers/Desktop/Voucher/BookingContainer/BookingContainer';

import DesktopVirtualTour from './containers/Desktop/VirtualTour/VirtualTour';
import DesktopVirtualTourDetailContainer from './containers/Desktop/VirtualTour/DetailContainer/DetailContainer';
import DesktopVirtualTourBookingContainer from './containers/Desktop/VirtualTour/BookingContainer/BookingContainer';

import DesktopThingsToDoAvailabilityContainer from './containers/Desktop/ThingsToDo/ThingsToDo';
import DesktopActivity from './containers/Desktop/Activity/AvailabilityContainer/AvailabilityContainer';
import DesktopAdmissionAvailabilityContainer from './containers/Desktop/Admission/AvailabilityContainer/AvailabilityContainer';
import DesktopAdmissionDetailContainer from './containers/Desktop/Admission/DetailContainer/DetailContainer';
import DesktopAdmissionBookingContainer from './containers/Desktop/Admission/BookingContainer/BookingContainer';

import DesktopThingsToDoAvailabilityWithExperienceType from './containers/Desktop/Promoted/AvailabilityContainer/AvailabilityContainer';

import MobileUserContainer from './containers/Mobile/NonProducts/UserContainer/UserContainer';
import MobileEditProfileContainer from './containers/Mobile/NonProducts/EditProfileContainer/EditProfileContainer';
import MobileMyCardContainer from './containers/Mobile/NonProducts/MyCardContainer/MyCardContainer';
import MobileSettingContainer from './containers/Mobile/NonProducts/SettingContainer/SettingContainer';

import MobileTourHomeContainer from './containers/Mobile/HomeContainer/HomeContainer';
import MobileSearchContainer from './containers/Mobile/Tours/SearchContainer/SearchContainer';
import MobileAvailabilityContainer from './containers/Mobile/Tours/AvailabilityContainer/AvailabilityContainer';
import MobileDetailConntainer from './containers/Mobile/Tours/DetailConntainer/DetailConntainer';
import MobileCorporateContainer from './containers/Mobile/NonProducts/CorporateContainer/CorporateContainer';
import MobileAboutUsContainer from './containers/Mobile/NonProducts/AboutUsContainer/AboutUsContainer';
import MobileBranchOfficeContainer from './containers/Mobile/NonProducts/BranchOfficeContainer/BranchOfficeContainer';
import MobileCareerContainer from './containers/Mobile/NonProducts/CareerContainer/CareerContainer';
import MobileCareerDetailContainer from './containers/Mobile/NonProducts/CareerDetailContainer/CareerDetailContainer';
import MobileBookingContainer from './containers/Mobile/Tours/BookingContainer/BookingContainer';
import MobileLoginContainer from './containers/Mobile/NonProducts/LoginContainer/LoginContainer'
import MobileRegisterContainer from './containers/Mobile/NonProducts/RegisterContainer/RegisterContainer'
import MobileResetPasswordContainer from './containers/Mobile/NonProducts/ResetPassContainer/ResetPassContainer'

import MobileMyBookingDetail from './containers/Mobile/NonProducts/MyBookingDetail/MyBookingDetail';
import MobileMyBookingContainer from './containers/Mobile/NonProducts/BookingContainer/BookingContainer';
import MobileMyBookingDetailContainer from './containers/Mobile/NonProducts/BookingDetailContainer/BookingDetailContainer';
import RetrieveBookingContainer from './containers/Mobile/NonProducts/RetrieveBookingContainer/RetrieveBookingContainer';
import MobileVirtualTour from './containers/Mobile/VirtualTour/VirtualTour';

// import MobileForgotBookingID from './containers/Mobile/NonProducts/ForgotBookingID/ForgotBookingID';
import MobileForgotBookingID from './containers/Mobile/NonProducts/ForgotBookingID/ForgotBookingID';
import MobileActivationContainer from './containers/Mobile/NonProducts/ActivationContainer/ActivationContainer';

import MobileHotelAvailability from './containers/Mobile/Hotel/AvailabilityContainer/AvailabilityContainer';
import MobileHotelBooking from './containers/Mobile/Hotel/BookingContainer/BookingContainer';
import MobileVoucherAvailability from './containers/Mobile/Voucher/AvailabilityContainer/AvailabilityContainer';
import MobileVoucherBooking from './containers/Mobile/Voucher/BookingContainer/BookingContainer';
import MobileVirtualTourDetailContainer from './containers/Mobile/VirtualTour/DetailContainer/DetailContainer';
import MobileVirtualTourBookingContainer from './containers/Mobile/VirtualTour/BookingContainer/BookingContainer';

import MobileThingsToDoAvailability from './containers/Mobile/ThingsToDo/AvailabilityContainer/AvailabilityContainer';
import MobileAdmissionDetailContainer from './containers/Mobile/Admission/DetailContainer/DetailContainer';
import MobileAdmissionPreBookingContainer from './containers/Mobile/Admission/PreBookingContainer/PreBookingContainer';
import MobileAdmissionBookingContainer from './containers/Mobile/Admission/BookingContainer/BookingContainer';
import MobileThingsToDo from './containers/Mobile/ThingsToDo/SearchContainer/SearchContainer';
import MobileThingsToDoAvailabilityWithExperienceType from './containers/Mobile/Promoted/Availability/AvailabilityContainer';

const routes = [
	{
		path: '/admission/booking/:slug',
		desktopComponent: DesktopAdmissionBookingContainer,
		mobileComponent: MobileAdmissionBookingContainer,
		exact: true,
	},
	{
		path: '/admission/pre-booking/:slug',
		mobileComponent: MobileAdmissionPreBookingContainer,
		exact: true,
	},
	{
		path: '/admission/detail/:slug',
		desktopComponent: DesktopAdmissionDetailContainer,
		mobileComponent: MobileAdmissionDetailContainer,
		exact: true,
	},
	{
		path: '/admission/availability',
		desktopComponent: DesktopAdmissionAvailabilityContainer,
		// mobileComponent: xxxxxx,
		exact: true,
	},
	{
		path: '/activity/availability',
		desktopComponent: DesktopActivity,
		// mobileComponent: xxxxxx,
		exact: true,
	},
	{
		path: '/things-to-do/availability/:slug',
		desktopComponent: DesktopThingsToDoAvailabilityWithExperienceType,
		mobileComponent: MobileThingsToDoAvailabilityWithExperienceType,
		exact: true,
	},
	{
		path: '/things-to-do/availability',
		desktopComponent: DesktopThingsToDoAvailabilityContainer,
		mobileComponent: MobileThingsToDoAvailability,
		exact: true,
	},
	{
		path: '/things-to-do',
		// desktopComponent: xxxxx,
		mobileComponent: MobileThingsToDo,
		exact: true,
	},
	{
		path: '/virtual-tour/booking/:slug',
		desktopComponent: DesktopVirtualTourBookingContainer,
		mobileComponent: MobileVirtualTourBookingContainer,
		exact: true,
	},
	{
		path: '/virtual-tour/detail/:slug',
		desktopComponent: DesktopVirtualTourDetailContainer,
		mobileComponent: MobileVirtualTourDetailContainer,
		exact: true,
	},
	{
		path: '/virtual-tour',
		desktopComponent: DesktopVirtualTour,
		mobileComponent: MobileVirtualTour,
		exact: true,
	},
	{
		path: '/voucher/booking/:slug',
		desktopComponent: DesktopVoucherBooking,
		mobileComponent: MobileVoucherBooking,
		exact: true,
	}, {
		path: '/voucher/availability',
		desktopComponent: DesktopVoucherAvailability,
		mobileComponent: MobileVoucherAvailability,
		exact: true,
	}, {
		path: '/hotel/booking/:slug',
		desktopComponent: DesktopHotelBooking,
		mobileComponent: MobileHotelBooking,
		exact: true,
	}, {
		path: '/hotel/availability',
		desktopComponent: DesktopHotelAvailability,
		mobileComponent: MobileHotelAvailability,
		exact: true,
	}, {
		path: '/email-activation/:activationCode',
		desktopComponent: DesktopActivationEmailContainer,
		mobileComponent: DesktopActivationEmailContainer,
		exact: true,
	}, {
		path: '/activation/:activationCode',
		desktopComponent: DesktopActivationContainer,
		mobileComponent: MobileActivationContainer,
		exact: true,
	}, {
		path: '/reset',
		desktopComponent: DesktopTourHomeContainer,
		mobileComponent: MobileResetPasswordContainer,
		exact: true,
	}, {
		path: '/register',
		desktopComponent: DesktopTourHomeContainer,
		mobileComponent: MobileRegisterContainer,
		exact: true,
	}, {
		path: '/login',
		desktopComponent: DesktopTourHomeContainer,
		mobileComponent: MobileLoginContainer,
		exact: true,
	}, {
		path: '/tour',
		desktopComponent: DesktopTourHomeContainer,
		mobileComponent: MobileSearchContainer,
		exact: true,
	}, {
		path: '/tour/availability',
		desktopComponent: DesktopTourAvailabilityContainer,
		mobileComponent: MobileAvailabilityContainer,
		exact: true,
	}, {
		path: '/tour/detail/:slug',
		desktopComponent: DesktopTourDetailContainer,
		mobileComponent: MobileDetailConntainer,
		exact: true,
	}, {
		path: '/tour/booking/:bookingCode',
		desktopComponent: DesktopTourBookingContainer,
		mobileComponent: MobileBookingContainer,
		exact: true,
	}, {
		path: '/corporate',
		desktopComponent: DesktopCorporateContainer,
		mobileComponent: MobileCorporateContainer,
		exact: true,
	}, {
		path: '/about-us',
		desktopComponent: DesktopAboutUsContainer,
		mobileComponent: MobileAboutUsContainer,
		exact: true,
	}, {
		path: '/branch-office',
		desktopComponent: DesktopBranchOfficeContainer,
		mobileComponent: MobileBranchOfficeContainer,
		exact: true,
	}, {
		path: '/career',
		desktopComponent: DesktopCareerContainer,
		mobileComponent: MobileCareerContainer,
		exact: true,
	}, {
		path: '/career/:slug',
		desktopComponent: DesktopCareerDetailContainer,
		mobileComponent: MobileCareerDetailContainer,
		exact: true,
	}, {
		path: '/redirect',
		desktopComponent: RedirectContainer,
		mobileComponent: RedirectContainer,
		exact: true,
	}, {
		path: '/retrieve-booking',
		desktopComponent: DesktopRetrieveBookingContainer,
		mobileComponent: RetrieveBookingContainer,
		exact: true,
	}, {
		path: '/retrieve-booking',
		desktopComponent: DesktopRetrieveBookingContainer,
		mobileComponent: MobileMyBookingDetail,
		exact: true,
	}, {
		path: '/retrieve-booking/forget/booking-id',
		desktopComponent: DesktopRetrieveBookingContainer,
		mobileComponent: MobileForgotBookingID,
		exact: true,
	}, {
		path: '/user',
		desktopComponent: DesktopUserContainer,
		mobileComponent: MobileUserContainer,
		exact: true,
		isProtected: true
	}, {
		path: '/user/profile',
		desktopComponent: DesktopUserContainer,
		mobileComponent: MobileEditProfileContainer,
		exact: true,
		isProtected: true
	}, {
		path: '/user/my-booking',
		desktopComponent: DesktopUserContainer,
		mobileComponent: MobileMyBookingContainer,
		exact: true,
		isProtected: true
	}, {
		path: '/user/my-booking/:bookingCode',
		desktopComponent: DesktopUserContainer,
		mobileComponent: MobileMyBookingDetailContainer,
		exact: true,
		isProtected: true
	}, {
		path: '/user/my-card',
		desktopComponent: DesktopUserContainer,
		mobileComponent: MobileMyCardContainer,
		exact: true,
		isProtected: true
	}, {
		path: '/user/setting',
		desktopComponent: DesktopUserContainer,
		mobileComponent: MobileSettingContainer,
		exact: true,
		isProtected: true
	}, {
		path: '/',
		desktopComponent: DesktopTourHomeContainer,
		mobileComponent: MobileTourHomeContainer,
		exact: false,
	}
];

export default routes;
