import React, { useState } from 'react';
import classes from './ListPanel.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import List from '../../../../../UI/List/List';
import Icon from '../../../../../UI/Icon/Icon';

function listPanel(props) {
    const [showMore, setShowMore] = useState(false);

    let wrapperClasses = [classes.Wrapper];
    let iconClasses = [];
    let collapseBtn = null;
    let label = 'Show More';
    if (props.isCollapseBtn && props.isCollapseBtn === true) {
        if (showMore) {
            wrapperClasses = [classes.Wrapper, classes.More];
            label = 'Show Less';
            iconClasses = [classes.ArrowUp];
        } else {
            label = 'Show More';
            wrapperClasses = [classes.Wrapper, classes.Less];
        }
        collapseBtn = (
            <div className={classes.CollapseBtn} onClick={() => setShowMore(!showMore)}>
                {label} 
                <div className={iconClasses.join(' ')}>
                    <Icon name="ico-caret-down" fill="#1A6FA3" stroke="none"  />
                </div>
            </div>
        );
    }
    return (
        <PanelBox>
            <div className={wrapperClasses.join(' ')}>
                <div className={classes.Title}>{props.title}</div>
                <List listColor={props.listColor} list={props.list}/>
                {collapseBtn}
            </div>
        </PanelBox>
    );
}

export default listPanel;