import React from 'react';
import classes from './OtherInformation.module.scss';

const otherInformation = (props) => {
    return (
        <div>
            <div className="u-bg-white_dark u-br-4 u-p-24 u-mb-16">
                <h5>1. Iklim dan Cuaca</h5>
                <p>Musim di EROPA pada saat ini adalah memasuki  MUSIM SEMI dengan suhu berkisar  4o  s/d 16oCyangberagam diberbagai kota. Khusus di atas Gunung Titlis suhu bisa turun menjadi  -5o s/d 0oC    </p>
                
                <h5>2. Pakaian</h5>
                <p>Musim di EROPA pada saat ini adalah memasuki  MUSIM SEMI dengan suhu berkisar  4o  s/d 16oCyangberagam diberbagai kota. Khusus di atas Gunung Titlis suhu bisa turun menjadi  -5o s/d 0oC    </p>
                
                <h5>3. Perbedaan Waktu</h5>
                <p>Perbedaan waktu  di negara Europeadalah lebih lambat 5 jam dari WIB.</p>
                <h5>4. Makanan</h5>
                <p>Makanan   yang   kami   sediakan   sudah   diatur   sebelumnya   sesuai   dengan   yang   terdapat   pada   jadwalperjalanan yang bervariasi guna menghindari rasa bosan, anda dapat juga dipersilahkan untuk membawamakanan dalam kemasan (mie instant, sambal botol , abon, dll).</p>
                <h5>5. Obat - Obatan</h5>
                <p>Disarankan untuk membawa obat-obatan pribadi bagi yang mengidap penyakit tertentu dikarenakan Andatidak selalu dapat membeli obat-obatan tanpa resep dokter, dan juga faktor kecocokan. Disarankan pulauntuk membawa vitamin dan supplement yang berguna untuk menjaga kondisi selama musim dingin. </p>
                <h5>6. Uang</h5>
                <p>Mata  uang yang digunakan adalah :  Euro.  Kami  mengajurkan untuk  membawa  mata  uang  tersebut  dariIndonesia , karena  jika membawa US Dollar kurs  tukar yang berlaku disana cukup rendah. Perkiraan kurssaat ini adalah:  1 Euro  = Rp 16.000,-</p>
                <h5>7. Telpon Genggam</h5>
                <p>Handphone   jenis   GSM   pada   umumnya   dapat   dipergunakan   hampir   diseluruh   negara   di   Eropa.   Silahkanmenghubungi operator telepon selular Anda untuk mengaktifkan fasilitas roaming internasional.</p>
                <h5>8. Kopor / Bagasi</h5>
                <p>Sesuai dengan peraturan penerbangan pesawat  Emirates Airlines kelas ekonomi , maka masing-masingpeserta tour diperbolehkan untuk membawa   bagasi dengan berat tidak lebih dari 30 KG (Boleh lebihdari 1 pcs) serta 1 hand bag dengan berat tidak lebih dari 7 KG.  Jika anda ingin membawa barang-barang cairan, aerosol dan gel, ke dalam cabin, anda harus memastikan agar setiap barang besarnyatidak melebihi 100ml. Semua barang ini harus dimasukkan ke dalam satu kantong yang transparandan harus ada seal atau penutupnya.Karena perjalanan berpindah-pindah dari satu kota ke kota lain, kamimengharapkan kopor dalam ukuran yang tidak terlalu besar dan  masih dalam keadaan baik dan kuat untukmenghindari   pecah      /   rusak   selama   perjalanan   yang   mengakibatkan   barang-barang   didalamnyaberhamburan atau hilang.   Hindari berat bagasi melebihi ketentuan pihak airlines karena biaya kelebihanbagasi akan dibebankan langsung kepada penumpang tersebut. Harap diperhatikan bahwa  benda-bendaseperti  pisau lipat  atau  lainnya yang sejenis, gunting kuku, peralatan manicure, gunting, cutter danbenda-benda kecil lain yang tajam dan runcing, tidak  diperbolehkan disimpan di dalam HANDCARRY  atau  dibawa masuk ke kabin pesawat.   Apabila   Anda  perlu  membawa  barang-barang  tersebutharap disimpan di dalam bagasi.</p>
                <h5>9. Keamanan</h5>
                <p>Kami   menganjurkan   untuk   tidak   membawa   perhiasan   yang   berlebihan.   Peserta   diminta   untuk   tidakmeninggalkan barang-barang berharga didalam bus. Pengemudi Bus, Local Guide, Tour Operator dan TourLeader  tidak bertanggung jawab apabila terjadi kehilangan barang akibat kelalaian peserta.  Kami jugamenganjurkan Anda untuk membuat fotocopy dari passport, credit card atau dokumen perjalananlainnya untuk dibawa dan ditinggal di rumah untuk keperluan emergency jika terjadi kehilangan.</p>
                <h5>10. Pengeluaran / Biaya Pribadi</h5>
                <p>Adalah tanggung jawab peserta pribadi jika menggunakan fasilitas di luar acara perjalanan seperti laundry,telpon,   mini   bar   ataupun   tour   tambahan.   Jika   pengeluaran   pribadi   dilakukan   di   dalam   hotel   mohondiselesaikan di kasir sebelum check out / meninggalkan hotel.</p>
                <h5>11. Tips</h5>
                <p>Adalah merupakan  suatu kebiasaan yang wajar   dalam  suatu perjalanan tour  untuk memberi  tips  kepadapara penunjang tour.Sebagai panduan untuk  servis lokal di EROPA selama perjalanan ini, dapat kamirincikan per orang sebagai berikut :
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>Pengemudi Bus</td>
                            <td>11 hari X EUR 2,-</td>
                            <td>= EUR 22,-</td>
                        </tr>
                        <tr>
                            <td>Tour Leader</td>
                            <td>13 hari X EUR 3,-</td>
                            <td>= EUR 39,-  +</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2">13 hari X EUR 3,-</td>
                            <td>= EUR 61,-</td>
                        </tr>
                    </tfoot>
                </table>
                <h5>12. Lain - Lain</h5>
                <ul className={classes.List}>
                    <li className={classes.Item}>Harap membawa  1 stel pakaian ganti di dalam hand carry, untuk berjaga – jaga apabila bagasiterlambat sampai di tempat tujuan, penundaan penerbangan, pembatalan  pesawat</li>
                    <li className={classes.Item}>Pada umumnya  hotel-hotel  di Europe tidak seperti di Asia, dimana ukuran kamar lebih kecil sertalebih   sederhana   fasilitasnya.   Untuk   itu   kami   menganjurkan   Anda   untuk   membawa   perlengkapanpribadi seperi sikat gigi, odol, sisir ataupun alat – alat toiletries lainnya. Begitu pula di beberapahotel tidak mempunyai fasilitas pendingin ruangan</li>
                    <li className={classes.Item}>Dianjurkan   juga   untuk   membawa  memory card / film kamera  dan  baterai  dalam   jumlah   yangmencukupi, dikarenakan harga barang – barang tersebut relatif lebih mahal di luar negeri.</li>
                    <li className={classes.Item}>Visa yang telah diperoleh melalui Kedutaan di Indonesia tidak menjamin bagi pemegang visa untukdapat masuk ke negara tersebut. Keputusan diperbolehkan atau tidaknya adalah hak sepenuhnyadari  pihak  Imigrasi  pada  waktu  tiba  di negara tujuan.  Jika  seseorang di  tolak  masuk  /  deportasi,maka berarti harus dipulangkan hari itu juga atas biaya dan tanggungan sendiri, dan biaya tour tidakdapat di uangkan kembali (non-refund) </li>
                    <li className={classes.Item}>Pada   beberapa   Bandara   di   Europe   tidak   memperbolehkan   membawa   barang   barangduplikat/palsu untuk merek merek eropa yang terkenal, seperti Louis Vuitton, Channel, Hermes, dll.</li>
                    <li className={classes.Item}>Plug / Saklar listrik di negara Eropa sama seperti di Indonesia.</li>
                    <li className={classes.Item}>Silahkan   membawa  ketel air listrik  untuk   keperluan   memasak   air   di   kamar   hotel,   dikarenakanbanyak hotel di Europe tidak menyediakan hal ini.</li>
                    <li className={classes.Item}>Harap   membawa   Kartu   Kredit   (masih   berlaku)   yang   di   peruntukan   menjamin   kembali   nya   TaxRefund ketika  Anda  melakukan  pembelanjaan   selama  di  Eropa.  Dan   pastikan  Kartu  Kredit  Andasudah menggunakan PIN, karena beberapa shopping center di Eropa sudah memberlakukan hal ini</li>
                </ul>
            </div>
        </div>
    );
}

export default otherInformation;