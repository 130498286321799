import React from 'react';
import Auth from './middleware/Auth';
import { Route, Redirect } from 'react-router-dom';

const protectedRoute = ({ component: Component, ...rest}) => {
    return (
        <Route 
        {...rest}
        render={ props => {
            if (Auth.isAuthenticated()) {
                return <Component {...props} />;
            } else {
                return <Redirect to={{pathname: '/', state: {from: props.location}}} />
            }
        }}/>
    );
};

export default protectedRoute;