import React from 'react';
import classes from './Spiner.module.scss';
import Backdrop from '../../Backdrop/Backdrop';
import CSSTransition from 'react-transition-group/CSSTransition';

const spiner = props => {
    return (
        <div>
            <Backdrop showBackdrop={props.show} />
            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={270}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
                }}>
                <div className={classes.Wrapper}>
                    <div className={classes.Bg}>
                        <div className={classes.Spiner}></div>
                    </div>
                </div>
                </CSSTransition>
        </div>
    );
}

export default spiner;