import axios from 'axios';
import { FIREBASE_API } from './constant/Api';

const getToken = () => {
    let token = process.env.REACT_APP_DEVELOPMENT_TOKEN;
    if (!token) {
        token = window.token;
    }
    return token;
}

const instance = axios.create({
    baseURL: FIREBASE_API,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getToken()
    },
});

export const firebase = axios.create({ baseURL: FIREBASE_API });

export const axiosAuth = axios.create({
    baseURL: FIREBASE_API,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getToken(),
        'sessionKey': localStorage.getItem('sessionKey')
    },
});

export const http = axios.create();

export default instance;