import React from 'react';
import PanelBox from '../../../PanelBox/PanelBox';
import classes from './PackagesListLoader.module.scss';
import ContentLoader from 'react-content-loader';

const packagesListLoader = () => {
    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                <ContentLoader
                    height={98}
                    speed={1}
                    primaryColor={'#e6e6e6'}
                >
                    <rect x="0" y="0" rx="4" ry="4" width="131" height="97" />
                    <rect x="140" y="0" rx="2" ry="2" width="131" height="11" />
                    <rect x="140" y="20" rx="2" ry="2" width="60" height="7" />
                    <rect x="140" y="40" rx="2" ry="2" width="65" height="7" />
                    <rect x="140" y="55" rx="2" ry="2" width="40" height="10" />
                    <rect x="185" y="55" rx="2" ry="2" width="38" height="10" />
                    <rect x="140" y="72" rx="2" ry="2" width="40" height="7" />
                    <rect x="140" y="84" rx="2" ry="2" width="85" height="12" />
                    <rect x="325" y="80" rx="2" ry="2" width="75" height="18" />
                </ContentLoader>
            </div>
        </PanelBox>
    )
}

export default packagesListLoader;