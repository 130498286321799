import React from 'react';
import classes from './MessageBox.module.scss';
import artSubmitApplication from '../../../../assets/images/art-submit-application.png';
import artBookingEmpty from '../../../../assets/images/art-booking-empty.png';
import artHotelEmpty from '../../../../assets/images/art-tour-empty.png';
import Button from '../../../UI/Button/Button';

const messageBox = (props) => {
    let img = null;
    switch (props.img) {
        case 'art-submit-application': img = artSubmitApplication;
            break;
        case 'art-booking-empty': img = artBookingEmpty;
            break;
        case 'art-hotel-empty': img = artHotelEmpty;
            break;
        default:
            break;
    }
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Content}>
                <img src={img} className={classes.Image} alt="booking not found" />
                <div className={classes.Title}>{props.title}</div>
                <div className={classes.SubTitle}>{props.subTitle}</div>
                <div className={classes.Action}>
                    <Button 
                        classes={['BtnRed', 'BtnMedium']}
                        clicked={props.clicked}>
                        {props.labelBtn}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default messageBox;