import React from 'react';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import MetaTag from '../../../../helper/MetaTag';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import Banner from '../../../../components/Desktop/Promoted/Banner/Banner';
import ContentAvailability from '../../../../components/Desktop/Promoted/ContentAvailability/ContentAvailability';
import { toCapitalize } from '../../../../helper/GlobalFunc';

const availabilityContainer = (props) => {
    return (
        <div className="bg-white-dark">
            <MetaTag title={toCapitalize(props.match.params.slug).split('-').join(' ')}/>
            <Header />
            <Banner />
            <ContentAvailability slug={props.match.params.slug}/>
            <Footer />
        </div>
    )
}

export default availabilityContainer;
