import React, { useEffect, useState } from 'react';
import classes from './Form.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import Button from '../../../../UI/Button/Button';
import Backdrop from '../../../../UI/Backdrop/Backdrop';
import Icon from '../../../../UI/Icon/Icon';
import TextField from '../../../../UI/Inputs/TextField/TextField';
import SelectFlag from '../../../../UI/Inputs/SelectFlag/SelectFlag';
import { overflowBody, objectToParam } from '../../../../../helper/GlobalFunc';

import { connect } from 'react-redux';
import * as retrieveBookingAction from '../../../../../redux/Shared/RetrieveBooking/RetrieveBookingAction';

function retrieveBookingForm(props) {
    const [isForgotBookingId, setIsForgotBookingId] = useState(false);

    useEffect( () => {
        overflowBody(props.show);
    }, [props.show]);

    const submitBooking = () => {
        if (props.isValidForm) {
            const data = {
                orderNumber: props.form.bookingId.value,
                email: props.form.email.value
            }
            window.location.href = '/retrieve-booking?' + objectToParam(data);
        } else {
            props.checkingForm();
        }
    }

    let form  = (
        <div className="u-mb-40">
            <div className="u-mb-24">
                {/* <span className={classes.Link} onClick={() => this.setIsForgotBookingId(true)}>Forgot your booking ID?</span> */}
                <div className="label">Booking ID</div>
                <TextField
                    placeholder="e.g. ANT-3536-xxxx" 
                    value={props.form.bookingId.value}
                    changed={(event) => props.inputChanged(event.target.value, 'bookingId')}
                    hasError={props.form.bookingId.hasError && props.form.bookingId.errorMsg !== ''}
                    errorMsg={props.form.bookingId.errorMsg} />
            </div>
            <div className="u-mb-24">
                <div className="label">Email Address</div>
                <TextField
                    placeholder="e.g. youremail@example.com" 
                    value={props.form.email.value}
                    changed={(event) => props.inputChanged(event.target.value, 'email')}
                    hasError={props.form.email.hasError && props.form.email.errorMsg !== ''}
                    errorMsg={props.form.email.errorMsg} />
            </div>
        </div>
    );
    let backBtn = null;
    if (isForgotBookingId) {
        backBtn = <div className={classes.BackBtn} onClick={() => setIsForgotBookingId(false)}>
                <Icon name="ico-arrow-left" stroke="none" fill="#1A6FA3" />
            </div>;

        form  = <div className="u-pb-24">
                    <div>
                        <div className="label">Email Address</div>
                        <TextField
                            placeholder="e.g. youremail@example.com" 
                            value={''}/>
                    </div>
                    <div className={[classes.Input, classes.LargeWidth].join(' ')}>
                        <label className={classes.Label}>Phone</label>
                        <div className={classes.Inline}>
                            <div className={classes.DialCode}>
                                <SelectFlag 
                                    value={'+62'}/>
                            </div>
                            <div className={classes.Phone}>
                                <TextField
                                    type="text"
                                    placeholder="e.g. 8123456789" 
                                    value={''} />
                            </div>
                        </div>
                    </div>
                </div>;
            }

    return (
        <div>
            <div className={classes.Backdrop}>
                <Backdrop showBackdrop={props.show}/>
            </div>
            <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={160}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close
            }}>
                <div className={classes.Wrapper}>
                    <div className={classes.Container}>
                        <div className={classes.Content}>
                            <div className={classes.CloseBtn} onClick={props.hideClicked}>
                                <Icon name="ico-close" stroke="none" fill="#202124" />
                            </div>
                            <div className={classes.FormWrapper}>
                                <div className={classes.Header}>
                                    {backBtn}
                                    <div className={classes.Title}>Retrieve Booking</div>
                                </div>
                                {form}
                                <div className={classes.Action}>
                                    <Button 
                                        classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
                                        isLoading={props.isSubmitLoading}
                                        clicked={submitBooking}>
                                        Retrieve Booking
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

const mapStateToProps = state => {
    return{
        form: state.RetrieveBookingReducer.formData,
        isValidForm: state.RetrieveBookingReducer.isValidForm
    };
}

const mapDispatchToProps = dispatch => {
    return{
        inputChanged: (val, identifier) => dispatch(retrieveBookingAction.inputChanged(val, identifier)),
        checkingForm: () => dispatch(retrieveBookingAction.checkingForm())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(retrieveBookingForm);

