import React from 'react';
import classes from './TourInfo.module.scss';
import { toCapitalize, textLimit } from '../../../../../helper/GlobalFunc';
import Icon from '../../../../UI/Icon/Icon';
import PanelBox from '../../../../UI/PanelBox/PanelBox';

const tourInfo = (props) => {
    return (
        <PanelBox>
            <div className={classes.HeaderDetail}>
                <div className={classes.Image}>
                    <img
                        className={classes.Img}
                        src={props.tourData.tourImage[0].tourImageName}
                        alt="Tour"/>
                </div>
                <div className={classes.Info}>
                    <div className={classes.Title}>
                        {textLimit(`${props.tourData.days}D ${toCapitalize(props.tourData.tourInventoryTitle)}`)}
                    </div>
                    <div className={classes.Desc}>{props.tourData.days + ' Days'} & {props.tourData.night + ' Nights'}</div>
                </div>
            </div>
            <div className={classes.Body}>
                <div className={classes.Item}>
                    <Icon width={26} name="ico-calendar" fill="#6A6E73" stroke="none" />
                    <span className={classes.Label}>
                        {/* <Moment format="ddd, DD MMM YYYY">
                            {props.schedule.dateStart}
                        </Moment> */}
                        Mon, 13 May 2019
                        <span> - </span>
                        {/* <Moment format="ddd, DD MMM YYYY">
                            {props.schedule.dateEnd}
                        </Moment> */}
                        Tue, 21 May 2019
                    </span>
                </div>
                <div className={classes.Item}>
                    <Icon width={26} name="ico-hotel" fill="#6A6E73" stroke="none" />
                    <span className={classes.Label}>3-Star Hotel</span>
                </div>
                <div className={classes.Item}>
                    <Icon width={26} name="ico-flight-o" fill="#6A6E73" stroke="none" />
                    <span className={classes.Label}>Cathay Pacific</span>
                </div>
                <div className={classes.Item}>
                    <Icon width={26} name="ico-user-o" fill="#6A6E73" stroke="none" />
                    <span className={classes.Label}>2 Room - 3 Adult & 1 Childer</span>
                </div>
            </div>
        </PanelBox>
    )
}

export default tourInfo;
