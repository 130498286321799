import axios from "../../../axios";
import { toast } from "react-toastify";
import { ANTAVAYA_API } from "../../../constant/Api";

export const setToDefault = () => {
	return {
		type: "SET_TO_DEFAULT",
	};
};

export const setVoucherDetail = (voucherDetail) => {
	return {
		type: "SET_VOUCHER_DETAIL",
		payload: { voucherDetail },
	};
};

export const seIsFetchVoucherDetail = (value) => {
	return {
		type: "SET_IS_FETCH_VOUCHER_DETAIL",
		payload: { value },
	};
};

export const seIsErrorFetchVoucherDetail = (value) => {
	return {
		type: "SET_IS_ERROR_FETCH_VOUCHER_DETAIL",
		payload: { value },
	};
};

export const checkContactInfoForm = () => {
	return {
		type: "CHECK_CONTACT_INFO_FORM_VOUCHER",
	};
};

export const changeContactInfoForm = (identifier, value) => {
	return {
		type: "CHANGE_CONTACT_INFO_FORM_VOUCHER",
		payload: { identifier, value },
	};
};

export const changeInquiryForm = (identifier, value) => {
	return {
		type: "CHANGE_INQUIRY_FORM_VOUCHER",
		payload: { identifier, value },
	};
};

export const seIsSubmitBooking = (value) => {
	return {
		type: "SET_IS_SUBMIT_BOOKING_VOUCHER",
		payload: { value },
	};
};

export const seIsSuccessSubmitBooking = (value) => {
	return {
		type: "SET_IS_SUCCESS_SUBMIT_BOOKING_VOUCHER",
		payload: { value },
	};
};

export const fetchVoucherDetail = (slug) => {
	return (dispatch) => {
		dispatch(seIsFetchVoucherDetail(true));
		axios.get(ANTAVAYA_API + 'voucher/detail/' + slug)
			.then((res) => {
				dispatch(setVoucherDetail(res.data.data));
				dispatch(seIsFetchVoucherDetail(false));
			})
			.catch((error) => {
				dispatch(seIsErrorFetchVoucherDetail(true));
				dispatch(seIsFetchVoucherDetail(false));
			});
	};
};

export const submitInquiry = (voucherId, contactInfoFrom, inquiryForm) => {
	const payload = {
		voucherID: voucherId,
		title: contactInfoFrom.title.value,
		firstName: contactInfoFrom.firstName.value,
		lastName: contactInfoFrom.lastName.value,
		phoneNumber: contactInfoFrom.dialCode.value.concat(
			contactInfoFrom.phone.value
		),
		email: contactInfoFrom.email.value,
		question: inquiryForm.question.value,
	};

	return (dispatch) => {
		dispatch(seIsSubmitBooking(true));
		axios.post(ANTAVAYA_API + 'voucher/inquiry', payload)
			.then((res) => {
				dispatch(seIsSuccessSubmitBooking(true));
				dispatch(seIsSubmitBooking(false));
			})
			.catch((error) => {
				dispatch(seIsSubmitBooking(false));
				if (error && error.response) {
					switch (typeof error.response.data.message) {
						case "string":
							toast.error(error.response.data.message);
							console.log(error.response.data.message);
							break;
						case "object":
							for (const key in error.response.data.message) {
								toast.error(error.response.data.message[key]);
							}
							break;
						default:
							break;
					}
				}
			});
	};
};
