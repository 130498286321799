import React from 'react';
import classes from './EmptyData.module.scss';
import Button from '../Button/Button';

import artTourEmpty from '../../../assets/images/art-tour-empty.png';
import artBookingEmpty from '../../../assets/images/art-booking-empty.png';
import artPaymentComplete from '../../../assets/images/art-payment-complete.png';
import artSubmitApplication from '../../../assets/images/art-submit-application.png';

const ImageInfo = (props) => {
    let button = null;
    let image = null;

    switch (props.type) {
        case 'tour-empty':
            image = artTourEmpty;
            break;
        case 'booking-empty':
            image = artBookingEmpty;
            break;
        case 'payment-complete':
            image = artPaymentComplete;
            break;
        case 'submit-application':
            image = artSubmitApplication;
            break;
        default:
            image = null;
            break;
    }

    if (props.btnAction) {
        button = (
            <Button
                classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
                clicked={props.btnAction}
                children={props.labelButton}/>
        )
    }
    return (
        <div className={classes.Content}>
            <div className={classes.Image}>
                <img src={image} alt="Finish"/>
            </div>
            <div className={classes.Heading}>{props.title}</div>
            <div className={classes.Desc}>{props.desc}</div>
            <div className={classes.BtnWrapper}>
                {button}
            </div>
        </div>
    )
}

export default ImageInfo;
