import { updateObject } from '../../utility';
import { DEFAULT_TITLE, ADULT_TITLE, DEFAULT_DIAL_CODE } from '../../../constant/General';
import * as validation from '../../../helper/Validation';

const contactInfoFormInit = {
    title: {
        value: DEFAULT_TITLE,
        options: ADULT_TITLE,
        hasError: false,
        errorMsg: '',
        rules: {
            required: true
        }
    },
    firstName: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules: {
            required: true
        }
    },
    lastName: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules: {
            required: true
        }
    },
    email: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules: {
            required: true,
            isEmail: true
        }
    },
    dialCode: {
        value: DEFAULT_DIAL_CODE
    },
    phone: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules: {
            required: true,
            isNumericPhone: true,
            minLength: 7,
        }
    }
};

const inquiryFormInit = {
    question: {
        value: '',
        hasError: true,
        errorMsg: '',
    }
};

const defaultInitState = {
    voucherDetail: null,
    isFetchVoucherDetail: false,
    isErrorFetchVoucherDetail: false,
    isValidContactInfoForm: false,
    contactInfoForm: contactInfoFormInit,
    inquiryForm: inquiryFormInit,
    isSubmitBooking: false,
    isSuccessSubmitBooking: false
};

const initialState = { ...defaultInitState };

const setToDefault = (state) => {
    return updateObject(state, defaultInitState);
}

const setVoucherDetail = (state, action) => {
    return updateObject(state, { voucherDetail: action.payload.voucherDetail });
};

const seIsFetchVoucherDetail = (state, action) => {
    return updateObject(state, { isFetchVoucherDetail: action.payload.value });
}

const seIsErrorFetchVoucherDetail = (state, action) => {
    return updateObject(state, { isErrorFetchVoucherDetail: action.payload.value });
}

const checkContactInfoForm = state => {
    let contactInfoForm = { ...state.contactInfoForm };
    for (const identifier in contactInfoForm) {
        let validate = validation.validate(contactInfoForm[identifier].rules, contactInfoForm[identifier].value);
        contactInfoForm[identifier] = { ...contactInfoForm[identifier], ...validate };
    }

    return updateObject(state, { contactInfoForm });
}

const changeContactInfoForm = (state, action) => {
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let contactInfoForm = { ...state.contactInfoForm };

    let validate = validation.validate(contactInfoForm[identifier].rules, value);
    contactInfoForm[identifier] = { ...contactInfoForm[identifier], ...validate };
    let isValidContactInfoForm = validation.isValidForm(contactInfoForm);

    return updateObject(state, { contactInfoForm, isValidContactInfoForm });
}

const changeInquiryForm = (state, action) => {
    let identifier = action.payload.identifier;
    let value = action.payload.value;
    let inquiryForm = { ...state.inquiryForm };

    let validate = validation.validate(inquiryForm[identifier].rules, value);
    inquiryForm[identifier] = { ...inquiryForm[identifier], ...validate };

    return updateObject(state, { inquiryForm });
}

const seIsSubmitBooking = (state, action) => {
    return updateObject(state, { isSubmitBooking: action.payload.value });
}

const seIsSuccessSubmitBooking = (state, action) => {
    return updateObject(state, { isSuccessSubmitBooking: action.payload.value });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VOUCHER_DETAIL': return setVoucherDetail(state, action);
        case 'SET_IS_FETCH_VOUCHER_DETAIL': return seIsFetchVoucherDetail(state, action);
        case 'SET_IS_ERROR_FETCH_VOUCHER_DETAIL': return seIsErrorFetchVoucherDetail(state, action);
        case 'CHECK_CONTACT_INFO_FORM_VOUCHER': return checkContactInfoForm(state, action);
        case 'CHANGE_CONTACT_INFO_FORM_VOUCHER': return changeContactInfoForm(state, action);
        case 'CHANGE_INQUIRY_FORM_VOUCHER': return changeInquiryForm(state, action);
        case 'SET_IS_SUBMIT_BOOKING_VOUCHER': return seIsSubmitBooking(state, action);
        case 'SET_IS_SUCCESS_SUBMIT_BOOKING_VOUCHER': return seIsSuccessSubmitBooking(state, action);
        case 'SET_TO_DEFAULT': return setToDefault(state);
        default: return state;
    }
};

export default reducer;