import React from 'react';
import classes from './PackageInfo.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import ImgThumb from '../../../../../UI/ImgThumb/ImgThumb';
import Icon from '../../../../../UI/Icon/Icon';
import { toCapitalize } from '../../../../../../helper/GlobalFunc';
import CurrencyFormat from 'react-currency-format';

const packageInfo = (props) => {

    const totalPrice = (prices) => {
        let total = 0;
        for (const key in prices) {
            total += prices[key].price * prices[key].qty
        }
        return (total === 0) ? props.admissionDetail.priceStartFrom : total;
    }

    return (
        <PanelBox>
            <div className={classes.Wrapper} onClick={props.showPopupClicked}>
                <div className={classes.Header}>
                    <div className="u-mr-16">
                        <ImgThumb src={props.admissionDetail.image} size="small" />
                    </div>
                    <div className={classes.Info}>
                        <div className={classes.Title}>
                            {toCapitalize(props.admissionDetail.tourAdmissionName)}
                        </div>
                    </div>
                    <div className={classes.Arrow}>
                        <Icon name="ico-chev-right" fill="#202124;" stroke="none"  />
                    </div>
                </div>
                <div className={classes.TotalPrice}>
                    <div className={classes.Label}>Total Price</div>
                    <div className={classes.Price}>
                        <CurrencyFormat value={totalPrice(props.visitors)} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                    </div>
                </div>
            </div>
        </PanelBox>
    )
}

export default packageInfo;
