import React from 'react';
import classes from './Switch.module.scss';

const checkBox = (props) => {
    return (
        <div>
            <label className={classes.Switch}>
                <input className={classes.Input} type="checkbox" checked={props.checked} onChange={props.changed} />
                <span className={[classes.Slider, classes.Round].join(' ')} />
            </label>
        </div>
    );
}

export default checkBox;