import React from 'react';
// import classes from './LeftContent.module.scss';
import Form from '../Form/Form';
// import PanelBox from '../../../../UI/PanelBox/PanelBox';

const leftContent = (props) => {
    return (
        <div>
            <div className="u-mb-24">
                <Form
                    submitInquryHandler={props.submitInquryHandler}
                    // hotelDetail={props.hotelDetail}
                    />
            </div>
        </div>
    )
}

export default leftContent;
