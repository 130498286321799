import React, { useState, useEffect } from 'react';
import BookingHeader from '../../../../components/Desktop/Shared/BookingHeader/BookingHeader';
import MetaTag from '../../../../helper/MetaTag';
import { connect } from 'react-redux';
import * as tourBooking from '../../../../redux/Tour/Booking/BookingAction';
import classes from './BookingContainer.module.scss';
import LeftContent from '../../../../components/Desktop/Tour/BookingForm/LeftContent/LeftContent';
import RightContent from '../../../../components/Desktop/Tour/BookingForm/RightContent/RightContent';
import NotFound from '../../../../components/Desktop/Tour/BookingForm/NotFound/NotFound';
import Spiner from '../../../../components/UI/Loaders/Spiner/Spiner';
import Confirm from '../../../../components/UI/Dialog/Confirm/Confirm';

const bookingContainer = props => {
    const [isContinueConfirm, setIsContinueConfirm] = useState(false);

    useEffect(() => {
        let bookingCode = props.match.params.bookingCode;
        props.setIsErrorInitBooking(false);
        props.onInitBookingDetail(bookingCode);
        props.getDetailMember();
    }, []);

    const submitInquiryHandler = () => {
        if (props.isValidForm && props.isValidParticipantForm) {
            setIsContinueConfirm(true);
        } else {
            props.checkBookingForm();
        }
    }

    const submitData = () => {
        let bookingCode = props.match.params.bookingCode;
        props.onSubmitBooking(bookingCode, props.contactInfo, props.participantForm);
    }

    let content = <div className={classes.Loading}><Spiner/></div>;
    if (!props.isInitLoading) {
        if (props.isErrorInitBooking) {
            content = <div className={classes.NotFound}>
                <NotFound clicked={() => props.history.push('/tour/availability?destination=all-region')}/>
            </div>
        } else {
            content = <div>
                <div className={classes.Header}>
                    <div className={classes.Title}>Tour Booking</div>
                    <div className={classes.SubTitle}>Fill in your details and review your booking</div>
                </div>
                <div className={classes.Body}>
                    <div className={classes.LeftContent}>
                        <LeftContent 
                            guestData={props.participantForm}
                            contactInfo={props.contactInfo}
                            inputChanged={(val, type) => props.contactInfoChanged(val, type)}
                            guestInputChanged={(event, roomIndex, guestType, guestIndex, inputType) => props.participantChanged(event, roomIndex, guestType, guestIndex, inputType)}
                            submitInquiryClicked={submitInquiryHandler}/>
                    </div>
                    <div className={classes.RightContent}>
                        <RightContent bookingData={props.tourDetail} />
                    </div>
                </div>
            </div>;
        }
    }

    return (
        <div className="bg-white-dark">
            <Confirm 
                show={isContinueConfirm}
                title="Continue to payment?"
                desc="Make sure your data is correct. You can’t change your booking details once you proceed to payment."
                cancelClicked={() => setIsContinueConfirm(false)}
                isConfirmLoading={props.isSubmitBookLoading}
                cancelLabel="No, check again"
                confirmLabel="Yes, continue"
                confirmClicked={submitData}/>
            <MetaTag 
                title="Tour Booking"/>
            <div className={classes.Wrapper}>
                <BookingHeader step={1}/>
                <div className={classes.Container}>
                    {content}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        isInitLoading: state.BookingReducer.isInitLoading,
        tourDetail: state.BookingReducer.tourDetail,
        isValidForm: state.BookingReducer.isValidForm,
        isValidParticipantForm: state.BookingReducer.isValidParticipantForm,
        contactInfo: state.BookingReducer.contactInfoForm,
        participantForm: state.BookingReducer.participantForm,
        isSubmitBookLoading: state.BookingReducer.isSubmitBookLoading,
        isErrorInitBooking: state.BookingReducer.isErrorInitBooking
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitBookingDetail: (token) => dispatch(tourBooking.initBookingDetail(token)),
        onSubmitBooking: (bookingCode, contactInfoFrom, participantForm) => dispatch(tourBooking.submitBooking(bookingCode, contactInfoFrom, participantForm)),
        contactInfoChanged: (val, type) => dispatch(tourBooking.contactInfoChanged(val, type)),
        participantChanged: (val, roomIndex, guestType, guestIndex, identifier) => dispatch(tourBooking.participantChanged(val, roomIndex, guestType, guestIndex, identifier)),
        checkBookingForm: () => dispatch(tourBooking.checkBookingForm()),
        setIsErrorInitBooking: (val) => dispatch(tourBooking.setIsErrorInitBooking(val)),
        getDetailMember: () => dispatch(tourBooking.getDetailMember())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(bookingContainer);