import React from 'react';
import classes from './ResetPassContainer.module.scss';
import TextField from '../../../../components/UI/Inputs/TextField/TextField';
import Button from '../../../../components/UI/Button/Button';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import artSubmitApplication from '../../../../assets/images/art-submit-application.png';

import { connect } from 'react-redux';
import * as forgotPasswordAction from '../../../../redux/Shared/ForgotPassword/ForgotPasswordAction';


function resetPasswordContainer(props) {
	const goBack = () => {
		props.history.push('/login');
		props.setIsSuccessSubmit(false);
	}

	const submitHandler = () => {
		if (props.isValidForm) {
			props.submitEmailHandler(props.form);
		} else {
			props.checkingForm();
		}
	}
	let content = <div className={classes.Content}>
			<p className={classes.Title}>Don't worry</p>
			<p className={classes.SubTitle}>
				Enter your email address below and we will send you a new password
			</p>
			<div className={classes.Panel}>
				<PanelBox>
					<div className={classes.Card}>
						<div className={classes.Input}>
							<label className={classes.Label}>Email</label>
							<TextField
								placeholder='e.g. youremail@example.com'
								value={props.form.email.value}
								changed={(event) => props.inputChanged(event.target.value, 'email')}
								hasError={props.form.email.hasError && props.form.email.errorMsg !== ''}
								errorMsg={props.form.email.errorMsg}
							/>
						</div>
						<Button
							classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
							isLoading={props.isSubmitLoading}
							clicked={submitHandler}>Reset My Password
						</Button>
					</div>
				</PanelBox>
			</div>
		</div>;
	
	if (props.isSuccessSubmit) {
		content = <div className={classes.Content}>
			<p className={classes.Title}>Please check your email</p>
			<p className={classes.SubTitle}>
				We have sent you a new password on your registered email address
			</p>
			<div className={classes.Image}>
				<img src={artSubmitApplication} alt="Success Register"/>
			</div>
		</div>;
	}

	return(
		<div>
			<div className={classes.Header}>
				<Header classes={['ClBlack', 'BgRed']} 
					icoColor="white"
					leftIcon="back"
					leftClicked={goBack}
					content={<div className={classes.Title}>Forgot Password</div>}/>
			</div>
			{content}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		form: state.ForgotPasswordReducer.form,
		isValidForm: state.ForgotPasswordReducer.isValidForm,
		isSubmitLoading: state.ForgotPasswordReducer.isSubmitLoading,
		isSuccessSubmit: state.ForgotPasswordReducer.isSuccessSubmit
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		inputChanged: (value, identifier) => dispatch(forgotPasswordAction.inputChanged(value, identifier)),
		submitEmailHandler: (form) => dispatch(forgotPasswordAction.submitEmail(form)),
		checkingForm: () => dispatch(forgotPasswordAction.checkingForm()),
		setIsSuccessSubmit: (val) => dispatch(forgotPasswordAction.setIsSuccessSubmit(val))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(resetPasswordContainer);
