import React from 'react';
import classes from './BookingDetail.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import { getTitle, formatHumanDate } from '../../../../../helper/GlobalFunc';
import PaymentStatus from '../../../../Shared/PaymentStatus/PaymentStatus';

const BookingDetail = (props) => {
	let paymentMethod = '-';
	if (props.bookingData.payment && Object.keys(Object.keys(props.bookingData.payment).length).length > 0) {
		paymentMethod = props.bookingData.payment.paymentMethod.label;
	}
	return (
		<PanelBox>
			<div className={classes.Card}>
				<div className={classes.Detail}>
					<div className={classes.Title}>Booking ID</div>
					<div className={classes.Value}>{props.bookingData.orderNumber}</div>
				</div>
				<div className={classes.Detail}>
					<div className={classes.Title}>Purchased Name</div>
					<div className={classes.Value}>{getTitle(props.bookingData.customerTitle)} {props.bookingData.customerName}</div>
				</div>
				<div className={classes.Detail}>
					<div className={classes.Title}>Purchased On</div>
					<div className={classes.Value}>{formatHumanDate(props.bookingData.bookingDate)}</div>
				</div>
				<div className={classes.Detail}>
					<div className={classes.Title}>Payment Method</div>
					<div className={classes.Value}>{paymentMethod}</div>
				</div>
			</div>
			<div className={classes.Foot}>
				<PaymentStatus status={props.bookingData.paymentStatus}/>
			</div>
		</PanelBox>
	)
}

export default BookingDetail