import React from 'react';
import classes from './GuestInfo.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import TextField from '../../../../../UI/Inputs/TextField/TextField';
import Select from '../../../../../UI/Inputs/Select/Select';
import SelectFlag from '../../../../../UI/Inputs/SelectFlag/SelectFlag';
import CheckBox from '../../../../../UI/Inputs/CheckBox/CheckBox';
import { GUEST_TYPE_TOUR } from '../../../../../../constant/General';

const guestInfo = (props) => {
    let adultForm = null; 
    let childForm = null;
    GUEST_TYPE_TOUR.forEach(guestType => {
        if (props.roomData[guestType] && props.roomData[guestType].length > 0) {
            const guestForm = props.roomData[guestType].map((form, guestIndex) => {
                return (
                    <div key={guestIndex} className={classes.GuestWrapper}>
                        <div className={classes.SubTitle}>{(guestType === 'adult')? 'Adult': 'Children'} {guestIndex + 1}</div>
                        <div className="u-p-24">
                            <div className="u-display-flex u-mb-24">
                                <div className="u-flex-3 u-mr-16">
                                    <div className="label">Title</div>
                                    <Select 
                                        placeholder="Title"
                                        value={form.title.value}
                                        options={form.title.options}
                                        changed={(val) => props.guestInputChanged(val, props.roomIndex, guestType, guestIndex, 'title')} />
                                </div>
                                <div className="u-flex-10 u-mr-16">
                                    <div className="label">First Name</div>
                                    <TextField
                                        placeholder="e.g. Julie" 
                                        value={form.firstName.value} 
                                        changed={(event) => props.guestInputChanged(event.target.value, props.roomIndex, guestType, guestIndex, 'firstName')}
                                        hasError={form.firstName.hasError && form.firstName.errorMsg !== ''}
                                        errorMsg={form.firstName.errorMsg}/>
                                </div>
                                <div className="u-flex-10">
                                    <div className="label">Last Name</div>
                                    <TextField
                                        placeholder="e.g. Applesed" 
                                        value={form.lastName.value} 
                                        changed={(event) => props.guestInputChanged(event.target.value, props.roomIndex, guestType, guestIndex, 'lastName')}
                                        hasError={form.lastName.hasError && form.lastName.errorMsg !== ''}
                                        errorMsg={form.lastName.errorMsg}/>
                                </div>
                            </div>
                            <div className="u-mb-24">
                                <div className="label">Date of Birth</div>
                                <div className="u-display-flex u-align-items-center">
                                    <div className="u-flex-5 u-mr-8">
                                        <Select 
                                            placeholder="Day"
                                            value={form.day.value}
                                            options={form.day.options}
                                            changed={(val) => props.guestInputChanged(val, props.roomIndex, guestType, guestIndex, 'day')} />
                                    </div> 
                                    <div className="u-flex-5 u-mr-8">
                                        <Select 
                                            placeholder="Month"
                                            value={form.month.value}
                                            options={form.month.options}
                                            changed={(val) => props.guestInputChanged(val, props.roomIndex, guestType, guestIndex, 'month')}  />
                                    </div> 
                                    <div className="u-flex-5 u-mr-16">
                                        <Select 
                                            placeholder="Year"
                                            value={form.year.value}
                                            options={form.year.options}
                                            changed={(val) => props.guestInputChanged(val, props.roomIndex, guestType, guestIndex, 'year')}  />
                                    </div>
                                    <div className="u-flex-10">
                                        <CheckBox 
                                            label="Sudah memiliki Visa"
                                            checked={form.isIncludeVisa.value}
                                            changed={(event) => props.guestInputChanged(event.target.checked, props.roomIndex, guestType, guestIndex, 'isIncludeVisa')} />
                                    </div>
                                </div>
                            </div>
                            <div className="u-display-flex u-mb-24">
                                <div className="u-flex-2 u-mr-16">
                                    <div className="label">Country Code</div>
                                    <SelectFlag 
                                        type="country"
                                        value={form.countryCode.value}
                                        changed={(val) => props.guestInputChanged(val, props.roomIndex, guestType, guestIndex, 'countryCode')}
                                        hasError={form.countryCode.hasError && form.countryCode.errorMsg !== ''}
                                        errorMsg={form.countryCode.errorMsg}/>
                                </div>
                                <div className="u-flex-10">
                                    <div className="label">Passport Number</div>
                                    <TextField
                                        placeholder="e.g. 874654875" 
                                        value={form.passportNumber.value} 
                                        changed={(event) => props.guestInputChanged(event.target.value, props.roomIndex, guestType, guestIndex, 'passportNumber')}
                                        hasError={form.passportNumber.hasError && form.passportNumber.errorMsg !== ''}
                                        errorMsg={form.passportNumber.errorMsg}/>
                                </div>
                            </div>
                            <div className="u-mb-24">
                                <div className="label">Expired Date</div>
                                <div className="u-display-flex u-justify-content-start">
                                    <div className="u-flex-2 u-mr-8">
                                        <Select 
                                            placeholder="Day"
                                            value={form.dayPassport.value}
                                            options={form.dayPassport.options}
                                            changed={(val) => props.guestInputChanged(val, props.roomIndex, guestType, guestIndex, 'dayPassport')} />
                                    </div> 
                                    <div className="u-flex-2 u-mr-8">
                                        <Select 
                                            placeholder="Month"
                                            value={form.monthPassport.value}
                                            options={form.monthPassport.options}
                                            changed={(val) => props.guestInputChanged(val, props.roomIndex, guestType, guestIndex, 'monthPassport')}  />
                                    </div> 
                                    <div className="u-flex-2 u-mr-16">
                                        <Select 
                                            placeholder="Year"
                                            value={form.yearPassport.value}
                                            options={form.yearPassport.options}
                                            changed={(val) => props.guestInputChanged(val, props.roomIndex, guestType, guestIndex, 'yearPassport')}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
            if (guestType === 'adult') {
                adultForm = guestForm;
            } else if (guestType === 'child'){
                childForm = guestForm;
            }
        }
    });
    
    return (
        <PanelBox isOverflow={false}>
            <div className={classes.Wrapper}>
                <div className={classes.Title}>Room {props.roomIndex + 1}</div>
                {adultForm}
                {childForm}
            </div>
        </PanelBox>
    );
}

export default guestInfo;