import React from 'react';
import classes from './Visitor.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import { AGE_RANGE_ADMISSION } from '../../../../../../constant/General';
import { toCapitalize, currencyFormat } from '../../../../../../helper/GlobalFunc';
import PlusMinusButton from '../../../../../UI/PlusMinusButton/PlusMinusButton';

const visitor = (props) => {
    if (props.visitors) {
        let visitor = props.visitors.map((item, index) => {
            let ageRange = AGE_RANGE_ADMISSION[item.type];
            let priceType = 'All age';
            let age = null;
            let disabledMinusRules = (item.qty <= 0) ? true : false
            if (item.type !== 'all-price') {
                priceType = item.type;
                age = '( ' + ageRange + ' )';
            }
            
            if (item.type === 'all-price' || item.type === 'adult') {
                disabledMinusRules = (item.qty <= 1) ? true : false
            }
            return (
                <div className={classes.Item} key={index}>
                    <div className={classes.Left}>
                        <label>{toCapitalize(priceType)} {age}</label>
                        <div className={classes.Price}>{currencyFormat(item.price)}</div>
                    </div>
                    <div className={classes.Right}>
                        <div>
                            <PlusMinusButton
                                isPlus={false}
                                disabled={disabledMinusRules}
                                clicked={() => props.onGuestRemoveClicked(item.type)} />
                        </div>
                        <div className={classes.Count}>{item.qty}</div>
                        <div>
                            <PlusMinusButton
                                isPlus={true}
                                clicked={() => props.onGuestAddedClicked(item.type)}/>
                        </div>
                    </div>
                </div>
            )

        })
        return (
            <div className={classes.Wrapper}>
                <div className={classes.Title}>Add Visitor</div>
                <PanelBox>
                    <div className={classes.Content}>
                        {visitor}
                    </div>
                </PanelBox>
            </div>
        )
    } else {
        return null;
    }
}

export default visitor;
