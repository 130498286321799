import React from 'react';
import classes from './Button.module.scss';

const button = (props) => {
    let btnClasses = [classes.Button];
    let child = props.children;
    let isDisabled = props.disabled;
    if (props.classes && props.classes.length > 0) {
        props.classes.forEach(val => {
            btnClasses.push(classes[val]);
        });
    }
    if (props.isLoading) {
        child = <div className={classes.Loader}></div>
        isDisabled = true;
    }
    return (
        <button
            className={btnClasses.join(' ')}
            onClick={props.clicked}
            disabled={isDisabled}>{child}</button>
    );
}

export default button;