import React, { useState, useEffect, useRef } from 'react';
import classes from './SearchContainer.module.scss';
import Destination from '../../../../components/Mobile/Tour/Destination/Destination';
import SearchResult from '../../../../components/Mobile/Tour/SearchResult/SearchResult';
import axios from '../../../../axios';
import { ANTAVAYA_API } from '../../../../constant/Api';
import NoResult from '../../../../components/Mobile/Shared/NoResult/NoResult';
import history from '../../../../history';
import Header from '../../../../components/Mobile/Shared/Header/Header';
import Icon from '../../../../components/UI/Icon/Icon';

const searchContainer = props => {
    const [searchInput, setSearchInput] = useState('');
    const [regionList, setRegionList] = useState([]);
    const [destinationList, setDestinationList] = useState([]);
    const [tourPackageList, setTourPackageList] = useState([]);
    const inputEl = useRef(null);

    useEffect(() => {
        inputEl.current.focus();
        getDestination();
    }, []);

    const getDestination = () => {
        axios.get(ANTAVAYA_API + 'v1/tour/destinations')
            .then( response => {
                setDestinationList(response.data)
            })
            .catch( error => {
                console.log(error)
            });
    }

    const backClickHandler = () => {
        history.goBack();
    }
    
    const searchChangeHandler = (event) => {
        if (event.target.value !== '') {
            getRegionList(event.target.value);
        }
        setSearchInput(event.target.value);
    }

    const getRegionList = (keyword) => {
        axios.get(ANTAVAYA_API + 'v1/tour/quick-find?q=' + keyword)
            .then( response => {
                if (response.data) {
                    setRegionList(response.data.region.concat(response.data.country));
                    setTourPackageList(response.data.tour);
                }
            })
            .catch( error => {
                console.log(error)
            });
    }

    const destinationClickHandler = regionSelected => {
        props.history.push('/tour/availability?destination=' + regionSelected);
    }

    const tourPackageClickHandler = slug => {
        props.history.push('/tour/detail/' + slug);
    }

    let noResult = (searchInput !== '' && tourPackageList.length === 0) ? <div className="u-pt-16 u-mx-16"><NoResult keyword={searchInput} /></div> : null;
    let content = <div>
        {noResult}
        <Destination
            list={destinationList}
            destinationClicked={(region) => destinationClickHandler(region)} />
    </div>;
    if (searchInput !== '' && tourPackageList.length > 0) {
        content = <SearchResult 
                    regionList={regionList}
                    tourPackageList={tourPackageList}
                    destinationClicked={(region) => destinationClickHandler(region)}
                    tourPackageClicked={ (slug) => tourPackageClickHandler(slug)} />; 
    }

    const searchForm = <div className={classes.SearchForm}>
        <div className={classes.FirstIcon}>
            <Icon name="ico-search" width="16" stroke="none" fill="#202124" />
        </div>
        <input 
            ref={inputEl}
            value={props.value}
            onChange={(event) => searchChangeHandler(event)} 
            className={classes.Input} 
            placeholder="Search for destinations"/>
    </div>;
        
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Header}>
                <Header classes={['ClBlack', 'Shadow']} 
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    content={searchForm}/>
            </div>
            
            <div className={classes.Content}>
                {content}
            </div>
        </div>     
    );
}

export default searchContainer;