import React, { useEffect } from 'react';
import classes from './VoucherPackage.module.scss';
import Slider from 'react-slick';
import ContentLoader from 'react-content-loader';
import Icon from '../../../UI/Icon/Icon';
import Item from './Item/Item';

import { connect } from 'react-redux';
import * as availabilityAction from '../../../../redux/Voucher/Availability/AvailabilityAction';

const voucherPackage = (props) => {
    useEffect(() => {
        props.fetchVoucher()
    }, [])

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: false,
        autoplaySpeed: 2500,
        dotsClass: "slick-dots " + classes.DotWrapper,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        touchMove:true,
        draggable: false,
        customPaging: function(i) {
            return (
                <div className="banner-product"></div>
            );
        },
    };

    let content = [<Loading key={1}/>, <Loading key={2}/>];
    if (!props.isFetchVoucher) {
        content = props.vouchers.map((item, index) => {
            return <div key={index} className={classes.Item}>
                    <Item data={item}/>
                </div>;
        });
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className={classes.Title}>Antavaya Voucher</div>
                <div className={classes.List}>
                    <Slider {...settings}>
                        {content}
                    </Slider>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        vouchers : state.VoucherAvailabilityReducer.vouchers,
        isFetchVoucher : state.VoucherAvailabilityReducer.isFetchVoucher,
        isErrorFetchVoucher : state.VoucherAvailabilityReducer.isErrorFetchVoucher,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchVoucher : () => dispatch(availabilityAction.fetchVoucher())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(voucherPackage);

function Loading() {
    let content = <div className={classes.LoadingWrap}>
        <ContentLoader
            height={545}
            speed={1}
            primaryColor="#e6e6e6">
            <rect x="20" y="20" rx="20" ry="20" width="360" height="330" />

            <rect x="30" y="390" rx="8" ry="8" width="300" height="30" />
            <rect x="30" y="450" rx="8" ry="8" width="170" height="30" />

        </ContentLoader>
    </div>
    return content;
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
			className={[classes.Arrow, classes.PrevArrow].join(' ')}
			onClick={onClick}>
				<Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
        </div>
    );
}

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
			className={[classes.Arrow, classes.NextArrow].join(' ')}
			onClick={onClick}>
                <Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
        </div>
    );
}