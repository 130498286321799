import React, { useEffect } from 'react';
import classes from './ContentAvailability.module.scss';
import Breadcrumb from '../../../../components/Desktop/Shared/Breadcrumb/Breadcrumb';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import Icon from '../../../UI/Icon/Icon';
import Button from '../../../UI/Button/Button';
import { formatDisplayDate } from '../../../../helper/GlobalFunc';
import { withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { DEFAULT_IMG } from '../../../../constant/Content';
import MessageBox from '../../Shared/MessageBox/MessageBox';

import { connect } from 'react-redux';
import * as availabilityVoucher from '../../../../redux/Voucher/Availability/AvailabilityAction';

const contentAvailability = (props) => {

    useEffect(() => {
        props.fetchVoucher()
    }, [])

    const goToDetail = (slug) => {
        props.history.push('/voucher/booking/' + slug)
    }

    const goBack = () => {
        props.history.push('/');
    }

    const breadcrumbLink = [{
        name: 'Home',
        url: '/'
    }, {
        name: 'Voucher',
        url: '/voucher/availability'
    }];

    let voucher = null;
    if (props.isFetchVoucher) {
        voucher = <Loader />;
    } else {
        if (props.isErrorFetchVoucher) {
            voucher = <MessageBox
                title="Voucher Empty"
                subTitle="Sorry voucher is unavailable Please see other vouchers"
                img="art-hotel-empty"
                labelBtn="Back to Home"
                clicked={() => goBack()} />
        } else {
            if (props.vouchers.length === 0) {
                voucher = <MessageBox
                    title="Voucher Empty"
                    subTitle="Sorry voucher is unavailable Please see other vouchers"
                    img="art-hotel-empty"
                    labelBtn="Back to Home"
                    clicked={() => goBack()} />
            } else {
                voucher = props.vouchers.map((item, index) => {
                    let benefits = null;
                    if (item.benefit) {
                        benefits = item.benefit.map((benefitItem, benefitKey) => {
                            let benefitClasses = [classes.Benefit];
                            let icon = 'ico-close-circle';
                            let color = '#E6E6E6';
                            let size = 19;
                            if (benefitItem.status) {
                                icon = 'ico-check-circle';
                                color = '#73C700';
                                benefitClasses.push(classes.Active);
                                size = 16;
                            }

                            return (
                                <div key={benefitKey} className={benefitClasses.join(' ')}>
                                    <div className={classes.Icon}>
                                        <Icon name={icon} fill={color} stroke="none" width={size} />
                                    </div>
                                    <div className={classes.Text}>
                                        {benefitItem.title}
                                    </div>
                                </div>
                            );
                        });
                    }
                    return (
                        <div className={classes.Item} key={index}>
                            <PanelBox isOverflow={false}>
                                <div className={classes.ItemBox}>
                                    <div className={classes.Image}>
                                        <img onError={DEFAULT_IMG} src={item.imageSource} alt="voucher" />
                                    </div>
                                    <div className={classes.Body}>
                                        {benefits}
                                    </div>
                                    <div className={classes.Footer}>
                                        <div className={classes.ItemDate}>
                                            <div className={classes.Label}>
                                                Expired
                                            </div>
                                            <div className={classes.Date}>
                                                <Icon name="ico-calendar" fill="#666666" stroke="none" width={20} />
                                                <div className={classes.Text}>
                                                    {formatDisplayDate(item.expiredDate)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.ItemBtn}>
                                            <Button
                                                classes={['BtnSmall', 'BtnBlock', 'BtnRed']}
                                                clicked={() => goToDetail(item.slug)}>
                                                Buy Now
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </PanelBox>
                        </div>
                    )
                });
            }
        }
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <Breadcrumb linkList={breadcrumbLink} />
                <div className={classes.Content}>
                    <div className={classes.ItemWrapper}>
                        {voucher}
                    </div>
                    <div className={classes.Clear}></div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        vouchers: state.VoucherAvailabilityReducer.vouchers,
        isFetchVoucher: state.VoucherAvailabilityReducer.isFetchVoucher,
        isErrorFetchVoucher: state.VoucherAvailabilityReducer.isErrorFetchVoucher
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchVoucher: () => dispatch(availabilityVoucher.fetchVoucher())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(contentAvailability));


const Loader = () => {
    let loader = [];
    for (let i = 0; i < 6; i++) {
        loader.push(
            <div className={classes.Item} key={i}>
                <PanelBox>
                    <div className={classes.ItemBox}>
                        <div className="u-p-10">
                            <ContentLoader
                                height={445}
                                speed={1}
                                primaryColor={'#e6e6e6'}>
                                <rect x="0" y="0" rx="12" ry="12" width="400" height="200" />

                                <rect x="0" y="225" rx="3" ry="3" width="150" height="20" />
                                <rect x="0" y="260" rx="3" ry="3" width="250" height="23" />
                                <rect x="0" y="305" rx="3" ry="3" width="150" height="20" />

                                <rect x="0" y="370" rx="3" ry="3" width="100" height="20" />
                                <rect x="0" y="405" rx="3" ry="3" width="180" height="30" />

                                <rect x="220" y="385" rx="3" ry="3" width="180" height="50" />
                            </ContentLoader>
                        </div>
                    </div>
                </PanelBox>
            </div>
        )

    }
    return loader;

}