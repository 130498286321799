import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import * as memberAction from '../../../../redux/Shared/Member/MemberAction';
import classes from './ActivationEmailContainer.module.scss';

function activationContainer (props) {
    useEffect(() => {
        let payload = {
            activationCode: props.match.params.activationCode,
            ipAddress: '127.0.0.1'
        }
        props.activationHandler(payload, props.history)
    }, []);

    // useEffect(() => {
    //     if (localStorage.getItem('sessionKey') && localStorage.getItem('userProfile')) {
    //         props.history.push('/')
    //     }
    // }, [props.isLogin])

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Content}>
                <div className={classes.Loader}></div>
                <div className={classes.Text}>Please wait...</div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogin: state.AuthReducer.isLogin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        activationHandler: (activationCode, history) => dispatch(memberAction.verificationEmail(activationCode, history))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(activationContainer);
