import React, { useState, useEffect } from 'react';
import classes from './ContentAvailability.module.scss';
import Breadcrumb from '../../Shared/Breadcrumb/Breadcrumb';
import Filter from './Filter/Filter';
import Sort from './Sort/Sort';
import { TOUR_PRICE_FILTER_LIST } from '../../../../constant/FilterList';
import { ADMISSION_SORT_LIST } from '../../../../constant/SortList';
import TourList from './TourList/TourList';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../redux/Admission/Availability/AvailabilityAction';

const contentAvailability = (props) => {
    const [priceFilterList, setPriceFilterList] = useState([]);

    useEffect(() => {
        props.initAdmissionExperiencePackages();
    }, [])

    useEffect(() => {
        setFilterListHandler();
    }, [props.categoryFilters]);

    const breadcrumbLink = [{
        name: 'Home',
        url: '/'
    },{
        name: 'Things To Do',
        url: '/things-to-do/availability'
    }];

    const filterChangeHandler = (event, index, filterType) => {
        let value = event.target.checked;
        let filterList = null;
        if (filterType === 'price') {
            filterList = [...priceFilterList];
            filterList[index].isChecked = value;
            setPriceFilterList(filterList);
        }
    }

    const setFilterListHandler = () => {
        setPriceFilterListHandler();
    }

    const setPriceFilterListHandler = () => {
        const priceFilterList = TOUR_PRICE_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });
        setPriceFilterList(priceFilterList);
    }

    const tourFilter = (tourPackageList) => {
        const priceFiltersCp = priceFilterList.filter( filter => {
            return filter.isChecked;
        });

        let tourPackages = tourPackageList;
        if (priceFiltersCp.length > 0) {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let index in priceFiltersCp) {
                    let limit = priceFiltersCp[index].value.split(':');
                    let min = parseInt(limit[0]);
                    let max = parseInt(limit[1]);
                    if (min <= tourPackage.priceStartFrom && max >= tourPackage.priceStartFrom) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            });
        }

        return tourPackages;
    }

    let activitiesFoundTitle = null;
    if (props.admissionExperiencePackages && props.admissionExperiencePackages.length > 0) {
        activitiesFoundTitle = props.admissionExperiencePackages.length + ' activities found';
    }

    let tourPackageList = tourFilter(props.admissionExperiencePackages);

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <Breadcrumb linkList={breadcrumbLink}/>
                <div className={classes.Content}>
                    <div className={classes.Sidebar}>
                        <Filter 
                            priceFilterList={priceFilterList}
                            filterChanged={(event, index, filterType) => filterChangeHandler(event, index, filterType)}
                            resetPriceFilterClicked={setPriceFilterListHandler}
                            resetFilterClicked={setFilterListHandler} />
                    </div>
                    <div className={classes.Availability}>
                        <div className={classes.Header}>
                            <div className={classes.Title}>{activitiesFoundTitle}</div>
                            <div className={classes.Sort}>
                                <label>Sort by</label>
                                <Sort 
                                    defaultValue={props.sortBy}
                                    options={ADMISSION_SORT_LIST}
                                    setSelected={(value) => props.setSortBy(value)}/>
                            </div>
                        </div>
                        <div>
                            <TourList 
                                isFetch={props.isFetchAdmisionExperiences}
                                isErrorFetch={props.IsErrorFetchAdmisionExperiences}
                                tourPackageList={tourPackageList}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        categoryFilters: state.AdmissionAvailabilityReducer.categoryFilters,
        admissionExperiencePackages: state.AdmissionAvailabilityReducer.admissionExperiencePackages,
        isFetchAdmisionExperiences: state.AdmissionAvailabilityReducer.isFetchAdmisionExperiences,
        IsErrorFetchAdmisionExperiences: state.AdmissionAvailabilityReducer.IsErrorFetchAdmisionExperiences,
        sortBy : state.AdmissionAvailabilityReducer.sortBy
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initAdmissionExperiencePackages: () => dispatch(AvailabilityAction.initAdmissionExperiencePackages()),
        setSortBy: (value) => dispatch(AvailabilityAction.setSortByAdmissionExperience(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(contentAvailability);
