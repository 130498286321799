import React, { useEffect } from 'react';
import classes from './Participant.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Room from '../../../../Desktop/Tour/ContentDetail/RightContent/Participant/Room/Room';
import CurrencyFormat from 'react-currency-format';
import Button from '../../../../UI/Button/Button';
import CSSTransition from 'react-transition-group/CSSTransition';

import { connect } from 'react-redux';
import * as detailAction from '../../../../../redux/Tour/Detail/DetailAction';
import Header from '../../../Shared/Header/Header';

const Participant = props => {

    useEffect( () => {
        if (props.show) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    }, [props.show]);

    const participantChangeHandler = (guestType, index, action, room) => {
        let adult = room.adult.length;
        let child = room.child.length;
        let isExtrabed = room.isExtrabed;
        if (guestType === 'adult') {
            if (action === 'ADD') {
                adult++;
            } else if (action === 'REMOVE') {
                adult--;
            }
        } else {
            if (action === 'ADD') {
                child++;
            } else if (action === 'REMOVE'){
                child--;
            }
        }
        if (action === 'IS_EXTRABED') {
            isExtrabed = !isExtrabed;
        }
        let data = {
            adult : adult,
            child : child,
            isExtraBed : isExtrabed,
            region : props.region
        }
        props.participantChanged(guestType, index, action, data)
    }

    let rooms = null;
    if (props.rooms) {
        let isCloseBtn = false;
        if (props.rooms.length > 1) {
            isCloseBtn = true;   
        }
        rooms = props.rooms.map((room, index) => {
            return (
                <div key={index} className={classes.BoxItem}>
                    <PanelBox>
                        <Room
                            removeRoomClicked={() => props.onRoomRemoved(index)}
                            removeGuestClicked={(guestType) => participantChangeHandler(guestType, index, 'REMOVE', room)}
                            addGuestClicked={(guestType) => participantChangeHandler(guestType, index, 'ADD', room)}
                            index={index} 
                            roomData={room}
                            isCloseBtn={isCloseBtn}
                            toggleExtraBed={(event) => participantChangeHandler(event.target.checked, index, 'IS_EXTRABED', room)}/>
                    </PanelBox>
                </div>
            );
        });
    }

    return (
        <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={300}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Closed 
            }}>
            <div className={classes.Wrapper}>
                <div className={classes.Header}>
                    <Header 
                        classes={['Shadow']}
                        leftIcon="back"
                        leftClicked={props.hidePopupClicked}
                        content="Participants" />
                </div>
                <div className={classes.Content}>
                    <div>
                        {rooms}
                    </div>
                    <div className={classes.Action} onClick={props.onRoomAdded}>
                        <button className={classes.AddAct}>+ Add Room</button>
                    </div>
                </div>
                <div className={classes.Footer}>
                    <div className={classes.PriceWrapper}>
                        <div className={classes.Label}>Start From</div>
                        <div className={classes.Price}>
                            <CurrencyFormat value={props.price} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            <span className={classes.Perpax}>/Pax</span>
                        </div>
                    </div>
                    <div className={classes.Button}>
                        <Button 
                            classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                            clicked={props.submitBookingClicked}>
                            Book Now
                        </Button>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}

const mapStateToProps = state => {
    return {
        rooms : state.TourDetailReducer.rooms
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRoomAdded       : () => dispatch(detailAction.addRoom()),
        onRoomRemoved     : (index) => dispatch(detailAction.removeRoom(index)),
        onGuestAdded      : (guestType, index) => dispatch(detailAction.addGuest(guestType, index)),
        onGuestRemoved    : (guestType, index) => dispatch(detailAction.removeGuest(guestType, index)),
        onExtraBedChanged : (event, index) => dispatch(detailAction.toggleExtraBed(event, index)),
        participantChanged: (guestType, index, action, data) => dispatch(detailAction.participantChanged(guestType, index, action, data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Participant);