import React, { useState, useEffect } from 'react';
import classes from './MyBooking.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import BookingList from './BookingList/BookingList';
import BookingDetail from './BookingDetail/BookingDetail';
import { BOOKING_TAB, BOOKING_DETAIL_TAB, DEFAULT_BOOKING_TAB, DEFAULT_BOOKING_DETAIL_TAB } from '../../../../../constant/General';
import NotFound from './NotFound/NotFound';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as myBookingAction from '../../../../../redux/Shared/MyBooking/MyBookingAction';

const myBooking = props => {
    const [tourListSection, setTourListSection] = useState(DEFAULT_BOOKING_TAB);
    const [tourDetailSection, setTourDetailSection] = useState(DEFAULT_BOOKING_DETAIL_TAB);

    useEffect(() => {
        let bookingCode = props.match.params.bookingCode;
        if (bookingCode) {
            props.setMyBookingId(bookingCode);
        } else {
            props.initBookingList();
        }
    }, []);

    const bookingDetailClicked = (id) => {
        props.history.push('/user/my-booking/'+id);
        props.setMyBookingId(id);
    }

    const bookingBackClicked = () => {
        props.history.push('/user/my-booking/');
        props.setMyBookingId(null);
        props.setMyBookingDetail(null);
    }

    let content = <div>
        <div className={classes.Head}>
            <div className={classes.Title}>My Booking</div>
        </div>
        <ul className="ui-tab">
            {BOOKING_TAB.map((tab, index) => {
                return <li key={index} className={(tourListSection === tab.slug) ? 'tab-item active' : 'tab-item'} onClick={() => setTourListSection(tab.slug)}>{tab.label}</li>
            })}
        </ul>
        {(props.isErrorFetch) ? <NotFound /> : <BookingList activePage={tourListSection} bookingDetailClicked={(id) => bookingDetailClicked(id)} />}
    </div>;

    if (props.bookingId) {
        content = <div>
            <div className={classes.Head}>
                <div className={classes.BackBtn} onClick={bookingBackClicked}>
                    <Icon name="ico-arrow-left" fill="#1A6FA3" stroke="none" width={20} />
                    <span>My Booking</span>
                </div>

                {(props.bookingDetail && props.bookingDetail.pdfUrl) &&
                <div className={classes.Title}>
                    <a className={classes.DownloadBtn} href={(props.bookingDetail)? props.bookingDetail.pdfUrl: '#'} target="blank">
                        Download E-Ticket
                    </a>
                </div>}

            </div>
            <ul className="ui-tab">
                {BOOKING_DETAIL_TAB.map((tab, index) => {
                    return <li key={index} className={(tourDetailSection === tab.slug) ? 'tab-item active' : 'tab-item'}
                        onClick={() => setTourDetailSection(tab.slug)}>
                        {tab.label}
                    </li>
                })}
            </ul>
            <BookingDetail activePage={tourDetailSection}/>
        </div>
    }
    return (
        <div className={classes.Wrapper}>
            {content}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        bookingList: state.MyBookingReducer.bookingList,
        isFetch: state.MyBookingReducer.isFetch,
        isErrorFetch: state.MyBookingReducer.isErrorFetch,
        bookingId: state.MyBookingReducer.bookingId,
        bookingDetail: state.MyBookingReducer.bookingDetail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initBookingList: () => dispatch(myBookingAction.initBookingList()),
        setMyBookingId: (id) => dispatch(myBookingAction.setMyBookingId(id)),
        setMyBookingDetail: (data) => dispatch(myBookingAction.setMyBookingDetail(data))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(myBooking));