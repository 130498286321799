import React, { useEffect } from 'react';
import classes from './ContentAvailability.module.scss';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import Icon from '../../../UI/Icon/Icon';
import Button from '../../../UI/Button/Button';
import { formatDisplayDate, currencyFormat } from '../../../../helper/GlobalFunc';
import { withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { DEFAULT_IMG } from '../../../../constant/Content';
import Ribbon from '../../../UI/Ribbon/Ribbon';
import MessageBox from '../../Shared/MessageBox/MessageBox';

import { connect } from 'react-redux';
import * as availabilityHotel from '../../../../redux/Hotel/Availability/AvailabilityAction';

const contentAvailability = (props) => {

    useEffect(() => {
        props.fetchHotel()
    }, [])

    const goToDetail = (slug) => {
        props.history.push('/hotel/booking/'+ slug)
    }

    const goBack = () => {
        props.history.push('/');
    }

    let content = null;
    if (props.isFetchHotel) {
        content = (
            <React.Fragment>
                <div className={classes.Content}>
                    <div className={classes.ItemWrapper}>
                        <Loader/>
                    </div>
                    <div className={classes.Clear}></div>
                </div>
            </React.Fragment>
        ) 
    } else {
        if (props.isErrorFetchHotel) {
            content = <MessageBox
                title="Hotel Empty"
                subTitle="Sorry hotel is unavailable Please see other hotels"
                img="art-hotel-empty"
                labelBtn="Back to Home"
                clicked={() => goBack()}/>
        } else {
            if (props.hotels.length === 0) {
                content = <MessageBox
                    title="Hotel Empty"
                    subTitle="Sorry hotel is unavailable Please see other hotels"
                    img="art-hotel-empty"
                    labelBtn="Back to Home"
                    clicked={() => goBack()}/>
            } else {
                let hotel = props.hotels.map((item, index) => {
                    return (
                        <div className={classes.Item} key={index}>
                            <PanelBox>
                                <div className={classes.ItemBox}>
                                    <div className={classes.RibbonWrapper}>
                                        <Ribbon text="free voucher tour" position="left" />
                                    </div>
                                    <div className={classes.Image}>
                                        <img onError={DEFAULT_IMG} src={item.imageSource} alt="bird"/>
                                    </div>
                                    <div className={classes.Body}>
                                        <div className={classes.Date}>
                                            <div className={classes.Label}>Booking Period</div>
                                            <div className={classes.Detail}>
                                                <div className={classes.Icon}>
                                                    <Icon name="ico-calendar" fill="#666666" stroke="none" width={20} />
                                                </div>
                                                {formatDisplayDate(item.bookingPeriodStartDate)} - {formatDisplayDate(item.bookingPeriodEndDate)}
                                            </div>
                                        </div>
                                        <div className={classes.Date}>
                                            <div className={classes.Label}>Stay Period</div>
                                            <div className={classes.Detail}>
                                                <div className={classes.Icon}>
                                                    <Icon name="ico-calendar" fill="#666666" stroke="none" width={20} />
                                                </div>
                                                {formatDisplayDate(item.stayPeriodStartDate)} - {formatDisplayDate(item.stayPeriodEndDate)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.Footer}>
                                        <div className={classes.ItemPrice}>
                                            <div className={classes.Label}>
                                                Start From
                                            </div>
                                            <div className={classes.Price}>
                                                {currencyFormat(item.hotelPrice)}
                                            </div>
                                        </div>
                                        <div className={classes.ItemBtn}>
                                            <Button
                                                classes={['BtnSmall', 'BtnBlock', 'BtnRed']}
                                                clicked={() => goToDetail(item.slug)}>
                                                Book Now
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </PanelBox>
                        </div>
                    )
                });

                content = (
                    <React.Fragment>
                        <div className={classes.Content}>
                            <div className={classes.ItemWrapper}>
                                {hotel}
                            </div>
                            <div className={classes.Clear}></div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                {content}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        hotels: state.HotelAvailabilityReducer.hotels,
        isFetchHotel: state.HotelAvailabilityReducer.isFetchHotel,
        isErrorFetchHotel: state.HotelAvailabilityReducer.isErrorFetchHotel
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchHotel: () => dispatch(availabilityHotel.fetchHotel())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(contentAvailability));


const Loader = () => {
    let loader = [];
    for (let i = 0; i < 3; i++) {
        loader.push(
            <div className={classes.Item} key={i}>
                <PanelBox>
                    <div className={classes.ItemBox}>
                        <div className="u-pl-10 u-pr-10">
                            <ContentLoader
                                height={675}
                                speed={1}
                                primaryColor={'#e6e6e6'}>
                                <rect x="0" y="0" rx="12" ry="12" width="400" height="400" />

                                <rect x="0" y="425" rx="3" ry="3" width="150" height="20" />
                                <rect x="0" y="460" rx="3" ry="3" width="250" height="23" />

                                <rect x="0" y="505" rx="3" ry="3" width="150" height="20" />
                                <rect x="0" y="540" rx="3" ry="3" width="250" height="23" />

                                <rect x="0" y="600" rx="3" ry="3" width="100" height="20" />
                                <rect x="0" y="635" rx="3" ry="3" width="180" height="30" />

                                <rect x="220" y="618" rx="3" ry="3" width="180" height="50" />
                            </ContentLoader>
                        </div>
                    </div>
                </PanelBox>
            </div>
        )
        
    }
    return loader;
        
}