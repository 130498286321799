import React, { Component } from 'react';
import classes from './AboutUs.module.scss';
import Intro from './Intro/Intro';
import Journey from './Journey/Journey';
import VisiMisi from './VisiMisi/VisiMisi';
import ProductService from './ProductService/ProductService';

class AboutUs extends Component {
    state = {  }
    render() {
        return (
            <div className={classes.Wrapper}>
                <Intro />
                <Journey />
                <VisiMisi />
                <ProductService />
            </div>
        );
    }
}

export default AboutUs;