import React from 'react';
import classes from './TourFacility.module.scss';
import Icon from '../../../../../UI/Icon/Icon';
import Moment from 'react-moment';
import { pluralize } from '../../../../../../helper/GlobalFunc';

const tourFacility = (props) => {
    let room = 0;
    let adult = 0;
    let child = 0;
    let airline = '';

    if (props.bookingData.room) {
        room = props.bookingData.room.length;
        for (const index in props.bookingData.room) {
            if (props.bookingData.room[index].adult) {
                adult += props.bookingData.room[index].adult;
            }
            if (props.bookingData.room[index].child) {
                child += props.bookingData.room[index].child;
            }
            if (props.bookingData.airlineName !== '') {
                airline = (
                <div className={classes.Item}>
                    <Icon name="ico-flight-o" fill="#6A6E73" stroke="none" />
                    <span className={classes.Label}>{props.bookingData.airlineName}</span>
                </div>
                )
            }
        }
    }

    return (
        <div className={classes.Wrapper}>
            {airline}
            <div className={classes.Item}>
                <Icon name="ico-user-o" fill="#6A6E73" stroke="none" />
                <span className={classes.Label}>{pluralize(room, 'Room', 'Rooms')} - {pluralize(adult, 'Adult', 'Adults')} - {pluralize(child, 'Child', 'Childs')}</span>
            </div>
            <div className={classes.Item}>
                <Icon name="ico-calendar" fill="#6A6E73" stroke="none" />
                <span className={classes.Label}>
                    <Moment format="ddd, DD MMM YYYY">
                        {props.bookingData.startDate}
                    </Moment>
                    <span> - </span>
                    <Moment format="ddd, DD MMM YYYY">
                        {props.bookingData.endDate}
                    </Moment>
                </span>
            </div>
        </div>
    );
}

export default tourFacility;