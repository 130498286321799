import React from 'react';
import classes from './SuccessBook.module.scss';
import img from '../../../../../assets/images/art-payment-complete.png';
import Button from '../../../../UI/Button/Button';

const notFound = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Content}>
                <img src={img} className={classes.Image} alt="booking not found" />
                <div className={classes.Title}>Thank You</div>
                <div className={classes.SubTitle}>Your request is submitted we will call you back within 24hrs.</div>
                <div className={classes.Action}>
                    <Button 
                        classes={['BtnRed', 'BtnMedium']}
                        clicked={props.clicked}>
                        Back to Home
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default notFound;