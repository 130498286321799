import React from 'react';
import classes from './CardListLoaderMobile.module.scss';
import ContentLoader from 'react-content-loader';

const cardListLoaderMobile = () => {
    return (
        <div className={classes.Wrapper}>
            <ContentLoader
                height={545}
                speed={1}
                primaryColor={'#e6e6e6'}
            >
                <rect x="30" y="43" rx="20" ry="20" width="100" height="42" />

                <rect x="30" y="230" rx="8" ry="8" width="300" height="30" />
                <rect x="30" y="280" rx="8" ry="8" width="170" height="30" />

                <rect x="30" y="345" rx="8" ry="8" width="98" height="30" />
                <rect x="150" y="345" rx="8" ry="8" width="100" height="30" />

                <rect x="30" y="410" rx="8" ry="8" width="150" height="20" />
                <rect x="30" y="445" rx="8" ry="8" width="200" height="35" />
            </ContentLoader>
        </div>
    )
}

export default cardListLoaderMobile;