import Cookies from 'universal-cookie';

class Auth {
    constructor() {
        this.cookies = new Cookies();
    }

    login(cb) {
        cb();
        return true;
    }

    logout(cb) {
        this.cookies.remove('token', { path: '/' });
        cb();
    }

    isAuthenticated() {
        const session = localStorage.getItem('sessionKey');
        if (session) {
            return true;
        } else {
            return false;
        }
    }
}

export default new Auth();