import React, { useState, useEffect } from 'react';
import classes from './ContentAvailability.module.scss';
import Breadcrumb from '../../Shared/Breadcrumb/Breadcrumb';
import { TOUR_PRICE_FILTER_LIST, TOUR_DURATION_FILTER_LIST } from '../../../../constant/FilterList';
import TourList from './TourList/TourList';
import Filter from './Filter/Filter';
import Sort from './Sort/Sort';
import { TOUR_SORT_LIST } from '../../../../constant/SortList';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../redux/Admission/Availability/AvailabilityAction';

const contentAvailability = (props) => {
    const [priceFilterList, setPriceFilterList] = useState([]);
    const [durationFilterList, setDurationFilterList] = useState([]);
    const [categoryFilterList, setCategoryFilterList] = useState([]);

    const breadcrumbLink = [{
        name: 'Home',
        url: '/'
    },{
        name: 'Promoted',
    }];

    useEffect(() => {
        props.initExperiencePackages(props.slug);
    }, [])

    useEffect(() => {
        setFilterListHandler();
    }, [props.categoryFilters]);

    const setFilterListHandler = () => {
        setPriceFilterListHandler();
        setDurationFilterListHandler();
        setCategoryFilterListHandler();
    }

    const setPriceFilterListHandler = () => {
        const priceFilterList = TOUR_PRICE_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });
        setPriceFilterList(priceFilterList);
    }

    const setDurationFilterListHandler = () => {
        const durationFilterList = TOUR_DURATION_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });
        setDurationFilterList(durationFilterList);
    }

    const setCategoryFilterListHandler = () => {
        const categoryFilterList = props.categoryFilters.map( item => {
            return {...item, isChecked: false};
        });
        setCategoryFilterList(categoryFilterList);
    }

    const filterChangeHandler = (event, index, filterType) => {
        let value = event.target.checked;
        let filterList = null;
        if (filterType === 'price') {
            filterList = [...priceFilterList];
            filterList[index].isChecked = value;
            setPriceFilterList(filterList);
        }
        if (filterType === 'duration') {
            filterList = [...durationFilterList];
            filterList[index].isChecked = value;
            setDurationFilterList(filterList);
        }
        if (filterType === 'category') {
            filterList = [...categoryFilterList];
            filterList[index].isChecked = value;
            setCategoryFilterList(filterList);
        }
    }

    const tourFilter = (tourPackageList) => {
        const priceFiltersCp = priceFilterList.filter( filter => {
            return filter.isChecked;
        });
        const durationFiltersCp = durationFilterList.filter( filter => {
            return filter.isChecked;
        });
        const categoryFiltersCp = categoryFilterList.filter( filter => {
            return filter.isChecked;
        });

        let tourPackages = tourPackageList;
        if (priceFiltersCp.length > 0) {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let index in priceFiltersCp) {
                    let limit = priceFiltersCp[index].value.split(':');
                    let min = parseInt(limit[0]);
                    let max = parseInt(limit[1]);
                    if (min <= tourPackage.priceStartFrom && max >= tourPackage.priceStartFrom) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            });
        }
        
        if (durationFiltersCp.length > 0) {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let durationIndex in durationFiltersCp) {
                    let limit = durationFiltersCp[durationIndex].value.split(':');
                    let min = parseInt(limit[0]);
                    let max = parseInt(limit[1]);
                    if (isNaN(max)) {
                        if (min <= tourPackage.days) {
                            isValid = true;
                            break;
                        }
                    } else {
                        if (min <= tourPackage.days && max >= tourPackage.days) {
                            isValid = true;
                            break;
                        }
                    }
                }
                return isValid;
            });
        }

        if (categoryFiltersCp.length > 0) {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let index in categoryFiltersCp) {
                    let value = categoryFiltersCp[index].value;
                    if (value === tourPackage.typeName) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            });
        }

        return tourPackages;
    }
    
    let tourPackageList = tourFilter(props.experiencePackages);

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <Breadcrumb linkList={breadcrumbLink}/>

                <div className={classes.Content}>
                    <div className={classes.Sidebar}>
                        <Filter 
                            priceFilterList={priceFilterList}
                            durationFilterList={durationFilterList}
                            categoryFilterList={categoryFilterList}
                            filterChanged={(event, index, filterType) => filterChangeHandler(event, index, filterType)}
                            resetPriceFilterClicked={setPriceFilterListHandler}
                            resetDurationFilterClicked={setDurationFilterListHandler}
                            resetCategoryFilterClicked={setCategoryFilterListHandler}
                            resetFilterClicked={setFilterListHandler} />
                    </div>
                    <div className={classes.Availability}>
                        <div className={classes.Header}>
                            <div className={classes.Title}>Title</div>
                            <div className={classes.Sort}>
                                <label>Sort by</label>
                                <Sort
                                    defaultValue={props.sortBy}
                                    options={TOUR_SORT_LIST}
                                    setSelected={(value) => props.setSortBy(value)}/>
                            </div>
                        </div>
                        <div>
                            <TourList 
                                isFetch={props.isFetchExperience}
                                isErrorFetch={props.isErrorFetchExperience}
                                tourPackageList={tourPackageList}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        categoryFilters: state.AdmissionAvailabilityReducer.categoryFilters,
        experiencePackages: state.AdmissionAvailabilityReducer.experiencePackages,
        isFetchExperience: state.AdmissionAvailabilityReducer.isFetchExperience,
        isErrorFetchExperience: state.AdmissionAvailabilityReducer.isErrorFetchExperience,
        sortBy : state.AdmissionAvailabilityReducer.sortBy
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initExperiencePackages: (slug) => dispatch(AvailabilityAction.initExperiencePackagesByExperienceType(slug)),
        setSortBy: (value) => dispatch(AvailabilityAction.setSortBy(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(contentAvailability);
