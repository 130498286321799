import React from 'react';
import classes from './ProductService.module.scss';
import Slider from 'react-slick';
import Icon from '../../../../UI/Icon/Icon';
import ProductServiceItem from './ProductServiceItem/ProductServiceItem';
import { PRODUCT_SERVICES } from '../../../../../constant/Content';

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={[classes.Arrow, classes.PrevArrow].join(' ')}
        onClick={onClick}
      >
        <Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
      </div>
    );
  }
  
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={[classes.Arrow, classes.NextArrow].join(' ')}
        onClick={onClick}>
        <Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
      </div>
    );
  }

const productService = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: true,
        autoplay: false,
        autoplaySpeed: 2500,
        dotsClass: "slick-dots " + classes.DotWrapper,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        customPaging: function(i) {
            return (
              <div className="banner-product"></div>
            );
        },
    };
    const services = PRODUCT_SERVICES.map((service, index) => {
        return (
            <div className={classes.Item} key={index}>
                <ProductServiceItem service={service} />
            </div>
        );
    });
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className="u-text-center u-mb-60">
                    <div className={classes.Title}>Product And Services</div>
                </div>
                <div className={classes.List}>
                    <Slider {...settings}>
                        {services}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default productService;