import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';
import { DEVICE } from '../../../constant/General';
import { toast } from 'react-toastify';

export const showLoginRegisterForm = (showForm) => {
    return {
        type: 'SHOW_LOGIN_REGISTER_FORM',
        showForm
    }
}

export const loginSuccess = (statusText, sessionKey, dataMember) => {
    return {
        type: 'LOGIN_SUCCESS',
        payload: { statusText, sessionKey, dataMember }
    }
}

export const loginError = (statusText) => {
    return {
        type: 'LOGIN_ERROR',
        payload: { statusText }
    }
}

export const logoutSuccess = (statusText) => {
    return {
        type: 'LOGOUT_SUCCESS',
        payload: { statusText }
    }
}

export const logoutError = (statusText) => {
    return {
        type: 'LOGOUT_ERROR',
        payload: { statusText }
    }
}

export const submitLogin = (data) => {
    return dispatch => {
        data = {...data, ...DEVICE};
        axios.post(ANTAVAYA_API + 'auth/login', data)
            .then((response) => {
                dispatch(loginSuccess(response.data.message, response.data.data.sessionKey, response.data.data.dataMember))
            })
            .catch((error) => {
                if (error.response) {
                    switch (typeof error.response.data.message) {
                        case 'string':
                            toast.error(error.response.data.message);
                            break;
                        case 'object':
                            for (const key in error.response.data.message) {
                                toast.error(error.response.data.message[key][0]);
                            }
                            break;
                        default:
                            break;
                    }
                }
                dispatch(loginError("Error Login"));
            });
    }
}

export const logout = () => {
    return dispatch => {
        axios.post(ANTAVAYA_API + 'auth/logout', DEVICE, {
            headers: {
                'sessionKey': localStorage.getItem('sessionKey')
            }
        })
        .then((response) => {
            dispatch(logoutSuccess(response.data.message));
        })
        .catch((error) => {
            dispatch(logoutError());
        });
    }
}

export const inputChangeHandler = (event, inputIdentifier) => {
    return {
        type : 'LOGIN_INPUT_CHANGED',
        payload : {
            value: event.target.value,
            inputIdentifier
        } 
    }
}

export const rememberMeChangeHandler = (event) => {
    return {
        type : 'LOGIN_REMEMBER_ME_CHANGED',
        payload : {
            value: event.target.checked
        } 
    }
}

export const checkFormHandler = () => {
    return {
        type : 'LOGIN_CHECK_FORM',
        payload : {} 
    }
}

export const checkToken = () => {
    return {
        type : 'LOGIN_CHECK_TOKEN',
        payload : {} 
    }
}

export const activationSuccess = (sessionKey, dataMember) => {
    return {
        type: 'ACTIVATION_SUCCESS',
        payload: {
            sessionKey,
            dataMember
        }
    }
}

export const activationAccount = (activationCode) => {
    return dispatch => {
        axios.post(ANTAVAYA_API + 'member/activate', {activationCode})
            .then((response) => {
                if (response.data) {
                    let sessionKey = response.data.data.sessionKey;
                    let dataMember = response.data.data.dataMember;
                    let message = response.data.message;
                    toast.success(message)
                    dispatch(activationSuccess(sessionKey, dataMember))
                }
            })
            .catch((error) => {
                switch (typeof error.response.data.message) {
                    case 'string':
                        toast.error(error.response.data.message);
                        break;
                    case 'object':
                        for (const key in error.response.data.message) {
                            toast.error(error.response.data.message[key][0]);
                        }
                        break;
                    default:
                        break;
                }
            })
    }
}