import React, { Component } from 'react';
import classes from './Map.module.scss';
import GoogleMap from 'google-map-react';
import Icon from '../../../../UI/Icon/Icon';
import { GOOGLE_MAP_KEY } from '../../../../../constant/VendorKey';

const Marker = () => <div><Icon name="ico-location-fill" fill="#CD2033" stroke="none" /></div>;

class Map extends Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let defaultCenter = [];
        let places = [];
        let map = null;
        if (this.props.stores && this.props.stores.length > 0) {
            defaultCenter.push(this.props.stores[0].lat);
            defaultCenter.push(this.props.stores[0].lng);
            places = this.props.stores.map((store, index) => {
                return (
                    <Marker
                        key={index}
                        lat={store.lat}
                        lng={store.lng}
                        text={store.name}
                    />
                );
            });
            map = (
                <GoogleMap
                    bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                    center={defaultCenter}
                    defaultZoom={12} >
                        {places}
                </GoogleMap>
            );
        }
    
        return (
            <div className={classes.Wrapper}>
                <div className={classes.Map}>
                    {map}
                </div>
            </div>
        );
    }
}

export default Map;
