import {createStore, combineReducers, applyMiddleware} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import lang from './Shared/Lang/LangReducer';

import BranchOfficeReducer from './NonProducts/BranchOffice/BranchOfficeReducer';
import CareerReducer from './NonProducts/Career/CareerReducer';
import TourAvailabilityReducer from './Tour/Availability/AvailabilityReducer';
import TourDetailReducer from './Tour/Detail/DetailReducer';
import ContactUsReducer from './NonProducts/ContactUs/ContactUsReducer';
import CareerFormReducer from './NonProducts/CareerForm/CareerFormReducer';
import InquiryReducer from './NonProducts/Inquiry/InquiryReducer';
import BookingReducer from './Tour/Booking/BookingReducer';

import AuthReducer from './Shared/Auth/AuthReducer';
import SignupReducer from './Shared/SignUp/SignupReducer';
import BottomNavReducer from './Shared/BottomNavBar/BottomNavBarReducer';
import ParticipantReducer from './Shared/Participant/ParticipantReducer';
import ForgotPasswordReducer from './Shared/ForgotPassword/ForgotPasswordReducer';
import MemberReducer from './Shared/Member/MemberReducer';
import MyBookingReducer from './Shared/MyBooking/MyBookingReducer';
import RetrieveBookingReducer from './Shared/RetrieveBooking/RetrieveBookingReducer';

import HotelAvailabilityReducer from './Hotel/Availability/AvailabilityReducer';
import HotelBookingReducer from './Hotel/Booking/BookingReducer';

import VirtualTourAvailability from './VirtualTour/Availability/AvailabilityReducer';
import VirtualTourDetailReducer from './VirtualTour/Detail/DetailReducer';
import VirtualTourBookingReducer from './VirtualTour/Booking/VirtualTourBookingReducer';

import VoucherAvailabilityReducer from './Voucher/Availability/AvailabilityReducer';
import VoucherBookingReducer from './Voucher/Booking/BookingReducer';

import AdmissionAvailabilityReducer from './Admission/Availability/AvailabilityReducer';
import AdmissionDetailReducer from './Admission/Detail/DetailReducer';
import AdmissionBookingReducer from './Admission/Booking/BookingReducer';

const rootReducer = combineReducers({
    // Tour
    TourAvailabilityReducer,
    TourDetailReducer,

    // Hotel
    HotelAvailabilityReducer,
    HotelBookingReducer,

    // Virtual Tour
    VirtualTourAvailability,
    VirtualTourDetailReducer,
    VirtualTourBookingReducer,
    
    // Voucher
    VoucherAvailabilityReducer,
    VoucherBookingReducer,

    // Admission
    AdmissionAvailabilityReducer,
    AdmissionDetailReducer,
    AdmissionBookingReducer,

    // shared
    lang,
    AuthReducer,
    SignupReducer,
    BottomNavReducer,
    ParticipantReducer,
    ForgotPasswordReducer,
    MemberReducer,
    MyBookingReducer,

    //Non Product
    BranchOfficeReducer,
    CareerReducer,
    ContactUsReducer,
    CareerFormReducer,
    InquiryReducer,
    BookingReducer,
    RetrieveBookingReducer
});

let store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

if (process.env.REACT_APP_APP_ENV !== 'production') {
    store = createStore(
        rootReducer,
        applyMiddleware(logger, thunk)
   );   
}
export default store