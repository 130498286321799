import React from 'react';
import ContentLoader from 'react-content-loader';

const breadCrumbLoader = () => {
    return (
        <ContentLoader
            height={35}
            speed={1}
            primaryColor={'#e6e6e6'}
        >
            <rect x="0" y="8" rx="3" ry="3" width="50" height="15" />
            <rect x="58" y="8" rx="3" ry="3" width="70" height="15" />
            <rect x="136" y="8" rx="3" ry="3" width="120" height="15" />
        </ContentLoader>
    )
}

export default breadCrumbLoader;