import React from 'react';
import classes from './LeftContent.module.scss';
import logoLight from '../../../../../../assets/images/logo-light.png';

const leftContent = (props) => {
    let jobDescriptionSection = null;
    if (props.jobDetail.job_descriptions && props.jobDetail.job_descriptions.length) {
        const jobDescriptionList = props.jobDetail.job_descriptions.map((jobDescription, index) => {
            return (<li key={index}>{jobDescription}</li>);
        });
        jobDescriptionSection = (
            <div>
                <div className={classes.SubTitle}>Job Descriptions</div>
                <div>
                    <ul>
                        {jobDescriptionList}
                    </ul>
                </div>
            </div>
        );
    }
    let requirementSection = null;
    if (props.jobDetail.requirements && props.jobDetail.requirements.length) {
        const requirementList = props.jobDetail.requirements.map((requirement, index) => {
            return (<li key={index}>{requirement}</li>);
        });
        requirementSection = (
            <div>
                <div className={classes.SubTitle}>Requirements</div>
                <div>
                    <ul>
                        {requirementList}
                    </ul>
                </div>
            </div>
        );
    }

    let additionalInformationSection = null;
    if (props.jobDetail.additional_information !== "") {
        additionalInformationSection = (
            <div>
                <div className={classes.SubTitle}>Additional Information</div>
                <div>
                    <p>{props.jobDetail.additional_information}</p>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Logo}>
                <img src={logoLight} alt="Antavaya" />
            </div>

            <div className={classes.JobTitle}>{props.jobDetail.name}</div>
            <div className={classes.JobLocation}>{props.jobDetail.location}</div>

            <div className={classes.SubTitle}>Company Description</div>
            <div>
                <p>AntaVaya Corporate Travel (PT. Vayatour) is the largest Travel Management Company in Indonesia. Established in 1965, it has gained a reputation of service quality and professional management second to none.</p>
                <p>With more than 600 staffs focused exclusively on corporate travel, a three-floor contact center with advanced telephony systems, over 40 implant locations and a national network spanning Java, Sulawesi, Balikpapan, Bali, Makassar, all the way to Papua, AntaVaya is in a unique position to deliver the specific travel service that your company needs.</p>
            </div>

            {jobDescriptionSection}

            {requirementSection}

            {additionalInformationSection}
            
        </div>
    );
}

export default leftContent;