import React, { useState, useEffect } from 'react';
import classes from './AvailabilityContainer.module.scss';
import Slider from 'react-slick';
import Availability from '../../../../components/Mobile/Tour/Availability/Availability';
import SortFilterBtn from '../../../../components/Mobile/Tour/SortFilter/Button/Button';
import Sort from '../../../../components/Mobile/Tour/SortFilter/Sort/Sort';
import Filter from '../../../../components/Mobile/Tour/SortFilter/Filter/Filter';
import queryString from 'query-string';
import { TOUR_PRICE_FILTER_LIST, TOUR_DURATION_FILTER_LIST } from '../../../../constant/FilterList';

import { connect } from 'react-redux';
import * as availabilityAction from '../../../../redux/Tour/Availability/AvailabilityAction';
import LabelLoader from '../../../../components/UI/Loaders/Mobile/Label/LabelLoader';
import Header from '../../../../components/Mobile/Shared/Header/Header';

const availabilityContainer = props => {
    const [title, setTitle] = useState('');
    const [showSortPopup, setShowSortPopup] = useState(false);
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [priceFilterList, setPriceFilterList] = useState([]);
    const [durationFilterList, setDurationFilterList] = useState([]);
    const [categoryFilterList, setCategoryFilterList] = useState([]);
    const [destinationList, setDestinationList] = useState([]);
    const [destinationSelected, setDestinationSelected] = useState('All');

    useEffect(() => {
        const params = queryString.parse(props.location.search);
        let region = 'all-region';
        if (params.destination !== undefined) {
            region = params.destination;
        }
        props.onInitTourPackages(region);
        setTitleHandler(region);
    }, []);
    
    useEffect(() => {
        setFilterListHandler()
        if(props.tourPackages) {
            setDestinationListHandler(props.tourPackages);
        }
    }, [props.tourPackages]);

    const setFilterListHandler = () => {
        setPriceFilterListHandler();
        setDurationFilterListHandler();
        setCategoryFilterListHandler();
    }

    const setTitleHandler = (region) => {
        let regions = region.split(';');
        let title = (regions[0] === 'all-region' ? 'All Region' : regions[0]);
        if (regions.length > 1) {
            title = regions[1];
        }
        setTitle(title);
    }

    const setDestinationListHandler = (tourPackages) => {
        let destinationList = ['All'];
        for (let tourIndex in tourPackages) {
            for (const destinationIndex in tourPackages[tourIndex].destinations) {
                destinationList.push(tourPackages[tourIndex].destinations[destinationIndex].countryName);
            }
        }
        destinationList = [...new Set(destinationList)];
        setDestinationList(destinationList);
    }

    const destinationClickHandler = (destinationSelected) => {
        setDestinationSelected(destinationSelected);
    }

    const setPriceFilterListHandler = () => {
        const priceFilterList = TOUR_PRICE_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });

        setPriceFilterList(priceFilterList);
    }

    const setDurationFilterListHandler = () => {
        const durationFilterList = TOUR_DURATION_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });

        setDurationFilterList(durationFilterList);
    }
    const setCategoryFilterListHandler = () => {
        const categoryFilterList = props.categoryFilters.map( item => {
            return {...item, isChecked: false};
        });

        setCategoryFilterList(categoryFilterList);
    }

    const setFilterClickHandler = (priceFilterList, durationFilterList, categoryFilterList) => {
        setPriceFilterList(priceFilterList);
        setDurationFilterList(durationFilterList);
        setCategoryFilterList(categoryFilterList);
    }

    const backClickHandler = () => {
        window.history.back();
    }

    const searchClickHandler = () => {
        props.history.push('/tour');
    }

    const tourFilter = (tourPackageList) => {
        const priceFilters = priceFilterList.filter( filter => {
            return filter.isChecked;
        });
        const durationFilters = durationFilterList.filter( filter => {
            return filter.isChecked;
        });
        const categoryFilters = categoryFilterList.filter( filter => {
            return filter.isChecked;
        });

        let tourPackages = tourPackageList;
        if (priceFilters.length > 0) {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let index in priceFilters) {
                    let limit = priceFilters[index].value.split(':');
                    let min = parseInt(limit[0]);
                    let max = parseInt(limit[1]);
                    if (min <= tourPackage.priceStartFrom && max >= tourPackage.priceStartFrom) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            });
        }
        
        if (durationFilters.length > 0) {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let durationIndex in durationFilters) {
                    let limit = durationFilters[durationIndex].value.split(':');
                    let min = parseInt(limit[0]);
                    let max = parseInt(limit[1]);
                    if (isNaN(max)) {
                        if (min <= tourPackage.days) {
                            isValid = true;
                            break;
                        }
                    } else {
                        if (min <= tourPackage.days && max >= tourPackage.days) {
                            isValid = true;
                            break;
                        }
                    }
                }
                return isValid;
            });
        }

        if (categoryFilters.length > 0) {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let index in categoryFilters) {
                    let value = categoryFilters[index].value;
                    if (value === tourPackage.typeName) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            });
        }

        if (destinationSelected !== 'All') {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let destinationIndex in tourPackage.destinations) {
                    if (tourPackage.destinations[destinationIndex].countryName === destinationSelected) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            });
        }

        return tourPackages;
    }

    const tourPackageClickHandler = id => {
        props.history.push('/tour/detail/' + id);
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        variableWidth: true,
        centerPadding: '0px',
        fade:false,
        dotsClass: "slick-dots " + classes.DotWrapper,
        autoplay: false,
        autoplaySpeed: 3500,
        swipeToSlide: true,
    };
    let tourPackageList = tourFilter(props.tourPackages);

    let labelList = [];
    if (!props.isFetchTour) {
        labelList = destinationList.map( (value, index) => {
            let labelClasses = [classes.Item];
            if (value === destinationSelected) {
                labelClasses = [classes.Item, classes.Active];
            }
            return (
                <div 
                    key={index} 
                    className={classes.ItemWrapper}
                    onClick={ () => destinationClickHandler(value)}>
                    <div className={labelClasses.join(' ')}>{value}</div>
                </div>
            );
        });
        labelList = (
            <Slider {...settings}>
                {labelList}
            </Slider>
        )
    } else {
        labelList =  (
            <div className={classes.ItemWrapperLoader}>
                <LabelLoader/>
            </div>
        )
    }

    return (
        <div className={classes.Wrapper}>
            <Sort 
                show={showSortPopup}
                hideSortPopupClicked={() => setShowSortPopup(false)} />
            <Filter 
                show={showFilterPopup}
                hideFilterPopupClicked={() => setShowFilterPopup(false)}
                durationFilterList={durationFilterList}
                priceFilterList={priceFilterList}
                categoryFilterList={categoryFilterList}
                resetFilterClicked={setFilterListHandler}
                setFilterClicked={ (priceFilterList, durationFilterList, categoryFilterList) => setFilterClickHandler(priceFilterList, durationFilterList, categoryFilterList)} />

            <div className={classes.HeaderWrapper}>
                <Header 
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    rightIcon="search"
                    rightClicked={searchClickHandler}
                    content={title} />
                <div className={classes.LabelList}>
                    {labelList}
                </div>
            </div>
            <div className={classes.Content}>
                <Availability 
                    isLoading={props.isFetchTour}
                    destinationList={destinationList}
                    destinationSelected={destinationSelected}
                    tourPackageList={tourPackageList}
                    tourPackageClicked={ (id) => tourPackageClickHandler(id)} />
            </div>

            <div className={classes.SortFilter}>
                <SortFilterBtn 
                    showSortPopupClicked={() => setShowSortPopup(true)}
                    showFilterPopupClicked={() => setShowFilterPopup(true)} />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        tourPackages : state.TourAvailabilityReducer.tourPackages,
        isFetchTour: state.TourAvailabilityReducer.isFetchTour,
        categoryFilters: state.TourAvailabilityReducer.categoryFilters
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitTourPackages : (regionSelected) => dispatch(availabilityAction.initTourPackages(regionSelected))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(availabilityContainer);