import React, { useState, useEffect } from 'react';
import classes from './ContentAvailability.module.scss';
import Breadcrumb from '../../Shared/Breadcrumb/Breadcrumb';
import Filter from './Filter/Filter';
import TourList from './TourList/TourList';
import Sort from './Sort/Sort';
import {TOUR_SORT_LIST} from '../../../../constant/SortList';
import { TOUR_PRICE_FILTER_LIST, TOUR_DURATION_FILTER_LIST } from '../../../../constant/FilterList';
import { withRouter } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import RegionFilter from './RegionFilter/RegionFilter';

import { connect } from 'react-redux';
import * as availabilityAction from '../../../../redux/Tour/Availability/AvailabilityAction';

function contentAvailability(props) {
    const [priceFilterList, setPriceFilterList] = useState([]);
    const [durationFilterList, setDurationFilterList] = useState([]);
    const [categoryFilterList, setCategoryFilterList] = useState([]);
    const [destinationList, setDestinationList] = useState([]);
    const [destinationSelected, setDestinationSelected] = useState('All Region');

    useEffect(() => {
        setFilterListHandler();
    }, [props.categoryFilters]);

    useEffect(() => {
        if(props.tourPackageList) {
            setDestinationListHandler(props.tourPackageList);
        }
    }, [props.tourPackageList]);

    const setDestinationListHandler = (tourPackages) => {
        let destinationList = ['All Region'];
        for (let tourIndex in tourPackages) {
            for (const destinationIndex in tourPackages[tourIndex].destinations) {
                destinationList.push(tourPackages[tourIndex].destinations[destinationIndex].countryName);
            }
        }
        destinationList = [...new Set(destinationList)];
        setDestinationList(destinationList);
    }

    const destinationClickHandler = (val) => {
        setDestinationSelected(val);
    }

    const setFilterListHandler = () => {
        setPriceFilterListHandler();
        setDurationFilterListHandler();
        setCategoryFilterListHandler();
    }

    const setPriceFilterListHandler = () => {
        const priceFilterList = TOUR_PRICE_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });
        setPriceFilterList(priceFilterList);
    }

    const setDurationFilterListHandler = () => {
        const durationFilterList = TOUR_DURATION_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });
        setDurationFilterList(durationFilterList);
    }

    const setCategoryFilterListHandler = () => {
        const categoryFilterList = props.categoryFilters.map( item => {
            return {...item, isChecked: false};
        });
        setCategoryFilterList(categoryFilterList);
    }

    const filterChangeHandler = (event, index, filterType) => {
        let value = event.target.checked;
        let filterList = null;
        if (filterType === 'price') {
            filterList = [...priceFilterList];
            filterList[index].isChecked = value;
            setPriceFilterList(filterList);
        }
        if (filterType === 'duration') {
            filterList = [...durationFilterList];
            filterList[index].isChecked = value;
            setDurationFilterList(filterList);
        }
        if (filterType === 'category') {
            filterList = [...categoryFilterList];
            filterList[index].isChecked = value;
            setCategoryFilterList(filterList);
        }
    }

    const goToDetailPageHandler = (id) => {
        props.history.push('/tour/detail/' + id);
    }

    const tourFilter = (tourPackageList) => {
        const priceFiltersCp = priceFilterList.filter( filter => {
            return filter.isChecked;
        });
        const durationFiltersCp = durationFilterList.filter( filter => {
            return filter.isChecked;
        });
        const categoryFiltersCp = categoryFilterList.filter( filter => {
            return filter.isChecked;
        });

        let tourPackages = tourPackageList;
        if (priceFiltersCp.length > 0) {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let index in priceFiltersCp) {
                    let limit = priceFiltersCp[index].value.split(':');
                    let min = parseInt(limit[0]);
                    let max = parseInt(limit[1]);
                    if (min <= tourPackage.priceStartFrom && max >= tourPackage.priceStartFrom) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            });
        }
        
        if (durationFiltersCp.length > 0) {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let durationIndex in durationFiltersCp) {
                    let limit = durationFiltersCp[durationIndex].value.split(':');
                    let min = parseInt(limit[0]);
                    let max = parseInt(limit[1]);
                    if (isNaN(max)) {
                        if (min <= tourPackage.days) {
                            isValid = true;
                            break;
                        }
                    } else {
                        if (min <= tourPackage.days && max >= tourPackage.days) {
                            isValid = true;
                            break;
                        }
                    }
                }
                return isValid;
            });
        }

        if (categoryFiltersCp.length > 0) {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let index in categoryFiltersCp) {
                    let value = categoryFiltersCp[index].value;
                    if (value === tourPackage.typeName) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            });
        }

        if (destinationSelected !== 'All Region') {
            tourPackages = tourPackages.filter( tourPackage => {
                let isValid = false;
                for (let destinationIndex in tourPackage.destinations) {
                    if (tourPackage.destinations[destinationIndex].countryName === destinationSelected) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;
            });
        }

        return tourPackages;
    }

    const breadcrumbLink = [{
        name: 'Home',
        url: '/'
    },{
        name: 'Tour Packages',
        url: '/tour/availability'
    }];
    let tourPackageList = tourFilter(props.tourPackageList);

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className={classes.RegionFilter}>
                    <RegionFilter 
                        destinationList={destinationList}
                        destinationSelected={destinationSelected}
                        regionClicked={ (val) => destinationClickHandler(val)}/>
                </div>
                <Breadcrumb linkList={breadcrumbLink}/>
                <div className={classes.Content}>
                    <div className={classes.Sidebar}>
                        <Filter 
                            priceFilterList={priceFilterList}
                            durationFilterList={durationFilterList}
                            categoryFilterList={categoryFilterList}
                            filterChanged={(event, index, filterType) => filterChangeHandler(event, index, filterType)}
                            resetPriceFilterClicked={setPriceFilterListHandler}
                            resetDurationFilterClicked={setDurationFilterListHandler}
                            resetCategoryFilterClicked={setCategoryFilterListHandler}
                            resetFilterClicked={setFilterListHandler} />
                    </div>
                    <div className={classes.Availability}>
                        <div className={classes.Header}>
                            <div className={classes.Title}>
                                <CurrencyFormat value={tourPackageList.length} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={''} /> tour packages found
                            </div>
                            <div className={classes.Sort}>
                                <label>Sort by</label>
                                <Sort 
                                    defaultValue={props.sortBy}
                                    options={TOUR_SORT_LIST}
                                    setSelected={(value) => props.setSortBy(value)} />
                            </div>
                        </div>
                        <TourList 
                            isLoading={props.isFetchTour}
                            tourPackageList={tourPackageList}
                            goToDetailPageHandler={(id) => goToDetailPageHandler(id)} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        isFetchTour: state.TourAvailabilityReducer.isFetchTour,
        tourPackageList : state.TourAvailabilityReducer.tourPackages,
        categoryFilters : state.TourAvailabilityReducer.categoryFilters,
        sortBy : state.TourAvailabilityReducer.sortBy
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSortBy: (value) => dispatch(availabilityAction.setSortBy(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(contentAvailability));