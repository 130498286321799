import React from 'react';
import classes from './Destination.module.scss';
import DestinationItem from './DestinationItem/DestinationItem';

const destination = (props) => {
    let destinationList = null;
        if (props.list && props.list.length > 0) {
            destinationList = props.list.map((destinationItem, index) => {
                return (
                    <div key={index} className={classes.Item} onClick={() => props.destinationClicked(destinationItem.value)}>
                        <DestinationItem destination={destinationItem}/>
                    </div>
                );
            });
        }
        return (
            <div className={classes.Wrapper}>
            <div className={classes.Label}>Destination</div>
            <div className={classes.List}>
                {destinationList}
            </div>
        </div>
        );
}

export default destination;