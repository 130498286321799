import React, { useEffect } from 'react';
import classes from './BookingDetail.module.scss';
import BookingDetail from '../../../../Shared/RetieveBooking/BookingDetail/BookingDetail';
import TourDetail from '../../../../Shared/RetieveBooking/TourDetail/TourDetail';
import Participant from '../../../../Shared/RetieveBooking/Participant/Participant';
import PriceDetail from '../../../../Shared/RetieveBooking/PriceDetail/PriceDetail';

import Itinerary from './Itinerary/Itinerary';
import Contact from './Contact/Contact';
import OtherInformation from './OtherInformation/OtherInformation';
import {withRouter} from 'react-router-dom';
import NotFound from '../NotFound/NotFound';

import { connect } from 'react-redux';
import * as myBookingAction from '../../../../../../redux/Shared/MyBooking/MyBookingAction';

const bookingDetail = (props) => {
    useEffect(() => {
        if (props.bookingId) {
            props.initBookingDetail({ orderNumber: props.match.params.bookingCode });
        }
    }, [props.bookingId]);

    let content = null;
    if (props.isErrorFetch) {
        content = <NotFound />;
    } else {
        switch (props.activePage) {
            case 'itinerary':
                content = <div className="u-bg-white_dark u-p-24 u-mt-24 u-br-4">
                        <Itinerary itineraryList={props.bookingDetail.itineraries}/>
                    </div>;
                break;
            case 'contact':
                content = <div className="u-mt-24">
                        <Contact />
                    </div>;
                break;
            case 'other-information':
                content = <div className="u-mt-24">
                        <OtherInformation />
                    </div>;
                break;
        
            default:
                content = <ul className={classes.List}>
                    <li className={classes.Item}>
                        <BookingDetail
                            isLoading={props.isFetch}
                            bookingData={props.bookingDetail}/>
                    </li>
                    <li className={classes.Item}>
                        <TourDetail 
                            isLoading={props.isFetch}
                            bookingData={props.bookingDetail}/>
                    </li>
                    <li className={classes.Item}>
                        <Participant
                            isLoading={props.isFetch}
                            bookingData={props.bookingDetail}/>
                    </li>
                    <li className={classes.Item}>
                        <PriceDetail
                            isLoading={props.isFetch}
                            bookingData={props.bookingDetail}/>
                    </li>
                </ul>;
                break;
        }
    }
    return (
        <div>
            {content}
        </div>
    ); 
}

const mapStateToProps = (state) => {
    return {
        bookingId: state.MyBookingReducer.bookingId,
        bookingDetail: state.MyBookingReducer.bookingDetail,
        isFetch: state.MyBookingReducer.isFetchBookingDetail,
        isErrorFetch: state.MyBookingReducer.isErrorFetchBookingDetail,
         
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initBookingDetail: (data) => dispatch(myBookingAction.initBookingDetail(data))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(bookingDetail));