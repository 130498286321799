import { axiosAuth } from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';
import { toast } from 'react-toastify';
import * as resHandler from '../../../helper/ResHandler';


export const selectChangedTitle = (value) => {
    return {
        type: 'SELECT_CHANGED_TITLE',
        payload: {
            value: value
        }
    }
}

export const inputChangedName = (event, inputIdentifier) => {
    return {
        type: 'INPUT_CHANGED_NAME',
        payload: {
            value: event.target.value,
            inputIdentifier
        }
    }
}

export const inputChangedEmail = (event, inputIdentifier) => {
    return {
        type: 'INPUT_CHANGED_EMAIL',
        payload: {
            value: event.target.value,
            inputIdentifier
        }
    }
}

export const setDialCodeHandler = (val) => {
    return {
        type: 'SET_DIAL_CODE_HANDLER',
        payload: {
            value: val
        }
    }
}

export const inputChangePhone = (event, inputIdentifier) => {
    return {
        type: 'INPUT_CHANGED_PHONE',
        payload: {
            value: event.target.value,
            inputIdentifier
        }
    }
}

export const inputChangePassword = (event, inputIdentifier) => {
    return {
        type : 'INPUT_CHANGED_PASSWORD',
        payload : {
            value: event.target.value,
            inputIdentifier
        } 
    }
}

export const selectChangedDateOfBirth = (event, inputIdentifier) => {
    return {
        type: 'SELECT_CHANGED_DATE_OF_BIRTH',
        payload: {
            value: event,
            inputIdentifier
        }
    }
}

export const inputChangedKTP = (event, inputIdentifier) => {
    return {
        type: 'INPUT_CHANGED_KTP',
        payload: {
            value: event.target.value,
            inputIdentifier
        }
    }
}

export const setOtp = (value) => {
    return {
        type: 'SET_OTP',
        payload: { value }
    }
}

export const setMemberDetail = (dataMember) => {
    return {
        type: 'SET_MEMBER_DETAIL',
        payload: {
            dataMember,
        }
    }
}

export const successChangedPassword = () => {
    return {
        type: 'SUCCESS_CHANGED_PASSWORD',
    }
}

export const setIsSuccessSendEmailVerify = (value) => {
    return {
        type: 'SET_IS_SUCCESS_SEND_EMAIL_VERIFY',
        payload: { value }
    }
}

export const setIsSendEmailVerifyLoading = (value) => {
    return {
        type: 'SET_IS_SEND_EMAIL_VERIFY_LOADING',
        payload: { value }
    }
}

export const dateBirthProfileChanged = (event, inputIdentifier) => {
    return {
        type: 'DATE_BIRTH_PROFILE_CHANGED',
        payload: {
            value: event,
            inputIdentifier,
        }
    }
}

export const sucessMemberChanged = () => {
    return {
        type: 'SUCCESS_MEMBER_CHANGED'
    }
}

export const successUpdateEmail = () => {
    return {
        type: 'SUCCESS_UPDATE_EMAIL'
    }
}

export const successChangedPhone = () => {
    return {
        type: 'SUCCESS_CHANGED_PHONE'
    }
}

export const setIsSubmitLoading = (value) => {
    return {
        type: 'SET_IS_SUBMIT_MEMBER_LOADING',
        payload: { value }
    }
}

export const setShowOtpForm = (value) => {
    return {
        type: 'SET_SHOW_OTP_FORM',
        payload: { value }
    }
}

// axios collection
export const getDetailMember = () => {
    return dispatch => {
        axiosAuth.post(ANTAVAYA_API + 'member/detail')
            .then((response) => {
                dispatch(setMemberDetail(response.data.data.member))
            })
            .catch((error) => {
                if (error.response && error.response.data.code === 401) {
                    resHandler.unauthorize();
                }
            })
    }
}

export const submitChangedMember = (data, formName) => {
    let path = '';
    let isPhoneVerify = false;
    switch (formName) {
        case 'generalForm':
            path = 'member/edit';
            break;
        case 'emailForm':
            path = 'member/change-email';
            break;
        case 'phoneForm':
            isPhoneVerify = true;
            path = 'member/change-phone';
            break;
        case 'passwordForm':
            path = 'member/change-password';
            break;
        default:
            break;
    }
    return dispatch => {
        dispatch(setIsSubmitLoading(true));
        axiosAuth.post(ANTAVAYA_API + path, data)
        .then((response) => {
                toast.success(response.data.message)
                dispatch(sucessMemberChanged());
                dispatch(setMemberDetail(response.data.data.member));
                dispatch(setIsSubmitLoading(false));
                if (isPhoneVerify){
                    dispatch(setShowOtpForm(true));
                }
            })
            .catch((error) => {
                dispatch(setIsSubmitLoading(false));
                if (error.response) {
                    if (error.response.data.code === 401){
                        resHandler.unauthorize();
                    } else {
                        if (error.response.data.message){
                            switch (typeof error.response.data.message) {
                                case 'string':
                                    toast.error(error.response.data.message);
                                    break;
                                case 'object':
                                    for (const key in error.response.data.message) {
                                        toast.error(error.response.data.message[key][0]);
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
            });
    }
}

export const sendActivationToEmail = () => {
    return dispatch => {
        dispatch(setIsSendEmailVerifyLoading(true));
        axiosAuth.post(ANTAVAYA_API + 'member/send-validation-email')
            .then((response) => {
                toast.success(response.data.message)
                dispatch(setIsSendEmailVerifyLoading(false));
                dispatch(setIsSuccessSendEmailVerify(true))
            })
            .catch((error) => {
                dispatch(setIsSendEmailVerifyLoading(false));
                if (error.response) {
                    if (error.response.data.code === 401) {
                        resHandler.unauthorize();
                    }
                    if (error.response.data.message){
                        switch (typeof error.response.data.message) {
                            case 'string':
                                toast.error(error.response.data.message);
                                break;
                            case 'object':
                                for (const key in error.response.data.message) {
                                    toast.error(error.response.data.message[key][0]);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
            });
    }
}

export const verificationEmail = (data, history) => {
    return dispatch => {
        axiosAuth.post(ANTAVAYA_API + 'member/verify-email', data)
            .then((response) => {
                toast.success(response.data.message)
                history.push('/')
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.code === 401) {
                        resHandler.unauthorize();
                    }
                    switch (typeof error.response.data.message) {
                        case 'string':
                            toast.error(error.response.data.message);
                            history.push('/')
                            break;
                        case 'object':
                            for (const key in error.response.data.message) {
                                toast.error(error.response.data.message[key][0]);
                                history.push('/')
                            }
                            break;
                        default:
                            break;
                    }
                }
            })
    }
}

export const sendOtp = (data) => {
    return dispatch => {
        axiosAuth.post(ANTAVAYA_API + 'member/send-otp', data)
            .then((response) => {
                console.log('response', response)
            })
            .catch((error) => {
                console.log('error', error)
            })
    }
}

export const confirmOtp = (otpCode) => {
    console.log('OTP', otpCode);
    let data = { otpCode };
    return dispatch => {
        axiosAuth.post(ANTAVAYA_API + 'member/confirm-otp', data)
            .then((response) => {
                console.log('response', response);
                dispatch(setShowOtpForm(false));
                dispatch(setMemberDetail(response.data.data.member));
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.code === 401) {
                        resHandler.unauthorize();
                    }
                    if (error.response.data.message){
                        switch (typeof error.response.data.message) {
                            case 'string':
                                toast.error(error.response.data.message);
                                break;
                            case 'object':
                                for (const key in error.response.data.message) {
                                    toast.error(error.response.data.message[key][0]);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
            })
    }
}