import React, { useState, useEffect } from 'react';
import classes from './ContentAvailability.module.scss';
import Breadcrumb from '../../Shared/Breadcrumb/Breadcrumb';
import { TOUR_PRICE_FILTER_LIST } from '../../../../constant/FilterList';
import TourList from './TourList/TourList';
import Filter from './Filter/Filter';
import Sort from './Sort/Sort';
import { ADMISSION_SORT_LIST } from '../../../../constant/SortList';

import { connect } from 'react-redux';
import * as availabilityAction from '../../../../redux/Admission/Availability/AvailabilityAction';

const contentAvailability = (props) => {
    const [priceFilterList, setPriceFilterList] = useState([]);

    const breadcrumbLink = [{
        name: 'Home',
        url: '/'
    },{
        name: 'Admission',
        url: '/activity/availability'
    }];

    useEffect(() => {
        props.initAdmissionPackages();
    }, [])

    useEffect(() => {
        setFilterListHandler();
    }, [props.categoryFilters]);

    const setFilterListHandler = () => {
        setPriceFilterListHandler();
    }

    const setPriceFilterListHandler = () => {
        const priceFilterList = TOUR_PRICE_FILTER_LIST.map( item => {
            return {...item, isChecked: false};
        });
        setPriceFilterList(priceFilterList);
    }

    const filterChangeHandler = (event, index, filterType) => {
        let value = event.target.checked;
        let filterList = null;
        if (filterType === 'price') {
            filterList = [...priceFilterList];
            filterList[index].isChecked = value;
            setPriceFilterList(filterList);
        }
    }

    const admissionFilter = (admissionPackagesList) => {
        const priceFiltersCp = priceFilterList.filter( filter => {
            return filter.isChecked;
        });


        let admissionPackages = admissionPackagesList;
        if (priceFiltersCp.length > 0) {
            admissionPackages = admissionPackages.filter(admisionPackage => {
                let isValid = false;
                for (let index in priceFiltersCp) {
                    let limit = priceFiltersCp[index].value.split(':');
                    let min = parseInt(limit[0]);
                    let max = parseInt(limit[1]);
                    if (min <= admisionPackage.priceStartFrom && max >= admisionPackage.priceStartFrom) {
                        isValid = true;
                        break;
                    }
                }
                return isValid;

            })
        }

        return admissionPackages;

    }

    let activitiesFoundTitle = null;
    if (props.admissionPackages && props.admissionPackages.length > 0) {
        activitiesFoundTitle = props.admissionPackages.length + ' activities found';
    }

    let admissionPackageList = admissionFilter(props.admissionPackages);
    
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <Breadcrumb linkList={breadcrumbLink}/>

                <div className={classes.Content}>
                    <div className={classes.Sidebar}>
                        <Filter 
                            priceFilterList={priceFilterList}
                            filterChanged={(event, index, filterType) => filterChangeHandler(event, index, filterType)}
                            resetPriceFilterClicked={setPriceFilterListHandler}
                            resetFilterClicked={setFilterListHandler} />
                    </div>
                    <div className={classes.Availability}>
                        <div className={classes.Header}>
                            <div className={classes.Title}>{activitiesFoundTitle}</div>
                            <div className={classes.Sort}>
                                <label>Sort by</label>
                                <Sort
                                    defaultValue={props.sortBy}
                                    options={ADMISSION_SORT_LIST}
                                    setSelected={(value) => props.setSortBy(value)}/>
                            </div>
                        </div>
                        <div>
                            <TourList
                                admissionPackages={admissionPackageList}
                                isFetchAdmission={props.isFetchAdmission}
                                isErrorFetchAdmission={props.isErrorFetchAdmission}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        admissionPackages: state.AdmissionAvailabilityReducer.admissionPackages,
        isFetchAdmission: state.AdmissionAvailabilityReducer.isFetchAdmission,
        isErrorFetchAdmission: state.AdmissionAvailabilityReducer.isErrorFetchAdmission,
        sortBy : state.AdmissionAvailabilityReducer.sortBy
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initAdmissionPackages: () => dispatch(availabilityAction.initAdmissionPackages()),
        setSortBy: (value) => dispatch(availabilityAction.setSortByAdmission(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(contentAvailability);
