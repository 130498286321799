import React, { Component, useState } from 'react'
import classes from './MyBookingDetail.module.scss'
import Slider from 'react-slick'
import LabelLoader from '../../../../components/UI/Loaders/Mobile/Label/LabelLoader'
import Icon from '../../../../components/UI/Icon/Icon'
import DetailPage from './Content/DetailPage/DetailPage'
import ContactPage from './Content/ContactPage/ContactPage'
import OtherPage from './Content/OtherPage/OtherPage'
import ItineraryPage from './Content/ItineraryPage/ItineraryPage'

class MyBookingDetail extends Component {
	scrollTop = () => {
		window.scrollTo(0, 0)
	}

	goBack = () => {
		this.props.history.goBack()
	}

	render() {
		this.scrollTop()
		return <Header clicked={this.goBack} />
	}
}

export default MyBookingDetail

const Header = props => {
	const [tripList] = useState([
		{ title: 'Tour Details', page: 'detail' },
		{ title: 'Itinerary', page: 'itinerary' },
		{ title: 'Contact', page: 'contact' },
		{ title: 'Other Information', page: 'other' }
	])
	const [menuSelected, setMenuSelected] = useState({ title: 'Tour Details' })
	const [activePage, setActivePage] = useState('detail')

	let menuClickHandler = menuSelected => {
		setMenuSelected(menuSelected)
		setActivePage(menuSelected.page)
	}

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		adaptiveHeight: true,
		variableWidth: true,
		centerPadding: '0px',
		fade: false,
		dotsClass: 'slick-dots ' + classes.DotWrapper,
		autoplay: false,
		autoplaySpeed: 3500,
		swipeToSlide: true
	}

	let labelList = []
	if (tripList && tripList.length > 0) {
		labelList = tripList.map((value, index) => {
			let labelClasses = [classes.Item]
			if (value.title === menuSelected.title) {
				labelClasses = [classes.Item, classes.Active]
			}
			return (
				<div
					key={index}
					className={classes.ItemWrapper}
					onClick={() => menuClickHandler(value)}
				>
					<div className={labelClasses.join(' ')}>{value.title}</div>
				</div>
			)
		})
		labelList = <Slider {...settings}>{labelList}</Slider>
	} else {
		labelList = (
			<div className={classes.ItemWrapperLoader}>
				<LabelLoader />
			</div>
		)
	}

	let page
	switch (activePage) {
		case 'detail':
			page = <DetailPage />
			break
		case 'itinerary':
			page = <ItineraryPage />
			break
		case 'contact':
			page = <ContactPage />
			break
		case 'other':
			page = <OtherPage />
			break
		default:
			page = activePage
			break
	}
	return (
		<div>
			<div className={classes.HeaderWrapper}>
				<div className={classes.Header}>
					<div onClick={props.clicked} className={classes.ActionTitle}>
						<Icon
							name={'ico-arrow-left'}
							fill='#202124'
							stroke='none'
							width={24}
						/>
						<div className={classes.Title}>My Booking</div>
					</div>
					<a href={'/'}
						className={classes.Download}
						target='blank'>
						Download E-Ticket
          			</a>
				</div>
				<div className={classes.LabelList}>{labelList}</div>
			</div>
			{page}
		</div>
	)
}
