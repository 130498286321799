import React, { useEffect } from 'react';
import classes from './VirtualTour.module.scss';
import Header from '../../../components/Mobile/Shared/Header/Header';
import voucherBannerImg from '../../../assets/images/image-banner-vt-mobile.jpg';
import PanelBox from '../../../components/UI/PanelBox/PanelBox';
import TextField from '../../../components/UI/Inputs/TextField/TextField';
import MobileSelectFlag from '../../../components/UI/Inputs/MobileSelectFlag/MobileSelectFlag';
import Button from '../../../components/UI/Button/Button';
import Reaptcha from 'reaptcha';
import { CAPTCHA_KEY } from '../../../constant/VendorKey';
import SuccessMessage from './SuccessMessage/SuccessMessage';

import { connect } from 'react-redux';
import * as VirtualTourBookingAction from '../../../redux/VirtualTour/Booking/VirtualTourBookingAction';

const virtualTour = (props) => {
    // eslint-disable-next-line
    let captcha = React.createRef();

    useEffect(() => {
        scrollTop()
    }, [])

    const submitFormClickHandler = () => {
        if (props.isVerified && props.isValidForm) {
            let postData = {
                name: props.formData.name.value,
                email: props.formData.email.value,
                phone: props.formData.dialCode.value + props.formData.phone.value,
                subject: 'subject'
            }
            props.formSubmited(postData)
        } else {
            props.formChecking();
        }
    }

    const goToHome = () => {
        props.history.push('/');
    }

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    const backClickHandler = () => {
        window.history.back();
    }

    let content = null;
    if (props.isSuccess) {
        scrollTop()
        content = <SuccessMessage
            title="Terima Kasih"
            subTitle="Data anda telah kami terima, untuk proses pembayaran dan akses zoom akan diteruskan oleh travel consultant kami melalui WhatsApp Messanger."
            labelBtn="Back to Home"
            clicked={() => goToHome()} />
    } else {
        content = (
            <div className={classes.Content}>
                <div className={classes.Banner}>
                    <img src={voucherBannerImg} alt="virtual-tour" />
                </div>
                <div className={classes.Summary}>
                    <div className={classes.Heading}>Virtual Tour Wo Ai Ni Taiwan</div>
                    <div className={classes.Desc}>LIVE Shoot Minggu 13 September 2020 pukul 15:00 WIB berdurasi 40-60 menit.</div>
                    <div className={classes.Detail}>
                        <p>Kita akan berwisata virtual mengunjungi 101 Taipei 101 adalah pencakar langit setinggi 101 tingkat di Distrik Xinyi, Taipei, Taiwan. Nama resminya adalah Gedung Finansial Internasional Taipei, Menara ini menjadi gedung tertinggi kedua di dunia.</p>
                        <p>Lalu selanjutnya mengunjungi Yongkang Street aiut kawasan yang terkenal dengan kulinernya. Kamu akan menemukan restoran din tai fung terkenal, cafe, dan gerobak jajan taiwan yang murah meriah.  Akan banyak tips dan informasi yang akan kamu dapatkan untuk nanti kamu berkunjung ke Taiwan, tur virtual ini akan dibawakan oleh pemandu berbahasa Indonesia.</p>
                        <p>Mohon untuk mengisi data di bawah ini dengan baik & benar</p>
                    </div>
                </div>
                <div className={classes.Form}>
                    <PanelBox>
                        <div className={classes.Body}>
                            <div className={classes.Row}>
                                <div className={classes.Input}>
                                    <label>Full Name</label>
                                    <TextField
                                        type="text"
                                        placeholder="e.g. Julie Applesed"
                                        value={props.formData.name.value}
                                        changed={(event) => props.inputChanged('name', event)}
                                        hasError={(props.formData.name.hasError && props.formData.name.errorMsg !== '')}
                                        errorMsg={props.formData.name.errorMsg} />
                                </div>
                            </div>
                            <div className={[classes.Row, classes.MultiColumn].join(' ')}>
                                <div className={[classes.Input, classes.PhoneWrapper].join(' ')}>
                                    <label>Phone Number</label>
                                    <div className={classes.Inline}>
                                        <div className={classes.DialCode}>
                                            <MobileSelectFlag
                                                value={props.formData.dialCode.value}
                                                selected={val => props.dialCodeChanged(val)} />
                                        </div>
                                        <div className={classes.Phone}>
                                            <TextField
                                                type="text"
                                                placeholder="e.g. 8123456789"
                                                value={props.formData.phone.value}
                                                changed={(event) => props.inputChanged('phone', event)}
                                                hasError={(props.formData.phone.hasError && props.formData.phone.errorMsg !== '')}
                                                errorMsg={props.formData.phone.errorMsg} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.Row}>
                                <div className={classes.Input}>
                                    <label>Email</label>
                                    <TextField
                                        type="text"
                                        placeholder="e.g. youremail@example.com"
                                        value={props.formData.email.value}
                                        changed={(event) => props.inputChanged('email', event)}
                                        hasError={(props.formData.email.hasError && props.formData.email.errorMsg !== '')}
                                        errorMsg={props.formData.email.errorMsg} />
                                </div>
                            </div>
                            {/* <div className={classes.Row}>
                                <div className={classes.Input}>
                                    <label>Zoom ID</label>
                                    <TextField
                                        type="text"
                                        placeholder="e.g. JulieApplesed"
                                        value={props.formData.zoomId.value}
                                        changed={(event) => props.inputChanged('zoomId', event)}
                                        hasError={(props.formData.zoomId.hasError && props.formData.zoomId.errorMsg !== '')}
                                        errorMsg={props.formData.zoomId.errorMsg} />
                                </div>
                            </div> */}

                            <div className={classes.Row}>
                                <div className={classes.Input}>
                                    <Reaptcha
                                        ref={e => (captcha = e)}
                                        sitekey={CAPTCHA_KEY}
                                        render="explicit"
                                        onVerify={props.verifyClicked} />
                                </div>
                            </div>
                            <div className={classes.Row}>
                                <div className={classes.Input}>
                                    <Button
                                        isLoading={props.isLoadingSubmitForm}
                                        classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                                        clicked={submitFormClickHandler}> Submit</Button>
                                </div>
                            </div>
                        </div>
                    </PanelBox>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.HeaderWrapper}>
                <Header
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    content="Virtual Tour" />
            </div>
            {content}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isValidForm: state.VirtualTourReducer.isValidForm,
        formData: state.VirtualTourReducer.formData,
        isVerified: state.VirtualTourReducer.isVerified,
        isLoadingSubmitForm: state.VirtualTourReducer.isLoadingSubmitForm,
        isSuccess: state.VirtualTourReducer.isSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChanged : (identifier, event) => dispatch(VirtualTourBookingAction.inputChanged(identifier, event)),
        verifyClicked : (res) => dispatch(VirtualTourBookingAction.verifyClicked(res)),
        formSubmited : (postData) => dispatch(VirtualTourBookingAction.formSubmited(postData)),
        formChecking : () => dispatch(VirtualTourBookingAction.formChecking()),
        dialCodeChanged : (value) => dispatch(VirtualTourBookingAction.dialCodeChanged(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(virtualTour);
