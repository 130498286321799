import React, { useState, useEffect } from 'react';
import classes from './Profile.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import Button from '../../../../UI/Button/Button';
import { formatDisplayDate, formatDisplayPhone } from '../../../../../helper/GlobalFunc';

import NameModal from './NameModal/NameModal';
import EmailModal from './EmailModal/EmailModal';
import PhoneModal from './PhoneModal/PhoneModal';
import PasswordModal from './PasswordModal/PasswordModal';
import BirthDateModal from './BirthDateModal/BirthDateModal';
import CitizenshipNumberModal from './CitizenshipNumberModal/CitizenshipNumberModal';
import EmailVerificationModal from './EmailVerificationModal/EmailVerificationModal';
import PhoneVerificationModal from './PhoneVerificationModal/PhoneVerificationModal';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../redux/Shared/Member/MemberAction';

const profile = (props) => {
    const [showUpdateNameForm, setShowUpdateNameForm] = useState(false);
    const [showUpdateEmailForm, setShowUpdateEmailForm] = useState(false);
    const [showUpdatePhoneForm, setShowUpdatePhoneForm] = useState(false);
    const [showUpdatePasswordForm, setShowUpdatePasswordForm] = useState(false);
    const [showUpdateDateOfBirthForm, setShowUpdateDateOfBirthForm] = useState(false);
    const [showUpdateIdCardForm, setShowUpdateIdCardForm] = useState(false);
    const [showConfirmationEmail, setshowConfirmationEmail] = useState(false);
    const [otpCode, setOtpCode] = useState('');

    useEffect(() => {
        setShowUpdateNameForm(false);
        setShowUpdateEmailForm(false);
        setShowUpdatePhoneForm(false);
        setShowUpdatePasswordForm(false);
        setShowUpdateDateOfBirthForm(false);
        setShowUpdateIdCardForm(false);
        setshowConfirmationEmail(false);
        props.setShowVerifyPhoneForm(false);
    }, [props.successMemberChanged]);

    let fullname = '';
    let email = '';
    let countryCode = '';
    let phone = '';
    let dateBirth = '';
    let dateBirthBtn = '';
    let citizenshipNumber = '';
    let citizenshipNumberBtn = '';
    let emailStatus = '';
    let phoneNumberStatus = '';
    let emailVerifiedBtn = '';
    let phoneNumberVerifiedBtn = '';
    let phoneNumberDisplay = '';
    let verified = (
        <span>
            <Icon name="ico-check-circle" stroke="none" fill="#73C700" width="16"/> Verified
        </span>
    );
    let notVerified = (
        <span className={classes.Unverified}>
            <Icon name="ico-check-circle" stroke="none" fill="#73C700" width="16"/> Not verified
        </span>
    )
    let unverifiedbtnEmail = (
        <div className={classes.Link}>
            <Button classes={['BtnRed', 'BtnXSmall']} clicked={() => setshowConfirmationEmail(true)}>Verify</Button>
        </div>
    )
    let unverifiedBtnPhoneNumber = (
        <div className={classes.Link}>
            <Button classes={['BtnRed', 'BtnXSmall']} clicked={() => props.setShowVerifyPhoneForm(true)}>Verify</Button>
        </div>
    )
    
    if (props.member) {
        fullname = `${props.member.firstName} ${props.member.lastName}`;
        email = props.member.email;
        countryCode = props.member.countryCode;
        phone = props.member.phone;
        dateBirth = (props.member.birthDate) ? formatDisplayDate(props.member.birthDate) : 'e.g. 12 - December -1987';
        dateBirthBtn = (props.member.birthDate) ? 'Edit': 'Add';
        citizenshipNumber = (props.member.citizenshipNumber) ? props.member.citizenshipNumber: <div className={classes.Placeholder}>e.g. 3120 4567 3564 2353</div>;
        citizenshipNumberBtn = (props.member.citizenshipNumber) ? 'Edit': 'Add';
        emailStatus = (props.member.isEmailVerified === 1) ? verified : notVerified;
        phoneNumberStatus = (props.member.isPhoneVerified === 1) ? verified : notVerified;
        emailVerifiedBtn = (props.member.isEmailVerified === 1) ? '' : unverifiedbtnEmail;
        phoneNumberVerifiedBtn = (props.member.isPhoneVerified === 1) ? '' : unverifiedBtnPhoneNumber;
        phoneNumberDisplay = formatDisplayPhone(`${countryCode}${phone}`);
    }

    return (
        <div>
            <div className={classes.Wrapper}>
                <div className={classes.Head}>
                    <div className={classes.Title}>Profile</div>
                </div>
                <div className={classes.Section}>
                    <div className={classes.Label}>Full Name</div>
                    <div className={classes.Box}>
                        <div className={classes.Value}>
                            {fullname}
                        </div>
                        <div className={classes.Action} onClick={() => setShowUpdateNameForm(true)}>
                            <div className={classes.Link}>Edit</div>
                        </div>
                    </div>
                </div>
                <div className={classes.Section}>
                    <div className={classes.Label}>Email Address</div>
                    <div className={classes.Box}>
                        <div className={classes.Value}>
                            {email}
                            {emailStatus}
                        </div>
                        <div className={classes.Action}>
                            <div className={classes.Link} onClick={() => setShowUpdateEmailForm(true)}>Edit</div>
                            {emailVerifiedBtn}
                        </div>
                    </div>
                </div>
                <div className={classes.Section}>
                    <div className={classes.Label}>Phone Number</div>
                    <div className={classes.Box}>
                        <div className={classes.Value}>
                            {phoneNumberDisplay}
                            {phoneNumberStatus}
                        </div>
                        <div className={classes.Action}>
                            <div className={classes.Link} onClick={() => setShowUpdatePhoneForm(true)}>Edit</div>
                            {phoneNumberVerifiedBtn}
                        </div>
                    </div>
                </div>
                <div className={classes.Section}>
                    <div className={classes.Label}>Password</div>
                    <div className={classes.Box}>
                        <div className={classes.Value}>
                            <div className={classes.Password}></div>
                        </div>
                        <div className={classes.Action}>
                            <div className={classes.Link} onClick={() => setShowUpdatePasswordForm(true)}>Edit</div>
                        </div>
                    </div>
                </div>
                <div className={classes.Section}>
                    <div className={classes.Label}>Date of birth</div>
                    <div className={classes.Box}>
                        <div className={classes.Value}>
                            {dateBirth}
                        </div>
                        <div className={classes.Action}>
                            <div className={classes.Link} onClick={() => setShowUpdateDateOfBirthForm(true)}>
                                {dateBirthBtn}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.Section}>
                    <div className={classes.Label}>ID card number (KTP)</div>
                    <div className={classes.Box}>
                        <div className={classes.Value}>
                            {citizenshipNumber}
                        </div>
                        <div className={classes.Action}>
                            <div className={classes.Link} onClick={() => setShowUpdateIdCardForm(true)}>
                                {citizenshipNumberBtn}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <NameModal
                showUpdateNameForm={showUpdateNameForm}
                showHideUpdateNameClicked={() => setShowUpdateNameForm(false)}/>
            <EmailModal
                showUpdateEmailForm={showUpdateEmailForm}
                showHideUpdateEmailClicked={() => setShowUpdateEmailForm(false)}/>
            <PhoneModal
                showUpdatePhoneForm={showUpdatePhoneForm}
                showHideUpdatePhoneClicked={() => setShowUpdatePhoneForm(false)}/>
            <PasswordModal
                showUpdatePasswordForm={showUpdatePasswordForm}
                showHideUpdatePasswordClicked={() => setShowUpdatePasswordForm(false)}/>
            <BirthDateModal
                showUpdateDateOfBirthForm={showUpdateDateOfBirthForm}
                showHideUpdateDateOfBirthClicked= {() => setShowUpdateDateOfBirthForm(false)}/>
            <CitizenshipNumberModal
                showUpdateIdCardForm={showUpdateIdCardForm}
                showHideUpdateIdCardClicked={() => setShowUpdateIdCardForm(false)}/>
            <EmailVerificationModal
                showConfirmationEmail={showConfirmationEmail}
                showHideConfirmationEmailClicked={() => setshowConfirmationEmail(false)}/>
            <PhoneVerificationModal
                showVerifyPhoneForm={props.showOtpForm}
                showHideVerifyPhoneClicked={() => props.setShowVerifyPhoneForm(false)}
                otpCode={otpCode}
                otpChanged={val => setOtpCode(val)} />

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        successMemberChanged: state.MemberReducer.successMemberChanged,
        showOtpForm: state.MemberReducer.showOtpForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setShowVerifyPhoneForm : val => dispatch(memberAction.setShowOtpForm(val))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(profile);
