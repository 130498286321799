import {updateObject} from '../../utility';

const initialState = {
    hotels : [],
    isFetchHotel : false,
    isErrorFetchHotel : false
}

const setHotel = (state, action) => {
    return updateObject(state, {hotels: action.payload.hotels});
};

const seIsFetchHotel = (state, action) => {
    return updateObject(state, {isFetchHotel: action.payload.value});
}

const seIsErrorFetchHotel = (state, action) => {
    return updateObject(state, {isErrorFetchHotel: action.payload.value});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'SET_HOTEL': return setHotel(state, action);
        case 'SET_IS_FETCH_HOTEL': return seIsFetchHotel(state, action);
        case 'SET_IS_ERROR_FETCH_HOTEL': return seIsErrorFetchHotel(state, action);
        default: return state;
    }
};

export default reducer;