import React from 'react';
import classes from './Banner.module.scss';
import { IMAGE_URl } from '../../../../constant/ResourceUrl';

const banner = (props) => {
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Background}>
                <img src={IMAGE_URl + 'tour-banner.jpg'} alt="promo" />
            </div>
            <div className={classes.Container}>
                <div className={classes.SearchForm}>
                    <div className={classes.Title}>Promoted Packages</div>
                </div>
            </div>
        </div>
    )

}

export default banner;
