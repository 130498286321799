import React from 'react';
import classes from './Spiner.module.scss';

function spiner(props){
    let spinerClasses = [classes.Ld];
    if (props.size) {
        spinerClasses.push(classes[props.size]);
    }
    return (
        <div className={spinerClasses.join(' ')}></div>
    );
}

export default spiner;