import React, { Component } from 'react'
import classes from './OtherPage.module.scss'
import PanelBox from '../../../../../../components/UI/PanelBox/PanelBox'

class OtherPage extends Component {
  render () {
    let description = true
    let useTip = true
    let useEtc = true

    let etc = null
    let tip = null
    let desc = null
    if (description === true) {
      if (useTip === true) {
        tip = (
          <div className={classes.Tip}>
            <div className={classes.ContentTip}>
              <div>Pengemudi Bus</div>
              <div className={classes.Money}>
                <div>11 hari x EUR 2,-</div>
                <div className={classes.TotalTip}>= EUR 22,-</div>
              </div>
            </div>
            <div className={classes.ContentTip}>
              <div>Tour Leader</div>
              <div className={classes.Money}>
                <div>13 hari x EUR 3,-</div>
                <div className={classes.TotalTip}>= EUR 39,-</div>
              </div>
            </div>
            <div className={[classes.Divider, classes.DivTip].join(' ')} />
            <div className={classes.ContentTip}>
              <div className={classes.Total}>Total</div>
              <div className={classes.Money}>
                <div className={[classes.TotalTip, classes.Total].join(' ')}>
                  = EUR 61,-
                </div>
              </div>
            </div>
          </div>
        )
      }
      desc = (
        <div className={classes.Desc}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </div>
      )
    }

    if (useEtc === true) {
      etc = (
        <ul>
          <li>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </li>
          <li>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </li>
          <li>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </li>
          <li>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </li>
        </ul>
      )
    }

    return (
      <div className={classes.Content}>
        <div className={classes.Panel}>
          <PanelBox>
            <div className={classes.Card}>
              <div className={classes.CardContent}>
                <div className={classes.Info}>
                  <div className={classes.Title}>1. Iklim dan Cuaca</div>
                  {desc}
                </div>
              </div>
            </div>
            <div className={classes.Divider} />
            <div className={classes.Card}>
              <div className={classes.CardContent}>
                <div className={classes.Info}>
                  <div className={classes.Title}>2. Pakaian</div>
                  {desc}
                  {tip}
                </div>
              </div>
            </div>
            <div className={classes.Divider} />
            <div className={classes.Card}>
              <div className={classes.CardContent}>
                <div className={classes.Info}>
                  <div className={classes.Title}>3. Lain-lain</div>
                  {etc}
                </div>
              </div>
            </div>
            <div className={classes.Divider} />
          </PanelBox>
        </div>
      </div>
    )
  }
}
export default OtherPage
