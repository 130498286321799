import React from 'react';
import classes from './PriceDetail.module.scss';
import PanelBox from '../../../UI/PanelBox/PanelBox';
import { currencyFormat, toCapitalize } from '../../../../helper/GlobalFunc';
import ContentLoader from 'react-content-loader';

const priceDetail = (props) => {
    let content = null;
    if (props.prices && props.total) {
        let priceDetail = props.prices.map((item, index) => {
            return (
                <li key={index} className={classes.PriceItem}>
                    <div className={classes.Name}>{toCapitalize(item.name)}</div>
                    <div className={classes.Price}>{currencyFormat(item.total)}</div>
                </li>
            )
        })

        content = (
            <React.Fragment>
                <ul className={classes.PriceList}>
                    {priceDetail}
                </ul>
                <div className={classes.Total}>
                    <div className={classes.Label}>Total</div>
                    <div className={classes.TotalPrice}>
                        {currencyFormat(props.total)}
                    </div>
                </div>
            </React.Fragment>
        )
    } else {
        content = (
            <div className="u-p-16">
                <ContentLoader
                    height={140}
                    speed={1}
                    primaryColor={'#e6e6e6'}
                >
                    <rect x="0" y="0" rx="2" ry="2" width="200" height="20" />
                    <rect x="280" y="0" rx="2" ry="2" width="200" height="20" />

                    <rect x="0" y="40" rx="2" ry="2" width="210" height="20" />
                    <rect x="280" y="40" rx="2" ry="2" width="200" height="20" />

                    <rect x="0" y="80" rx="2" ry="2" width="180" height="20" />
                    <rect x="280" y="80" rx="2" ry="2" width="200" height="20" />

                    <rect x="0" y="120" rx="2" ry="2" width="200" height="20" />
                    <rect x="280" y="120" rx="2" ry="2" width="200" height="20" />
                </ContentLoader>
            </div>
        );
    }
    
    return (
        <div className={classes.Wrapper}>
            <PanelBox>
                {content}
            </PanelBox>
        </div>
    )
}

export default priceDetail;