import React from 'react';
import classes from './BookingDetail.module.scss';
import ContentLoader from 'react-content-loader';
import { formatHumanDate, getTitle } from '../../../../../helper/GlobalFunc';
import PaymentStatus from '../../../../Shared/PaymentStatus/PaymentStatus';

const bookingDetail = (props) => {
    let content = <div className={classes.Wrapper}>
            <ContentLoader
                height={105}
                speed={1}
                primaryColor={'#e6e6e6'}>
                <rect x="0" y="0" rx="2" ry="2" width="100" height="8" />

                <rect x="0" y="25" rx="2" ry="2" width="50" height="8" />
                <rect x="0" y="45" rx="2" ry="2" width="80" height="10" />
                <rect x="130" y="25" rx="2" ry="2" width="50" height="8" />
                <rect x="130" y="45" rx="2" ry="2" width="80" height="10" />
                <rect x="260" y="25" rx="2" ry="2" width="50" height="8" />
                <rect x="260" y="45" rx="2" ry="2" width="80" height="10" />

                <rect x="0" y="75" rx="2" ry="2" width="50" height="8" />
                <rect x="0" y="95" rx="2" ry="2" width="80" height="10" />
                <rect x="130" y="75" rx="2" ry="2" width="50" height="8" />
                <rect x="130" y="95" rx="2" ry="2" width="80" height="10" />
            </ContentLoader>
        </div>;
    
    if (!props.isLoading && props.bookingData) {
        content = <div className={classes.Wrapper}>
            <div className={classes.Title}>Booking Details</div>
            <ul className={classes.List}>
                <li className={classes.Item}>
                    <div className={classes.Label}>Booking By</div>
                    <div className={classes.value}>{getTitle(props.bookingData.customerTitle)} {props.bookingData.customerName}</div>
                </li>
                <li className={classes.Item}>
                    <div className={classes.Label}>Order ID</div>
                    <div className={classes.value}>{props.bookingData.orderNumber}</div>
                </li>
                <li className={classes.Item}>
                    <div className={classes.Label}>Booking Date</div>
                    <div className={classes.value}>{formatHumanDate(props.bookingData.bookingDate)}</div>
                </li>
                <li className={classes.Item}>
                    <div className={classes.Label}>Status</div>
                    <div className={classes.value}>
                        <PaymentStatus status={props.bookingData.paymentStatus}/>
                    </div>
                </li>
            </ul>
        </div>
    }

    return <div>{content}</div>
}

export default bookingDetail;