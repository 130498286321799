import React from 'react';
import classes from './Breadcrumb.module.scss';
import { toCapitalize } from '../../../../helper/GlobalFunc';

const breadcrumb = props => {
    let linkList = [];
    if (props.linkList && props.linkList.length > 0) {
        linkList = props.linkList.map((item, index) => {
            if (props.linkList.length === index + 1){
                return <li key={index}>{toCapitalize(item.name)}</li>
            }
            return <li key={index}><a href={item.url}>{item.name}</a></li>
        });
    }

    return (
        <div className={classes.Wrapper}>
            <ul className={classes.BreadcrumbList}>
                {linkList}
            </ul>
        </div>
    );
}

export default breadcrumb;