import React, { Component } from 'react';
import Backdrop from '../Backdrop/Backdrop';
import classes from './Alert.module.scss';
import successIcon from '../../../assets/images/ico-succes.png';
import Button from '../Button/Button';
import CSSTransition from 'react-transition-group/CSSTransition';

class Alert extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div>
                <Backdrop showBackdrop={this.props.showAlert}/>
                <CSSTransition
                in={this.props.showAlert}
                mountOnEnter
                unmountOnExit
                timeout={160}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
                }}>
                    <div className={classes.Wrapper}>
                        <div className={classes.Alert}>
                            <div className={classes.Icon}>
                                <img src={successIcon} alt="Success" />
                            </div>
                            <div className={classes.Title}>Thank You!</div>
                            <div className={classes.Description}>Your request is submitted <br/>We will call you back within 24hrs.</div>
                            <div className={classes.Action}>
                                <div className={classes.SingleBtn}>
                                    <Button 
                                        classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                                        clicked={this.props.confirmAlertClicked}>{this.props.confirmLabel}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default Alert;