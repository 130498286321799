import React, {  } from 'react';
import Modal from '../../../../../UI/Modal/Modal';
import Select from '../../../../../UI/Inputs/Select/Select';
import Button from '../../../../../UI/Button/Button';
import { formatShortDate } from '../../../../../../helper/GlobalFunc';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../../redux/Shared/Member/MemberAction';

function birthDateModal(props) {
    const updateFormDateOfBirth = () => {
        let date = props.formBirthDate.year.value + '-' + props.formBirthDate.month.value + '-' + props.formBirthDate.day.value;
        let dataMember = {
            birthDate: formatShortDate(new Date(date))
        }
        props.submitChangedMember(dataMember)
    }
    return (
        <Modal 
            title="Add Date of Birth"
            show={props.showUpdateDateOfBirthForm}
            closeClicked={() => props.showHideUpdateDateOfBirthClicked(false)}>
                <div className="u-mb-40">
                    <div className="u-mb-24">
                        <div className="label">Date of Birth</div>
                        <div className="u-display-flex">
                            <div className="u-flex-2">
                                <Select 
                                    placeholder="Day"
                                    value={props.formBirthDate.day.value}
                                    options={props.formBirthDate.day.options}
                                    changed={(event) => props.selectChangedDateOfBirth(event, 'day')}/>
                            </div>
                            <div className="u-flex-5 u-ml-8 u-mr-8">
                                <Select 
                                    placeholder="Month"
                                    value={props.formBirthDate.month.value}
                                    options={props.formBirthDate.month.options}
                                    changed={(event) => props.selectChangedDateOfBirth(event, 'month')}/>
                            </div>
                            <div className="u-flex-3">
                                <Select 
                                    placeholder="Year"
                                    value={props.formBirthDate.year.value}
                                    options={props.formBirthDate.year.options}
                                    changed={(event) => props.selectChangedDateOfBirth(event, 'year')}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Button 
                        clicked={() => updateFormDateOfBirth()}
                        isLoading={props.isSubmitLoading}
                        classes={['BtnRed', 'BtnMedium', 'BtnBlock']}>Add</Button>
                </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        isSubmitLoading: state.MemberReducer.isSubmitLoading,
        formBirthDate: state.MemberReducer.formBirthDate
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectChangedDateOfBirth: (event, inputIdentifier) => dispatch(memberAction.selectChangedDateOfBirth(event, inputIdentifier)),
        submitChangedMember: (dataMember) => dispatch(memberAction.submitChangedMember(dataMember, 'generalForm'))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(birthDateModal);