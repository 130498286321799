import React, { Component } from 'react';
import classes from './Photo.module.scss';
import PanelBox from '../../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../../UI/Icon/Icon';
import Slider from 'react-slick';
import PhotoItem from './PhotoItem/PhotoItem';


function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={[classes.Arrow, classes.PrevArrow].join(' ')}
        onClick={onClick}>
        <div className={classes.LeftIcon}>
            <Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
        </div>
      </div>
    );
}
  
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
        className={[classes.Arrow, classes.NextArrow].join(' ')}
        onClick={onClick}>
        <div className={classes.RightIcon}>
            <Icon name="ico-caret-down" fill="#000000" stroke="none" width={48}/>
        </div>
        </div>
    );
}

class Photo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSlide: 1,
        };
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            autoplay: false,
            autoplaySpeed: 2500,
            dotsClass: "slick-dots " + classes.DotWrapper,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            afterChange: current => {
                this.setState({ currentSlide: current + 1 })
            },
            customPaging: function(i) {
                return (
                  <div className="banner-product"></div>
                );
            },
        };
    
        let imageList = null;
        if (this.props.imageList && this.props.imageList.length > 0) {
            imageList = this.props.imageList.map((photo, index) => {
                return (
                    <div className={classes.Item} key={index}>
                        <PhotoItem photo={photo} />
                    </div>
                );
            });
        }
    
        return (
            <PanelBox>
                <div className={classes.Wrapper}>
                    <div className={classes.Title}>Photos</div>
                    <div className={classes.Slider}>
                        <div className={classes.Page}>
                            <Icon name="ico-image" fill="#ffffff" stroke="none" width={20}/>
                            <div className={classes.PageNumber}>{this.state.currentSlide}/{this.props.imageList.length}</div>
                        </div>
                        <Slider {...settings}>
                            {imageList}
                        </Slider>
                    </div>
                </div>
            </PanelBox>
        );
    }
}

export default Photo;
