import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';

export const inputChange = (identifier, event) => {
    let value = event.target.value;
    return {
        type: 'INPUT_CHANGE',
        payload: {identifier, value}
    }
}

export const setSelected = (identifier, val) => {
    return {
        type: 'SET_SELECTED',
        payload: {identifier, val}
    }
}

export const verifyClicked = (res) => {
    return {
        type: 'VERIFY_CLICKED'
    }
}

export const formChecking = () => {
    return {
        type: 'FORM_CHECKING'
    };
};

export const alertConfirmed = () => {
    return {
        type: 'ALERT_CONFIRMED'
    };
}

export const loadingSubmitForm = () => {
    return {
        type: 'LOADING_SUBMIT_FORM'
    }
}

export const submitSuccess = () => {
    return {
        type: 'SUBMIT_SUCCESS'
    }
}

export const formSubmited = (postData) => {
    return dispatch => {
        dispatch(loadingSubmitForm());
        axios.post(ANTAVAYA_API + 'v1/mailer/inquiry', postData)
            .then( response => {
                dispatch(submitSuccess());
            })
            .catch(function (error) {
                console.log('ERR', error);
            });
    }
}