import React from 'react';
import classes from './ContactInfo.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import TextField from '../../../../UI/Inputs/TextField/TextField';
import MobileSelectFlag from '../../../../UI/Inputs/MobileSelectFlag/MobileSelectFlag';
import Switch from '../../../../UI/Inputs/Switch/Switch';
import MobileSelect from "../../../../UI/Inputs/MobileSelect/MobileSelect";

const contactInfo = props => {
    return (
        <PanelBox isOverflow={false}>
            <div className={classes.Wrapper}>
                <div>
                    <ul className={classes.Form}>
                        <li className={classes.Row}>
                            <div className={classes.Input}>
                                <label>Title</label>
                                <MobileSelect
                                    value={props.contactInfo.title.value}
                                    label="Title"
                                    options={props.contactInfo.title.options}
                                    changed={event => props.inputChanged(event, 'title')}/>
                            </div>
                        </li>
                        <li className={classes.Row}>
                            <div className={classes.Input}>
                                <label>First Name</label>
                                <TextField
                                    placeholder="e.g. Julie"
                                    value={props.contactInfo.firstName.value}
                                    changed={(event) => props.inputChanged(event.target.value, 'firstName')}
                                    hasError={props.contactInfo.firstName.hasError && props.contactInfo.firstName.errorMsg !== ''}
                                    errorMsg={props.contactInfo.firstName.errorMsg} />
                            </div>
                        </li>
                        <li className={classes.Row}>
                            <div className={classes.Input}>
                                <label>Last Name</label>
                                <TextField
                                    placeholder="e.g. Applesed"
                                    value={props.contactInfo.lastName.value}
                                    changed={(event) => props.inputChanged(event.target.value, 'lastName')}
                                    hasError={props.contactInfo.lastName.hasError && props.contactInfo.lastName.errorMsg !== ''}
                                    errorMsg={props.contactInfo.lastName.errorMsg} />
                            </div>
                        </li>
                        <li className={classes.Row}>
                            <div className={classes.Input}>
                                <label>Email</label>
                                <TextField
                                    placeholder="e.g. youremail@example.com"
                                    value={props.contactInfo.email.value}
                                    changed={(event) => props.inputChanged(event.target.value, 'email')}
                                    hasError={props.contactInfo.email.hasError && props.contactInfo.email.errorMsg !== ''}
                                    errorMsg={props.contactInfo.email.errorMsg} />
                            </div>
                        </li>
                        <li className={[classes.Row, classes.MultiColumn].join(' ')}>
                            <div className={[classes.Input, classes.PhoneWrapper].join(' ')}>
                                <label>Phone Number</label>
                                <div className={classes.Inline}>
                                    <div className={classes.DialCode}>
                                        <MobileSelectFlag
                                            value={props.contactInfo.dialCode.value}
                                            selected={val => props.inputChanged(val, 'dialCode')} />
                                    </div>
                                    <div className={classes.Phone}>
                                        <TextField
                                            type="text"
                                            placeholder="e.g. 8123456789"
                                            value={props.contactInfo.phone.value}
                                            changed={(event) => props.inputChanged(event.target.value, 'phone')}
                                            hasError={props.contactInfo.phone.hasError && props.contactInfo.phone.errorMsg !== ''}
                                            errorMsg={props.contactInfo.phone.errorMsg} />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className={classes.Action}>
                        <div>I’m Participant</div>
                        <div>
                            <Switch
                                checked={props.contactInfo.isParticipant.value}
                                changed={(event) => props.inputChanged(event.target.checked, 'isParticipant')} />
                        </div>
                    </div>
                </div>
            </div>
        </PanelBox>
    );
}

export default contactInfo;