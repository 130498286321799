import React, { useState } from 'react';
import classes from './VoucherInput.module.scss';
import SearchAutocomplete from '../SearchAutocomplete/SearchAutocomplete';

const voucherInput = (props) => {
    const [voucherList] = useState([ {name: 'All Voucher', value: 'all-voucher'} ]);
    const [voucherSelected, setVoucherSelected] = useState('all-voucher');

    const showBackdropHandler = () => {
        props.showBackdrop();
    }

    const itemSelectedHandler = (val) => {
        props.hideBackdropClicked();
        setVoucherSelected(val);
    }

    const regionInputChangeHandler = (event) => {
        setVoucherSelected(event.target.value);
    }

    let wrapperClass = [classes.Wrapper];
    let formClasses = [classes.Form];
    if (props.type === 'small') {
        wrapperClass.push(classes.TopLeftRadius);
        formClasses.push(classes.Small);
    }

    return (
        <div>
            <div className={wrapperClass.join(' ')}>
                <div className={formClasses.join(' ')}>
                    <div className={classes.InputWrapper}>
                        <SearchAutocomplete
                            isReadOnly={true}
                            label="VOUCHER"
                            placeholder="Select a Voucher"
                            value='All Voucher'
                            dropdownList={voucherList}
                            showBackdrop={showBackdropHandler}
                            changed={(event) => regionInputChangeHandler(event)}
                            itemSelected={(val) => itemSelectedHandler(val)}/>
                    </div>
                </div>
                <div className={classes.Action}>
                    <button className={classes.Button} onClick={() => props.exploreClicked(voucherSelected)}>Explore</button>
                </div>
            </div>
        </div>
    )
}

export default voucherInput;