import React from 'react';
import imgSrc from '../../../../../../../assets/images/avatar.jpg';
import antavayaImgSrc from '../../../../../../../assets/images/antavaya-logo.png';
import ImgThumb from '../../../../../../UI/ImgThumb/ImgThumb';

function contact() {
    return(
        <div>
            <div className="u-bg-white_dark u-br-4 u-p-24 u-color-black u-mb-16">
                <div className="u-fw-500 u-mb-24">Tour Leader</div>
                <div className="u-display-flex u-align-items-center">
                    <div className="u-flex-10 u-text-left">
                        <ImgThumb src={imgSrc} />
                    </div>
                    <span className="u-flex-10">
                        <div className="u-fs-12 u-color-grey u-mb-10">Name</div>
                        <div className="u-fs-14 u-fw-300">Samanta Siregar</div>
                    </span>
                    <div className="u-flex-10">
                        <div className="u-fs-12 u-color-grey u-mb-10">WhatsApp</div>
                        <div className="u-fs-14 u-fw-300">+62 878 8765 8765</div>
                    </div>
                    <div className="u-flex-10"></div>
                </div>
            </div>
            <div className="u-bg-white_dark u-br-4 u-p-24 u-color-black">
                <div className="u-fw-500 u-mb-24">Antavaya</div>
                <div className="u-display-flex">
                    <div className="u-flex-10 u-text-left">
                        <ImgThumb src={antavayaImgSrc} />
                    </div>
                    <div className="u-flex-10">
                        <div className="u-fs-12 u-color-grey u-mb-10">Call Center</div>
                        <div className="u-fs-14 u-fw-300">+6221 6253 919</div>
                    </div>
                    <div className="u-flex-10">
                        <div className="u-fs-12 u-color-grey u-mb-10">WhatsApp</div>
                        <div className="u-fs-14 u-fw-300">+62813 8396 1533</div>
                    </div>
                    <div className="u-flex-10">
                        <div className="u-fs-12 u-color-grey u-mb-10">E-mail</div>
                        <div className="u-fs-14 u-fw-300">hello@antavaya.com</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default contact;