export const BRANCH_LIST = [
    {
        "name": "Jakarta",
        "stores":
        [
            {
                "address": "Jl. Hayam Wuruk No. 88 Jakarta 11160",
                "fax": "+62 21 600 0170",
                "lat": -6.154852,
                "lng": 106.8160753,
                "name": "Hayam Wuruk",
                "phone": "+62 21 600 1888",
                "whatsapp": "62216001888"
            },
            {
                "address": "Jl. Melawai Raya No. 116 - B Jakarta 12160",
                "fax": "+62 21 720 2314",
                "lat": -6.245781,
                "lng": 106.798584,
                "name": "Melawai",
                "phone": "+62 21 600 1888"
            },
            {
                "address": "Gandaria City 1st Floor Unit 168 Jl. Sultan Iskandar Muda No. 57 Kebayoran Lama, Jakarta 12240",
                "fax": "+62 21 2900 7873",
                "lat": -6.2445439,
                "lng": 106.7816411,
                "name": "Gandaria City",
                "phone": "+62 21 2900 7898",
                "whatsapp": "622129007898"
            },
            {
                "address": "Lower Ground No. 221 A Jl. Let. Jend. S. Parman Kav. 28, Jakarta 11470",
                "fax": "+62 21 2920 0209",
                "lat": -6.177634,
                "lng": 106.7889988,
                "name": "Central Park",
                "phone": "+62 21 2920 0208"
            },
            {
                "address": "Jl. Batutulis Raya No. 38, Jakarta 10120",
                "fax": "+62 21 345 5252",
                "lat": -6.163413,
                "lng": 106.8226243,
                "name": "Batutulis",
                "phone": "+62 21 380 0202"
            },
            {
                "address": "Jl. Boulevard Raya Blok LA 6/19 Kelapa Gading, Jakarta 14240",
                "fax": "+62 21 453 2352",
                "lat": -6.1580632,
                "lng": 106.9043946,
                "name": "Kelapa Gading",
                "phone": "+62 21 450 0066"
            },
            {
                "address": "Lower Ground Floor Unit 07 Jl. Casablanca Raya Kav. 88, Jakarta 12870",
                "fax": "",
                "lat": -6.2239477,
                "lng": 106.8430854,
                "name": "Kota Kasablanka",
                "phone": "+62 21 2290 1993"
            },
            {
                "address": "Jl. Hayam Wuruk No. 88 Jakarta 11160",
                "fax": "+62 21 600 0170",
                "lat": -6.154852,
                "lng": 106.8160753,
                "name": "Sub Agent",
                "phone": "+62 21 600 1888"
            },
            {
                "address": "Jl. Melawai Raya No. 116 - B Jakarta 12160",
                "fax": "+62 21 720 2314",
                "lat": -6.245781,
                "lng": 106.798584,
                "name": "Melawai Umroh",
                "phone": "+62 21 723 0215"
            }
        ]
    },
    {
        "name": "Bandung",
        "stores":
        [
            {
                "address": "Jl. Sunda No. 42 Bandung 40112, Jawa Barat",
                "fax": "+62 22 426 1759",
                "lat": -6.9232617,
                "lng": 107.6110092,
                "name": "Bandung",
                "phone": "+62 22 426 1739"
            },
            {
                "address": "Jl. Pasir Kaliki No. 161 Bandung 40171, Jawa Barat",
                "fax": "+62 22 423 7216",
                "lat": -6.902983,
                "lng": 107.5950579,
                "name": "Pasir Kaliki",
                "phone": "+62 22-6046285"
            },
            {
                "address": "1st Floor/ B110 Jl. Gatot Subroto No. 289 Bandung 40273",
                "fax": "+62 22 8734 1789",
                "lat": -6.9251105,
                "lng": 107.6348577,
                "name": "Trans Studio Mall Bandung",
                "phone": "+62 22 8734 1799"
            },
            {
                "address": "Jl. Sunda No. 42 Bandung 40112, Jawa Barat",
                "fax": "+62 22 426 1759",
                "lat": -6.9232617,
                "lng": 107.6110092,
                "name": "Umroh Bandung",
                "phone": "+62 22 426 1739"
            }
        ]
    },
    {
        "name": "Makasar",
        "stores":
        [
            {
                "address": "Jl. Dr. Wahidin Sudirohusodo No. 34 A Makassar 90174",
                "fax": "+62 411 361 3910",
                "lat": -5.1304837,
                "lng": 119.4099814,
                "name": "Makasar",
                "phone": "+62 411 361 8648"
            }
        ]
    },
    {
        "name": "Palembang",
        "stores":
        [
            {
                "address": "Ground Floor Unit 15 - A, Jl. Radial No. 26 Ilir, Bukit Kecil Palembang 30135",
                "fax": "+62 711 564 9408",
                "lat": -2.9791756,
                "lng": 104.7432399,
                "name": "Transmart Palembang City Center",
                "phone": "+62 711 564 9267"
            }
        ]
    },
    {
        "name": "Balikpapan",
        "stores":
        [
            {
                "address": "Komplek Ruko Mal Fantasi AB 1 No. 23-24 Jl. MT Haryono Balikpapan Baru 76114",
                "fax": "+62 542 878 394",
                "lat": -1.26212,
                "lng": 116.8890384,
                "name": "Balikpapan",
                "phone": "+62 542 872 626"
            }
        ]
    },
    {
        "name": "Surabaya",
        "stores":
        [
            {
                "address": "2nd Floor Unit 236 Jln. Dharmahusada Indah Timur No.35-37 Surabaya 60115 Jawa Timur",
                "fax": "+62 31 5820 1494",
                "lat": -7.275708,
                "lng": 112.7799513,
                "name": "Galaxy Sub",
                "phone": "+62 31 5820 1493 / +62 31 5820"
            },
            {
                "address": "Jl. Bengawan No. 51 Surabaya 60241, Jawa Timur",
                "fax": "+62 31 568 4522",
                "lat": -7.2901941,
                "lng": 112.7343645,
                "name": "Surabaya",
                "phone": "+62 31 566 2030"
            },
            {
                "address": "Jl. Bengawan No. 51 Surabaya 60241, Jawa Timur",
                "fax": "+62 31 568 4522",
                "lat": -7.2901941,
                "lng": 112.7343645,
                "name": "Umroh Surabaya",
                "phone": "+62 31 566 2030"
            }
        ]
    },
    {
        "name": "Bogor",
        "stores":
        [
            {
                "address": "Unit GF - 11A, Jl. KH R. Abdullah Bin Nuh No. 36, Bogor 16166, Jawa Barat",
                "fax": "+62 251 835 6865",
                "lat": -6.5562844,
                "lng": 106.775168,
                "name": "Transmart Yasmin",
                "phone": "+62 251 835 6861"
            }
        ]
    },
    {
        "name": "Denpasar",
        "stores":
        [
            {
                "address": "Kompleks Perkantoran Duta Permai Blok I/H Jl. Dewi Sartika, Denpasar Bali 80114",
                "fax": "+62 361 231 180",
                "lat": -8.669781,
                "lng": 115.2142573,
                "name": "Bali",
                "phone": "+62 361 235 581"
            }
        ]
    },
    {
        "name": "Cibubur",
        "stores":
        [
            {
                "address": "Unit LG # 55, Jl. Alternatif Cibubur No. 230, Harjamukti Cimanggis, Kota Depok, West Java 16454",
                "fax": "",
                "lat": -6.3754313,
                "lng": 106.9011301,
                "name": "Trans Studio Mall Cibubur",
                "phone": "+62 21 3971 5595"
            }
        ]
    }
];
