import React from "react";
import classes from "./ContactInfo.module.scss";
import PanelBox from "../../../../../components/UI/PanelBox/PanelBox";
import TextField from "../../../../../components/UI/Inputs/TextField/TextField";
import TextArea from "../../../../../components/UI/Inputs/TextArea/TextArea";
import MobileSelectFlag from "../../../../../components/UI/Inputs/MobileSelectFlag/MobileSelectFlag";
import MobileSelect from "../../../../../components/UI/Inputs/MobileSelect/MobileSelect";

const contactInfo = (props) => {
	return (
		<PanelBox>
			<div className={classes.Wrapper}>
				<div>
					<ul className={classes.Form}>
						<li className={classes.Row}>
							<div className={classes.Input}>
								<label>Title</label>
								<MobileSelect
									value={props.contactInfo.title.value}
									label="Title"
									options={props.contactInfo.title.options}
									changed={(value) => props.inputChanged("title", value)}
								/>
							</div>
						</li>
						<li className={classes.Row}>
							<div className={classes.Input}>
								<label>First Name</label>
								<TextField
									placeholder="e.g. Julie"
									value={props.contactInfo.firstName.value}
									changed={(event) =>
										props.inputChanged("firstName", event.target.value)
									}
									hasError={
										props.contactInfo.firstName.hasError &&
										props.contactInfo.firstName.errorMsg !== ""
									}
									errorMsg={props.contactInfo.firstName.errorMsg}
								/>
							</div>
						</li>
						<li className={classes.Row}>
							<div className={classes.Input}>
								<label>Last Name</label>
								<TextField
									placeholder="e.g. Applesed"
									value={props.contactInfo.lastName.value}
									changed={(event) =>
										props.inputChanged("lastName", event.target.value)
									}
									hasError={
										props.contactInfo.lastName.hasError &&
										props.contactInfo.lastName.errorMsg !== ""
									}
									errorMsg={props.contactInfo.lastName.errorMsg}
								/>
							</div>
						</li>
						<li className={classes.Row}>
							<div className={classes.Input}>
								<label>Email</label>
								<TextField
									placeholder="e.g. youremail@example.com"
									value={props.contactInfo.email.value}
									changed={(event) =>
										props.inputChanged("email", event.target.value)
									}
									hasError={
										props.contactInfo.email.hasError &&
										props.contactInfo.email.errorMsg !== ""
									}
									errorMsg={props.contactInfo.email.errorMsg}
								/>
							</div>
						</li>
						<li className={[classes.Row, classes.MultiColumn].join(" ")}>
							<div className={[classes.Input, classes.PhoneWrapper].join(" ")}>
								<label>Phone Number</label>
								<div className={classes.Inline}>
									<div className={classes.DialCode}>
										<MobileSelectFlag
											value={props.contactInfo.dialCode.value}
											selected={(val) => props.inputChanged("dialCode", val)}
										/>
									</div>
									<div className={classes.Phone}>
										<TextField
											type="text"
											placeholder="e.g. 8123456789"
											value={props.contactInfo.phone.value}
											changed={(event) =>
												props.inputChanged("phone", event.target.value)
											}
											hasError={
												props.contactInfo.phone.hasError &&
												props.contactInfo.phone.errorMsg !== ""
											}
											errorMsg={props.contactInfo.phone.errorMsg}
										/>
									</div>
								</div>
							</div>
						</li>
						<li className={classes.Row}>
							<div className={classes.Input}>
								<label>Have a Question?</label>
								<TextArea
									placeholder="Type here"
									value={props.inquiry.question.value}
									changed={(event) =>
										props.inquiryChanged("question", event.target.value)
									}
									hasError={
										props.inquiry.question.hasError &&
										props.inquiry.question.errorMsg !== ""
									}
									errorMsg={props.inquiry.question.errorMsg}
								/>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</PanelBox>
	);
};

export default contactInfo;
