import React from 'react';
import classes from './VisiMisi.module.scss';
import { VISI_MISI_LIST } from '../../../../../constant/Content';
import { DEFAULT_IMG } from '../../../../../constant/Content';

const visiMisi = () => {
    const visiMisiList = VISI_MISI_LIST.map((item, index) => {
        return (
            <li key={index}>
                <div className={classes.Icon}>
                    <img onError={DEFAULT_IMG} src={item.icon} alt={item.title} />
                </div>
                <div className={classes.Content}>
                    <div>
                        <div className={classes.Title}>{item.title}</div>
                        <div className={classes.Description}>{item.description}</div>
                    </div>
                </div>
            </li>
        );
    });

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className="u-text-center u-mb-60 u-color-white">
                    <div className={classes.Title}>Antavaya Vision And Mission</div>
                </div>

                <ul className={classes.List}>
                    {visiMisiList}
                </ul>
            </div>
        </div>
    );
}

export default visiMisi;