const ITINERARY_DUMMY = [
    {
        airlineCode: 'BI',
        airlineName: 'Royal Brunei Airlines',
        arrivalAirportCode: 'DXB',
        arrivalTime: '03:35+1',
        departureAirportCode: 'CGK',
        departureTime: '13:10',
        tourFacility: null,
        tourImage: null,
        tourItineraryDay: 1,
        tourItineraryDescription:
            'Hari ini Anda berkumpul di Bandara International Soekarno Hatta untuk bersama-sama berangkat menuju kota Dubai di negara Uni Arab Emirates dengan berganti pesawat di Bandar Seri Begawan.',
        tourItineraryTitle: 'JAKARTA - BANDAR SERI BEGAWAN'
    },
    {
        airlineCode: null,
        airlineName: null,
        arrivalAirportCode: null,
        arrivalTime: null,
        departureAirportCode: null,
        departureTime: null,
        tourFacility: [
            { tourFacilityName: 'Breakfast' },
            { tourFacilityName: 'Lunch' }
        ],
        tourImage: [
            'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fdark-mountain.net%2Fcontent%2Fuploads%2F2013%2F12%2FP1060166s.jpg&f=1&nofb=1',
            'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F3.bp.blogspot.com%2F-RyoSMWDUGZM%2FUQfJxppog8I%2FAAAAAAAAfg0%2FBwg3ohIgPUY%2Fs1600%2F305341-svetik.jpg&f=1&nofb=1',
            'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi0.wp.com%2Ftechbeasts.com%2Fwp-content%2Fuploads%2F2016%2F05%2FFree-Download-Mountain-Hd-Wallpapers.jpg%3Fresize%3D1920%252C1200&f=1&nofb=1',
            'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F2.bp.blogspot.com%2F-GS38GIXs-NM%2FUQfUp6TMdgI%2FAAAAAAAAg0E%2FN4mXc4t6vgQ%2Fs1600%2FAhklun_and_Wood_River_Mountains.jpg&f=1&nofb=1',
            'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.desicomments.com%2Fwallpapers%2Fwp-content%2Fuploads%2F2014%2F12%2FSnowy-mountain.jpg&f=1&nofb=1',
            'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmobilegamegraphics.com%2Fwp-content%2Fuploads%2F2015%2F06%2FMountain1.jpg&f=1&nofb=1'
        ],
        tourItineraryDay: 2,
        tourItineraryDescription:
            "Setibanya di Dubai, Anda akan diajak orientasi kota Dubai dengan photostop dengan latar belakang Burj Khalifa yang merupakan gedung tertinggi di dunia dengan ketinggian mencapai 830m, Burj Al Arab salah satu hotel termewah di dunia dan landmark wisata terkenal di Dubai dengan restoran akuarium bawah laut. Anda akan diajak untuk menggunakan Abra Taxi yang merupakan transportasi air Dubai yang menyuguhkan keindahan pemandangan kota Dubai menuju Gold Souk pasar tradisional yang menjual perhiasan & Spice Souk merupakan pasar tradisional rempah – rempah, photostop di Marina City merupakan sebuah distrik buatan manusia di daerah yang belakangan ini disebut sebagai 'Dubai baru' dan Atlantis Palm adalah resor yang dibangun di Palm Jumeirah di Dubai yang bentuk arsitekturnya didasarkan pada Atlantis Resort di Bahama. Kemudian Anda akan diajak berbelanja di Dubai Mall dan melihat Dubai Fountain. Bermalam di Dubai. (MP/MS/--). Hotel : Ibis Hotel / Setaraf",
        tourItineraryTitle: 'TIBA DI DUBAI',
        hotelDetail: [
            {
                hotelRoom:
                    'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.omnihotels.com%2F-%2Fmedia%2Fimages%2Fhotels%2Fmsyrvr%2Fhotel%2Fmsyrvr-omni-riverfront-hotel-deluxe-room.jpg%3Fh%3D660%26la%3Den%26w%3D1170&f=1&nofb=1',
                hotelName: 'Ibis Hotel Rotorua',
                hotelClass: 3
            }
        ]
    },
    {
        airlineCode: null,
        airlineName: null,
        arrivalAirportCode: null,
        arrivalTime: null,
        departureAirportCode: null,
        departureTime: null,
        tourFacility: null,
        tourImage: null,
        tourItineraryDay: 3,
        tourItineraryDescription:
            "Setibanya di Dubai, Anda akan diajak orientasi kota Dubai dengan photostop dengan latar belakang Burj Khalifa yang merupakan gedung tertinggi di dunia dengan ketinggian mencapai 830m, Burj Al Arab salah satu hotel termewah di dunia dan landmark wisata terkenal di Dubai dengan restoran akuarium bawah laut. Anda akan diajak untuk menggunakan Abra Taxi yang merupakan transportasi air Dubai yang menyuguhkan keindahan pemandangan kota Dubai menuju Gold Souk pasar tradisional yang menjual perhiasan & Spice Souk merupakan pasar tradisional rempah – rempah, photostop di Marina City merupakan sebuah distrik buatan manusia di daerah yang belakangan ini disebut sebagai 'Dubai baru' dan Atlantis Palm adalah resor yang dibangun di Palm Jumeirah di Dubai yang bentuk arsitekturnya didasarkan pada Atlantis Resort di Bahama. Kemudian Anda akan diajak berbelanja di Dubai Mall dan melihat Dubai Fountain. Bermalam di Dubai. (MP/MS/--). Hotel : Ibis Hotel / Setaraf",
        tourItineraryTitle: 'TIBA DI DUBAI'
    },
    {
        airlineCode: 'BI',
        airlineName: 'Royal Brunei Airlines',
        arrivalAirportCode: 'DXB',
        arrivalTime: '03:35+1',
        departureAirportCode: 'CGK',
        departureTime: '13:10',
        tourFacility: null,
        tourImage: null,
        tourItineraryDay: 4,
        tourItineraryDescription:
            'Hari ini Anda berkumpul di Bandara International Soekarno Hatta untuk bersama-sama berangkat menuju kota Dubai di negara Uni Arab Emirates dengan berganti pesawat di Bandar Seri Begawan.',
        tourItineraryTitle: 'JAKARTA - BANDAR SERI BEGAWAN'
    },
    {
        airlineCode: null,
        airlineName: null,
        arrivalAirportCode: null,
        arrivalTime: null,
        departureAirportCode: null,
        departureTime: null,
        tourFacility: [
            { tourFacilityName: 'Breakfast' },
            { tourFacilityName: 'Lunch' }
        ],
        tourImage: null,
        tourItineraryDay: 5,
        tourItineraryDescription:
            "Setibanya di Dubai, Anda akan diajak orientasi kota Dubai dengan photostop dengan latar belakang Burj Khalifa yang merupakan gedung tertinggi di dunia dengan ketinggian mencapai 830m, Burj Al Arab salah satu hotel termewah di dunia dan landmark wisata terkenal di Dubai dengan restoran akuarium bawah laut. Anda akan diajak untuk menggunakan Abra Taxi yang merupakan transportasi air Dubai yang menyuguhkan keindahan pemandangan kota Dubai menuju Gold Souk pasar tradisional yang menjual perhiasan & Spice Souk merupakan pasar tradisional rempah – rempah, photostop di Marina City merupakan sebuah distrik buatan manusia di daerah yang belakangan ini disebut sebagai 'Dubai baru' dan Atlantis Palm adalah resor yang dibangun di Palm Jumeirah di Dubai yang bentuk arsitekturnya didasarkan pada Atlantis Resort di Bahama. Kemudian Anda akan diajak berbelanja di Dubai Mall dan melihat Dubai Fountain. Bermalam di Dubai. (MP/MS/--). Hotel : Ibis Hotel / Setaraf",
        tourItineraryTitle: 'TIBA DI DUBAI'
    },
    {
        airlineCode: null,
        airlineName: null,
        arrivalAirportCode: null,
        arrivalTime: null,
        departureAirportCode: null,
        departureTime: null,
        tourFacility: null,
        tourImage: null,
        tourItineraryDay: 6,
        tourItineraryDescription:
            "Setibanya di Dubai, Anda akan diajak orientasi kota Dubai dengan photostop dengan latar belakang Burj Khalifa yang merupakan gedung tertinggi di dunia dengan ketinggian mencapai 830m, Burj Al Arab salah satu hotel termewah di dunia dan landmark wisata terkenal di Dubai dengan restoran akuarium bawah laut. Anda akan diajak untuk menggunakan Abra Taxi yang merupakan transportasi air Dubai yang menyuguhkan keindahan pemandangan kota Dubai menuju Gold Souk pasar tradisional yang menjual perhiasan & Spice Souk merupakan pasar tradisional rempah – rempah, photostop di Marina City merupakan sebuah distrik buatan manusia di daerah yang belakangan ini disebut sebagai 'Dubai baru' dan Atlantis Palm adalah resor yang dibangun di Palm Jumeirah di Dubai yang bentuk arsitekturnya didasarkan pada Atlantis Resort di Bahama. Kemudian Anda akan diajak berbelanja di Dubai Mall dan melihat Dubai Fountain. Bermalam di Dubai. (MP/MS/--). Hotel : Ibis Hotel / Setaraf",
        tourItineraryTitle: 'TIBA DI DUBAI'
    },
    {
        airlineCode: 'BI',
        airlineName: 'Royal Brunei Airlines',
        arrivalAirportCode: 'DXB',
        arrivalTime: '03:35+1',
        departureAirportCode: 'CGK',
        departureTime: '13:10',
        tourFacility: null,
        tourImage: null,
        tourItineraryDay: 7,
        tourItineraryDescription:
            'Hari ini Anda berkumpul di Bandara International Soekarno Hatta untuk bersama-sama berangkat menuju kota Dubai di negara Uni Arab Emirates dengan berganti pesawat di Bandar Seri Begawan.',
        tourItineraryTitle: 'JAKARTA - BANDAR SERI BEGAWAN'
    },
    {
        airlineCode: null,
        airlineName: null,
        arrivalAirportCode: null,
        arrivalTime: null,
        departureAirportCode: null,
        departureTime: null,
        tourFacility: [
            { tourFacilityName: 'Breakfast' },
            { tourFacilityName: 'Lunch' }
        ],
        tourImage: null,
        tourItineraryDay: 8,
        tourItineraryDescription:
            "Setibanya di Dubai, Anda akan diajak orientasi kota Dubai dengan photostop dengan latar belakang Burj Khalifa yang merupakan gedung tertinggi di dunia dengan ketinggian mencapai 830m, Burj Al Arab salah satu hotel termewah di dunia dan landmark wisata terkenal di Dubai dengan restoran akuarium bawah laut. Anda akan diajak untuk menggunakan Abra Taxi yang merupakan transportasi air Dubai yang menyuguhkan keindahan pemandangan kota Dubai menuju Gold Souk pasar tradisional yang menjual perhiasan & Spice Souk merupakan pasar tradisional rempah – rempah, photostop di Marina City merupakan sebuah distrik buatan manusia di daerah yang belakangan ini disebut sebagai 'Dubai baru' dan Atlantis Palm adalah resor yang dibangun di Palm Jumeirah di Dubai yang bentuk arsitekturnya didasarkan pada Atlantis Resort di Bahama. Kemudian Anda akan diajak berbelanja di Dubai Mall dan melihat Dubai Fountain. Bermalam di Dubai. (MP/MS/--). Hotel : Ibis Hotel / Setaraf",
        tourItineraryTitle: 'TIBA DI DUBAI'
    },
    {
        airlineCode: null,
        airlineName: null,
        arrivalAirportCode: null,
        arrivalTime: null,
        departureAirportCode: null,
        departureTime: null,
        tourFacility: null,
        tourImage: null,
        tourItineraryDay: 9,
        tourItineraryDescription:
            "Setibanya di Dubai, Anda akan diajak orientasi kota Dubai dengan photostop dengan latar belakang Burj Khalifa yang merupakan gedung tertinggi di dunia dengan ketinggian mencapai 830m, Burj Al Arab salah satu hotel termewah di dunia dan landmark wisata terkenal di Dubai dengan restoran akuarium bawah laut. Anda akan diajak untuk menggunakan Abra Taxi yang merupakan transportasi air Dubai yang menyuguhkan keindahan pemandangan kota Dubai menuju Gold Souk pasar tradisional yang menjual perhiasan & Spice Souk merupakan pasar tradisional rempah – rempah, photostop di Marina City merupakan sebuah distrik buatan manusia di daerah yang belakangan ini disebut sebagai 'Dubai baru' dan Atlantis Palm adalah resor yang dibangun di Palm Jumeirah di Dubai yang bentuk arsitekturnya didasarkan pada Atlantis Resort di Bahama. Kemudian Anda akan diajak berbelanja di Dubai Mall dan melihat Dubai Fountain. Bermalam di Dubai. (MP/MS/--). Hotel : Ibis Hotel / Setaraf",
        tourItineraryTitle: 'TIBA DI DUBAI'
    }
]
export default ITINERARY_DUMMY