import React from 'react';
import { isNumber } from "util";
import CurrencyFormat from 'react-currency-format';
import { ADULT_TITLE } from '../constant/General';
import Moment from 'react-moment';

export const toCapitalize = text => {
    let newText = text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

    return newText;
}

export const pluralize = (count, singular, plural) => {
    if (count > 1) {
        return count + ' ' + plural;
    }
    return count + ' ' + singular;
}

export const formatDisplayDate = (date) => {
    let nowDate = new Date(date);
    let newDate = '';
    const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if (date !== undefined) {
        newDate = nowDate.getDate() + ' ' + months[nowDate.getMonth()] + ' ' + nowDate.getFullYear();
    }

    return newDate;
}

export const formatDisplayTime = (date) => {
    let time = date.split(' ')[1];
    let cleanTime = time.split(':')

    return `${cleanTime[0]}:${cleanTime[1]} WIB`
}

export const formatHumanDate = (date) => {
    return <Moment format="ddd, DD MMM YYYY">
        {(date) ? new Date(date): new Date()}
    </Moment>
}

export const formatShortDate = (date) => {
    let defaultDate = new Date();
    let nowDate = date;
    let newDate = null;

    if (isNumber(date)) {
        nowDate = defaultDate
    }
    
    if (nowDate !== undefined) {
        let year = nowDate.getFullYear()
        let month = ("0" + (nowDate.getMonth() + 1)).slice(-2);
        let day = ("0" + nowDate.getDate()).slice(-2);
        newDate = year+ '-' + month + '-' +day;
    }

    return newDate;
}

export const textLimit = (text, limit=25) => {
    if (text.length > limit) {
        let textSplit = text.substr(0, limit)
        return `${textSplit}...`
    } else {
        let textSplit = text
        return `${textSplit}`
    }
}

export const overflowBody = val => {
    if (val) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
    }
}

export const formatDisplayPhone = (str) => {
    var strx = str.split('');
    let div = Math.floor(str.length / 3);
    if (div > 0) {
        for (let index = 1; index <= div; index++) {
            strx[str.length - div - index] = '*';
        }
    }
    return strx.join('');
}

export const formatTitle = (camelCase) => {
    let text = camelCase.replace(/([A-Z])/g, (match) => ` ${match}`);
    return toCapitalize(text);
}

export const currencyFormat = val => {
    return (
        <CurrencyFormat value={val} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '}/>
    )
}

export const formatDateMobile = (d, m, y) => {
    let day = ("0" + d).slice(-2);
    let month = ("0" + m).slice(-2);
    let year = y;

    let newDate = new Date(year, month, day)
    return newDate;
}

export const getDay = (date='') => {
    let currentDate = new Date();
    if (date) {
        currentDate = new Date(date);
    }
    return currentDate.getDate().toString();
}

export const getMonth = (date='') => {
    let currentDate = new Date();
    if (date) {
        currentDate = new Date(date);
    }
    return (currentDate.getMonth() + 1).toString();
}

export const getYear = (date='') => {
    let currentDate = new Date();
    if (date) {
        currentDate = new Date(date);
    }
    return currentDate.getFullYear().toString();
}

export const objectToParam = (obj) => {
    let str = '';
    for (var key in obj) {
        if (str !== "") {
            str += "&";
        }
        str += key + "=" + encodeURIComponent(obj[key]);
    }
    return str;
}

export const getTitle = (val) => {
    return ADULT_TITLE.find(item => item.value === val).text;
}

export const admissionFilterForActiveData = (data, type) => {
    let tmp = [];
    let dateNow = new Date();

    if (type) {
        data.forEach(admissionExperience => {
            if (admissionExperience.type === type) {
                let { startPeriodOfBook, endPeriodOfBook } = admissionExperience;
                let startBook = new Date(startPeriodOfBook)
                let endBook = new Date(endPeriodOfBook)
        
                if (dateNow >= startBook  && dateNow <= endBook) {
                    tmp.push(admissionExperience)
                }
            } else {
                tmp.push(admissionExperience)
            }
        });
    } else {
        data.forEach(admission => {
            let { startPeriodOfBook, endPeriodOfBook } = admission;
            let startBook = new Date(startPeriodOfBook)
            let endBook = new Date(endPeriodOfBook)
    
            if (dateNow >= startBook  && dateNow <= endBook) {
                tmp.push(admission)
            }
        });
    }

    return tmp;
}