import CryptoJS from 'crypto-js';
import jwt from 'jsonwebtoken';

export const encrypt = data => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), 'antavaya123').toString();
};
  
export const decrypt = data => {
    let bytes = CryptoJS.AES.decrypt(data, 'antavaya123');
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const encryptUrl = (data) => {
    return jwt.sign(data, 'antavaya123');
}

export const decryptUrl = (data) => {
    return jwt.verify(data, 'antavaya123');
}