import React from 'react';
import classes from './Ribbon.module.scss';
import { isMobile } from 'react-device-detect';

const ribbon = (props) => {

    let text = props.text;
    
    let isDeviceMobile= (!isMobile) ? classes.Desktop : classes.Mobile;
    let position = (props.position === 'left') ? classes.RibbonLeft : classes.RibbonRight; // class default left
    let contentClasses = [position, isDeviceMobile];

    if (props.size === 'small') {
        contentClasses.push(classes.Small);
    }

    let content = (
        <div className={contentClasses.join(' ')}>
            {text}
        </div>
    )

    return (
        <div className={classes.Wrapper}>
            {content}
        </div>
    )
}

export default ribbon;