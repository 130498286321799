import React, { useState } from 'react';
import classes from './MobileSelectFlag.module.scss';
import Icon from '../../Icon/Icon';

import dialCodes from '../../../../assets/jsons/dialCode.json';
import { IMAGE_URl } from '../../../../constant/ResourceUrl';
import FlagList from './FlagList/FlagList';

const MobileSelectFlag = props => {
    const [showList, setShowList] = useState(false);

    const selectedHandler = val => {
        setShowList(false);
        props.selected(val)
    }

    const getTextHandler = (val, options) => {
        let index = options.findIndex(option => {
            let code = option.dial_code;
            if (props.type === 'country') {
                code = option.code;
            }
            return code === val;
        });

        let text = '';
        if (index >= 0) {
            if (props.type === 'country') {
                text = (
                    <div className={classes.Value}>
                        <div className={classes.Label}>{options[index].code}</div>
                    </div>
                );
            } else {
                text = (
                    <div className={classes.Value}>
                        <img src={IMAGE_URl + 'flags/' + options[index].code + '.png'} alt={options[index].name} />
                        <div className={classes.Label}>{options[index].dial_code}</div>
                    </div>
                );
            }
        }
        return text;
    }

    let text = '';
    if (props.value) {
        text = getTextHandler(props.value, dialCodes);
    }

    let iconClasses = [classes.Icon];
    let selectClasses = [classes.Select];
    if (showList) {
        iconClasses.push(classes.IsActive);
        selectClasses.push(classes.Focus);
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Select} onClick={() => setShowList(true)}>
                <div className={classes.Input}>
                    <div className={selectClasses.join(' ')}>
                        {text}
                    </div>
                </div>
                <div className={iconClasses.join(' ')}>
                    <Icon name="ico-caret-down" fill="#202124" stroke="none" />
                </div>
            </div>

            <FlagList 
                type={props.type}
                show={showList}
                dialCodes={dialCodes}
                selected={(val) => selectedHandler(val)}
                hideListClicked={() => setShowList(false)}/>
        </div>
    );
}

export default MobileSelectFlag;