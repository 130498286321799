import React from 'react';
import classes from './ForgotPassword.module.scss';
import Button from '../../../../UI/Button/Button';
import TextField from '../../../../UI/Inputs/TextField/TextField';

import { connect } from 'react-redux';
import * as forgotPasswordAction from '../../../../../redux/Shared/ForgotPassword/ForgotPasswordAction';

function forgotPassword (props) {
    const submitHandler = () => {
        if (props.isValidForm) {
            props.submitEmailHandler(props.form)
        } else {
            props.checkingForm();
        }
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.FormWrapper}>
                <div className={classes.Header}>
                    <div className={classes.Title}>Forgot Password ?</div>
                    <div className={classes.LabelHeader}>Back to <span className={classes.Link} onClick={() => props.switchFormClicked('login')}>Login</span></div>
                </div>
                <div className={[classes.Input, classes.LargeWidth].join(' ')}>
                    <label className={classes.Label}>Enter your email address below and we will send you a password reset link</label>
                    <TextField
                        placeholder="e.g. youremail@example.com" 
                        value={props.form.email.value} 
                        changed={(event) => props.inputChanged(event.target.value, 'email')}
                        hasError={props.form.email.hasError && props.form.email.errorMsg !== ''}
                        errorMsg={props.form.email.errorMsg} />
                </div>
                <div className={classes.Action}>
                    <Button 
                        classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
                        isLoading={props.isSubmitLoading}
                        clicked={submitHandler}>
                        Reset My Password
                    </Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        form: state.ForgotPasswordReducer.form,
        isValidForm: state.ForgotPasswordReducer.isValidForm,
        isSubmitLoading: state.ForgotPasswordReducer.isSubmitLoading,
        isSuccessSubmit: state.ForgotPasswordReducer.isSuccessSubmit
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChanged: (val, identifier) => dispatch(forgotPasswordAction.inputChanged(val, identifier)),
        submitEmailHandler: (data) => dispatch(forgotPasswordAction.submitEmail(data)),
        checkingForm: () => dispatch(forgotPasswordAction.checkingForm())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(forgotPassword);