import { updateObject } from '../../utility';
import * as validation from '../../../helper/Validation';
import { getDay, getMonth, getYear} from '../../../helper/GlobalFunc';
import Moment from 'moment';
import { ADULT_TITLE, DEFAULT_TITLE, DEFAULT_COUNTRY_CODE } from '../../../constant/General';

const currentDate = new Date();

const getDayy = (month, year) => {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date).getDate());
        date.setDate(date.getDate() + 1);
    }
    return days;
}
const currentDay = currentDate.getDate();
const indexMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();

const days = getDayy(indexMonth, currentYear);
const dayOptions = days.map((day, index) => {
    return {
        value: (index + 1).toString(),
        text: day
    }
});

const currentMonth = indexMonth + 1;
const monthOptions = Moment.months().map((month, index) => {
    return {
        value: (index + 1).toString(),
        text: month
    }
});
const limitYear = 80;
const limitYearPassport = 10;
let yearOption = [];
let yearOptionPassport = [];
for (let index = currentYear; index >= currentYear - limitYear; index--) {
    yearOption.push({
        value: index.toString(),
        text: index
    });
}
for (let index = currentYear; index <= currentYear + limitYearPassport; index++) {
    yearOptionPassport.push({
        value: index.toString(),
        text: index
    });
}

const participantForm = {
    title: {
        value: DEFAULT_TITLE,
        options: ADULT_TITLE,
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    day: {
        value: currentDay.toString(),
        options: dayOptions,
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    month: {
        value: currentMonth.toString(),
        options: monthOptions,
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    year: {
        value: (currentYear-2).toString(),
        options: yearOption,
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    firstName: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    lastName: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            required: true
        }
    },
    countryCode: {
        value: DEFAULT_COUNTRY_CODE,
        hasError: true,
        errorMsg: ''
    },
    passportNumber: {
        value: '',
        hasError: true,
        errorMsg: '',
        rules : {
            isNumeric: true
        }
    },
    dayPassport: {
        value: currentDay.toString(),
        options: dayOptions,
        hasError: true,
        errorMsg: ''
    },
    monthPassport: {
        value: currentMonth.toString(),
        options: monthOptions,
        hasError: true,
        errorMsg: ''
    },
    yearPassport: {
        value: currentYear.toString(),
        options: yearOptionPassport,
        hasError: true,
        errorMsg: ''
    },
};

const initialState = {
    participants: [],
    isFetchParticipant: true,
    isSubmit: false,
    isValidForm : false,
    successSubmit: false,
    isDeleteLoading: false,
    succesDelete: false,
    participantId: null,
    participantForm: participantForm
}

const setParticipantList = (state, action) => {
    return updateObject(state, {participants: action.payload});
}

const setIsFetchParticipant = (state, action) => {
    return updateObject( state, {isFetchParticipant: action.payload.value});
}

const setIsSubmit = (state, action) => {
    return updateObject( state, {isSubmit: action.payload.value});
}

const setIsDeleteLoading = (state, action) => {
    return updateObject( state, {isDeleteLoading: action.payload.value});
}

const setIsSuccessDelete = (state, action) => {
    return updateObject( state, {succesDelete: action.payload.value});
}

const setValidForm = (inputForm) => {
    let isValidForm = true;
    if (inputForm) {
        for (const inputIdentifier in inputForm) {
            if (inputForm[inputIdentifier].value !== undefined && inputForm[inputIdentifier].rules !== undefined ) {
                if (inputForm[inputIdentifier].hasError) {
                    isValidForm = false;
                    break;
                }
            }
        }
    }
    return isValidForm;
}

const inputChanged = (state, action) => {
    let identifier = action.payload.inputIdentifier;
    let value = action.payload.value;
    let participantData = {...state.participantForm};
    
    let validate = validation.validate(participantData[identifier].rules, value);
    participantData[identifier] = {...participantData[identifier], ...validate};

    let isValidForm = setValidForm(participantData);

    return updateObject(state, {participantForm: participantData, isValidForm});
}

const setEditParticipant = (state, action) => {
    let participant = action.payload.participant;
    let participantData = {...state.participantForm};

    participantData.title = {...participantData.title, ...{value:participant.title}};
    participantData.firstName = {...participantData.firstName, ...{value: participant.firstName}};
    participantData.lastName = {...participantData.lastName, ...{value:participant.lastName}};
    participantData.day = {...participantData.day, ...{value: getDay(participant.birthdate)}};
    participantData.month = {...participantData.month, ...{value: getMonth(participant.birthdate)}};
    participantData.year = {...participantData.year, ...{value: getYear(participant.birthdate)}};
    participantData.countryCode = {...participantData.countryCode, ...{value: participant.passportCountry}};
    participantData.passportNumber = {...participantData.passportNumber, ...{value: participant.passportNumber}};
    participant.dayPassport = {...participant.dayPassport, ...{value: getDay(participant.passportExpiredDate)}};
    participant.monthPassport = {...participant.monthPassport, ...{value: getMonth(participant.passportExpiredDate)}};
    participant.yearPassport = {...participant.yearPassport, ...{value: getYear(participant.passportExpiredDate)}};

    return updateObject(state, {
        participantForm: participantData, 
        participantId: participant.id,
        successSubmit: false})
}

const setAddParticipant = (state) => {
    return updateObject(state, {participantForm, participantId: null, successSubmit: false})
}

const updateParticipantList = (state, action) => {
    let participant = action.payload.participant;
    let id = action.payload.id;
    let participants = [...state.participants];

    if (id) {
        let index = participants.findIndex(item => (item.id) === id);
        if (index !== -1) {
            participants[index] = {...participants[index], ...participant}
        }
    } else {
        participants = [...participants, participant];
    }
    return updateObject(state, { participants, successSubmit: true})
}

const removeParticipant = (state, action) => {
    let participants = [...state.participants];
    let index = participants.findIndex(item => (item.id).toString() === action.payload.participantId);
    
    if (index !== -1) {
        participants.splice(index, 1);
    }

    return updateObject(state, { participants, successSubmit: false })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PARTICIPANT_LIST': return setParticipantList(state, action);
        case 'SET_IS_FETCH_PARTICIPANT': return setIsFetchParticipant(state, action);
        case 'SET_IS_SUBMIT_PARTICIPANT': return setIsSubmit(state, action);
        case 'SET_IS_DELET_PARTICIPANT_LOADING': return setIsDeleteLoading(state, action);
        case 'SET_IS_SUCCESS_DELET_PARTICIPANT': return setIsSuccessDelete(state, action);
        case 'PARTICIPANT_CHANGED': return inputChanged(state, action);
        case 'SET_EDIT_PARTICIPANT': return setEditParticipant(state, action);
        case 'SET_ADD_PARTICIPANT': return setAddParticipant(state);
        case 'UPDATE_PARTICIPANT_LIST': return updateParticipantList(state, action);
        case 'REMOVE_PARTICIPANT': return removeParticipant(state, action);
        default: return state;
    }
}

export default reducer;