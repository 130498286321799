import React from 'react';
import classes from './Admission.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import { DEFAULT_IMG } from '../../../../../constant/Content';
import Icon from '../../../../UI/Icon/Icon';
import CurrencyFormat from 'react-currency-format';
import { toCapitalize, formatDisplayDate } from '../../../../../helper/GlobalFunc';

const admission = (props) => {
    return (
        <div className={classes.TourItem} onClick={props.clicked}>
            <PanelBox>
                <div className={classes.Image}>
                    <div className={classes.Summary}>
                        <div className={classes.Title}>
                            {toCapitalize(props.admissionData.tourAdmissionName)}
                        </div>
                        <div className={classes.Subtitle}>
                            {props.admissionData.location}
                        </div>
                    </div>
                    <img onError={DEFAULT_IMG} src={props.admissionData.image} className={classes.Img} alt="" />
                </div>
                <div className={classes.Description}>
                    <div>
                        <div className={classes.Label}>Period of Validity</div>
                        <div className={classes.ValidDate}>
                            <Icon name="ico-calendar" fill="#666666" stroke="none" width={18} />
                            <div className={classes.Date}>{formatDisplayDate(props.admissionData.startPeriodOfTravelling)} - {formatDisplayDate(props.admissionData.endPeriodOfTravelling)}</div>
                        </div>
                    </div>
                    <div className={classes.PriceWrapper}>
                        <div className={classes.Label}>Start From</div>
                        <div className={classes.Price}>
                            <CurrencyFormat value={props.admissionData.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            <span className={classes.Perpax}>/Pax</span>
                        </div>
                    </div>
                </div>
            </PanelBox>
        </div>
    )
}

export default admission;
