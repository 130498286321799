import React from 'react';
import classes from './Contact.module.scss';
import PanelBox from '../../../../../components/UI/PanelBox/PanelBox';
import ImgThumb from '../../../../../components/UI/ImgThumb/ImgThumb';
import avatar from '../../../../../assets/images/avatar.jpg';
import logo from '../../../../../assets/images/antavaya-logo.png';

const contact = () => {
    return (
        <div className={classes.Wrapper}>
        <div className="u-p-16">
            <PanelBox>
                <div className={classes.Content}>
                    <div className={classes.Header}>
                        <div className={classes.Left}>
                            <div className={classes.WrapperLeft}>
                                <div className={classes.Name}>Samanta Siregar</div>
                                <div  className={classes.Label}>Tour Leader</div>
                            </div>
                        </div>
                        <div className={classes.Right}>
                            <ImgThumb type="circle" size="small" src={avatar} />
                        </div>
                    </div>
                    <div className={classes.Footer}>
                        <div className={classes.Detail}>
                            <div className={classes.Left}>Whatsapp</div>
                            <div className={classes.Right}>081709874563</div>
                        </div>
                        <div className={classes.Detail}>
                            <div className={classes.Left}>Call Center</div>
                            <div className={classes.Right}>081709874563</div>
                        </div>
                        <div className={classes.Detail}>
                            <div className={classes.Left}>Email</div>
                            <div className={classes.Right}>hallo@antavaya.com</div>
                        </div>
                    </div>
                </div>
            </PanelBox>
        </div>
            <div className="u-p-16">
                <PanelBox>
                    <div className={classes.Content}>
                        <div className={classes.Header}>
                            <div className={classes.Left}>
                            <div className={classes.WrapperLeft}>
                                <div className={classes.Name}>Antavaya</div>
                                <div  className={classes.Label}>Corporate Travel</div>
                            </div>
                            </div>
                            <div className={classes.Right}>
                                <ImgThumb type="circle" size="small" src={logo} />
                            </div>
                        </div>
                        <div className={classes.Footer}>
                            <div className={classes.Detail}>
                                <div className={classes.Left}>Whatsapp</div>
                                <div className={classes.Right}>081709874563</div>
                            </div>
                            <div className={classes.Detail}>
                                <div className={classes.Left}>Call Center</div>
                                <div className={classes.Right}>081709874563</div>
                            </div>
                            <div className={classes.Detail}>
                                <div className={classes.Left}>Email</div>
                                <div className={classes.Right}>hallo@antavaya.com</div>
                            </div>
                        </div>
                    </div>
                </PanelBox>
            </div>
        </div>
    )
}
export default contact;
