import React from 'react';
import classes from './Dropdown.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import Counter from '../../../../../UI/Counter/Counter';

const Dropdown = (props) => {

    let adultLabel = (
        <div className={classes.Label}>
            <div>Adult</div>
            <div className={classes.Desc}>(12 years+)</div>
        </div>
    );
    let childLabel = (
        <div className={classes.Label}>
            <div>Child</div>
            <div className={classes.Desc}>(2 - 12 years)</div>
        </div>
    );
    let infantLabel = (
        <div className={classes.Label}>
            <div>Infant</div>
            <div className={classes.Desc}>{'(< 2 years)'}</div>
        </div>
    );

    let adultCanDecrease = false;
    let adultCanIncrease = false;
    let childCanDecrease = false;
    let childCanIncrease = false;
    let infantCanDecrease = false;
    let infantCanIncrease = false;
    if (props.passengers.adult > 1) {
        adultCanDecrease = true;
    }
    if ((props.passengers.adult + props.passengers.child) < 7) {
        adultCanIncrease = true;
        childCanIncrease = true;
    }
    if (props.passengers.child > 0) {
        childCanDecrease = true;
    } 
    if (props.passengers.infant > 0) {
        infantCanDecrease = true;
    }

    if (props.passengers.infant < 4 && props.passengers.infant < props.passengers.adult) {
        infantCanIncrease = true;
    }


    return (
        <CSSTransition
            in={props.showDropdownList}
            mountOnEnter
            unmountOnExit
            timeout={270}
            classNames={{
                enterActive: classes.Open,
                exitActive: classes.Close
            }}>
            <div className={classes.Wrapper}>
                <ul className={[classes.Section, classes.PassengerWrapper].join(' ')}>
                    <li className={classes.Item}>
                        <Counter 
                            label={adultLabel}
                            value={props.passengers.adult}
                            canDecrease={adultCanDecrease}
                            decreaseClicked={() => props.decreaseOrIncreaseClicked('decrease', 'adult')}
                            canIncrease={adultCanIncrease}
                            increaseClicked={() => props.decreaseOrIncreaseClicked('increase', 'adult')}/>
                    </li>
                    <li className={classes.Item}>
                        <Counter 
                            label={childLabel}
                            value={props.passengers.child}
                            canDecrease={childCanDecrease}
                            decreaseClicked={() => props.decreaseOrIncreaseClicked('decrease', 'child')}
                            canIncrease={childCanIncrease}
                            increaseClicked={() => props.decreaseOrIncreaseClicked('increase', 'child')}/>
                    </li>
                    <li className={classes.Item}>
                        <Counter 
                            label={infantLabel}
                            value={props.passengers.infant}
                            canDecrease={infantCanDecrease}
                            decreaseClicked={() => props.decreaseOrIncreaseClicked('decrease', 'infant')}
                            canIncrease={infantCanIncrease}
                            increaseClicked={() => props.decreaseOrIncreaseClicked('increase', 'infant')}/>
                    </li>
                </ul>
                {/* <div className={[classes.Section, classes.SeatClassWrapper].join(' ')}>
                    <div>Cabin Class</div>
                    <div>Select</div>
                </div> */}
            </div>
        </CSSTransition>
    );
}

export default Dropdown;