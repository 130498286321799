import React, { useState } from 'react';
import classes from './HotelInput.module.scss';
import SearchAutocomplete from '../SearchAutocomplete/SearchAutocomplete';

const hotelInput = (props) => {
    const [regionList] = useState([ {name: 'All Region', value: 'all-region'} ]);
    const [regionSelected, setRegionSelected] = useState('all-region');

    const showBackdropHandler = () => {
        props.showBackdrop();
    }

    const itemSelectedHandler = (val) => {
        props.hideBackdropClicked();
        setRegionSelected(val);
    }

    const regionInputChangeHandler = (event) => {
        setRegionSelected(event.target.value);
    }

    let wrapperClass = [classes.Wrapper];
    let formClasses = [classes.Form];
    if (props.type === 'small') {
        wrapperClass.push(classes.TopLeftRadius);
        formClasses.push(classes.Small);
    }

    return (
        <div>
            <div className={wrapperClass.join(' ')}>
                <div className={formClasses.join(' ')}>
                    <div className={classes.InputWrapper}>
                        <SearchAutocomplete
                            isReadOnly={true}
                            label="REGION"
                            placeholder="Select a Region"
                            value='All Region'
                            dropdownList={regionList}
                            showBackdrop={showBackdropHandler}
                            changed={(event) => regionInputChangeHandler(event)}
                            itemSelected={(val) => itemSelectedHandler(val)}/>
                    </div>
                </div>
                <div className={classes.Action}>
                    <button className={classes.Button} onClick={() => props.searchHotelClicked(regionSelected)}>Explore</button>
                </div>
            </div>
        </div>
    )
}

export default hotelInput;