import React from 'react';
import classes from './CounterButton.module.scss';
import Icon from '../../Icon/Icon';

const CounterButton = (props) => {
    let btn = <Icon name="ico-minus-circle-o" fill="#CD2033" stroke="none" width={32} />;
    if (props.type === 'increase') {
        btn = <Icon name="ico-plus-circle-o" fill="#CD2033" stroke="none" width={32} />;
    }
    return (
        <button
            className={classes.Button}
            onClick={props.clicked}
            disabled={props.disabled}>
            {btn}
        </button>
    );
}

export default CounterButton;