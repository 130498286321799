import React from 'react';
import classes from './ParticipantLoader.module.scss';
import ContentLoader from 'react-content-loader';

const participantLoader = () => {
    return (
        <div className={classes.Wrapper}>
            <ContentLoader
                height={50}
                speed={1}
                primaryColor={'#e6e6e6'}
            >  
                <rect x="0" y="20" rx="4" ry="4" width="150" height="18" />
            </ContentLoader>
            <div className={classes.Room}>
                <div className={classes.Header}>
                    <ContentLoader
                        height={50}
                        speed={1}
                        primaryColor={'#e6e6e6'}
                    >  
                        <rect x="20" y="20" rx="4" ry="4" width="83" height="18" />
                    </ContentLoader>
                </div>
                <div className={classes.List}>
                    <ContentLoader
                        height={185}
                        speed={1}
                        primaryColor={'#e6e6e6'}
                    >  
                        <rect x="20" y="20" rx="4" ry="4" width="75" height="18" />
                        <rect x="20" y="45" rx="4" ry="4" width="100" height="15" />
                        <rect x="250" y="20" rx="4" ry="4" width="120" height="40" />

                        <rect x="20" y="80" rx="4" ry="4" width="75" height="18" />
                        <rect x="20" y="105" rx="4" ry="4" width="100" height="15" />
                        <rect x="250" y="80" rx="4" ry="4" width="120" height="40" />

                        <rect x="20" y="140" rx="4" ry="4" width="115" height="28" />
                    </ContentLoader>
                </div>
            </div>

            <ContentLoader
                    height={55}
                    speed={1}
                    primaryColor={'#e6e6e6'}
            >  
                <rect x="0" y="15" rx="4" ry="4" width="398" height="40" />
            </ContentLoader>
        </div>
    )
}

export default participantLoader;