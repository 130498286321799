import React from 'react';
import MetaTags from 'react-meta-tags';
import { ANTAVAYA } from '../constant/Antavaya';

const metaTag = (props) => {
    let title = ANTAVAYA.siteName + ' - The Smart Way to Travel';
    if (props.title) {
        title = ANTAVAYA.siteName + ' - ' + props.title;
    }
    let metaDescription = "AntaVaya menyediakan solusi perjalanan lengkap dari liburan (tour, tiket, hotel), meeting & event, inbound, incentive, Umroh, transportasi hingga korporat";
    if (props.description) {
        metaDescription = props.description;
    }
    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <meta property="og:title" content={title} />
            <meta property="og:image" content={ANTAVAYA.image} />

            <meta property="og:site_name"			content={ANTAVAYA.siteName} />
            <meta property="og:type"               	content={ANTAVAYA.type} />
            <meta property="og:description"        	content={metaDescription} />
            <meta property="og:url"                 content={ANTAVAYA.url} />
            <meta property="og:latitude" 			content={ANTAVAYA.latitude} />
            <meta property="og:longitude" 			content={ANTAVAYA.longitude} />
            <meta property="og:street-address" 		content={ANTAVAYA.longitude[0]} />
            <meta property="og:locality" 			content={ANTAVAYA.locality} />
            <meta property="og:region" 				content={ANTAVAYA.region} />
            <meta property="og:postal-code" 		content={ANTAVAYA.postalCode} />
            <meta property="og:country-name" 		content={ANTAVAYA.countryName} />
            <meta property="og:email" 				content={ANTAVAYA.email} />
            <meta property="og:phone_number" 		content={ANTAVAYA.hotline} />

            <meta itemProp="name" 					content={title} />
            <meta itemProp="description" 			content={metaDescription} />
            <meta itemProp="image" 					content={ANTAVAYA.image} />       

            <meta name="twitter:card" 				content="summary_large_image" />
            <meta name="twitter:site" 				content="@antavaya_id" />
            <meta name="twitter:title" 				content={title} />
            <meta name="twitter:creator" 			content="@antavaya_id" />
            <meta name="twitter:description" 		content={metaDescription} />
            <meta name="twitter:image" 				content={ANTAVAYA.image} />
            <meta name="twitter:image:src" 			content={ANTAVAYA.image} />	
            <meta name="twitter:domain" 			content={ANTAVAYA.domain} />

        </MetaTags>
    );
}

export default metaTag;