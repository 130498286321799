import React from 'react';
import classes from './RightContent.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Date from './Date/Date';
import Participant from './Participant/Participant';
import Button from '../../../../UI/Button/Button';
import CurrencyFormat from 'react-currency-format';
import { FacebookShareButton, FacebookIcon, 
        WhatsappShareButton, WhatsappIcon, 
        TwitterShareButton, TwitterIcon} from 'react-share';

import DateLoader from '../../../../UI/Loaders/Desktop/DetailPackages/DateLoader/DateLoader';
import ParticipantLoader from '../../../../UI/Loaders/Desktop/DetailPackages/ParticipantLoader/ParticipantLoader';
import PriceLoader from '../../../../UI/Loaders/Desktop/DetailPackages/PriceLoader/PriceLoader';
import ContentLoader from 'react-content-loader';

const rightContent = (props) => {
    let date = null;
    let participant = null;
    let price = null;
    let socialmedia = null;
    let title = null;
    let url = window.location.href;

    if (props.tourPackage && props.tourPackage.schedule && props.tourPackage.schedule.length > 0) {
        date = (
            <li className={classes.Item}>
                <Date
                    schedule={props.schedule}
                    setScheduleChanged={(val) => props.setScheduleChanged(val)} 
                    tourScheduleList={props.tourPackage.schedule}
                    tourPackage={props.tourPackage}
                    setItinerary={(scheduleId) => props.setItinerary(scheduleId)} />
            </li>
        );

        participant = (
            <li className={classes.Item}>
                <Participant region={props.tourPackage.region}/>
            </li>
        )

        price = (
            <li className={classes.Item}>
                <div className={classes.PriceWrapper}>
                    <div className={classes.Label}>Start from</div>
                    <div className={classes.Price}>
                        <CurrencyFormat value={props.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                        <span className={classes.Perpax}>/Pax</span></div>
                </div>
                <Button 
                    classes={['BtnRed', 'BtnMedium', 'BtnBlock']}
                    disabled={false}
                    clicked={props.submitBookingClicked}>
                    Book Now
                </Button>
            </li>
        )

        socialmedia = (
            <div className={classes.SocialMedia}>
                <div className={classes.Title}>Share this tour package</div>
                <ul className={classes.List}>
                    <li>
                        <WhatsappShareButton url={url}>
                            <WhatsappIcon size={32} />
                        </WhatsappShareButton>
                    </li>
                    <li>
                        <FacebookShareButton url={url}>
                            <FacebookIcon size={32} />
                        </FacebookShareButton>
                    </li>
                    <li>
                        <TwitterShareButton url={url}>
                            <TwitterIcon size={32} />
                        </TwitterShareButton>
                    </li>
                </ul>
            </div>
        )

        title = (
            <div className={classes.Header}>Choose Date and Participant</div>
        )
        
    } else {
        date = <DateLoader/>
        participant = <ParticipantLoader/>
        price = <PriceLoader/>
        title = (
            <div className={classes.Header}>
                <ContentLoader
                    height={20}
                    primaryColor={'#4296c4'}
                    secondaryColor={'#398dba'}
                >
                    <rect x="70" y="0" rx="4" ry="4" width="258" height="18" />
                </ContentLoader>
            </div>
        )
        socialmedia = null
    }

    return (
        <div>
            <PanelBox isOverflow={false}>
                <div className={classes.Wrapper}>
                    {title}
                    <ul className={classes.SectionList}>
                        {date}
                        {participant}
                        {price}
                    </ul>
                </div>
            </PanelBox>

            <div className="u-mt-16">
                <PanelBox>
                    {socialmedia}
                </PanelBox>
            </div>
        </div>

    );
}

export default rightContent;