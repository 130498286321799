import React, { Component } from 'react';
import Backdrop from '../../../../UI/Backdrop/Backdrop';
import classes from './RedirectPopup.module.scss';
// import successIcon from '../../../../../assets/images/ico-succes.png';
import CSSTransition from 'react-transition-group/CSSTransition';

class Alert extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div>
                <Backdrop showBackdrop={this.props.showPopup}/>
                <CSSTransition
                in={this.props.showPopup}
                mountOnEnter
                unmountOnExit
                timeout={160}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
                }}>
                    <div className={classes.Wrapper}>
                        <div className={classes.Alert}>
                            {/* <div className={classes.Icon}>
                                <img src={successIcon} alt="Success" />
                            </div> */}
                            <div className={classes.Title}>Directed to Pergi.com site</div>
                            <div className={classes.Description}>In a few seconds you will be directed to the Pergi.com site</div>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default Alert;