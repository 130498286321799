import React from 'react';
import classes from './TourList.module.scss';
import TourItem from './TourItem/TourItem';
// import { PER_PAGE_TOUR } from '../../../../../constant/General';
import EmptyData from '../../../../UI/EmptyData/EmptyData';
import PackageListLoader from '../../../../UI/Loaders/Desktop/List/PackagesListLoader';

const tourList = (props) => {
    // const [currentPage, setCurrentPage] = useState(1);
    // const [totalPage, setTotalPage] = useState(1);
    // const [maxShowItem, setMaxShowItem] = useState(PER_PAGE_TOUR);

    // const showMore = () => {
    //     let nextPage = currentPage + 1;
    //     if (currentPage < totalPage) {
    //         setCurrentPage(nextPage);
    //         setMaxShowItem(nextPage * PER_PAGE_TOUR);
    //     }
    // }

    let content = null;
    if (props.isFetchAdmission) {
        content = [];
        for (let index = 0; index < 5; index++) {
            let loader = (
                <div className={classes.Item} key={index}>
                    <PackageListLoader />
                </div>
            );
            content.push(loader);
        }
    } else {
        if (props.isErrorFetchAdmission) {
            content = (
                <EmptyData
                    type="tour-empty"
                    title="Tour Admission empty"
                    desc="Sorry tour is unavailable Please see other destinations"
                />
            );
        } else {
            if (props.admissionPackages && props.admissionPackages.length > 0) {
                content = props.admissionPackages.map((admission, key) => {
                    return (
                        <div className={classes.Item} key={key}>
                            <TourItem
                                packageItem={admission}
                                goToPage={(slug) => props.goToPage(slug)}
                            />
                        </div>
                    )
                })
            } else {
                content = (
                    <EmptyData
                        type="tour-empty"
                        title="Tour Admission empty"
                        desc="Sorry tour is unavailable Please see other destinations"
                    />
                );
            }
        }
    }

    return (
        <div className={classes.TourList}>
            {content}

            {/* <PanelBox>
                <div
                    onClick={showMore}
                    className={classes.ShowMore}>
                    SHOW MORE RESULTS
                </div>
            </PanelBox> */}
            
        </div>
    )
}

export default tourList;
