import React from 'react';
import classes from './RightContent.module.scss';
import PanelBox from '../../../../UI/PanelBox/PanelBox';
import Icon from '../../../../UI/Icon/Icon';
import { formatDisplayDate, currencyFormat } from '../../../../../helper/GlobalFunc';
import { DEFAULT_IMG } from '../../../../../constant/Content';

const rightContent = (props) => {
    let content = null;
    if (props.voucherDetail) {
        let benefits = null;
        if (props.voucherDetail.benefit) {
            benefits = props.voucherDetail.benefit.map((benefitItem, benefitKey) => {
                let benefitClasses = [classes.Benefit];
                let icon = 'ico-close-circle';
                let color = '#E6E6E6';
                if (benefitItem.status) {
                    icon = 'ico-check-circle';
                    color = '#73C700';
                    benefitClasses.push(classes.Active);
                }

                return (
                    <div key={benefitKey} className={benefitClasses.join(' ')}>
                        <Icon name={icon} fill={color} stroke="none" width={16} />
                        <div className={classes.Text}>
                            {benefitItem.title}
                        </div>
                    </div>
                );
            });
        }
        content = (
            <div>
                <li className={classes.Item}>
                    <div className={classes.Wrapper}>
                        <div className={classes.Image}>
                            <div className={classes.ImgWrap}>
                                <img onError={DEFAULT_IMG} src={props.voucherDetail.imageSource} alt="voucher"/>
                            </div>
                        </div>
                    </div>
                </li>
                <li className={[classes.Item, classes.Padding].join(' ')}>
                    <div className={classes.Body}>
                        <div className="u-my-16">
                            {benefits}
                        </div>
                        <div className={classes.Date}>
                            <div className={classes.Label}>Expired Date</div>
                            <div className={classes.Detail}>
                                <div className={classes.Icon}>
                                    <Icon name="ico-calendar" fill="#666666" stroke="none" width={20} />
                                </div>
                                {formatDisplayDate(props.voucherDetail.expiredDate)}
                            </div>
                        </div>
                    </div>
                </li>
                <li className={[classes.Item, classes.Padding].join(' ')}>
                    <div className={classes.PriceWrap}>
                        <div className={classes.Label}>Price</div>
                        <div className={classes.Detail}>
                            <span className={classes.Price}>{currencyFormat(props.voucherDetail.price)}</span>
                        </div>
                    </div>
                </li>
            </div>
        )
    }

    return (
        <PanelBox isOverflow={false}>
            <div className={classes.Wrapper}>
                <ul className={classes.SectionList}>
                    {content}
                </ul>
            </div>
        </PanelBox>
    )
}

export default rightContent;