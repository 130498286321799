import React, { useEffect } from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import MobileSelect from "../../../../../../UI/Inputs/MobileSelect/MobileSelect";
import Button from "../../../../../../UI/Button/Button";
import TextField from "../../../../../../UI/Inputs/TextField/TextField";
import MobileSelectFlag from "../../../../../../UI/Inputs/MobileSelectFlag/MobileSelectFlag";
import CheckBox from "../../../../../../UI/Inputs/CheckBox/CheckBox";
import { GUEST_TYPE_TOUR } from "../../../../../../../constant/General";
import PanelBox from "../../../../../../UI/PanelBox/PanelBox";
import classes from "./FillData.module.scss";
import Header from '../../../../../Shared/Header/Header';
import { formatDateMobile, overflowBody } from '../../../../../../../helper/GlobalFunc';
import CalendarSwipe from '../../../../../../UI/CalendarSwipe/CalendarSwipe';

function FillData(props) {
    let currentDate = new Date();
    let minDateAdult = new Date(currentDate.getFullYear() - 90, currentDate.getMonth(), currentDate.getDate());
    let maxDateAdult = new Date(currentDate.getFullYear() - 13, currentDate.getMonth(), currentDate.getDate());
    let minDateChild = new Date(currentDate.getFullYear() - 12, currentDate.getMonth(), currentDate.getDate());
    let maxDateChild = new Date(currentDate.getFullYear() - 2, currentDate.getMonth(), currentDate.getDate());
    let minExpiredDatePassport = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    let maxExpiredDatePassport = new Date(currentDate.getFullYear() + 5, currentDate.getMonth(), currentDate.getDate());

	useEffect(() => {
		overflowBody(props.showPopup);
    }, [props.showPopup]);
    
	let adultForm = null;
	let childForm = null;
	const visaLabel = <div className={[classes.Label, classes.Visa].join(" ")}>Sudah memiliki Visa</div>;

	const dateHandler = (date, roomIndex, guestType, guestIndex, identifier) => {
		let day = date.getDate().toString();
		let month = date.getMonth().toString();
		let year = date.getFullYear().toString();

		props.guestInputChanged(day, roomIndex, guestType, guestIndex, 'day'+identifier);
		props.guestInputChanged(month, roomIndex, guestType, guestIndex, 'month'+identifier);
		props.guestInputChanged(year, roomIndex, guestType, guestIndex, 'year'+identifier);
	}

	GUEST_TYPE_TOUR.forEach(guestType => {
		if (props.roomData[guestType] && props.roomData[guestType].length > 0) {
			const guestForm = props.roomData[guestType].map((form, guestIndex) => {
				return (
					<div key={guestIndex} className={classes.GuestWrapper}>
						<div className={classes.SubTitle}>
							{guestType === 'adult' ? 'Adult' : 'Children'} {guestIndex + 1}
						</div>
						<div className={classes.Panel}>
							<PanelBox>
								<ul className={classes.Form}>
									<li className={classes.Row}>
										<div className={[classes.Input, classes.LargeWidth].join(" ")}>
											<label className={classes.Label}>Title</label>
											<MobileSelect
												value={form.title.value}
												label="Title"
												options={form.title.options}
												changed={event => props.guestInputChanged(event, props.roomIndex, guestType, guestIndex, 'title')}/>
										</div>
									</li>
									<li className={[classes.Row]}>
										<div className={[classes.Input, classes.LargeWidth].join(" ")}>
											<label className={classes.Label}>First Name</label>
											<TextField
												placeholder="e.g. Julie"
												value={form.firstName.value}
												changed={event => props.guestInputChanged(event.target.value, props.roomIndex, guestType, guestIndex, 'firstName')}
												hasError={form.firstName.hasError && form.firstName.errorMsg !== ""}
												errorMsg={form.firstName.errorMsg}/>
										</div>
									</li>
									<li className={[classes.Row]}>
										<div className={[classes.Input, classes.LargeWidth].join(" ")}>
											<label className={classes.Label}>Last Name</label>
											<TextField
												placeholder="e.g. Applesed"
												value={form.lastName.value}
												changed={event => props.guestInputChanged(event.target.value, props.roomIndex, guestType, guestIndex, 'lastName')}
												hasError={form.lastName.hasError && form.lastName.errorMsg !== ""}
												errorMsg={form.lastName.errorMsg}/>
										</div>
									</li>
									<li className={[classes.Row]}>
										<div className={[classes.Input, classes.LargeWidth].join(" ")}>
											<label className={classes.Label}>Date of Birth</label>
											<CalendarSwipe
                                                minDate={(guestType === 'adult') ? minDateAdult : minDateChild}
                                                maxDate={(guestType === 'adult') ? maxDateAdult : maxDateChild}
												inputIdentifier="birthdate"
												title="Date of Birth"
												value={formatDateMobile(form.day.value, form.month.value, form.year.value)}
												changed={event => dateHandler(event, props.roomIndex, guestType, guestIndex, '')}/>
										</div>
									</li>
									<li className={[classes.Row]}>
										<div className={[classes.Input, classes.LargeWidth].join(" ")}>
											<CheckBox
												label={visaLabel}
												checked={form.isIncludeVisa.value}
												changed={event => props.guestInputChanged( event.target.checked, props.roomIndex, guestType, guestIndex, 'isIncludeVisa')}/>
										</div>
									</li>
                                    <hr className={classes.passportLineBreak}></hr>
                                    <li className={[classes.Row]}>
                                        <div className={classes.Inline}>
                                            <div className={[classes.Input, classes.SmallWidth, 'u-mr-8'].join(" ")}>
                                                <label className={classes.Label}>Country Code</label>
                                                <MobileSelectFlag
                                                    type="country"
                                                    value={form.countryCode.value}
                                                    selected={event => props.guestInputChanged(event, props.roomIndex, guestType, guestIndex, 'countryCode')} />
                                            </div>
                                            <div className={[classes.Input, classes.LargeWidth].join(" ")}>
                                                <label className={classes.Label}>Pasport Number</label>
                                                <TextField
                                                    placeholder="e.g. 645736457"
                                                    value={form.passportNumber.value}
                                                    changed={event => props.guestInputChanged(event.target.value, props.roomIndex, guestType, guestIndex, 'passportNumber')}
                                                    hasError={form.passportNumber.hasError && form.passportNumber.errorMsg !== ""}
                                                    errorMsg={form.passportNumber.errorMsg}/>
                                            </div>
                                        </div>
                                    </li>
									<li className={[classes.Row]}>
										<div className={[classes.Input, classes.LargeWidth].join(" ")}>
											<label className={classes.Label}>Expired Date</label>
											<CalendarSwipe
                                                minDate={minExpiredDatePassport}
                                                maxDate={maxExpiredDatePassport}
												inputIdentifier="expiredDate"
												title="Expired Date"
												value={formatDateMobile(form.dayPassport.value, form.monthPassport.value, form.yearPassport.value)}
												changed={date => dateHandler(date, props.roomIndex, guestType, guestIndex, 'Passport')}/>
										</div>
									</li>
								</ul>
							</PanelBox>
						</div>
					</div>
				);
			});
			if (guestType === 'adult') {
				adultForm = guestForm;
			} else if (guestType === 'child') {
				childForm = guestForm;
			}
		}
	});
	return (
		<CSSTransition
		in={props.showPopup}
		mountOnEnter
		unmountOnExit
		timeout={300}
		classNames={{
			enterActive: classes.Open,
			exitActive: classes.Closed
		}}>
		<div className={classes.Wrapper}>
			<div className={classes.Header}>
				<Header 
					classes={['Shadow']}
					leftIcon="back"
					leftClicked={props.back}
					content="Participants" />
			</div>
			<div className={classes.Content}>
				{adultForm}
				{childForm}
				<Button
					classes={["BtnMedium", "BtnBlock", "BtnRed"]}
					clicked={props.hidePopupClicked}>
					Save
				</Button>
			</div>
		</div>
		</CSSTransition>
	);
}

export default FillData;
