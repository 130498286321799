import React, { useState } from 'react';
import classes from './Sort.module.scss';
import onClickOutside from "react-onclickoutside";
import CSSTransition from 'react-transition-group/CSSTransition';
import Icon from '../../../../UI/Icon/Icon';

const sort = (props) => {
    const [showList, setShowList] = useState(false);

    sort.handleClickOutside = () => setShowList(false);

    const showListHandler = () => {
        setShowList(true);
    }

    const setActiveItemHandler = (value) => {
        props.setSelected(value);
        setShowList(false);
    }

    let DisplayValue = 'Recomended';
    const itemList = props.options.map((item ,index) => {
        let activeClass = null;
        if (props.defaultValue === item.value) {
            DisplayValue = item.name;
            activeClass = classes.Active;
        }
        return (
            <li key={index} 
                className={activeClass} 
                onClick={() => setActiveItemHandler(item.value)}>{item.name}
            </li>
        );

    })

    let iconClasses = [classes.Icon];
    if (showList) {
        iconClasses.push(classes.IsActive);
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Dropdown} onClick={() => showListHandler()}>
                <div className={classes.Input}>
                    <input placeholder="Sort by" value={DisplayValue} readOnly/>
                </div>
                <div className={iconClasses.join(' ')}>
                    <Icon name="ico-caret-down" fill="#202124" stroke="none" />
                </div>
            </div>
            <CSSTransition
                in={showList}
                mountOnEnter
                unmountOnExit
                timeout={270}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
                }}>
                <ul className={classes.List}>
                    {itemList}
                </ul>
            </CSSTransition>
        </div>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () => sort.handleClickOutside
};

export default onClickOutside(sort, clickOutsideConfig);
