import React from 'react';
import classes from './JobList.module.scss';
import Icon from '../../../../UI/Icon/Icon';

const jobList = (props) => {
    let careerList = [];
    if (props.careerList && props.careerList.length > 0) {
        careerList = props.careerList.filter((career) => {
            return (career.department === props.departmentValue || props.departmentValue === 'All Department') &&
                (career.location === props.locationValue || props.locationValue === 'All Location') && 
                (career.name.toLowerCase().indexOf(props.positionValue.toLowerCase()) !== -1 || props.positionValue === '') ? true : false;
        }).map((career, index) => {
            return (
                <li key={index} className={classes.ItemWrapper}>
                    <a href={'/career/' + career.slug}>
                        <div className={classes.Item}>
                            <div className={[classes.Column, classes.Bold].join(' ')}>{career.name}</div>
                            <div className={classes.Column}>{career.department}</div>
                            <div className={[classes.Column, classes.Location].join(' ')}>{career.location}</div>
                            <div className={classes.Icon}>
                                <Icon name="ico-arrow-right" fill="#CD2033" stroke="none" />
                            </div>
                        </div>
                    </a>
                </li>
            );
        });
    }

    let notFound = null;
    if (careerList.length === 0 ) {
        notFound = (
            <li className={classes.NotFound}>
                <div className={classes.Item}>
                    <div className={[classes.Column, 'u-text-center'].join(' ')}>
                        <div className="u-bold u-mb-8">We can’t find the job you are looking for</div>
                        <div className="u-color-grey">Please try different keywords or broaden your search filter.</div>
                    </div>
                </div>
            </li>
        );
    }
    return (
        <div className={classes.Wrapper}>
            <div className={classes.Head}>
                <div className={classes.Item}>
                    <div className={classes.Column}>POSITIONS</div>
                    <div className={classes.Column}>DEPARTMENTS</div>
                    <div className={[classes.Column, classes.Location].join(' ')}>LOCATIONS</div>
                </div>
            </div>
            <ul className={classes.List}>
                {careerList}
                {notFound}
            </ul>
        </div>
    );
}

export default jobList;