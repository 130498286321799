import React from 'react';
import classes from './TourInfo.module.scss';
import PanelBox from '../../../../../../UI/PanelBox/PanelBox';
import ImgThumb from '../../../../../../UI/ImgThumb/ImgThumb';
import Icon from '../../../../../../UI/Icon/Icon';
import { formatDisplayDate } from '../../../../../../../helper/GlobalFunc';

const tourInfo = (props) => {
    return (
        <PanelBox>
            <div className={classes.Wrapper}>
                <div>
                    <div className={classes.Header} >
                        <div className="u-mr-16">
                            <ImgThumb src={props.admissionDetail.image} size="small" />
                        </div>
                        <div className={classes.Title}>
                            {props.admissionDetail.tourAdmissionName}
                        </div>
                    </div>
                    <div className={classes.Body}>
                        <div className={classes.Item}>
                            <div className={classes.Detail}>
                                <div className={classes.Icon}>
                                    <Icon name="ico-calendar" stroke="none" fill="#818589" width={22}/> 
                                </div>
                                {formatDisplayDate(localStorage.getItem('date'))}
                            </div>
                        </div>
                        <div className={classes.Item}>
                            <div className={classes.Detail}>
                                <div className={classes.Icon}>
                                    <Icon name="ico-user-o" stroke="none" fill="#818589" width={22}/> 
                                </div>
                                {props.personsDetail.join(',  ')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PanelBox>
    )
}

export default tourInfo;
