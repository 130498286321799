import React, { useState } from 'react';
import classes from './Banner.module.scss';
import { IMAGE_URl } from '../../../../constant/ResourceUrl';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import BannerInput from '../../Shared/ThingsToDo/BannerInput/BannerInput';
import { withRouter } from 'react-router-dom';

const banner = (props) => {
    console.log('props', props)
    const [showBackdrop, setShowBackdrop] = useState(false);

    // eslint-disable-next-line
    let searchForm = React.createRef();

    const showBackdropHandler = () => {
        setShowBackdrop(true);
    }

    const hideBackdropHandler = () => {
        setShowBackdrop(false);
    }

    const searchActivityClickedHandler = () => {
        props.history.push('/activity/availability');
    }

    const searchAdmissionClickedHandler = () => {
        props.history.push('/admission/availability');
    }

    let zIndex = {
        zIndex:  100
    };
    if (showBackdrop) {
        zIndex = {
            zIndex:  660
        };
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Background}>
                <img src={IMAGE_URl + 'tour-banner.jpg'} alt="things-to-do"/>
            </div>
            <Backdrop 
                showBackdrop={showBackdrop}
                backdropClicked={() => setShowBackdrop(false)}/>
            <div className={classes.Container} style={zIndex}>
                <div className={classes.SearchForm}>
                    <div className={classes.Title}>Things To Do</div>
                    <div ref={(section) => { searchForm = section; }}>
                        <BannerInput
                            path={'things-to-do'}
                            backdropVisibility={showBackdrop}
                            hideBackdropClicked={hideBackdropHandler}
                            showBackdrop={showBackdropHandler}
                            searchActivityClicked={searchActivityClickedHandler}
                            searchAdmissionClicked={searchAdmissionClickedHandler}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(banner);
