import React, { useEffect } from 'react';
import classes from './EditPhoneNumber.module.scss';
import Popup from '../../../../../components/UI/Popup/Popup';
import TextField from '../../../../../components/UI/Inputs/TextField/TextField';
import MobileSelectFlag from '../../../../../components/UI/Inputs/MobileSelectFlag/MobileSelectFlag'
import Button from '../../../../../components/UI/Button/Button';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../redux/Shared/Member/MemberAction';

function editPhoneNumber(props) {
    useEffect(() => {
        props.hideSortPopupClicked()
    }, [props.successChangePhone])

    let updatePhone = () => {
        let payload = {
            countryCode: props.formPhone.dialCode.value,
            phoneNumber: props.formPhone.phone.value,
            ipAddress: '127.0.0.1'
        }
        props.submitChangedPhoneHandler(payload);
    }
    let content = (
        <div>
            <div className={classes.InputWrapper}>
                <div className="label">Phone Number</div>
                <div className={classes.Input}>
                    <div className="u-flex-2">
                        <MobileSelectFlag 
                            value={props.formPhone.dialCode.value} 
                            selected={val => props.setDialCodeHandler(val)} />
                    </div>
                    <div className="u-flex-8 u-ml-8">
                        <TextField
                            placeholder="e.g. 817****7645" 
                            value={props.formPhone.phone.value}
                            changed={(event) => props.inputChangedPhoneHandler(event, 'phone')}/>
                    </div>
                </div>
                <Button
                    classes={['BtnRed', 'BtnBlock', 'BtnMedium']}
                    clicked={() => updatePhone()}>
                    Save
                </Button>
            </div>
        </div>
    )

    return (
        <Popup
            show={props.show}
            hideClicked={props.hideSortPopupClicked}
            title="Change Phone Number"
            content={content}/>
    )
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        formPhone: state.MemberReducer.formPhone,
        successChangePhone: state.MemberReducer.successChangePhone
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inputChangedPhoneHandler: (event, inputIdentifier) => dispatch(memberAction.inputChangePhone(event, inputIdentifier)),
        submitChangedPhoneHandler: (data) => dispatch(memberAction.submitChangedMember(data, 'phoneForm')),
        setDialCodeHandler: (val) => dispatch(memberAction.setDialCodeHandler(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(editPhoneNumber);
