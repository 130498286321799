export function required(value) {
    let hasError = false;
    let errorMsg = '';
    hasError = value.trim() === '';
    if (hasError) {
        errorMsg = 'This field is required!';
    }
    return {hasError, errorMsg, value};
}

export function minLength(value, minLength) {
    let hasError = false;
    let errorMsg = '';
    hasError = value.length < minLength;
    if (hasError) {
        errorMsg = 'This field must be at least '+ minLength +' characters in length.';
    }
    return {hasError, errorMsg, value};
}

export function maxLength(value, maxLength) {
    let hasError = false;
    let errorMsg = '';
    hasError = value.length > maxLength;
    if (hasError) {
        errorMsg = 'This field cannot exceed '+ maxLength +' characters in length.';
    }
    return {hasError, errorMsg, value};
}

export function isEmail(value) {
    let hasError = false;
    let errorMsg = '';
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    hasError = !pattern.test(value);
    if (hasError) {
        errorMsg = 'This field must contain a valid email address.';
    }
    return {hasError, errorMsg, value};
}

export function isNumeric(val) {
    let hasError = false;
    let errorMsg = '';
    let value = val;
    const pattern = /\D/g;
    value = value.replace(pattern,'');
    return {hasError, errorMsg, value};
}

export function isNumericPhone(val) {
    let hasError = false;
    let errorMsg = '';
    let value = val;
    const charPattern = /\D/g;
    const zeroPattern = /^0+/;
    value = value.replace(zeroPattern,'');
    value = value.replace(charPattern,'');
    return {hasError, errorMsg, value};
}

export function validate(rules, value) {
    let validate = {
        hasError : false,
        errorMsg : '',
        value    : value
    }
    if (rules) {
        for (var rule in rules) {
            switch (rule) {
                case 'isNumeric':
                    validate = isNumeric(value);
                    value = validate.value;
                    break;
                case 'isNumericPhone':
                    validate = isNumericPhone(value);
                    value = validate.value;
                    break;
                case 'required':
                    validate = required(value);
                    break;
                case 'minLength':
                    validate = minLength(value, rules.minLength);
                    break;
                case 'maxLength':
                    validate = maxLength(value, rules.maxLength);
                    break;
                case 'isEmail':
                    validate = isEmail(value);
                    break;
            
                default:
                    break ;
            }

            if (validate.hasError) {
                break;
            }
        }
    }

    return validate;
}

export function isValidForm(form) {
    let isValid = true;
    if (form) {
        for (const inputIdentifier in form) {
            if (form[inputIdentifier].value !== undefined && form[inputIdentifier].rules !== undefined ) {
                if (form[inputIdentifier].hasError) {
                    isValid = false;
                    break;
                }
            }
        }
    }
    return isValid;
}

