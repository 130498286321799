import React from 'react';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import Banner from '../../../../components/Desktop/NonProducts/Banner/Banner';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import Career from '../../../../components/Desktop/NonProducts/Career/Career';
import MetaTag from '../../../../helper/MetaTag';

const careerContainer = props => {
    return (
        <div className="bg-white-dark min-width">
            <MetaTag 
                title="Career"/>
            <Header />
            <Banner title="Find Your Dream Job in Antavaya" image="https://media01.cdnpergi.com/antavaya/images/branch-office-1.jpg" />
            <Career />
            <Footer />
        </div>
    );
}

export default careerContainer;