import React, { useState, useEffect } from 'react';
import classes from './DetailConntainer.module.scss';
import ImageSlider from '../../../../components/Mobile/Tour/Detail/ImageSlider/ImageSlider';
import { toCapitalize } from '../../../../helper/GlobalFunc';
import DestinationList from '../../../../components/Mobile/Tour/Detail/DestinationList/DestinationList';
import FreeVoucher from '../../../../components/Mobile/Tour/Detail/FreeVoucher/FreeVoucher';
import AboutVoucher from '../../../../components/Mobile/Tour/Detail/FreeVoucher/AboutVoucher/AboutVoucher';
import ScheduleList from '../../../../components/Mobile/Tour/Detail/ScheduleList/ScheduleList';
import ListPanel from '../../../../components/Mobile/Tour/Detail/ListPanel/ListPanel';
import Icon from '../../../../components/UI/Icon/Icon';
import CurrencyFormat from 'react-currency-format';
import Button from '../../../../components/UI/Button/Button';
import Itinerary from '../../../../components/Mobile/Tour/Detail/Itinerary/Itinerary';
import Terms from '../../../../components/Mobile/Tour/Detail/Terms/Terms';
import Participant from '../../../../components/Mobile/Tour/Detail/Participant/Participant';
import history   from '../../../../history';
import queryString from 'query-string';
import DetailPackageLoader from '../../../../components/UI/Loaders/Mobile/DetailPackage/DetailPackageLoader';
import Included from '../../../../components/Mobile/Tour/Included/Included';
import UnavailableTour from '../../../../components/UI/UnavailableTour/UnavailableTour';
import Spiner from '../../../../components/UI/Loaders/SpinerDetail/Spiner';

import { connect } from 'react-redux';
import * as detailAction from '../../../../redux/Tour/Detail/DetailAction';
import * as tourBooking from '../../../../redux/Tour/Booking/BookingAction';
import ImageDetail from '../../../../components/Mobile/Tour/Detail/ImageDetail/ImageDetail';
import Header from '../../../../components/Mobile/Shared/Header/Header';

const DetailConntainer = props => {
    const [showItinerary, setShowItinerary] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showParticipant, setShowParticipant] = useState(false);
    const [isSecondHeader, setIsSecondHeader] = useState(false);
    const [showImageDetail, setShowImageDetail] = useState(false);
    const [showTourEmpty, seShowTourEmpty] = useState(false);
    const [showAboutVoucher, setShowAboutVoucher] = useState(false);

    useEffect( () => {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', handleScroll);
        let slug = props.match.params.slug;
        props.onInitDetailTour(slug);

        if (props.tourEmpty) {
            seShowTourEmpty(true);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }

    }, [props.tourEmpty]);

    const handleScroll = () => {
        const offsetTop = window.scrollY;
        if (offsetTop > 215) {
            setIsSecondHeader(true)
        } else {
            setIsSecondHeader(false);
        }
    }

    const backClickHandler = () => {
        if (props.history.action === 'PUSH') {
            const values = queryString.parse(props.location.search);
            if (values.page === 'booking') {
                props.history.push('/');
            } else {
                history.go(-1);
            }
        } else {
            props.history.push('/');
        }
    }

    const submitBookingClickHandler = () => {
        document.body.style.overflowY = 'hidden';
        const tourPackage = props.tourDetail;
        let rooms = [];

        // eslint-disable-next-line
        let loopRoom = props.roomList.map((room) => {
            let payload = {
                adult: room.adult.length,
                child: room.child.length,
                isExtraBed: room.isExtrabed
            }
            rooms.push(payload);
        })

        let dataTour = {
            tourScheduleId: props.scheduleId,
            tourInventoryCode: tourPackage.inventoryCode,
            room: rooms
        }
        
        props.onSubmitTour(dataTour, props.history)
    }

    const backToHome = () => {
        props.history.push('/');
        document.body.style.overflow = 'auto';
    }
    
    let modal = null;

    if (props.isLoading) {
        if (showTourEmpty) {
            modal = (
                <UnavailableTour
                showUnavailableTour={showTourEmpty}
                title="Tour Empty"
                description="Sorry, tour is unavailable. Please see other destinations."
                buttonLabel="Back to Home"
                redirect={backToHome}/>
            )
        }
        return (
            <div>
                {modal}
                <DetailPackageLoader/>
            </div>
        )
    } else {
        if(props.tourDetail !== null) {
            const title = props.tourDetail.days + 'D ' + toCapitalize(props.tourDetail.inventoryTitle);
            const tocList = props.tourDetail.toc;
            const includeList = props.tourDetail.include;
            const excludeList = props.tourDetail.exclude;
            const highlightList = props.tourDetail.sellingPointTags.map(item => item);
            const cancellationPolicyList = props.tourDetail.cancelTerm;
            let secondHeaderClasses = [classes.Nav, classes.SecondNav];
            if (isSecondHeader) {
                secondHeaderClasses.push(classes.Show);
            }

            const headTitle = <div className={classes.HeadTitle}>{title}</div>
            return (
                <div onScroll={handleScroll}>
                    <Spiner show={props.isLoadingSpinner} />
                    <ImageDetail
                        show={showImageDetail}
                        hidePopupClicked={() => setShowImageDetail(false)}
                        imageSet={props.tourDetail.images} />
                    <Participant 
                        show={showParticipant}
                        hidePopupClicked={() => setShowParticipant(false)}
                        price={props.priceStartFrom}
                        region={props.tourDetail.region}
                        submitBookingClicked={submitBookingClickHandler} />
                    <Terms
                        show={showTerms}
                        hidePopupClicked={() => setShowTerms(false)}
                        cancellationPolicyList={cancellationPolicyList}
                        tocList={tocList} />
                    <Itinerary
                        show={showItinerary}
                        hidePopupClicked={() => setShowItinerary(false)}
                        iteneraryList={props.tourDetail.itineraries}
                        downloadFile={props.tourDetail.pdf} />

                    <AboutVoucher
                        show={showAboutVoucher}
                        hidePopupClicked={() => setShowAboutVoucher(false)} />
                    
                    <div className={classes.Wrapper}>
                        <div className={classes.Nav}>
                            <Header 
                                classes={['BgTransparent']}
                                icoColor="white"
                                leftIcon="back"
                                leftClicked={backClickHandler} />
                        </div>
                        <div className={secondHeaderClasses.join(' ')}>
                            <Header
                                classes={['Shadow']}
                                leftIcon="back"
                                leftClicked={backClickHandler}
                                content={headTitle} />
                        </div>
    
                        <div className={classes.Content}>
                            <ImageSlider clicked={() => setShowImageDetail(true)} imageList={props.tourDetail.images}/>
                            <div className="u-p-16" >
                                <div className={classes.Title}>{title}</div>
                                <div className={classes.Includes}>
                                    {(props.tourDetail.includeFlight) &&
                                        <div className={classes.Item}>
                                            <Included label="Flight" color="black"/>
                                        </div>
                                    }
                                    {(props.tourDetail.includeHotel) &&
                                        <div className={classes.Item}>
                                            <Included label="Hotel" color="black"/>
                                        </div>
                                    }
                                </div>
                                <div className="u-mt-12">
                                    <DestinationList tourDestinations={props.tourDetail.destinations}/>
                                </div>
                                <div className="u-mt-12">
                                    <FreeVoucher clicked={() => setShowAboutVoucher(true)}/>
                                </div>
                            </div> 
                            <div>
                                <ScheduleList
                                    scheduleSelected={props.scheduleId} 
                                    scheduleList={props.tourDetail.schedule}
                                    scheduleClicked={(scheduleId) => props.scheduleChanged(scheduleId)}/>
                            </div>
                            <div className="u-p-16">
                                <div className="u-mb-24">
                                    <ListPanel title="Highlight" list={highlightList} listColor="green" listSize="small" isCollapseBtn={true} showMore={true} />
                                </div>
                                <div className="u-mb-24">
                                    <ListPanel title="Include" list={includeList} listColor="green" listSize="small" isCollapseBtn={true} showMore={true} />
                                </div>
                                <div className="u-mb-24">
                                    <ListPanel title="Exclude" list={excludeList} listColor="red" listSize="small" isCollapseBtn={true} showMore={true} />
                                </div>
                                
                                <ul className={classes.ItineraryTerms}>
                                    <li className={classes.Item} onClick={() => setShowItinerary(true)}>
                                        <span>Itinerary</span>
                                        <span>
                                            <Icon name="ico-arrow-right" width="20" stroke="none" fill="#202124" />
                                        </span>
                                    </li>
                                    <li className={classes.Item} onClick={() => setShowTerms(true)}>
                                        <span>Terms & Conditions</span>
                                        <span>
                                            <Icon name="ico-arrow-right" width="20" stroke="none" fill="#202124" />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className={classes.FooterWrapper}>
                            <div className={classes.PriceWrapper}>
                                <div className={classes.Label}>Start From</div>
                                <div className={classes.Price}>
                                    <CurrencyFormat value={props.priceStartFrom} displayType="text" thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                                    <span className={classes.Perpax}>/Pax</span>
                                </div>
                            </div>
                            <div className={classes.Button}>
                                <Button 
                                    classes={['BtnMedium', 'BtnBlock', 'BtnRed']}
                                    clicked={() => setShowParticipant(true)}>
                                    Add Participant
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.TourDetailReducer.isLoadingTourDetail,
        isLoadingSpinner: state.TourDetailReducer.isLoadingSpinner,
        roomList: state.TourDetailReducer.rooms,
        tourDetail: state.TourDetailReducer.tourDetail,
        schedule: state.TourDetailReducer.schedule,
        scheduleId: state.TourDetailReducer.scheduleId,
        tourEmpty: state.TourDetailReducer.tourEmpty,
        priceStartFrom: state.TourDetailReducer.priceStartFrom
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeLoadingSpinner : () => dispatch(detailAction.handleSpinner()),
        onInitDetailTour : (tourId) => dispatch(detailAction.initDetailTour(tourId)),
        onSubmitTour : (dataTour, history) => dispatch(tourBooking.submitTour(dataTour, history)),
        scheduleChanged : (scheduleId) => dispatch(detailAction.scheduleChanged(scheduleId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailConntainer);