import { axiosAuth } from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';
import * as resHandler from '../../../helper/ResHandler';
import { toast } from 'react-toastify';


export const setParticipantList = (data) => {
    return {
        type: 'SET_PARTICIPANT_LIST',
        payload : data
    }
}

export const inputChange = (value, inputIdentifier) => {
    return {
        type : 'PARTICIPANT_CHANGED',
        payload : { value, inputIdentifier } 
    }
}

export const addParticipant = (participant) => {
    return {
        type : 'ADD_PARTICIPANT',
        payload : { participant } 
    }
}

export const successSubmit = () => {
    return {
        type: 'SUCCESS_SUBMIT'
    }
}

export const setEditParticipant = (participant, index) => {
    return {
        type: 'SET_EDIT_PARTICIPANT',
        payload: { participant, index }
    }
}

export const setAddParticipant = () => {
    return {
        type: 'SET_ADD_PARTICIPANT'
    }
}

export const setIsFetchParticipant = (value) => {
    return {
        type: 'SET_IS_FETCH_PARTICIPANT',
        payload: { value }
    }
}

export const setIsSubmitParticipant = (value) => {
    return {
        type: 'SET_IS_SUBMIT_PARTICIPANT',
        payload: { value }
    }
}

export const setIsDeleteLoading = (value) => {
    return {
        type: 'SET_IS_DELET_PARTICIPANT_LOADING',
        payload: { value }
    }
}

export const setIsSuccesDelete = (value) => {
    return {
        type: 'SET_IS_SUCCESS_DELET_PARTICIPANT',
        payload: { value }
    }
}

export const updateParticipantList = (participant, id) => {
    return {
        type: 'UPDATE_PARTICIPANT_LIST',
        payload: { participant, id }
    }
}

export const removeParticipant = (participantId) => {
    return {
        type: 'REMOVE_PARTICIPANT',
        payload: { participantId }
    }
}

export const getParticipantList = () => {
    let page = 1;
    let perPage = 100;
    return dispatch => {
        axiosAuth.post(ANTAVAYA_API + `passenger/list?page=${page}&perPage=${perPage}`, {})
            .then((response) => {
                dispatch(setIsFetchParticipant(false));
                let passengers = [];
                if (response.data.data.list_passenger) {
                    passengers = response.data.data.list_passenger;
                }
                dispatch(setParticipantList(passengers))
            })
            .catch((error) => {
                dispatch(setIsFetchParticipant(false));
            });
    }
}

export const submitParticipant = (data, id) => {

    let url = ANTAVAYA_API + 'passenger/add';
    if (id) {
        url = ANTAVAYA_API + 'passenger/update';
    }

    return dispatch => {
        dispatch(setIsSubmitParticipant(true));
        axiosAuth.post(url, data)
            .then((res) => {
                dispatch(setIsSubmitParticipant(false));
                dispatch(updateParticipantList(res.data.data.passenger, id));
                if (res.data.code === 200) {
                }
            })
            .catch((error) => {
                dispatch(setIsSubmitParticipant(false));
                if (error.response) {
                    if (error.response.data.code === 401){
                        resHandler.unauthorize();
                    } else {
                        if (error.response.data.message){
                            switch (typeof error.response.data.message) {
                                case 'string':
                                    toast.error(error.response.data.message);
                                    break;
                                case 'object':
                                    for (const key in error.response.data.message) {
                                        toast.error(error.response.data.message[key][0]);
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
            });
    }
}

export const onDeleteParticipant = (data) => {
    return dispatch => {
        dispatch(setIsDeleteLoading(true));
        dispatch(setIsSuccesDelete(false));
        axiosAuth.post(ANTAVAYA_API + 'passenger/delete', data)
            .then((res) => {
                console.log(res);
                dispatch(setIsDeleteLoading(false));
                dispatch(setIsSuccesDelete(true));
                dispatch(removeParticipant(data.passengerID));
            })
            .catch((error) => {
                dispatch(setIsDeleteLoading(false));
                console.log('error', error)
            })
    }
}
