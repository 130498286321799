import { updateObject } from '../../utility';
import * as validation from '../../../helper/Validation';
import { ADULT_TITLE, DEFAULT_TITLE, DEFAULT_DIAL_CODE } from '../../../constant/General';

const initialState = {
    showRegisterForm: false,
    isValidForm: false,
    successSignup: false,
    signupData: {
        title: {
            value: DEFAULT_TITLE,
            options: ADULT_TITLE,
            hasError: false,
            errorMsg: '',
            rules : {
                required: true
            }
        },
        firstname: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
            }
        },
        lastname: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
            }
        },
        email: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
                isEmail: true
            }
        },
        password: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
            }
        },
        dialCode: {
            value: DEFAULT_DIAL_CODE,
            hasError: false,
            errorMsg: '',
            rules : {
                required: true,
            }
        },
        phone: {
            value: '',
            hasError: true,
            errorMsg: '',
            rules : {
                required: true,
                isNumericPhone: true
            }
        }
    }
}

const setValidForm = (inputForm) => {
    let isValidForm = true;
    if (inputForm) {
        for (const inputIdentifier in inputForm) {
            if (inputForm[inputIdentifier].value !== undefined && inputForm[inputIdentifier].rules !== undefined ) {
                if (inputForm[inputIdentifier].hasError) {
                    isValidForm = false;
                    break;
                }
            }
        }
    }
    return isValidForm;
}

const inputChanged = (state, action) => {
    let signupData = {...state.signupData};
    let isValidForm = {...state.isValidForm};
    let validate = validation.validate(signupData[action.payload.inputIdentifier].rules, action.payload.value);
    
    signupData[action.payload.inputIdentifier] = {...signupData[action.payload.inputIdentifier], ...validate};
    isValidForm = setValidForm(signupData);

    return updateObject(state, {signupData, isValidForm})
}

const titleChanged = (state, action) => {
    let signupDataCp = {...state.signupData};
    let isValidForm = {...state.isValidForm};
    let validate = validation.validate(signupDataCp.title.rules, action.payload.value);
    signupDataCp.title = {...signupDataCp.title, ...validate}

    isValidForm = setValidForm(signupDataCp);

    return updateObject(state, { signupData: signupDataCp, isValidForm });
}

const dialCodeChanged = (state, action) => {
    let signupData = {...state.signupData};
    let isValidForm = {...state.isValidForm};
    let validate = validation.validate(signupData.dialCode.value.rules, action.payload.value);

    signupData.dialCode = {...signupData.dialCode, ...validate};
    isValidForm = setValidForm(signupData);

    return updateObject(state, {signupData, isValidForm});
}

const successSignup = (state, action) => {
    return updateObject(state, {successSignup: action.payload.value});
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case 'SIGN_UP_INPUT_CHANGED' : return inputChanged(state, action);
        case 'SET_DIAL_CODE_HANDLER' : return dialCodeChanged(state, action);
        case 'SUCCESS_SIGN_UP' : return successSignup(state, action);
        case 'SIGN_UP_SELECT_CHANGED': return titleChanged(state, action);
        default: return state;
    }
}

export default reducer;