import React, { useState } from 'react';
import classes from './MobileSelect.module.scss';
import Icon from '../../Icon/Icon';
import Popup from '../../Popup/Popup';

const select = props => {
    const [showList, setShowList] = useState(false);

    const showListHandler = () => {
        setShowList(true);
    }

    const hideListHandler = () => {
        setShowList(false);
    }

    const getTextHandler = (val, options) => {
        let index = options.findIndex(option => option.value === val);
        let text = '';
        if (index !== -1) {
            text = options[index].text;
        }
        return text;
    }

    const setValueHandler = (val) => {
        hideListHandler();
        props.changed(val);
    }


    let iconClasses = [classes.Icon];
    if (showList) {
        iconClasses.push(classes.IsActive);
    }


    let text = props.placeholder;
    if (props.value && (props.options && props.options.length > 0)) {
        text = getTextHandler(props.value, props.options);
    }

    let options = null;
    if (props.options && props.options.length > 0) {
        options = props.options.map((option, index) => {
            return (
                <li 
                    key={index} 
                    className={classes.Item} 
                    onClick={() => setValueHandler(option.value)}>
                    {option.text}
                </li>
            );
        });
    }

    let content = (
        <ul className={classes.List}>
            {options}
        </ul>
    )

    return (
        <div>
            <Popup
                show={showList}
                hideClicked={hideListHandler}
                title={props.title}
                content={content}/>
            <div className={classes.Wrapper}>
                <div className={classes.Select} onClick={showListHandler}>
                    <div className={classes.Input}>
                        <div className={classes.Select}>
                            {text}
                        </div>
                    </div>
                    <div className={iconClasses.join(' ')}>
                        <Icon name="ico-caret-down" fill="#202124" stroke="none" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default select;