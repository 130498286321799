import React, { Component } from 'react';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import Footer from '../../../../components/Desktop/Shared/Footer/Footer';
import Banner from '../../../../components/Desktop/NonProducts/Banner/Banner';
import AboutUs from '../../../../components/Desktop/NonProducts/AboutUs/AboutUs';
import MetaTag from '../../../../helper/MetaTag';

class CareerContainer extends Component {
    state = {  }
    render() {
        return (
            <div>
                <MetaTag 
                    title="About Us"/>
                <Header />
                <Banner title="About Us" image="https://media01.cdnpergi.com/antavaya/images/about-us-1.jpg" />
                <AboutUs />
                <Footer />
            </div>
        );
    }
}

export default CareerContainer;