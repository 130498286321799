import {updateObject} from '../../utility';
import {reactLocalStorage} from 'reactjs-localstorage';

const initState = {
    locale: (reactLocalStorage.get('locale') === undefined) ? 'Id' : reactLocalStorage.get('locale')
}

const setLang = (state, locale) => {
     return updateObject(state, {locale: locale});
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_LANG':
            return setLang(state, action.locale);
        default:
            return state;
    }
}

export default reducer;