import axios from "../../../axios";
import { ANTAVAYA_API } from "../../../constant/Api";

export const checkBookingForm = () => {
    return {
        type: 'CHECK_BOOKING_FORM'
    }
}

export const contactInfoChanged = (identifier, value) => {
    return {
        type: 'SET_CONTACT_INFO_FORM_ADMISSION',
        payload: { value, identifier }
    }
}

export const isSucessBooking = (value) => {
    return {
        type: 'SET_IS_SUCCESS_BOOKING',
        payload: {
            value
        }
    }
}

export const submitBooking = (data) => {
    return dispatch => {
        axios.post(ANTAVAYA_API + 'tour-admission/inquiry', data)
            .then((response) => {
                if (response) {
                    dispatch(isSucessBooking(true));
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }
}