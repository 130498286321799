import React, { useState, useEffect } from 'react';
import classes from './BannerInput.module.scss';
import Icon from '../../../../UI/Icon/Icon';
import CSSTransition from 'react-transition-group/CSSTransition';
import CurrencyFormat from 'react-currency-format';
import { toCapitalize } from '../../../../../helper/GlobalFunc';
import { withRouter } from 'react-router-dom';
import TextField from '../../../../UI/Inputs/TextField/TextField';

import { connect } from 'react-redux';
import * as AvailabilityAction from '../../../../../redux/Admission/Availability/AvailabilityAction';

const bannerInput = (props) => {
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setSearch('')
    }, [props.backdropVisibility])

    const showBackdropHandler = () => {
        props.showBackdrop();
        setShowSuggestion(true)
    }

    const inputHandler = (value) => {
        setSearch(value)
        props.onSearch(value)
    }

    let wrapperClass = [classes.Wrapper];
    let formClasses = [classes.Form];
    if (props.type === 'small') {
        wrapperClass.push(classes.TopLeftRadius);
        formClasses.push(classes.Small);
    }

    let show = false;
    if (props.backdropVisibility && showSuggestion) {
        show = true
    }

    let activityClass = classes.Item;
    let admissionClass = classes.Item;
    if (props.path === 'activity') {
        activityClass = [classes.Item, classes.Active].join(' ');
    } else if (props.path === 'admission') {
        admissionClass = [classes.Item, classes.Active].join(' ');
    }

    let searchAvailability = null;
    let image = 'https://s3-ap-southeast-1.amazonaws.com/antavaya.com/images/pattern-image.png';

    let contentSearch = [];
    if (!props.isFetchSearch) {
        // eslint-disable-next-line
        props.searchAdmissionPackages.map((item, index) => {
            image = item.image
            contentSearch.push(
                <div className={classes.Package} key={index+'a'} onClick={() => props.history.push('/admission/detail/' + item.slug)}>
                    <div className={classes.ImageWrapper}>
                        <img src={image} className={classes.Img} alt={''} />
                    </div>
                    <div className={classes.Description}>
                        <div className={classes.Title}>
                            {item.tourAdmissionName}
                        </div>
                        <div className={classes.PriceWrapper}>
                            <div className={classes.StartFrom}>Start From</div>
                            <div className={classes.Price}>
                                <CurrencyFormat value={item.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            </div>
                            <div className={classes.Perpax}>/Pax</div>
                        </div>
                    </div>
                </div>
            )
        })

        // eslint-disable-next-line
        props.searchExperiencePackages.map((item, index) => {
            image = item.defaultImage.imageFile;
            contentSearch.push(
                <div className={classes.Package} key={index+'b'} onClick={() => props.history.push('/tour/detail/' + item.slug)}>
                    <div className={classes.ImageWrapper}>
                        <img src={image} className={classes.Img} alt={''} />
                    </div>
                    <div className={classes.Description}>
                        <div className={classes.Title}>
                            {item.days}D {toCapitalize(item.inventoryTitle)}
                        </div>
                        <div className={classes.PriceWrapper}>
                            <div className={classes.StartFrom}>Start From</div>
                            <div className={classes.Price}>
                                <CurrencyFormat value={item.priceStartFrom} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'Rp '} />
                            </div>
                            <div className={classes.Perpax}>/Pax</div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    if (search !== '' && !props.isFetchSearch) {
        contentSearch.map((item) => {
            return item
        })
        searchAvailability = (
            <React.Fragment>
                <div className={classes.SearchAvailibility}>
                    <div className={classes.ShowSearch}>
                        {contentSearch}
                    </div>
                    <div className={classes.Hide}></div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className={classes.OuterWrap}>
            <div className={wrapperClass.join(' ')}>
                <div className={formClasses.join(' ')}>
                    <div className={classes.InputWrapper}>
                        <div className={classes.Search}  onClick={() => showBackdropHandler()}>
                            <Icon name="ico-search" fill="#818589" stroke="none" width={24}/>
                            <div className={classes.InputSpace}>
                                <TextField
                                    type="text"
                                    classes={['NoBorder']}
                                    changed={(event) => inputHandler(event.target.value)}
                                    value={search}
                                    placeholder="Search by destination, activity or admission"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.Action}>
                    <button className={classes.Button} onClick={() => props.history.push('/things-to-do/availability')}>Explore</button>
                </div>
            </div>

            <CSSTransition
                in={show}
                mountOnEnter
                unmountOnExit
                timeout={270}
                classNames={{
                    enterActive: classes.Open,
                    exitActive: classes.Close
                }}>
                <div className="u-mt-16">
                    <div className={classes.SubProduct}>
                        <div className={classes.Show}>
                            <div className={activityClass} onClick={props.searchActivityClicked}>Activity Page</div>
                            <div className={admissionClass} onClick={props.searchAdmissionClicked}>Admission</div>
                        </div>
                        <div className={classes.Hide}>
                            hide
                        </div>
                        {searchAvailability}
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

const mapStateToProp = (state) => {
    return {
        searchAdmissionPackages: state.AdmissionAvailabilityReducer.searchAdmissionPackages,
        searchExperiencePackages: state.AdmissionAvailabilityReducer.searchExperiencePackages,
        isFetchSearch: state.AdmissionAvailabilityReducer.isFetchSearch,
        isErrorFetchSearch: state.AdmissionAvailabilityReducer.isErrorFetchSearch,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSearch: (value) => dispatch(AvailabilityAction.searchAdmissionExperiencePackages(value))
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(withRouter(bannerInput));