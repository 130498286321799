import React from 'react';
import classes from './Visitor.module.scss';
import Icon from '../../../../../UI/Icon/Icon';
import PlusMinusButton from '../../../../../UI/PlusMinusButton/PlusMinusButton';
import { currencyFormat, toCapitalize } from '../../../../../../helper/GlobalFunc';
import { AGE_RANGE_ADMISSION } from '../../../../../../constant/General';

// import * as DetailAction from '../../../../../../redux/Admission/Detail/DetailAction';

const visitor = (props) => {

    let content = (
        <div className={classes.Content}>
            <div className={classes.Default}>
                Appears after the package is selected
            </div>
        </div>
    );

    if (props.visitorsData) {
        let ticket = props.visitorsData.map((item, index) => {
            let ageRange = AGE_RANGE_ADMISSION[item.type];
            let priceType = 'All age';
            let age = null;
            let disabledMinusRules = (item.qty <= 0) ? true : false
            if (item.type !== 'all-price') {
                priceType = item.type;
                age = '( ' + ageRange + ' )';
            }
            
            if (item.type === 'all-price' || item.type === 'adult') {
                disabledMinusRules = (item.qty <= 1) ? true : false
            }
            return (
                <li className={classes.Item} key={index}>
                    <div>
                        <div className={classes.Desc}>{toCapitalize(priceType)} {age}</div>
                        <div className={classes.RoomType}>{currencyFormat(item.price)}</div>
                    </div>
                    <div className={classes.Action}>
                        <PlusMinusButton
                            isPlus={false}
                            disabled={disabledMinusRules}
                            clicked={() => props.onGuestRemoveClicked(item.type)} />

                        <div className={classes.Value}>{item.qty}</div>
                        
                        <PlusMinusButton
                            isPlus={true}
                            clicked={() => props.onGuestAddedClicked(item.type)}/>
                    </div>
                </li>
            );

        })
        
        content = (
            <div className={classes.Content}>
                <ul className={classes.List}>
                    {ticket}
                </ul>
            </div>
        )
    }
    
    return (
        <div className={classes.Item}>
            <div className={classes.Label}>
                <Icon name="ico-user-o" width={20} stroke="none" fill="#CD2033" />
                <label>Visitors</label>
            </div>
            {content}
        </div>
    )
}

export default visitor;
