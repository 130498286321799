import React from 'react';
import classes from './PlusMinusButton.module.scss';
import Icon from '../Icon/Icon';

const button = (props) => {
    let btnClasses = [classes.Button];
    if (props.classes && props.classes.length > 0) {
        props.classes.forEach(val => {
            btnClasses.push(classes[val]);
        });
    }
    let btn = <Icon name="ico-minus-circle-o" fill="#CD2033" stroke="none" width={32} />;
    if (props.isPlus) {
        btn = <Icon name="ico-plus-circle-o" fill="#CD2033" stroke="none" width={32} />;
    }
    return (
        <button
            className={classes.Button}
            onClick={props.clicked}
            disabled={props.disabled}>
            {btn}
        </button>
    );
}

export default button;