import React, { Component } from 'react';
import classes from './BranchOfficeList.module.scss';
import Map from './Map/Map';
import BranchOfficeItem from './BranchOfficeItem/BranchOfficeItem';


class BranchOfficeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regionIndex: 0
        };
    }

    render() {
        
        let stores = this.props.stores.map((region, index) => {
            return (
                <div className={classes.Item} key={index}>
                    <BranchOfficeItem region={region} />
                </div>
            );
        });

        return (
            <div className={classes.Wrapper}>
                <div className={classes.Container}>
                    <div className="u-mb-60">
                        <Map stores={this.props.stores}/>
                    </div>
                    <div className={classes.List}>
                        {stores}
                    </div>
                </div>
            </div>
        );
    }
}

export default BranchOfficeList;