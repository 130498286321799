import React from 'react'
import classes from './ContentCard.module.scss'
import PanelBox from '../../../../../../../components/UI/PanelBox/PanelBox'
import { toCapitalize, formatHumanDate, pluralize } from '../../../../../../../helper/GlobalFunc';
import ImgThumb from '../../../../../../../components/UI/ImgThumb/ImgThumb';
import PaymentStatus from '../../../../../../../components/Shared/PaymentStatus/PaymentStatus';

const ContentCard = props => {
    let textLimit = text => {
        if (text.length > 6) {
            let textSplit = text.substr(0, 25)
            return `${textSplit}...`
        } else {
            let textSplit = text
            return `${textSplit}`
        }
    }

    let cardOverlay
    let footOverlay
    let clickButton
    if (props.overlay === true) {
        cardOverlay = [classes.Card, classes.Overlay].join(' ')
        footOverlay = [classes.Foot, classes.Overlay].join(' ')
        clickButton = <div className={classes.Click}>See Detail</div>
    } else {
        cardOverlay = [classes.Card]
        footOverlay = [classes.Foot]
        clickButton = (
            <div onClick={props.clicked} className={classes.Click}>
                See Detail
            </div>
        )
    }

    return (
        <div className={classes.Panel}>
            <PanelBox>
                <div className={cardOverlay}>
                    <div className={classes.Head}>
                        <div className={classes.Text}>
                            Booking ID <p className={classes.Id}>{props.data.bookingID}</p>
                        </div>
                        {clickButton}
                    </div>
                    <div className={classes.Body}>
                        <div className={classes.Image}>
                            <ImgThumb src={props.data.image.url} type="square" size="small"/>
                        </div>
                        <div className={classes.Info}>
                            <div className={classes.Title}>{props.data.duration + 'D ' + toCapitalize(textLimit(props.data.tourName))}</div>
                            <div className={classes.Desc}>{pluralize(props.data.days, 'Day', 'Days')} & {pluralize(props.data.night, 'Night', 'Nights')}</div>
                            <div className={classes.Date}>
                                {formatHumanDate(props.data.departureDate)} - {formatHumanDate(props.data.endTourDate)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={footOverlay}>
                    <PaymentStatus status={props.data.paymentStatus} />
                </div>
            </PanelBox>
        </div>
    )
}

export default ContentCard
