import React from 'react';
import classes from './Journey.module.scss';
import { JOURNEY } from '../../../../../constant/Content';

const journey = () => {
    const journeyList = JOURNEY.map((journey, index) => {
        let mod = index % 2;
        let itemWrapperClass = classes.ItemLeft;
        let itemClass = [classes.Item, classes.Left];
        let dotClass = [classes.DotWrapper, classes.Left];
        let contentClass = [classes.Contetn, classes.Right];
        if (mod === 0) {
            itemWrapperClass = classes.ItemRight;
            itemClass = [classes.Item, classes.Right];
            dotClass = [classes.DotWrapper, classes.Right];
            contentClass = [classes.Contetn, classes.Left];
        } 
        return (
            <li key={index} className={itemWrapperClass}>
                <div className={itemClass.join(' ')}>
                    <div className={dotClass.join(' ')}>
                        <div className={classes.Dot}></div>
                    </div>
                    <div className={contentClass.join(' ')}>
                        <div className={classes.Year}>{journey.year}</div>
                        <div className={classes.Title}>{journey.title}</div>
                    </div>
                </div>
            </li>
        );
    });

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Container}>
                <div className="u-text-center u-mb-60">
                    <div className={classes.Title}>Antavaya Journey</div>
                </div>

                <ul>
                    {journeyList}
                </ul>
            </div>
        </div>
    );
}

export default journey;