import React, { useEffect } from 'react';
import classes from './AvailabilityContainer.module.scss';
import Availability from '../../../../components/Mobile/Hotel/Availability/Availability';
import EmptyData from '../../../../components/UI/EmptyData/EmptyData';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import ContentLoader from 'react-content-loader';

import { connect } from 'react-redux';
import * as availabilityAction from '../../../../redux/Hotel/Availability/AvailabilityAction';
import Header from '../../../../components/Mobile/Shared/Header/Header';

const availabilityContainer = props => {

    useEffect(() => {
        props.fetchHotel();
        scrollTop();
    }, []);

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    const backClickHandler = () => {
        window.history.back();
    }

    const itemClickHandler = slug => {
        props.history.push('/hotel/booking/' + slug);
    }

    let content = null;
    if (props.isFetchHotel) {
        content = (
            <div className={classes.Content}>
                {[<Loader key={1} />, <Loader key={2} />, <Loader key={3} />, <Loader key={4} />]}
            </div>
        );
    } else {
        if (props.isErrorFetchHotel) {
            content = (
                <div className={classes.Content}>
                    <EmptyData
                        type="tour-empty"
                        title="Hotel Empty"
                        desc="Sorry hotel is unavailable" />
                </div>
            )
        } else {
            if (props.hotels.length === 0) {
                content = (
                    <div className={classes.Content}>
                        <EmptyData
                            type="tour-empty"
                            title="Hotel Empty"
                            desc="Sorry hotel is unavailable" />
                    </div>
                )
            } else {
                content = (
                    <div className={classes.Content}>
                        <Availability
                            isFetchHotel={props.isFetchHotel}
                            data={props.hotels}
                            clicked={(slug) => itemClickHandler(slug)} />
                    </div>
                )
            }

        }
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.HeaderWrapper}>
                <Header
                    leftIcon="back"
                    leftClicked={backClickHandler}
                    content="Hotel" />
            </div>
            {content}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        hotels: state.HotelAvailabilityReducer.hotels,
        isFetchHotel: state.HotelAvailabilityReducer.isFetchHotel,
        isErrorFetchHotel: state.HotelAvailabilityReducer.isErrorFetchHotel,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchHotel: () => dispatch(availabilityAction.fetchHotel())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(availabilityContainer);

const Loader = (props) => {
    return (
        <div className="u-mb-24" key={props.key}>
            <PanelBox>
                <div className="u-p-16">
                    <ContentLoader
                        height={110}
                        speed={1}
                        primaryColor={'#e6e6e6'}>
                        <rect x="0" y="0" rx="12" ry="12" width="112" height="112" />

                        <rect x="130" y="3" rx="4" ry="4" width="100" height="15" />
                        <rect x="130" y="28" rx="4" ry="4" width="150" height="18" />

                        <rect x="130" y="60" rx="4" ry="4" width="100" height="15" />
                        <rect x="130" y="85" rx="4" ry="4" width="150" height="18" />
                    </ContentLoader>
                </div>
            </PanelBox>
        </div>
    )
}