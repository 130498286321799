import React, { Component } from 'react';
import classes from './ListPanel.module.scss';
import List from '../../../../UI/List/List';
import Icon from '../../../../UI/Icon/Icon';

class ListPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false
        }

    }
    showMoreToggleHandler = () => {
        this.setState({showMore: !this.state.showMore});
    }
    render() {
        let wrapperClasses = [classes.Wrapper];
        let iconClasses = [];
        let collapseBtn = null;
        let label = 'Show More';
        if (this.props.isCollapseBtn && this.props.isCollapseBtn === true) {
            if (this.state.showMore) {
                wrapperClasses = [classes.Wrapper, classes.More];
                label = 'Show Less';
                iconClasses = [classes.ArrowUp];
            } else {
                label = 'Show More';
                wrapperClasses = [classes.Wrapper, classes.Less];
            }
            collapseBtn = (
                <div className={classes.CollapseBtn}>
                    <div className={classes.Overlay}></div>
                    <div className={classes.Button} onClick={this.showMoreToggleHandler}>
                        {label} 
                        <div className={iconClasses.join(' ')}>
                            <Icon name="ico-caret-down" fill="#1A6FA3" stroke="none"  />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className={wrapperClasses.join(' ')}>
                <div className={classes.Title}>{this.props.title}</div>
                <List listColor={this.props.listColor} listSize={this.props.listSize} list={this.props.list}/>
                {collapseBtn}
            </div>
        );
    }
}

export default ListPanel;