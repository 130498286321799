import React, { Component } from 'react';
import classes from './TextField.module.scss';
import CSSTransition from 'react-transition-group/CSSTransition';
import Icon from '../../../UI/Icon/Icon';

class TextField extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showPassword : false
        };
    }

    showPassworClickHandler = () => {
        this.setState({showPassword: true});
    }

    hidePasswordClickHandler = () => {
        this.setState({showPassword: false});
    }

    render() {
        let type = 'text';
        if (this.props.type) {
            type = this.props.type;
        }
        let inputClasses = [classes.TextField];
        if (this.props.classes && this.props.classes.length > 0) {
            this.props.classes.forEach(inputClass => {
                inputClasses.push(classes[inputClass]);
            });
        }

        if (this.props.hasError) {
            inputClasses.push(classes.HasError);
        }

        let showHideIcon = null;
        let icon = <div onClick={this.showPassworClickHandler}>
                <Icon name="ico-show" fill="#6A6E73" stroke="none" />
            </div>;
        if (this.state.showPassword) {
            icon = <div onClick={this.hidePasswordClickHandler}>
                <Icon name="ico-hide" fill="#6A6E73" stroke="none" />
            </div>;
        }

        if (this.props.type === 'password') {
            showHideIcon = (
                <div className={classes.Icon}>
                    {icon}
                </div>
            );
            if (this.state.showPassword) {
                type = 'text';
            }
        }

        return (
            <div className={classes.Wrapper}>
                <input 
                    type={type}
                    disabled={this.props.disabled}
                    name={this.props.name} 
                    value={this.props.value} 
                    onChange={(event) => this.props.changed(event)} 
                    className={inputClasses.join(' ')} 
                    placeholder={this.props.placeholder}/>
                    {showHideIcon}
                    <CSSTransition
                        in={this.props.hasError}
                        mountOnEnter
                        unmountOnExit
                        timeout={270}
                        classNames={{
                            enterActive: classes.Open,
                            exitActive: classes.Close
                        }}>
                        <span className={classes.ErrorMsg}>{this.props.errorMsg}</span>
                    </CSSTransition>
            </div>
        );
    }
}

export default TextField;