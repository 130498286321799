import axios from '../../../axios';
import { ANTAVAYA_API } from '../../../constant/Api';
import { toast } from 'react-toastify';

export const inputChangeHandler = (event, inputIdentifier) => {
    return {
        type: 'SIGN_UP_INPUT_CHANGED',
        payload: {
            value: event.target.value,
            inputIdentifier
        }
    }
}

export const titleChangedHandler = (value) => {
    return {
        type: 'SIGN_UP_SELECT_CHANGED',
        payload: {
            value: value
        }
    }
}

export const setDialCodeHandler = (val) => {
    return {
        type: 'SET_DIAL_CODE_HANDLER',
        payload: {
            value: val
        }
    }
}

export const successSignup = (value) => {
    return {
        type: 'SUCCESS_SIGN_UP',
        payload: { value }
    }
}

export const signUpHandler = (signupData) => {
    const data = {
        title: signupData.title.value,
        firstname: signupData.firstname.value,
        lastname: signupData.lastname.value,
        email: signupData.email.value,
        password: signupData.password.value,
        countryCode: signupData.dialCode.value,
        phoneNumber: signupData.phone.value
    }
    return dispatch => {
        axios.post(ANTAVAYA_API + 'auth/signup', data)
            .then((response) => {
                if (response.data.code === 200) {
                    dispatch(successSignup(true));
                }
            })
            .catch((error) => {
                switch (typeof error.response.data.message) {
                    case 'string':
                        toast.error(error.response.data.message);
                        break;
                    case 'object':
                        for (const key in error.response.data.message) {
                            toast.error(error.response.data.message[key][0]);
                        }
                        break;
                    default:
                        break;
                }

            })
    }
}