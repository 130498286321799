import React from 'react';
import Modal from '../../../../../UI/Modal/Modal';
import OtpInput from 'react-otp-input';
import Button from '../../../../../UI/Button/Button';
import { formatDisplayPhone } from '../../../../../../helper/GlobalFunc';

import { connect } from 'react-redux';
import * as memberAction from '../../../../../../redux/Shared/Member/MemberAction';

function phoneVerificationModal(props) {
    let displayPhone = null;
    if (props.member) {
        displayPhone = formatDisplayPhone(`${props.member.countryCode}${props.member.phone}`);
    }
    return(
        <Modal 
            title="Verification Code"
            show={props.showVerifyPhoneForm}
            closeClicked={() => props.showHideVerifyPhoneClicked(false)}>
            <div className="u-mb-24">
                <div className="u-text-center u-color-grey">
                    <div className="u-mb-6">Please enter the verification code that we sent to</div>
                    <div>{displayPhone}</div>
                </div>
            </div>
            <div className="u-mb-40 u-display-flex flex-justify-content-center">
                <OtpInput
                    onChange={otp => props.otpChanged(otp)}
                    inputStyle="otp-input"
                    numInputs={4}
                    value={props.otp}
                    separator={<span></span>}
                    />
            </div>
            
            <div className="u-mb-16">
                <Button 
                    clicked={() => props.confirmOtp(props.otp)}
                    classes={['BtnRed', 'BtnMedium', 'BtnBlock']}>Verify</Button>
            </div>
            <div className="u-mb-16">
                <Button 
                    clicked={props.sendOtp}
                    classes={['BtnOutline', 'BtnMedium', 'BtnBlock']}>Send New Code</Button>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        member: state.MemberReducer.member,
        otp: state.MemberReducer.otp,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendOtp: () => dispatch(memberAction.sendOtp()),
        otpChanged: (otp) => dispatch(memberAction.setOtp(otp)),
        confirmOtp: (otp) => dispatch(memberAction.confirmOtp(otp))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(phoneVerificationModal);