import React from 'react';
import classes from './BannerDetail.module.scss';
import Icon from '../../../UI/Icon/Icon';
import { toCapitalize } from '../../../../helper/GlobalFunc';
import BannerDetailLoader from '../../../UI/Loaders/Desktop/DetailPackages/BannerDetail/BannerDetailLoader';
import Included from '../../Tour/Included/Included';
import { DEFAULT_IMG } from '../../../../constant/Content';

const bannerAvailability = (props) => {
    let imgHeaderUrl = null;
    if (props.tourPackage && props.tourPackage.defaultImage) {
        imgHeaderUrl = (
            <img onError={DEFAULT_IMG} src={props.tourPackage.defaultImage.url} alt="" />
        )
    }

    let content = null;
    if (props.tourPackage) {
        const destinationList = props.tourPackage.destinations.map((item, index) => {
            return <li key={index}>{item.destinationName}</li>;
        });

        content = (
            <div className={classes.Container}>
                <span className={classes.Duration}>{props.tourPackage.days} Days & {props.tourPackage.night} Night</span>
                <div className={classes.TitleWrapper}>
                    <div className={classes.Title}>{props.tourPackage.days + 'D '} {toCapitalize(props.tourPackage.inventoryTitle)}</div>
                    <ul className={classes.CityList}>
                        {destinationList}
                    </ul>
                    <div className={classes.Includes}>
                        {(props.tourPackage.includeFlight) &&
                            <div className={classes.Item}>
                                <Included label="Flight"/>
                            </div>
                        }
                        {(props.tourPackage.includeHotel) &&
                            <div className={classes.Item}>
                                <Included label="Hotel"/>
                            </div>
                        }
                    </div>
                </div>
                <div className={classes.ViewPhoto} onClick={props.goToPhotoSectionClicked}>
                    <Icon name="ico-image" fill="#6A6E73" stroke="none" />
                    <div className={classes.Label}>
                        View Photo
                    </div>
                </div>
            </div>
        );
    } else {
        content = (<BannerDetailLoader/>)
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Background}>
                {imgHeaderUrl}
                <div className={classes.Overlay}></div>
            </div>
            {content}
        </div>
    );
}

export default bannerAvailability;