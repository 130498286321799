import React from 'react';
import classes from './Participant.module.scss';
import PanelBox from '../../../../../../components/UI/PanelBox/PanelBox';
import { toCapitalize } from '../../../../../../helper/GlobalFunc';
import ContentLoader from 'react-content-loader';

const participant = (props) => {
    let content = null;

    if (props.detailTour) {
        let participants = props.detailTour.participant.map((participant, index) => {
            return (
                <div key={index} className={classes.Item}>
                    <div className={classes.Label}>{participant.type}</div>
                    <div className={classes.Value}>{toCapitalize(participant.title)}. {participant.name}</div>
                </div>
            )
        })

        content = (
            <div className={classes.Wrapper}>
                {participants}
            </div>
        )
    } else {
        content = (
            <div className="u-p-16">
                <ContentLoader
                    height={60}
                    speed={1}
                    primaryColor={'#e6e6e6'}
                >
                    <rect x="0" y="0" rx="2" ry="2" width="200" height="20" />
                    <rect x="280" y="0" rx="2" ry="2" width="200" height="20" />

                    <rect x="0" y="40" rx="2" ry="2" width="210" height="20" />
                    <rect x="280" y="40" rx="2" ry="2" width="200" height="20" />
                </ContentLoader>
            </div>
        );
    }

    return (
        <PanelBox>
            {content}
        </PanelBox>
    )
}

export default participant;