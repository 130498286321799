import React, { useEffect } from 'react';
import classes from './RetrieveBookingContainer.module.scss';
import Header from '../../../../components/Desktop/Shared/Header/Header';
import PanelBox from '../../../../components/UI/PanelBox/PanelBox';
import queryString from 'query-string';
import BookingDetail from '../../../../components/Desktop/Shared/RetieveBooking/BookingDetail/BookingDetail';
import TourDetail from '../../../../components/Desktop/Shared/RetieveBooking/TourDetail/TourDetail';
import Participant from '../../../../components/Desktop/Shared/RetieveBooking/Participant/Participant';
import PriceDetail from '../../../../components/Desktop/Shared/RetieveBooking/PriceDetail/PriceDetail';
import { connect } from 'react-redux';
import * as action from '../../../../redux/Shared/RetrieveBooking/RetrieveBookingAction';
import NotFound from '../../../../components/Desktop/Shared/RetieveBooking/NotFound/NotFound';

function retrieveBooking(props) {
    useEffect(() => {
        const urlParams = queryString.parse(props.location.search);

        let data = {
            orderNumber: urlParams.orderNumber,
            email: urlParams.email
        };
        props.initDetailBooking(data);

    }, []);

    let content = <div className={classes.Container}>
        <PanelBox>
            <div className={classes.Content}>
                <div className={classes.Head}>
                    <div className={classes.Title}>Retrieve Booking</div>
                    {(props.bookingDetail && props.bookingDetail.pdfUrl) &&
                    <a className={classes.DownloadBtn} href={(props.bookingDetail)? props.bookingDetail.pdfUrl: '#'} target="blank">
                        Download E-Ticket
                    </a>}
                </div>
                <div className="u-mb-24">
                    <BookingDetail 
                        isLoading={props.isFetchBookingDetail}
                        bookingData={props.bookingDetail} />
                </div>
                <div className="u-mb-24">
                    <TourDetail tourData={props.tourData}
                        isLoading={props.isFetchBookingDetail}
                        bookingData={props.bookingDetail} />
                </div>
                <div className="u-mb-24">
                    <Participant 
                        isLoading={props.isFetchBookingDetail}
                        bookingData={props.bookingDetail}/>
                </div>
                <div>
                    <PriceDetail
                        isLoading={props.isFetchBookingDetail}
                        bookingData={props.bookingDetail}/>
                </div>
            </div>
        </PanelBox>
    </div>;
    if (props.isErrorFetchBookingDetail) {
        content = <div className={classes.Container}>
            <NotFound clicked={() => props.history.push('/tour/availability?destination=all-region')}/>
        </div>;
    } 

    return (
        <div className={classes.Wrapper}>
            <Header />
            {content}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        bookingDetail: state.RetrieveBookingReducer.bookingDetail,
        isFetchBookingDetail: state.RetrieveBookingReducer.isFetchBookingDetail,
        isErrorFetchBookingDetail: state.RetrieveBookingReducer.isErrorFetchBookingDetail
    };
}

const mapDispatchToProps = dispatch => {
    return {
        initDetailBooking: (data) => dispatch(action.initRetrieveBooking(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(retrieveBooking);
